import { AnimatePresence, useReducedMotion } from "framer-motion";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import {
  Overlay,
  Path,
  Step,
  WizardProvider,
  useWizard,
} from "@components/ModalWizardHelper";
import ModalWizardLayout from "@components/ModalWizardLayout";
import WizardSteps from "@components/WizardSteps";
import ConfirmReplacementNeededStep from "@components/units/multiUseReplacement/ConfirmReplacementNeededStep";
import ConfirmReplacementNeededStepFooter from "@components/units/multiUseReplacement/ConfirmReplacementNeededStepFooter";
import DeclineReplacementStep from "@components/units/multiUseReplacement/DeclineReplacementStep";
import DeclineReplacementStepFooter from "@components/units/multiUseReplacement/DeclineReplacementStepFooter";
import SelectReasonStep from "@components/units/multiUseReplacement/SelectReasonStep";
import SelectReasonStepFooter from "@components/units/multiUseReplacement/SelectReasonStepFooter";
import SpecifyReasonStep from "@components/units/multiUseReplacement/SpecifyReasonStep";
import SpecifyReasonStepFooter from "@components/units/multiUseReplacement/SpecifyReasonStepFooter";
import StartStep from "@components/units/multiUseReplacement/StartStep";
import StartStepFooter from "@components/units/multiUseReplacement/StartStepFooter";
import {
  useUnitToolReplacementDeclined,
  useUnitToolReplacementRequested,
} from "@state/hooks";
import { BackForwardCard, Direction } from "@util/animations";
import { ToolReplacementForm, replacementReasons } from "@util/viewModel";

const ConfirmReplacementWizard = ({
  controllerSerialNumber,
  sku,
  uid,
  unitReminderId,
  onClose,
}: {
  controllerSerialNumber: string;
  unitReminderId?: string;
  sku: string;
  uid: string;
  onClose: (completed: boolean) => void;
}) => {
  const shouldReduceMotion = useReducedMotion();

  const [attemptCancel, setAttemptCancel] = useState(false);

  const { mutate: submitReplacementDeclined } =
    useUnitToolReplacementDeclined(unitReminderId);
  const { mutate: submitReplacementRequested } =
    useUnitToolReplacementRequested(unitReminderId);

  const {
    formState,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
    ...methods
  } = useForm<ToolReplacementForm>({
    defaultValues: {
      controllerSerialNumber,
      uid: uid || "",
      sku,
    },
  });

  const path: Path = useMemo(
    () => [
      "start",
      {
        replacementNeeded: ["replacementNeeded"],
        replacementNotNeeded: [
          "selectReasons",
          {
            specifyReason: ["specifyReason", "decline"],
            declineWithoutReason: ["declineWithoutReason"],
          },
        ],
      },
    ],
    [],
  );

  const steps: Step[] = useMemo(
    () => [
      {
        name: "start",
        title: "Multi Use Item Replacement",
        Content: <StartStep />,
        Footer: <StartStepFooter onCancel={() => setAttemptCancel(true)} />,
      },
      {
        name: "replacementNeeded",
        title: "Confirm Replacement",
        Content: <ConfirmReplacementNeededStep />,
        Footer: <ConfirmReplacementNeededStepFooter />,
      },
      {
        name: "selectReasons",
        title: "Select Reason",
        Content: <SelectReasonStep />,
        Footer: <SelectReasonStepFooter />,
      },
      {
        name: "specifyReason",
        title: "Specify Reason",
        Content: <SpecifyReasonStep />,
        Footer: <SpecifyReasonStepFooter />,
      },
      {
        name: "decline",
        title: "Decline Replacement",
        Content: <DeclineReplacementStep />,
        Footer: <DeclineReplacementStepFooter />,
      },
      {
        name: "declineWithoutReason",
        title: "Decline Replacement",
        Content: <DeclineReplacementStep />,
        Footer: <DeclineReplacementStepFooter />,
      },
    ],
    [],
  );

  const [direction, setDirection] = useState<Direction>("forward");

  const { step, count, previous, ...rest } = useWizard(path, {
    onStepChange: (_stepName, direction) => setDirection(direction),
  });

  const currentStep = useMemo(
    () => steps.find((s) => s.name === step.name) ?? steps[0],
    [step, steps],
  );

  const onSubmit = useCallback(
    (formEvent?: React.FormEvent) =>
      void handleSubmit(
        async ({
          choice,
          selectedReasons,
          reason,
          controllerSerialNumber,
          uid,
          sku,
        }) =>
          new Promise((resolve, reject) =>
            choice === "needed"
              ? submitReplacementRequested(
                  {
                    controllerSerialNumber: controllerSerialNumber,
                    sku: sku,
                    uid: uid,
                  },
                  {
                    onSuccess: resolve,
                    onError: reject,
                  },
                )
              : submitReplacementDeclined(
                  {
                    controllerSerialNumber: controllerSerialNumber,
                    sku: sku,
                    uid: uid,
                    reason: replacementReasons(selectedReasons, reason) || "",
                  },
                  {
                    onSuccess: resolve,
                    onError: reject,
                  },
                ),
          )
            .then(() => {
              onClose(true);
              toast.success(
                <span>
                  Replacement {choice === "needed" ? "requested" : "declined"}
                </span>,
              );
            })
            .catch(() => {
              setError("root", {
                message:
                  "We encountered an issue on our side. Please try again soon.",
              });
            }),
      )(formEvent),
    [
      handleSubmit,
      onClose,
      setError,
      submitReplacementDeclined,
      submitReplacementRequested,
    ],
  );

  const handleCloseAttempt = useCallback(() => setAttemptCancel(true), []);

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleBack = useCallback(() => previous(), [previous]);

  return (
    <Modal contentLabel="Replacement" onClose={handleCloseAttempt}>
      <FormProvider
        {...{
          formState,
          handleSubmit,
          register,
          reset,
          setError,
          setValue,
          watch,
          ...methods,
        }}
      >
        <WizardProvider {...{ step, count, direction, previous, ...rest }}>
          <form onSubmit={onSubmit}>
            <ModalWizardLayout
              header={
                <div style={{ position: "relative" }}>
                  <ModalHeader
                    title={currentStep.title}
                    backLabel={currentStep.backLabel}
                    onBack={handleBack}
                    onClose={handleCloseAttempt}
                  />

                  <Overlay show={attemptCancel} />
                </div>
              }
              content={
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <AnimatePresence
                    initial={false}
                    custom={{ direction, reduceMotion: shouldReduceMotion }}
                  >
                    <BackForwardCard
                      key={currentStep.name}
                      custom={{
                        direction,
                        reduceMotion: shouldReduceMotion,
                      }}
                    >
                      {currentStep.Content}
                    </BackForwardCard>
                  </AnimatePresence>
                  <Overlay show={attemptCancel} />
                </div>
              }
              footer={
                <ModalFooter>
                  {attemptCancel ? (
                    <>
                      <p>
                        You have not completed the replacement confirmation. If
                        you leave now,{" "}
                        <strong>
                          You may get a replacement sent in a few days
                        </strong>{" "}
                        this will be charged to you.
                      </p>

                      <p>Are you sure you want to leave now?</p>

                      <ButtonGroup flow="horizontal">
                        <Button onClick={() => setAttemptCancel(false)}>
                          No, I&apos;m not finished.
                        </Button>
                        <Button buttonType="destructive" onClick={handleClose}>
                          Yes, leave now.
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      {currentStep.Footer}
                      <WizardSteps steps={count} current={step.number} />
                    </>
                  )}
                </ModalFooter>
              }
            />
          </form>
        </WizardProvider>
      </FormProvider>
    </Modal>
  );
};

export default ConfirmReplacementWizard;
