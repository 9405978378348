import { AnimatePresence, motion } from "framer-motion";
import { Toaster } from "react-hot-toast";
import { Redirect, Route, Switch } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import TermsOfService from "@auth/TermsOfService";
import DS from "@design/system";
import { MapStateProvider } from "@util/MapState";
import { SidePanelsProvider } from "@util/SidePanels";
import useIncoming from "@util/useIncoming";
import useTerminology from "@util/useTerminology";

import Banner from "./Banner";
import DetailView from "./DetailView";
import Header from "./Header";
import MapView from "./MapView";

const Container = styled.div`
  min-height: calc(var(--vh, 1vh) * 100);

  display: grid;
  grid-template-rows: [header] auto [content] 1fr;
`;

const AuthenticatedPage = ({ showDetailView }: { showDetailView: boolean }) => {
  const { palettes } = useTheme();

  useTerminology();
  useIncoming();

  return (
    <Container>
      <div>
        <Banner />
        <Header />
        <TermsOfService />
      </div>
      <main
        style={{
          position: "relative",
          display: "grid",
        }}
      >
        <Switch>
          <Route
            path={[
              "/set-password/:email/:resetKey",
              "/set-password/:email/",
              "/set-password",
            ]}
          >
            <Redirect to="/" />
          </Route>
          <SidePanelsProvider>
            <MapStateProvider>
              <MapView />
            </MapStateProvider>

            <AnimatePresence>
              {showDetailView && (
                <>
                  <motion.div
                    style={{
                      zIndex: 1,
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      background: "rgba(0, 0, 0, 0.25)",
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  />
                  <motion.div
                    style={{
                      position: "absolute",
                      overflow: "hidden",
                      top: 16,
                      right: 16,
                      bottom: 16,
                      left: 16,
                      zIndex: 1,

                      background: palettes.body.background,
                      borderRadius: DS.radii.floating,
                    }}
                    initial={{
                      transform: "scale(0.9)",
                      opacity: 0,
                    }}
                    animate={{
                      transform: "scale(1)",
                      opacity: 1,
                    }}
                    exit={{
                      transform: "scale(0.9)",
                      opacity: 0,
                    }}
                    transition={{ duration: 0.2, ease: "easeOut" }}
                  >
                    <Route
                      path={[
                        "/group/:groupId",
                        "/store/:storeId",
                        "/all-stores",
                      ]}
                    >
                      <DetailView />
                    </Route>
                  </motion.div>
                </>
              )}
            </AnimatePresence>
          </SidePanelsProvider>
        </Switch>
      </main>

      <Toaster
        toastOptions={{
          style: {
            padding: DS.margins.micro,
            color: palettes.body.foreground,
            background: palettes.body.background,
            borderRadius: DS.radii.largeItem,
            boxShadow: DS.shadows.dialog,
          },
        }}
      />
    </Container>
  );
};

export default AuthenticatedPage;
