import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-hot-toast";

import { UnstyledList } from "@design/helpers";
import DS from "@design/system";

import Button from "../components/Button";
import HighlightChars from "../components/HighlightChars";
import ListItem from "../components/ListItem";
import Modal from "../components/Modal";
import ModalFooter from "../components/ModalFooter";
import ModalHeader from "../components/ModalHeader";
import ModalStandardLayout from "../components/ModalStandardLayout";
import NoResults from "../components/NoResults";
import Scroller from "../components/Scroller";
import SearchBar from "../components/SearchBar";
import Selectable from "../components/Selectable";
import {
  useAddStoresToGroup,
  useSearch,
  useStores,
  useStoresInGroup,
} from "../state/hooks";

const getButtonLabel = (storeIds: string[], loading: boolean) => {
  if (storeIds.length === 1 && loading) {
    return "Adding store to group...";
  } else if (storeIds.length > 1 && loading) {
    return "Adding stores to group...";
  } else if (storeIds.length === 0) {
    return "Select stores to add...";
  } else if (storeIds.length === 1) {
    return "Confirm adding store to group";
  } else {
    return "Confirm adding stores to group";
  }
};

const getSavedStoreNames = (savedStores: Api.Store[]) => {
  const sized = 3;
  const savedStoreNamesList = savedStores.map((store) => store.Name);
  if (savedStoreNamesList.length === 1) return savedStoreNamesList[0];
  if (savedStoreNamesList.length > sized)
    return savedStoreNamesList.slice(0, sized).join(", ") + "...";

  const firsts = savedStoreNamesList.slice(0, savedStoreNamesList.length - 1);
  const last = savedStoreNamesList[savedStoreNamesList.length - 1];
  return firsts.join(", ") + " and " + last;
};

type AddStoresToGroupsViewModel = { storeIds: string[]; groupsIds: string[] };

const AddStoreToGroupModal = ({
  groupId,
  onClose,
}: {
  groupId?: string;
  onClose: () => void;
}) => {
  const { data: allStores } = useStores();
  const { data: childStores } = useStoresInGroup(groupId, true);

  const { mutate: addStoresToGroup } = useAddStoresToGroup();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<AddStoresToGroupsViewModel>({
    defaultValues: {
      storeIds: [],
      groupsIds: [groupId],
    },
  });

  const storeIds = useWatch({ control, name: "storeIds" });

  const {
    results: filteredStores,
    search,
    q,
    setList: setAddableStores,
  } = useSearch([] as Api.Store[], (store) => store.Name);

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const onSubmit: React.FormEventHandler = useCallback(
    (e) =>
      void handleSubmit(async (data: AddStoresToGroupsViewModel) => {
        if (!allStores) return;

        const names = getSavedStoreNames(
          allStores.filter((store) =>
            data.storeIds.find((id) => id === store.StoreId),
          ),
        );

        return new Promise<Api.ResponseSuccess<unknown>>((resolve, reject) =>
          addStoresToGroup(data, { onSuccess: resolve, onError: reject }),
        )
          .then(() => {
            onClose();
            toast.success(
              <span>
                <strong>{names}</strong> successfully added.
              </span>,
            );
          })
          .catch(() => {
            onClose();
            toast.error(
              <span>
                Unable to add <strong>{names}</strong>.
              </span>,
            );
          });
      })(e),
    [addStoresToGroup, allStores, handleSubmit, onClose],
  );

  useEffect(() => {
    if (!allStores || !childStores) {
      setAddableStores([]);
      return;
    }

    setAddableStores(
      allStores.filter(
        (store) => !childStores.find((s) => s.StoreId === store.StoreId),
      ),
    );
  }, [allStores, childStores, setAddableStores]);

  useLayoutEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  return (
    <Modal contentLabel="Add store to group" onClose={onClose}>
      <form onSubmit={onSubmit}>
        <ModalStandardLayout
          header={<ModalHeader title="Add Store to Group" onClose={onClose} />}
          content={
            <div
              style={{
                display: "grid",
                gridTemplateRows: "auto 1fr",
                gap: DS.margins.regular,
              }}
            >
              <div style={{ marginTop: DS.margins.regular, padding: "0 24px" }}>
                <SearchBar
                  placeholder="Search for stores&hellip;"
                  value={q}
                  onSearch={(q) => search(q)}
                />
              </div>

              <Scroller>
                <div style={{ padding: "0 24px 16px" }}>
                  {filteredStores && (
                    <Controller
                      control={control}
                      name="storeIds"
                      render={({ field }) => (
                        <UnstyledList>
                          {filteredStores.map(({ item: store, positions }) => (
                            <li key={store.StoreId}>
                              <Selectable
                                {...field}
                                label={store.Name}
                                value={store.StoreId}
                                selected={
                                  !!field.value.find(
                                    (storeId) => storeId === store.StoreId,
                                  )
                                }
                                onChange={(e) => {
                                  field.onChange(
                                    e.target.checked
                                      ? field.value.concat(store.StoreId)
                                      : field.value.filter(
                                          (storeId) =>
                                            storeId !== store.StoreId,
                                        ),
                                  );
                                }}
                              >
                                <ListItem
                                  title={
                                    <HighlightChars
                                      str={store.Name}
                                      indices={positions}
                                    />
                                  }
                                  description={store.StreetAddress.AsOneLine}
                                />
                              </Selectable>
                            </li>
                          ))}
                        </UnstyledList>
                      )}
                    />
                  )}
                  {(!filteredStores || !filteredStores.length) && (
                    <NoResults>
                      No stores matching <strong>{q}</strong> found.
                    </NoResults>
                  )}
                </div>
              </Scroller>
            </div>
          }
          footer={
            <ModalFooter>
              <Button
                stretch
                type="submit"
                buttonType="action"
                disabled={storeIds.length === 0 || isSubmitting}
              >
                {getButtonLabel(storeIds, isSubmitting)}
              </Button>
            </ModalFooter>
          }
        />
      </form>
    </Modal>
  );
};

export default AddStoreToGroupModal;
