import color from "color";

import { defaultIcons } from "../icons";
import { Theme } from "../themes";
import Logo from "./Logo";
import { Cintas } from "./colors";

export const cintas: Theme = {
  id: "cintas",

  meta: {
    themeName: "Cintas",

    appName: "Smart First Aid",
    description:
      "Smart First Aid is a smart first aid system that helps ensure you are always ready and prepared in the event of an injury at work.",
    Logo,

    font: "proxima-nova",
    links: [
      {
        rel: "stylesheet",
        href: "https://use.typekit.net/zjh5aya.css",
      },
    ],

    copyright: "Cintas Corporation",
  },

  links: {
    footer: [
      {
        label: "Terms of Service",
        url: "https://smartfirstaid.com/terms-and-conditions",
      },
      {
        label: "Privacy Notice",
        url: "https://smartfirstaid.com/privacy-notice",
      },
    ],

    support: { label: "Support", url: "https://help.smartfirstaid.com" },
    termsOfService: {
      label: "Terms of Service",
      url: "https://smartfirstaid.com/terms-and-conditions",
    },

    supportEmail: "support@smartfirstaid.com",
  },

  map: {
    initial: [-101.0, 39.0, 4], // USA
  },

  features: { smsNotifications: false, showBilling: false },

  icons: {
    ...defaultIcons,

    unitType: {
      ...defaultIcons.unitType,

      FirstAidStation: "cintas-device",
    },

    eventCategory: {
      ...defaultIcons.eventCategory,

      Cuts: "cintas-bandages-color",
      Burns: "cintas-burns-color",
      Eyes: "cintas-eyes-color",
      STF: "cintas-tools-color",
      Cleaning: "cintas-productivity-color",
      Safety: "cintas-tablets-color",
    },

    other: {
      ...defaultIcons.other,

      device: "cintas-device",
      incident: "cintas-incidents",
      store: "cintas-store",
    },
  },

  palettes: {
    signature: Cintas.core.blue,
    secondary: Cintas.core.red,

    header: {
      foreground: Cintas.neutralOther.white,
      background: Cintas.core.blue,
    },

    body: {
      foreground: Cintas.neutralOther.dark,
      background: Cintas.neutralOther.white,
      accent: Cintas.core.blue,

      dim: Cintas.unofficial.lightGray,

      border: Cintas.unofficial.lightGray,

      title: Cintas.core.blue,
      small: Cintas.neutralOther.mid,
    },

    well: {
      foreground: Cintas.neutralOther.dark,
      background: Cintas.unofficial.lightGray,

      dim: color(Cintas.unofficial.lightGray)
        .mix(color("#000000"), 0.15)
        .string(),

      title: Cintas.unofficial.darkGray,
      small: Cintas.neutralOther.mid,
    },

    itemSelected: {
      background: color(Cintas.neutralOther.white)
        .mix(color(Cintas.blue.light), 0.25)
        .string(),

      light: Cintas.unofficial.lightGray,
      dim: color(Cintas.neutralOther.white)
        .mix(color(Cintas.blue.light), 0.5)
        .string(),

      border: Cintas.blue.light,
    },

    tooltip: {
      foreground: Cintas.neutralOther.white,
      background: Cintas.unofficial.darkGray,

      small: Cintas.neutralOther.mid,
    },

    form: {
      foreground: Cintas.neutralOther.dark,
      background: Cintas.neutralOther.white,
      accent: Cintas.core.blue,

      light: color(Cintas.neutralOther.white)
        .mix(color("#000000"), 0.05)
        .string(),
      dim: color(Cintas.neutralOther.white).mix(color("#000000"), 0.1).string(),

      border: Cintas.neutralOther.mid,

      title: Cintas.neutralOther.mid,
      small: Cintas.neutralOther.mid,
    },

    buttons: {
      action: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.core.blue,
        dim: color(Cintas.core.blue).mix(color("#000000"), 0.15).string(),

        border: "transparent",
      },
      progressive: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.core.blue,
        dim: color(Cintas.core.blue).mix(color("#000000"), 0.15).string(),

        border: "transparent",
      },
      regressive: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.core.blue,
        dim: color(Cintas.core.blue).mix(color("#000000"), 0.15).string(),

        border: "transparent",
      },
      destructive: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.core.red,
        dim: color(Cintas.core.red).mix(color("#000000"), 0.15).string(),

        border: "transparent",
      },
      neutral: {
        foreground: Cintas.neutralOther.dark,
        background: Cintas.neutralOther.white,
        dim: color(Cintas.neutralOther.white)
          .mix(color("#000000"), 0.15)
          .string(),

        border: Cintas.neutralOther.mid,
      },
      neutralSelected: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.core.blue,
        dim: color(Cintas.core.blue).mix(color("#000000"), 0.15).string(),

        border: Cintas.core.blue,
      },
    },

    messages: {
      success: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.green.dark,
      },
      info: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.core.blue,
      },
      notice: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.orange.mid,
      },
      error: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.core.red,
      },
    },

    states: {
      na: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.neutralOther.mid,
      },
      good: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.green.dark,
      },
      neutral: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.core.blue,
      },
      warning: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.orange.mid,
      },
      bad: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.core.red,
      },
    },

    severity: {
      low: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.blue.mid,
      },
      medium: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.orange.mid,
      },
      high: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.core.red,
      },
    },

    roles: {
      Admin: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.green.dark,
      },
      Consultant: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.core.blue,
      },
      Owner: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.neutralOther.dark,
      },
      Supervisor: {
        foreground: Cintas.neutralOther.white,
        background: Cintas.core.red,
      },
      "Local Safety Officer": {
        foreground: Cintas.neutralOther.white,
        background: Cintas.orange.mid,
      },
    },

    wizardIcon: {
      primary: Cintas.core.blue,
      secondary: Cintas.blue.mid,
      fill: Cintas.neutralOther.white,
      background: Cintas.blue.light,
    },
  },
};
