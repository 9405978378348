import styled from "styled-components";

import { ApiResponseError } from "@api";
import DS from "@design/system";

import Message from "./Message";

const ErrorWell = styled.div`
  padding: ${DS.margins.micro};

  color: ${({ theme }) => theme.palettes.messages.error.foreground};
  font-size: 14px;

  background: ${({ theme }) => theme.palettes.messages.error.background};
  border-radius: ${DS.radii.item};
`;

export const AutoErrorWell = <TError extends Api.ValidationError>({
  response,
}: {
  response: ApiResponseError<TError>;
}) => {
  const { error, originalResponse } = response;

  return (
    <Message type="error">
      {originalResponse.Message}:
      <ul>
        {error.all.map((error) => (
          <li key={error.ErrorKey}>{error.Description}</li>
        ))}
      </ul>
    </Message>
  );
};

export default ErrorWell;
