import { AnimatePresence, useReducedMotion } from "framer-motion";
import { useState, useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import { ApiResponseError, isApiResponseError } from "@api";
import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import {
  Overlay,
  Step,
  WizardProvider,
  useWizard,
  Path,
} from "@components/ModalWizardHelper";
import ModalWizardLayout from "@components/ModalWizardLayout";
import { GroupAndOrStoreKey, useMutateUser, useUserFull } from "@state/hooks";
import { BackForwardCard, Direction } from "@util/animations";
import { LastResponseContext } from "@util/viewModel";

import DisableMfa from "./DisableMfa";
import SubmitDisableMfaFooter from "./SubmitDisableMfaFooter";

const DisableMfaModal = ({
  userId,
  mutationKeys,
  onClose,
}: {
  userId: string;
  mutationKeys?: GroupAndOrStoreKey;
  onClose: () => void;
}) => {
  const { data: user } = useUserFull(userId);
  const { mutate: updateUser } = useMutateUser(mutationKeys);

  const [attemptCancel, setAttemptCancel] = useState<boolean>(false);
  const [direction, setDirection] = useState<Direction>("forward");
  const [lastResponse, setLastResponse] =
    useState<ApiResponseError<Api.UserValidationError> | null>(null);

  const handleCloseAttempt = useCallback(() => setAttemptCancel(true), []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const { register, handleSubmit, reset, setValue, setError, ...methods } =
    useForm<Api.User>({
      defaultValues: {
        AuthenticateChangeRequest: {
          HasMfaEnabled: false,
        },
      },
    });

  const onSubmit: React.FormEventHandler = useCallback(
    (e) =>
      void handleSubmit((data) => {
        const disableMfaUser: Api.User = {
          ...data,
          AuthenticateChangeRequest: {
            ...data.AuthenticateChangeRequest,
            HasMfaEnabled: false,
          },
        };

        return new Promise<Api.ResponseSuccess<Api.User>>((resolve, reject) =>
          updateUser(disableMfaUser, { onSuccess: resolve, onError: reject }),
        )
          .then((response) => response.SuccessPayload)
          .then((disableMfaUser) => {
            if (!disableMfaUser) return;
            onClose();
            toast.success(
              <span>Two-Factor Authentication successfully disabled </span>,
            );
          })
          .catch((error) => {
            if (isApiResponseError<Api.UserValidationError>(error)) {
              setLastResponse(error);
              setError("AuthenticateChangeRequest.CurrentPassword", {
                message: "Incorrect password",
              });
            }
          });
      })(e),
    [handleSubmit, onClose, setError, updateUser],
  );

  useEffect(() => reset(user), [user, reset]);

  const path: Path = useMemo(() => ["Are you sure?"], []);

  const steps: Step[] = useMemo(
    () => [
      {
        name: "Are you sure?",
        title: "Disable Two-Factor Authentication",

        Content: <DisableMfa />,
        Footer: (
          <SubmitDisableMfaFooter onCancel={() => setAttemptCancel(true)} />
        ),
      },
    ],
    [],
  );

  const { step, count, previous, ...rest } = useWizard(path, {
    onStepChange: (_stepName, direction) => setDirection(direction),
  });
  const shouldReduceMotion = useReducedMotion();

  const currentStep = useMemo(
    () => steps.find((s) => s.name === step.name) ?? steps[0],
    [step, steps],
  );

  if (!step || !direction) return null;

  return (
    <LastResponseContext.Provider value={lastResponse}>
      <Modal
        contentLabel="Disable Two-factor Authentication"
        onClose={handleCloseAttempt}
      >
        <FormProvider
          {...{
            handleSubmit,
            register,
            setValue,
            reset,
            setError,
            ...methods,
          }}
        >
          <WizardProvider {...{ step, count, direction, previous, ...rest }}>
            <form onSubmit={onSubmit}>
              <ModalWizardLayout
                header={
                  <div style={{ position: "relative" }}>
                    <ModalHeader
                      title={currentStep.title}
                      backLabel={currentStep.backLabel}
                      onBack={previous}
                      onClose={handleCloseAttempt}
                    />

                    <Overlay show={attemptCancel} />
                  </div>
                }
                content={
                  <div style={{ position: "relative" }}>
                    <AnimatePresence
                      initial={false}
                      custom={{ direction, reduceMotion: shouldReduceMotion }}
                    >
                      <BackForwardCard
                        key={currentStep.name}
                        custom={{ direction, reduceMotion: shouldReduceMotion }}
                      >
                        {currentStep.Content}
                      </BackForwardCard>
                    </AnimatePresence>
                    <Overlay show={attemptCancel} />
                  </div>
                }
                footer={
                  <ModalFooter>
                    {attemptCancel ? (
                      <>
                        <p>
                          You have not finished disabling two-factor
                          authentication. If you leave now,{" "}
                          <strong>2FA will remain on.</strong>
                        </p>

                        <p>Are you sure you want to leave now?</p>

                        <ButtonGroup flow="horizontal">
                          <Button onClick={() => setAttemptCancel(false)}>
                            No, I&apos;m not finished.
                          </Button>
                          <Button
                            buttonType="destructive"
                            onClick={handleClose}
                          >
                            Yes, leave now.
                          </Button>
                        </ButtonGroup>
                      </>
                    ) : (
                      <>{currentStep.Footer}</>
                    )}
                  </ModalFooter>
                }
              />
            </form>
          </WizardProvider>
        </FormProvider>
      </Modal>
    </LastResponseContext.Provider>
  );
};

export default DisableMfaModal;
