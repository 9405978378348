import { addDays, differenceInDays, subDays } from "date-fns";
import {
  CSSProperties,
  PointerEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useResizeObserver from "use-resize-observer";

import { DateRange } from "./DateRange";

type MonthSegment = {
  label: string;

  width: number;
  x: number;
};

type Meta = {
  pixelsPerDay: number;
  dayCount: number;
  numberOfMonths: number;
  startMonth: number;
  endMonth: number;
};

function findMonthSegments(
  dateRange: DateRange,
  stuff: {
    pixelsPerDay: number;
    dayCount: number;
    numberOfMonths: number;
    startMonth: number;
    endMonth: number;
  },
) {
  const { start, end } = dateRange;

  const month = new Date(start);

  return Array.from<undefined[]>({ length: stuff.numberOfMonths }).reduce(
    (labelsArray, _, index) => {
      month.setMonth(stuff.startMonth + index);

      const monthName = month.toLocaleString("default", { month: "short" });

      const daysInMonth = new Date(
        month.getFullYear(),
        month.getMonth() + 1,
        0,
      ).getDate();

      let visibleDays = daysInMonth;

      if (index === 0) visibleDays -= start.getDate() - 1;
      if (index === stuff.numberOfMonths - 1)
        visibleDays -= daysInMonth - end.getDate();

      if (visibleDays === 0) visibleDays = stuff.dayCount;

      return [
        ...labelsArray,
        {
          width: visibleDays * stuff.pixelsPerDay,
          x: labelsArray.reduce((width, label) => width + label.width, 0),
          label: monthName,
        },
      ];
    },
    [] as MonthSegment[],
  );
}

function fitSelectedDateRange(range: DateRange, selected: DateRange) {
  const selectedDuration = Math.abs(
    differenceInDays(selected.start, selected.end),
  );

  const newSelected = { ...selected };

  if (newSelected.start.getTime() > range.end.getTime()) {
    newSelected.start = subDays(range.end, selectedDuration);
    newSelected.end = range.end;
  }

  if (newSelected.end.getTime() < range.start.getTime()) {
    newSelected.start = range.start;
    newSelected.end = addDays(range.start, selectedDuration);
  }

  if (newSelected.start.getTime() < range.start.getTime()) {
    newSelected.start = range.start;
  }

  if (newSelected.end.getTime() > range.end.getTime()) {
    newSelected.end = range.end;
  }

  // If there were no adjustments, then make sure we return the same object.
  return selected.start.getTime() === newSelected.start.getTime() &&
    selected.end.getTime() === newSelected.end.getTime()
    ? selected
    : newSelected;
}

export default function useDateRangeSelection({
  dateRange,
  initialSelectedDateRange,
  sticky = false,
  onSelectedDateRangeChange,
  onSelectedDateRangeChangeLive,
}: {
  dateRange: DateRange;
  initialSelectedDateRange: DateRange;
  sticky?: boolean;
  onSelectedDateRangeChange?: (dateRange: DateRange) => void;
  onSelectedDateRangeChangeLive?: (dateRange: DateRange) => void;
}) {
  // Container reference
  const elRef = useRef<Element | null>(null);

  const onSelectedDateRangeChangeRef = useRef(onSelectedDateRangeChange);
  useEffect(() => {
    onSelectedDateRangeChangeRef.current = onSelectedDateRangeChange;
  }, [onSelectedDateRangeChange]);

  const onSelectedDateRangeChangeLiveRef = useRef(
    onSelectedDateRangeChangeLive,
  );
  useEffect(() => {
    onSelectedDateRangeChangeLiveRef.current = onSelectedDateRangeChangeLive;
  }, [onSelectedDateRangeChangeLive]);

  // Internal state for size and position of the selection window, kept in
  // pixels rather than dates for simplicity.
  const [size, setSize] = useState({ start: 0, end: 0 });

  // Also keep a reference to the date range window.
  const [, setSelectedDateRange] = useState<DateRange>(
    initialSelectedDateRange,
  );

  const [isSticky, setIsSticky] = useState<{ start: boolean; end: boolean }>(
    () => ({
      start:
        initialSelectedDateRange.start.getTime() === dateRange.start.getTime(),
      end: initialSelectedDateRange.end.getTime() === dateRange.end.getTime(),
    }),
  );

  // Observed container ref and size
  const { ref: containerRef, width: containerWidth } = useResizeObserver();

  // useResizeObserver doesn't return a full bounds object, so we will get our
  // own whenever it updates.
  const containerBounds = useMemo(
    () =>
      elRef.current && containerWidth
        ? elRef.current.getBoundingClientRect()
        : null,
    [containerWidth],
  );

  // Combine the observed container ref and our ref.
  const ref = useCallback(
    (el: Element | null) => {
      containerRef(el);
      elRef.current = el;
    },
    [containerRef],
  );

  const [monthSegments, setMonthSegments] = useState<MonthSegment[]>([]);
  const [meta, setMeta] = useState<Meta | null>(null);

  //
  // Helper methods for pixel->date->pixel translation
  const pixelToDate = useCallback(
    (pixel: number): Date => {
      if (!containerWidth) return new Date();

      const { start, end } = dateRange;

      const startTime = start.getTime();
      const endTime = end.getTime();

      const pixelWidth = endTime - startTime;
      const location = pixel / containerWidth;
      const timeOffset = pixelWidth * location;

      return new Date(startTime + timeOffset);
    },
    [containerWidth, dateRange],
  );

  const dateToPixel = useCallback(
    (date: Date): number => {
      if (!containerWidth) return 0;

      const { start, end } = dateRange;

      const startTime = start.getTime();
      const endTime = end.getTime();

      const timeWidth = endTime - startTime;
      const location = (date.getTime() - startTime) / timeWidth;
      const pixelOffset = containerWidth * location;

      return pixelOffset;
    },
    [containerWidth, dateRange],
  );

  const onLeftGripPointerDown: PointerEventHandler = useCallback(() => {
    let d: DateRange;

    const pointerMove = (e: PointerEvent) => {
      e.preventDefault();

      setSize((size) => {
        const newSize = { ...size };
        newSize.start = e.clientX - (containerBounds?.x ?? 0);

        if (newSize.start < 0) newSize.start = 0;
        if (newSize.start > (containerWidth ?? 0))
          newSize.start = containerWidth ?? 0;
        if (newSize.start > newSize.end) newSize.end = newSize.start;

        const newDateRangeWindow = {
          start: pixelToDate(newSize.start),
          end: pixelToDate(newSize.end),
        };

        setSelectedDateRange(newDateRangeWindow);

        d = newDateRangeWindow;

        onSelectedDateRangeChangeLiveRef.current?.(d);

        return newSize;
      });
    };

    const pointerUp = () => {
      document.removeEventListener("pointermove", pointerMove);
      document.removeEventListener("pointerup", pointerUp);

      if (d) {
        setIsSticky((current) => ({
          ...current,
          start: d.start.getTime() === dateRange.start.getTime(),
        }));

        onSelectedDateRangeChangeRef.current?.(d);
      }
    };

    document.addEventListener("pointermove", pointerMove);
    document.addEventListener("pointerup", pointerUp);
  }, [containerBounds?.x, containerWidth, dateRange.start, pixelToDate]);

  const onRightGripPointerDown = useCallback(() => {
    let d: DateRange;

    const pointerMove = (e: PointerEvent) => {
      e.preventDefault();

      setSize((size) => {
        const newSize = { ...size };
        newSize.end = e.clientX - (containerBounds?.x ?? 0);

        if (newSize.end > (containerWidth ?? 0))
          newSize.end = containerWidth ?? 0;
        if (newSize.end < 0) newSize.end = 0;
        if (newSize.end < newSize.start) newSize.start = newSize.end;

        const newDateRangeWindow = {
          start: pixelToDate(newSize.start),
          end: pixelToDate(newSize.end),
        };

        setSelectedDateRange(newDateRangeWindow);

        d = newDateRangeWindow;

        onSelectedDateRangeChangeLiveRef.current?.(d);

        return newSize;
      });
    };

    const pointerUp = () => {
      document.removeEventListener("pointermove", pointerMove);
      document.removeEventListener("pointerup", pointerUp);

      if (d) {
        setIsSticky((current) => ({
          ...current,
          end: d.end.getTime() === dateRange.end.getTime(),
        }));

        onSelectedDateRangeChangeRef.current?.(d);
      }
    };

    document.addEventListener("pointermove", pointerMove);
    document.addEventListener("pointerup", pointerUp);
  }, [containerBounds?.x, containerWidth, dateRange, pixelToDate]);

  const onScrollWindowPointerDown: PointerEventHandler<HTMLElement> =
    useCallback(
      (e) => {
        e.preventDefault();

        let d: DateRange;

        if (e.target !== e.currentTarget) return;
        if (!containerBounds) return;

        const pt = e.clientX - containerBounds.x;

        const offsets = {
          start: size.start - pt,
          end: size.end - pt,
        };

        const pointerMove = (evt: PointerEvent) => {
          evt.preventDefault();

          if (!elRef.current || !containerBounds) return;

          setSize((size) => {
            const newSize = { ...size };

            newSize.start =
              evt.clientX - (containerBounds?.x ?? 0) + offsets.start;
            newSize.end = evt.clientX - (containerBounds?.x ?? 0) + offsets.end;

            if (newSize.end > (containerBounds.width ?? 0)) {
              newSize.start -= evt.movementX;
              newSize.end = containerBounds.width ?? 0;
            }

            if (newSize.start < 0) {
              newSize.start = 0;
              newSize.end -= evt.movementX;
            }

            const newDateRangeWindow = {
              start: pixelToDate(newSize.start),
              end: pixelToDate(newSize.end),
            };

            setSelectedDateRange(newDateRangeWindow);

            d = newDateRangeWindow;

            onSelectedDateRangeChangeLiveRef.current?.(d);

            return newSize;
          });
        };

        const pointerUp = () => {
          document.removeEventListener("pointermove", pointerMove);
          document.removeEventListener("pointerup", pointerUp);

          if (d) {
            setIsSticky({
              start: d.start.getTime() === dateRange.start.getTime(),
              end: d.end.getTime() === dateRange.end.getTime(),
            });

            onSelectedDateRangeChangeRef.current?.(d);
          }
        };

        document.addEventListener("pointermove", pointerMove);
        document.addEventListener("pointerup", pointerUp);
      },
      [containerBounds, dateRange, pixelToDate, size],
    );

  const scrollWindowProps_style = useMemo(
    () =>
      ({
        position: "absolute",
        top: 0,
        left: 0,
        width: size.end - size.start,
        height: "100%",
        userSelect: "none",

        transform: `translate3d(${size.start}px, 0, 0)`,
      }) as CSSProperties,
    [size.end, size.start],
  );

  const scrollWindowProps = useMemo(
    () => ({
      style: scrollWindowProps_style,
      onPointerDown: onScrollWindowPointerDown,
    }),
    [onScrollWindowPointerDown, scrollWindowProps_style],
  );

  const leftGripProps = useMemo(
    () => ({
      style: { position: "absolute", top: 0, left: 0 } as CSSProperties,
      onPointerDown: onLeftGripPointerDown,
    }),
    [onLeftGripPointerDown],
  );

  const rightGripProps = useMemo(
    () => ({
      style: { position: "absolute", top: 0, right: 0 } as CSSProperties,
      onPointerDown: onRightGripPointerDown,
    }),
    [onRightGripPointerDown],
  );

  // All calculations when the date range changes
  useEffect(() => {
    const { start, end } = dateRange;
    const startYear = start.getUTCFullYear();
    const endYear = end.getUTCFullYear();

    const startMonth = start.getMonth();
    const endMonth = end.getMonth();

    const numberOfMonths =
      12 * (endYear - startYear) + (endMonth - startMonth) + 1;

    const dayCount = Math.abs(differenceInDays(start, end));
    const pixelsPerDay = (containerWidth ?? 0) / dayCount;

    const stuff: Meta = {
      pixelsPerDay,
      dayCount,
      numberOfMonths,
      startMonth,
      endMonth,
    };

    setSelectedDateRange((current) => {
      if (!current) return current;

      const newSelectedDateRange = fitSelectedDateRange(dateRange, current);

      if (newSelectedDateRange.end.getTime() === dateRange.end.getTime())
        setIsSticky((current) => ({ ...current, end: true }));

      if (sticky && isSticky.start) {
        newSelectedDateRange.start = dateRange.start;
      } else if (
        newSelectedDateRange.start.getTime() === dateRange.start.getTime()
      ) {
        setIsSticky((current) => ({ ...current, start: true }));
      }

      if (sticky && isSticky.end) {
        newSelectedDateRange.end = dateRange.end;
      } else if (
        newSelectedDateRange.end.getTime() === dateRange.end.getTime()
      ) {
        setIsSticky((current) => ({ ...current, end: true }));
      }

      const newSize = {
        start: dateToPixel(newSelectedDateRange.start),
        end: dateToPixel(newSelectedDateRange.end),
      };

      setSize(newSize);

      onSelectedDateRangeChangeRef.current?.(newSelectedDateRange);

      return newSelectedDateRange;
    });

    setMonthSegments(findMonthSegments(dateRange, stuff));
    setMeta(stuff);
  }, [
    containerWidth,
    dateRange,
    dateToPixel,
    isSticky.end,
    isSticky.start,
    sticky,
  ]);

  return {
    meta,
    size,
    monthSegments,
    containerProps: {
      ref,
      // style: {
      //   position: "relative",
      //   userSelect: "none",
      // } as CSSProperties,
    },
    scrollWindowProps,
    leftGripProps,
    rightGripProps,
  };
}
