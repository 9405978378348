import { useFormContext } from "react-hook-form";

import ErrorWell from "@components/ErrorWell";
import { WizardStepContainer } from "@components/ModalWizardHelper";
import { AedReplaced, Battery } from "@components/WizardIcons";
import WizardOption from "@components/WizardOption";
import WizardOptionSeparator from "@components/WizardOptionSeparator";
import { ModalDescription, UnstyledList } from "@design/helpers";
import { ToolReplacementForm } from "@util/viewModel";

const StartStep = () => {
  const { formState, register } = useFormContext<ToolReplacementForm>();

  return (
    <WizardStepContainer>
      <ModalDescription>
        {formState.errors.root && (
          <ErrorWell>{formState.errors.root.message}</ErrorWell>
        )}
        Select whether you need a replacement sent, or not.
      </ModalDescription>

      <UnstyledList
        style={{
          padding: "16px 64px",
          display: "grid",
          alignContent: "center",
        }}
      >
        <li>
          <WizardOption
            title="Send Replacement"
            description={
              "This replacement is needed. It will be shipped to you as soon as possible."
            }
            image={<AedReplaced />}
            type="radio"
            value={"needed"}
            {...register("choice")}
          />
        </li>
        <li role="presentation">
          <WizardOptionSeparator>Or</WizardOptionSeparator>
        </li>
        <li>
          <WizardOption
            title="Replacement Not Needed"
            description={
              "This replacement is not needed. You will be asked to provide a reason."
            }
            image={<Battery />}
            type="radio"
            value={"not-needed"}
            {...register("choice")}
          />
        </li>
      </UnstyledList>
    </WizardStepContainer>
  );
};

export default StartStep;
