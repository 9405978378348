import color from "color";
import styled from "styled-components";

const QuantityContainer = styled.div<{ color?: string }>`
  display: grid;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  line-height: 1;
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) =>
    color(theme.palettes.buttons.neutral.foreground)
      .mix(color(theme.palettes.buttons.neutral.background), 0.5)
      .toString()};

  border: 0;
  border-radius: 16px;

  background: ${({ theme }) =>
    color(theme.palettes.buttons.neutral.foreground)
      .mix(color(theme.palettes.body.background), 0.9)
      .toString()};
`;

export default QuantityContainer;
