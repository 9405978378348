import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { UnstyledList } from "@design/helpers";
import DS from "@design/system";
import { numberToWord } from "@lang/lang";
import { useIncidents } from "@state/hooks";

import { INCIDENTS_OVER_DAYS } from "../util/incidents";
import ErrorWell from "./ErrorWell";
import Icon from "./Icon";
import IncidentCard, { IncidentCardPlaceholder } from "./IncidentCard";
import Linkable from "./Linkable";
import Message from "./Message";

const IncidentsRow = ({
  storeId,
  groupId,
  showStores = false,
  onIncidentClick,
  unit,
}: {
  storeId?: string;
  groupId?: string;
  showStores?: boolean;
  unit?: Api.Unit;

  onIncidentClick: (incident: Api.Incident) => void;
}) => {
  const { t } = useTranslation();

  const incidents = useIncidents({
    numberDays: INCIDENTS_OVER_DAYS,
    storeId,
    groupId,
  });

  const recentIncidents = useMemo(
    () =>
      incidents.data
        ? incidents.data
            .filter((incident) =>
              unit && unit.UnitName
                ? incident.primaryEvent?.unitName === unit.UnitName
                : true,
            )
            .filter(
              (incident) =>
                incident.incidentOverallSeverity !== "Low" ||
                incident.severityLevel !== "Low",
            )
        : [],
    [incidents.data, unit],
  );

  return incidents.isLoading ? (
    <UnstyledList horizontal gutter={DS.margins.regularN}>
      <IncidentCardPlaceholder />
      <IncidentCardPlaceholder />
      <IncidentCardPlaceholder />
    </UnstyledList>
  ) : incidents.isError ? (
    <ErrorWell
      style={{ margin: DS.margins.regularCss("rl"), textAlign: "center" }}
    >
      <Icon name="exclamation-alt-circle" /> There was a problem loading
      {t("term.incident_other").toLowerCase()}.
    </ErrorWell>
  ) : !recentIncidents.length ? (
    <div style={{ margin: DS.margins.regularCss("rl") }}>
      <Message type="success">
        No <strong>{t("severity.high").toLowerCase()}</strong> or{" "}
        <strong>{t("severity.medium").toLowerCase()}</strong>{" "}
        {t("term.incident_other").toLowerCase()} in the last{" "}
        {numberToWord(INCIDENTS_OVER_DAYS)} days for this{" "}
        {storeId ? `${t("term.store_one").toLowerCase()}` : "group"}.
      </Message>
    </div>
  ) : (
    <UnstyledList horizontal gutter={DS.margins.regularN}>
      {recentIncidents.map((incident) => (
        <li key={incident.incidentId}>
          <Linkable border="big" onClick={() => onIncidentClick(incident)}>
            <IncidentCard incident={incident} showStore={showStores} />
          </Linkable>
        </li>
      ))}
    </UnstyledList>
  );
};

export default IncidentsRow;
