import { useMemo } from "react";
import styled from "styled-components";

import { useUnit } from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";

import Icon from "./Icon";

const Container = styled.div<{ size: number }>`
  width: ${({ size }) => size / 1.25}px;
  height: ${({ size }) => size}px;

  font-size: ${({ size }) => size / 5}px;
  font-weight: 700;

  border-radius: ${({ size }) => size / 8}px;

  display: grid;
  gap: ${({ size }) => size / 20}px;
  align-content: center;
  justify-items: center;
`;

const UnitTypeIcon = ({ unitId, size }: { unitId: string; size: number }) => {
  const { unitTypeIcon, readinessPalette } = useThemeHelper();

  const { data: unit } = useUnit(unitId);

  const palette = useMemo(
    () => readinessPalette(unit?.ReadinessPercentage ?? -1),
    [readinessPalette, unit?.ReadinessPercentage],
  );

  if (!unit) return <div>E</div>;

  return (
    <Container
      size={size}
      style={{
        ...palette.toCss(),
        border: "solid 1px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Icon
        name={unitTypeIcon(unit.UnitType)}
        size={size / 2.67}
        color={palette.foreground}
      />
    </Container>
  );
};

export default UnitTypeIcon;
