import color from "color";
import { MouseEventHandler, useMemo } from "react";
import styled from "styled-components";

import DS from "@design/system";
import { BasePalette } from "@themes/types";
import { useThemeHelper } from "@util/useThemeHelper";

import { useTooltip } from "./Tooltip";

const STAT_SIZE = 80;

const Container = styled.button<{ palette: BasePalette }>`
  box-sizing: border-box;
  width: ${STAT_SIZE}px;
  height: ${STAT_SIZE}px;
  padding: 0;

  cursor: pointer;
  line-height: 1;
  text-align: center;
  color: ${({ palette }) => palette.foreground};

  border: solid ${DS.margins.micro};
  border-color: ${({ theme, palette }) =>
    color(palette.background)
      .mix(color(theme.palettes.body.background), 0.5)
      .string()};
  border-radius: ${STAT_SIZE - DS.margins.regularN}px;
  background: ${({ palette }) => palette.background};

  display: grid;
  align-content: center;

  &:hover {
    border-color: ${({ theme, palette }) =>
      color(palette.background)
        .mix(color(theme.palettes.body.background), 0.25)
        .string()};
    background: ${({ palette }) => palette.background};
  }

  &:active {
    transform: translateY(1px);
  }
`;

const StatCircle = ({
  title,
  number,
  label,
  type,
  onClick,
}: {
  title?: string;
  number: string | number;
  label?: string;
  type?: Api.EventSeverityLevel;
  onClick?: MouseEventHandler;
}) => {
  const { severityPalette } = useThemeHelper();

  const { tooltipProps } = useTooltip<HTMLButtonElement>(title);

  const palette = useMemo(() => severityPalette(type), [severityPalette, type]);

  return (
    <Container
      {...tooltipProps}
      aria-label={title}
      palette={palette}
      onClick={onClick}
    >
      <span style={{ fontSize: 28, fontWeight: 700 }}>{number}</span>
      <span style={{ fontSize: 12, fontWeight: 400 }}>{label}</span>
    </Container>
  );
};

export default StatCircle;
