import { useState } from "react";

import { ls } from "./localStorage";

export function useLocalStorage<T extends unknown>(
  key: string,
  initialValue: T,
): [T, (value: T | ((value: T) => T)) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = ls.getItem(key);

    if (!item) return initialValue;

    try {
      return JSON.parse(item) as T;
    } catch (e) {
      return item as unknown as T;
    }
  });

  const setValue = (value: T | ((value: T) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;

    // Store raw value in state
    setStoredValue(valueToStore);

    // And make sure it's a string for Local Storage.
    ls.setItem(
      key,
      typeof valueToStore === "string"
        ? valueToStore
        : JSON.stringify(valueToStore),
    );
  };

  return [storedValue, setValue];
}
