import color from "color";
import styled from "styled-components";

import Icon from "./Icon";
import { useTooltip } from "./Tooltip";

const Button = styled.button`
  display: block;
  width: 24px;
  height: 24px;
  padding: 4px;
  margin: 0;

  appearance: none;
  cursor: pointer;
  font-size: 16px;
  color: ${({ theme }) =>
    color(theme.palettes.buttons.neutral.foreground)
      .mix(color(theme.palettes.buttons.neutral.background), 0.5)
      .toString()};

  background: ${({ theme }) =>
    color(theme.palettes.buttons.neutral.foreground)
      .mix(color(theme.palettes.body.background), 0.9)
      .toString()};
  border: 0;
  border-radius: 12px;

  & > span {
    display: block;
  }

  &:hover {
    color: ${({ theme }) =>
      color(theme.palettes.buttons.neutral.foreground)
        .mix(color(theme.palettes.buttons.neutral.background), 0.25)
        .toString()};
    background: ${({ theme }) =>
      color(theme.palettes.buttons.neutral.foreground)
        .mix(color(theme.palettes.body.background), 0.8)
        .toString()};
  }

  &:active {
    color: ${({ theme }) =>
      color(theme.palettes.buttons.neutral.foreground)
        .mix(color(theme.palettes.buttons.neutral.background), 0.15)
        .toString()};
    background: ${({ theme }) =>
      color(theme.palettes.buttons.neutral.foreground)
        .mix(color(theme.palettes.body.background), 0.7)
        .toString()};
  }
`;

const CloseButton = ({
  title,
  onClick,
}: {
  title?: string;
  onClick: () => void;
}) => {
  const { tooltipProps } = useTooltip<HTMLButtonElement>(title || "Close", {
    delay: 1000,
  });

  return (
    <Button
      {...tooltipProps}
      type="button"
      aria-label={title || "Close"}
      onClick={onClick}
    >
      <Icon name="times" />
    </Button>
  );
};

export default CloseButton;
