import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Marker } from "react-map-gl";
import { useTheme } from "styled-components";

import { FormColumn, LabelledInput } from "@components/FormControls";
import Icon from "@components/Icon";
import MapLocationEditor, {
  LongitudeLatitude,
} from "@components/MapLocationEditor";
import DS from "@design/system";
import { useHasPermission, useStore } from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";

const LocationTab = ({ loading }: { loading: boolean }) => {
  const { t } = useTranslation();
  const { palettes } = useTheme();
  const { icon } = useThemeHelper();

  const { formState, register, setValue, watch } = useFormContext<Api.Unit>();

  const [longitude, latitude, storeId] = watch([
    "Longitude",
    "Latitude",
    "StoreId",
  ]);

  const { hasPermission } = useHasPermission();
  const { data: store } = useStore(storeId);

  const editable = useMemo(
    () => hasPermission("units_edit_unit"),
    [hasPermission],
  );

  const handleLocationChange = useCallback(
    (location: LongitudeLatitude) => {
      setValue("Longitude", location.longitude);
      setValue("Latitude", location.latitude);
    },
    [setValue],
  );

  return (
    <FormColumn>
      <LabelledInput
        label="Location description (optional)"
        placeholder="E.g.: Warehouse 2, upper level"
        disabled={!editable}
        hasError={!!formState.errors?.Location}
        error={formState.errors?.Location?.message}
        loading={loading}
        {...register("Location")}
      />
      <MapLocationEditor
        width={328}
        height={300}
        resetLocation={
          formState.defaultValues &&
          formState.defaultValues.Longitude &&
          formState.defaultValues.Latitude
            ? {
                longitude: formState.defaultValues.Longitude,
                latitude: formState.defaultValues.Latitude,
              }
            : undefined
        }
        isLoading={
          !store ||
          formState.isLoading ||
          latitude === undefined ||
          longitude === undefined
        }
        presets={
          store
            ? [
                {
                  title: `Set to ${t("term.store_one").toLowerCase()} location`,
                  icon: icon("store"),
                  location: {
                    longitude: store.StreetAddress.Longitude,
                    latitude: store.StreetAddress.Latitude,
                  },
                },
              ]
            : undefined
        }
        markers={
          store
            ? [
                <Marker
                  key={store.StoreId}
                  longitude={store.StreetAddress.Longitude}
                  latitude={store.StreetAddress.Latitude}
                >
                  <div
                    style={{
                      padding: DS.margins.nano,
                      borderRadius: DS.radii.pill,
                      display: "grid",
                      placeItems: "center",
                      background: palettes.states.na.background,
                    }}
                  >
                    <Icon
                      name={icon("store")}
                      color={palettes.states.na.foreground}
                      size={16}
                    />
                  </div>
                </Marker>,
              ]
            : undefined
        }
        onLocationChange={handleLocationChange}
      />
    </FormColumn>
  );
};

export default LocationTab;
