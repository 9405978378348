import { useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import BorderBox from "@components/BorderBox";
import HighlightChars from "@components/HighlightChars";
import Icon from "@components/Icon";
import IconButton from "@components/IconButton";
import ItemSelector from "@components/ItemSelector";
import MediaCard from "@components/MediaCard";
import Message from "@components/Message";
import StoreAddress from "@components/StoreAddress";
import DS from "@design/system";
import { useGroupsFlat } from "@state/hooks";

const AccessTab = () => {
  const { t } = useTranslation();
  const { palettes } = useTheme();

  const { data: groups, isLoading } = useGroupsFlat();

  const { control } = useFormContext<Api.User>();
  const roles = useWatch({ control, name: "Roles" });

  const groupsToChooseFrom = useMemo(() => {
    if (!groups || !roles || !roles[0]) return [];

    switch (roles[0].Name) {
      case "Admin":
        return groups.filter((group) => group.Type === "Root");
      case "Consultant":
      case "Supervisor":
        return groups.filter(
          (group) =>
            group.Type === "Franchise" ||
            group.Type === "Adhoc" ||
            group.Type === "SingleStore",
        );
      case "Owner":
        return groups.filter((group) => group.Type === "Franchise");
      case "Local Safety Officer":
        return groups.filter((group) => group.Type === "SingleStore");
    }
  }, [groups, roles]);

  return (
    <div style={{ display: "grid", gap: DS.margins.regular }}>
      <Controller
        control={control}
        name="Groups"
        render={({ field }) => (
          <>
            <ItemSelector
              items={groupsToChooseFrom}
              loading={isLoading}
              getKey={(group) => group.GroupId}
              searchSelector={(group) => group.Name}
              placeholder={`Search for ${t(
                "term.store_other",
              ).toLowerCase()} or groups to add…`}
              itemDisabled={(group) =>
                !!field.value.find((g) => g.GroupId === group.GroupId)
              }
              itemRenderer={(group, positions, isActive) => (
                <div
                  style={{
                    padding: DS.margins.micro,

                    background: isActive ? palettes.body.dim : "transparent",

                    display: "grid",
                    gridTemplateColumns: "1fr auto",
                    alignItems: "center",
                  }}
                >
                  <MediaCard
                    title={
                      <HighlightChars str={group.Name} indices={positions} />
                    }
                    description={
                      group.Type === "SingleStore" ? (
                        <StoreAddress groupId={group.GroupId} />
                      ) : group.Type === "Franchise" ? (
                        "Franchise"
                      ) : (
                        "Group"
                      )
                    }
                  />
                  {field.value.find((g) => g.GroupId === group.GroupId) && (
                    <Icon name="check" />
                  )}
                </div>
              )}
              onItemSelect={(group) =>
                field.onChange(field.value.concat(group))
              }
            />
            <div style={{ display: "grid", gap: DS.margins.micro }}>
              {field.value && field.value.length > 0 ? (
                field.value
                  .sort((a, b) => a.Name.localeCompare(b.Name))
                  .map((group) => (
                    <BorderBox
                      key={group.GroupId}
                      style={{
                        padding: DS.margins.micro,
                        borderRadius: DS.radii.item,
                        display: "grid",
                        gridAutoFlow: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <MediaCard
                        title={group.Name}
                        description={
                          group.Type === "SingleStore" ? "Store" : "Group"
                        }
                      />
                      <span style={{ color: palettes.body.small }}>
                        <IconButton
                          fit="compact"
                          title={`Remove access to ${group.Name}`}
                          buttonType="transparent"
                          icon="times"
                          onClick={() =>
                            field.onChange(
                              field.value.filter(
                                (g) => g.GroupId !== group.GroupId,
                              ),
                            )
                          }
                        />
                      </span>
                    </BorderBox>
                  ))
              ) : (
                <Message type="notice">
                  <strong>This person has no access.</strong> Give them access
                  by adding stores or groups here.
                </Message>
              )}
            </div>
          </>
        )}
      />
    </div>
  );
};

export default AccessTab;
