import { AnimatePresence, useReducedMotion } from "framer-motion";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { isApiResponseError } from "@api";
import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import {
  Overlay,
  Path,
  Step,
  WizardProvider,
  useWizard,
} from "@components/ModalWizardHelper";
import ModalWizardLayout from "@components/ModalWizardLayout";
import WizardSteps from "@components/WizardSteps";
import ReviewStep from "@components/units/replenished/ReviewStep";
import ReviewStepFooter from "@components/units/replenished/ReviewStepFooter";
import StartStep from "@components/units/replenished/StartStep";
import StartStepFooter from "@components/units/replenished/StartStepFooter";
import UploadPhotoStep from "@components/units/replenished/UploadPhotoStep";
import UploadPhotoStepFooter from "@components/units/replenished/UploadPhotoStepFooter";
import { useUnitReplenished } from "@state/hooks";
import { BackForwardCard, Direction } from "@util/animations";
import { AedReplenished } from "@util/viewModel";

const ConfirmAedReplenishedWizardModal = ({
  unitReminderId,
  controllerSerialNumber,
  shipmentId,
  onClose,
}: {
  unitReminderId?: string;
  controllerSerialNumber: string;
  shipmentId: string;
  onClose: () => void;
}) => {
  const shouldReduceMotion = useReducedMotion();
  const { t } = useTranslation();

  const [attemptCancel, setAttemptCancel] = useState(false);
  const [direction, setDirection] = useState<Direction>("forward");

  const { mutate: submitUnitReplenished } = useUnitReplenished(unitReminderId);

  const {
    formState,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
    ...methods
  } = useForm<AedReplenished>({
    defaultValues: {
      ControllerSerialNumber: controllerSerialNumber,
      ShipmentId: shipmentId,
      PhotoBase64: "",

      confirmed: [],
    },
  });

  const path: Path = useMemo(() => ["start", "photo", "review"], []);

  const steps: Step[] = useMemo(
    () => [
      {
        name: "start",
        title: "Replacement",
        Content: <StartStep />,
        Footer: <StartStepFooter onCancel={() => setAttemptCancel(true)} />,
      },

      {
        name: "photo",
        title: "Upload Photo",
        Content: <UploadPhotoStep />,
        Footer: <UploadPhotoStepFooter />,
      },

      {
        name: "review",
        title: "Confirm Replacement",
        Content: <ReviewStep />,
        Footer: <ReviewStepFooter />,
      },
    ],
    [],
  );

  const { step, count, previous, ...rest } = useWizard(path, {
    onStepChange: (_stepName, direction) => setDirection(direction),
  });

  const currentStep = useMemo(
    () => steps.find((s) => s.name === step.name) ?? steps[0],
    [step, steps],
  );

  const onSubmit = useCallback(
    (formEvent?: React.FormEvent) =>
      void handleSubmit(async (data) => {
        // Cheat way of stripping properties
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { confirmed, photoPreview, ...toSave } = data;

        return new Promise((resolve, reject) =>
          submitUnitReplenished(toSave, {
            onSuccess: resolve,
            onError: reject,
          }),
        )
          .then(() => {
            onClose();
            toast.success(<span>{t("term.aed_one")} items replaced</span>);
          })
          .catch((error) => {
            if (isApiResponseError<Api.UnitValidationError>(error)) {
              if (error.error.hasError("generic_error")) {
                setError("root", {
                  message:
                    "The file type attached is not an image. Please ensure you use a valid image file and try again.",
                });
              }

              setError("root", {
                message:
                  "We encountered an issue on our side. Please try again soon.",
              });
            } else {
              setError("root", {
                message:
                  "We encountered an issue on our side. Please try again soon.",
              });
            }
          });
      })(formEvent),
    [handleSubmit, onClose, setError, submitUnitReplenished, t],
  );

  const handleCloseAttempt = useCallback(() => setAttemptCancel(true), []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  if (!step || !direction) return null;

  return (
    <Modal contentLabel="Replacement" onClose={handleCloseAttempt}>
      <FormProvider
        {...{
          formState,
          handleSubmit,
          register,
          reset,
          setError,
          setValue,
          watch,
          ...methods,
        }}
      >
        <WizardProvider {...{ step, count, direction, previous, ...rest }}>
          <form onSubmit={onSubmit}>
            <ModalWizardLayout
              header={
                <div style={{ position: "relative" }}>
                  <ModalHeader
                    title={currentStep.title}
                    backLabel={currentStep.backLabel}
                    onBack={previous}
                    onClose={handleCloseAttempt}
                  />

                  <Overlay show={attemptCancel} />
                </div>
              }
              content={
                <div style={{ position: "relative" }}>
                  <AnimatePresence
                    initial={false}
                    custom={{ direction, reduceMotion: shouldReduceMotion }}
                  >
                    <BackForwardCard
                      key={currentStep.name}
                      custom={{ direction, reduceMotion: shouldReduceMotion }}
                    >
                      {currentStep.Content}
                    </BackForwardCard>
                  </AnimatePresence>
                  <Overlay show={attemptCancel} />
                </div>
              }
              footer={
                <ModalFooter>
                  {attemptCancel ? (
                    <>
                      <p>
                        You have not finished confirming that you have
                        replenished this {t("term.aed_one")}. If you leave now,{" "}
                        <strong>this action will remain open</strong>.
                      </p>

                      <p>Are you sure you want to leave now?</p>

                      <ButtonGroup flow="horizontal">
                        <Button onClick={() => setAttemptCancel(false)}>
                          No, I&apos;m not finished.
                        </Button>
                        <Button buttonType="destructive" onClick={handleClose}>
                          Yes, leave now.
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      {currentStep.Footer}
                      <WizardSteps steps={count} current={step.number} />
                    </>
                  )}
                </ModalFooter>
              }
            />
          </form>
        </WizardProvider>
      </FormProvider>
    </Modal>
  );
};

export default ConfirmAedReplenishedWizardModal;
