import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import DS from "@design/system";
import { useHasPermission } from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";

import ContentLoader from "../util/ContentLoader";
import Icon, { IconName } from "./Icon";

const BORDER_THICKNESS = "3px";

const MenuLink = styled(Link)<{ selected: boolean }>`
  padding: 12px 16px calc(12px - ${BORDER_THICKNESS});

  text-decoration: none;
  color: ${({ theme, selected }) =>
    selected ? theme.palettes.body.foreground : theme.palettes.body.small};
  font-size: 18px;
  font-weight: 400;

  border-bottom: solid ${BORDER_THICKNESS};
  border-bottom-color: ${({ theme, selected }) =>
    selected ? theme.palettes.body.accent : "transparent"};

  display: flex;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.palettes.body.dim};
  }
`;

const MenuButton = ({
  url,
  icon,
  label,
  selected,
}: {
  url: string;
  icon: IconName;
  label: string;
  selected: boolean;
}) => (
  <MenuLink to={url} selected={selected}>
    <Icon name={icon} size={20} />
    <span style={{ marginLeft: DS.margins.micro, whiteSpace: "nowrap" }}>
      {label}
    </span>
  </MenuLink>
);

const MenuButtonPlaceholder = () => (
  <ContentLoader width={130} height={47}>
    <rect x={16} y={14} width={20} height={20} rx={DS.radii.item} />
    <rect x={44} y={17} width={70} height={14} rx={DS.radii.item} />
  </ContentLoader>
);

const MenuContainer = styled.nav`
  display: grid;
  grid-auto-flow: column;
  column-gap: ${DS.margins.regular};
  justify-content: flex-start;
`;

const Menu = ({
  baseUrl,
  currentPage,
  type,
}: {
  baseUrl: string;
  currentPage: string;
  type: "store" | "group" | "allStores";
}) => {
  const { icon } = useThemeHelper();
  const { t } = useTranslation();

  const { hasPermission, isLoading } = useHasPermission();

  return isLoading ? (
    <MenuContainer>
      <MenuButtonPlaceholder />
      <MenuButtonPlaceholder />
      <MenuButtonPlaceholder />
    </MenuContainer>
  ) : (
    <MenuContainer>
      <MenuButton
        url={`${baseUrl}/overview`}
        label={t("menu.overview")}
        icon={icon("overview")}
        selected={currentPage === `${baseUrl}/overview`}
      />
      <MenuButton
        url={`${baseUrl}/devices`}
        label={t("menu.units")}
        icon={icon("device")}
        selected={currentPage === `${baseUrl}/devices`}
      />
      <MenuButton
        url={`${baseUrl}/incidents`}
        label={t("menu.incidents")}
        icon={icon("incident")}
        selected={currentPage === `${baseUrl}/incidents`}
      />
      <MenuButton
        url={`${baseUrl}/shipments`}
        label={t("menu.shipments")}
        icon={icon("shipment")}
        selected={currentPage === `${baseUrl}/shipments`}
      />
      {hasPermission("users_view_store_users") && (
        <MenuButton
          url={`${baseUrl}/people`}
          label={t("menu.people")}
          icon={icon("person")}
          selected={currentPage === `${baseUrl}/people`}
        />
      )}

      {type === "group" && (
        <MenuButton
          url={`${baseUrl}/stores`}
          label={t("menu.stores")}
          icon={icon("store")}
          selected={currentPage === `${baseUrl}/stores`}
        />
      )}
      {type === "store" &&
        hasPermission("stores_view_edit_billing_information") && (
          <MenuButton
            url={`${baseUrl}/billing`}
            label={t("menu.billing")}
            icon={icon("billing")}
            selected={currentPage === `${baseUrl}/billing`}
          />
        )}
      {type === "store" && (
        <MenuButton
          url={`${baseUrl}/settings`}
          label={t("menu.settings")}
          icon={icon("setting")}
          selected={currentPage === `${baseUrl}/settings`}
        />
      )}
    </MenuContainer>
  );
};
export default Menu;
