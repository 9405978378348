import { useCallback, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";

import { UnstyledList } from "@design/helpers";
import DS from "@design/system";

import { ContextMenuItem } from "./ContextMenu";
import ErrorWell from "./ErrorWell";
import GroupHeader, { GroupHeaderControl } from "./GroupHeader";
import Icon from "./Icon";
import Linkable from "./Linkable";
import ListItem, { ListItemsPlaceholder } from "./ListItem";
import SecondaryButton from "./SecondaryButton";

const ListItemRow = styled.li`
  margin-left: ${-DS.margins.microN};
  margin-right: ${-DS.margins.microN};

  &:not(:last-child) {
    padding-bottom: ${DS.margins.nano};
    border-bottom: solid 1px ${({ theme }) => theme.palettes.body.border};
  }
`;

const GroupedList = <T extends unknown>({
  separator = false,
  tight = false,
  title,
  emptyLabel,
  errorLabel = "Error loading data",
  data,
  showMax = 0,
  isLoading,
  isSelected,
  isError,
  onListItemClick,
  listItemKey,
  listItemXpathId,
  listItemTo,
  listItemImage,
  listItemTitle,
  listItemDescription,
  listItemContextMenu,
  controls,
  selectedRef,
}: {
  separator?: boolean;
  tight?: boolean;
  title?: string | null;
  emptyLabel: React.ReactNode;
  errorLabel?: React.ReactNode;
  data: T[] | undefined;
  showMax?: number;
  isLoading: boolean;
  isError: boolean;
  isSelected?: (item: T) => boolean;
  onListItemClick?: (item: T) => void;
  listItemKey: (item: T) => string;
  listItemXpathId?: (item: T) => string;
  listItemTo?: (item: T) => string;
  listItemImage?: (item: T) => React.ReactNode;
  listItemTitle: (item: T) => React.ReactNode;
  listItemDescription: (item: T) => React.ReactNode | string;
  listItemContextMenu?: (item: T) => ContextMenuItem[] | undefined;
  controls?: GroupHeaderControl[];
  selectedRef?: (e: HTMLLIElement) => void;
}) => {
  const { palettes } = useTheme();

  const [showAll, setShowAll] = useState(false);

  const visibleData = useMemo(() => {
    const topData =
      data && !showAll && showMax ? data.slice(0, showMax) : data ?? [];

    if (showAll) return topData;

    if (!isSelected || !data) return topData;

    const selected = data.find(isSelected);

    if (selected && topData.includes(selected)) return topData;

    return selected ? [...topData, selected] : topData;
  }, [data, isSelected, showAll, showMax]);

  const handleShowAllClick = useCallback(() => setShowAll(true), []);
  const handleShowLessClick = useCallback(() => setShowAll(false), []);

  return (
    <section>
      {title && (
        <GroupHeader separator={separator} controls={controls} tight={tight}>
          {title}
        </GroupHeader>
      )}

      {isLoading ? (
        <div
          style={{
            marginLeft: -DS.margins.microN,
            marginRight: -DS.margins.microN,
          }}
        >
          <ListItemsPlaceholder showImage={!!listItemImage} count={3} />
        </div>
      ) : visibleData.length > 0 ? (
        <UnstyledList
          style={{
            borderRadius: 4,
            background: palettes.body.background,
            gap: 4,
          }}
        >
          {visibleData.map((item) => (
            <ListItemRow
              key={listItemKey(item)}
              ref={(el) => {
                if (el && isSelected && isSelected(item)) {
                  selectedRef?.(el);
                }
              }}
              data-xpath-id={listItemXpathId?.(item)}
            >
              <Linkable
                to={listItemTo ? listItemTo(item) : undefined}
                active={isSelected && isSelected(item)}
                onClick={
                  onListItemClick ? () => onListItemClick(item) : undefined
                }
              >
                <ListItem
                  image={listItemImage ? listItemImage(item) : undefined}
                  title={listItemTitle(item)}
                  description={listItemDescription(item)}
                  contextMenu={
                    listItemContextMenu ? listItemContextMenu(item) : undefined
                  }
                  linked={!!listItemTo || !!onListItemClick}
                />
              </Linkable>
            </ListItemRow>
          ))}
        </UnstyledList>
      ) : isError ? (
        <ErrorWell style={{ textAlign: "center" }}>
          <Icon
            name="exclamation-alt-circle"
            color={palettes.messages.error.foreground}
          />{" "}
          {errorLabel}
        </ErrorWell>
      ) : (
        <div style={{ marginTop: tight ? DS.margins.micro : 0 }}>
          {emptyLabel}
        </div>
      )}

      {showMax > 0 && data && data.length > showMax && (
        <div style={{ textAlign: "center" }}>
          {!showAll ? (
            <SecondaryButton onClick={handleShowAllClick}>
              Show all
            </SecondaryButton>
          ) : (
            <SecondaryButton onClick={handleShowLessClick}>
              Show less
            </SecondaryButton>
          )}
        </div>
      )}
    </section>
  );
};

export default GroupedList;
