import { useCallback, useContext, useMemo } from "react";

import { useWizardContext } from "@components/ModalWizardHelper";

import { LastResponseContext } from "../../util/viewModel";
import Button from "../Button";
import Icon from "../Icon";

const AccountCreationFailedTabFooter = () => {
  const { goToStart } = useWizardContext();
  const lastResponse = useContext(LastResponseContext);

  const handleNextClick = useCallback(() => goToStart(), [goToStart]);

  const duplicateEmail = useMemo(
    () => lastResponse && lastResponse.error.hasError("duplicated_email"),
    [lastResponse],
  );

  return duplicateEmail ? (
    <>
      <Button>Reset their password</Button>
      <Button buttonType="progressive" onClick={handleNextClick}>
        <Icon name="arrow-alt-left" /> Use a different email address
      </Button>
    </>
  ) : (
    <>
      <Button buttonType="progressive" onClick={handleNextClick}>
        <Icon name="arrow-alt-left" /> Back to start
      </Button>
    </>
  );
};

export default AccountCreationFailedTabFooter;
