import { Fragment, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTheme } from "styled-components";

import BorderBox from "@components/BorderBox";
import ErrorWell from "@components/ErrorWell";
import MediaCard from "@components/MediaCard";
import Message from "@components/Message";
import { WizardStepContainer } from "@components/ModalWizardHelper";
import Scroller from "@components/Scroller";
import { Link } from "@components/SecondaryButton";
import StoreTime from "@components/StoreTime";
import { WizardOptionPlaceholder } from "@components/WizardOption";
import { MediumText, ModalDescription, UnstyledList } from "@design/helpers";
import DS from "@design/system";
import {
  useStoreFull,
  useUnit,
  useUnitReplenishmentSummary,
} from "@state/hooks";
import { removeDuplicates } from "@util/data";
import { DateFormat } from "@util/dateFormat";
import { ToolReplacementForm } from "@util/viewModel";

const ADDRESS_PARTS: (keyof Api.StreetAddress | (keyof Api.StreetAddress)[])[] =
  ["LocationName", "Line1", "Line2", "City", ["Region", "PostCode"], "Country"];

const addressParts = (address: Api.StreetAddress) =>
  ADDRESS_PARTS.filter((p) => p instanceof Array || !!address[p]).map((p) =>
    p instanceof Array
      ? p.map((ps) => address[ps]).join(", ")
      : String(address[p]),
  );

const ConfirmReplacementNeededStep = () => {
  const { palettes } = useTheme();

  const { formState, watch } = useFormContext<ToolReplacementForm>();

  const [controllerSerialNumber] = watch(["controllerSerialNumber"]);

  const { data: unit } = useUnit(controllerSerialNumber);
  const { data: store } = useStoreFull(unit?.StoreId);
  const {
    data: replenishment,
    isLoading,
    isError,
  } = useUnitReplenishmentSummary(controllerSerialNumber);

  const itemsToReplace = useMemo(
    () =>
      replenishment
        ? removeDuplicates(
            [
              ...replenishment.CloseToExpiryProductsInKit,
              ...replenishment.ExpiredStockStillInKit,
              ...replenishment.ExpiredStockNeedingReplacement,
            ],
            (stock) => stock.UID,
          )
        : [],
    [replenishment],
  );

  return (
    <WizardStepContainer>
      <ModalDescription style={{ padding: "0 64px" }}>
        Replacement items will be delivered to the contact and address listed
        below.
      </ModalDescription>

      <Message type="info">
        poo Please{" "}
        <Link href="mailto:support@cleverfirstaid.com?subject=Update Unit delivery details">
          contact support
        </Link>{" "}
        if these details need updating.
      </Message>

      <Scroller>
        <div
          style={{
            padding: "8px 64px",
            display: "grid",
            gap: 8,
            gridTemplateAreas: "'manager contact' 'address address'",
          }}
        >
          <BorderBox style={{ gridArea: "manager", padding: 8 }}>
            <MediumText
              style={{
                marginBottom: DS.margins.nano,
                fontWeight: 700,
              }}
            >
              Manager
            </MediumText>
            {store?.ContactManager.FullName}
          </BorderBox>

          <BorderBox style={{ gridArea: "contact", padding: 8 }}>
            <MediumText
              style={{
                marginBottom: DS.margins.nano,
                fontWeight: 700,
              }}
            >
              Site Contact
            </MediumText>
            {unit?.ContactUser?.FullName ?? store?.StoreContact.FullName}
          </BorderBox>

          <BorderBox style={{ padding: 8, gridArea: "address" }}>
            <MediumText
              style={{
                marginBottom: DS.margins.nano,
                fontWeight: 700,
              }}
            >
              Delivery Address
            </MediumText>
            {store &&
              addressParts(store?.StreetAddress).map((p, i, arr) => (
                <Fragment key={i}>
                  {p}
                  {i < arr.length - 1 && <br />}
                </Fragment>
              ))}
          </BorderBox>
        </div>
      </Scroller>

      {formState.errors.root && (
        <ErrorWell>{formState.errors.root.message}</ErrorWell>
      )}

      <div style={{ display: "none" }}>
        <Scroller>
          <UnstyledList style={{ padding: "16px 64px" }}>
            {isError ? (
              <Message type="error">
                There was an error loading the replenishment summary.
              </Message>
            ) : isLoading ? (
              <>
                <WizardOptionPlaceholder />
                <WizardOptionPlaceholder />
              </>
            ) : (
              itemsToReplace.map((stock) => (
                <li key={stock.UID}>
                  <MediaCard
                    title={stock.ProductName}
                    description={
                      <span>
                        Expires{" "}
                        <StoreTime
                          storeName={unit?.StoreName ?? ""}
                          dateTime={stock.ExpiryDate}
                          format={DateFormat.short}
                        />
                      </span>
                    }
                    image={
                      <div
                        style={{
                          width: 48,
                          height: 48,

                          backgroundColor: "white",
                          backgroundImage: `url(${stock.ProductImageUrl})`,
                          backgroundSize: 40,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",

                          border: `solid 1px ${palettes.body.border}`,
                          borderRadius: DS.radii.item,
                        }}
                      />
                    }
                  />
                </li>
              ))
            )}
          </UnstyledList>
        </Scroller>
      </div>
    </WizardStepContainer>
  );
};

export default ConfirmReplacementNeededStep;
