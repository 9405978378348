import {
  KeyboardEventHandler,
  ReactEventHandler,
  RefCallback,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export interface FormattedInputProps {
  ref: RefCallback<HTMLInputElement | null>;
  onKeyDown: KeyboardEventHandler;
  onSelect: ReactEventHandler<HTMLInputElement>;
}

type DateFormat = "MMDDYYYY" | "DDMMYYYY";

type FormattedDateSections = "DATE" | "MONTH" | "YEAR";

type Sections = {
  [Property in FormattedDateSections]: { start: number; end: number };
};

const MIN_SERVER_DATE = new Date("1753-01-02");

const DDMMYYYYFormat: Sections = {
  DATE: { start: 0, end: 2 },
  MONTH: { start: 3, end: 5 },
  YEAR: { start: 6, end: 10 },
};

const MMDDYYYYFormat: Sections = {
  MONTH: { start: 0, end: 2 },
  DATE: { start: 3, end: 5 },
  YEAR: { start: 6, end: 10 },
};

class DateSection {
  /**
   * The raw value of the section, without padding, as a string.
   */
  value: number | null;
  remainingInput: number;
  name: string;
  defaultFmt: string;
  defaultLength: number;

  constructor(
    defaultFmt: string,
    defaultLength: number,
    name: string,
    value?: number | null,
  ) {
    this.name = name;
    this.defaultFmt = defaultFmt;
    this.defaultLength = defaultLength;
    this.value = value ?? null;
    this.remainingInput = defaultLength;
  }

  reset() {
    this.value = null;
    this.remainingInput = this.defaultLength;
  }
}

interface State {
  format: DateFormat;
  values: { [key in FormattedDateSections]: DateSection };
  selectedDateSection: FormattedDateSections;
}

interface FormattedInputHandler {
  value?: Date | null;
  onChange?: (value: Date) => void;

  format?: DateFormat;
}

function zeroPadding(length: number, value: number | null) {
  const pad = "0000".substring(0, length);

  if (!value) return pad;

  const ans = pad
    .substring(0, pad.length - value.toString().length)
    .concat(value.toString());

  return ans;
}

function stateToFormattedString(state: State, separator = "/") {
  const date = state.values.DATE;
  const month = state.values.MONTH;
  const year = state.values.YEAR;

  const fmtDate =
    date.value !== null
      ? zeroPadding(date.defaultLength, date.value)
      : date.defaultFmt;

  const fmtMonth =
    month.value !== null
      ? zeroPadding(month.defaultLength, month.value)
      : month.defaultFmt;

  const fmtYear =
    year.value !== null
      ? zeroPadding(year.defaultLength, year.value)
      : year.defaultFmt;

  if (state.format === "DDMMYYYY") {
    return `${fmtDate}${separator}${fmtMonth}${separator}${fmtYear}`;
  } else if (state.format === "MMDDYYYY") {
    return `${fmtMonth}${separator}${fmtDate}${separator}${fmtYear}`;
  } else {
    return "Invalid format";
  }
}

function nextDateSection(
  currentSection: FormattedDateSections,
  format: DateFormat = "DDMMYYYY",
) {
  if (format === "DDMMYYYY") {
    switch (currentSection) {
      case "DATE":
        return "MONTH";
      case "MONTH":
        return "YEAR";
      case "YEAR":
        return "YEAR";
    }
  } else if (format === "MMDDYYYY") {
    switch (currentSection) {
      case "MONTH":
        return "DATE";
      case "DATE":
        return "YEAR";
      case "YEAR":
        return "YEAR";
    }
  } else {
    return "DATE";
  }
}

function previousDateSection(
  currentSection: FormattedDateSections,
  format: DateFormat = "DDMMYYYY",
) {
  if (format === "DDMMYYYY") {
    switch (currentSection) {
      case "DATE":
        return "DATE";
      case "MONTH":
        return "DATE";
      case "YEAR":
        return "MONTH";
    }
  } else if (format === "MMDDYYYY") {
    switch (currentSection) {
      case "MONTH":
        return "MONTH";
      case "DATE":
        return "MONTH";
      case "YEAR":
        return "DATE";
    }
  } else {
    return "YEAR";
  }
}

function dateToState(date: Date, format: DateFormat): State {
  if (isNaN(date.getTime()))
    throw new Error("Tried to apply an invalid date to state");

  return {
    format,
    selectedDateSection: "DATE",
    values: {
      DATE: new DateSection("DD", 2, "date", date.getDate()),
      MONTH: new DateSection("MM", 2, "month", date.getMonth() + 1),
      YEAR: new DateSection("YYYY", 4, "year", date.getFullYear()),
    },
  };
}

/**
 * Convert a given start, into a Date.
 *
 * @param state - The state object to interpret the date from.
 * @param fixDate - If `true`, the date will be adjusted to a real date. For example, if a month value of 13 is given, then adjust the date to Jan of the next year.
 *
 * @return Date
 */
function stateToDate(state: State, fixDate = false): Date {
  const {
    values: { YEAR, MONTH, DATE },
  } = state;

  // Allow the user to enter an invalid date, rather than trying to fix it for
  // them. For example, if the current month is June (06) and the user changes
  // the date to 31 (there is no 31 June) with the intention of changing the
  // month next. Giving an invalid date allows that to happen without an
  // infinite loop of trying to fix the date.

  // Capture the user's intention.
  const intention = `${zeroPadding(
    YEAR.defaultLength,
    YEAR.value,
  )}-${zeroPadding(MONTH.defaultLength, MONTH.value)}-${zeroPadding(
    DATE.defaultLength,
    DATE.value,
  )}`;

  // Creating the date like this will automatically adjust it. We can use this
  // as the adjusted date for comparasion
  const dateAdjusted = new Date(
    YEAR.value ?? 1,
    (MONTH.value ?? 1) - 1,
    DATE.value ?? 1,
  );

  const adjusted = `${zeroPadding(
    YEAR.defaultLength,
    dateAdjusted.getFullYear(),
  )}-${zeroPadding(
    MONTH.defaultLength,
    dateAdjusted.getMonth() + 1,
  )}-${zeroPadding(DATE.defaultLength, dateAdjusted.getDate())}`;

  dateAdjusted.setFullYear(YEAR.value ?? 1);
  dateAdjusted.setMonth((MONTH.value ?? 0) - 1);
  dateAdjusted.setDate(DATE.value ?? 1);

  if (dateAdjusted.getTime() < MIN_SERVER_DATE.getTime()) {
    dateAdjusted.setFullYear(MIN_SERVER_DATE.getFullYear());
    dateAdjusted.setMonth(MIN_SERVER_DATE.getMonth());
    dateAdjusted.setDate(MIN_SERVER_DATE.getDate());
  }

  // Of course, the date adjusting is optional, since some cases we will want
  // to fix the date, such as when the user increases/decreasses the date with
  // up/down arrow keys. If no adjustment is requested, we will return an
  // invalid date.
  return !fixDate && intention !== adjusted
    ? new Date("Invalid date")
    : dateAdjusted;
}

function setSelectionFromState(el: HTMLInputElement, state: State) {
  if (!el || document.activeElement !== el) return;

  const sections =
    state.format === "DDMMYYYY" ? DDMMYYYYFormat : MMDDYYYYFormat;

  const { start, end } = sections[state.selectedDateSection];

  el.setSelectionRange(start, end);
}

const US_FORMAT = new Date(2000, 12, 20)
  .toLocaleString(navigator.language)
  .startsWith("20")
  ? false
  : true;

export const useFormattedInput = ({
  value,
  onChange,
  format = US_FORMAT ? "MMDDYYYY" : "DDMMYYYY",
}: FormattedInputHandler) => {
  const elRef = useRef<HTMLInputElement | null>(null);

  // State holds our current cursor and selection state, as well as all the
  // section values used to make the formatted or unformatted value.
  const [state, setState] = useState<State>(() =>
    value
      ? dateToState(value, format)
      : {
          format,
          selectedDateSection: format.startsWith("DD") ? "DATE" : "MONTH",
          values: {
            DATE: new DateSection("DD", 2, "date"),
            MONTH: new DateSection("MM", 2, "month"),
            YEAR: new DateSection("YYYY", 4, "year"),
          },
        },
  );

  const handleKeyDown: KeyboardEventHandler = useCallback(
    (e) => {
      if (!elRef.current) return;
      if (e.key !== "Tab") e.preventDefault();

      const newState: State = { ...state };

      if (e.key === "Delete" || e.key === "Backspace") {
        newState.values[newState.selectedDateSection].value = null;
        newState.values[newState.selectedDateSection].remainingInput =
          newState.values[newState.selectedDateSection].defaultLength;
      } else if (isNaN(parseInt(e.key, 10))) {
        let fix = false;
        let num: number | null;

        switch (e.key) {
          case "/":
          case "ArrowRight":
            newState.values[newState.selectedDateSection].remainingInput =
              newState.values[newState.selectedDateSection].defaultLength;

            newState.selectedDateSection = nextDateSection(
              state.selectedDateSection,
              format,
            );

            if (
              newState.selectedDateSection === "YEAR" &&
              !!newState.values.DATE.value &&
              !!newState.values.MONTH.value
            ) {
              const fixedDate = stateToDate(newState, true);
              newState.values["YEAR"].value = fixedDate.getFullYear();
              newState.values["MONTH"].value = fixedDate.getMonth() + 1;
              newState.values["DATE"].value = fixedDate.getDate();
            }

            break;
          case "ArrowLeft":
            newState.values[newState.selectedDateSection].remainingInput =
              newState.values[newState.selectedDateSection].defaultLength;

            newState.selectedDateSection = previousDateSection(
              state.selectedDateSection,
              format,
            );

            break;
          case "ArrowDown":
            num = newState.values[newState.selectedDateSection].value;

            newState.values[newState.selectedDateSection].remainingInput =
              newState.values[newState.selectedDateSection].defaultLength;

            // Blindly adjust the date. We will fix it soon.
            newState.values[newState.selectedDateSection].value =
              (num ?? 0) - 1;

            fix = true;

            break;
          case "ArrowUp":
            num = newState.values[newState.selectedDateSection].value;

            newState.values[newState.selectedDateSection].remainingInput =
              newState.values[newState.selectedDateSection].defaultLength;

            // Blindly adjust the date. We will fix it soon.
            newState.values[newState.selectedDateSection].value =
              (num ?? 0) + 1;

            fix = true;

            break;
          case "Tab":
            fix = true;
            break;
        }

        if (fix) {
          const fixedDate = stateToDate(newState, true);
          newState.values["YEAR"].value = fixedDate.getFullYear();
          newState.values["MONTH"].value = fixedDate.getMonth() + 1;
          newState.values["DATE"].value = fixedDate.getDate();
        }
      } else {
        let fix = false;

        // Entered a number from 0-9
        const { selectedDateSection, values } = newState;

        const section = values[selectedDateSection];

        if (section.remainingInput == section.defaultLength) {
          section.value = parseInt(e.key, 10);
          section.remainingInput -= 1;
        } else if (section.remainingInput > 0) {
          section.value = parseInt(`${section.value ?? 0}${e.key}`, 10);
          section.remainingInput -= 1;
        }

        if (section.remainingInput === 0) {
          section.remainingInput = section.defaultLength;

          newState.selectedDateSection = nextDateSection(
            selectedDateSection,
            format,
          );

          if (newState.selectedDateSection === "YEAR") fix = true;
          if (fix) {
            const fixedDate = stateToDate(newState, true);
            newState.values["YEAR"].value = fixedDate.getFullYear();
            newState.values["MONTH"].value = fixedDate.getMonth() + 1;
            newState.values["DATE"].value = fixedDate.getDate();
          }
        }
      }

      const d = stateToDate(newState);
      setState(newState);

      if (
        newState.values.YEAR.value &&
        newState.values.YEAR.value > MIN_SERVER_DATE.getFullYear() &&
        newState.values.MONTH.value &&
        newState.values.DATE.value
      ) {
        onChange?.(d);
      }

      if (elRef.current) {
        elRef.current.value = stateToFormattedString(newState);
        setSelectionFromState(elRef.current, newState);
      }
    },
    [format, onChange, state],
  );

  const handleSelect: ReactEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (!elRef.current) return;

      e.preventDefault();

      const { selectionStart: start } = elRef.current;

      if (start === null) return;

      let section: keyof Sections;
      let selectedSection: FormattedDateSections | null = "YEAR";

      const sections = format === "DDMMYYYY" ? DDMMYYYYFormat : MMDDYYYYFormat;

      for (section in sections) {
        if (
          start >= sections[section].start &&
          start <= sections[section].end
        ) {
          selectedSection = section;
          break;
        }
      }

      if (selectedSection === null) return;

      if (selectedSection === null) return state;
      if (
        selectedSection === state.selectedDateSection &&
        (elRef.current?.selectionEnd ?? 0) -
          (elRef.current?.selectionStart ?? 0) !==
          0
      )
        return;

      const newState = { ...state };
      newState.selectedDateSection = selectedSection;

      const newSection = newState.values[selectedSection];
      newSection.remainingInput = newSection.defaultLength;

      if (elRef.current) setSelectionFromState(elRef.current, newState);

      setState(newState);
    },
    [format, state],
  );

  const formatProps = useMemo<FormattedInputProps>(
    () => ({
      ref: (el) => (elRef.current = el),
      onKeyDown: handleKeyDown,
      onSelect: handleSelect,
    }),
    [handleKeyDown, handleSelect],
  );

  // Update the internal state when the date value changes externally
  useEffect(() => {
    setState((current) => {
      if (!value || isNaN(value.getTime())) {
        if (elRef.current)
          elRef.current.value = stateToFormattedString(current);

        return current;
      }

      const currentDate = stateToDate(current);

      if (
        !isNaN(currentDate.getTime()) &&
        value.toISOString() === currentDate.toISOString()
      ) {
        if (elRef.current)
          elRef.current.value = stateToFormattedString(current);

        return current;
      }

      const newState: State = {
        ...current,
        values: { ...dateToState(value, format).values },
      };

      if (elRef.current) {
        elRef.current.value = stateToFormattedString(newState);

        setSelectionFromState(elRef.current, newState);
      }

      return newState;
    });
  }, [format, value]);

  return { formatProps };
};
