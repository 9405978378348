import { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import Button from "@components/Button";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";
import { numberToWord } from "@lang/lang";

const getMessage = (groups: Api.GroupFlat[]) => {
  if (!groups || !groups.length) {
    return <span>None selected.</span>;
  }

  if (groups.length === 1) {
    return <span>One selected.</span>;
  }

  return <span>{numberToWord(groups.length, true)} selected.</span>;
};

const AccessTabFooter = () => {
  const { control } = useFormContext<Api.User>();
  const { goTo } = useWizardContext();

  const [groups] = useWatch({ control, name: ["Groups"] });

  const handleNextClick = useCallback(() => goTo("review", true), [goTo]);

  return (
    <>
      <p>{getMessage(groups)}</p>
      <Button
        buttonType="progressive"
        disabled={groups.length === 0}
        onClick={handleNextClick}
      >
        Next, review and confirm <Icon name="arrow-alt-right" />
      </Button>
    </>
  );
};

export default AccessTabFooter;
