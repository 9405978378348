import { useFormContext } from "react-hook-form";

import ErrorWell from "@components/ErrorWell";
import { CodeInput } from "@components/FormControls";
import { WizardStepContainer } from "@components/ModalWizardHelper";
import { ModalDescription } from "@design/helpers";

const SuccessMfaModal = () => {
  const { register, formState } = useFormContext<Api.User>();

  return (
    <WizardStepContainer>
      <ModalDescription style={{ padding: "0 64px" }}>
        Enter the 6-digit verification code generated by your authenticator app.
        {formState.errors.root && (
          <ErrorWell>{formState.errors.root?.message}</ErrorWell>
        )}
      </ModalDescription>
      <CodeInput
        digits={6}
        {...register("AuthenticateChangeRequest.SuppliedTOTPCode")}
      />
    </WizardStepContainer>
  );
};

export default SuccessMfaModal;
