import { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTheme } from "styled-components";

const useHelpRedirect = () => {
  const help = useRouteMatch(["/help"]);
  const { links } = useTheme();
  const history = useHistory();

  useEffect(() => {
    const path = history.location;
    const helpUrl = `${links.support.url}/portal/index.html${path.search}${path.hash}`;
    !!help && window.location.replace(helpUrl);
  }, [help, history, links]);
};

export default useHelpRedirect;
