import { sub } from "date-fns";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import useResizeObserver from "use-resize-observer";

import DS from "@design/system";
import ContentLoader from "@util/ContentLoader";
import { bucketIncidentStats } from "@util/incidents";

import { useIncidentStats } from "../state/hooks";
import Chart from "./Chart";

const Container = styled.div<{ height: number; borderRadius: number }>`
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  height: ${({ height }) => height}px;

  background: ${({ theme }) => theme.palettes.well.background};
  border-radius: ${({ borderRadius }) => borderRadius}px;
`;

const ChartLabel = styled.span`
  color: ${({ theme }) => theme.palettes.well.foreground};
  font-size: 12px;
`;

const IncidentsChartPlaceholder = ({
  days,
  height = 144,
  barWidth = 16,
  borderRadius = DS.radii.itemN,
}: {
  days?: number;
  height?: number;
  barWidth?: number;
  borderRadius?: number;
}) => {
  const { ref, width: w, height: h } = useResizeObserver();

  const maxHeight = height - DS.margins.jumboN;
  const [bars] = useState(
    Array.from({ length: days ?? 40 }, () =>
      Math.max(Math.floor(Math.random() * maxHeight), 10),
    ),
  );

  return (
    <Container height={height} borderRadius={borderRadius} ref={ref}>
      <ContentLoader title="Loading event data…" width={w ?? 0} height={h ?? 0}>
        <rect
          x={(w ?? 0) - 120 - 8}
          y="11"
          rx="2"
          ry="2"
          width="120"
          height="10"
        />

        {bars.map((b, i) => (
          <rect
            key={`bar-${i}`}
            x={(w ?? 0) - (barWidth + 3 + 3 - -i * (barWidth + 4))}
            y={(h ?? 0) - b + 4}
            rx="4"
            ry="4"
            width={barWidth}
            height={b}
          />
        ))}
      </ContentLoader>
    </Container>
  );
};

const IncidentsChart = ({
  groupId,
  storeId,
  days = 15,
  height = 144,
  borderRadius = DS.radii.itemN,
}: {
  groupId?: string;
  storeId?: string;
  days?: number;
  height?: number;
  borderRadius?: number;
}) => {
  const { data: incidentStats } = useIncidentStats({
    storeId,
    groupId,
    numberDays: days,
  });
  const { t } = useTranslation();
  const { palettes } = useTheme();

  const buckets = useMemo(
    () =>
      bucketIncidentStats(
        {
          start: sub(new Date(), { days: days - 1 }),
          end: new Date(),
        },
        incidentStats,
      ),
    [days, incidentStats],
  );

  if (!incidentStats) {
    return (
      <IncidentsChartPlaceholder
        days={days}
        height={height}
        borderRadius={borderRadius}
      />
    );
  }

  return (
    <Container height={height} borderRadius={borderRadius}>
      <ChartLabel
        style={{
          position: "absolute",
          top: DS.margins.micro,
          right: DS.margins.micro,
        }}
      >
        {t("term.incident_other")} over last {days} days
      </ChartLabel>

      <div
        style={{
          overflow: "auto",
          position: "absolute",
          top: DS.margins.jumbo,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <Chart
          segments={[
            {
              label: t("severity.low"),
              color: palettes.severity.low.background,
            },
            {
              label: t("severity.medium"),
              color: palettes.severity.medium.background,
            },
            {
              label: t("severity.high"),
              color: palettes.severity.high.background,
            },
          ]}
          emptyLabel={`No ${t("term.incident_other").toLowerCase()}`}
          data={buckets}
          height={height - DS.margins.jumboN}
        />
      </div>
    </Container>
  );
};

export default IncidentsChart;
