import { useCallback, useMemo } from "react";
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import { useTheme } from "styled-components";

import DS from "@design/system";
import { useGroup, useStore, useStores } from "@state/hooks";
import useParamsUpper from "@util/useParamsUpper";
import AllStoresOverview from "src/pages/AllStoresOverview";

import Billing from "../pages/Billing";
import GroupOverview from "../pages/GroupOverview";
import Incidents from "../pages/Incidents";
import People from "../pages/People";
import Shipments from "../pages/Shipments";
import StoreOverview from "../pages/StoreOverview";
import StoreSettings from "../pages/StoreSettings";
import Stores from "../pages/Stores";
import Units from "../pages/Units";
import CloseButton from "./CloseButton";
import { DetailHeader, DetailContent } from "./DetailHeader";
import GroupSwitchButton from "./GroupSwitchButton";
import Menu from "./Menu";
import SwitcherPanel from "./SwitcherPanel";

const DetailView = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { groupId, storeId } = useParamsUpper<{
    groupId: string;
    storeId: string;
  }>();
  const { palettes } = useTheme();

  const { data: allStores } = useStores();
  const { data: store, isLoading: isStoreLoading } = useStore(storeId);
  const { data: group, isLoading: isGroupLoading } = useGroup(groupId);

  const singleStore = useMemo(
    () => allStores && allStores.length === 1,
    [allStores],
  );

  const handleCloseClick = useCallback(() => history.push("/"), [history]);

  if (
    (storeId && !isStoreLoading && !store) ||
    (groupId && !isGroupLoading && !group) ||
    (groupId && group && !group.GroupId)
  ) {
    handleCloseClick();
  }

  return (
    <div
      id="detail"
      style={{
        overflow: "hidden",
        height: "100%",
        display: "grid",
        gridTemplateRows: "auto 1fr",
        gridTemplateColumns: singleStore ? "1fr" : "320px 1fr",
        gridTemplateAreas: singleStore
          ? "'menu' 'content'"
          : "'sidebar menu' 'sidebar content'",
      }}
    >
      <DetailHeader
        id="detail-header"
        style={{
          borderLeft: `solid 1px ${palettes.body.border}`,
          gridArea: "menu",
          display: "grid",
          gridTemplateRows: "auto auto",
          gridTemplateColumns: "1fr auto",
          gridTemplateAreas: "'title close' 'menu menu'",
        }}
      >
        <GroupSwitchButton current={groupId ? group : storeId ? store : true} />
        <div style={{ paddingTop: DS.margins.regular }}>
          <CloseButton onClick={handleCloseClick} />
        </div>
        <div style={{ gridArea: "menu" }}>
          <Menu
            baseUrl={match.url}
            currentPage={history.location.pathname}
            type={groupId ? "group" : storeId ? "store" : "allStores"}
          />
        </div>
      </DetailHeader>

      {!singleStore && (
        <div
          id="detail-navigation"
          style={{ position: "relative", gridArea: "sidebar" }}
        >
          <SwitcherPanel />
        </div>
      )}

      <DetailContent
        id="detail-content"
        style={{
          overflow: "hidden",
          borderLeft: `solid 1px ${palettes.body.border}`,
        }}
      >
        <Switch>
          <Route
            path={`${match.path}/overview`}
            component={
              groupId
                ? GroupOverview
                : storeId
                  ? StoreOverview
                  : AllStoresOverview
            }
          />

          <Route path={`${match.path}/devices`} component={Units} />
          <Route path={`${match.path}/incidents`} component={Incidents} />
          <Route path={`${match.path}/shipments`} component={Shipments} />
          <Route path={`${match.path}/people`} component={People} />

          {groupId && (
            <Route path={`${match.path}/stores`} component={Stores} />
          )}

          {storeId && (
            <Route path={`${match.path}/billing`} component={Billing} />
          )}
          {storeId && (
            <Route path={`${match.path}/settings`} component={StoreSettings} />
          )}

          <Redirect path={`${match.path}/`} to={`${match.path}/overview`} />
          <Redirect path={`${match.path}/*`} to={`${match.path}/overview`} />
        </Switch>
      </DetailContent>
    </div>
  );
};

export default DetailView;
