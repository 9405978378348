import { motion, AnimatePresence } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { mergeRefs, useLayer } from "react-laag";
import styled from "styled-components";

import DS from "@design/system";

import ContextButton from "./ContextButton";
import Icon, { IconName } from "./Icon";
import { useTooltip } from "./Tooltip";

import type { PlacementType } from "react-laag/dist/PlacementType";

export const MenuButton = styled.a`
  inline-size: -webkit-fill-available;
  inline-size: -moz-available;
  inline-size: stretch;

  margin: ${DS.margins.nano};
  padding: ${DS.margins.micro} ${DS.margins.regular};

  appearance: none;
  cursor: pointer;
  color: ${({ theme }) => theme.palettes.body.foreground};
  text-decoration: none;
  text-align: start;

  border: 0;
  border-radius: ${DS.radii.item};
  background: ${({ theme }) => theme.palettes.body.background};

  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  gap: ${DS.margins.micro};

  &:hover {
    background: ${({ theme }) => theme.palettes.body.dim};
  }
`;

export const Separator = styled.li`
  height: 0;
  padding: 0;
  margin: 0;

  border-bottom: solid 1px ${({ theme }) => theme.palettes.body.border};
`;

export const ContextMenuContainer = styled.ul`
  overflow: hidden;
  margin: 0;
  padding: 0;

  list-style: none;

  background: ${({ theme }) => theme.palettes.body.background};
  border: solid 1px ${({ theme }) => theme.palettes.body.border};
  border-radius: ${DS.radii.largeItem};
  box-shadow: ${DS.shadows.dialog};

  li {
    white-space: nowrap;
  }
`;

type ContextMenuSeparator = null;
type ContextMenuButton = {
  key: string;
  icon?: IconName;
  label: string;
  onClick: (close: () => void) => void;
};
type ContextMenuLink = {
  key: string;
  icon?: IconName;
  label: string;
  href: string;
};
export type ContextMenuItem =
  | ContextMenuButton
  | ContextMenuLink
  | ContextMenuSeparator;

export const isContextMenuButton = (
  item?: ContextMenuItem | null,
): item is ContextMenuButton => {
  return !!item && !!item.key && "onClick" in item;
};

export const isContextMenuLink = (
  item?: ContextMenuItem | null,
): item is ContextMenuLink => {
  return !!item && "href" in item;
};

const ContextMenu = ({
  label,
  shape,
  items,
  placement,
  onActiveToggle,
}: {
  label: string;
  shape?: "circle" | "square";
  items: ContextMenuItem[];
  placement?: PlacementType;
  onActiveToggle?: (active: boolean) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => setIsOpen(false), []);

  const {
    tooltipProps: { ref, ...tooltipProps },
  } = useTooltip(label, { delay: 1000 });

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    onOutsideClick: close,
    // onDisappear: close,
    overflowContainer: false,
    auto: true,
    placement,
    triggerOffset: 8,
    containerOffset: 8,
    arrowOffset: 0,
  });

  useEffect(() => {
    onActiveToggle && onActiveToggle(isOpen);
  }, [isOpen, onActiveToggle]);

  return (
    <>
      <ContextButton
        tabIndex={0}
        shape={shape}
        ref={mergeRefs(triggerProps.ref, ref)}
        aria-label={label}
        {...tooltipProps}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <Icon name="ellip-horizontal" />
      </ContextButton>
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <motion.div
              {...layerProps}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <ContextMenuContainer aria-label="Context menu">
                {items.map((item, index) =>
                  isContextMenuButton(item) || isContextMenuLink(item) ? (
                    <li key={item.key}>
                      <MenuButton
                        as={isContextMenuButton(item) ? "button" : "a"}
                        {...(isContextMenuLink(item) && { href: item.href })}
                        {...(isContextMenuButton(item) && {
                          onClick: (e) => {
                            e.stopPropagation();
                            item.onClick(close);
                          },
                        })}
                      >
                        {item.icon && <Icon name={item.icon} />}
                        {item.label}
                      </MenuButton>
                    </li>
                  ) : (
                    <Separator key={`separator-${index}`} />
                  ),
                )}
              </ContextMenuContainer>
            </motion.div>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};

export default ContextMenu;
