import { useCallback, useState } from "react";
import styled from "styled-components";

import DS from "@design/system";
import { useCurrentUser } from "@state/hooks";
import { trackEvent } from "@util/analytics";
import { useLocalStorage } from "@util/useLocalStorage";

import Button from "./Button";
import Icon from "./Icon";
import { Link } from "./SecondaryButton";

const BannerWrapper = styled.div`
  box-sizing: border-box;
  padding: ${DS.margins.micro} ${DS.margins.regular};
  background: ${({ theme }) => theme.palettes.header.background};
  color: ${({ theme }) => theme.palettes.header.foreground};

  display: grid;
  grid-template-columns: [BannerContainer] auto [Dismiss] max-content;
  align-items: center;
`;

const BannerContainer = styled.nav`
  text-align: center;
  color: ${({ theme }) => theme.palettes.header.foreground};
`;

const Dismiss = ({ onClick }: { onClick: React.MouseEventHandler }) => (
  <Button buttonType="transparent" size="small" fit="compact" onClick={onClick}>
    Dismiss <Icon name="times" />
  </Button>
);

const Banner = () => {
  const [isVisible, seIsVisible] = useState(true);

  const [dismissedCount, setDismissedCount] = useLocalStorage(
    "dismissCount",
    0,
  );

  const { data: currentUser } = useCurrentUser();

  const handleBackToPortalClick = useCallback(() => {
    trackEvent({
      category: "Button",
      action: "Returned to old portal",
    });

    window.location.assign(import.meta.env.VITE_APP_API_ENDPOINT);
  }, []);

  return currentUser?.lastLoggedInPortalDate &&
    dismissedCount >= 0 &&
    dismissedCount < 3 &&
    isVisible ? (
    <BannerWrapper
      style={{
        display: isVisible ? "grid" : "none",
      }}
    >
      <BannerContainer>
        Welcome to the new portal! You can{" "}
        <Link onClick={handleBackToPortalClick} title="Clever First Aid Portal">
          return to the old portal
        </Link>{" "}
        at anytime.
      </BannerContainer>
      <Dismiss
        onClick={() => {
          seIsVisible(false);
          setDismissedCount((current) => current + 1);
        }}
      />
    </BannerWrapper>
  ) : null;
};
export default Banner;
