import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { ApiResponseError, isApiResponseError } from "@api";
import Button from "@components/Button";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import ModalStandardLayout from "@components/ModalStandardLayout";
import Scroller from "@components/Scroller";
import { Tab, TabList, TabPanel, Tabs } from "@components/Tabs";
import EditUserFailedMessage from "@components/people/EditUserFailedMessage";
import DetailsTab from "@components/units/edit/DetailsTab";
import HistoryTab from "@components/units/edit/HistoryTab";
import LocationTab from "@components/units/edit/LocationTab";
import DS from "@design/system";
import { useHasPermission, useMutateUnit, useUnitFull } from "@state/hooks";
import { LastResponseContext } from "@util/viewModel";

const getTabIndexByName = (
  tab: Parameters<typeof EditUnitModal>[0]["defaultTab"],
) => {
  switch (tab) {
    case "location":
      return 1;

    case "history":
      return 2;

    default:
    case "details":
      return 0;
  }
};

const EditUnitModal = ({
  unitId,
  title,
  defaultTab = "details",
  onClose,
}: {
  unitId: string;
  title?: string;
  defaultTab?: "details" | "location" | "history";
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const { hasPermission } = useHasPermission();
  const { data: unit, isLoading } = useUnitFull(unitId);
  const { mutate: updateUnit } = useMutateUnit();

  const { reset, formState, handleSubmit, ...methods } = useForm<Api.Unit>();
  const [lastResponse, setLastResponse] =
    useState<ApiResponseError<Api.UserValidationError> | null>(null);

  const editable = useMemo(
    () => hasPermission("units_edit_unit"),
    [hasPermission],
  );

  const onSubmit: React.FormEventHandler = useCallback(
    (e) =>
      void handleSubmit(async (data: Api.Unit) => {
        const updatedUnit = {
          ...data,
        } as Api.Unit;

        return new Promise<Api.ResponseSuccess<Api.Unit>>((resolve, reject) =>
          updateUnit(updatedUnit, { onSuccess: resolve, onError: reject }),
        )
          .then((response) => response.SuccessPayload)
          .then((updatedUnit) => {
            if (!updatedUnit) return;
            onClose();
            toast.success(
              <span>
                <strong>{updatedUnit.UnitName}</strong> successfully updated.
              </span>,
            );
          })
          .catch((error) => {
            if (isApiResponseError<Api.UserValidationError>(error)) {
              setLastResponse(error);
            }
          });
      })(e),
    [handleSubmit, onClose, updateUnit],
  );

  useEffect(() => reset(unit), [unit, reset]);

  return (
    <LastResponseContext.Provider value={lastResponse}>
      <Modal contentLabel={t(`modals.editUnit.contentLabel`)} onClose={onClose}>
        <FormProvider {...{ reset, formState, handleSubmit, ...methods }}>
          <form onSubmit={onSubmit}>
            <ModalStandardLayout
              header={
                <ModalHeader
                  title={title || t(`modals.editUnit.title`)}
                  loading={isLoading}
                  onClose={onClose}
                />
              }
              content={
                <div>
                  <Tabs
                    defaultIndex={getTabIndexByName(defaultTab)}
                    style={{
                      display: "grid",
                      gridTemplateRows: "auto auto 1fr",
                      height: "100%",
                    }}
                  >
                    <TabList
                      style={{
                        padding: DS.margins.regularCss("rl"),
                      }}
                    >
                      <Tab>Details</Tab>
                      <Tab>Location</Tab>
                      {unit?.UnitType === "AutomatedExternalDefibrillator" && (
                        <Tab>History</Tab>
                      )}
                    </TabList>

                    <Scroller>
                      <div
                        style={{
                          margin: DS.margins.micro,
                          display: "grid",
                        }}
                      >
                        <EditUserFailedMessage response={lastResponse} />

                        <TabPanel>
                          <DetailsTab loading={isLoading} />
                        </TabPanel>
                        <TabPanel>
                          <LocationTab loading={isLoading} />
                        </TabPanel>
                        {unit?.UnitType ===
                          "AutomatedExternalDefibrillator" && (
                          <TabPanel>
                            <HistoryTab />
                          </TabPanel>
                        )}
                      </div>
                    </Scroller>

                    <div
                      style={{
                        display: "grid",
                        height: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <Scroller>
                        <div style={{ padding: DS.margins.regular }}>
                          <TabPanel>
                            <DetailsTab loading={isLoading} />
                          </TabPanel>
                          <TabPanel>
                            <LocationTab loading={isLoading} />
                          </TabPanel>
                          <TabPanel>
                            <HistoryTab />
                          </TabPanel>
                        </div>
                      </Scroller>
                    </div>
                  </Tabs>
                </div>
              }
              footer={
                <ModalFooter>
                  {editable ? (
                    <Button
                      type="submit"
                      buttonType="action"
                      disabled={formState.isSubmitting}
                    >
                      {formState.isSubmitting
                        ? "Saving changes…"
                        : "Save changes"}
                    </Button>
                  ) : (
                    <Button onClick={onClose}>Close</Button>
                  )}
                </ModalFooter>
              }
            />
          </form>
        </FormProvider>
      </Modal>
    </LastResponseContext.Provider>
  );
};

export default EditUnitModal;
