import { useCallback, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import api, { isApiResponseError } from "@api";
import Button from "@components/Button";
import ErrorWell from "@components/ErrorWell";
import { LabelledInput } from "@components/FormControls";
import { Link } from "@components/SecondaryButton";
import { LargeBrandTitle, SmallText, Well } from "@design/helpers";
import DS from "@design/system";

const ForgotPassword = ({
  defaultEmail,
  onBackToLoginClick,
  onEmailSubmit,
}: {
  defaultEmail?: string;
  onBackToLoginClick?: (e: React.MouseEvent) => void;
  onEmailSubmit?: (email: string) => void;
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    control,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = useForm<{ email: string }>();

  const email = useWatch({
    control,
    name: "email",
    defaultValue: defaultEmail,
  });

  const onSubmit: React.FormEventHandler = useCallback(
    (e) =>
      void handleSubmit(async ({ email }: { email: string }) => {
        setErrorMessage(null);

        try {
          await api.requestPasswordReset(email);
          onEmailSubmit && onEmailSubmit(email);
        } catch (e) {
          setErrorMessage("Something went wrong. Please try again.");

          if (!isApiResponseError<Api.UserValidationError>(e)) return;

          const { error } = e;

          if (error.isServerError) {
            setErrorMessage(
              "We encountered an issue on our side. Please try again soon.",
            );
          } else {
            setErrorMessage(null);
            setError("email", {
              type: "custom",
              message:
                e.error.all[0].Description ||
                "There was an issue sending your recovery link. Please try again.",
            });
          }
        }
      })(e),
    [handleSubmit, onEmailSubmit, setError],
  );

  return (
    <form style={{ height: "100%" }} action="#" onSubmit={onSubmit}>
      <div
        style={{
          height: "100%",
          display: "grid",
          gap: DS.margins.large,
          alignContent: "center",
        }}
      >
        <LargeBrandTitle>Forgot Password</LargeBrandTitle>

        {errorMessage && <ErrorWell>{errorMessage}</ErrorWell>}
        {errors.email && <ErrorWell>{errors.email.message}</ErrorWell>}

        <div>
          <LabelledInput
            type="email"
            label="Email"
            defaultValue={defaultEmail}
            hasError={!!errors.email}
            readOnly={isSubmitting}
            {...register("email")}
          />
          <Well style={{ marginTop: 8, marginBottom: 0 }}>
            <SmallText>
              Enter your email address and we will send you a recovery link.
              This will allow you to reset your password.
            </SmallText>
          </Well>
        </div>
        <div
          style={{
            textAlign: "center",
            display: "grid",
            gap: DS.margins.micro,
          }}
        >
          <Button
            buttonType="action"
            type="submit"
            disabled={!email || isSubmitting}
          >
            {isSubmitting ? "Sending recovery link..." : "Send recovery link"}
          </Button>
          <Link href="#" onClick={onBackToLoginClick}>
            Return to log in
          </Link>
        </div>
      </div>
    </form>
  );
};

export default ForgotPassword;
