import { SemiStrongText, SmallText } from "@design/helpers";
import DS from "@design/system";

import ContentLoader, { TextBlock } from "../util/ContentLoader";
import { Wrapped } from "./Layout";
import { useTooltip } from "./Tooltip";

export const MediaCardPlaceholder = ({
  width = 220,
  showImage = true,
  imageSize = 32,
  showDescriptionExt = false,
}: {
  width?: number;
  showImage?: boolean;
  imageSize?: number;
  showDescriptionExt?: boolean;
}) => (
  <ContentLoader width={width} height={showImage ? imageSize : 30}>
    {showImage && (
      <rect x={0} y={0} rx={4} ry={4} width={imageSize} height={imageSize} />
    )}

    <TextBlock
      x={
        showImage
          ? imageSize +
            (imageSize > 48 ? DS.margins.regularN : DS.margins.microN)
          : 0
      }
      y={showImage ? imageSize / 2 - (showDescriptionExt ? 21 : 13) : 3}
      width={width - 40}
      fontSize={12}
    />
    <TextBlock
      x={
        showImage
          ? imageSize +
            (imageSize > 48 ? DS.margins.regularN : DS.margins.microN)
          : 0
      }
      y={showImage ? imageSize / 2 + (showDescriptionExt ? -3 : 2) : 18}
      width={width - 140}
      fontSize={10}
    />
    {showDescriptionExt && (
      <TextBlock
        x={
          showImage
            ? imageSize +
              (imageSize > 48 ? DS.margins.regularN : DS.margins.microN)
            : 0
        }
        y={showImage ? imageSize / 2 + 12 : 18}
        width={width - 170}
        fontSize={10}
      />
    )}
  </ContentLoader>
);

const MediaCard = ({
  image,
  title,
  description,
  descriptionExt,
  imageSize = 32,
  descriptionTip,
}: {
  image?: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  descriptionExt?: React.ReactNode;
  imageSize?: number;
  descriptionTip?: React.ReactNode;
}) => {
  const { tooltipProps } = useTooltip<HTMLDivElement>(descriptionTip);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gap: imageSize > 48 ? DS.margins.regular : DS.margins.micro,
        alignItems: "center",
      }}
    >
      {image && typeof image === "string" ? (
        <img
          src={image}
          alt=""
          width={imageSize}
          height={imageSize}
          style={{ borderRadius: DS.radii.item }}
        />
      ) : (
        image
      )}

      <div
        style={{
          overflow: "hidden",
          gridColumn: !image ? "span 2" : "2",
          alignSelf: "center",
        }}
      >
        <SemiStrongText
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </SemiStrongText>
        <SmallText
          style={{ marginTop: imageSize > 48 ? 0 : -3 }}
          {...tooltipProps}
        >
          <Wrapped>{description}</Wrapped>
        </SmallText>

        {descriptionExt && (
          <SmallText
            style={{
              marginTop: imageSize > 48 ? 0 : -3,
              textTransform: "uppercase",
              fontWeight: 600,
            }}
          >
            <Wrapped>{descriptionExt}</Wrapped>
          </SmallText>
        )}
      </div>
    </div>
  );
};

export default MediaCard;
