import { useCallback, useLayoutEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import BorderBox from "@components/BorderBox";
import Button from "@components/Button";
import ChangeStoreContact from "@components/ChangeStoreContact";
import GroupHeader from "@components/GroupHeader";
import MediaCard from "@components/MediaCard";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import ModalStandardLayout from "@components/ModalStandardLayout";
import Scroller from "@components/Scroller";
import { UnstyledList } from "@design/helpers";
import DS from "@design/system";
import { numberToWord } from "@lang/lang";
import {
  useDeleteUser,
  GroupAndOrStoreKey,
  useUserAssociations,
} from "@state/hooks";

const DeleteUserModal = ({
  user,
  mutatorKeys,
  onConfirm,
  onClose,
}: {
  user: Api.User;
  mutatorKeys?: GroupAndOrStoreKey;
  onConfirm: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { palettes } = useTheme();

  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);

  const { mutate, isPending } = useDeleteUser(mutatorKeys);
  const {
    data: associations,
    isLoading: isLoadingAssociations,
    isFetching: isFetchingAssociations,
  } = useUserAssociations(user.UserId);

  const handleConfirm = useCallback(() => {
    mutate(user.UserId, {
      onError: () => {
        toast.error(
          <span>
            Unable to delete <strong>{user.FullName}</strong>.
          </span>,
        );
      },
      onSuccess: () => {
        toast.success(
          <span>
            <strong>{user.FullName}</strong> successfully deleted.
          </span>,
        );
        onConfirm && onConfirm();
      },
    });
  }, [mutate, user, onConfirm]);

  useLayoutEffect(() => {
    cancelButtonRef.current?.focus();
  }, []);

  return (
    <Modal contentLabel="Delete person" onClose={onClose}>
      <ModalStandardLayout
        header={<ModalHeader title="Delete Person" onClose={onClose} />}
        content={
          <div
            style={{
              display: "grid",
              gap: DS.margins.regular,
              alignContent: "flex-start",
            }}
          >
            <BorderBox style={{ margin: DS.margins.largeCss("rl") }}>
              <MediaCard
                title={user.FullName}
                description={user.Email}
                descriptionExt={user.Roles[0].DisplayName}
              />
            </BorderBox>
            {associations && associations.length > 0 ? (
              <Scroller>
                <div style={{ margin: DS.margins.largeCss("rl") }}>
                  <p
                    style={{
                      margin: 0,
                      color: palettes.well.foreground,
                    }}
                  >
                    <strong>{user.FullName}</strong> can not be deleted as they
                    are the {t("term.store_one").toLowerCase()} contact for{" "}
                    {numberToWord(associations.length)}{" "}
                    {t("deleteUser.associations", {
                      count: associations.length,
                    })}
                    .
                    <br />
                    <br />
                    You will need to change the{" "}
                    {t("term.store_one").toLowerCase()} contact below for these
                    {t("term.store_other").toLowerCase()} to someone else before
                    you can continue:
                  </p>
                  <UnstyledList style={{ margin: DS.margins.microCss("tb") }}>
                    {associations.map((group) => (
                      <li key={group.GroupId} style={{}}>
                        <GroupHeader separator={false}>
                          {group.Stores[0].Name}
                        </GroupHeader>
                        <ChangeStoreContact
                          storeId={group.Stores[0].StoreId}
                          loading={
                            isLoadingAssociations || isFetchingAssociations
                          }
                        />
                      </li>
                    ))}
                  </UnstyledList>
                </div>
              </Scroller>
            ) : (
              <p
                style={{
                  margin: DS.margins.largeCss("rl"),
                  color: palettes.well.foreground,
                }}
              >
                This action will permanently delete{" "}
                <strong>{user.FullName}</strong>.
                <br />
                <br />
                They will no longer have access to this portal. However, some of
                their data associated to events may remain.
              </p>
            )}
          </div>
        }
        footer={
          <ModalFooter>
            {associations && associations.length > 0 ? (
              <Button onClick={onClose}>Close</Button>
            ) : (
              <>
                <p>Are you sure you want to continue?</p>
                <Button
                  disabled={isPending}
                  ref={cancelButtonRef}
                  onClick={onClose}
                >
                  No, take me back!
                </Button>
                <Button
                  buttonType="destructive"
                  disabled={isPending}
                  onClick={handleConfirm}
                >
                  {isPending ? "Deleting…" : "Yes, permanently delete."}
                </Button>
              </>
            )}
          </ModalFooter>
        }
      />
    </Modal>
  );
};

export default DeleteUserModal;
