import { useCallback } from "react";
import { useTheme } from "styled-components";

import { useAuth } from "@auth/auth";
import DS from "@design/system";

import Button from "./Button";

const DOWNLOAD_APPLE = "/apple-download.png";
const DOWNLOAD_APPLE_2 = "/apple-download@2x.png";

const DOWNLOAD_GOOGLE = "/google-download.png";
const DOWNLOAD_GOOGLE_2 = "/google-download@2x.png";

export default function SplashScreen() {
  const { meta, palettes, id } = useTheme();

  const { showSplashScreen, setShowSplashScreen } = useAuth();

  const handleClick = useCallback(
    () => setShowSplashScreen(false),
    [setShowSplashScreen],
  );

  return (
    id !== "cintas" &&
    showSplashScreen && (
      <div
        style={{
          zIndex: 2,
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,

          background: palettes.body.background,

          display: "grid",
          gap: DS.margins.regular,
          alignContent: "center",
          justifyItems: "center",
        }}
      >
        <div>
          <meta.Logo height={80} />
        </div>
        <div>Download the Clever First Aid App</div>
        <div>
          <div style={{ display: "flex", gap: 20 }}>
            <a
              href="https://play.google.com/store/apps/details?id=com.cleverfirstaid.mobile.app&hl=en&gl=US&pli=1"
              rel="noreferrer"
              target="_blank"
            >
              <figure>
                <img
                  alt="Get it on Google Play"
                  srcSet={`${DOWNLOAD_GOOGLE}, ${DOWNLOAD_GOOGLE_2} 2x`}
                  src={DOWNLOAD_GOOGLE}
                  width={150}
                  height={50}
                />
              </figure>
            </a>

            <a
              href="https://apps.apple.com/nz/app/clever-first-aid-app/id1624838022"
              rel="noreferrer"
              target="_blank"
            >
              <figure>
                <img
                  alt="Download on the App Store"
                  srcSet={`${DOWNLOAD_APPLE}, ${DOWNLOAD_APPLE_2} 2x`}
                  src={DOWNLOAD_APPLE}
                  width={150}
                  height={50}
                />
              </figure>
            </a>
          </div>
        </div>
        <Button buttonType="progressive" onClick={handleClick}>
          Continue to the Portal
        </Button>
      </div>
    )
  );
}
