import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapRef, MapboxGeoJSONFeature } from "react-map-gl";

import { useUnit } from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";

import { Pin, PinContent } from "./Pin";
import { UnitFeatureProperties } from "./types";

const getValue = (value: number) => {
  if (isNaN(value) || value === Infinity) return "--";
  if (value === 0) return "0%";
  if (value === 100) return "100%";
  return `${value.toFixed(0)}%`;
};

export const UnitReadinessMarker = ({
  mapRef,
  feature,
  unitId,
  selected,
  cluster,
  onChildChange,
}: {
  mapRef: MapRef | null;
  feature: MapboxGeoJSONFeature;
  unitId: string;
  selected: boolean;
  cluster?: number | null;
  onChildChange?: (child: string) => void;
}) => {
  const { t } = useTranslation();
  const { readinessPalette } = useThemeHelper();

  const [selectedProperties, setSelectedProperties] =
    useState<UnitFeatureProperties | null>(null);

  const { data: unit } = useUnit(selectedProperties?.id ?? unitId);

  const { readiness, minReadiness } =
    selectedProperties ?? (feature.properties as UnitFeatureProperties);

  const { foreground, background } = useMemo(
    () => readinessPalette(readiness ?? minReadiness),
    [minReadiness, readiness, readinessPalette],
  );

  const onChange = useCallback(
    (f: GeoJSON.Feature<GeoJSON.Geometry>) => {
      setSelectedProperties(f.properties as UnitFeatureProperties);
      onChildChange?.((f.properties as UnitFeatureProperties).id);
    },
    [onChildChange],
  );

  return (
    <Pin
      mapRef={mapRef}
      feature={feature}
      source="units"
      label={unit?.UnitName}
      selected={selected}
      cluster={cluster}
      onChange={onChange}
      content={() => (
        <PinContent
          label={
            <span style={{ color: foreground }}>
              {getValue(readiness ?? minReadiness)}
            </span>
          }
          description={
            !selected && cluster ? (
              <span style={{ color: foreground }}>
                {t("pages.map.markers.unitCount", { count: cluster })}
              </span>
            ) : (
              <span style={{ color: foreground }}>Rating</span>
            )
          }
          selected={selected}
          cluster={cluster}
        />
      )}
      background={(width, height) => (
        <rect width={width} height={height} fill={background} />
      )}
    />
  );
};
