import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

import { trackEvent } from "./analytics";

export interface Panel {
  id: string;
  // getStyles: () => void;
  content: React.ReactElement;
}

export interface SidePanelsContext {
  panels: Panel[];

  /**
   * Will push the panel to the top of the stack. If it already exists, it will just swap it. If there are panels above it, they will be lost.
   */
  push: (panel: Panel) => void;

  /**
   * If no `id` is provided, will pop the top panel off the stack. If `id` is provided, will pop everything from `id` and above.
   */
  pop: (id?: string) => void;

  /**
   * Similar to `push`, but performs an animated swap of a panel on update.
   */
  swap: (id: string, panel: Panel) => void;
}

const initialValue = createContext<SidePanelsContext>({
  panels: [],
  push: () => null,
  pop: () => null,
  swap: () => null,
});

export const useSidePanels = () => useContext(initialValue);

export const SidePanelsProvider = ({ children }: { children: ReactNode }) => {
  const [panels, setPanels] = useState<Panel[]>([]);

  const push = useCallback((panel: Panel) => {
    setPanels((panels) => {
      return panels.filter((p) => p.id !== panel.id).concat(panel);
    });

    trackEvent({
      category: "Side Panel",
      action: "Show",
      label: panel.id,
    });
  }, []);

  const pop = useCallback((id?: string) => {
    setPanels((panels) => {
      if (id) {
        trackEvent({
          category: "Side Panel",
          action: "Hide",
          label: id,
        });

        return panels.filter((panel) => panel.id !== id);
      } else {
        trackEvent({
          category: "Side Panel",
          action: "Hide",
          label: panels.at(-1)?.id,
        });

        return panels.slice(0, -1);
      }
    });
  }, []);

  const swap = useCallback((id: string, panel: Panel) => {
    setPanels((panels) => {
      const newArr = [...panels];
      const index = newArr.findIndex((p) => p.id === id);

      if (index === -1) {
        newArr.push(panel);
      } else {
        newArr[index] = panel;
      }

      return newArr;
    });

    trackEvent({
      category: "Side Panel",
      action: "Swap",
      label: `${id} swapped for ${panel.id}`,
    });
  }, []);

  return (
    <initialValue.Provider value={{ panels, push, pop, swap }}>
      {children}
    </initialValue.Provider>
  );
};
