import { useContext } from "react";

import Message from "@components/Message";

import { LastResponseContext } from "../../util/viewModel";
import { AutoErrorWell } from "../ErrorWell";

const RenderErrorMessage = () => {
  const response = useContext(LastResponseContext);

  if (!response) return null;

  const { error } = response;

  if (error.hasError("duplicated_email")) {
    return (
      <>
        <Message type="error">
          <strong>Email address already in use</strong>
          <br />
          An account with this email address already exists. Account emails must
          be unique.
        </Message>
        <p>
          If this person has forgotten their log in details, you can{" "}
          <strong>reset their password</strong>.
        </p>
        <p>
          Alternatively, you can try again{" "}
          <strong>using a different email address</strong>.
        </p>
      </>
    );
  }

  if (error.hasError("duplicated_phone")) {
    return (
      <>
        <Message type="error">
          <strong>Phone number already in use</strong>
          <br />
          An account with this phone number already exists. Account phone
          numbers must be unique.
        </Message>
        <p>
          Providing a phone number is optional, so you can omit it if needed. Go{" "}
          <strong>back to start</strong> to update the phone number.
        </p>
      </>
    );
  }

  if (error.hasError("multiple_franchises")) {
    return (
      <>
        <Message type="error">
          <strong>Attempting to add someone to multiple Franchises.</strong>
          <br />
          People can only be assigned to groups or stores within one franchise
          at a time.
        </Message>
        <p>
          Please ensure you only add a person to groups or stores within one
          franchise at a time.
        </p>
      </>
    );
  }

  return <AutoErrorWell response={response} />;
};

const AccountCreationFailedTab = () => {
  return <RenderErrorMessage />;
};

export default AccountCreationFailedTab;
