import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useHistory } from "react-router-dom";

import useAddNewGroup from "@components/AddNewGroup";
import DaysGoneBanner from "@components/DaysGoneBanner";
import ReadinessBanner from "@components/ReadinessBanner";
import SidePanelContainer from "@components/SidePanelContainer";
import { SidePanelContentContainer } from "@components/SidePanelContentContainer";
import SidePanelFooter from "@components/SidePanelFooter";
import SidePanelHeaderPlaceholder from "@components/SidePanelHeaderPlaceholder";
import StoreReadinessImage from "@components/StoreReadinessImage";
import DS from "@design/system";
import { useThemeHelper } from "@util/useThemeHelper";

import Button from "../components/Button";
import GroupHeader from "../components/GroupHeader";
import GroupedList from "../components/GroupedList";
import Icon from "../components/Icon";
import IncidentsRow from "../components/IncidentsRow";
import Scroller from "../components/Scroller";
import SidePanelHeader from "../components/SidePanelHeader";
import AddStoreToGroupModal from "../modals/AddStoreToGroupModal";
import {
  useGroup,
  useGroupsWithStoreCount,
  useStoresInGroup,
} from "../state/hooks";
import { useMapState } from "../util/MapState";
import { useSidePanels } from "../util/SidePanels";
import IncidentSidePanel from "./IncidentSidePanel";
import StoreSidePanel from "./StoreSidePanel";

const GroupSidePanel = ({ groupId }: { groupId: string }) => {
  const { icon } = useThemeHelper();
  const history = useHistory();
  const { t } = useTranslation();

  const { push, pop } = useSidePanels();
  const { setGroupId, setStoreId } = useMapState();

  const {
    data: group,
    isLoading: isGroupLoading,
    isError: isGroupError,
  } = useGroup(groupId);
  const { data: parentGroup } = useGroup(group?.ParentGroupId);
  const stores = useStoresInGroup(groupId, true);
  const { data: groupsWithStoreCount } = useGroupsWithStoreCount();
  const [AddNewGroup, showAddGroup] = useAddNewGroup(group);

  const [childGroups, setChildGroups] = useState<Api.GroupWithCount[]>([]);

  const [showAddStoreModal, closeAddStoreModal] = useModal(
    () => (
      <AddStoreToGroupModal groupId={groupId} onClose={closeAddStoreModal} />
    ),
    [groupId],
  );

  const handleStoreClick = useCallback(
    (store: Api.Store) => {
      setStoreId(store.StoreId);
      push({
        id: `store-${store.StoreId}`,
        content: <StoreSidePanel storeId={store.StoreId} />,
      });
    },
    [push, setStoreId],
  );

  const handleGroupClick = useCallback(
    (group: Api.TreeGroup) => {
      setGroupId(group.GroupId);
      push({
        id: `group-${group.GroupId}`,
        content: <GroupSidePanel groupId={group.GroupId} />,
      });
    },
    [push, setGroupId],
  );

  const handleIncidentClick = useCallback(
    (incident: Api.Incident) => {
      push({
        id: `incident-${incident.incidentId}`,
        content: <IncidentSidePanel incident={incident} />,
      });
    },
    [push],
  );

  const handleCloseClick = useCallback(() => {
    // FIXME: This should select the "previous" group, if there was one.
    setGroupId(null);
    pop();
  }, [pop, setGroupId]);

  const handleViewAllIncidentsClick = useCallback(() => {
    history.push(`/group/${groupId}/incidents`);
  }, [groupId, history]);

  const handleViewAllStoresClick = useCallback(() => {
    history.push(`/group/${groupId}/stores`);
  }, [groupId, history]);

  const handleAddStoreToGroupClick = useCallback(() => {
    showAddStoreModal();
  }, [showAddStoreModal]);

  const handleViewGroupClick = useCallback(() => {
    history.push(`/group/${groupId}`);
  }, [groupId, history]);

  useEffect(() => {
    if (!group || !group.Groups || !groupsWithStoreCount) {
      setChildGroups([]);
      return;
    }

    setChildGroups(
      group.Groups.filter(
        (group) => group.Type === "Adhoc" || group.Type === "Franchise",
      )
        .map(
          (g) =>
            groupsWithStoreCount.filter((gr) => gr.GroupId === g.GroupId)[0],
        )
        .filter((g) => g)
        .sort((a, b) => a.Name.localeCompare(b.Name)),
    );
  }, [group, groupsWithStoreCount]);

  return (
    <SidePanelContainer aria-label="Group">
      <div>
        {!group ? (
          <SidePanelHeaderPlaceholder />
        ) : (
          <SidePanelHeader
            title={group.Name}
            subtitle={parentGroup?.Name || "…"}
            closeTitle="Close group view"
            onClose={handleCloseClick}
          />
        )}

        <div
          style={{
            margin: DS.margins.regular,
            display: "grid",
            gap: DS.margins.micro,
            gridTemplateColumns: "1fr auto",
          }}
        >
          <ReadinessBanner groupId={groupId} />
          <DaysGoneBanner groupId={groupId} />
        </div>
      </div>

      <Scroller>
        <SidePanelContentContainer
          contents={[
            <>
              <GroupHeader
                controls={[
                  {
                    title:
                      `View all ${t(
                        "term.incident_other",
                      ).toLowerCase()} for ` + (group?.Name ?? "…"),
                    icon: "arrow-up-right-from-square",
                    onClick: handleViewAllIncidentsClick,
                  },
                ]}
              >
                Recent {t("severity.high")}/{t("severity.medium").toLowerCase()}{" "}
                {t("term.incident_other").toLowerCase()}
              </GroupHeader>
              <Scroller gutter={DS.margins.regularN}>
                <IncidentsRow
                  groupId={groupId}
                  showStores
                  onIncidentClick={handleIncidentClick}
                />
              </Scroller>
            </>,
            <>
              <GroupedList
                title={`${t("term.store_other")} in Group`}
                controls={[
                  {
                    title:
                      `View all ${t("term.store_other").toLowerCase()} for ` +
                      (group?.Name ?? "…"),
                    icon: "arrow-up-right-from-square",
                    onClick: handleViewAllStoresClick,
                  },
                ]}
                separator
                tight
                emptyLabel={`No ${t(
                  "term.store_other",
                ).toLowerCase()} in this group.`}
                data={stores.data}
                isLoading={stores.isLoading}
                isError={stores.isError}
                listItemKey={(store) => store.StoreId}
                listItemImage={(store) => (
                  <StoreReadinessImage storeId={store.StoreId} />
                )}
                listItemTitle={(store) => store.Name}
                listItemDescription={(store) => store.StreetAddress.AsOneLine}
                onListItemClick={handleStoreClick}
              />
            </>,
            <>
              <GroupedList
                title="Sub-groups in group"
                separator
                tight
                emptyLabel="No sub-groups in this group."
                data={childGroups}
                controls={
                  childGroups && [
                    {
                      title: "Add a new group",
                      icon: "plus",
                      onClick: () => showAddGroup(),
                    },
                  ]
                }
                isLoading={isGroupLoading}
                isError={isGroupError}
                listItemKey={(g) => g.GroupId}
                listItemTitle={(g) => g.Name}
                listItemDescription={(g) =>
                  `${t("term.store", { count: g.storeCount })}`
                }
                onListItemClick={handleGroupClick}
              />
              <AddNewGroup />
            </>,
          ]}
        />
      </Scroller>

      <SidePanelFooter>
        <Button onClick={handleAddStoreToGroupClick} stretch>
          <Icon name={icon("store")} /> Add stores to group…
        </Button>
        <Button buttonType="progressive" onClick={handleViewGroupClick} stretch>
          Open group details <Icon name="arrow-up-right-from-square" />
        </Button>
      </SidePanelFooter>
    </SidePanelContainer>
  );
};

export default GroupSidePanel;
