import { useCallback, useMemo } from "react";

import {
  IncidentDetails,
  IncidentDetailsHeader,
} from "@components/IncidentDetails";
import SidePanelContainer from "@components/SidePanelContainer";
import { useTerminologies } from "@util/useTerminologies";

import Scroller from "../components/Scroller";
import SidePanelFooter from "../components/SidePanelFooter";
import SidePanelHeader from "../components/SidePanelHeader";
import { useSidePanels } from "../util/SidePanels";

const IncidentSidePanel = ({ incident }: { incident: Api.Incident }) => {
  const { pop } = useSidePanels();
  const { getTitle } = useTerminologies();

  const handleCloseClick = useCallback(() => pop(), [pop]);

  const dateString = useMemo(
    () => new Date(incident.date).toDateString(),
    [incident.date],
  );

  const timeString = useMemo(
    () => new Date(incident.date).toLocaleTimeString(),
    [incident.date],
  );

  return (
    <SidePanelContainer>
      <div style={{ display: "grid" }}>
        <SidePanelHeader
          title={getTitle(incident)}
          subtitle={
            <>
              {dateString}, {timeString}
            </>
          }
          closeTitle="Close incident view"
          onClose={handleCloseClick}
        />
        <IncidentDetailsHeader incident={incident} />
      </div>

      <Scroller>
        <IncidentDetails incident={incident} />
      </Scroller>

      <SidePanelFooter />
    </SidePanelContainer>
  );
};
export default IncidentSidePanel;
