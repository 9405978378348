import { useFormContext } from "react-hook-form";
import { useTheme } from "styled-components";

import ErrorWell from "@components/ErrorWell";
import { WizardStepContainer } from "@components/ModalWizardHelper";
import { ModalDescription, UnstyledList } from "@design/helpers";
import DS from "@design/system";
import { useShipment } from "@state/hooks";
import { AedReplenished } from "@util/viewModel";

const ReviewStep = () => {
  const { palettes } = useTheme();

  const {
    watch,
    formState: { errors },
  } = useFormContext<AedReplenished>();

  const [shipmentId, photoPreview] = watch(["ShipmentId", "photoPreview"]);

  const { data: shipment } = useShipment(shipmentId);

  return (
    <WizardStepContainer>
      <ModalDescription style={{ padding: "0 64px" }}>
        {errors.root && <ErrorWell>{errors.root.message}</ErrorWell>}
        You are confirming that you have received the following items and
        replaced them.
      </ModalDescription>

      <div
        style={{
          position: "relative",
          margin: "8px 64px",

          borderRadius: DS.radii.largeItem,
          background: palettes.well.background,
          ...(photoPreview && {
            backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 64px), url(${photoPreview})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }),

          display: "grid",
          alignContent: "center",
          justifyItems: "center",
        }}
      >
        {!photoPreview && (
          <span style={{ fontWeight: 600, color: palettes.well.small }}>
            No photo uploaded
          </span>
        )}
        <UnstyledList
          horizontal
          style={{
            position: "absolute",
            right: DS.margins.micro,
            bottom: DS.margins.micro,
          }}
        >
          {shipment?.ShipmentLines.map((line) => (
            <li key={line.ShipmentLineID}>
              <div
                style={{
                  width: 48,
                  height: 48,

                  backgroundColor: "white",
                  backgroundImage: `url(${line.ImageUrl})`,
                  backgroundSize: 40,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",

                  border: `solid 1px ${palettes.body.border}`,
                  borderRadius: DS.radii.item,
                }}
              />
            </li>
          ))}
        </UnstyledList>
      </div>
    </WizardStepContainer>
  );
};

export default ReviewStep;
