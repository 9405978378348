import color from "color";
import { useEffect, useRef, useState } from "react";
import CL from "react-content-loader";
import styled, { useTheme } from "styled-components";

import DS from "@design/system";

export const TextBlock = ({
  x,
  y,
  width,
  fontSize,
}: {
  x: number;
  y: number;
  width: number;
  fontSize: number;
}) => {
  const height = fontSize;
  return <rect x={x} y={y} rx={2} ry={2} width={width} height={height} />;
};

const TextLoaderContainer = styled.span`
  & > svg {
    display: block;
  }
`;

export const TextLoader = ({ width }: { width: number }) => {
  const { palettes } = useTheme();

  const el = useRef<HTMLSpanElement>(null);

  const [boxHeight, setBoxHeight] = useState<number>(0);
  const [textHeight, setTextHeight] = useState<number>(0);

  useEffect(() => {
    if (!el.current) {
      setBoxHeight(0);
      setTextHeight(0);
      return;
    }

    const spacer = document.createElement("div");
    spacer.innerHTML = "&nbsp;";
    el.current.appendChild(spacer);

    const { height } = el.current.getBoundingClientRect();
    el.current.removeChild(spacer);

    setBoxHeight(height);

    const { fontSize } = getComputedStyle(el.current);
    setTextHeight(Number(fontSize.slice(0, -2)));
  }, []);

  return (
    <TextLoaderContainer ref={el}>
      <CL
        foregroundColor={color(palettes.body.foreground)
          .mix(color(palettes.body.background), 0.8)
          .string()}
        backgroundColor={color(palettes.body.foreground)
          .mix(color(palettes.body.background), 0.9)
          .string()}
        title="Loading…"
        width={width}
        height={boxHeight}
        viewBox={`0 0 ${width} ${boxHeight}`}
      >
        <rect
          y={textHeight * 0.2}
          width={width}
          height={textHeight - textHeight * 0.2}
          rx={DS.radii.item}
        />
      </CL>
    </TextLoaderContainer>
  );
};

export const BoxLoader = ({
  title,
  width,
  height,
  radius = DS.radii.item,
}: {
  title?: string;
  width: number;
  height: number;
  radius?: string | number;
}) => (
  <ContentLoader title={title} width={width} height={height}>
    <rect width={width} height={height} rx={radius} />
  </ContentLoader>
);

const ContentLoader = ({
  title = "Loading…",
  width,
  height,
  children,
}: {
  title?: string;
  width: number;
  height: number;
  children?: React.ReactNode;
}) => {
  const { palettes } = useTheme();

  return (
    <CL
      foregroundColor={color(palettes.body.foreground)
        .mix(color(palettes.body.background), 0.8)
        .string()}
      backgroundColor={color(palettes.body.foreground)
        .mix(color(palettes.body.background), 0.9)
        .string()}
      title={title}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{ display: "block" }}
    >
      {children}
    </CL>
  );
};

export default ContentLoader;
