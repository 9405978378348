import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useId, useMemo, useState } from "react";
import { useLayer } from "react-laag";
import { useTheme } from "styled-components";

import DS from "@design/system";

import Button from "./Button";
import { MenuButton } from "./ContextMenu";
import Icon from "./Icon";

export const ButtonSelection = <
  TValue extends string,
  TOption extends { id: TValue; label: string },
>({
  options,
  value,
  onChange,
}: {
  options: TOption[];
  value: TValue;
  onChange: (value: TValue) => void;
}) => {
  const id = useId();
  const { palettes } = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const buttonOption = useMemo(
    () => options.find((option) => option.id === value)?.label,
    [options, value],
  );

  const handleExpanded = useCallback(
    (optionId: TValue) => {
      onChange(optionId);
    },
    [onChange],
  );

  const handleClose = useCallback(() => setIsOpen(false), []);

  const handleClick = useCallback(() => {
    setIsOpen((open) => !open);
  }, []);

  const { layerProps, layerSide, triggerProps, triggerBounds, renderLayer } =
    useLayer({
      isOpen,
      overflowContainer: false,
      onOutsideClick: handleClose,
      auto: true,
      placement: "bottom-start",
      possiblePlacements: ["bottom-start", "top-start"],
      triggerOffset: 0,
      containerOffset: 0,
      arrowOffset: 0,
    });

  return (
    <>
      <Button
        {...triggerProps}
        onClick={handleClick}
        style={{
          position: "relative",
          ...(isOpen &&
            layerSide === "bottom" && {
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              background: palettes.form.dim,
            }),

          ...(isOpen &&
            layerSide === "top" && {
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
            }),
        }}
        aria-haspopup
        aria-expanded={isOpen}
        aria-controls={id}
      >
        {buttonOption}

        <Icon name="caret-up-down" />
      </Button>

      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <motion.ul
              {...layerProps}
              style={{
                ...layerProps.style,
                boxSizing: "border-box",
                width: triggerBounds?.width,
                margin: 0,
                padding: 0,

                listStyle: "none",

                borderWidth: 1,
                borderStyle: "solid",
                borderColor: palettes.form.border,

                ...(layerSide === "top" && {
                  borderWidth: "1px 1px 0",

                  borderTopRightRadius: DS.radii.item,
                  borderTopLeftRadius: DS.radii.item,

                  transformOrigin: "bottom",
                }),

                ...(layerSide === "bottom" && {
                  borderWidth: "0 1px 1px",
                  borderBottomRightRadius: DS.radii.item,
                  borderBottomLeftRadius: DS.radii.item,

                  transformOrigin: "top",
                }),

                background: palettes.form.background,
                boxShadow: DS.shadows.dialog,
              }}
              initial={{ opacity: 0, scaleY: 0.75 }}
              animate={{ opacity: 1, scaleY: 1 }}
              exit={{ opacity: 0, scaleY: 0.75 }}
              transition={{ duration: 0.1 }}
            >
              {options
                .filter((option) => option.label !== buttonOption)
                .map((option) => (
                  <li id={id} key={option.id}>
                    <MenuButton
                      onClick={() => {
                        handleExpanded(option.id);
                        setIsOpen(false);
                      }}
                    >
                      {option.label}
                    </MenuButton>
                  </li>
                ))}
            </motion.ul>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};
