import { useTransition, animated } from "@react-spring/web";
import styled from "styled-components";

import DS from "@design/system";

import { useSidePanels } from "../util/SidePanels";

const Container = styled.div`
  height: 100%;
`;

const Panel = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: ${({ theme }) => theme.palettes.body.background};
  border-radius: ${DS.radii.floating};

  box-shadow: ${DS.shadows.panel};

  display: grid;
`;

const SidePanel = () => {
  const { panels } = useSidePanels();

  const transitions = useTransition(panels, {
    keys: (panel) => panel.id,
    from: { t: 1, scale: 1 },
    enter: { t: 0, scale: 1 },
    leave: { t: 1, scale: 1 },
    update: (_panel, a) => ({
      scale: panels.length && a < panels.length - 1 ? 0.9 : 1,
    }),
    config: {
      tension: 250,
    },
  });

  return (
    <Container>
      {transitions(({ t, scale }, panel) => (
        <animated.div
          key={panel.id}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            transform: t.to(
              (v) => `translate3d(calc(-${v * 100}% - ${v * 16}px), 0, 0)`,
            ),
            scale,
          }}
        >
          <Panel>{panel.content}</Panel>
        </animated.div>
      ))}
    </Container>
  );
};

export default SidePanel;
