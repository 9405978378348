import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { lowercase } from "@util/case";

import en from "./en";

/**
 * Helper function for passing values into Trans components. Mostly to keep
 * TypeScript happy.
 *
 * @example
 *  <Trans>
 *    Show <strong>{case({ count })}</strong>
 *  </Trans>
 */
export function cast(record: Record<string, unknown>): string {
  return record as unknown as string;
}

export const numberToWord = (number: number, upper = false) => {
  if (number === 0) return "";
  if (number === 1) return upper ? "One" : "one";
  if (number === 2) return upper ? "Two" : "two";
  if (number === 3) return upper ? "Three" : "three";
  if (number === 4) return upper ? "Four" : "four";
  if (number === 5) return upper ? "Five" : "five";
  if (number === 6) return upper ? "Six" : "six";
  if (number === 7) return upper ? "Seven" : "seven";
  if (number === 8) return upper ? "Eight" : "eight";
  if (number === 9) return upper ? "Nine" : "nine";
  return `${number}`;
};

void i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {},
    fallbackLng: "en",
    resources: {
      en: { translation: en },
    },
  })
  .then(() => {
    i18n.services.formatter?.add("lowercase", (value: string) =>
      lowercase(value),
    );
  });
