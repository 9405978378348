import { useFormContext } from "react-hook-form";

import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";

const SuccessMfaFooter = ({ code }: { code?: number }) => {
  const { formState } = useFormContext<Api.User>();
  const { previous } = useWizardContext();

  const codeToString = code?.toString().split("");
  return (
    <ButtonGroup flow="horizontal">
      <Button onClick={previous}>
        <Icon name="arrow-alt-left" /> Back to setup
      </Button>

      <Button
        type="submit"
        buttonType="action"
        disabled={
          !codeToString || codeToString.length !== 6 || formState.isSubmitting
        }
      >
        {formState.isSubmitting ? "Submitting 2FA code…" : "Submit 2FA code"}
      </Button>
    </ButtonGroup>
  );
};

export default SuccessMfaFooter;
