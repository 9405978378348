import color from "color";
import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import DS from "@design/system";
import {
  useChangeStoreContact,
  useFranchiseForStore,
  useStoreFull,
  useUsersInGroup,
} from "@state/hooks";

import { removeDuplicates } from "../util/data";
import BorderBox from "./BorderBox";
import HighlightChars from "./HighlightChars";
import Icon from "./Icon";
import ItemSelector from "./ItemSelector";
import MediaCard, { MediaCardPlaceholder } from "./MediaCard";

const ChangeStoreContact = ({
  storeId,
  loading = false,
}: {
  storeId?: string;
  loading?: boolean;
}) => {
  const { t } = useTranslation();
  const { palettes } = useTheme();

  const { data: store, isLoading: isLoadingStore } = useStoreFull(storeId);
  const { data: franchise } = useFranchiseForStore(storeId);
  const { data: usersInFranchise } = useUsersInGroup(franchise?.GroupId);
  const changeStoreContact = useChangeStoreContact();

  const selectableUsers = useMemo(
    () =>
      removeDuplicates(
        usersInFranchise?.InThisGroup.concat(usersInFranchise?.InChildGroups) ??
          [],
        (user) => user.UserId,
      ),
    [usersInFranchise],
  );

  const handleStoreContactChange = useCallback(
    (user: Api.User) => {
      if (!store) return;

      changeStoreContact.mutate(
        {
          storeId: store.StoreId,
          contactUser: user,
        },
        {
          onError: (_err, { contactUser }) => {
            toast.error(
              <span>
                Unable to change {t("term.store_one").toLowerCase()} contact to{" "}
                <strong>{contactUser.FullName}</strong>.
              </span>,
            );
          },
        },
      );
    },
    [store, changeStoreContact, t],
  );

  return isLoadingStore || !store?.StoreContact ? (
    <div style={{ padding: DS.margins.microCss("tb") }}>
      <MediaCardPlaceholder />
    </div>
  ) : (
    <ItemSelector
      label={`Change ${t("term.store_one").toLowerCase()} contact`}
      value={store.StoreContact}
      valueRenderer={(user) => (
        <BorderBox
          style={{
            padding: DS.margins.micro,
            borderRadius: DS.radii.item,
            display: "grid",
            gridAutoFlow: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MediaCard
            title={user.FullName}
            description={user.Roles[0]?.DisplayName}
          />
          <Icon name="pencil-alt" size={12} color={palettes.body.small} />
        </BorderBox>
      )}
      items={selectableUsers}
      getKey={(user) => user.UserId}
      searchSelector={(user) => user.FullName}
      placeholder="Search for people…"
      loading={changeStoreContact.isPending || loading}
      onItemSelect={handleStoreContactChange}
      itemRenderer={(user, positions, isActive) => (
        <div
          style={{
            padding: DS.margins.micro,

            background: isActive ? palettes.form.dim : "transparent",
            borderRadius: DS.radii.item,

            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
          }}
        >
          <MediaCard
            title={<HighlightChars str={user.FullName} indices={positions} />}
            description={user.Roles[0]?.DisplayName}
          />
        </div>
      )}
      loadingRenderer={() => (
        <div
          style={{
            height: 32,
            padding: DS.margins.micro,
            color: color(palettes.body.foreground)
              .mix(color(palettes.body.background), 0.8)
              .string(),
            fontWeight: 600,

            display: "grid",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <span>
            <Icon name="spinner" spin /> Saving{" "}
            {t("term.store_one").toLowerCase()} contact
          </span>
        </div>
      )}
    />
  );
};

export default ChangeStoreContact;
