import { useFormContext, useWatch } from "react-hook-form";

import PasswordMatchIndicator from "@auth/PasswordMatchIndicator";
import PasswordStrengthIndicator from "@auth/PasswordStrengthIndicator";
import { LabelledPasswordInput } from "@components/FormControls";
import Message from "@components/Message";
import DS from "@design/system";

const ChangePassword = () => {
  const {
    control,
    register,
    formState: { errors, isSubmitting },
  } = useFormContext<Api.User>();
  const [firstPassword, resetPassword] = useWatch({
    control,
    name: [
      "AuthenticateChangeRequest.SuppliedPassword",
      "AuthenticateChangeRequest.ConfirmPassword",
    ],
  });

  return (
    <div
      style={{
        display: "grid",
        gap: DS.margins.regular,
      }}
    >
      <Message type="notice" icon="lock">
        Please ensure you enter a <strong>strong password</strong> for your
        account.
      </Message>

      <input type="hidden" {...register("Email")} />

      <div>
        <LabelledPasswordInput
          label="Current Password"
          hasError={!!errors.AuthenticateChangeRequest?.CurrentPassword}
          readOnly={isSubmitting}
          {...register("AuthenticateChangeRequest.CurrentPassword")}
        />
      </div>
      <div>
        <LabelledPasswordInput
          label="New Password"
          hasError={!!errors.AuthenticateChangeRequest?.SuppliedPassword}
          readOnly={isSubmitting}
          {...register("AuthenticateChangeRequest.SuppliedPassword")}
        />
        <PasswordStrengthIndicator password={firstPassword} />
      </div>
      <div>
        <LabelledPasswordInput
          label="Confirm Password"
          hasError={!!errors.AuthenticateChangeRequest?.ConfirmPassword}
          readOnly={isSubmitting}
          {...register("AuthenticateChangeRequest.ConfirmPassword")}
        />
        <PasswordMatchIndicator
          password={firstPassword}
          confirmPassword={resetPassword}
        />
      </div>
    </div>
  );
};

export default ChangePassword;
