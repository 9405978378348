import mapboxGl from "mapbox-gl";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import MapGL, { MapRef, ViewState, ViewStateChangeEvent } from "react-map-gl";
import { useTheme } from "styled-components";

import DS from "@design/system";
import { BoxLoader } from "@util/ContentLoader";
import { useMapLayer } from "@util/useMapLayer";
import { IIcon } from "src/icons";

import ButtonBar from "./ButtonBar";
import Icon from "./Icon";
import IconButton from "./IconButton";
import MapControls from "./MapControls";

const ZOOMED_IN = 18;

export type LongitudeLatitude = {
  longitude: number;
  latitude: number;
};

export type Preset = {
  title: string;
  icon: IIcon;
  location: LongitudeLatitude;
};

const MAP_ANIMATION_SPEED = 3;

const MapLocationEditor = ({
  width = 328,
  height = 300,
  resetLocation,
  location,
  isLoading = false,
  presets,
  markers,
  onLocationChange,
}: {
  width?: number;
  height?: number;
  resetLocation?: LongitudeLatitude;
  location?: LongitudeLatitude;
  isLoading?: boolean;
  presets?: Preset[];
  markers?: ReactElement[];
  onLocationChange?: (location: LongitudeLatitude) => void;
}) => {
  const { palettes } = useTheme();

  const mapRef = useRef<MapRef | null>(null);

  const [viewState, setViewState] = useState<ViewState>();

  const [mapLoaded, setMapLoaded] = useState(false);
  const [initialZoomComplete, setInitialZoomComplete] = useState(false);

  const handleLoad = useCallback(() => setMapLoaded(true), []);

  const handleMove = useCallback(
    (e: ViewStateChangeEvent) => setViewState(e.viewState),
    [],
  );

  const handleSearchForAddressClick = useCallback(() => {
    console.log("search");
  }, []);

  const handleMoveEnd = useCallback(
    (e: ViewStateChangeEvent) => {
      const { longitude, latitude } = e.viewState;
      onLocationChange?.({ longitude, latitude });
    },
    [onLocationChange],
  );

  useEffect(() => {
    if (initialZoomComplete || !mapLoaded || !location) return;

    const { longitude, latitude } = location;

    setInitialZoomComplete(true);

    mapRef.current?.flyTo({
      speed: MAP_ANIMATION_SPEED,
      center: [longitude, latitude],
      zoom: ZOOMED_IN,
    });
  }, [initialZoomComplete, location, mapLoaded]);

  const currentLocation = location ?? resetLocation;

  const [mapView] = useMapLayer();

  return isLoading || !currentLocation ? (
    <BoxLoader width={width} height={height} radius={DS.radii.largeItem} />
  ) : (
    <MapGL
      ref={mapRef}
      mapLib={mapboxGl}
      mapboxAccessToken={import.meta.env.VITE_APP_MAPBOX_TOKEN}
      mapStyle={mapView}
      onMove={handleMove}
      onMoveEnd={handleMoveEnd}
      onLoad={handleLoad}
      initialViewState={{
        ...currentLocation,
        zoom: 18,
      }}
      {...viewState}
      style={{
        position: "relative",
        width,
        height,
        borderRadius: DS.radii.largeItem,
      }}
    >
      <div
        style={{
          display: "none",
          position: "absolute",
          top: DS.margins.micro,
          left: DS.margins.micro,
          // display: "grid",
          gap: DS.margins.micro,
        }}
      >
        <ButtonBar vertical>
          <IconButton
            title="Search for an address"
            tooltipPlacement="right-center"
            icon="search"
            iconSize={12}
            fit="compact"
            onClick={handleSearchForAddressClick}
          />
        </ButtonBar>
      </div>
      <div
        style={{
          position: "absolute",
          top: DS.margins.micro,
          right: DS.margins.micro,
          display: "grid",
          gap: DS.margins.micro,
        }}
      >
        <MapControls
          mapRef={mapRef.current}
          showPoi={true}
          pointsOfInterest={presets}
          showMyLocation={true}
          resetLocation={resetLocation}
          showSatelite={true}
          onLocationChange={onLocationChange}
          size="small"
        />
      </div>

      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate3d(-50%, -22px, 0)",
          pointerEvents: "none",
        }}
      >
        <Icon name="map-pin" color={palettes.body.accent} size={24} />
      </div>

      {markers}
    </MapGL>
  );
};

export default MapLocationEditor;
