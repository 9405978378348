import { sub } from "date-fns";

export type DateRange = {
  start: Date;
  end: Date;
};

export function normalizeDateRange(dateRange: DateRange): DateRange {
  const updatedDateRange = {
    ...dateRange,
  };

  updatedDateRange.start.setHours(0, 0, 0, 0);
  updatedDateRange.end.setHours(23, 59, 59, 999);

  return updatedDateRange;
}

export const isDateRange = (
  params: Date | string | DateRange | number,
): params is DateRange => {
  return (
    !!params &&
    typeof params !== "string" &&
    typeof params !== "number" &&
    "start" in params &&
    "end" in params
  );
};

export function days(days: number): DateRange {
  const now = new Date();

  const start = sub(now, { days });
  const end = now;

  return normalizeDateRange({ start, end });
}

export function fourteenDays(): DateRange {
  return days(14);
}

export function months(months: number): DateRange {
  const now = new Date();

  const start = sub(now, { months });
  const end = now;

  return normalizeDateRange({ start, end });
}

export function oneMonth(): DateRange {
  return months(1);
}
