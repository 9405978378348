import Button from "@components/Button";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";
import DS from "@design/system";

const LocationStepFooter = ({ onCancel }: { onCancel: () => void }) => {
  const { next } = useWizardContext();

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: DS.margins.regular,
        }}
      >
        <Button onClick={onCancel}>Cancel inspection</Button>
        <Button buttonType="progressive" onClick={next}>
          Next
          <Icon name="arrow-alt-right" />
        </Button>
      </div>
    </div>
  );
};

export default LocationStepFooter;
