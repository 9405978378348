import DS from "@design/system";

const Logo = ({ height = 48 }: { height?: number }) => (
  <svg
    height={height}
    viewBox="0 0 175.4 73.7"
    fill={DS.paletteExt.realNiceGreen.x700}
  >
    <g>
      <path
        d="M18.6,22.4v8.9c0,1.3-0.9,2.4-1.9,2.4L10.3,34c-0.9,0-1.6-0.9-1.6-2.1V13.3c0-2.7,1.6-5.4,3.7-6.2L32,0.3
		c3.3-1.2,6.2,0.8,6.2,4.5v9.6l-3.9,0.8V6c0-1.4-1.1-2.2-2.3-1.8l-19.6,6.1c-0.8,0.3-1.5,1.3-1.5,2.4v8.8v3.1v6.4l5-0.3v-6.8
		l-3.8,0.5v-3.1l4.6-0.8C17.7,20.2,18.6,21.1,18.6,22.4z M38.2,30.2v-7.6L34.3,23v6.2l-7.3,0.5v-7.7l7.3-1l3.9-0.6l11-1.8
		c1.8-0.3,3.3,0.9,3.3,2.5v31.7c0,1.6-1.5,2.7-3.3,2.5l-8.9-1.7v4.2l8.9,2.1c4.7,0.9,8.8-1.9,8.8-6.3V20.5c0-4.4-4.1-7.3-8.8-6.4
		l-23,4.5c-1.2,0.2-2.2,1.5-2.2,2.9v9.5c0,1.4,1,2.5,2.2,2.5l9.3-0.3C37,33.1,38.2,31.8,38.2,30.2z M25.9,54.9l6.3,1.2v-3.9
		l-5.4-0.8v-7.6l7.2,0.6v8.2v3.9v11.2c0,1.4-1,2.2-2.3,1.7l-19.5-6.3c-0.8-0.3-1.5-1.3-1.5-2.4v-7.2l-2.2-0.5v7
		c0,2.7,1.6,5.5,3.7,6.2l19.5,7c3.3,1.2,6.2-0.8,6.2-4.4V43.5c0-1.6-1.3-2.9-2.8-3l-9.3-0.4c-1.2,0-2.2,1-2.2,2.4V52
		C23.7,53.4,24.7,54.7,25.9,54.9z M8.6,41.6v5.6l2.2,0.3v-4.8l5,0.4v6.8l-5-0.8l-2.2-0.3l-5.7-0.9c-0.6-0.1-1.2-1-1.2-1.9V27.4
		c0-1,0.5-1.8,1.2-1.9l4.6-0.7v-2.9l-4.6,0.8C1.3,23,0,25.2,0,27.6v18c0,2.4,1.3,4.6,2.9,4.9l13.6,2.6c1,0.2,1.9-0.7,1.9-2v-8.9
		c0-1.3-0.8-2.4-1.9-2.5l-6.4-0.3C9.3,39.5,8.6,40.4,8.6,41.6z"
      />
      <g>
        <path
          d="M79.5,17.4c3.9,0,6.2,2.3,6.8,5.5h-4.1c-0.4-1.1-1.2-1.9-2.7-1.9c-2.2,0-3.4,1.9-3.4,4.5
			c0,2.5,1.2,4.5,3.4,4.5c1.4,0,2.3-0.8,2.7-1.9h4.1c-0.6,3.2-2.9,5.5-6.8,5.5c-4.4,0-7.5-3.3-7.5-8.1
			C71.9,20.7,75.1,17.4,79.5,17.4z"
        />
        <path d="M88.2,17.6h4.1v12.1h7v3.5H88.2V17.6z" />
        <path d="M111.9,17.6v3.5h-7.2v2.4h6.6V27h-6.6v2.7h7.2v3.5h-11.2V17.6H111.9z" />
        <path d="M118.4,33.3l-5.8-15.7h4.4l3.5,10.1l3.5-10.1h4.2l-5.8,15.7H118.4z" />
        <path d="M140.5,17.6v3.5h-7.2v2.4h6.6V27h-6.6v2.7h7.2v3.5h-11.2V17.6H140.5z" />
        <path
          d="M146.9,28.1v5.2h-4.1V17.6h7c3.4,0,5.7,1.9,5.7,5.3c0,2.4-1.2,4-3.1,4.7l3.7,5.6h-4.5l-3.3-5.2H146.9z
			 M146.9,24.8h2.5c1.5,0,2.1-0.7,2.1-1.9s-0.7-1.9-2.1-1.9h-2.5V24.8z"
        />
        <path d="M72.6,54.3V38.6h11v3.5h-7v3.1H83v3.5h-6.4v5.5H72.6z" />
        <path d="M89.6,54.3h-4.1V38.6h4.1V54.3z" />
        <path
          d="M96,49.1v5.2H92V38.6h7c3.4,0,5.7,1.9,5.7,5.3c0,2.4-1.2,4-3.1,4.7l3.7,5.6h-4.5l-3.3-5.2H96z M96,45.8h2.5
			c1.5,0,2.1-0.7,2.1-1.9s-0.7-1.9-2.1-1.9H96V45.8z"
        />
        <path
          d="M110,49.6c0.3,1.1,1.2,1.6,2.5,1.6c1.2,0,2.1-0.5,2.1-1.5c0-0.7-0.5-1.1-1.5-1.3l-3-0.6c-2.3-0.5-4-1.7-4-4.4
			c0-3.1,2.5-5.1,6-5.1c3.8,0,6,2.1,6.4,4.8h-4c-0.3-0.9-1-1.5-2.4-1.5c-1.2,0-2,0.5-2,1.4c0,0.7,0.5,1.1,1.3,1.2l3.1,0.7
			c2.7,0.6,4.2,2.1,4.2,4.5c0,3.2-2.7,5-6.1,5c-3.7,0-6.3-1.8-6.9-4.9H110z"
        />
        <path d="M119.6,38.6h13.8v3.5h-4.8v12.1h-4.1V42.1h-4.8V38.6z" />
        <path d="M147.8,38.6l6,15.7h-4.2l-1.1-3h-5.7l-1.1,3h-4.1l6-15.7H147.8z M144,47.9h3.2l-1.6-4.4L144,47.9z" />
        <path d="M159.2,54.3h-4.1V38.6h4.1V54.3z" />
        <path
          d="M161.6,38.6h5.9c4.9,0,7.9,3.1,7.9,7.8c0,4.8-3,7.8-7.9,7.8h-5.9V38.6z M167.3,50.7c2.6,0,4.1-1.7,4.1-4.3
			c0-2.7-1.5-4.3-4.1-4.3h-1.7v8.6H167.3z"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
