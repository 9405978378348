import { Dispatch, SetStateAction, useMemo } from "react";

import { useCurrentUser } from "@state/hooks";

import { useLocalStorage } from "./useLocalStorage";

export type MarkerType = "unit" | "store";

export type MarkerMode =
  | "readiness"
  | "incidents"
  | "escalations"
  | "shipments";

export type MarkerViewState = {
  type: MarkerType;
  mode: MarkerMode;
};

export type UserPreferences = {
  markerView: MarkerViewState;
  mapView: "default" | "satellite";
};

export const useUserPreference = <TSlice extends keyof UserPreferences>({
  key,
  defaultValue,
}: {
  key: TSlice;
  defaultValue: UserPreferences[TSlice];
}): [
  UserPreferences[TSlice],
  Dispatch<SetStateAction<UserPreferences[TSlice]>>,
] => {
  const { data: currentUser } = useCurrentUser();

  const localStorageKey = useMemo(
    () => (currentUser ? `${currentUser.userId}-${key}` : "default"),
    [currentUser, key],
  );

  const [value, setValue] = useLocalStorage(localStorageKey, defaultValue);

  return [value, setValue];
};
