import styled from "styled-components";

const Pill = styled.div<{
  type?: "default" | "action";
  position?: string;
  background?: string;
}>`
  padding: 1px 8px;

  color: ${({ theme, type }) =>
    type === "default"
      ? theme.palettes.body.small
      : theme.palettes.body.foreground};
  font-size: 12px;

  background: ${({ theme, type, background }) =>
    type === "default"
      ? theme.palettes.body.dim
      : background
        ? background
        : theme.palettes.states.good.background};
  border-radius: 100px;

  display: flex;
  align-items: ${({ position }) => position || "center"};
`;

export default Pill;
