import { ColumnDef } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";

import useAddNewGroup from "@components/AddNewGroup";
import Button from "@components/Button";
import DateRangeForDays from "@components/DateRangeForDays";
import { DonutValue } from "@components/Donut";
import ErrorWell from "@components/ErrorWell";
import { LabelledSelect, LabelledSwitch } from "@components/FormControls";
import GroupHeader from "@components/GroupHeader";
import GroupedList from "@components/GroupedList";
import Icon from "@components/Icon";
import { ListItemPlaceholder } from "@components/ListItem";
import {
  BarVerticalColumn,
  BarVerticalMetric,
  DonutMetric,
  EscalationsVerticalBarMetric,
  MetricsRow,
  NumberMetric,
} from "@components/Metric";
import MultiButton from "@components/MultiButton";
import { Page } from "@components/Page";
import Scroller from "@components/Scroller";
import { Table } from "@components/Table";
import { IconWell, SmallText } from "@design/helpers";
import DS from "@design/system";
import AddStoreToGroupModal from "@modals/AddStoreToGroupModal";
import DeleteGroupModal from "@modals/DeleteGroupModal";
import {
  useCurrentCompanyId,
  useCurrentUser,
  useDashboard,
  useGroup,
  useGroupsWithStoreCount,
  useHasPermission,
  useIncidentStats,
  usePageTitle,
  useStoresInGroup,
  useStoresReadiness,
  useUnits,
} from "@state/hooks";
import { DateFormat, dateFormatter } from "@util/dateFormat";
import {
  downloadAedReport,
  downloadAedReportCsv,
  downloadIncidentStats,
  downloadSummaryReport,
} from "@util/downloads";
import { IncidentSummary } from "@util/incidents";
import { Ready, UnitStockStatus, Active, getUnitTypes } from "@util/unit";
import useParamsUpper from "@util/useParamsUpper";
import { useTerminologies } from "@util/useTerminologies";
import { useThemeHelper } from "@util/useThemeHelper";

const displayTime = (hour: number) => {
  const from = new Date(0);
  from.setHours(hour);

  const to = new Date(0);
  to.setHours(hour + 1);

  const start = dateFormatter(from, DateFormat.timeOnly);
  const end = dateFormatter(to, DateFormat.timeOnly);

  return `${start}–${end}`;
};

const GroupOverview = () => {
  const { t } = useTranslation();
  const { palettes } = useTheme();
  const { eventCategoryIcon, icon, severityPalette } = useThemeHelper();
  const history = useHistory();
  const { groupId } = useParamsUpper<{ groupId: string }>();

  const { hasPermission } = useHasPermission();
  const companyId = useCurrentCompanyId();
  const { data: currentUser } = useCurrentUser();
  const { data: groupName } = useGroup(groupId);
  const { data: stores } = useStoresInGroup(groupId, true);
  const { data: units } = useUnits({ groupId });
  const { categoryLabel, devicesReadyCaption, devicesOnlineCaption } =
    useTerminologies();

  const {
    readinesses,
    isLoading: isReadinessLoading,
    isError: isReadinessError,
  } = useStoresReadiness(stores?.map((store) => store.StoreId));
  const {
    data: group,
    isLoading: isGroupLoading,
    isError: isGroupError,
  } = useGroup(groupId);
  const { data: groupsWithStoreCount } = useGroupsWithStoreCount();
  const {
    data: incidentStats,
    isLoading: isIncidentStatsLoading,
    isError: isIncidentStatsError,
  } = useIncidentStats({ groupId });

  const [AddNewGroup, showAddGroup] = useAddNewGroup(group);

  const [childGroups, setChildGroups] = useState<Api.GroupWithCount[]>([]);
  const [groupToDelete, setGroupToDelete] = useState<Api.GroupWithCount>();
  const [days, setDays] = useState<number>(7);
  const [showMinorIncidents, setShowMinorIncidents] = useState(false);

  usePageTitle(`Overview ${groupName ? " - " + groupName.Name : ""}`);

  // Stats
  const dashboard = useDashboard({
    groupId,
    numberDays: days,
    includeLowIncidents: showMinorIncidents,
  });

  const data = useMemo(
    () =>
      dashboard.data?.Dashboard.IncidentRankings.sort((a, b) =>
        a.StoreName.localeCompare(b.StoreName),
      ) ?? [],
    [dashboard.data?.Dashboard.IncidentRankings],
  );

  const footerData = useMemo(() => {
    const initialFooterData = {
      totalIncidents: 0,
      totalHigh: 0,
      totalMedium: 0,
      totalLow: 0,
      totalBurns: 0,
      totalEyes: 0,
      totalCuts: 0,
      totalStf: 0,
      totalOthers: 0,
    };

    return dashboard.data?.Dashboard?.IncidentRankings.map((incident) => ({
      totalIncidents: incident.TotalIncidents,
      totalHigh: incident.TotalHigh,
      totalMedium: incident.TotalMedium,
      totalLow: incident.TotalLow,
      totalBurns: incident.TotalBurns,
      totalEyes: incident.TotalEyes,
      totalCuts: incident.TotalCuts,
      totalStf: incident.TotalSTF,
      totalOthers: incident.TotalOthers,
    })).reduce(
      (prev, curr) => ({
        totalIncidents: prev.totalIncidents + curr.totalIncidents,
        totalHigh: prev.totalHigh + curr.totalHigh,
        totalMedium: prev.totalMedium + curr.totalMedium,
        totalLow: prev.totalLow + curr.totalLow,
        totalBurns: prev.totalBurns + curr.totalBurns,
        totalEyes: prev.totalEyes + curr.totalEyes,
        totalCuts: prev.totalCuts + curr.totalCuts,
        totalStf: prev.totalStf + curr.totalStf,
        totalOthers: prev.totalOthers + curr.totalOthers,
      }),
      initialFooterData,
    );
  }, [dashboard]);

  const columns = useMemo<ColumnDef<IncidentSummary>[]>(
    () => [
      {
        id: "storeName",
        header: t("term.store_one") ?? "",
        cell: ({ row: { original: incidents } }) =>
          incidents && (
            <div
              style={{
                color: palettes.well.foreground,
                fontWeight: "bold",
              }}
            >
              {incidents.StoreName}
            </div>
          ),
      },
      {
        id: "city",
        header: "City",
        cell: ({ row: { original: incidents } }) =>
          incidents && (
            <div
              style={{
                color: palettes.well.foreground,
                fontWeight: "bold",
              }}
            >
              {incidents.StoreCity}
            </div>
          ),
      },
      {
        id: "total",
        header: "Total",

        footer:
          footerData && footerData.totalIncidents !== 0
            ? `${footerData?.totalIncidents}`
            : "",

        cell: ({ row: { original: incidents } }) =>
          incidents && (
            <div
              style={{
                color: palettes.well.foreground,
              }}
            >
              {incidents.TotalIncidents === 0 ? "" : incidents.TotalIncidents}
            </div>
          ),
      },
      {
        id: "high",
        header: t("severity.high") ?? "",
        footer:
          footerData && footerData.totalHigh !== 0
            ? `${footerData?.totalHigh}`
            : "",
        cell: ({ row: { original: incidents } }) =>
          incidents && (
            <div
              style={{
                color: palettes.well.foreground,
              }}
            >
              {incidents.TotalHigh === 0 ? "" : incidents.TotalHigh}
            </div>
          ),
      },
      {
        id: "medium",
        header: t("severity.medium") ?? "",
        footer:
          footerData && footerData.totalMedium !== 0
            ? `${footerData?.totalMedium}`
            : "",
        cell: ({ row: { original: incidents } }) =>
          incidents && (
            <div
              style={{
                color: palettes.well.foreground,
              }}
            >
              {incidents.TotalMedium === 0 ? "" : incidents.TotalMedium}
            </div>
          ),
      },

      {
        id: "low-incidents",
        header: t("severity.low") ?? "",
        enableHiding: true,
        footer:
          footerData && footerData.totalLow !== 0
            ? `${footerData?.totalLow}`
            : "",
        cell: ({ row: { original: incidents } }) =>
          incidents && (
            <div
              style={{
                color: palettes.well.foreground,
              }}
            >
              {incidents.TotalLow === 0 ? "" : incidents.TotalLow}
            </div>
          ),
      },
      {
        id: "burns",
        header: t("incidentCategory.burn") ?? "",
        footer:
          footerData && footerData.totalBurns !== 0
            ? `${footerData?.totalBurns}`
            : "",
        cell: ({ row: { original: incidents } }) =>
          incidents && (
            <div
              style={{
                color: palettes.well.foreground,
              }}
            >
              {incidents.TotalBurns === 0 ? "" : incidents.TotalBurns}
            </div>
          ),
      },
      {
        id: "eyes",
        header: t("incidentCategory.eye") ?? "",
        footer:
          footerData && footerData.totalEyes !== 0
            ? `${footerData?.totalEyes}`
            : "",
        cell: ({ row: { original: incidents } }) =>
          incidents && (
            <div
              style={{
                color: palettes.well.foreground,
              }}
            >
              {incidents.TotalEyes === 0 ? "" : incidents.TotalEyes}
            </div>
          ),
      },
      {
        id: "cuts",
        header: t("incidentCategory.cut") ?? "",
        footer:
          footerData && footerData.totalCuts !== 0
            ? `${footerData?.totalCuts}`
            : "",
        cell: ({ row: { original: incidents } }) =>
          incidents && (
            <div
              style={{
                color: palettes.well.foreground,
              }}
            >
              {incidents.TotalCuts === 0 ? "" : incidents.TotalCuts}
            </div>
          ),
      },
      {
        id: "stf",
        header: t("incidentCategory.stf") ?? "",
        footer:
          footerData && footerData.totalStf !== 0
            ? `${footerData?.totalStf}`
            : "",
        cell: ({ row: { original: incidents } }) =>
          incidents && (
            <div
              style={{
                color: palettes.well.foreground,
              }}
            >
              {incidents.TotalSTF === 0 ? "" : incidents.TotalSTF}
            </div>
          ),
      },
      {
        id: "others",
        header: t("incidentCategory.unknown"),
        footer:
          footerData && footerData.totalOthers !== 0
            ? `${footerData?.totalOthers}`
            : "",
        cell: ({ row: { original: incidents } }) =>
          incidents && (
            <div
              style={{
                color: palettes.well.foreground,
              }}
            >
              {incidents.TotalOthers === 0 ? "" : incidents.TotalOthers}
            </div>
          ),
      },
    ],
    [footerData, t, palettes.well.foreground],
  );

  const handleIncidentSeverityChange = useCallback(
    (severity: Api.EventSeverityLevel) => {
      history.push(
        `/group/${group?.GroupId}/incidents?severity=${severity}&days=${days}`,
      );
    },
    [days, group?.GroupId, history],
  );

  const handleIncidentCategoryChange = useCallback(
    (category: Api.EventCategory) => {
      history.push(
        `/group/${
          group?.GroupId
        }/incidents?category-type=${category}&days=${days}${
          showMinorIncidents ? "" : "&severity=High,Medium"
        }`,
      );
    },
    [days, group?.GroupId, history, showMinorIncidents],
  );

  const handleDaysGoneChange = useCallback(() => {
    history.push(`/group/${group?.GroupId}/devices?days-since=1`);
  }, [group?.GroupId, history]);

  const handleReadinessChange = useCallback(
    (readiness: Ready) => {
      history.push(`/group/${group?.GroupId}/devices?readiness=${readiness}`);
    },
    [group?.GroupId, history],
  );

  const handleLowReadinessChange = useCallback(() => {
    history.push(`/group/${group?.GroupId}/devices?readiness=not-ready`);
  }, [group?.GroupId, history]);

  const handleOfflineChange = useCallback(
    (active: Active) => {
      history.push(`/group/${group?.GroupId}/devices?active=${active}`);
    },
    [group?.GroupId, history],
  );

  const handleStockLevelChange = useCallback(
    (stockLevel: UnitStockStatus) => {
      history.push(
        `/group/${group?.GroupId}/devices?stock-level=${stockLevel}`,
      );
    },
    [group?.GroupId, history],
  );

  const readyRating = useMemo(() => {
    const readyRating = readinesses
      ? Number(
          readinesses
            .filter(({ readiness }) => (readiness ?? -1) > -1)
            .reduce(
              (p, { readiness }, _i, { length }) =>
                p + (readiness ?? 0) / length,
              0,
            )
            .toFixed(1),
        )
      : 0;

    return readyRating === Infinity ||
      dashboard.data?.Dashboard.UnitsTotal === 0
      ? undefined
      : readyRating;
  }, [dashboard.data, readinesses]);

  const incidentsByCategory = useMemo(() => {
    return (
      [
        "Cuts",
        "Burns",
        "Eyes",
        "STF",
        "Cleaning",
        "Safety",
      ] as Api.EventCategory[]
    ).map((id) => {
      const count =
        dashboard.data?.Dashboard.IncidentsByProductCategory.find(
          (incident) => incident.key === id,
        )?.incidentCount ?? 0;

      return {
        id,
        title: `${categoryLabel(id)} (${count})`,
        count,
        icon: eventCategoryIcon(id),
      };
    });
  }, [
    eventCategoryIcon,
    categoryLabel,
    dashboard.data?.Dashboard.IncidentsByProductCategory,
  ]);

  const incidentsByTime = useMemo(() => {
    const data: BarVerticalColumn<string>[] = Array.from(new Array(24)).map(
      (_, hour) => ({
        id: `time-${hour}`,
        title: `${displayTime(hour)}`,
        count: [],
      }),
    );

    if (showMinorIncidents) {
      dashboard.data?.Dashboard.IncidentsByTimeOfDayLow?.forEach(
        ({ Item1: hour, Item2: count }) =>
          (data[hour as number].count as number[]).push(count),
      );
    }
    dashboard.data?.Dashboard.IncidentsByTimeOfDayMedium?.forEach(
      ({ Item1: hour, Item2: count }) =>
        (data[hour as number].count as number[]).push(count),
    );
    dashboard.data?.Dashboard.IncidentsByTimeOfDayHigh?.forEach(
      ({ Item1: hour, Item2: count }) =>
        (data[hour as number].count as number[]).push(count),
    );

    return data;
  }, [
    dashboard.data?.Dashboard.IncidentsByTimeOfDayHigh,
    dashboard.data?.Dashboard.IncidentsByTimeOfDayLow,
    dashboard.data?.Dashboard.IncidentsByTimeOfDayMedium,
    showMinorIncidents,
  ]);

  const [showAddStoreModal, closeAddStoreModal] = useModal(
    () => (
      <AddStoreToGroupModal groupId={groupId} onClose={closeAddStoreModal} />
    ),
    [groupId],
  );

  const [showDeleteGroupModal, closeDeleteGroupModal] = useModal(() => {
    if (!groupToDelete) return null;

    return (
      <DeleteGroupModal
        group={groupToDelete}
        onConfirm={() => closeDeleteGroupModal()}
        onClose={() => closeDeleteGroupModal()}
      />
    );
  }, [groupToDelete]);

  const handleDeleteGroup = useCallback(
    (group: Api.GroupWithCount) => {
      setGroupToDelete(group);
      showDeleteGroupModal();
    },
    [showDeleteGroupModal],
  );

  const handleAddStoreClick = useCallback(() => {
    showAddStoreModal();
  }, [showAddStoreModal]);

  const handleShowMinorIncidentsChanged: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((e) => {
      setShowMinorIncidents(e.target.checked);
    }, []);

  const metricColors = useMemo(
    () => [
      ...(showMinorIncidents ? [severityPalette("Low").background] : []),
      severityPalette("Medium").background,
      severityPalette("High").background,
    ],
    [showMinorIncidents, severityPalette],
  );

  useEffect(() => {
    if (!group || !group.Groups || !groupsWithStoreCount) {
      setChildGroups([]);
      return;
    }

    setChildGroups(
      group.Groups.filter(
        (group) => group.Type === "Adhoc" || group.Type === "Franchise",
      )
        .map(
          (g) =>
            groupsWithStoreCount.filter((gr) => gr.GroupId === g.GroupId)[0],
        )
        .filter((g) => g)
        .sort((a, b) => a.Name.localeCompare(b.Name)),
    );
  }, [group, groupsWithStoreCount]);

  return (
    <Page showSidebar>
      <Scroller>
        <div
          style={{
            padding: DS.margins.regular,
            display: "grid",
            gap: DS.margins.regular,
          }}
        >
          <div>
            <GroupHeader separator={false}>
              {t("groupOverview.title.units")}
            </GroupHeader>
            <MetricsRow>
              <EscalationsVerticalBarMetric groupId={groupId} />
              <DonutMetric
                tKey="metrics.unitsReady"
                label={t("metrics.unitStockStatus.title")}
                onChange={(stockLevel) => handleStockLevelChange(stockLevel)}
                data={[
                  {
                    id: "stocked",
                    title: t("metrics.unitStockStatus.caption.stocked"),
                    count: dashboard.data?.Dashboard.UnitsStocked ?? 0,
                    color: palettes.states.good.background,
                  },
                  {
                    id: "in-transit",
                    title: t("metrics.unitStockStatus.caption.inTransit"),
                    count: dashboard.data?.Dashboard.UnitsRefillInTransit ?? 0,
                    color: palettes.body.accent,
                  },
                  {
                    id: "needs-refill",
                    title: t("metrics.unitStockStatus.caption.needsRefill"),
                    count:
                      dashboard.data?.Dashboard.UnitsNeedRefillNonCritical ?? 0,
                    color: palettes.states.warning.background,
                  },
                  {
                    id: "critical",
                    title: t("metrics.unitStockStatus.caption.critical"),
                    count: dashboard.data?.Dashboard.UnitsCritical ?? 0,
                    color: palettes.states.bad.background,
                  },
                ]}
                isLoading={dashboard.isLoading}
                isError={dashboard.isError}
                showLegend
              />
              <DonutMetric
                tKey="metrics.unitsOnline"
                label={t("metrics.unitsOnline.title")}
                onChange={(active) => handleOfflineChange(active)}
                caption={devicesOnlineCaption(
                  dashboard.data?.Dashboard.UnitsActive ?? 0,
                  dashboard.data?.Dashboard.UnitsTotal ?? 0,
                  dashboard.data?.Dashboard.UnitsTurnedOff ?? 0,
                )}
                icon="plug"
                data={[
                  {
                    id: "online",
                    title: t("metrics.unitsOnline.value.online"),
                    count: dashboard.data?.Dashboard.UnitsActive ?? 0,
                    color: palettes.states.good.background,
                  },
                  {
                    id: "offline",
                    title: t("metrics.unitsOnline.value.offline"),
                    count: dashboard.data?.Dashboard.UnitsTurnedOff ?? 0,
                    color: palettes.states.bad.background,
                  },
                ]}
                isLoading={dashboard.isLoading}
                isError={dashboard.isError}
                half
              />
              <DonutMetric
                label={t("metrics.unitsReady.title")}
                onChange={(readiness) => handleReadinessChange(readiness)}
                caption={devicesReadyCaption(
                  dashboard.data?.Dashboard.UnitsReady ?? 0,
                  dashboard.data?.Dashboard.UnitsTotal ?? 0,
                )}
                icon="shield-alt"
                data={[
                  {
                    id: "ready",
                    title: t("metrics.unitsReady.value.ready"),
                    count: dashboard.data?.Dashboard.UnitsReady ?? 0,
                    color: palettes.states.good.background,
                  },
                  {
                    id: "not-ready",
                    title: t("metrics.unitsReady.value.notReady"),
                    count:
                      (dashboard.data?.Dashboard.UnitsTotal ?? 0) -
                      (dashboard.data?.Dashboard.UnitsReady ?? 0),
                    color: palettes.states.bad.background,
                  },
                ]}
                isLoading={dashboard.isLoading}
                isError={dashboard.isError}
                half
              />

              <NumberMetric
                label={t("metrics.daysSinceLastHigh.title")}
                value={incidentStats?.daysSinceLastHighSeverityIncident}
                onChange={handleDaysGoneChange}
                title={t("metrics.daysSinceLastHigh.tooltip")}
                isLoading={isIncidentStatsLoading}
                isError={isIncidentStatsError}
              />

              <NumberMetric
                label={t("metrics.unitReadyRating.title")}
                title={t("metrics.unitReadyRating.tooltip")}
                onChange={handleLowReadinessChange}
                value={readyRating}
                isLoading={isReadinessLoading}
                isError={isReadinessError}
              />
            </MetricsRow>
          </div>

          <div>
            <GroupHeader
              controls={[
                {
                  element: (
                    <LabelledSwitch
                      tKey="groupOverview.showLowIncidents"
                      onChange={handleShowMinorIncidentsChanged}
                    />
                  ),
                },
                {
                  element: (
                    <LabelledSelect
                      compact
                      label={t("groupOverview.timePeriod.label")}
                      options={[
                        {
                          label: t("groupOverview.timePeriod.values.24hours"),
                          value: "1",
                        },
                        {
                          label: t("groupOverview.timePeriod.values.7days"),
                          value: "7",
                        },
                        {
                          label: t("groupOverview.timePeriod.values.14days"),
                          value: "14",
                        },
                        {
                          label: t("groupOverview.timePeriod.values.30days"),
                          value: "30",
                        },
                        {
                          label: t("groupOverview.timePeriod.values.3months"),
                          value: "91",
                        },
                        {
                          label: t("groupOverview.timePeriod.values.6months"),
                          value: "182",
                        },
                        {
                          label: t("groupOverview.timePeriod.values.12months"),
                          value: "365",
                        },
                      ]}
                      value={days}
                      onChange={(e) => setDays(Number(e.target.value))}
                    />
                  ),
                },
              ]}
            >
              <span>
                {t("groupOverview.title.incidents")}{" "}
                <span style={{ fontSize: 12, fontWeight: 400 }}>
                  <DateRangeForDays numberDays={days} />
                </span>
              </span>
            </GroupHeader>

            <MetricsRow>
              <BarVerticalMetric
                label={t("metrics.incidentsByCategory.title")}
                value={dashboard.data?.Dashboard.TotalIncidents ?? 0}
                size="heavy"
                data={incidentsByCategory}
                onChange={(category) => handleIncidentCategoryChange(category)}
                isLoading={dashboard.isLoading}
                isError={dashboard.isError}
              />

              <DonutMetric
                label={t("metrics.incidentsBySeverity.title")}
                data={
                  [
                    ...(showMinorIncidents
                      ? [
                          {
                            id: "Low",
                            title: t("metrics.incidentsBySeverity.caption.low"),
                            count:
                              dashboard.data?.Dashboard.IncidentsByIncidentType.find(
                                (d) => d.key === "Low",
                              )?.incidentCount ?? 0,
                            color: severityPalette("Low").background,
                          },
                        ]
                      : []),

                    {
                      id: "Medium",
                      title: t("metrics.incidentsBySeverity.caption.medium"),
                      count:
                        dashboard.data?.Dashboard.IncidentsByIncidentType.find(
                          (d) => d.key === "Medium",
                        )?.incidentCount ?? 0,
                      color: severityPalette("Medium").background,
                    },
                    {
                      id: "High",
                      title: t("metrics.incidentsBySeverity.caption.high"),
                      count:
                        dashboard.data?.Dashboard.IncidentsByIncidentType.find(
                          (d) => d.key === "High",
                        )?.incidentCount ?? 0,
                      color: severityPalette("High").background,
                    },
                  ] as DonutValue<Api.EventSeverityLevel>[]
                }
                onChange={(severity) => handleIncidentSeverityChange(severity)}
                isLoading={dashboard.isLoading}
                isError={dashboard.isError}
                showLegend
              />

              <NumberMetric
                label="Average ready rating over period"
                value={
                  dashboard.data?.Dashboard.UnitsTotal === 0
                    ? undefined
                    : dashboard.data?.Dashboard.UnitReadinessPercent
                }
                isLoading={dashboard.isLoading}
                isError={dashboard.isError}
              />

              <BarVerticalMetric
                label={t("metrics.incidentsByTimeOfDay.title")}
                value={""}
                size="light"
                color={metricColors}
                data={incidentsByTime}
                isLoading={dashboard.isLoading}
                isError={dashboard.isError}
                selected={null}
              />
            </MetricsRow>
          </div>

          {dashboard.isLoading ? (
            Array.from({ length: 3 }).map((_v, i) => (
              <div
                key={`placeholder-${i}`}
                style={{
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderBottom: `solid 1px ${palettes.body.border}`,
                }}
              >
                <ListItemPlaceholder />
              </div>
            ))
          ) : dashboard.isError || !dashboard.data ? (
            <ErrorWell style={{ textAlign: "center" }}>
              <Icon
                name="exclamation-alt-circle"
                color={palettes.states.bad.background}
              />{" "}
              {t("groupOverview.incidentSummary.error")}
            </ErrorWell>
          ) : !dashboard.data.Dashboard.IncidentRankings.length ? (
            <IconWell style={{ justifyContent: "center" }}>
              <Icon
                name="check-alt-circle"
                color={palettes.states.good.background}
              />
              <SmallText>
                <Trans>
                  {t("groupOverview.incidentSummary.empty", { count: days })}
                  <strong></strong>
                </Trans>
              </SmallText>{" "}
            </IconWell>
          ) : (
            <Table
              data={data}
              columns={columns}
              showFooter={true}
              columnVisibility={{ "low-incidents": showMinorIncidents }}
            />
          )}
        </div>
      </Scroller>
      <div
        style={{
          overflow: "hidden",
          borderLeft: `solid 1px ${palettes.body.border}`,
          display: "grid",
          gridTemplateRows: "auto auto 1fr",
        }}
      >
        <div
          style={{
            padding: DS.margins.regular,
            display: "grid",
            gap: DS.margins.micro,
          }}
        >
          <Button onClick={handleAddStoreClick} stretch>
            <Icon name={icon("store")} />{" "}
            {t("groupOverview.button.addStoresToGroup")}
          </Button>
          <MultiButton
            items={[
              {
                key: "summary-report",
                label: "Summary report",
                href: downloadSummaryReport({
                  companyId,
                  userId: currentUser?.id,
                  groupId,
                  includeLowIncidents: showMinorIncidents,
                }),
              },
              ...(getUnitTypes(units ?? []).some(
                (type) => type === "AutomatedExternalDefibrillator",
              )
                ? [
                    {
                      key: "aed-report",
                      label: t("groupOverview.report.aedReport"),
                      href: downloadAedReport({
                        companyId,
                        userId: currentUser?.id,
                        groupId,
                      }),
                    },
                    {
                      key: "aed-report-csv",
                      label: t("groupOverview.report.aedReportCsv"),
                      href: downloadAedReportCsv({
                        companyId,
                        groupId,
                      }),
                    },
                  ]
                : []),
              ...(hasPermission("events_view_customer_events")
                ? [
                    {
                      key: "incident-stats",
                      label: t("groupOverview.report.incidentStats"),
                      href: downloadIncidentStats(groupId),
                    },
                  ]
                : []),
            ]}
          >
            {t("groupOverview.button.reportDownloads")}
          </MultiButton>
        </div>
        <Scroller>
          <div
            style={{
              padding: DS.margins.regularCss("rbl"),
            }}
          >
            <GroupedList
              title="Sub-groups in group"
              separator
              emptyLabel="No sub-groups in this group."
              data={childGroups}
              controls={
                group && hasPermission("stores_create_edit_delete_groups")
                  ? [
                      {
                        icon: "plus",
                        title: "Add a new group",
                        onClick: () => showAddGroup(),
                      },
                    ]
                  : []
              }
              isLoading={isGroupLoading}
              isError={isGroupError}
              listItemKey={(g) => g.GroupId}
              listItemTitle={(g) => g.Name}
              listItemDescription={(g) =>
                `${t("term.store", { count: g.storeCount })}`
              }
              listItemContextMenu={(group) =>
                hasPermission("stores_create_edit_delete_groups")
                  ? [
                      {
                        key: "delete-group",
                        icon: "trash-alt",
                        label: "Delete group",
                        onClick: (close) => {
                          handleDeleteGroup(group);
                          close();
                        },
                      },
                    ]
                  : undefined
              }
              onListItemClick={(group) =>
                history.push(`/group/${group.GroupId}/overview`)
              }
            />
            <AddNewGroup />
          </div>
        </Scroller>
      </div>
    </Page>
  );
};

export default GroupOverview;
