import { useWizardContext } from "@components/ModalWizardHelper";

import Button from "../Button";
import Icon from "../Icon";

const NotificationsTabFooter = () => {
  const { next } = useWizardContext();

  return (
    <Button buttonType="progressive" onClick={next}>
      Next, access <Icon name="arrow-alt-right" />
    </Button>
  );
};

export default NotificationsTabFooter;
