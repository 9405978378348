const ErrorPageImage = ({ width }: { width: number }) => (
  <svg
    width={width}
    viewBox="0 0 511 693"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M511 383.189C511 493.136 511 548.11 475.812 579.293C440.625 610.475 386.05 603.864 276.9 590.642L183.84 579.37C96.329 568.769 52.5734 563.469 26.2867 533.805C5.0994e-05 504.142 4.71408e-05 460.067 3.94344e-05 371.916L2.81976e-05 243.382C2.04104e-05 154.307 1.65168e-05 109.77 26.6762 80.0049C53.3524 50.2399 97.6238 45.3799 186.167 35.6598L279.227 25.4437C387.408 13.5677 441.499 7.62971 476.249 38.7741C511 69.9185 511 124.334 511 233.166L511 383.189Z"
      fill="#C1EAF8"
    />
    <path
      d="M159.828 278.757C213.398 271.423 329.339 275.504 364.542 350.502C382.784 389.367 372.187 446.179 352.106 500.465L289.927 479.644L263.221 607.829C221.742 606.235 137.252 600.942 131.13 592.524C125.008 584.106 123.477 545.164 123.477 526.745C120.408 527.382 117.526 527.948 114.868 528.431C81.1952 534.553 70.8638 506.11 69.9072 491.124C71.1827 471.673 76.2208 418.421 86.1695 361.025C96.1182 303.629 139.421 282.265 159.828 278.757Z"
      fill="#65B8D4"
      stroke="black"
      strokeOpacity="0.8"
      strokeWidth="1.91321"
    />
    <path
      d="M278.363 661.75L281.479 638.047C302.663 606.103 331.834 554.081 352.105 499.706L284.595 477.276L255.6 615.374L253.436 625.68C240.281 631.863 217.916 646.292 233.703 654.536C249.49 662.781 236.819 675.148 228.51 680.301C221.239 686.828 213.969 697.409 243.05 687.515C272.132 677.621 278.71 666.216 278.363 661.75Z"
      fill="#E8F8FE"
    />
    <path
      d="M284.595 477.276L352.105 499.706C331.834 554.081 302.663 606.103 281.479 638.047L278.363 661.75C278.71 666.216 272.132 677.621 243.05 687.515C213.969 697.409 221.239 686.828 228.51 680.301C236.819 675.148 249.49 662.781 233.703 654.536C217.916 646.292 240.281 631.863 253.436 625.68L255.6 615.374L284.595 477.276ZM284.595 477.276L290.628 433.167"
      stroke="black"
      strokeWidth="1.91321"
    />
    <path
      d="M283.23 287.366C283.995 343.997 247.517 453.178 228.704 501.646L82.3428 518.865C195.605 447.694 255.488 332.965 271.751 283.54L283.23 287.366Z"
      fill="#D9D9D9"
      stroke="black"
      strokeOpacity="0.8"
      strokeWidth="1.91321"
    />
    <path
      d="M113.911 528.842C80.2381 534.965 69.9068 506.522 68.9502 491.535C68.9502 453.271 132.724 451.357 164.611 455.184L250.705 460.923C251.662 459.329 255.488 455.949 263.141 455.184C270.794 454.418 274.621 458.691 275.577 460.923C277.171 466.344 279.404 480.629 275.577 494.405C271.751 508.18 265.692 507.159 263.141 504.927L247.836 494.405C219.641 502.628 158.334 519.725 122.52 527.156C119.451 527.793 116.569 528.359 113.911 528.842Z"
      fill="#E8F8FE"
      stroke="black"
      strokeWidth="1.91321"
    />
    <path
      d="M244.966 235.71C244.966 247.954 256.445 272.061 262.184 282.583C262.503 286.091 259.506 295.976 244.966 307.455C230.425 318.934 212.122 317.978 204.788 316.065L193.309 291.193C186.931 288.642 172.455 276.844 165.567 250.059C158.68 223.274 152.494 205.098 150.262 199.359C147.392 182.459 149.688 147.702 181.83 143.875C222.007 139.092 244.966 147.55 244.966 196.489C256.832 201.851 244.966 222.798 244.966 235.71Z"
      fill="#E8F8FE"
    />
    <path
      d="M230.616 199.359C234.207 199.508 242.766 195.495 244.966 196.489M244.966 196.489C256.832 201.851 244.966 222.798 244.966 235.71C244.966 247.954 256.445 272.061 262.184 282.583C262.503 286.091 259.506 295.976 244.966 307.455C230.425 318.934 212.122 317.978 204.788 316.065L193.309 291.193C186.931 288.642 172.455 276.844 165.567 250.059C158.68 223.274 152.494 205.098 150.262 199.359C147.392 182.459 149.688 147.702 181.83 143.875C222.007 139.092 244.966 147.55 244.966 196.489Z"
      stroke="black"
      strokeWidth="1.91321"
    />
    <path
      d="M81.3857 519.822C92.865 392.019 146.754 305.223 172.263 277.8H182.786C162.123 338.258 204.788 452.221 228.703 501.646L121.563 528.431C94.0129 534.553 82.0235 524.923 81.3857 519.822Z"
      fill="white"
      stroke="black"
      strokeOpacity="0.8"
      strokeWidth="1.91321"
    />
    <path
      d="M264.098 165.877C270.22 181.948 253.894 218.491 244.966 234.753C244.966 234.753 249.749 215.621 244.966 205.098C240.183 194.576 229.66 200.315 229.66 200.315L237.313 229.013C239.864 245.594 238.269 281.435 211.484 292.149C184.699 302.863 171.626 274.93 168.437 259.625C170.669 258.349 172.505 253.733 176.09 251.015C183.166 245.651 186.613 244.319 198.092 245.276C209.571 246.232 225.068 250.059 229.66 234.753C234.252 219.447 217.224 182.14 207.658 163.964C207.339 165.558 200.579 167.982 179.916 176.4C154.088 186.923 147.392 185.009 140.696 184.053C135.339 183.288 131.448 172.892 130.173 167.791C130.173 165.558 131.512 159.946 136.869 155.355C143.565 149.615 148.348 139.092 159.828 124.743C171.307 110.394 187.569 116.134 202.875 116.134C215.119 116.134 225.833 126.975 229.66 132.396C232.211 132.077 238.843 132.205 244.966 135.266C252.618 139.092 256.445 145.789 264.098 165.877Z"
      fill="#29C684"
      stroke="black"
      strokeWidth="1.91321"
    />
    <path
      d="M191.396 191.706L163.654 242.406L154.088 207.968L191.396 191.706Z"
      fill="#D9D9D9"
      stroke="black"
      strokeWidth="1.91321"
    />
    <path
      d="M263.141 161.094C252.427 174.869 185.975 199.359 154.088 209.881C147.201 205.29 148.986 191.706 150.262 185.009C213.398 169.704 240.089 133.874 244.009 134.309C252.619 135.266 260.59 153.441 263.141 161.094Z"
      fill="white"
      stroke="black"
      strokeWidth="1.91321"
    />
    <rect
      x="273.063"
      y="550.668"
      width="59.3096"
      height="21.0453"
      rx="4.78303"
      transform="rotate(-30.9372 273.063 550.668)"
      fill="white"
      stroke="black"
      strokeWidth="1.91321"
    />
    <rect
      x="293.082"
      y="543.131"
      width="15.3057"
      height="13.3925"
      rx="0.956606"
      transform="rotate(-30.9372 293.082 543.131)"
      fill="#D9D9D9"
      stroke="black"
      strokeWidth="1.91321"
    />
    <line
      x1="208.733"
      y1="340.804"
      x2="222.268"
      y2="327.27"
      stroke="black"
      strokeWidth="2.82392"
      strokeLinecap="round"
    />
    <line
      x1="222.268"
      y1="342.801"
      x2="208.733"
      y2="329.267"
      stroke="black"
      strokeWidth="2.82392"
      strokeLinecap="round"
    />
    <line
      x1="208.733"
      y1="362.528"
      x2="218.577"
      y2="352.685"
      stroke="black"
      strokeWidth="2.05376"
      strokeLinecap="round"
    />
    <line
      x1="218.577"
      y1="363.981"
      x2="208.734"
      y2="354.137"
      stroke="black"
      strokeWidth="2.05376"
      strokeLinecap="round"
    />
  </svg>
);

export default ErrorPageImage;
