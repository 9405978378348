import { Fragment } from "react";
import styled, { useTheme } from "styled-components";

import DS from "@design/system";
import { NamedTheme, themes } from "@themes";
import { Theme } from "@themes/types";

import LilSquareThing from "./LilSquareThing";
import { useThemeSwitch } from "./ThemeProvider";
import { useTooltip } from "./Tooltip";

const Container = styled.div`
  display: grid;
  gap: 4px;
  grid-auto-flow: column;
`;

function ChangeThemeButton({
  selected,
  nextTheme,
}: {
  selected: boolean;
  nextTheme: Theme;
}) {
  const { palettes } = useTheme();
  const { setTheme } = useThemeSwitch();
  const { tooltipProps } = useTooltip<HTMLButtonElement>(
    <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
      Switch to <LilSquareThing border color={nextTheme.palettes.signature} />
      <strong>{nextTheme.meta.themeName}</strong> ({nextTheme.meta.appName})
      theme
    </div>,
  );

  return (
    <button
      {...tooltipProps}
      style={{
        padding: selected ? "0 8px" : 0,
        border: 0,
        margin: 0,
        cursor: "pointer",
        borderRadius: DS.radii.pill,
        fontWeight: selected ? 600 : 400,
        color: selected
          ? palettes.buttons.neutralSelected.foreground
          : "inherit",
        background: selected
          ? palettes.buttons.neutralSelected.background
          : "transparent",
      }}
      onClick={() => setTheme(nextTheme.id as NamedTheme)}
    >
      {nextTheme.meta.themeName}
    </button>
  );
}

export default function ThemeSwitcher() {
  const theme = useTheme();

  return (
    <Container>
      {(Object.keys(themes) as NamedTheme[]).map((k, i, arr) => (
        <Fragment key={k}>
          <ChangeThemeButton selected={theme.id === k} nextTheme={themes[k]} />{" "}
          {i !== arr.length - 1 ? "|" : ""}
        </Fragment>
      ))}
    </Container>
  );
}
