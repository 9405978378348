import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapRef, MapboxGeoJSONFeature } from "react-map-gl";
import { useTheme } from "styled-components";

import Icon from "@components/Icon";
import { useShipmentsByIds, useUnit } from "@state/hooks";
import { getShipmentStatusIcon, activeShipmentsOnly } from "@util/shipments";
import { useThemeHelper } from "@util/useThemeHelper";

import { Pin, PinContent } from "./Pin";
import { UnitFeatureProperties } from "./types";

export const UnitShipmentMarker = ({
  mapRef,
  feature,
  id,
  selected,
  cluster,
  onChildChange,
}: {
  mapRef: MapRef | null;
  feature: MapboxGeoJSONFeature;
  id: string;
  selected: boolean;
  cluster?: number | null;
  onChildChange?: (child: string) => void;
}) => {
  const { t } = useTranslation();
  const { palettes } = useTheme();
  const { shipmentStatusPalette } = useThemeHelper();

  const [selectedProperties, setSelectedProperties] =
    useState<UnitFeatureProperties | null>(null);

  const { data: unit, isLoading: isLoadingStore } = useUnit(
    selectedProperties?.id ?? id,
  );
  const { data: shipments, isLoading: isLoadingShipments } = useShipmentsByIds(
    selectedProperties?.shipmentsIds.split(",").filter((id) => !!id) ?? [],
    { numberDays: 90 },
  );

  const activeShipments = useMemo(
    () => shipments?.filter(activeShipmentsOnly),
    [shipments],
  );

  const onChange = useCallback(
    (f: GeoJSON.Feature<GeoJSON.Geometry>) => {
      setSelectedProperties(f.properties as UnitFeatureProperties);
      onChildChange?.((f.properties as UnitFeatureProperties).id);
    },
    [onChildChange],
  );

  return (
    <Pin
      mapRef={mapRef}
      feature={feature}
      source="units"
      label={unit?.UnitName}
      selected={selected}
      cluster={cluster}
      onChange={onChange}
      content={() => {
        return (
          <PinContent
            label={
              <Icon
                name={
                  isLoadingStore || isLoadingShipments
                    ? "spinner"
                    : activeShipments && activeShipments[0]
                      ? getShipmentStatusIcon(activeShipments[0].Status)
                      : "minus"
                }
                color={
                  shipmentStatusPalette(activeShipments?.[0]?.Status).background
                }
                spin={isLoadingStore || isLoadingShipments}
                size={selected ? 24 : cluster ? 16 : 16}
              />
            }
            description={
              !selected && cluster ? (
                <div style={{ paddingTop: 4, color: palettes.body.small }}>
                  {t("pages.map.markers.unitCount", { count: cluster })}
                </div>
              ) : null
            }
            selected={selected}
            cluster={cluster}
          />
        );
      }}
      background={(width, height) => (
        <rect width={width} height={height} fill={palettes.body.background} />
      )}
    />
  );
};
