import { forwardRef } from "react";
import styled from "styled-components";

import DS from "@design/system";

const WIDTH = "60px";
const HEIGHT = "24px";
const SWITCH_SIZE = "16px";
const BORDER_THICKNESS = "1px";

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: ${WIDTH};

  user-select: none;
`;

const Label = styled.label`
  display: block;
  overflow: hidden;

  cursor: default;
  border-radius: 100px;
  border: solid ${BORDER_THICKNESS} ${({ theme }) => theme.palettes.body.border};
`;

const Inner = styled.span<{ labelOn: string; labelOff: string }>`
  width: 200%;
  margin-left: -100%;

  transition: margin 150ms ease-in-out 0s;

  display: grid;

  grid-template-columns: repeat(2, minmax(0, 1fr));

  &:before,
  &:after {
    display: block;
    box-sizing: border-box;
    padding: 0 ${DS.margins.micro};

    color: ${({ theme }) => theme.palettes.body.background};
    line-height: ${HEIGHT};
    font-size: 14px;
    font-weight: 600;
  }

  &:before {
    content: "${({ labelOn }) => labelOn}";

    text-transform: uppercase;
    color: ${({ theme }) => theme.palettes.buttons.progressive.foreground};

    background-color: ${({ theme }) =>
      theme.palettes.buttons.progressive.background};
  }

  &:after {
    content: "${({ labelOff }) => labelOff}";

    text-align: right;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palettes.buttons.neutral.foreground};

    background-color: ${({ theme }) => theme.palettes.buttons.neutral.dim};
  }
`;

const Switch = styled.span`
  position: absolute;
  display: block;
  width: ${SWITCH_SIZE};
  height: ${SWITCH_SIZE};
  margin: calc(((${HEIGHT} - ${SWITCH_SIZE}) / 2) + ${BORDER_THICKNESS});

  top: 0;
  right: calc(${WIDTH} - ${HEIGHT} - (${BORDER_THICKNESS} * 2));

  background: ${({ theme }) => theme.palettes.buttons.progressive.foreground};
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);

  transition: all 150ms ease-in-out 0s;
`;

const Checkbox = styled.input.attrs({
  type: "checkbox",
})`
  position: absolute;
  opacity: 0;
  pointer-events: none;

  &:focus + ${Label} {
    outline-style: auto;
    outline-color: -webkit-focus-ring-color;
    outline-width: 1px;
  }

  &:checked + ${Label} {
    border: (
      solid ${({ theme }) => theme.palettes.buttons.neutral.foreground}
        ${BORDER_THICKNESS}
    );
  }

  &:checked + ${Label} ${Inner} {
    margin-left: 0;
  }

  &:checked + ${Label} ${Switch} {
    right: 0;
  }
`;

export interface Props {
  id?: string;
  name?: string;
  labelOn?: string;
  labelOff?: string;
}

const Component = forwardRef<HTMLInputElement, Props>(
  ({ id, labelOn = "On", labelOff = "Off", ...rest }, ref) => (
    <Container>
      <Checkbox id={id} ref={ref} {...rest} />
      <Label htmlFor={id}>
        <Inner labelOn={labelOn} labelOff={labelOff} />
        <Switch />
      </Label>
    </Container>
  ),
);
Component.displayName = "Switch";

export default Component;
