import { motion } from "framer-motion";
import { useState } from "react";
import { styled } from "styled-components";

import IconButton from "@components/IconButton";
import LanguageSwitcher from "@components/LanguageSwitcher";
import ThemeSwitcher from "@components/ThemeSwitcher";
import VersionBanner from "@components/VersionBanner";
import DS from "@design/system";

const Container = styled.div`
  box-sizing: border-box;
  position: fixed;
  height: 24px;
  padding: 0 12px 0 2px;
  z-index: 2;
  bottom: 4px;
  right: 4px;

  color: ${({ theme }) => theme.palettes.messages.notice.foreground};
  font-size: 12px;
  font-weight: 600;

  border-radius: ${DS.radii.pill};
  background: ${({ theme }) => theme.palettes.messages.notice.background};

  display: grid;
  gap: 4px;
  grid-auto-flow: column;
  align-items: center;
`;

export default function DevTools() {
  const [themesExpanded, setThemesExpanded] = useState(false);
  const [languagesExpanded, setLanguagesExpanded] = useState(false);

  return import.meta.env.VITE_APP_ENV_NAME !== "production" ? (
    <Container>
      <IconButton
        title={`${themesExpanded ? "Hide" : "Show"} theme switcher`}
        icon="palette"
        fit="compact"
        shape="round"
        onClick={() => setThemesExpanded((c) => !c)}
      />
      <motion.div
        style={{ overflow: "hidden", whiteSpace: "nowrap" }}
        animate={{
          width: themesExpanded ? "auto" : 0,
          transitionEnd: {
            display: themesExpanded ? "block" : "none",
          },
        }}
      >
        <ThemeSwitcher />
      </motion.div>
      <span>::</span>
      <IconButton
        title={`${languagesExpanded ? "Hide" : "Show"} language switcher`}
        icon="language"
        fit="compact"
        shape="round"
        onClick={() => setLanguagesExpanded((c) => !c)}
      />
      <motion.div
        style={{ overflow: "hidden", whiteSpace: "nowrap" }}
        animate={{
          width: languagesExpanded ? "auto" : 0,
          transitionEnd: {
            display: languagesExpanded ? "block" : "none",
          },
        }}
      >
        <LanguageSwitcher />
      </motion.div>
      <span>::</span>
      <VersionBanner />
    </Container>
  ) : null;
}
