import { createContext, useContext } from "react";

import { ApiResponseError } from "@api";

// User form
export interface AddUserForm extends Api.User {
  userIds: string[];
  groupsIds: string[];
}

export type AedInspection = Api.AedInspectionComplete;

export type AcceptTos = Api.AcceptTos;

export type AedReplenished = {
  confirmed: string[];
  photoPreview: string;
} & Api.AedReplenished;

export type ConfirmReplacementForm = {
  selectedReasons: string[];
  choice?: "needed" | "not-needed";
} & Api.AedReplenishment;

export type ToolReplacementForm = {
  selectedReasons: string[];
  choice?: "needed" | "not-needed";
} & Api.UnitToolReplacement;

export type AcceptTosForm = {
  selectedReasons: string[];
  choice?: "needed" | "not-needed";
} & Api.AcceptTos;

const REPLACEMENT_REASONS = [
  { key: "replaced", description: "AED replaced or updated" },
  { key: "battery", description: "battery replaced or updated" },
  { key: "pads", description: "pads replaced or updated" },
  { key: "accounted for", description: "Missing item is accounted for" },
  {
    key: "already requested",
    description: "Product replacement previously requested",
  },
];

export const replacementReasons = (
  selectedReasons: string[],
  reason?: string,
) => {
  const descriptions = REPLACEMENT_REASONS.filter((r) =>
    selectedReasons.includes(r.key),
  ).map((r) => r.description);

  if (selectedReasons.includes("other") && reason) descriptions.push(reason);

  const summary = descriptions.join(", ");

  return summary.charAt(0).toUpperCase() + summary.slice(1);
};

export type WizardStepState = {
  steps: string[];
  currentIndex: number;
  currentName: string;

  nextIndex: number;
  nextName: string;
  nextLabel: string;

  prevIndex: number;
  prevName: string;
  prevLabel: string;
};

export const WizardStepContext = createContext<WizardStepState>({
  steps: [],

  currentIndex: -1,
  currentName: "",

  nextIndex: -1,
  nextName: "",
  nextLabel: "",

  prevIndex: -1,
  prevName: "",
  prevLabel: "",
});

export const useWizardStepState = () => useContext(WizardStepContext);

export const LastResponseContext =
  createContext<ApiResponseError<Api.UserValidationError> | null>(null);

export const SelectedGroupContext = createContext<{ groupId?: string }>({});

export type EscalationProps<T extends Api.Escalation> = {
  escalation: T;
};
