import { useFormContext } from "react-hook-form";

import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";
import { ConfirmReplacementForm } from "@util/viewModel";

const ConfirmReplacementNeededStepFooter = () => {
  const { previous } = useWizardContext();
  const { formState } = useFormContext<ConfirmReplacementForm>();

  return (
    <ButtonGroup flow="horizontal">
      <Button disabled={formState.isSubmitting} onClick={previous}>
        <Icon name="arrow-alt-left" /> Back, to selection
      </Button>
      <Button
        type="submit"
        buttonType="action"
        disabled={formState.isSubmitting}
      >
        {formState.isSubmitting
          ? "Confirming replacement…"
          : "Confirm replacement"}
      </Button>
    </ButtonGroup>
  );
};

export default ConfirmReplacementNeededStepFooter;
