import { motion } from "framer-motion";
import { CSSProperties } from "react";

export type Direction = "forward" | "back";

export interface VariantProps {
  direction: Direction;
  reduceMotion?: boolean | null;
}

const variants = {
  visible: {
    opacity: 1,
    left: 0,
  },
  show: ({ direction, reduceMotion }: VariantProps) => ({
    opacity: 0,
    left: reduceMotion ? 0 : direction === "forward" ? "100%" : "-100%",
  }),
  hide: ({ direction, reduceMotion }: VariantProps) => ({
    opacity: 0,
    left: reduceMotion ? 0 : direction === "forward" ? "-50%" : "50%",
  }),
};

const styles: CSSProperties = {
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,

  display: "grid",
  gridTemplateRows: "1fr",
};

const backForwardTransitionProps = {
  style: styles,
  initial: "show",
  animate: "visible",
  exit: "hide",
  variants,
  transition: { duration: 0.25 },
};

interface Props {
  children?: React.ReactNode;
  custom: VariantProps;
}

export const BackForwardCard = ({ children, ...rest }: Props) => (
  <motion.div {...rest} {...backForwardTransitionProps}>
    {children}
  </motion.div>
);
