import color from "color";
import { useDayzed } from "dayzed";
import { AnimatePresence, motion } from "framer-motion";
import { forwardRef, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { mergeRefs, useLayer } from "react-laag";
import styled, { useTheme } from "styled-components";
import { v4 as uuidv4 } from "uuid";

import IconButton from "@components/IconButton";
import DS from "@design/system";
import { IIcon } from "@icons";
import { useFormattedInput } from "@util/useFormattedInput";
import { useIdOrDefault } from "@util/useIdOrDefault";

import ContentLoader, { TextBlock } from "../util/ContentLoader";
import Calendar from "./Calendar";
import Icon from "./Icon";
import Switch, { Props as ISwitchProps } from "./Switch";

export const FormColumn = styled.div`
  display: grid;
  gap: ${DS.margins.micro};
  align-content: flex-start;
`;

export const FormRow = styled.div`
  display: grid;

  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);

  gap: ${DS.margins.micro};
`;

export const Container = styled.div<{
  withLabel: boolean;
  hasError: boolean;
  compact?: boolean;
}>`
  position: relative;
  z-index: 0;
  display: block;

  padding: ${({ compact }) => (compact ? DS.margins.nano : DS.margins.micro)}
    ${DS.margins.micro} ${({ compact }) => (compact ? "18px" : "28px")};

  border-radius: ${DS.radii.item};
  background: ${({ theme }) => theme.palettes.form.background};

  input::placeholder,
  select:required:invalid {
    opacity: 1;
    font-style: italic;
    color: ${({ theme }) => theme.palettes.form.title} !important;
  }

  select:required:invalid option {
    color: ${({ theme }) => theme.palettes.form.foreground};
    font-style: normal;
  }

  option[value=""][disabled] {
    display: none;
  }

  select {
    appearance: none;
    cursor: pointer;
  }

  input,
  select {
    transition: all 200ms ease-in-out;
  }

  input:hover:not(:disabled):not(:read-only),
  textarea:hover:not(:disabled):not(:read-only),
  select:hover:not(:disabled):not(:read-only) {
    border-color: ${({ theme, hasError }) =>
      hasError
        ? theme.palettes.states.bad.background
        : theme.palettes.form.accent};
  }

  input:focus,
  select:focus {
    border-color: ${({ theme }) => theme.palettes.form.accent};
    background: ${({ theme }) => theme.palettes.form.background};

    outline: 0;
    box-shadow: 0 0 0 3px
      ${({ theme }) => color(theme.palettes.form.accent).alpha(0.5).string()};
  }

  input:disabled,
  input:read-only,
  textarea:disabled,
  select:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.palettes.form.small};
    -webkit-text-fill-color: ${({ theme }) => theme.palettes.form.small};
    font-style: italic;

    background: ${({ theme }) => theme.palettes.form.dim};
  }
`;

const Label = styled.label<{ compact?: boolean; hasError: boolean }>`
  pointer-events: none;

  display: block;
  position: relative;
  z-index: 1;

  line-height: 16px;
  font-size: ${({ compact }) => (compact ? "10px" : "12px")};
  color: ${({ theme, hasError }) =>
    hasError
      ? theme.palettes.states.bad.background
      : theme.palettes.form.title};
`;

const ErrorMessage = styled.div`
  padding: 3px ${DS.margins.micro} ${DS.margins.nano};

  color: ${({ theme }) => theme.palettes.messages.error.foreground};
  font-size: 12px;

  border-radius: ${DS.margins.nanoCss("bl")};
  background: ${({ theme }) => theme.palettes.messages.error.background};
`;

export const Control = styled.div<{
  withLabel: boolean;
  hasError: boolean;
  error?: string;
  compact?: boolean;
}>`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: ${({ withLabel, compact }) =>
    withLabel
      ? `${compact ? "12px" : DS.margins.large} ${DS.margins.micro} ${
          compact ? 0 : DS.margins.micro
        }`
      : DS.margins.micro};
  top: 0;
  left: 0;

  font-size: ${({ compact }) => (compact ? "14px" : "inherit")};
  color: ${({ theme }) => theme.palettes.form.foreground};

  border: solid 1px;
  border-color: ${({ theme, hasError }) =>
    hasError
      ? theme.palettes.states.bad.background
      : theme.palettes.form.border ?? "transparent"};

  border-radius: ${DS.radii.item};
  background: transparent;

  ${({ error }) =>
    error &&
    `
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  `}
`;

const UpDownArrow = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;

  pointer-events: none;

  color: ${({ theme }) => theme.palettes.form.title};
  transform: translateY(-50%);

  select:focus + & {
    color: ${({ theme }) => theme.palettes.form.title};
  }
`;

const Radio = styled.input`
  position: absolute;

  opacity: 0;
  pointer-events: none;
`;

const IconOptionContainer = styled.label`
  padding: ${DS.margins.nano};

  cursor: pointer;

  border-radius: ${DS.radii.item};

  & span {
    display: block;
    color: ${({ theme }) =>
      color(theme.palettes.buttons.neutral.foreground)
        .mix(color(theme.palettes.buttons.neutral.background), 0.5)
        .toString()};
  }

  &:hover {
    color: ${({ theme }) =>
      color(theme.palettes.buttons.neutral.foreground)
        .mix(color(theme.palettes.buttons.neutral.background), 0.25)
        .toString()};

    background: ${({ theme }) =>
      color(theme.palettes.buttons.neutral.foreground)
        .mix(color(theme.palettes.body.background), 0.8)
        .toString()};
  }

  & ${Radio}:checked + span {
    color: ${({ theme }) => theme.palettes.form.accent};
  }
`;

const ValidationIconContainer = styled.div`
  position: absolute;
  right: ${DS.margins.micro};
  bottom: ${DS.margins.micro};

  > span {
    display: block;
  }
`;

export interface ValidationIcon {
  name: IIcon;
  color?: string;
}

interface BaseProps<TTransShape extends object> {
  id?: string;

  /**
   * Visible label for the input. Use "Sentence casing".
   */
  label?: string;

  compact?: boolean;

  hasError?: boolean;
  error?: string;

  loading?: boolean;

  tKey?: Util.AllowedPaths<I18n.TranslationBase, TTransShape>;
}

interface InputProps
  extends BaseProps<object>,
    React.InputHTMLAttributes<HTMLInputElement> {
  validationIcon?: ValidationIcon;
}

export const LabelledInputPlaceholder = ({ label }: { label: string }) => (
  <Container withLabel={!!label} hasError={false}>
    <Label as="div" hasError={false}>
      {label}
    </Label>
    <Control withLabel={!!label} hasError={false}>
      <ContentLoader width={120} height={18}>
        <TextBlock x={0} y={4} width={150} fontSize={11} />
      </ContentLoader>
    </Control>
  </Container>
);

const ICON_SIZE = 16;

export const LabelledInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      compact,
      label,
      name,
      hasError,
      error,
      validationIcon,
      loading,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const theId = useMemo(() => id ?? `input-${uuidv4()}`, [id]);

    return loading ? (
      <LabelledInputPlaceholder label={label ?? ""} />
    ) : (
      <div>
        <Container withLabel={!!label} hasError={!!hasError} compact={compact}>
          {label && (
            <Label htmlFor={theId} hasError={!!hasError} compact={compact}>
              {label}
            </Label>
          )}
          <Control
            compact={compact}
            as="input"
            name={name}
            withLabel={!!label}
            hasError={!!hasError}
            error={error}
            id={theId}
            ref={ref}
            {...rest}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange && onChange(e);
            }}
            style={
              validationIcon && {
                ...{
                  paddingRight:
                    DS.margins.microN + ICON_SIZE + DS.margins.microN,
                },
              }
            }
            aria-invalid={hasError}
            aria-errormessage={error}
          />
          {validationIcon && (
            <ValidationIconContainer>
              <Icon
                name={validationIcon.name}
                color={validationIcon.color}
                size={ICON_SIZE}
              />
            </ValidationIconContainer>
          )}
        </Container>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    );
  },
);

LabelledInput.displayName = "LabelledInput";

interface TextAreaProps
  extends BaseProps<object>,
    React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  validationIcon?: ValidationIcon;
}

export const LabelledPasswordInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      compact,
      label,
      name,
      hasError,
      error,
      validationIcon,
      loading,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const { palettes } = useTheme();

    const theId = useIdOrDefault(id);

    const [showPassword, setShowPassword] = useState(false);

    const passwordRef = useRef<HTMLDivElement>(null);
    const handleToggleShowPassword = () => {
      passwordRef.current?.focus();
      setShowPassword((current) => !current);
    };

    return loading ? (
      <LabelledInputPlaceholder label={label ?? ""} />
    ) : (
      <div style={{ display: "grid" }}>
        <Container withLabel={!!label} hasError={!!hasError} compact={compact}>
          {label && (
            <Label htmlFor={theId} hasError={!!hasError} compact={compact}>
              {label}
            </Label>
          )}
          <Control
            compact={compact}
            as="input"
            name={name}
            withLabel={!!label}
            hasError={!!hasError}
            error={error}
            id={theId}
            type={showPassword ? "text" : "password"}
            ref={mergeRefs(ref, passwordRef)}
            {...rest}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange && onChange(e);
            }}
            style={{
              ...{
                paddingRight: DS.margins.microN + ICON_SIZE + DS.margins.microN,
              },
            }}
            aria-invalid={hasError}
            aria-errormessage={error}
          />
          <div
            style={{
              position: "absolute",
              right: DS.margins.micro,
              bottom: DS.margins.micro,
              color: color(palettes.buttons.neutral.foreground)
                .mix(color(palettes.buttons.neutral.background), 0.5)
                .toString(),
            }}
          >
            <IconButton
              icon={showPassword ? "eye-slash" : "eye"}
              title={showPassword ? "Hide password" : "Show password"}
              fit="tight"
              buttonType="transparent"
              iconSize={ICON_SIZE}
              onClick={handleToggleShowPassword}
            />
          </div>
          {validationIcon && (
            <ValidationIconContainer>
              <Icon
                name={validationIcon.name}
                color={validationIcon.color}
                size={ICON_SIZE}
              />
            </ValidationIconContainer>
          )}
        </Container>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    );
  },
);

LabelledPasswordInput.displayName = "LabelledPasswordInput";

export const LabelledTextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { id, compact, label, hasError, error, validationIcon, loading, ...rest },
    ref,
  ) => {
    const theId = useMemo(() => id ?? `input-${uuidv4()}`, [id]);

    return loading ? (
      <LabelledInputPlaceholder label={label ?? ""} />
    ) : (
      <div>
        <Container withLabel={!!label} hasError={!!hasError} compact={compact}>
          {label && (
            <Label htmlFor={theId} hasError={!!hasError} compact={compact}>
              {label}
            </Label>
          )}
          <Control
            compact={compact}
            as="textarea"
            withLabel={!!label}
            hasError={!!hasError}
            error={error}
            id={theId}
            ref={ref}
            {...rest}
            style={{
              resize: "none",
              ...(validationIcon
                ? {
                    paddingRight:
                      DS.margins.microN + ICON_SIZE + DS.margins.microN,
                  }
                : {}),
            }}
          />

          {validationIcon && (
            <ValidationIconContainer>
              <Icon
                name={validationIcon.name}
                color={validationIcon.color}
                size={ICON_SIZE}
              />
            </ValidationIconContainer>
          )}
        </Container>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    );
  },
);

LabelledTextArea.displayName = "LabelledTextArea";

const ALLOWED_DIGITS = /[0-9]/;

export const LargeDigitInput = ({
  name,
  value,
  onChange,
}: {
  name: string;
  value?: number | null;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isFocused = useRef(false);

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      isFocused.current = true;
      e.target.select();
    },
    [],
  );

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const v = e.target.value.substring(0, 1);

      if (v.length === 0) {
        onChange && onChange(e);
        return;
      }

      const digit = Number(v);

      if (isNaN(digit) || !ALLOWED_DIGITS.test(v)) {
        e.target.value = "";
      } else {
        e.target.value = v;
        containerRef.current?.nextElementSibling
          ?.querySelector("input")
          ?.focus();
      }

      onChange && onChange(e);
    },
    [onChange],
  );

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback((e) => {
      if (e.key === "Backspace" && e.currentTarget.value.length === 0) {
        containerRef.current?.previousElementSibling
          ?.querySelector("input")
          ?.focus();
      }
    }, []);

  const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback(() => {
      //TODO: If the value is good, go next — Needed because change is not fired if same value is entered.
    }, []);

  return (
    <Container
      ref={containerRef}
      withLabel={false}
      hasError={false}
      style={{ height: 32, padding: DS.margins.micro }}
    >
      <Control
        as="input"
        name={name}
        pattern="[0-9]"
        inputMode="numeric"
        withLabel={false}
        hasError={false}
        value={value ?? ""}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        style={{
          fontSize: 24,
          fontWeight: 600,
          textAlign: "center",
        }}
      />
    </Container>
  );
};

function setReactInputValue(input: HTMLInputElement, value: string) {
  const previousValue = input.value;

  input.value = value;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  const tracker = (input as any)._valueTracker;

  if (tracker) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    tracker.setValue(previousValue);
  }

  input.dispatchEvent(new Event("change", { bubbles: true }));
}

export const CodeInput = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & {
    digits: number;
    onSubmitSuggested?: () => void;
  }
>(({ digits, name, onChange, onSubmitSuggested, ...rest }, ref) => {
  const hiddenRef = useRef<HTMLInputElement | null>(null);

  const [submitSuggested, setSubmitSuggested] = useState(false);
  const [digitValues, setDigitValues] = useState(
    Array<number | null>(digits).fill(null),
  );

  const handlePaste: React.ClipboardEventHandler = useCallback(
    (e) => {
      e.preventDefault();

      setDigitValues(() => {
        const digits = Array.from(
          e.clipboardData.getData("text/plain").replace(/[^0-9]/g, ""),
        )
          .map((d) => Number(d))
          .filter((d) => !isNaN(d));

        setTimeout(() => {
          hiddenRef.current &&
            setReactInputValue(hiddenRef.current, digits.join(""));

          if (submitSuggested) return;

          setSubmitSuggested(true);
          onSubmitSuggested && onSubmitSuggested();
        });

        return digits;
      });
    },
    [onSubmitSuggested, submitSuggested],
  );

  const handleChange = useCallback(
    (index: number): React.ChangeEventHandler<HTMLInputElement> =>
      (e) => {
        setDigitValues((current) => {
          const updated = [...current];
          updated[index] = e.target.value ? Number(e.target.value) : null;

          setTimeout(() => {
            hiddenRef.current &&
              setReactInputValue(hiddenRef.current, updated.join(""));

            if (
              updated.filter((d) => d !== null).length < digits ||
              submitSuggested
            )
              return;

            setSubmitSuggested(true);
            onSubmitSuggested && onSubmitSuggested();
          });

          return updated;
        });
      },
    [digits, onSubmitSuggested, submitSuggested],
  );

  const inputs = useMemo(
    () =>
      Array.from({ length: digits }).map((_v, i) => (
        <LargeDigitInput
          key={`${name ?? "noname"}-digit-${i}`}
          name={`${name ?? "noname"}-digit-${i}`}
          value={digitValues[i]}
          onChange={handleChange(i)}
        />
      )),
    [digitValues, digits, handleChange, name],
  );

  return (
    <div
      style={{
        display: "grid",
        gap: DS.margins.micro,
        gridTemplateColumns: `repeat(${digits}, 40px)`,
        justifyContent: "center",
        alignItems: "center",
      }}
      onPaste={handlePaste}
    >
      <input
        type="text"
        ref={(e) => {
          ref && typeof ref === "function" && ref(e);
          hiddenRef.current = e;
        }}
        name={name}
        onChange={onChange}
        {...rest}
        style={{ display: "none" }}
      />
      {inputs}
    </div>
  );
});

CodeInput.displayName = "CodeInput";

interface SelectProps
  extends BaseProps<object>,
    React.SelectHTMLAttributes<HTMLSelectElement> {
  placeholder?: string;
  options: {
    value: string | number;
    label: string;
    disabled?: boolean;
  }[];
}

export const LabelledSelect = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      id,
      label,
      compact = false,
      hasError = false,
      error,
      options,
      placeholder,
      loading,
      ...rest
    },
    ref,
  ) => {
    const theId = useMemo(() => id ?? `input-${uuidv4()}`, [id]);

    return loading ? (
      <LabelledInputPlaceholder label={label ?? ""} />
    ) : (
      <Container
        withLabel={!!label}
        hasError={hasError}
        compact={compact}
        style={{ paddingRight: DS.margins.microN + DS.margins.microN + 16 }}
      >
        {label && (
          <Label htmlFor={theId} compact={compact} hasError={hasError}>
            {label}
          </Label>
        )}
        <Control
          as="select"
          id={theId}
          ref={ref}
          compact={compact}
          hasError={hasError}
          error={error}
          withLabel={!!label}
          {...rest}
          required={!!placeholder}
        >
          {placeholder && (
            <option hidden value="">
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </Control>

        <UpDownArrow>
          <Icon name="caret-up-down" size={16} />
        </UpDownArrow>
      </Container>
    );
  },
);

LabelledSelect.displayName = "LabelledSelect";

interface SwitchProps
  extends BaseProps<I18n.FormControls.Switch>,
    ISwitchProps,
    React.InputHTMLAttributes<HTMLInputElement> {}

export const LabelledSwitch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ id, label, name, title, tKey, labelOn, labelOff, ...rest }, ref) => {
    const { t } = useTranslation();
    const theId = useMemo(() => id ?? `input-${uuidv4()}`, [id]);

    return (
      <label
        title={title}
        style={{
          display: "grid",
          gridAutoFlow: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: DS.margins.micro,
        }}
      >
        {label ?? (tKey && t(`${tKey}.label`))}
        <Switch
          id={theId}
          name={name}
          ref={ref}
          labelOn={labelOn || (tKey && t(`${tKey}.on`))}
          labelOff={labelOff || (tKey && t(`${tKey}.off`))}
          {...rest}
        />
      </label>
    );
  },
);

LabelledSwitch.displayName = "LabelledSwitch";

interface CheckboxProps
  extends BaseProps<object>,
    React.InputHTMLAttributes<HTMLInputElement> {}

export const LabelledCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, label, name, title, ...rest }, ref) => {
    const theId = useMemo(() => id ?? `input-${uuidv4()}`, [id]);

    return (
      <label title={title} style={{}}>
        <input id={theId} type="checkbox" name={name} ref={ref} {...rest} />{" "}
        {label}
      </label>
    );
  },
);

LabelledCheckbox.displayName = "LabelledCheckbox";

interface DatePickerProps extends InputProps {
  minDate?: Date;
  maxDate?: Date;
  showPicker?: boolean;
}

export const LabelledDatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      id,
      compact,
      label,
      name,
      hasError,
      error,
      loading,
      value,
      minDate,
      maxDate,
      onChange,
      showPicker = true,
      ...rest
    },
    ref,
  ) => {
    const { palettes } = useTheme();

    const elRef = useRef<HTMLInputElement | null>(null);

    // TODO: Add option for showing time?
    const [isOpen, setIsOpen] = useState(false);

    // Internally tracked actual date
    const [date, setDate] = useState<Date | null>(
      value && !(value instanceof Array)
        ? new Date(value)
        : rest.defaultValue && !(rest.defaultValue instanceof Array)
          ? new Date(rest.defaultValue)
          : null,
    );

    const [offset, setOffset] = useState<number>(0);

    const theId = useMemo(() => id ?? `input-${uuidv4()}`, [id]);

    const setFormValue = useCallback((newIsoFormValue: string) => {
      if (!elRef.current) return;

      // Force set the hidden input value
      Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value",
      )?.set?.call(elRef.current, newIsoFormValue);

      // ...and manually dispatch the change. Needed for hooks like useForm's
      // register which monitors the attached input internally.
      elRef.current.dispatchEvent(new Event("change", { bubbles: true }));
    }, []);

    const handleFormattedDateChange = useCallback(
      (value: Date | null) => {
        if (!value || isNaN(value.getTime())) {
          setFormValue("");
          return;
        }

        setDate(value);
        setOffset(0);

        const newIsoFormValue = value.toISOString();
        setFormValue(newIsoFormValue);
      },
      [setFormValue],
    );

    const control = useDayzed({
      date: date ?? new Date(),
      selected: date ?? undefined,
      showOutsideDays: true,
      offset,
      minDate,
      maxDate,
      onOffsetChanged: (o) => {
        setOffset(o);
      },
      onDateSelected: (selected) => {
        setDate(selected.date);
        setOffset(0);
        setFormValue(selected.date.toISOString());
      },
    });

    const { formatProps } = useFormattedInput({
      value: date,
      onChange: handleFormattedDateChange,
    });

    const { renderLayer, triggerProps, layerProps, layerSide } = useLayer({
      isOpen: showPicker && isOpen,
      onOutsideClick: () => setIsOpen((open) => !open),
      auto: true,
      placement: "bottom-start",
      triggerOffset: DS.margins.microN,
    });

    return loading ? (
      <LabelledInputPlaceholder label={label ?? ""} />
    ) : (
      <div>
        <input
          type="text"
          name={name}
          ref={(el) => {
            typeof ref === "function" && ref(el);
            elRef.current = el;

            const refDate = el ? new Date(el.value) : null;

            if (
              refDate &&
              !isNaN(refDate.getTime()) &&
              (!date || refDate.toISOString() !== date.toISOString())
            ) {
              setDate(refDate);
            }
          }}
          value={value}
          onChange={onChange}
          style={{ display: "none" }}
        />
        <Container
          withLabel={!!label}
          hasError={!!hasError}
          compact={compact}
          style={{ minWidth: 90 }}
        >
          {label && (
            <Label htmlFor={theId} hasError={!!hasError} compact={compact}>
              {label}
            </Label>
          )}
          <Control
            id={theId}
            as="input"
            compact={compact}
            withLabel={!!label}
            hasError={!!hasError}
            error={error}
            {...rest}
            {...triggerProps}
            {...formatProps}
            ref={(el) => {
              triggerProps.ref(el);
              formatProps.ref(el);
            }}
            type="input"
            style={{ textAlign: "center" }}
            onClick={(e) => {
              rest.onClick?.(e);
              setIsOpen(true);
            }}
            onFocus={(e) => {
              rest.onFocus?.(e);
              setIsOpen(true);
            }}
            onKeyDown={(e) => {
              rest.onKeyDown?.(e);
              formatProps.onKeyDown(e);
              if (e.key === "Tab") setIsOpen(false);
            }}
          />
        </Container>

        {showPicker &&
          renderLayer(
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  {...layerProps}
                  style={{
                    ...layerProps.style,
                    overflow: "hidden",
                    padding: DS.margins.micro,
                    borderRadius: DS.radii.largeItem,
                    border: `solid 1px ${palettes.form.border}`,
                    background: palettes.form.background,
                    boxShadow: DS.shadows.dialog,

                    ...(layerSide === "top" && {
                      transformOrigin: "bottom",
                    }),

                    ...(layerSide === "bottom" && {
                      transformOrigin: "top",
                    }),
                  }}
                  initial={{ opacity: 0, scaleY: 0.75 }}
                  animate={{ opacity: 1, scaleY: 1 }}
                  exit={{ opacity: 0, scaleY: 0.75 }}
                  transition={{ duration: 0.1 }}
                  aria-modal
                  aria-label={label}
                >
                  <Calendar
                    key="calendars"
                    control={control}
                    onTodayClick={() => setOffset(0)}
                  />
                </motion.div>
              )}
            </AnimatePresence>,
          )}
      </div>
    );
  },
);

LabelledDatePicker.displayName = "LabelledDatePicker";

interface IconOptionProps
  extends BaseProps<object>,
    React.InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  value?: string;
  type?: string;
  icon: IIcon;
}

export const IconOption = forwardRef<HTMLInputElement, IconOptionProps>(
  ({ id, icon, label, name, value, type, ...rest }, ref) => {
    const theId = useMemo(() => id ?? `input-${uuidv4()}`, [id]);

    return (
      <IconOptionContainer title={label} aria-label={label}>
        <Radio
          id={theId}
          type={type}
          name={name}
          value={value}
          ref={ref}
          {...rest}
        />
        <Icon name={icon} />
      </IconOptionContainer>
    );
  },
);

IconOption.displayName = "IconOption";

export const OptionGroup = ({
  caption,
  children,
}: {
  caption: string;
  children: React.ReactNode;
}) => (
  <div
    style={{
      display: "grid",
      gridAutoFlow: "column",
      justifyContent: "space-between",
    }}
  >
    {caption}
    <div
      style={{
        display: "grid",
        gridAutoFlow: "column",
        gap: DS.margins.nano,
        alignItems: "flex-start",
      }}
    >
      {children}
    </div>
  </div>
);

export interface OptionGroupProps extends BaseProps<object> {
  name?: string;
  type?: "radio" | "checkbox";
  description: string;
  title?: string;
  options: { icon: IIcon; label: string; value: string }[];
  value: string[];
  onChange: (value: string[]) => void;
}

export const LabelledOptionGroupPlaceholder = ({
  width,
}: {
  width: number;
  optionCount: number;
}) => (
  <Container withLabel hasError={false}>
    <Label as="div" hasError={false}>
      <ContentLoader width={width - 16} height={16}>
        <TextBlock x={0} y={4} width={150} fontSize={11} />
      </ContentLoader>
    </Label>

    <Control withLabel hasError={false}>
      <ContentLoader width={width - 16} height={18}>
        <TextBlock x={0} y={4} width={100} fontSize={11} />
        <rect x={width - 10 - 28} y={1} width={16} height={16} />
        <rect x={width - 10 - 28 * 2} y={1} width={16} height={16} />
        <rect x={width - 10 - 28 * 3} y={1} width={16} height={16} />
        <rect x={width - 10 - 28 * 4} y={1} width={16} height={16} />
      </ContentLoader>
    </Control>
  </Container>
);

export const LabelledOptionGroup = ({
  label,
  name,
  title,
  type = "checkbox",
  description,
  hasError,
  error,
  options,
  value,
  onChange,
}: OptionGroupProps) => {
  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (!onChange) return;

        if (e.target.value === "none") {
          onChange(["none"]);
          return;
        }

        const newValue = value.filter((v) => v !== "none");

        onChange(
          e.target.checked
            ? newValue.concat(e.target.value)
            : newValue.filter((v) => v !== e.target.value),
        );
      },
      [value, onChange],
    );

  return (
    <Container
      as="fieldset"
      title={title}
      withLabel={!!label}
      hasError={!!hasError}
      style={{ margin: 0, border: 0 }}
    >
      {label && (
        <Label
          as="legend"
          hasError={!!hasError}
          style={{
            float: "left",
            padding: 0,
          }}
        >
          {label}
        </Label>
      )}
      <Control withLabel={!!label} hasError={!!hasError} error={error}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
          }}
        >
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {description}
          </div>

          <div
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gap: DS.margins.nano,
            }}
          >
            {options.map((option, i) => (
              <IconOption
                {...option}
                key={`icon-option-${i}-${option.icon}`}
                name={name}
                type={type}
                checked={value && value.includes(option.value)}
                onChange={handleInputChange}
              />
            ))}
          </div>
        </div>
      </Control>
    </Container>
  );
};

export const LabelledInputGroup = styled.div`
  & :not(:first-child):not(:last-child) input {
    padding-top: 2px;
    padding-bottom: 2px;

    border-radius: 0;
    border-top: 0;
    border-bottom: 0;
  }

  & :first-child input {
    padding-bottom: 2px;

    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & :last-child input {
    padding-top: 2px;

    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;
