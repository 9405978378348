import axios from "axios";

export const isApiResponseError = <TError extends Api.ValidationError>(
  response: unknown,
): response is ApiResponseError<TError> => response instanceof ApiResponseError;

export class ApiResponseError<
  TError extends Api.ValidationError,
> extends Error {
  public readonly error: ApiError<TError>;
  public readonly originalResponse: Api.ResponseFailure<TError>;

  constructor(response: Api.ResponseFailure<TError>) {
    super(response.Message);

    this.name = "ApiResponseError";

    this.error = new ApiError(response);
    this.originalResponse = response;
  }
}

export const isApiError = <TError extends Api.ValidationError>(
  e: unknown,
): e is Api.ResponseFailure<TError> => {
  const error = e as Api.ResponseFailure<TError>;

  return (
    error.Success === false &&
    error.Errors !== undefined &&
    !!error.Message &&
    !!error.StatusCode
  );
};

export class ApiError<TError extends Api.ValidationError> {
  private _errors: Api.ErrorDefinition<TError>[] = [];

  private _isServerError = false;

  constructor(e: unknown) {
    if (axios.isAxiosError(e)) {
      this._isServerError = true;
      return;
    }

    if (isApiError<TError>(e)) {
      this._errors = e.Errors ?? [];
      return;
    }

    this._errors = [];
  }

  get all() {
    return this._errors;
  }

  get isServerError() {
    return this._isServerError;
  }

  hasError(keys: TError | TError[]) {
    return this._errors.find((error) =>
      (keys instanceof Array ? keys : [keys]).some(
        (key) => key === error.ErrorKey,
      ),
    );
  }

  hasErrorDescription(messages: string | string[]) {
    return this._errors.find((error) =>
      (messages instanceof Array ? messages : [messages]).some(
        (message) => message === error.Description,
      ),
    );
  }

  isXsrfError() {
    return (
      this.hasError("generic_error" as TError) &&
      this.hasErrorDescription(
        "Required anti-forgery cookie and header do not match",
      )
    );
  }
}
