import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useModal } from "react-modal-hook";

import { ApiResponseError, isApiResponseError } from "@api";
import Button from "@components/Button";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import ModalStandardLayout from "@components/ModalStandardLayout";
import Scroller from "@components/Scroller";
import { Tab, TabList, TabPanel, Tabs } from "@components/Tabs";
import AccessTab from "@components/people/AccessTab";
import ChangePassword from "@components/people/ChangePassword";
import DisableMfaModal from "@components/people/DisableMfaModal";
import EditUserFailedMessage from "@components/people/EditUserFailedMessage";
import GeneralTab from "@components/people/GeneralTab";
import NotificationsTab from "@components/people/NotificationsTab";
import DS from "@design/system";
import ConfirmMfaModal from "@modals/ConfirmMfaModal";
import {
  GroupAndOrStoreKey,
  useCurrentUser,
  useHasPermission,
  useMutateUser,
  useUserFull,
} from "@state/hooks";

import { LastResponseContext } from "../util/viewModel";

const getTabIndexByName = (
  tab: Parameters<typeof EditUserModal>[0]["defaultTab"],
) => {
  switch (tab) {
    case "notifications":
      return 1;

    case "access":
    case "password":
      return 2;

    default:
    case "general":
      return 0;
  }
};

const EditUserModal = ({
  userId,
  title,
  defaultTab = "general",
  mutationKeys,
  onClose,
}: {
  userId: string;
  title?: string;
  defaultTab?: "general" | "notifications" | "password" | "access";
  mutationKeys?: GroupAndOrStoreKey;
  onClose: () => void;
}) => {
  const { hasPermission } = useHasPermission();
  const { data: currentUser } = useCurrentUser();
  const { data: user, isLoading } = useUserFull(userId);
  const { mutate: updateUser } = useMutateUser(mutationKeys);

  const { reset, formState, handleSubmit, ...methods } = useForm<Api.User>();
  const [lastResponse, setLastResponse] =
    useState<ApiResponseError<Api.UserValidationError> | null>(null);

  const isCurrentUser = useMemo(
    () => currentUser && currentUser.userId === userId,
    [currentUser, userId],
  );

  const editable = useMemo(() => {
    return isCurrentUser || hasPermission("users_create_edit_delete_user");
  }, [hasPermission, isCurrentUser]);

  const onSubmit: React.FormEventHandler = useCallback(
    (e) =>
      void handleSubmit(async (data: Api.User) => {
        // Split first and last names based on naïve assumptions.
        const [FirstName, ...LastNameParts] = data.FullName.split(" ");

        const updatedUser: Api.User = {
          ...data,
          ...{ FirstName, LastName: LastNameParts.join(" ") },
          AuthenticateChangeRequest: {
            ...data.AuthenticateChangeRequest,
            IsChangingPassword:
              !!data.AuthenticateChangeRequest.SuppliedPassword,
          },
        };

        return new Promise<Api.ResponseSuccess<Api.User>>((resolve, reject) =>
          updateUser(updatedUser, { onSuccess: resolve, onError: reject }),
        )
          .then((response) => response.SuccessPayload)
          .then((updatedUser) => {
            if (!updatedUser) return;
            onClose();
            toast.success(
              <span>
                <strong>{updatedUser.FullName}</strong> successfully updated.
              </span>,
            );
          })
          .catch((error) => {
            if (isApiResponseError<Api.UserValidationError>(error)) {
              setLastResponse(error);
            }
          });
      })(e),
    [handleSubmit, onClose, updateUser],
  );

  useEffect(() => reset(user), [user, reset]);

  const [showMfaModal, closeMfaModal] = useModal(
    () => (
      <ConfirmMfaModal
        userId={userId}
        mutationKeys={mutationKeys}
        onClose={closeMfaModal}
      />
    ),
    [mutationKeys, userId],
  );

  const [showMfaDisableModal, closeMfaMDisableModal] = useModal(
    () => (
      <DisableMfaModal
        userId={userId}
        mutationKeys={mutationKeys}
        onClose={() => {
          closeMfaMDisableModal();
        }}
      />
    ),
    [mutationKeys, userId],
  );

  return (
    <LastResponseContext.Provider value={lastResponse}>
      <Modal contentLabel="Edit person" onClose={onClose}>
        <FormProvider {...{ reset, formState, handleSubmit, ...methods }}>
          <form onSubmit={onSubmit}>
            <ModalStandardLayout
              header={
                <ModalHeader
                  title={title || "Person's Details"}
                  onClose={onClose}
                />
              }
              content={
                <>
                  <Tabs
                    defaultIndex={getTabIndexByName(defaultTab)}
                    style={{ display: "grid", gridTemplateRows: "auto 1fr" }}
                  >
                    <div style={{ padding: DS.margins.regularCss("rl") }}>
                      <div style={{ marginBottom: DS.margins.micro }}>
                        <EditUserFailedMessage response={lastResponse} />
                      </div>
                      <TabList>
                        <Tab>General</Tab>
                        <Tab>Notifications</Tab>
                        {!isCurrentUser && <Tab>Access</Tab>}
                        {isCurrentUser && <Tab>Password</Tab>}
                      </TabList>
                    </div>

                    <Scroller>
                      <div
                        style={{
                          padding: `${DS.margins.regular} ${DS.margins.regular}`,
                        }}
                      >
                        <TabPanel>
                          <GeneralTab
                            loading={isLoading}
                            isCurrentUser={isCurrentUser}
                          />
                        </TabPanel>

                        <TabPanel>
                          <NotificationsTab />
                        </TabPanel>

                        {!isCurrentUser && (
                          <TabPanel>
                            <AccessTab />
                          </TabPanel>
                        )}

                        {isCurrentUser && (
                          <TabPanel>
                            <ChangePassword />
                            {user ? (
                              user?.AuthenticateChangeRequest.HasMfaEnabled ? (
                                <Button
                                  buttonType="destructive"
                                  onClick={showMfaDisableModal}
                                  stretch
                                >
                                  Disable two-factor authentication
                                </Button>
                              ) : (
                                <Button onClick={showMfaModal} stretch>
                                  Enable two-factor authentication
                                </Button>
                              )
                            ) : (
                              <Button disabled stretch>
                                Checking two-factor…
                              </Button>
                            )}
                          </TabPanel>
                        )}
                      </div>
                    </Scroller>
                  </Tabs>
                </>
              }
              footer={
                <ModalFooter>
                  {editable ? (
                    <Button
                      type="submit"
                      buttonType="action"
                      disabled={isLoading || formState.isSubmitting}
                    >
                      {formState.isSubmitting
                        ? "Saving changes…"
                        : "Save changes"}
                    </Button>
                  ) : (
                    <Button onClick={onClose}>Close</Button>
                  )}
                </ModalFooter>
              }
            />
          </form>
        </FormProvider>
      </Modal>
    </LastResponseContext.Provider>
  );
};

export default EditUserModal;
