import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "styled-components";

import DS from "@design/system";
import { useGroupsWithStoreCount, useStores } from "@state/hooks";
import { useMapState } from "@util/MapState";
import useParamsUpper from "@util/useParamsUpper";

import { useLocalStorage } from "../util/useLocalStorage";
import GroupedList from "./GroupedList";
import { ListItemsPlaceholder } from "./ListItem";
import Scroller from "./Scroller";
import { SearchResult, SearchView } from "./SearchView";
import SidePanelFooter from "./SidePanelFooter";
import { Tab, TabList, TabPanel, Tabs } from "./Tabs";

type PreviousItem = {
  id: string;
  type: "group" | "store";
};

// Match group or store, followed by id, then the tab.
const URL_REGEX = /\/(?:group|store)\/[0-9a-zA-Z-]+\/(.*)/;

const SwitcherPanel = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { palettes } = useTheme();

  const { setGroupId, setStoreId } = useMapState();
  const [, setPreviousItems] = useLocalStorage<PreviousItem[]>(
    "previouslyVisited",
    [],
  );

  const [searchView, setSearchView] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);

  const [tabScrollPosition, setTabScrollPosition] = useState<number[]>([]);

  const [tabHasScrolled, setTabHasScrolled] = useState<boolean[]>([]);

  const [tabIndex, setTabIndex] = useState(0);

  const [scrollerToItem, setScrollerToItem] = useState(0);

  const [scrollElement, setScrollElement] = useState<HTMLElement | null>();

  const handleSelectedRef = useCallback((e: HTMLElement) => {
    setScrollElement(e);
  }, []);

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) =>
      setScrollPosition(e.currentTarget.scrollTop),
    [],
  );

  const handleTabSelect = useCallback(
    (index: number, lastIndex: number) => {
      setTabScrollPosition((current) => {
        const newTabScrollPosition = [...current];
        newTabScrollPosition[lastIndex] = scrollPosition;

        return newTabScrollPosition;
      });
      setTabIndex(index);
    },
    [scrollPosition],
  );

  const { groupId, storeId } = useParamsUpper<{
    groupId: string;
    storeId: string;
  }>();

  const stores = useStores();
  const groupsWithStoreCount = useGroupsWithStoreCount();

  const franchises = useMemo(
    () =>
      groupsWithStoreCount.data
        ? groupsWithStoreCount.data
            .filter((group) => group.Type === "Franchise")
            .sort((a, b) => a.Name.localeCompare(b.Name))
        : [],
    [groupsWithStoreCount.data],
  );

  const groups = useMemo(
    () =>
      groupsWithStoreCount.data
        ? groupsWithStoreCount.data
            .filter((group) => group.Type === "Adhoc")
            .sort((a, b) => a.Name.localeCompare(b.Name))
        : [],
    [groupsWithStoreCount.data],
  );

  const storesOnly = useMemo(
    () =>
      !groupsWithStoreCount.isLoading && !groups.length && !franchises.length,
    [franchises, groups, groupsWithStoreCount.isLoading],
  );

  const currentTab = useMemo(() => {
    const [, path = "overview"] = location.pathname.match(URL_REGEX) ?? [];
    return path;
  }, [location.pathname]);

  const navigateToFoundItem = useCallback(
    (result: SearchResult) => {
      switch (result.type) {
        case "group":
          setGroupId(result.resultId);
          setPreviousItems(
            (current) =>
              [{ id: result.resultId, type: "group" }].concat(
                current[0],
              ) as PreviousItem[],
          );
          history.push(`/group/${result.resultId}/overview`);
          break;
        case "store":
          setStoreId(result.resultId);
          setPreviousItems(
            (current) =>
              [{ id: result.resultId, type: "store" }].concat(
                current[0],
              ) as PreviousItem[],
          );
          history.push(`/store/${result.resultId}/overview`);
          break;
      }
    },
    [history, setGroupId, setPreviousItems, setStoreId],
  );

  const handleStoreClick = useCallback(
    (storeId: string) => {
      setPreviousItems(
        (current) =>
          [{ id: storeId, type: "store" }].concat(current[0]) as PreviousItem[],
      );
      history.push(`/store/${storeId}/${currentTab}`);
    },
    [currentTab, history, setPreviousItems],
  );

  const handleGroupClick = useCallback(
    (groupId: string) => {
      setPreviousItems(
        (current) =>
          [{ id: groupId, type: "group" }].concat(current[0]) as PreviousItem[],
      );
      history.push(`/group/${groupId}/${currentTab}`);
    },
    [currentTab, history, setPreviousItems],
  );

  useLayoutEffect(() => {
    setScrollerToItem(tabScrollPosition[tabIndex]);
  }, [tabIndex, tabScrollPosition]);

  useEffect(() => {
    if (!scrollElement) return;
    if (tabHasScrolled[tabIndex]) return;

    scrollElement.scrollIntoView({
      behavior: "instant",
      block: "center",
      inline: "nearest",
    });

    setTabHasScrolled((current) => {
      const newTabHasScrolled = [...current];
      newTabHasScrolled[tabIndex] = true;
      return newTabHasScrolled;
    });
  }, [scrollElement, tabHasScrolled, tabIndex]);

  return (
    <div
      style={{
        boxSizing: "border-box",
        height: "100%",
        background: palettes.body.dim,
        display: "grid",
        gridTemplateRows: "auto auto 1fr",
        alignContent: "start",
      }}
    >
      <SearchView
        navigateToFoundItem={navigateToFoundItem}
        searchView={searchView}
        setSearchView={setSearchView}
        style={undefined}
      />

      {!searchView && (
        <>
          {groupsWithStoreCount.isLoading ? (
            <div
              style={{
                padding: DS.margins.regularCss("rbl"),
              }}
            >
              <ListItemsPlaceholder showImage={false} count={3} />
            </div>
          ) : storesOnly ? (
            <Scroller onScroll={handleScroll}>
              <div
                style={{
                  padding: DS.margins.regularCss("rbl"),
                }}
              >
                <GroupedList
                  title=""
                  emptyLabel={""}
                  data={stores.data}
                  isLoading={stores.isLoading}
                  isError={stores.isError}
                  isSelected={(store) => store.StoreId === storeId}
                  listItemKey={(store) => store.StoreId}
                  listItemTitle={(store) => store.Name}
                  listItemDescription={(store) => store.StreetAddress.AsOneLine}
                  onListItemClick={(store) => handleStoreClick(store.StoreId)}
                  selectedRef={handleSelectedRef}
                />
              </div>
            </Scroller>
          ) : (
            <Tabs
              selectedIndex={tabIndex}
              onSelect={handleTabSelect}
              style={{
                overflow: "hidden",
                display: "grid",
                gridTemplateRows: "auto 1fr",
              }}
            >
              <div style={{ padding: DS.margins.regularCss("rl") }}>
                <TabList>
                  <Tab>All</Tab>
                  {franchises.length > 0 && <Tab>Franchises</Tab>}
                  {groups.length > 0 && <Tab>Groups</Tab>}
                  <Tab>{t("term.store_other")}</Tab>
                </TabList>
              </div>

              <Scroller onScroll={handleScroll} scrollPosition={scrollerToItem}>
                <div
                  style={{
                    padding: `${DS.margins.micro} ${DS.margins.regular}`,
                  }}
                >
                  <TabPanel>
                    {franchises.length > 0 && (
                      <GroupedList
                        title="Franchises"
                        emptyLabel={""}
                        data={franchises}
                        showMax={3}
                        isLoading={groupsWithStoreCount.isLoading}
                        isError={groupsWithStoreCount.isError}
                        isSelected={(group) => group.GroupId === groupId}
                        listItemKey={(group) => group.GroupId}
                        listItemTitle={(group) => group.Name}
                        listItemDescription={(group) => group.Type}
                        onListItemClick={(group) =>
                          handleGroupClick(group.GroupId)
                        }
                        selectedRef={handleSelectedRef}
                      />
                    )}

                    {groups.length > 0 && (
                      <GroupedList
                        title="Groups"
                        emptyLabel={""}
                        data={groups}
                        showMax={3}
                        isLoading={groupsWithStoreCount.isLoading}
                        isError={groupsWithStoreCount.isError}
                        isSelected={(group) => group.GroupId === groupId}
                        listItemKey={(group) => group.GroupId}
                        listItemTitle={(group) => group.Name}
                        listItemDescription={(group) =>
                          `${t("term.store", {
                            count: group.storeCount,
                          })}`
                        }
                        onListItemClick={(group) =>
                          handleGroupClick(group.GroupId)
                        }
                        selectedRef={handleSelectedRef}
                      />
                    )}
                    <GroupedList
                      title={storesOnly ? "" : t("term.store_other")}
                      emptyLabel={""}
                      data={stores.data}
                      showMax={3}
                      isLoading={stores.isLoading}
                      isError={stores.isError}
                      isSelected={(store) => store.StoreId === storeId}
                      listItemKey={(store) => store.StoreId}
                      listItemTitle={(store) => store.Name}
                      listItemDescription={(store) =>
                        store.StreetAddress.AsOneLine
                      }
                      onListItemClick={(store) =>
                        handleStoreClick(store.StoreId)
                      }
                      selectedRef={handleSelectedRef}
                    />
                  </TabPanel>

                  {franchises.length > 0 && (
                    <TabPanel>
                      <GroupedList
                        title=""
                        emptyLabel={""}
                        data={franchises}
                        isLoading={groupsWithStoreCount.isLoading}
                        isError={groupsWithStoreCount.isError}
                        isSelected={(group) => group.GroupId === groupId}
                        listItemKey={(group) => group.GroupId}
                        listItemTitle={(group) => group.Name}
                        listItemDescription={(group) => group.Type}
                        onListItemClick={(group) =>
                          handleGroupClick(group.GroupId)
                        }
                        selectedRef={handleSelectedRef}
                      />
                    </TabPanel>
                  )}

                  {groups.length > 0 && (
                    <TabPanel>
                      <GroupedList
                        title=""
                        emptyLabel={""}
                        data={groups}
                        isLoading={groupsWithStoreCount.isLoading}
                        isError={groupsWithStoreCount.isError}
                        isSelected={(group) => group.GroupId === groupId}
                        listItemKey={(group) => group.GroupId}
                        listItemTitle={(group) => group.Name}
                        listItemDescription={(group) => group.Type}
                        onListItemClick={(group) =>
                          handleGroupClick(group.GroupId)
                        }
                        selectedRef={handleSelectedRef}
                      />
                    </TabPanel>
                  )}

                  <TabPanel>
                    <GroupedList
                      title=""
                      emptyLabel={""}
                      data={stores.data}
                      isLoading={stores.isLoading}
                      isError={stores.isError}
                      isSelected={(store) => store.StoreId === storeId}
                      listItemKey={(store) => store.StoreId}
                      listItemTitle={(store) => store.Name}
                      listItemDescription={(store) =>
                        store.StreetAddress.AsOneLine
                      }
                      onListItemClick={(store) =>
                        handleStoreClick(store.StoreId)
                      }
                      selectedRef={handleSelectedRef}
                    />
                  </TabPanel>
                </div>
              </Scroller>
              <SidePanelFooter />
            </Tabs>
          )}
        </>
      )}
    </div>
  );
};

export default SwitcherPanel;
