import { WizardStepContainer } from "@components/ModalWizardHelper";
import { ModalDescription } from "@design/helpers";

const StartStepMfa = () => {
  return (
    <WizardStepContainer>
      <ModalDescription
        style={{
          padding: "120px 40px",
          lineHeight: "1.5",
          textAlign: "center",
        }}
      >
        Download a supported authenticator app on your mobile device (such as{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
          target="_blank"
          rel="noreferrer"
        >
          Google Authenticator
        </a>
        ,{" "}
        <a href="https://authy.com/" target="_blank" rel="noreferrer">
          Authy
        </a>
        ,{" "}
        <a href="https://1password.com/" target="_blank" rel="noreferrer">
          1Password
        </a>
        ,{" "}
        <a href="https://www.lastpass.com/" target="_blank" rel="noreferrer">
          LastPass,
        </a>{" "}
        etc.)
      </ModalDescription>
    </WizardStepContainer>
  );
};

export default StartStepMfa;
