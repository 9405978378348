import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { AuthState, isApiResponseError } from "@api";
import Button from "@components/Button";
import ErrorWell from "@components/ErrorWell";
import { CodeInput } from "@components/FormControls";
import { Link } from "@components/SecondaryButton";
import { BodyText, LargeBrandTitle, SmallText, Well } from "@design/helpers";
import DS from "@design/system";

import { useAuth } from "./auth";

interface MfaParams {
  email: string;
  code: string;
}

const Mfa = ({
  email,
  onBackToLoginClick,
  mfaType,
}: {
  email?: string;
  mfaType: AuthState;
  onBackToLoginClick?: (e: React.MouseEvent) => void;
}) => {
  const auth = useAuth();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    register,
    watch,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<MfaParams>({ mode: "onSubmit" });

  const code = watch("code");

  const digits = useMemo(() => {
    return mfaType === "mfa-totp" ? 6 : 5;
  }, [mfaType]);

  const onSubmit = useCallback(
    (formEvent?: React.FormEvent) =>
      void handleSubmit(async (data: MfaParams) => {
        setErrorMessage(null);

        try {
          await auth.submitMfa(data.email, data.code);
        } catch (e) {
          setErrorMessage("Something went wrong. Please try again.");

          if (!isApiResponseError<Api.UserValidationError>(e)) return;

          const { error } = e;

          if (error.isServerError) {
            setErrorMessage(
              "We encountered an issue on our side. Please try again soon.",
            );
          } else if (error.hasError("2fa_code_not_matched")) {
            setErrorMessage(
              "You have entered an incorrect code. Please check the code matches the one you received.",
            );
          }
        }
      })(formEvent),
    [auth, handleSubmit],
  );

  const handleSubmitSuggested = useCallback(() => onSubmit(), [onSubmit]);

  return (
    <form style={{ height: "100%" }} action="#" onSubmit={onSubmit}>
      <div
        style={{
          height: "100%",
          display: "grid",
          gap: DS.margins.large,
          alignContent: "center",
        }}
      >
        <LargeBrandTitle>Validation Code</LargeBrandTitle>

        {errorMessage && <ErrorWell>{errorMessage}</ErrorWell>}

        {mfaType === "mfa-email" ? (
          <BodyText>
            Enter the 5-digit code that we sent to the supplied address.
          </BodyText>
        ) : (
          <BodyText>
            Enter the 6-digit code that was supplied by your authenticator app.
          </BodyText>
        )}

        <Well
          style={{
            overflow: "hidden",
            textAlign: "center",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <strong title={email}>{email}</strong>
        </Well>

        <input type="hidden" value={email} {...register("email")} />

        <CodeInput
          digits={digits}
          onSubmitSuggested={handleSubmitSuggested}
          {...register("code")}
        />

        <div
          style={{
            textAlign: "center",
            display: "grid",
            gap: DS.margins.micro,
          }}
        >
          <Button
            buttonType="action"
            type="submit"
            stretch
            disabled={!code || code.length !== digits || isSubmitting}
          >
            {isSubmitting ? "Verifying…" : "Verify"}
          </Button>

          <SmallText style={{ display: "none", textAlign: "center" }}>
            Didn&apos; t receive a code? <Link href="#">Send a new code</Link>.
          </SmallText>
          <Link href="#" onClick={onBackToLoginClick}>
            Return to log in
          </Link>
        </div>
      </div>
    </form>
  );
};

export default Mfa;
