import { useFormContext } from "react-hook-form";

import Button from "../Button";

const ReviewTabFooter = () => {
  const {
    formState: { isSubmitting },
  } = useFormContext<Api.User>();

  return (
    <Button type="submit" buttonType="action" disabled={isSubmitting}>
      {isSubmitting ? "Adding new person…" : "Add new person"}
    </Button>
  );
};

export default ReviewTabFooter;
