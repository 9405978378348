export const Cintas = {
  core: {
    /**
     * PMS 280
     * Primary brand color
     */
    blue: "#012169",

    /**
     * PMS 200
     * Secondary brand color
     */
    red: "#BA0C2F",
  },

  blue: {
    /**
     * PMS 641
     */
    dark: "#0067A0",

    /**
     * PMS 299
     */
    mid: "#0099CC",

    /**
     * PMS 291
     */
    light: "#9BCBEB",
  },

  orange: {
    /**
     * PMS 1575
     */
    dark: "#FF7F32",

    /**
     * PMS 137
     */
    mid: "#FFA300",

    /**
     * PMS 7549
     */
    light: "#FCB613",
  },

  green: {
    /**
     * PMS 362
     */
    dark: "#4C9D2F",

    /**
     * PMS 375
     */
    mid: "#97C93C",
  },

  neutralOther: {
    /**
     * PMS 513
     */
    purple: "#93358C",

    /**
     * PMS 425
     */
    dark: "#54585A",

    /**
     * PMS 429
     */
    mid: "#A2AAAD",

    /**
     * White
     */
    white: "#ffffff",
  },

  unofficial: {
    black: "#000000",

    /**
     * Dark background, found on the Cintas website.
     */
    darkGray: "#2B2E2F",

    /**
     * Light background, found on hte Cintas website.
     */
    lightGray: "#EBEDEE",
  },
};
