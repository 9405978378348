import { ReactNode } from "react";
import styled from "styled-components";

import DS from "@design/system";

const Container = styled.div`
  padding: ${DS.margins.regularCss("rl")};
  padding-bottom: ${DS.margins.micro};
  display: grid;
  align-content: flex-start;
  gap: ${DS.margins.regular};
`;

export const SidePanelContentContainer = ({
  contents,
}: {
  contents: ReactNode[];
}) => {
  return (
    <Container>
      {contents.map((content, i) => (
        <div key={i}>{content}</div>
      ))}
    </Container>
  );
};
