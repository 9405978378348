import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";

import Button from "@components/Button";
import DaysGoneBanner from "@components/DaysGoneBanner";
import GroupedList from "@components/GroupedList";
import Icon from "@components/Icon";
import IncidentsRow from "@components/IncidentsRow";
import ListItem, { ListItemsPlaceholder } from "@components/ListItem";
import Message from "@components/Message";
import ReadinessBanner from "@components/ReadinessBanner";
import Scroller from "@components/Scroller";
import ShipmentCard from "@components/ShipmentCard";
import SidePanelContainer from "@components/SidePanelContainer";
import { SidePanelContentContainer } from "@components/SidePanelContentContainer";
import SidePanelFooter from "@components/SidePanelFooter";
import SidePanelHeaderPlaceholder from "@components/SidePanelHeaderPlaceholder";
import StoreTime from "@components/StoreTime";
import { LargeDataNotice, UnstyledList } from "@design/helpers";
import DS from "@design/system";
import EditUnitModal from "@modals/EditUnitModal";
import RequestAddressChangeModal from "@modals/RequestAddressChangeModal";
import ScheduleInspectionModal from "@modals/ScheduleInspectionModal";
import {
  useActivitiesForUnit,
  useEscalations,
  useShipments,
  useUnit,
} from "@state/hooks";
import { useMapState } from "@util/MapState";
import { DateFormat } from "@util/dateFormat";
import {
  EscalationDescription,
  EscalationTitle,
  aedActivityFilter,
  getActivityTitle,
  getActivityIcon,
  getEscalationIcon,
  openAndNotPausedEscalationsOnly,
} from "@util/escalations";
import { activeShipmentsOnly } from "@util/shipments";
import { useThemeHelper } from "@util/useThemeHelper";

import GroupHeader from "../components/GroupHeader";
import SidePanelHeader from "../components/SidePanelHeader";
import { useSidePanels } from "../util/SidePanels";
import EscalationSidePanel from "./EscalationSidePanel";
import IncidentSidePanel from "./IncidentSidePanel";

const UnitSidePanel = ({
  controllerSerialNumber,
}: {
  controllerSerialNumber: string;
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { palettes } = useTheme();
  const { escalationsPalette } = useThemeHelper();

  const { data: unit } = useUnit(controllerSerialNumber);
  const unitStoreId = unit?.StoreId;

  const escalations = useEscalations({ storeId: unitStoreId });
  const shipments = useShipments({ storeId: unitStoreId, numberDays: 90 });

  const [selectedUnit, setSelectedUnit] = useState<Api.Unit | null>();

  const activeShipments = useMemo(
    () =>
      shipments?.data
        ?.filter(activeShipmentsOnly)
        .filter(
          (shipment) =>
            shipment.ControllerSerialNumber === unit?.ControllerSerialNumber,
        ),
    [shipments?.data, unit?.ControllerSerialNumber],
  );

  const { data: activities, isLoading } = useActivitiesForUnit(
    {
      storeId: unitStoreId,
      numberDays: 90,
    },
    unit?.ControllerSerialNumber,
  );

  const aedActivities = useMemo(
    () => activities?.filter(aedActivityFilter) ?? [],
    [activities],
  );

  const { pop, push } = useSidePanels();
  const { setControllerSerialNumber, flyTo, setMarkerViewState } =
    useMapState();

  const [showEditUnitModal, closeEditUnitModal] = useModal(
    () => (
      <EditUnitModal
        unitId={selectedUnit?.ControllerSerialNumber ?? ""}
        onClose={() => {
          setSelectedUnit(null);
          closeEditUnitModal();
        }}
      />
    ),
    [selectedUnit?.ControllerSerialNumber],
  );

  const [showScheduleInspectionModal, closeScheduleInspectionModal] = useModal(
    () => (
      <ScheduleInspectionModal
        controllerSerialNumber={selectedUnit?.ControllerSerialNumber ?? ""}
        onClose={() => {
          setSelectedUnit(null);
          closeScheduleInspectionModal();
        }}
      />
    ),
    [selectedUnit],
  );

  const [showRequestAddressChangeModal, closeRequestAddressChangeModal] =
    useModal(
      () => (
        <RequestAddressChangeModal
          controllerSerialNumber={selectedUnit?.ControllerSerialNumber ?? ""}
          onClose={() => {
            setSelectedUnit(null);
            closeRequestAddressChangeModal();
          }}
        />
      ),
      [selectedUnit],
    );

  const handleShowOnMapClick = useCallback(() => {
    if (unit) {
      setMarkerViewState({ type: "unit" });

      flyTo([unit.Longitude, unit.Latitude]);
    }
  }, [flyTo, unit, setMarkerViewState]);

  const handleCloseClick = useCallback(() => {
    setControllerSerialNumber(null);
    pop();
  }, [pop, setControllerSerialNumber]);

  const handleViewAllIncidentsClick = useCallback(() => {
    history.push(`/store/${unit?.StoreId ?? ""}/incidents`);
  }, [history, unit?.StoreId]);

  const handleViewAllShipmentClick = useCallback(() => {
    history.push(`/store/${unit?.StoreId ?? ""}/shipments`);
  }, [history, unit?.StoreId]);

  const handleIncidentClick = useCallback(
    (incident: Api.Incident) => {
      push({
        id: `incident-${incident.incidentId}`,
        content: <IncidentSidePanel incident={incident} />,
      });
    },
    [push],
  );

  const handleEscalationClick = useCallback(
    (escalation: Api.Escalation) => {
      push({
        id: `escalation-${escalation.UnitReminderId}`,
        content: <EscalationSidePanel escalation={escalation} />,
      });
    },
    [push],
  );

  const handleUnitClick = useCallback(
    (unit?: Api.Unit) => {
      setSelectedUnit(unit);
      showEditUnitModal();
    },
    [showEditUnitModal],
  );

  const handleScheduleInspectionClick = useCallback(
    (unit?: Api.Unit) => {
      setSelectedUnit(unit);
      showScheduleInspectionModal();
    },
    [showScheduleInspectionModal],
  );

  const handleRequestAddressChangeClick = useCallback(
    (unit?: Api.Unit) => {
      setSelectedUnit(unit);
      showRequestAddressChangeModal();
    },
    [showRequestAddressChangeModal],
  );

  useEffect(() => {
    setControllerSerialNumber(unit?.ControllerSerialNumber ?? "");
  }, [setControllerSerialNumber, unit?.ControllerSerialNumber]);

  return (
    <SidePanelContainer aria-label="Device">
      <div>
        {!unit ? (
          <SidePanelHeaderPlaceholder />
        ) : (
          <div style={{ display: "grid" }}>
            <SidePanelHeader
              title={unit.UnitName}
              subtitle={unit.Location ?? "-"}
              closeTitle={
                unit.UnitType === "AutomatedExternalDefibrillator"
                  ? `Close ${t("term.aed_one")} view`
                  : `Close ${t("term.unit_one")} view`
              }
              onClose={handleCloseClick}
            />
            <Button
              stretch
              fit="compact"
              shape="square"
              buttonType="progressive"
              onClick={handleShowOnMapClick}
            >
              Show On Map <Icon name="arrow-alt-right" />
            </Button>
          </div>
        )}
        <div
          style={{
            margin: DS.margins.regular,
            display: "grid",
            gap: DS.margins.micro,
            gridTemplateColumns: "1fr auto",
          }}
        >
          <ReadinessBanner
            controllerSerialNumber={unit?.ControllerSerialNumber}
          />
          <DaysGoneBanner storeId={unit?.StoreId} />
        </div>
      </div>

      <Scroller>
        <SidePanelContentContainer
          contents={[
            ...(unit?.UnitType === "FirstAidStation"
              ? [
                  <>
                    <GroupHeader
                      separator
                      controls={[
                        {
                          title: `View all ${t(
                            "term.incident_other",
                          ).toLowerCase()} for ${unit?.StoreName ?? "…"}`,
                          icon: "arrow-up-right-from-square",
                          onClick: handleViewAllIncidentsClick,
                        },
                      ]}
                    >
                      Recent {t("severity.high")}/{t("severity.medium")}{" "}
                      {t("term.incident_other")}
                    </GroupHeader>
                    <Scroller gutter={DS.margins.regularN}>
                      <IncidentsRow
                        unit={unit}
                        storeId={unit?.StoreId}
                        onIncidentClick={handleIncidentClick}
                      />
                    </Scroller>
                  </>,
                ]
              : []),

            <>
              <GroupedList
                separator
                tight
                title="Actions to Do"
                emptyLabel={
                  <Message type="success">
                    All tasks <strong>complete</strong>, no action required.
                  </Message>
                }
                data={escalations.data
                  ?.filter(openAndNotPausedEscalationsOnly)
                  .filter(
                    (es) =>
                      es.ControllerSerialNumber ===
                      unit?.ControllerSerialNumber,
                  )}
                isLoading={escalations.isLoading}
                isError={escalations.isError}
                errorLabel="Error loading actions"
                listItemKey={(escalation) => escalation.UnitReminderId}
                listItemXpathId={(escalation) =>
                  escalation.ControllerSerialNumber
                }
                listItemImage={(escalation) => (
                  <Icon
                    name={getEscalationIcon(escalation.ReminderType)}
                    color={escalationsPalette(escalation).background}
                  />
                )}
                listItemTitle={(escalation) => (
                  <EscalationTitle escalation={escalation} />
                )}
                listItemDescription={(escalation) => (
                  <EscalationDescription escalation={escalation} />
                )}
                onListItemClick={handleEscalationClick}
              />
            </>,
            <>
              <GroupHeader
                separator
                controls={[
                  {
                    title: `View all shipments for ${unit?.StoreName ?? "…"}`,
                    icon: "arrow-up-right-from-square",
                    onClick: handleViewAllShipmentClick,
                  },
                ]}
              >
                Shipments
              </GroupHeader>
              {activeShipments && activeShipments.length > 0 ? (
                <ShipmentCard shipments={activeShipments} size="full" />
              ) : (
                <Message type="success">No active shipments.</Message>
              )}
            </>,
            <>
              {unit?.UnitType === "AutomatedExternalDefibrillator" && (
                <>
                  <GroupHeader separator>Recent Activity</GroupHeader>
                  <UnstyledList>
                    {isLoading ? (
                      <ListItemsPlaceholder />
                    ) : aedActivities.length === 0 ? (
                      <LargeDataNotice>No recent activity</LargeDataNotice>
                    ) : (
                      aedActivities.map((a) => (
                        <li key={a.ActivityID}>
                          <ListItem
                            image={
                              <Icon
                                name={getActivityIcon(a)}
                                color={palettes.body.small}
                              />
                            }
                            title={getActivityTitle(a)}
                            description={
                              <span>
                                By {a.LastModifiedByUserName} on{" "}
                                <StoreTime
                                  storeName={a.StoreName}
                                  dateTime={a.ModifiedDate}
                                  format={DateFormat.dateTimeAndTimeZone}
                                />
                              </span>
                            }
                          />
                        </li>
                      ))
                    )}
                  </UnstyledList>
                </>
              )}
            </>,
          ]}
        />
      </Scroller>
      <SidePanelFooter>
        {unit?.UnitType === "AutomatedExternalDefibrillator" ? (
          <>
            <Button stretch onClick={() => handleScheduleInspectionClick(unit)}>
              <Icon name="calendar-days" /> Schedule inspection…{" "}
            </Button>
            <Button
              stretch
              onClick={() => handleRequestAddressChangeClick(unit)}
            >
              <Icon name="address-card" />
              Request address change…
            </Button>
            <Button
              buttonType="progressive"
              stretch
              onClick={() => handleUnitClick(unit)}
            >
              {/* FIXME: This should be "single", but that prints "aed" instead
                  of "AED". */}
              Open {t("term.aed_one")} details…
            </Button>
          </>
        ) : (
          <Button
            buttonType="progressive"
            stretch
            onClick={() => handleUnitClick(unit)}
          >
            Open {t("term.firstAidStation_one")} details…
          </Button>
        )}
      </SidePanelFooter>
    </SidePanelContainer>
  );
};

export default UnitSidePanel;
