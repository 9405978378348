import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";

import DS from "@design/system";
import { useCompany, useGroups, useUpdateGroup } from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";

import { findAncestors } from "../util/data";
import Icon from "./Icon";
import InlineEditable from "./InlineEditable";
import { RouteLink } from "./SecondaryButton";

const Container = styled.div`
  width: 100%;
  padding: ${DS.margins.regular} 0 ${DS.margins.micro};
  margin: 0;

  border: 0;

  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
  align-items: center;
  justify-content: start;

  transition: all 100ms ease-in-out;
`;

const Breadcrumbs = styled.ol`
  padding: 0;
  margin: 0;

  list-style: none;

  display: grid;
  grid-auto-flow: column;
  justify-content: start;
`;

const Crumb = styled.li`
  display: flex;
  align-items: center;
`;

const isGroup = (
  group?: Api.Store | Api.TreeGroup | boolean,
): group is Api.TreeGroup =>
  !!(group as Api.TreeGroup)?.GroupId &&
  (group as Api.TreeGroup)?.Type === "Adhoc";

const isFranchise = (
  group?: Api.Store | Api.TreeGroup | boolean,
): group is Api.TreeGroup =>
  !!(group as Api.TreeGroup)?.GroupId &&
  (group as Api.TreeGroup)?.Type === "Franchise";

const isCompany = (
  group?: Api.Store | Api.TreeGroup | boolean,
): group is Api.TreeGroup => (group as Api.TreeGroup)?.Type === "Root";

const isStore = (
  store?: Api.Store | Api.TreeGroup | boolean,
): store is Api.Store => !!(store as Api.Store)?.StoreId;

const isAllStores = (
  group?: Api.Store | Api.TreeGroup | boolean,
): group is Api.TreeGroup => group === true;

const getName = (
  current?: Api.Store | Api.TreeGroup | boolean,
  company?: Api.Company,
  terminology?: string | null,
) => {
  if (isGroup(current)) {
    return current.Name;
  } else if (isFranchise(current) && company) {
    return current.Name;
  } else if (isCompany(current) && company) {
    return company.Name;
  } else if (isStore(current)) {
    return current.Name;
  } else if (isAllStores(current)) {
    return `All ${terminology}`;
  } else {
    return "…";
  }
};

const getSubTitle = (
  current?: Api.Store | Api.TreeGroup | boolean,
  groups?: Api.TreeGroup[],
  terminology?: string | null,
) => {
  if ((isGroup(current) || isFranchise(current)) && groups) {
    const parents = findAncestors(
      groups,
      (group) => group.GroupId === current.GroupId,
      (group) => group.Groups,
    );
    parents.pop();

    return (
      <nav aria-label="Group breadcrumbs">
        <Breadcrumbs>
          {parents.map((parent, i) => (
            <Crumb key={`breadcrumb-${i}`}>
              <RouteLink to={`/group/${parent.GroupId}/overview`}>
                {parent.Name}
              </RouteLink>{" "}
              <Icon name="angle-right" />
            </Crumb>
          ))}
          <li aria-current="location">{current.Name}</li>
        </Breadcrumbs>
      </nav>
    );
  } else if (isCompany(current)) {
    return "Company";
  } else if (isStore(current) && groups) {
    const parents = findAncestors(
      groups,
      (group) => group.GroupId === current.SingleStoreGroupID,
      (group) => group.Groups,
    );
    parents.pop();

    return parents.length ? (
      <nav aria-label="Store breadcrumbs">
        <Breadcrumbs>
          {parents.map((parent, i) => (
            <Crumb key={`breadcrumb-${i}`}>
              <RouteLink to={`/group/${parent.GroupId}/overview`}>
                {parent.Name}
              </RouteLink>{" "}
              <Icon name="angle-right" />
            </Crumb>
          ))}
          <li aria-current="location">{current.Name}</li>
        </Breadcrumbs>
      </nav>
    ) : (
      <nav aria-label="Store breadcrumbs">
        <Breadcrumbs>
          <Crumb key={`breadcrumb-${current.StoreId}`}>
            <RouteLink to={`/all-stores/overview`}>All {terminology}</RouteLink>{" "}
            <Icon name="angle-right" />
          </Crumb>

          <li aria-current="location">{current.StreetAddress.AsOneLine}</li>
        </Breadcrumbs>
      </nav>
    );
  }
};

const GroupSwitchButton = ({
  current,
}: {
  current?: Api.Store | Api.TreeGroup | boolean;
}) => {
  const { t } = useTranslation();
  const { palettes } = useTheme();
  const { icon } = useThemeHelper();

  const { data: company } = useCompany();
  const { data: groups } = useGroups();
  const { mutate: updateGroup } = useUpdateGroup();

  const getIcon = useMemo(() => {
    if (isGroup(current)) {
      return icon("group");
    } else if (isStore(current)) {
      return icon("store");
    } else if (isCompany(current)) {
      return icon("company");
    } else {
      return icon("group");
    }
  }, [current, icon]);

  const handleGroupNameChange = useCallback(
    (newName: string) => {
      if (!current || !isGroup(current)) return;

      updateGroup({
        GroupId: current.GroupId,
        Name: newName,
      });
    },
    [current, updateGroup],
  );

  return (
    <Container>
      <div
        style={{
          width: 40,
          height: 40,
          background: palettes.body.accent,
          borderRadius: 4,
          display: "grid",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <Icon name={getIcon} size={30} color={palettes.body.background} />
      </div>
      <div>
        <h2
          style={{
            margin: 0,
            color: palettes.body.foreground,
            fontSize: 28,
            lineHeight: "32px",
            fontWeight: 700,
          }}
        >
          {isGroup(current) ? (
            <InlineEditable
              value={getName(current, company, t("term.store_one"))}
              placeholder="Group name"
              onChange={handleGroupNameChange}
            />
          ) : (
            getName(current, company, t("term.store_other"))
          )}
        </h2>
        <div
          style={{
            color: palettes.body.small,
            fontSize: 12,
            lineHeight: "15px",
          }}
        >
          {getSubTitle(current, groups, t("term.store_other"))}
        </div>
      </div>
    </Container>
  );
};

export default GroupSwitchButton;
