import {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import useResizeObserver from "use-resize-observer";

const Container = styled.div`
  position: relative;
  display: grid;
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  position: relative;
  overflow: auto;
`;

const Shadow = styled.div`
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 3px;
`;

const ShadowTop = styled(Shadow)`
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
`;

const ShadowBottom = styled(Shadow)`
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
`;

const Scroller = ({
  gutter,
  children,
  onScroll,
  scrollPosition,
}: {
  gutter?: number;
  children?: ReactElement;
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
  scrollPosition?: number;
}) => {
  const elRef = useRef<HTMLDivElement | null>(null);

  const { ref: containerRef, height: containerHeight } = useResizeObserver();
  const { ref: contentRef, height: contentHeight } = useResizeObserver();

  const [topVisible, setTopVisible] = useState<boolean>();
  const [bottomVisible, setBottomVisible] = useState<boolean>();

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (!containerHeight || !contentHeight) return;

      setTopVisible(
        e.currentTarget.scrollTop > 0 && contentHeight > containerHeight,
      );

      setBottomVisible(
        e.currentTarget.scrollTop < contentHeight - containerHeight,
      );
      onScroll && onScroll(e);
    },
    [containerHeight, contentHeight, onScroll],
  );

  useEffect(() => {
    if (!containerHeight || !contentHeight) return;

    setBottomVisible(containerHeight < contentHeight);
  }, [containerHeight, contentHeight]);

  useLayoutEffect(() => {
    elRef.current?.scrollTo({ top: scrollPosition });
  }, [scrollPosition]);

  return (
    <Container
      style={{
        marginLeft: gutter ? gutter * -1 : 0,
        marginRight: gutter ? gutter * -1 : 0,
      }}
    >
      {topVisible && <ShadowTop />}

      <ScrollContainer
        ref={(el) => {
          elRef.current = el;
          containerRef(el);
        }}
        onScroll={handleScroll}
      >
        <div ref={contentRef}>{children}</div>
      </ScrollContainer>

      {bottomVisible && <ShadowBottom />}
    </Container>
  );
};

export default Scroller;
