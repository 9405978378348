import { useFormContext } from "react-hook-form";

import Button from "@components/Button";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";
import DS from "@design/system";

const ReviewStepFooter = () => {
  const { previous } = useWizardContext();
  const {
    formState: { isSubmitting },
    clearErrors,
  } = useFormContext();

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: DS.margins.regular,
        }}
      >
        <Button
          disabled={isSubmitting}
          onClick={() => {
            clearErrors();
            previous();
          }}
        >
          <Icon name="arrow-alt-left" />
          Back
        </Button>
        <Button type="submit" buttonType="action" disabled={isSubmitting}>
          {isSubmitting ? "Confirming…" : "Confirm inspection"}
        </Button>
      </div>
    </div>
  );
};

export default ReviewStepFooter;
