import { useEffect } from "react";
import ReactModal from "react-modal";
import { useTheme } from "styled-components";

import DS from "@design/system";

import { trackEvent } from "../util/analytics";

ReactModal.setAppElement("#root");

const Modal = ({
  isOpen,
  isFullSize,
  contentLabel,
  onClose,
  children,
}: {
  isOpen?: boolean;
  isFullSize?: boolean;
  contentLabel?: string;
  onClose?: () => void;
  children: React.ReactNode;
}) => {
  const { palettes } = useTheme();

  useEffect(() => {
    trackEvent({
      category: "Modal",
      action: "Open",
      label: contentLabel,
    });
  }, [contentLabel]);

  return (
    <ReactModal
      isOpen={isOpen ?? true}
      contentLabel={contentLabel}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        onClose && onClose();
        trackEvent({
          category: "Modal",
          action: "Close",
          label: contentLabel,
        });
      }}
      style={{
        overlay: {
          zIndex: 1,
          background: "rgba(0, 0, 0, 0.25)",

          display: "grid",
          alignItems: "center",
          justifyItems: "center",
        },
        content: {
          boxSizing: "border-box",
          overflow: "hidden",

          ...(isFullSize
            ? {
                top: DS.margins.large,
                right: DS.margins.large,
                bottom: DS.margins.large,
                left: DS.margins.large,
              }
            : {
                top: "auto",
                right: "auto",
                bottom: "auto",
                left: "auto",
                maxWidth: "75%",
              }),

          padding: 0,

          border: 0,
          borderRadius: DS.radii.floating,
          boxShadow: DS.shadows.dialog,
          background: palettes.body.background,
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
