import { Placement } from "react-laag";

import { IIcon } from "../icons";
import Icon from "./Icon";
import { useTooltip } from "./Tooltip";

export const IconWithToolTip = ({
  icon,
  iconSize,
  padding,
  title,
  toolTip,
  tooltipPlacement,
  color,
}: {
  icon: IIcon;
  iconSize?: number;
  padding?: string;
  title?: string;
  toolTip?: React.ReactNode;
  tooltipPlacement?: Placement;
  color: string;
}) => {
  const { tooltipProps } = useTooltip<HTMLDivElement>(toolTip ?? title, {
    placement: tooltipPlacement ?? "top-center",
  });

  return (
    <div
      {...tooltipProps}
      aria-label={title}
      style={{
        padding,
        fontSize: iconSize ?? "inherit",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Icon name={icon} color={color} />
    </div>
  );
};
