import GraphemeSplitter from "grapheme-splitter";
import styled from "styled-components";

import DS from "@design/system";

const Highlight = styled.span`
  background: ${({ theme }) => theme.palettes.messages.notice.background};
  border-radius: ${DS.radii.item};
`;

const splitter = new GraphemeSplitter();

const HighlightChars = ({
  str,
  indices,
}: {
  str: string;
  indices?: Set<number> | null;
}) => {
  if (!indices) return <>{str}</>;

  const chars = splitter.splitGraphemes(str);

  // Skips tracks double or greater width characters
  let skips = 0;

  const nodes = chars
    .reduce<{ match: boolean; chars: string[] }[]>((groups, c, i) => {
      const clone = [...groups];

      if (c.length > 1) {
        skips = skips + c.length - 1;
      }

      const previousCharMatched = indices.has(i + skips - 1);
      const currentCharMatched = indices.has(i + skips);

      if (
        (previousCharMatched && currentCharMatched) ||
        (!previousCharMatched && !currentCharMatched)
      ) {
        const currentGroup = clone.pop() ?? {
          match: currentCharMatched,
          chars: [],
        };
        currentGroup.chars.push(c);

        return [...clone, currentGroup];
      } else {
        return [...clone, { match: currentCharMatched, chars: [c] }];
      }
    }, [])
    .map((group, i) => {
      if (group.match) {
        return <Highlight key={i}>{group.chars}</Highlight>;
      } else {
        return group.chars;
      }
    });

  return <>{nodes}</>;
};

export default HighlightChars;
