import { Placement } from "react-laag";

import { useTooltip } from "./Tooltip";

export const TextWithToolTip = ({
  title,
  toolTipPlacement,
  children,
}: {
  title: string;
  toolTipPlacement: Placement;
  children: React.ReactNode;
}) => {
  const { tooltipProps } = useTooltip<HTMLDivElement>(title, {
    placement: toolTipPlacement ?? "top-center",
  });

  return (
    <span {...tooltipProps} aria-label={title}>
      {children}
    </span>
  );
};
