import { Fragment } from "react";
import { useFormContext } from "react-hook-form";

import BorderBox from "@components/BorderBox";
import ErrorWell from "@components/ErrorWell";
import Message from "@components/Message";
import { WizardStepContainer } from "@components/ModalWizardHelper";
import Scroller from "@components/Scroller";
import { Link } from "@components/SecondaryButton";
import { MediumText, ModalDescription } from "@design/helpers";
import DS from "@design/system";
import { useStoreFull, useUnit } from "@state/hooks";
import { ConfirmReplacementForm } from "@util/viewModel";

const ADDRESS_PARTS: (keyof Api.StreetAddress | (keyof Api.StreetAddress)[])[] =
  ["LocationName", "Line1", "Line2", "City", ["Region", "PostCode"], "Country"];

const addressParts = (address: Api.StreetAddress) =>
  ADDRESS_PARTS.filter((p) => p instanceof Array || !!address[p]).map((p) =>
    p instanceof Array
      ? p.map((ps) => address[ps]).join(", ")
      : String(address[p]),
  );

const ConfirmReplacementNeededStep = () => {
  const { formState, watch } = useFormContext<ConfirmReplacementForm>();

  const [controllerSerialNumber] = watch(["controllerSerialNumber"]);

  const { data: unit } = useUnit(controllerSerialNumber);
  const { data: store } = useStoreFull(unit?.StoreId);

  return (
    <WizardStepContainer>
      <ModalDescription style={{ padding: "0 64px" }}>
        Replacement items will be delivered to the contact and address listed
        below.
      </ModalDescription>

      <div style={{ padding: "0 64px" }}>
        <Message type="info">
          Please{" "}
          <Link href="mailto:support@cleverfirstaid.com?subject=Update AED delivery details">
            contact support
          </Link>{" "}
          if these details need updating.
        </Message>
      </div>

      {formState.errors.root && (
        <ErrorWell>{formState.errors.root.message}</ErrorWell>
      )}

      <Scroller>
        <div
          style={{
            padding: "8px 64px",
            display: "grid",
            gap: 8,
            gridTemplateAreas: "'manager contact' 'address address'",
          }}
        >
          <BorderBox style={{ gridArea: "manager", padding: 8 }}>
            <MediumText
              style={{
                marginBottom: DS.margins.nano,
                fontWeight: 700,
              }}
            >
              Manager
            </MediumText>
            {store?.ContactManager.FullName}
          </BorderBox>

          <BorderBox style={{ gridArea: "contact", padding: 8 }}>
            <MediumText
              style={{
                marginBottom: DS.margins.nano,
                fontWeight: 700,
              }}
            >
              Site Contact
            </MediumText>
            {unit?.ContactUser?.FullName ?? store?.StoreContact.FullName}
          </BorderBox>

          <BorderBox style={{ padding: 8, gridArea: "address" }}>
            <MediumText
              style={{
                marginBottom: DS.margins.nano,
                fontWeight: 700,
              }}
            >
              Delivery Address
            </MediumText>
            {store &&
              addressParts(store?.StreetAddress).map((p, i, arr) => (
                <Fragment key={i}>
                  {p}
                  {i < arr.length - 1 && <br />}
                </Fragment>
              ))}
          </BorderBox>
        </div>
      </Scroller>
    </WizardStepContainer>
  );
};

export default ConfirmReplacementNeededStep;
