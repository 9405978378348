import styled from "styled-components";

const MODE = import.meta.env.MODE ?? "--";
const LOCATION = import.meta.env.VITE_APP_ENV_NAME ?? "--";
const COMMIT = import.meta.env.VITE_APP_DEPLOY_COMMIT ?? "--";
const VERSION = import.meta.env.VITE_APP_VERSION ?? "--";

const Container = styled.div``;

export default function VersionBanner() {
  return (
    <Container>
      {LOCATION} (env: {MODE})
      {LOCATION === "localdev"
        ? ""
        : `/ ${COMMIT.substring(0, 8)} (${VERSION})`}
    </Container>
  );
}
