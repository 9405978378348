import { load } from "@fingerprintjs/fingerprintjs";
import { InternalAxiosRequestConfig } from "axios";
import { v4 as uuidv4 } from "uuid";

import { trimStartAndEndDates } from "@util/dates";

export const attachCorrelationId = (config: InternalAxiosRequestConfig) => {
  config.headers && (config.headers["CorrelationId"] = uuidv4());

  return config;
};

export const attachApplicationInfo = (config: InternalAxiosRequestConfig) => {
  config.headers && (config.headers["IsCFWP"] = "9832&^jlkhbfeJHVJsWQ87%#");

  return config;
};

export const convertStartAndEndDates = (config: InternalAxiosRequestConfig) => {
  trimStartAndEndDates(config.params);
  return config;
};

const fingerprint: { current: string | null } = { current: null };

export const attachFingerprint = async (config: InternalAxiosRequestConfig) => {
  if (typeof window === "undefined") {
    config.headers && (config.headers["Fingerprint"] = "end-to-end-tests");
    return config;
  }

  if (!fingerprint.current) {
    const fp = await load();

    fingerprint.current = (await fp.get()).visitorId;
  }

  config.headers && (config.headers["Fingerprint"] = fingerprint.current);

  return config;
};
