import { useFormContext } from "react-hook-form";

import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";

const ReviewStepFooter = () => {
  const {
    formState: { isSubmitting },
    clearErrors,
  } = useFormContext();

  const { previous } = useWizardContext();

  return (
    <ButtonGroup flow="horizontal">
      <Button
        disabled={isSubmitting}
        onClick={() => {
          clearErrors();
          previous();
        }}
      >
        <Icon name="arrow-alt-left" />
        Back to photo upload
      </Button>
      <Button type="submit" buttonType="action" disabled={isSubmitting}>
        {isSubmitting ? "Confirming…" : "Confirm replacement"}
      </Button>
    </ButtonGroup>
  );
};

export default ReviewStepFooter;
