import { forwardRef, HTMLAttributes } from "react";
import styled, { useTheme } from "styled-components";

import DS from "@design/system";

import Icon from "./Icon";

const EditIcon = styled.div`
  display: none;
  position: absolute;
  top: ${DS.margins.micro};
  right: ${DS.margins.micro};

  font-size: 12px;
  color: ${({ theme }) => theme.palettes.body.foreground};
`;

const Container = styled.div`
  position: relative;
  padding: ${DS.margins.micro};
  margin: ${DS.margins.microCss("rl", true)};

  border-radius: ${DS.radii.item};

  ${({ onClick }) =>
    onClick &&
    `
  cursor: pointer;

  &:hover {
    div {
      display: block;
    }
  }
    `}
`;

interface ClickyThingyProps extends HTMLAttributes<HTMLDivElement> {
  showEditIcon?: boolean;
  isActive?: boolean;
}

const ClickyThingy = forwardRef<HTMLDivElement, ClickyThingyProps>(
  (
    { children, showEditIcon = true, onClick, isActive, title, ...rest },
    ref,
  ) => {
    const theme = useTheme();

    return (
      <Container
        role={onClick ? "button" : undefined}
        aria-label={title}
        title={title}
        tabIndex={onClick ? 0 : undefined}
        onClick={onClick}
        style={{
          backgroundColor: isActive ? theme.palettes.form.dim : "transparent",
        }}
        ref={ref}
        {...rest}
      >
        {showEditIcon && (
          <EditIcon>
            <Icon name="pencil-alt" />
          </EditIcon>
        )}

        {children}
      </Container>
    );
  },
);
ClickyThingy.displayName = "ClickyThingy";

export default ClickyThingy;
