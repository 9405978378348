import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MapRef, MapboxGeoJSONFeature } from "react-map-gl";

import { useStore } from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";

import { Pin, PinContent } from "./Pin";

const EscalationMarker = ({
  mapRef,
  feature,
  storeId,
  selected,
  actions,
  cluster,
}: {
  mapRef: MapRef | null;
  feature: MapboxGeoJSONFeature;
  storeId: string;
  selected: boolean;
  actions: number;
  cluster?: number | null;
}) => {
  const { t } = useTranslation();
  const { escalationsCountPalette } = useThemeHelper();

  const { data: store } = useStore(storeId);

  const { foreground, background } = useMemo(
    () => escalationsCountPalette(actions),
    [actions, escalationsCountPalette],
  );

  return (
    <Pin
      mapRef={mapRef}
      feature={feature}
      source="stores"
      label={store?.Name}
      selected={selected}
      cluster={cluster}
      content={() => (
        <PinContent
          label={<span style={{ color: foreground }}>{actions}</span>}
          description={
            !selected && cluster ? (
              <span style={{ color: foreground }}>
                {t("pages.map.markers.storeCount", { count: cluster })}
              </span>
            ) : (
              <span style={{ color: foreground }}>Actions</span>
            )
          }
          selected={selected}
          cluster={cluster}
        />
      )}
      background={(width, height) => (
        <rect width={width} height={height} fill={background} />
      )}
    />
  );
};

export default EscalationMarker;
