import styled from "styled-components";

import DS from "@design/system";

const LilSquareThing = styled.span<{ border?: boolean; color: string }>`
  display: inline-block;
  width: 12px;
  height: 12px;
  background: ${({ color }) => color};
  border-radius: ${DS.radii.item};
  border: ${({ border }) => (border ? "solid 1px currentColor" : "none")};
`;

export default LilSquareThing;
