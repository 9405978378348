import { useCallback } from "react";

import { MarkerViewState, useUserPreference } from "./useUserPreference";

export const useMarkerState = (): [
  MarkerViewState,
  (newValue: Partial<MarkerViewState>) => void,
] => {
  const [userPreferences, setUserPreferences] = useUserPreference({
    key: "markerView",
    defaultValue: {
      type: "store",
      mode: "readiness",
    },
  });

  // Provide a partial updater for convenience
  const updateSettings = useCallback(
    (newSettings: Partial<MarkerViewState>): void => {
      setUserPreferences((prev) => ({
        ...prev,
        ...newSettings,
      }));
    },

    [setUserPreferences],
  );

  return [userPreferences, updateSettings];
};
