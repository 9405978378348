import { forwardRef } from "react";
import styled, { useTheme } from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { SemiStrongText, SmallText } from "@design/helpers";
import DS from "@design/system";
import ContentLoader, { TextBlock } from "@util/ContentLoader";

import Icon from "./Icon";

const ImageContainer = styled.div`
  align-self: center;
  filter: grayscale(100%);

  transition: filter ease-in-out 100ms;

  > svg {
    display: block;
  }
`;

const IconContainer = styled.div`
  & > :last-child {
    opacity: 0;

    background: ${({ theme }) => theme.palettes.body.background};
    border-radius: 10px;

    transition: opacity ease-in-out 100ms;
  }
`;

const Label = styled.label`
  padding: ${DS.margins.micro};

  cursor: pointer;
  user-select: none;

  border-radius: ${DS.radii.largeItem};
  border: solid 1px transparent;

  background: ${({ theme }) => theme.palettes.body.background};

  display: grid;
  grid-template-areas:
    "image title selected"
    "image description selected";

  grid-template-columns: 48px 1fr auto;
  grid-template-rows: auto 1fr;
  column-gap: ${DS.margins.micro};
  row-gap: 0;

  transition: all ease-in-out 100ms;
`;

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

const Container = styled.div`
  ${Label}:hover {
    background: ${({ theme }) => theme.palettes.itemSelected.light};
  }

  ${Checkbox}:checked + ${Label} {
    border-color: ${({ theme }) => theme.palettes.itemSelected.border};
    background: ${({ theme }) => theme.palettes.itemSelected.background};

    ${ImageContainer} {
      filter: grayscale(0);
    }

    ${IconContainer} > :last-child {
      opacity: 1;
    }
  }

  ${Checkbox}:checked + ${Label}:hover {
    background: ${({ theme }) => theme.palettes.itemSelected.dim};
  }
`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Use title case
   */
  title: string;
  description: React.ReactNode;
  image: React.ReactNode;
}

export const WizardOptionPlaceholder = () => (
  <Container>
    <ContentLoader width={352} height={68}>
      <rect x={9} y={9} rx={4} ry={4} width={50} height={50} />

      <TextBlock x={64} y={11} width={120} fontSize={16} />
      <TextBlock x={64} y={30} width={260} fontSize={12} />
      <TextBlock x={64} y={45} width={140} fontSize={12} />
    </ContentLoader>
  </Container>
);

const WizardOption = forwardRef<HTMLInputElement, Props>(
  (
    {
      id = `option-${uuidv4()}`,
      title,
      description,
      image,
      type = "checkbox",
      ...rest
    },
    ref,
  ) => {
    const { palettes } = useTheme();

    return (
      <Container>
        <Checkbox id={id} type={type} ref={ref} {...rest} />
        <Label htmlFor={id}>
          <ImageContainer role="presentation" style={{ gridArea: "image" }}>
            {image}
          </ImageContainer>
          <SemiStrongText
            style={{
              margin: 0,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              gridArea: "title",
            }}
          >
            {title}
          </SemiStrongText>
          <SmallText
            style={{
              margin: 0,
              gridArea: "description",
            }}
          >
            {description}
          </SmallText>
          <IconContainer style={{ position: "relative", gridArea: "selected" }}>
            <div
              style={{
                position: "absolute",
                width: 16,
                height: 16,
                top: 0,
                right: 0,
                color: palettes.form.border,
                display: "grid",
              }}
            >
              <Icon name={"square-o"} />
            </div>
            <div
              style={{
                position: "absolute",
                width: 16,
                height: 16,
                top: 0,
                right: 0,
                color: palettes.form.accent,
                display: "grid",
              }}
            >
              <Icon name={"check-square"} />
            </div>
          </IconContainer>
        </Label>
      </Container>
    );
  },
);

WizardOption.displayName = "WizardOption";

export default WizardOption;
