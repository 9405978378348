import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";
import { ConfirmReplacementForm } from "@util/viewModel";

const StartStepFooter = ({ onCancel }: { onCancel: () => void }) => {
  const { goTo } = useWizardContext();
  const { formState, watch } = useFormContext<ConfirmReplacementForm>();

  const choice = watch("choice");

  const buttonLabel = useMemo(
    () =>
      choice === "needed"
        ? "Next, confirm"
        : choice === "not-needed"
          ? "Next, select reason"
          : "Next",
    [choice],
  );

  const handleNextClick = useCallback(
    () =>
      choice === "needed"
        ? goTo("replacementNeeded", true)
        : goTo("selectReasons", true),
    [choice, goTo],
  );

  return (
    <ButtonGroup flow="horizontal">
      <Button disabled={formState.isSubmitting} onClick={onCancel}>
        Close
      </Button>
      <Button
        buttonType="progressive"
        disabled={choice === undefined}
        onClick={handleNextClick}
      >
        {buttonLabel} <Icon name="arrow-alt-right" />
      </Button>
    </ButtonGroup>
  );
};

export default StartStepFooter;
