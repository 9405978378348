import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useCompanyTerminology } from "@state/hooks";

const useTerminology = () => {
  const { i18n } = useTranslation();
  const { data: terminology } = useCompanyTerminology();

  useEffect(() => {
    if (!terminology) return;

    const termKeys: [
      key: I18n.PluralTermKeys,

      one: keyof Api.Terminology,
      other: keyof Api.Terminology,

      zero?: keyof Api.Terminology,
      two?: keyof Api.Terminology,
      few?: keyof Api.Terminology,
      many?: keyof Api.Terminology,
    ][] = [
      ["store", "Store", "Stores"],
      ["unit", "Unit", "Units"],

      // Unit Types
      [
        "aed",
        "AutomatedExternalDefibrillator",
        "AutomatedExternalDefibrillators",
      ],
      ["firstAidStation", "FirstAidStation", "FirstAidStations"],
      ["eyewashStation", "EyewashStation", "EyewashStations"],

      // Incidents
      ["incident", "Incident", "Incidents"],
      ["severity", "SeverityWord", "SeverityWords"],
    ];

    const severityKeys: [
      key: I18n.SeverityTermKeys,
      value: keyof Api.Terminology,
    ][] = [
      ["low", "IncidentSeverity-Low"],
      ["medium", "IncidentSeverity-Medium"],
      ["high", "IncidentSeverity-High"],
    ];

    const incidentCategoryKeys: [
      key: I18n.IncidentCategoryTermKeys,
      value: keyof Api.Terminology,
    ][] = [
      ["burn", "IncidentCategory-Burns"],
      ["eye", "IncidentCategory-Eyes"],
      ["stf", "IncidentCategory-STF"],
      ["cut", "IncidentCategory-Cuts"],
      ["cleaning", "IncidentCategory-Cleaning"],
      ["safety", "IncidentCategory-Safety"],
      ["uncategorized", "IncidentCategory-Uncategorized"],
      ["boxOfItems", "IncidentCategory-BoxOfItems"],
      ["hardware", "IncidentCategory-Hardware"],
      ["tools", "IncidentCategory-Tools"],
      ["supplementary", "IncidentCategory-Supplementary"],
      ["unknown", "IncidentCategory-Unknown"],
    ];

    i18n.addResourceBundle(
      "en",
      "translation",
      {
        term: termKeys.reduce<I18n.PluralTerms>(
          (p, [key, one, other, zero, two, few, many]) => ({
            ...p,
            [`${key}_one`]: terminology[one],
            [`${key}_other`]: terminology[other],

            [`${key}_zero`]: terminology[zero ?? other],
            [`${key}_two`]: terminology[two ?? other],
            [`${key}_few`]: terminology[few ?? other],
            [`${key}_many`]: terminology[many ?? other],
          }),
          {} as I18n.PluralTerms,
        ),

        severity: severityKeys.reduce<I18n.SeverityTerms>(
          (p, [key, value]) => ({
            ...p,
            [key]: terminology[value],
          }),
          {} as I18n.SeverityTerms,
        ),

        incidentCategory:
          incidentCategoryKeys.reduce<I18n.IncidentCategoryTerms>(
            (p, [key, value]) => ({
              ...p,
              [key]: terminology[value],
            }),
            {} as I18n.IncidentCategoryTerms,
          ),
      },
      true,
      true,
    );
    // Adding 'en' to the deps allows us to HMR the page during dev and have the
    // translations update correctly. Without this, we lose sync with the
    // terminologies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminology]);
};

export default useTerminology;
