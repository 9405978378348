import React, { useState, useRef, useCallback, useEffect } from "react";
import { useTheme } from "styled-components";

import { UnstyledList } from "@design/helpers";
import DS from "@design/system";

import ClickyThing from "./ClickyThing";
import { LabelledInput } from "./FormControls";
import Icon from "./Icon";

interface Props<T> {
  label: string;
  items: T[];
  value: T[];

  searchPlaceHolder: string;
  isSearchEnabled?: boolean;

  getKey: (item: T) => string;
  getLabel: (item: T) => string;
  itemRenderer: (
    item: T,
    matchIndicies?: number[] | null,
    activeItem?: boolean | null,
  ) => React.ReactNode;

  onItemAdded?: (item: T) => void;
  onItemRemoved?: (item: T) => void;
}

const MultiSelect = <T extends unknown>({
  label,
  items,
  value,
  searchPlaceHolder,
  isSearchEnabled,
  getKey,
  getLabel,
  itemRenderer,
  onItemAdded,
  onItemRemoved,
}: Props<T>): JSX.Element => {
  const { palettes } = useTheme();

  const wrapperDivRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const mapsRef = useRef<HTMLLIElement[]>([]);

  const [inputValue, setInputValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>();
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0);
  const [isHover, setIsHover] = useState(false);

  const removeItem = useCallback(
    (item: T) => onItemRemoved && onItemRemoved(item),
    [onItemRemoved],
  );

  const addItem = useCallback(
    (item: T) => onItemAdded && onItemAdded(item),
    [onItemAdded],
  );

  const getFilteredItems = useCallback(() => {
    return items.filter((item) =>
      getLabel(item)
        .toLowerCase()
        .startsWith(inputValue?.toLowerCase() ?? ""),
    );
  }, [items, getLabel, inputValue]);

  const isItemSelected = useCallback(
    (item: T) => {
      return value.some((value) => getKey(value) === getKey(item));
    },
    [getKey, value],
  );

  const getSelectedItemsToDisplay = useCallback(() => {
    const output = value
      .map((selectedItem) => ` ${getLabel(selectedItem)}`)
      .toString()
      .substring(0, 26);

    return output.length === 26 ? `${output}...` : output;
  }, [getLabel, value]);

  const currentItem = useCallback(() => {
    return items.find((value) => items.indexOf(value) === highlightedIndex);
  }, [items, highlightedIndex]);

  const onItemClicked = useCallback(
    (clickedItem: T | undefined) => {
      if (!clickedItem) return;

      if (isItemSelected(clickedItem)) removeItem(clickedItem);
      else addItem(clickedItem);
    },
    [isItemSelected, removeItem, addItem],
  );

  const resetActiveItemAndIndex = useCallback(() => {
    setHighlightedIndex(0);
    setInputValue("");
    setIsOpen(false);
  }, [setInputValue, setHighlightedIndex]);

  const setScrollToItem = useCallback(
    (activeIndex: number, direction: "up" | "down") => {
      const element = mapsRef.current[activeIndex];

      if (!element.parentElement?.parentElement) return;

      const { top: elemntTop, bottom: elemntBottom } =
        element.getBoundingClientRect();

      const { top: scrollBoxTop, bottom: scrollBoxBottom } =
        element.parentElement.parentElement.getBoundingClientRect();

      const distToTopOfScrollBox = elemntTop - scrollBoxTop;
      const distToBottomOfScrollBox = scrollBoxBottom - elemntBottom;

      const topReached = distToTopOfScrollBox < 0;
      const bottomReached = distToBottomOfScrollBox < 0;

      if (!topReached && !bottomReached) return; // Scroll not needed

      element.scrollIntoView({
        block: direction === "up" ? "start" : "end",
        inline: "nearest",
        behavior: "smooth",
      });
    },
    [],
  );

  const setNextItemActiveDown = useCallback(() => {
    if (highlightedIndex === items.length - 1) return;
    setHighlightedIndex(highlightedIndex + 1);
    setScrollToItem(highlightedIndex, "down");
  }, [highlightedIndex, items.length, setScrollToItem]);

  const setNextItemActiveUp = useCallback(() => {
    if (highlightedIndex === 0) return;
    setHighlightedIndex(highlightedIndex - 1);
    setScrollToItem(highlightedIndex, "up");
  }, [highlightedIndex, setScrollToItem]);

  const keyDownHandler: React.KeyboardEventHandler = useCallback(
    (event) => {
      switch (event.key) {
        case "Escape":
          setIsOpen(false);
          resetActiveItemAndIndex();
          break;
        case "ArrowDown":
          setNextItemActiveDown();
          break;
        case "ArrowUp":
          setNextItemActiveUp();
          break;
        case "Enter":
          onItemClicked(currentItem());
          setIsOpen(false);
          break;
      }
    },
    [
      currentItem,
      onItemClicked,
      resetActiveItemAndIndex,
      setNextItemActiveDown,
      setNextItemActiveUp,
    ],
  );

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        wrapperDivRef.current &&
        !wrapperDivRef.current.contains(e.target as Node)
      ) {
        resetActiveItemAndIndex();
      }
    },
    [resetActiveItemAndIndex],
  );

  useEffect(() => {
    if (!isOpen) return;

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside, isOpen]);

  useEffect(() => {
    if (isOpen) searchInputRef.current?.focus();
  }, [isOpen]);

  return (
    <div
      style={{
        padding: `${DS.margins.nano} ${DS.margins.micro} ${DS.margins.micro}`,

        background: palettes.form.background,
        border: `solid 1px ${palettes.form.border}`,
        borderRadius: DS.radii.item,

        cursor: "pointer",
        ...(isHover && !isOpen
          ? { backgroundColor: palettes.form.dim }
          : { backgroundColor: palettes.form.background }),
        ...(isOpen
          ? { boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.15)" }
          : { boxShadow: "none" }),
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {/* eslint-disable-next-line
            jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-static-element-interactions */}
      <div onClick={() => setIsOpen(!isOpen)} style={{ display: "flex" }}>
        <div>
          <div style={{ fontSize: "10px", color: palettes.form.title }}>
            {label}
          </div>
          <div style={{ height: "15px", marginTop: "-4px" }}>
            <span
              style={{
                paddingRight: 8,
                margin: "0px",
                fontSize: "14px",
              }}
            >
              {getSelectedItemsToDisplay()}
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            aria-label="toggle menu"
            style={{
              WebkitAppearance: "none",
              backgroundColor: "transparent",
              border: "none",
              color: palettes.form.title,
            }}
          >
            <Icon name="caret-down" />
          </div>
        </div>
      </div>

      <UnstyledList>
        {isOpen && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            id="multiselect-items"
            style={{
              overflow: "hidden",
              position: "absolute",
              zIndex: 2,
              borderRadius: DS.radii.largeItem,
              background: palettes.form.background,
              boxShadow: DS.shadows.panel,
              padding: "5px",
            }}
            ref={wrapperDivRef}
            onKeyDown={keyDownHandler}
          >
            {isSearchEnabled && (
              <LabelledInput
                placeholder={searchPlaceHolder}
                autoComplete="off"
                spellCheck="false"
                autoCorrect="off"
                ref={searchInputRef}
                onChange={(event) => setInputValue(event.currentTarget.value)}
              />
            )}
            <div
              style={{
                boxSizing: "border-box",
                overflow: "hidden auto",
                width: "100%",
                maxHeight: 200,
                padding: DS.margins.micro,
              }}
            >
              {getFilteredItems().map((item, index) => (
                <li
                  key={`${getKey(item)}-${index}`}
                  style={{
                    margin: 0,
                    padding: 0,

                    ...(highlightedIndex === index
                      ? { background: palettes.form.background }
                      : {}),
                  }}
                  ref={(el) => {
                    if (el) {
                      mapsRef.current[index] = el;
                    }
                  }}
                >
                  <ClickyThing
                    showEditIcon={false}
                    onClick={() => {
                      onItemClicked(item);
                    }}
                    isActive={highlightedIndex === index}
                    onMouseEnter={() => setHighlightedIndex(index)}
                  >
                    {isItemSelected(item) ? (
                      <span style={{ marginRight: DS.margins.nano }}>
                        <Icon name="square-checked"></Icon>
                      </span>
                    ) : (
                      <span style={{ marginRight: DS.margins.nano }}>
                        <Icon name="square"></Icon>
                      </span>
                    )}
                    {itemRenderer(item)}
                  </ClickyThing>
                </li>
              ))}
            </div>
          </div>
        )}
      </UnstyledList>
    </div>
  );
};

export default MultiSelect;
