import { useCallback, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import DS from "@design/system";

import ContextMenu, { ContextMenuItem } from "./ContextMenu";
import Icon from "./Icon";
import MediaCard, { MediaCardPlaceholder } from "./MediaCard";

interface ListItemProps {
  image?: React.ReactNode;
  compact?: boolean;
  title: React.ReactNode;
  description?: React.ReactNode;
  descriptionTip?: React.ReactNode;

  label?: string;
  imageSize?: number;
  contextMenu?: ContextMenuItem[];

  /**
   * Controls the visibility of the chevron in cases where this ListItem will navigate somewhere.
   */
  linked?: boolean;
}

const Container = styled.div<{ compact: boolean }>`
  padding: ${({ compact }) => (compact ? 0 : DS.margins.micro)};

  display: grid;

  grid-template-columns: auto 1fr auto;
  justify-content: space-between;
  column-gap: ${DS.margins.micro};
`;

export const ListItemPlaceholder = ({
  showImage = true,
  imageSize = 32,
  compact = false,
}: {
  showImage?: boolean;
  imageSize?: number;
  compact?: boolean;
}) => (
  <Container compact={compact}>
    <MediaCardPlaceholder showImage={showImage} imageSize={imageSize} />
  </Container>
);

export const ListItemsPlaceholder = ({
  showImage = true,
  imageSize = 32,
  count = 3,
  compact = false,
}: {
  showImage?: boolean;
  imageSize?: number;
  count?: number;
  compact?: boolean;
}) => (
  <>
    {Array.from({ length: count }, (_never, i) => (
      <ListItemPlaceholder
        key={`placeholder-${i}`}
        compact={compact}
        showImage={showImage}
        imageSize={imageSize}
      />
    ))}
  </>
);

const ListItem = ({
  image,
  compact = false,
  title,
  description,
  descriptionTip,
  label = "",
  imageSize = 32,
  contextMenu,
  linked = false,
}: ListItemProps) => {
  const { palettes } = useTheme();

  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleActiveToggle = useCallback(
    (active: boolean) => setActive(active),
    [],
  );

  return (
    <Container
      title={label}
      compact={compact}
      aria-label={label}
      ref={containerRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onFocus={() => setHovered(true)}
      onBlur={() => setHovered(false)}
    >
      <MediaCard
        image={image}
        imageSize={imageSize}
        title={title}
        description={description}
        descriptionTip={descriptionTip}
      />

      {contextMenu && (hovered || active) && (
        <div style={{ gridColumn: "3", display: "grid", alignItems: "center" }}>
          <ContextMenu
            label="Options"
            placement="right-center"
            onActiveToggle={handleActiveToggle}
            items={contextMenu}
          />
        </div>
      )}

      {linked && !contextMenu && (hovered || active) && (
        <div style={{ gridColumn: "3", display: "grid", alignItems: "center" }}>
          <Icon name="angle-right" color={palettes.body.small} />
        </div>
      )}
    </Container>
  );
};

export default ListItem;
