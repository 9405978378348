import { formatDistanceToNow } from "date-fns";
import { Fragment, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";

import { useTooltip } from "@components/Tooltip";
import { StrongText, SmallText, UnstyledList } from "@design/helpers";
import DS from "@design/system";
import {
  useEscalations,
  useStore,
  useUnitReadinessReasons,
} from "@state/hooks";
import {
  getEscalationIcon,
  openAndNotPausedEscalationsOnly,
} from "@util/escalations";
import { getLastHeartbeat } from "@util/firstAidStation";
import { useThemeHelper } from "@util/useThemeHelper";

import ContentLoader, { TextBlock } from "../util/ContentLoader";
import ContextMenu, { ContextMenuItem } from "./ContextMenu";
import HighlightChars from "./HighlightChars";
import Icon from "./Icon";
import { IconWithToolTip } from "./IconWithToolTip";
import QuantityContainer from "./QuantityContainer";
import UnitTypeIcon from "./UnitTypeIcon";

const Container = styled.div<{ active: boolean }>`
  background: ${({ active, theme }) =>
    active ? theme.palettes.body.dim : theme.palettes.body.background};
  border-radius: ${DS.radii.floating};
  cursor: pointer;
`;

const Content = styled.div<{ active: boolean }>`
  display: grid;
  position: relative;
  place-items: center;
  height: 128px;
  background: ${({ active, theme }) =>
    active ? theme.palettes.well.dim : theme.palettes.well.background};
  border-top-left-radius: ${DS.radii.floating};
  border-top-right-radius: ${DS.radii.floating};
  transition: all 300ms ease-in-out;
`;

const PillCapsuleContainer = styled.div<{ background?: string }>`
  height: 16px;
  padding-left: 4px;
  padding-right: 8px;
  font-size: 10px;

  border-radius: 8px;
  background: ${({ background, theme }) =>
    background || theme.palettes.body.foreground};

  display: flex;
  align-items: center;
  gap: 4px;
`;

const PillCapsule = ({
  color,
  inverted,
  label,
}: {
  color: string;
  inverted?: boolean;
  label: ReactNode;
}) => {
  const { palettes } = useTheme();

  return (
    <PillCapsuleContainer
      style={{
        color: inverted ? palettes.well.background : palettes.well.foreground,
        background: inverted
          ? palettes.well.foreground
          : palettes.well.background,
      }}
    >
      <Icon name="circle-solid" color={color} size={8} />
      {label}
    </PillCapsuleContainer>
  );
};

export const UnitCardPlaceholder = () => {
  const { palettes } = useTheme();

  return (
    <div
      style={{
        paddingTop: DS.margins.largeN - 4,
        border: `solid 1px ${palettes.body.border}`,
        borderRadius: DS.radii.largeItemN,

        display: "grid",
        justifyItems: "center",
        alignItems: "center",
      }}
    >
      <ContentLoader title="Loading person…" width={192} height={224}>
        <circle cx={192 / 2} cy={50} r={50} />

        <TextBlock
          x={192 / 2 - 120 / 2}
          y={105 + 24}
          width={120}
          fontSize={11}
        />
        <TextBlock
          x={192 / 2 - 90 / 2}
          y={105 + 24 + 24}
          width={90}
          fontSize={10}
        />
        <TextBlock
          x={192 / 2 - 70 / 2}
          y={101 + 24 + 24 + 24}
          width={70}
          fontSize={8}
        />
      </ContentLoader>
    </div>
  );
};

const UnitCard = ({
  unit,
  highlight,
  showStoreName,
  onClick,
  onScheduleInspection,
  onRequestAddressChange,
}: {
  unit?: Api.Unit;
  showStoreName?: boolean;
  highlight?: Set<number> | null;
  onClick: (e: React.MouseEvent) => void;
  onScheduleInspection: () => void;
  onRequestAddressChange: () => void;
}) => {
  const { t } = useTranslation();
  const { palettes } = useTheme();
  const { escalationsPalette, stockReplenishmentStatusPalette } =
    useThemeHelper();

  const { data: escalations } = useEscalations({ storeId: unit?.StoreId });
  const { data: store } = useStore(unit?.StoreId);
  const unitReadiness = useUnitReadinessReasons(unit?.ControllerSerialNumber);

  const openEscalations = useMemo(
    () =>
      escalations
        ?.filter(openAndNotPausedEscalationsOnly)
        .filter(
          (es) => es.ControllerSerialNumber === unit?.ControllerSerialNumber,
        ),
    [escalations, unit?.ControllerSerialNumber],
  );

  const { tooltipProps } = useTooltip<HTMLDivElement>(unitReadiness.reason);

  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);

  if (!unit) return <UnitCardPlaceholder />;

  return (
    <Container
      active={hovered || active}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onFocus={() => setHovered(true)}
      onBlur={() => setHovered(false)}
    >
      <Content active={active || hovered}>
        <div
          {...tooltipProps}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",

            transform: hovered
              ? "translate3d(-50%, -50%, 0) scale3d(1.1, 1.1, 1)"
              : "translate3d(-50%, -50%, 0) scale3d(1, 1, 1)",
            transition: "transform 200ms ease-in-out",
          }}
        >
          <UnitTypeIcon unitId={unit.ControllerSerialNumber} size={80} />
        </div>
        <div
          style={{
            position: "absolute",
            margin: DS.margins.micro,
            left: 0,
            bottom: 0,

            display: "grid",
            justifyItems: "start",
            gap: DS.margins.nano,
          }}
        >
          {unit.Status === "Transit" && (
            <PillCapsule
              color={palettes.states.neutral.background}
              inverted
              label={t("units.status.Transit.statusPill")}
            />
          )}

          {unit.Status !== "Transit" && (
            <PillCapsule
              color={
                stockReplenishmentStatusPalette(unit.ReplenishmentStatus)
                  .background
              }
              label={t(
                `units.replenishmentStatus.${unit.ReplenishmentStatus}.statusPill`,
              )}
            />
          )}

          {unit.UnitType === "FirstAidStation" &&
            unit.Status !== "Transit" &&
            !unit.IsActive &&
            !!unit.LastHeartbeat && (
              <PillCapsule
                color={palettes.states.bad.background}
                inverted
                label={
                  <>
                    Offline for <strong>{getLastHeartbeat(unit)}</strong>
                  </>
                }
              />
            )}
        </div>
      </Content>

      <div
        style={{
          borderRight: `1px solid ${palettes.body.border}`,
          borderBottom: `1px solid ${palettes.body.border}`,
          borderLeft: `1px solid ${palettes.body.border}`,

          borderBottomLeftRadius: DS.radii.floating,
          borderBottomRightRadius: DS.radii.floating,
        }}
      >
        <div
          style={{ padding: "8px 16px 0 16px", display: "grid", gap: "8px" }}
        >
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              textAlign: "left",
            }}
          >
            <StrongText>
              <HighlightChars str={unit.UnitName} indices={highlight} />
            </StrongText>
            {showStoreName && (
              <>
                <SmallText
                  style={{
                    color: palettes.body.small,
                    textAlign: "left",
                  }}
                >
                  {unit.StoreName}
                </SmallText>
              </>
            )}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              gap: "4px",
              justifyContent: "space-between",
              paddingBottom: "16px",
            }}
          >
            {!openEscalations?.length ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: palettes.well.background,
                  height: "24px",
                  borderRadius: "12px",
                  paddingLeft: "4px",
                  paddingRight: "8px",
                  paddingTop: "0",
                  paddingBottom: "0",
                  boxSizing: "border-box",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ padding: "6px" }}>
                    <Icon
                      name="check-alt-circle"
                      size={12}
                      color={palettes.states.good.background}
                    />
                  </div>
                  <span
                    style={{
                      color: palettes.well.foreground,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                  >
                    No actions to do
                  </span>
                </div>
              </div>
            ) : (
              <UnstyledList horizontal gap={DS.margins.nanoN}>
                {openEscalations?.map((es, i) => (
                  <li key={i}>
                    <QuantityContainer style={escalationsPalette(es).toCss()}>
                      <IconWithToolTip
                        icon={
                          es.ReminderType === "DeviceInspection"
                            ? "aed"
                            : getEscalationIcon(es.ReminderType)
                        }
                        color={escalationsPalette(es).foreground}
                        toolTip={
                          <>
                            <Icon
                              name="circle-solid"
                              size={8}
                              color={escalationsPalette(es).background}
                            />{" "}
                            <Fragment>
                              {store?.StoreContact.FullName} contacted{" "}
                              {formatDistanceToNow(new Date(es.CreatedDate), {
                                addSuffix: true,
                              })}
                            </Fragment>
                          </>
                        }
                      />
                    </QuantityContainer>
                  </li>
                ))}
              </UnstyledList>
            )}

            {unit.UnitType === "AutomatedExternalDefibrillator" && (
              <ContextMenu
                label={`${t("term.aed_one")} options`}
                shape="circle"
                placement="right-start"
                onActiveToggle={(a) => setActive(a)}
                items={[
                  {
                    key: "schedule-inspection",
                    icon: "calendar-days",
                    label: `Schedule ${t("term.aed_one")} inspection…`,
                    onClick: (close) => {
                      onScheduleInspection();
                      close();
                    },
                  } as ContextMenuItem,
                  {
                    key: "request-address-change",
                    icon: "address-card",
                    label: "Request address change…",
                    onClick: (close) => {
                      onRequestAddressChange();
                      close();
                    },
                  } as ContextMenuItem,
                ]}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default UnitCard;
