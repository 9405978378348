import styled from "styled-components";

import DS from "@design/system";

const BorderBox = styled.div`
  padding: ${DS.margins.regular};

  border: solid 1px ${({ theme }) => theme.palettes.body.border};
  border-radius: ${DS.radii.largeItem};
`;

export default BorderBox;
