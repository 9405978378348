import { useId, useMemo } from "react";

export function useIdOrDefault(defaultId?: string) {
  const newId = useId();

  return useMemo(() => {
    if (defaultId && !defaultId?.match(/^[a-zA-Z]/)) {
      throw new Error(
        `Id not valid, '${defaultId}' should start with a letter`,
      );
    }

    return defaultId ?? newId;
  }, [newId, defaultId]);
}
