import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { TitleSmall } from "@design/helpers";
import DS from "@design/system";

import StoreLocationMap from "../components/StoreLocationMap";
import { usePageTitle, useStoreFull } from "../state/hooks";
import ContentLoader, { TextBlock } from "../util/ContentLoader";
import useParamsUpper from "../util/useParamsUpper";

const StoreSettings = () => {
  const { t } = useTranslation();
  const { storeId } = useParamsUpper<{ storeId: string }>();
  const { palettes } = useTheme();

  const { data: store } = useStoreFull(storeId);

  usePageTitle(`${store ? store.Name : ""} Settings`);

  return (
    <div
      style={{
        height: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 320px",
      }}
    >
      <div
        style={{
          padding: 16,
          display: "grid",
          gap: DS.margins.regular,
          alignContent: "start",
        }}
      >
        <TitleSmall>{t("term.store_one")} Location</TitleSmall>
        <p style={{ margin: 0 }}>
          {store ? (
            <>
              <strong>{store.Name}</strong>
              <br />
              {store.StreetAddress.LocationName}
              <br />
              {store.StreetAddress.Line1}
              <br />
              {store.StreetAddress.Line2}
              <br />
              {store.StreetAddress.City} {store.StreetAddress.PostCode}
              <br />
              {store.StreetAddress.Region}
              <br />
              {store.StreetAddress.Country}
              <br />
            </>
          ) : (
            <div>
              <ContentLoader
                title="Loading store information…"
                width={160}
                height={120}
              >
                <TextBlock x={0} y={5} width={120} fontSize={12} />
                <TextBlock x={0} y={25} width={160} fontSize={12} />
                <TextBlock x={0} y={45} width={100} fontSize={12} />
                <TextBlock x={0} y={65} width={80} fontSize={12} />
                <TextBlock x={0} y={85} width={120} fontSize={12} />
                <TextBlock x={0} y={105} width={110} fontSize={12} />
              </ContentLoader>
            </div>
          )}
        </p>
      </div>

      <div
        style={{
          padding: 16,
          borderLeft: `solid 1px ${palettes.body.border}`,
        }}
      >
        {store ? (
          <StoreLocationMap store={store} width={288} height={288} />
        ) : (
          <ContentLoader
            title={`Loading ${t("term.store_one").toLowerCase()} location map`}
            width={288}
            height={288}
          >
            <rect
              width={288}
              height={288}
              rx={DS.radii.item}
              ry={DS.radii.item}
            />
          </ContentLoader>
        )}
      </div>
    </div>
  );
};

export default StoreSettings;
