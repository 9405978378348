import { FormEventHandler, useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Icon from "@components/Icon";
import Message from "@components/Message";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import { WizardStepContainer, Overlay } from "@components/ModalWizardHelper";
import ModalWizardLayout from "@components/ModalWizardLayout";
import { LogOut, UserFriends } from "@components/WizardIcons";
import WizardOption from "@components/WizardOption";
import {
  LargeDataNotice,
  ModalDescription,
  SmallText,
  UnstyledList,
} from "@design/helpers";
import DS from "@design/system";
import { useCompany, useMutateCompanyMfa } from "@state/hooks";

const EnableCompanyMfaModal = ({ onClose }: { onClose: () => void }) => {
  const { data: company } = useCompany();
  const { mutateAsync: updateCompanyMfa } = useMutateCompanyMfa();

  const [attemptCancel, setAttemptCancel] = useState<boolean>(false);

  const { control, formState, handleSubmit, register, setError, watch } =
    useForm<Api.CompanyMfa & { acceptDisable: boolean }>({
      defaultValues: {
        enable: !company?.IsMfaPolicyEnabled,
        forceSignOutUsers: true,
        companyId: company?.CompanyId,

        // Client-side only acceptance if disabling 2FA when it has previously
        // been enabled.
        acceptDisable: false,
      },
    });

  const [acceptDisable, mfaEnable, forSignOutUsers] = watch([
    "acceptDisable",
    "enable",
    "forceSignOutUsers",
  ]);

  const onSubmit: FormEventHandler = useCallback(
    (formEvent) =>
      void handleSubmit(async (data) => {
        try {
          await updateCompanyMfa({ ...data, enable: mfaEnable });
          onClose();

          toast.success(
            `Two-factor authentication ${mfaEnable ? "enabled" : "disabled"}.`,
          );
        } catch (error) {
          setError("root", {
            message:
              "We encountered an issue on our side. Please try again soon.",
          });
        }
      })(formEvent),
    [handleSubmit, mfaEnable, onClose, setError, updateCompanyMfa],
  );

  return (
    <Modal contentLabel="Enforce company-wide two-factor authentication">
      <form onSubmit={onSubmit}>
        <ModalWizardLayout
          header={
            <div style={{ position: "relative" }}>
              <ModalHeader
                title={`${
                  mfaEnable ? "Enforce" : "Disable"
                } Two-Factor Authentication`}
                onClose={onClose}
              />

              <Overlay show={attemptCancel} />
            </div>
          }
          content={
            <div
              style={{
                position: "relative",
                display: "grid",
              }}
            >
              <WizardStepContainer>
                <ModalDescription>
                  {mfaEnable ? "Enforce" : "Disable"} two-factor authentication
                  for all users, company-wide
                </ModalDescription>

                {mfaEnable && forSignOutUsers && formState.isSubmitting ? (
                  <LargeDataNotice
                    style={{
                      display: "flex",
                      gap: DS.margins.nano,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Icon name="spinner" spin /> Logging everyone out. This may
                    take a few moments…
                  </LargeDataNotice>
                ) : mfaEnable ? (
                  <div
                    style={{
                      padding: "16px 64px",
                      display: "grid",
                      alignItems: "center",
                    }}
                  >
                    <Controller
                      control={control}
                      name="forceSignOutUsers"
                      render={({ field }) => (
                        <UnstyledList>
                          <li>
                            <WizardOption
                              ref={field.ref}
                              title="Log Everyone Out (Recommended)"
                              description="This will log everyone out, including you, and require them to log back in and set up 2FA."
                              image={<LogOut />}
                              type="radio"
                              checked={field.value === true}
                              onChange={() => field.onChange(true)}
                            />
                          </li>
                          <li>
                            <WizardOption
                              ref={field.ref}
                              title="Keep Everyone Logged In"
                              description="This will keep everyone logged in. They will be required to set up 2FA next time they log in."
                              image={<UserFriends />}
                              type="radio"
                              checked={field.value === false}
                              onChange={() => field.onChange(false)}
                            />
                          </li>
                        </UnstyledList>
                      )}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      padding: "16px 64px",
                      display: "grid",
                    }}
                  >
                    <div>
                      <Message type="notice">
                        <strong>Two-factor authentication</strong> adds an
                        additional layer of security by requiring more than just
                        a password to sign in. It is recommend that you keep 2FA
                        enforced company-wide.
                      </Message>
                      <SmallText style={{ marginTop: 4, textAlign: "center" }}>
                        This will not affect people who independently opted in
                        to 2FA
                      </SmallText>
                    </div>
                    <WizardOption
                      title="Yes, Disable 2FA Company-wide"
                      description="It is recomended that you keen 2FA enforcement on."
                      image={<LogOut />}
                      type="checkbox"
                      {...register("acceptDisable")}
                    />
                  </div>
                )}
              </WizardStepContainer>

              <Overlay show={attemptCancel} />
            </div>
          }
          footer={
            <ModalFooter>
              {attemptCancel ? (
                <>
                  {mfaEnable ? (
                    <>
                      <p>
                        You have not finished disabling two-factor
                        authentication. If you leave now,{" "}
                        <strong>2FA will remain on.</strong>
                      </p>

                      <p>Are you sure you want to leave now?</p>
                    </>
                  ) : (
                    <>
                      <p>
                        You have not finished enabling two-factor
                        authentication. If you leave now,{" "}
                        <strong>2FA will remain off.</strong>
                      </p>

                      <p>Are you sure you want to leave now?</p>
                    </>
                  )}

                  <ButtonGroup flow="horizontal">
                    <Button onClick={() => setAttemptCancel(false)}>
                      No, I&apos;m not finished.
                    </Button>
                    <Button buttonType="destructive" onClick={onClose}>
                      Yes, leave now.
                    </Button>
                  </ButtonGroup>
                </>
              ) : mfaEnable ? (
                <ButtonGroup flow="horizontal">
                  <Button onClick={() => setAttemptCancel(true)}>Close</Button>
                  <Button
                    type="submit"
                    buttonType="action"
                    stretch
                    disabled={formState.isSubmitting}
                  >
                    {formState.isSubmitting
                      ? "Enabling 2FA…"
                      : "Enable 2FA for company"}
                  </Button>
                </ButtonGroup>
              ) : (
                <ButtonGroup flow="horizontal">
                  <Button onClick={onClose}>Close</Button>
                  <Button
                    type="submit"
                    buttonType="destructive"
                    stretch
                    disabled={!acceptDisable || formState.isSubmitting}
                  >
                    {formState.isSubmitting
                      ? "Disabling 2FA…"
                      : "Disable 2FA for company"}
                  </Button>
                </ButtonGroup>
              )}
            </ModalFooter>
          }
        />
      </form>
    </Modal>
  );
};

export default EnableCompanyMfaModal;
