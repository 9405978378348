import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTheme } from "styled-components";

import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import { Overlay } from "@components/ModalWizardHelper";
import ModalWizardLayout from "@components/ModalWizardLayout";
import { useAcceptTos, useCurrentUser, useOnLogOutFn } from "@state/hooks";

const TermsOfService = () => {
  const { links } = useTheme();
  const { data: currentUser } = useCurrentUser();
  const declineToS = useOnLogOutFn();
  const acceptToS = useAcceptTos();

  const [isAccepted, setIsAccepted] = useState(false);
  const [attemptCancel, setAttemptCancel] = useState(false);

  const handleClose = useCallback(() => {
    void declineToS();
  }, [declineToS]);

  const handleCloseAttempt = useCallback(() => setAttemptCancel(true), []);

  const { handleSubmit, setError, ...methods } = useForm<Api.AcceptTos>({
    defaultValues: {},
  });

  const onSubmit = useCallback(
    (formEvent?: React.FormEvent) =>
      void handleSubmit(async () => {
        try {
          await acceptToS();
          setIsAccepted(true);
        } catch (error) {
          setError("root", {
            message:
              "We encountered an issue on our side. Please try again soon.",
          });
        }
      })(formEvent),
    [acceptToS, handleSubmit, setError],
  );

  return (
    <Modal
      contentLabel="Terms of Service"
      isOpen={!currentUser?.acceptedToS && !!currentUser && !isAccepted}
      onClose={handleCloseAttempt}
    >
      <FormProvider {...{ handleSubmit, setError, ...methods }}>
        <form onSubmit={onSubmit}>
          <ModalWizardLayout
            header={
              <div style={{ position: "relative" }}>
                <ModalHeader
                  title="Terms of Service"
                  onClose={handleCloseAttempt}
                />

                <Overlay show={attemptCancel} />
              </div>
            }
            content={
              <div style={{ position: "relative" }}>
                <iframe
                  title={links.termsOfService.label}
                  width="100%"
                  height="100%"
                  src={links.termsOfService.url}
                  style={{ border: 0 }}
                ></iframe>

                <Overlay show={attemptCancel} />
              </div>
            }
            footer={
              <ModalFooter>
                {attemptCancel ? (
                  <>
                    <p>If you dont accept the Terms, you will be logged out</p>
                    <p>Are you sure you want to log out?</p>

                    <ButtonGroup flow="horizontal">
                      <Button onClick={() => setAttemptCancel(false)}>
                        No, I&apos;m not finished.
                      </Button>
                      <Button buttonType="destructive" onClick={handleClose}>
                        Yes, log out now.
                      </Button>
                    </ButtonGroup>
                  </>
                ) : (
                  <ButtonGroup flow="horizontal">
                    <Button
                      onClick={handleCloseAttempt}
                      buttonType="destructive"
                    >
                      Decline
                    </Button>
                    <Button buttonType="action" type="submit">
                      Accept
                    </Button>
                  </ButtonGroup>
                )}
              </ModalFooter>
            }
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default TermsOfService;
