import { useFormContext } from "react-hook-form";

import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";

const SubmitDisableMfaFooter = ({ onCancel }: { onCancel: () => void }) => {
  const { formState } = useFormContext<Api.User>();

  return (
    <ButtonGroup flow="horizontal">
      <Button onClick={onCancel}>Close</Button>
      <Button
        type="submit"
        buttonType="destructive"
        disabled={formState.isSubmitting}
      >
        {formState.isSubmitting ? "Disabling…" : "Disable 2FA"}
      </Button>
    </ButtonGroup>
  );
};

export default SubmitDisableMfaFooter;
