import { FzfResultItem } from "fzf";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import { IIcon } from "../icons";

export type Sorting =
  | "all"
  | "creation-date"
  | "overdue"
  | "on-way"
  | "completed"
  | "unfinished-first";

export type Filtering = "all" | "hide" | "overdue" | "on-way" | "completed";

export type ShipmentFilter = {
  showShipments: Filtering;
};

export const getStatusValue = (status: Api.ShipmentStatus) => {
  switch (status) {
    // Notice - All states before shipment has been sent, or where there is
    // nothing the customer can do.
    case "Created":
    case "LabelPending":
    case "LabelCreated":
    case "Printed": // StarShipIt
    case "AwaitingCollection": // StarShipIt
    case "AwaitingDispatch": // Toll
    case "Sent":
    case "Dispatched": // StarShipIt
    case "InTransit": // StarShipIt
    case "OutForDelivery": // StarShipIt
      return 1;

    // Warning - All states where the customer needs to do something
    case "PickupInStore": // StarShipIt
    case "AttemptedDelivery": // StarShipIt
    case "Unpacking":
    case "Delivered": // StarShipIt
      return 1;

    // Positive - All sucessfully completed shipments
    case "Complete":
      return 2;

    // Danger - All states where action is urgently needed or shipment cancelled
    case "OverdueUnpacking":
    case "NeverCompleted":
    case "Cancelled":
    case "LabelCreationError":
    case "Exception": // StarShipIt
      return 1;

    // Neutral - Unknown states
    default:
      return 0;
  }
};

export const activeShipmentsOnly = (shipment: Api.Shipment) => {
  switch (shipment.Status) {
    case "Cancelled":
    case "LabelCreationError":
    case "Exception":
    case "NeverCompleted":
    case "Complete":
      return false;

    default:
      return true;
  }
};

export const getShipmentStatusIcon = (status: Api.ShipmentStatus): IIcon => {
  switch (status) {
    // Clipboard - Shipment is with us.
    case "Created":
    case "LabelPending":
    case "LabelCreated":
    case "Printed": // StarShipIt
    case "AwaitingCollection": // StarShipIt
    case "AwaitingDispatch": // Toll
      return "clipboard-list";

    // Truck - Shipment is en route
    case "Sent":
    case "Dispatched": // StarShipIt
    case "InTransit": // StarShipIt
    case "OutForDelivery": // StarShipIt
    case "PickupInStore": // StarShipIt
    case "AttemptedDelivery": // StarShipIt
      return "truck";

    // Box Open - Shipment is with the customer
    case "Unpacking":
    case "OverdueUnpacking":
    case "Delivered": // StarShipIt
      return "box-open";

    // Check - Shipment completed successfully
    case "Complete":
      return "check";

    // Time - Shipment cancelled
    case "NeverCompleted":
    case "Cancelled":
    case "LabelCreationError":
    case "Exception": // StarShipIt
      return "times";

    // Question - Unknown state
    default:
      return "question";
  }
};

export const useShipmentsFilter = (): [
  ShipmentFilter,
  (
    filter:
      | Partial<ShipmentFilter>
      | ((current: ShipmentFilter) => Partial<ShipmentFilter>),
  ) => void,
] => {
  const { search } = useLocation();

  const [filters, setFilters] = useState<ShipmentFilter>(() => {
    const query = new URLSearchParams(search);

    return {
      showShipments: query.get("showShipments") ?? "all",
    } as ShipmentFilter;
  });

  const setShipmentFilter = useCallback(
    (
      filter:
        | Partial<ShipmentFilter>
        | ((current: ShipmentFilter) => Partial<ShipmentFilter>),
    ) => {
      const newFilter = typeof filter === "function" ? filter(filters) : filter;
      setFilters((currentFilter) => ({
        ...currentFilter,
        ...newFilter,
      }));
    },
    [filters],
  );

  return [filters, setShipmentFilter];
};

export const sort_shipments = (by: Sorting) => {
  return (
    { item: a }: FzfResultItem<Api.Shipment>,
    { item: b }: FzfResultItem<Api.Shipment>,
  ) => {
    switch (by) {
      case "creation-date":
        return Date.parse(b.CreatedDate) - Date.parse(a.CreatedDate);

      case "unfinished-first":
        return getStatusValue(a.Status) - getStatusValue(b.Status);
    }

    return 0;
  };
};

export const filter_shipments = (by: Filtering) => {
  return ({ item: shipment }: FzfResultItem<Api.Shipment>) => {
    switch (by) {
      case "completed":
        return shipment.Status === "Complete";

      case "on-way":
        return (
          shipment.Status === "Created" ||
          shipment.Status === "LabelPending" ||
          shipment.Status === "LabelCreated" ||
          shipment.Status === "Printed" ||
          shipment.Status === "AwaitingCollection" ||
          shipment.Status === "AwaitingDispatch" ||
          shipment.Status === "Sent" ||
          shipment.Status === "Dispatched" ||
          shipment.Status === "InTransit" ||
          shipment.Status === "OutForDelivery"
        );
      case "overdue":
        return (
          shipment.Status === "OverdueUnpacking" ||
          shipment.Status === "NeverCompleted" ||
          shipment.Status === "Cancelled" ||
          shipment.Status === "LabelCreationError" ||
          shipment.Status === "Exception"
        );
      case "hide":
        return shipment.Status !== "Complete";

      default:
        return true;
    }
  };
};
