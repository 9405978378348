import {
  HTMLAttributes,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTheme } from "styled-components";
import { useDebouncedCallback } from "use-debounce";

import DS from "@design/system";
import { DateRange, fourteenDays, oneMonth } from "@util/DateRange";
import useDateRangeSelection from "@util/useDateRangeSelection";

import Chart, {
  Bucket,
  ChartSegments,
  ChartValues,
  SegmentDefBase,
} from "./Chart";
import DateRangePicker from "./DateRangePicker";

const LINE_GRAPH_HEIGHT = 24;

const Grip = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    const { palettes } = useTheme();

    return (
      <div
        ref={ref}
        style={{
          ...props.style,
          width: 1,
          height: "100%",
          background: palettes.body.border,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: 1,
            width: 8,
            height: 16,

            borderRadius: DS.radii.pill,
            border: `solid 1px ${palettes.well.small}`,
            background: palettes.well.background,

            transform: "translate(calc(-50% - 0.5px),-50%)",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: 2,
              width: 4,
              borderBottom: `solid 1px ${palettes.well.small}`,
              transform: "translateY(-2.5px)",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: 2,
              width: 4,
              borderBottom: `solid 1px ${palettes.well.small}`,
              transform: "translateY(-0.5px)",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: 2,
              width: 4,
              borderBottom: `solid 1px ${palettes.well.small}`,
              transform: "translateY(1.5px)",
            }}
          />
        </div>
        <div
          {...props}
          style={{
            position: "absolute",
            left: -8,
            width: 16,
            height: "100%",
          }}
        />
      </div>
    );
  },
);

Grip.displayName = "Grip";

const DateRangeGraph = <
  TDef extends SegmentDefBase,
  TSegments extends ChartSegments<TDef>,
  TValues extends ChartValues<TSegments>,
>({
  segments,
  data,
  emptyLabel,
  initialDateRange,
  initialSelectedDateRange,
  onDateRangeChange,
  onSelectedDateRangeChange,
}: {
  segments: TSegments;
  data: Bucket<TValues>[];
  emptyLabel?: string;
  initialDateRange?: DateRange;
  initialSelectedDateRange?: DateRange;
  onDateRangeChange?: (dateRange: DateRange) => void;
  onSelectedDateRangeChange?: (dateRange: DateRange) => void;
}) => {
  const { palettes } = useTheme();

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(
    initialSelectedDateRange ?? fourteenDays(),
  );

  const [dateRange, setDateRange] = useState<DateRange>(
    initialDateRange ?? oneMonth(),
  );

  useEffect(() => {
    if (initialDateRange) {
      setDateRange(initialDateRange);
    }
  }, [initialDateRange]);

  const handleSelectedDateRangeChange = useDebouncedCallback(
    (range: DateRange) => {
      setSelectedDateRange(range);
      onSelectedDateRangeChange?.(range);
    },
    250,
  );

  const handleSelectedDateRangeChangeLive = useCallback((range: DateRange) => {
    setSelectedDateRange(range);
  }, []);

  const {
    meta,
    size,
    monthSegments,
    containerProps,
    scrollWindowProps,
    leftGripProps,
    rightGripProps,
  } = useDateRangeSelection({
    dateRange,
    initialSelectedDateRange: selectedDateRange,
    sticky: true,
    onSelectedDateRangeChange: handleSelectedDateRangeChange,
    onSelectedDateRangeChangeLive: handleSelectedDateRangeChangeLive,
  });

  const lineGraph = useMemo(() => {
    if (!meta) return { width: 0, points: "" };

    const { dayCount, pixelsPerDay } = meta;

    const max = data.reduce((p, b) => Math.max(p, b.count), 1);
    const scale = (LINE_GRAPH_HEIGHT - 2) / max;

    const points = data
      .reverse()
      .map(
        ({ count }, i) =>
          `${i * pixelsPerDay},${LINE_GRAPH_HEIGHT - count * scale - 1}`,
      )
      .join(" ");

    return {
      width: dayCount * pixelsPerDay,
      points,
    };
  }, [data, meta]);

  const bucketsForChart = useMemo(
    () =>
      data.filter(
        (b) =>
          b.date.start.getTime() >= selectedDateRange.start.getTime() &&
          b.date.end.getTime() <= selectedDateRange.end.getTime(),
      ),
    [data, selectedDateRange.end, selectedDateRange.start],
  );

  return (
    <div
      style={{
        border: `solid 1px ${palettes.body.border}`,
        borderRadius: DS.radii.largeItem,

        display: "grid",
      }}
    >
      <div
        style={{
          padding: DS.margins.micro,
          display: "flex",
          gap: DS.margins.micro,
          justifyContent: "end",
        }}
      >
        <DateRangePicker
          initialDateRange={initialDateRange}
          onDateRangeChange={onDateRangeChange}
        />
      </div>
      <div style={{ overflow: "hidden", height: 80 }}>
        <Chart
          segments={segments}
          emptyLabel={emptyLabel}
          data={bucketsForChart}
          height={80}
        />
      </div>
      <div
        {...containerProps}
        style={{
          overflow: "visible",
          position: "relative",
          margin: "0 4px",
          userSelect: "none",
        }}
      >
        <div style={{ height: LINE_GRAPH_HEIGHT }}>
          <svg
            viewBox={`0 0 ${lineGraph.width} 24`}
            width={lineGraph.width}
            height={LINE_GRAPH_HEIGHT}
          >
            <defs>
              <clipPath id="selection">
                <rect
                  x={size.start}
                  y="0"
                  width={size.end - size.start}
                  height={LINE_GRAPH_HEIGHT}
                />
              </clipPath>
            </defs>

            <polyline
              points={lineGraph.points}
              fill="none"
              stroke={palettes.body.border}
              strokeWidth={1}
            />

            <polyline
              points={lineGraph.points}
              fill="none"
              stroke={palettes.body.accent}
              strokeWidth={2}
              clipPath="url(#selection)"
            />
          </svg>
          <div
            {...scrollWindowProps}
            style={{
              ...scrollWindowProps.style,
              cursor: "ew-resize",
            }}
          >
            <Grip {...leftGripProps} />
            <Grip {...rightGripProps} />
          </div>
        </div>
        <ul
          style={{
            pointerEvents: "none",
            position: "relative",
            overflow: "hidden",
            padding: 0,
            margin: 0,
            height: 16,
            listStyle: "none",
          }}
        >
          {monthSegments.map(({ x, width, label }, i) => (
            <li
              key={`${label}-${i}`}
              style={{
                boxSizing: "border-box",
                position: "absolute",
                width,
                paddingLeft: 4,
                paddingRight: 4,
                lineHeight: "16px",
                fontSize: 10,
                color: palettes.body.foreground,
                transform: `translateX(${x}px)`,
              }}
            >
              {label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DateRangeGraph;
