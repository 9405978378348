import { SidePanelTitle, SmallText } from "@design/helpers";
import DS from "@design/system";
import { TextLoader } from "@util/ContentLoader";

const SidePanelHeaderPlaceholder = () => (
  <div
    style={{
      padding: DS.margins.regularCss("tb"),
      margin: DS.margins.regularCss("rl"),
    }}
  >
    <SidePanelTitle style={{ lineHeight: "24px" }}>
      <TextLoader width={200} />
    </SidePanelTitle>
    <SmallText>
      <TextLoader width={150} />
    </SmallText>
  </div>
);

export default SidePanelHeaderPlaceholder;
