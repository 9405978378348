import { ColumnDef } from "@tanstack/react-table";
import { FzfResultItem } from "fzf";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useRouteMatch } from "react-router-dom";
import { useTheme } from "styled-components";

import Button from "@components/Button";
import ContextMenu, { ContextMenuItem } from "@components/ContextMenu";
import HighlightChars from "@components/HighlightChars";
import Icon from "@components/Icon";
import ListItem, { ListItemsPlaceholder } from "@components/ListItem";
import MediaCard, { MediaCardPlaceholder } from "@components/MediaCard";
import MultiSelect from "@components/MultiSelect";
import { Page } from "@components/Page";
import Pill from "@components/Pill";
import Scroller from "@components/Scroller";
import SearchBar from "@components/SearchBar";
import SecondaryButton from "@components/SecondaryButton";
import { Table } from "@components/Table";
import {
  LargeDataNotice,
  PanelTitle,
  SmallText,
  UnstyledList,
  Well,
} from "@design/helpers";
import DS from "@design/system";
import {
  useCompany,
  useCurrentUser,
  useGroup,
  useHasPermission,
  useIsHierarchicallySuperiorTo,
  usePageTitle,
  useRoles,
  useSearch,
  useStore,
  useStoresInGroup,
  useUsersIn,
  useUsersInStores,
} from "@state/hooks";
import { findInTree, flattenTree, removeDuplicates } from "@util/data";
import { DateFormat, dateFormatter } from "@util/dateFormat";
import useParamsUpper from "@util/useParamsUpper";
import { useThemeHelper } from "@util/useThemeHelper";

import AddUserWizardModal from "../modals/AddUserWizardModal";
import DeleteUserModal from "../modals/DeleteUserModal";
import EditUserModal from "../modals/EditUserModal";
import RemoveUserFromGroupModal from "../modals/RemoveUserFromGroupModal";

type Filterable = {
  id: string;
  name: string;
};

const People = () => {
  const { t } = useTranslation();
  const allStoresMatch = useRouteMatch(["/all-stores"]);
  const { storeId, groupId } = useParamsUpper<{
    storeId?: string;
    groupId?: string;
  }>();

  const { palettes: theme } = useTheme();
  const { rolePalette } = useThemeHelper();
  const { hasPermission } = useHasPermission();

  const { data: roles } = useRoles();
  const { data: store } = useStore(storeId);
  const { data: company } = useCompany();
  const { data: currentUser } = useCurrentUser();
  const theGroupId = groupId ?? store?.SingleStoreGroupID;

  const group = useGroup(theGroupId);
  const stores = useStoresInGroup(theGroupId, true);
  const usersIn = useUsersIn({
    storeId,
    groupId,
    allStores: !!allStoresMatch,
  });
  const allUsers = usersIn.data?.directOrLower;
  const inheritedUsers = usersIn.data?.inherited;

  const [roleFilter, setRoleFilter] = useState<Api.Role[]>([]);
  const [groupsFilter, setGroupsFilter] = useState<Filterable[]>([]);
  const [storesFilter, setStoresFilter] = useState<Filterable[]>([]);
  const [selectedUser, setSelectedUser] = useState<Api.User | null>();
  const [userToDelete, setUserToDelete] = useState<Api.User | null>();
  const [userToRemove, setUserToRemove] = useState<Api.User | null>();
  const [filterableStores, setFilterableStores] = useState<Filterable[]>([]);

  const usersInStores = useUsersInStores(
    useMemo(() => storesFilter.map((s) => s.id), [storesFilter]),
  );

  usePageTitle(
    `People ${
      store
        ? " - " + store.Name
        : group.data
          ? " - " + group.data.Name
          : company
            ? " - " + company.Name
            : ""
    }`,
  );

  const isRoot = useMemo(() => group.data?.Type === "Root", [group.data]);

  const hasFilter = useMemo<boolean>(
    () =>
      roleFilter.length > 0 ||
      groupsFilter.length > 0 ||
      storesFilter.length > 0,
    [groupsFilter.length, roleFilter.length, storesFilter.length],
  );

  const filterGroupsFn = useMemo(() => {
    if (!group.data) return () => true;

    const rootGroups =
      findInTree(
        [group.data],
        (group) => groupsFilter.some((g) => g.id === group.GroupId),
        (group) => group.Groups,
        true,
      ) ?? [];

    const childGroups = flattenTree(
      rootGroups,
      (group) => group.Groups,
      (group) => group.Groups,
      (group) => group.GroupId,
    );

    const groupsToSearch = [...rootGroups, ...childGroups];

    if (groupsToSearch.length > 0) {
      groupsToSearch.push(group.data);
    }

    return (user: Api.User) =>
      groupsToSearch.length === 0 ||
      user.Groups.some((usersGroup) =>
        groupsToSearch.some((g) => g.GroupId === usersGroup.GroupId),
      );
  }, [group.data, groupsFilter]);

  const filterStoresFn = useMemo(() => {
    if (!stores.data || storesFilter.length === 0 || !usersInStores.data)
      return () => true;

    const allowedUsers = removeDuplicates(
      storesFilter
        .map(
          (s) =>
            usersInStores.data.find((store) => store.storeId === s.id)?.users ??
            [],
        )
        .reduce<Api.User[]>((p, storeUsers) => p.concat(storeUsers ?? []), []),
      (user) => user.UserId,
    );

    return (user: Api.User) =>
      allowedUsers && allowedUsers.some((u) => u.UserId === user.UserId);
  }, [stores.data, storesFilter, usersInStores.data]);

  const {
    search,
    q,
    list: users,
    setList: setSearchableUsers,
    results: usersFiltered,
  } = useSearch<Api.User>([], (user) => user.FullName);

  const [showAddUserModal, closeAddUserModal] = useModal(
    () => (
      <AddUserWizardModal
        mutationKeys={{ groupId, storeId }}
        groupId={theGroupId}
        onClose={closeAddUserModal}
      />
    ),
    [groupId, storeId, theGroupId],
  );

  const [showEditUserModal, closeEditUserModal] = useModal(
    () => (
      <EditUserModal
        userId={selectedUser?.UserId ?? ""}
        title="Person's Details"
        mutationKeys={{ groupId, storeId }}
        onClose={() => {
          setSelectedUser(null);
          closeEditUserModal();
        }}
      />
    ),
    [groupId, selectedUser?.UserId, storeId],
  );

  const [showRemoveUserFromGroupModal, closeRemoveUserFromGroupModal] =
    useModal(() => {
      if (!group.data || !userToRemove) return null;

      return (
        <RemoveUserFromGroupModal
          user={userToRemove}
          group={group.data}
          store={store}
          onConfirm={closeRemoveUserFromGroupModal}
          onClose={closeRemoveUserFromGroupModal}
        />
      );
    }, [group.data, store, userToRemove]);

  const [showDeleteUserModal, closeDeleteUserModal] = useModal(() => {
    if (!userToDelete) return null;

    return (
      <DeleteUserModal
        user={userToDelete}
        mutatorKeys={{ storeId, groupId }}
        onConfirm={() => closeDeleteUserModal()}
        onClose={() => closeDeleteUserModal()}
      />
    );
  }, [groupId, storeId, userToDelete]);

  const handleSearchChange = useCallback((q: string) => search(q), [search]);

  const handleClearFilterClick = useCallback(() => {
    setRoleFilter([]);
    setGroupsFilter([]);
    setStoresFilter([]);
    search("");
  }, [search]);

  const handleAddUserClick = useCallback(() => {
    showAddUserModal();
  }, [showAddUserModal]);

  const handleUserClick = useCallback(
    (user: Api.User) => {
      setSelectedUser(user);
      showEditUserModal();
    },
    [showEditUserModal],
  );

  const handleRemoveUserFromGroup = useCallback(
    (user: Api.User) => {
      if (!user) return;

      setUserToRemove(user);
      showRemoveUserFromGroupModal();
    },
    [showRemoveUserFromGroupModal],
  );

  const handleDeleteUser = useCallback(
    (user: Api.User) => {
      if (!user) return;

      setUserToDelete(user);
      showDeleteUserModal();
    },
    [showDeleteUserModal],
  );

  const filterRoles = useCallback(
    (user: Api.User) => {
      return (
        roleFilter.length === 0 ||
        roleFilter.some((role) => role.Name === user.Roles[0].Name)
      );
    },
    [roleFilter],
  );

  useEffect(() => {
    if (!stores.data) {
      setFilterableStores([]);
      return;
    }

    setFilterableStores(
      stores.data.map((store) => ({
        id: store.StoreId,
        name: store.Name,
      })),
    );
  }, [stores.data]);

  useEffect(() => {
    setSearchableUsers(
      allUsers
        ?.filter(filterRoles)
        .filter(filterGroupsFn)
        .filter(filterStoresFn),
    );
  }, [
    allUsers,
    filterGroupsFn,
    filterRoles,
    filterStoresFn,
    setSearchableUsers,
  ]);

  const data = useMemo(
    () =>
      usersFiltered?.sort(
        (a, b) => a.item.FirstName.localeCompare(b.item.FirstName) ?? [],
      ),
    [usersFiltered],
  );
  const { isHierarchicallySuperiorTo } = useIsHierarchicallySuperiorTo();

  const columns = useMemo<ColumnDef<FzfResultItem<Api.User>>[]>(
    () => [
      {
        id: "FullName",
        header: "Name",
        cell: ({ row: { original: user } }) =>
          user && (
            <ListItem
              compact={true}
              description={user.item.Email}
              title={
                <HighlightChars
                  str={user.item.FullName}
                  indices={user.positions}
                />
              }
            />
          ),
      },
      {
        id: "Role",
        header: "Role",
        cell: ({
          row: {
            original: { item: user },
          },
        }) =>
          user && (
            <UnstyledList
              style={{
                overflow: "hidden",
                maxWidth: "100%",
                display: "flex",
                whiteSpace: "nowrap",
              }}
            >
              <Pill
                type="default"
                style={rolePalette(user.Roles[0].Name).toCss()}
              >
                {user.Roles[0].DisplayName}
              </Pill>
            </UnstyledList>
          ),
      },
      {
        id: "Groups",
        header: "Groups",
        cell: ({
          row: {
            original: { item: user },
          },
        }) =>
          user && (
            <UnstyledList
              style={{
                overflow: "hidden",
                maxWidth: "100%",
                display: "flex",
                whiteSpace: "nowrap",
              }}
            >
              {user.Groups?.map((group) => (
                <Pill as="li" key={group.GroupId} type="default">
                  {group.Name}
                </Pill>
              ))}
            </UnstyledList>
          ),
      },
      {
        id: "LoggedIn",
        header: "Last Logged In",
        cell: ({
          row: {
            original: { item: user },
          },
        }) => (
          <SmallText style={{ color: theme.body.small }}>
            {user && user.LastLoggedInDate ? (
              <span>
                {t("people.lastLoggedIn")}{" "}
                {dateFormatter(
                  { start: new Date(user.LastLoggedInDate), end: new Date() },
                  DateFormat.relative,
                )}
              </span>
            ) : (
              <span>{t("people.neverLoggedIn")}</span>
            )}
          </SmallText>
        ),
      },
      {
        id: "contextButton",
        header: "",
        cell: ({
          row: {
            original: { item: user },
          },
        }) => {
          const canRemoveFromGroup = user.Groups.some(
            (group) => group.GroupId === theGroupId,
          );
          const removeFromLabel = storeId
            ? "Remove from store"
            : "Remove from group";
          return (
            user &&
            currentUser &&
            hasPermission("users_create_edit_delete_user") &&
            isHierarchicallySuperiorTo(user) && (
              <ContextMenu
                label="Person options"
                placement="right-start"
                items={[
                  ...(canRemoveFromGroup && removeFromLabel
                    ? [
                        {
                          key: "remove-user",
                          icon: "user-minus",
                          label: removeFromLabel,
                          onClick: (close) => {
                            handleRemoveUserFromGroup(user);
                            close();
                          },
                        } as ContextMenuItem,
                      ]
                    : []),
                  ...(canRemoveFromGroup &&
                  removeFromLabel &&
                  user.UserId !== currentUser.userId
                    ? [null]
                    : []),
                  ...(user.UserId !== currentUser.userId
                    ? [
                        {
                          key: "delete-user",
                          icon: "trash-alt",
                          label: "Delete person",
                          onClick: (close) => {
                            handleDeleteUser(user);
                            close();
                          },
                        } as ContextMenuItem,
                      ]
                    : []),
                ]}
              />
            )
          );
        },
      },
    ],
    [
      currentUser,
      handleDeleteUser,
      handleRemoveUserFromGroup,
      hasPermission,
      isHierarchicallySuperiorTo,
      rolePalette,
      storeId,
      t,
      theGroupId,
      theme.body.small,
    ],
  );

  return (
    <Page showSidebar={!isRoot}>
      <div
        style={{
          display: "grid",
          overflow: "hidden",
          gridTemplateRows: "auto 1fr",
        }}
      >
        <div
          style={{
            padding: DS.margins.regularCss("trl"),
            marginBottom: DS.margins.micro,
          }}
        >
          <div
            style={{
              padding: DS.margins.micro,
              background: theme.well.background,
              borderRadius: DS.radii.largeItem,

              display: "grid",
              gridAutoFlow: "column",
              gap: DS.margins.regular,
              justifyContent: "flex-start",
              gridTemplateColumns: "1fr auto",
            }}
          >
            <SearchBar
              placeholder={t("people.search") ?? ""}
              value={q}
              onSearch={handleSearchChange}
            />

            <MultiSelect
              label="Filter by role"
              searchPlaceHolder="Type role to narrow down options"
              items={roles ?? []}
              value={roleFilter}
              getKey={(item) => item.id}
              getLabel={(item) => item.DisplayName}
              onItemAdded={(role) =>
                setRoleFilter((previous) => [...previous, role])
              }
              onItemRemoved={(role) =>
                setRoleFilter((previous) =>
                  previous.filter((r) => r.Name !== role.Name),
                )
              }
              itemRenderer={(item) => item.DisplayName}
            />

            {groupId && (
              <MultiSelect
                label={`Filter by ${t("term.store_one").toLowerCase()}`}
                items={filterableStores}
                value={storesFilter}
                isSearchEnabled={true}
                searchPlaceHolder={`Type ${t(
                  "term.store_one",
                ).toLowerCase()} name to narrow down options`}
                getKey={(store) => store.id}
                getLabel={(store) => store.name}
                onItemAdded={(store) => {
                  setStoresFilter((previous) => [...previous, store]);
                }}
                onItemRemoved={(store) => {
                  setStoresFilter((previous) =>
                    previous.filter((g) => g.id !== store.id),
                  );
                }}
                itemRenderer={(store) => store.name}
              />
            )}

            {hasPermission("users_create_edit_delete_user") && (
              <Button
                title={`Add new or existing people to ${
                  group.data?.Name ?? ""
                }`}
                onClick={handleAddUserClick}
              >
                <Icon name="user-plus" /> Add people…
              </Button>
            )}
          </div>
          {hasFilter && (
            <Well
              style={{
                marginTop: DS.margins.micro,
                textAlign: "center",
                background: theme.messages.notice.background,
              }}
            >
              <SmallText style={{ color: theme.messages.notice.foreground }}>
                <strong>
                  {t("people.filter", {
                    count: (allUsers?.length ?? 0) - (users?.length ?? 0),
                  })}
                </strong>{" "}
                hidden with the current filter.{" "}
                <SecondaryButton inline onClick={handleClearFilterClick}>
                  Clear the filters
                </SecondaryButton>{" "}
                to show all people.
              </SmallText>
            </Well>
          )}

          {!(group.isLoading || usersIn.isLoading) &&
            users &&
            users.length === 0 && (
              <LargeDataNotice>
                There are no people assigned <strong>directly</strong> to this{" "}
                {storeId ? "store" : "group"}.
              </LargeDataNotice>
            )}

          {q && (!usersFiltered || !usersFiltered.length) && (
            <LargeDataNotice>
              No people matching <strong>{q}</strong> found.
            </LargeDataNotice>
          )}
        </div>

        <Scroller>
          <div style={{ padding: DS.margins.regularCss("rbl") }}>
            {!usersIn.isLoading && data ? (
              <Table
                data={data}
                columns={columns}
                onRowClick={(row) => handleUserClick(row.original.item)}
                aria-label="People list"
              />
            ) : usersIn.isLoading ? (
              <ListItemsPlaceholder />
            ) : null}
          </div>
        </Scroller>
      </div>

      {!isRoot && (
        <div
          style={{
            overflow: "hidden",
            borderLeft: `solid 1px ${theme.body.border}`,
            display: "grid",
            alignContent: "start",
          }}
        >
          <div
            style={{
              padding: DS.margins.regular,
            }}
          >
            <PanelTitle>Other people with access</PanelTitle>
            <SmallText>
              These people have inherited access to{" "}
              <strong>{group.data?.Name}</strong> by belonging to one or more
              groups higher up.
            </SmallText>
          </div>

          <Scroller>
            <UnstyledList
              style={{
                padding: DS.margins.regularCss("rbl"),
              }}
            >
              {usersIn.isLoading && (
                <li
                  style={{
                    padding: DS.margins.regular,
                    border: "solid 1px",
                    borderColor: theme.body.border,
                    borderRadius: DS.radii.largeItem,
                  }}
                >
                  <MediaCardPlaceholder imageSize={64} showDescriptionExt />
                </li>
              )}
              {inheritedUsers?.map((user) => (
                <li
                  key={user.UserId}
                  style={{
                    padding: DS.margins.regular,
                    border: "solid 1px",
                    borderColor: theme.body.border,
                    borderRadius: DS.radii.largeItem,
                  }}
                >
                  <MediaCard
                    title={user.FullName}
                    description={user.Email}
                    descriptionExt={user.Roles[0].DisplayName}
                  />
                </li>
              ))}
            </UnstyledList>
          </Scroller>
        </div>
      )}
    </Page>
  );
};

export default People;
