import { useFormContext } from "react-hook-form";

import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";
import { ConfirmReplacementForm } from "@util/viewModel";

const SpecifyReasonStepFooter = () => {
  const { goTo, previous } = useWizardContext();
  const { formState, watch } = useFormContext<ConfirmReplacementForm>();

  const reason = watch("reason");

  return (
    <ButtonGroup flow="horizontal">
      <Button disabled={formState.isSubmitting} onClick={previous}>
        <Icon name="arrow-alt-left" /> Back, to reason
      </Button>
      <Button
        buttonType="progressive"
        disabled={!reason}
        onClick={() => goTo("decline", true)}
      >
        Next, confirm <Icon name="arrow-alt-right" />
      </Button>
    </ButtonGroup>
  );
};

export default SpecifyReasonStepFooter;
