const mem = {} as { [key: string]: string };

const memStorage = {
  getItem: (key: string) => mem[key],
  setItem: (key: string, value: string) => {
    mem[key] = value;
  },
  removeItem: (key: string) => {
    delete mem[key];
  },
};

let lsProx;

try {
  lsProx = window.localStorage;
} catch (e) {
  lsProx = memStorage;
}

export const ls = lsProx;
