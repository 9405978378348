import styled from "styled-components";

import DS from "@design/system";

const DetailHeader = styled.header`
  padding: 0 ${DS.margins.regular};

  border-bottom: solid 1px ${({ theme }) => theme.palettes.body.border};
`;

const DetailTitle = styled.div`
  display: grid;
  gap: ${DS.margins.large};
  grid-template-columns: 1fr auto;
  align-items: flex-start;
`;

const DetailContent = styled.section``;

export { DetailHeader, DetailTitle, DetailContent };
