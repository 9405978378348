import { useFormContext } from "react-hook-form";
import { useTheme } from "styled-components";

import { WizardStepContainer } from "@components/ModalWizardHelper";
import { ModalDescription } from "@design/helpers";
import DS from "@design/system";
import { ConfirmReplacementForm, replacementReasons } from "@util/viewModel";

const DeclineReplacementStep = () => {
  const { palettes } = useTheme();

  const { watch } = useFormContext<ConfirmReplacementForm>();

  const [selectedReasons, reason] = watch(["selectedReasons", "reason"]);

  return (
    <WizardStepContainer>
      <ModalDescription style={{ padding: "0 64px" }}>
        Check that the following accurately describes why a replacement is not
        needed.
      </ModalDescription>
      <div
        style={{
          padding: "0 64px",
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <blockquote
          style={{
            margin: 0,
            paddingLeft: DS.margins.regular,
            color: palettes.body.small,
            fontSize: 20,
            borderLeft: "solid 2px",
            borderColor: palettes.body.border,
          }}
        >
          {replacementReasons(selectedReasons, reason)}
        </blockquote>
      </div>
    </WizardStepContainer>
  );
};

export default DeclineReplacementStep;
