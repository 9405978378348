import { useMemo, useState } from "react";
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import { useTheme } from "styled-components";

import {
  MediumText,
  SmallText,
  StrongText,
  UnstyledList,
  Well,
} from "@design/helpers";
import DS from "@design/system";
import { useHasPermission, useStores } from "@state/hooks";
import { DateFormat } from "@util/dateFormat";
import {
  filter_eventsWithItemsPutBack,
  filter_eventsWithItemsRemoved,
  filter_eventsWithPhotos,
  reduce_aggregateItemsWithDeltas,
} from "@util/incidents";

import Carousel from "./Carousel";
import GroupHeader from "./GroupHeader";
import { Wrapped } from "./Layout";
import ListItem from "./ListItem";
import QuantityContainer from "./QuantityContainer";
import StoreTime from "./StoreTime";

export const IncidentDetailsHeader = ({
  incident,
}: {
  incident: Api.Incident;
}) => {
  const { palettes } = useTheme();
  const { hasPermission } = useHasPermission();

  const { data: stores } = useStores();

  const [selectedPhoto, setSelectedPhoto] = useState<string>();

  const store = useMemo(
    () =>
      stores
        ? stores.find(
            (store) => store.Name === incident.primaryEvent?.storeName,
          )
        : null,
    [incident, stores],
  );

  const doorPhotos = useMemo(() => {
    if (!incident.associatedEvents) return [incident.photoUrl];

    const photos = incident.associatedEvents
      .filter(filter_eventsWithPhotos())
      .map((event) => event.photoUrl)
      .filter((photoUrl) => !!photoUrl);

    return photos.length === 0
      ? incident.photoUrl
        ? [incident.photoUrl]
        : []
      : photos;
  }, [incident]);

  const [showLightbox, closeLightbox] = useModal(
    () => (
      <ReactModal
        isOpen={true}
        onRequestClose={closeLightbox}
        style={{
          overlay: {
            zIndex: 1,
            background: "rgba(0, 0, 0, 0.25)",
          },
          content: {
            padding: 0,
            border: 0,
            borderRadius: 0,
            background: "transparent",
            pointerEvents: "none",
            display: "grid",
            alignItems: "center",
            justifyItems: "center",
          },
        }}
      >
        <div style={{ pointerEvents: "all" }}>
          <img
            style={{ display: "block" }}
            width={544}
            height={416}
            src={selectedPhoto}
            alt=""
            draggable={false}
          />
        </div>
      </ReactModal>
    ),
    [selectedPhoto],
  );

  return (
    <>
      {hasPermission("events_view_door_photos") && doorPhotos.length > 0 && (
        <div
          style={{
            boxSizing: "border-box",
            width: 320,
            height: 200,
            margin: DS.margins.microCss("tb"),
          }}
        >
          <Carousel
            dots="image"
            items={doorPhotos}
            onClick={(doorPhoto) => {
              setSelectedPhoto(doorPhoto);
              return showLightbox();
            }}
          >
            {(doorPhoto) => (
              <img
                style={{
                  display: "block",
                  cursor: "zoom-in",
                  margin: DS.margins.regularCss("rl"),
                  background: palettes.well.background,
                  borderRadius: DS.radii.item,
                }}
                width={288}
                height={200}
                src={doorPhoto}
                alt=""
                draggable={false}
              />
            )}
          </Carousel>
        </div>
      )}

      <div
        style={{
          overflow: "hidden",
          padding: `${DS.margins.micro} ${DS.margins.regular}`,
        }}
      >
        {store && (
          <Well>
            <StrongText>{incident.primaryEvent?.unitName}</StrongText>
            <MediumText style={{ color: palettes.well.small }}>
              {store.Name}
            </MediumText>
            <SmallText>
              <Wrapped>{store.StreetAddress.AsOneLine}</Wrapped>
            </SmallText>
          </Well>
        )}
      </div>
    </>
  );
};

export const IncidentDetails = ({ incident }: { incident: Api.Incident }) => {
  const itemsTaken = useMemo(
    () =>
      incident.associatedEvents
        ?.filter(filter_eventsWithItemsRemoved())
        .reduce(reduce_aggregateItemsWithDeltas, [])
        .sort(
          (a, b) =>
            new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime(),
        ) ?? [],
    [incident.associatedEvents],
  );

  const itemsPutBack = useMemo(
    () =>
      incident.associatedEvents
        ?.filter(filter_eventsWithItemsPutBack())
        .reduce(reduce_aggregateItemsWithDeltas, [])
        .sort(
          (a, b) =>
            new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime(),
        ) ?? [],
    [incident.associatedEvents],
  );

  return (
    <div
      style={{
        padding: DS.margins.regularCss("rbl"),
        display: "grid",
        gap: DS.margins.micro,
      }}
    >
      <UnstyledList>
        {!!itemsTaken.length && incident && (
          <div>
            <GroupHeader>Items Taken</GroupHeader>
            {itemsTaken.map((item) => (
              <li key={item.eventId}>
                <div
                  style={{
                    padding: DS.margins.microCss("rl"),
                    display: "grid",
                    gridAutoFlow: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ListItem
                    image={item.photoUrl}
                    title={item.productName}
                    description={
                      <StoreTime
                        storeName={item.storeName}
                        dateTime={item.eventDate}
                        format={DateFormat.dateTimeAndTimeZone}
                      />
                    }
                  />
                  {item.count > 0 && (
                    <QuantityContainer>{`x${item.count}`}</QuantityContainer>
                  )}
                </div>
              </li>
            ))}
          </div>
        )}

        {!!itemsPutBack.length && incident && (
          <div>
            <GroupHeader>Items Returned to Cabinet </GroupHeader>
            {itemsPutBack.map((item) => (
              <li key={item.eventId}>
                <div
                  style={{
                    padding: DS.margins.microCss("rl"),
                    display: "grid",
                    gridAutoFlow: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ListItem
                    image={item.photoUrl}
                    title={item.productName}
                    description={
                      <StoreTime
                        storeName={item.storeName}
                        dateTime={item.eventDate}
                        format={DateFormat.dateTimeAndTimeZone}
                      />
                    }
                  />
                  {item.count > 0 && (
                    <QuantityContainer>{`x${item.count}`}</QuantityContainer>
                  )}
                </div>
              </li>
            ))}
          </div>
        )}
      </UnstyledList>
    </div>
  );
};
