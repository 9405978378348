import styled from "styled-components";

import { SmallGroupHeader } from "@design/helpers";
import DS from "@design/system";
import { TextLoader } from "@util/ContentLoader";

import { IconName } from "./Icon";
import IconButton from "./IconButton";

const ControlsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: ${DS.margins.regular};
`;

export interface GroupHeaderControl {
  icon?: IconName;
  title?: string;
  element?: React.ReactElement;
  onClick?: (e: React.MouseEvent) => void;
}

export interface GroupHeaderProps {
  children: React.ReactNode;

  controls?: GroupHeaderControl[];
  separator?: boolean;

  /**
   * Some situations call for a tighter fit
   */
  tight?: boolean;
}

export const GroupHeaderPlaceholder = ({
  separator = true,
}: {
  separator?: boolean;
}) => {
  return (
    <SmallGroupHeader
      style={{
        position: "relative",
        ...(!separator && { border: 0 }),

        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",
      }}
    >
      <TextLoader width={100} />
    </SmallGroupHeader>
  );
};

const GroupHeader = ({
  controls,
  separator = true,
  tight = false,
  children,
}: GroupHeaderProps) => (
  <SmallGroupHeader
    style={{
      position: "relative",
      ...(!separator && { border: 0 }),
      ...(tight && { margin: 0 }),

      display: "grid",
      gridTemplateColumns: "1fr auto",
      alignItems: "center",
    }}
  >
    {children}

    {controls && (
      <ControlsWrapper>
        {controls.map(({ element, icon, title, onClick }, i) => (
          <div key={`control-${i}`}>
            {element ? (
              element
            ) : icon && title ? (
              <IconButton
                icon={icon}
                title={title}
                buttonType="transparent"
                onClick={onClick}
              />
            ) : null}
          </div>
        ))}
      </ControlsWrapper>
    )}
  </SmallGroupHeader>
);

export default GroupHeader;
