import styled from "styled-components";

import DS from "@design/system";

const NoResults = styled.div`
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: ${DS.margins.micro};

  color: ${({ theme }) => theme.palettes.body.small};
  text-align: center;
`;

export default NoResults;
