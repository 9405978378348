import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import { SmallText } from "@design/helpers";
import DS from "@design/system";
import { Theme } from "@themes/types";

const Bar = styled.div<{ barColor: string; lit: boolean }>`
  height: 4px;

  background: ${({ theme, barColor, lit }) =>
    lit ? barColor : theme.palettes.body.dim};
  border-radius: 2px;
`;

const getColor = ({ palettes }: Theme, score: number) => {
  switch (score) {
    default:
    case -1:
      return palettes.states.na.background;
    case 0:
      return palettes.states.bad.background;
    case 1:
      return palettes.states.good.background;
  }
};

const matchLabel = (score: number) => {
  switch (score) {
    case -1:
      return <span>&nbsp;</span>;
    case 0:
      return "Must match previous entry";
    case 1:
      return <span>&nbsp;</span>;
  }
};

const PasswordMatchIndicator = ({
  password,
  confirmPassword,
}: {
  password?: string;
  confirmPassword?: string;
}) => {
  const theme = useTheme();

  const [score, setScore] = useState<number>(-1);
  const [color, setColor] = useState<string>(getColor(theme, score));

  useEffect(() => {
    if (!confirmPassword) {
      setScore(-1);
      setColor(getColor(theme, -1));
      return;
    }
    if (confirmPassword === password) {
      setScore(1);
      setColor(getColor(theme, 1));
      return;
    }
    setScore(0);
    setColor(getColor(theme, 0));
  }, [confirmPassword, password, theme]);

  return (
    <>
      <div role="presentation">
        <div
          style={{
            marginTop: 4,
          }}
        >
          <Bar barColor={color} lit={score >= 0} />
        </div>
        <div
          style={{
            display: "grid",
            gridAutoFlow: "column",
            justifyContent: "space-between",
          }}
        >
          <SmallText style={{ padding: DS.margins.nano, color }}>
            {matchLabel(score)}
          </SmallText>
        </div>
      </div>
    </>
  );
};

export default PasswordMatchIndicator;
