import { useTheme } from "styled-components";

import Icon from "@components/Icon";
import MediaCard from "@components/MediaCard";
import { IconWell, SmallText } from "@design/helpers";
import DS from "@design/system";
import { useUnit } from "@state/hooks";
import { EscalationProps } from "@util/viewModel";

const Replenishment = ({
  escalation,
}: EscalationProps<Api.ReplenishmentEscalation>) => {
  const { palettes } = useTheme();

  const { data: unit } = useUnit(escalation.ControllerSerialNumber);

  return (
    <div
      style={{
        overflow: "hidden",
        padding: `${DS.margins.micro} ${DS.margins.regular}`,
        display: "grid",
        gap: DS.margins.regular,
      }}
    >
      <div
        style={{
          display: "grid",
          gap: DS.margins.micro,
          gridTemplateColumns: "auto 1fr",
        }}
      >
        <Icon
          name="truck"
          size={50}
          color={palettes.states.warning.background}
        />
        <MediaCard title="New Stock Arrived" description={unit?.UnitName} />
      </div>
      <IconWell>
        <Icon
          name="exclamation-alt-circle"
          color={palettes.messages.info.background}
        />
        <SmallText>
          A shipment has arrived with new stock and is awaiting replacement.
          Please re-stock this first aid cabinet as soon as possible.
        </SmallText>
      </IconWell>
    </div>
  );
};

export default Replenishment;
