import styled from "styled-components";

const SidePanelContainer = styled.section`
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;

  background: ${({ theme }) => theme.palettes.body.background};

  display: grid;
  grid-template-rows: auto 1fr auto;
`;

export default SidePanelContainer;
