import { useCallback, useContext, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Message from "@components/Message";
import { UnstyledList } from "@design/helpers";
import { SelectedGroupContext } from "@util/viewModel";

import {
  useCurrentUser,
  useGroup,
  useRoles,
  useSearch,
  useUsers,
  useUsersInGroupFlat,
} from "../../state/hooks";
import HighlightChars from "../HighlightChars";
import ListItem from "../ListItem";
import NoResults from "../NoResults";
import SearchBar from "../SearchBar";
import Selectable, { SelectablePlaceholder } from "../Selectable";

const ControlsContainer = styled.div`
  display: grid;
  gap: 8px;
  align-content: flex-start;
`;

const SelectUsersTab = ({ loading = false }: { loading?: boolean }) => {
  const { t } = useTranslation();

  const { data: currentUser } = useCurrentUser();
  const { groupId } = useContext(SelectedGroupContext);

  const { data: group } = useGroup(groupId);
  const { data: allUsers, isLoading: isAllUsersLoading } = useUsers();
  const { data: peopleInGroup } = useUsersInGroupFlat(groupId);
  const { data: roles } = useRoles();

  const lsoRole = useMemo(
    () => roles?.find((r) => r.Name === "Local Safety Officer"),
    [roles],
  );

  const {
    results: usersFiltered,
    search,
    q,
    setList: setUsersList,
  } = useSearch([] as Api.User[], (user) => user.FullName);

  const { control } = useFormContext<{
    userIds: string[];
    groupsIds: string[];
  }>();

  const filterFn = useCallback(
    (person: Api.User) => {
      if (!currentUser) return false;

      // Not the logged in user
      if (person.UserId === currentUser.userId) return false;

      // Not in the list of people already in the group
      if (peopleInGroup?.find((u) => u.UserId === person.UserId)) return false;

      // If we have a group, and it's not a SingleStore, then don't let LSOs in.
      if (
        groupId &&
        group &&
        group.Type !== "SingleStore" &&
        person.Roles &&
        person.Roles.find((role) => role.Name === "Local Safety Officer")
      )
        return false;

      return true;
    },
    [currentUser, peopleInGroup, groupId, group],
  );

  useEffect(() => {
    if (!allUsers) {
      setUsersList([]);
      return;
    }

    setUsersList(
      allUsers
        .filter(filterFn)
        .sort((a, b) => a.LastName.localeCompare(b.LastName)),
    );
  }, [allUsers, filterFn, setUsersList]);

  return (
    <ControlsContainer>
      <SearchBar
        placeholder="Search for existing people…"
        value={q}
        onSearch={search}
      />

      {group && group.Type !== "SingleStore" && (
        <Message type="info">
          People with a role of <strong>{lsoRole?.DisplayName}</strong> must be
          assigned directly to {t("term.store_one").toLowerCase()}, so have been
          removed from this list.
        </Message>
      )}

      {loading || isAllUsersLoading || !allUsers ? (
        <>
          <SelectablePlaceholder />
          <SelectablePlaceholder />
          <SelectablePlaceholder />
        </>
      ) : usersFiltered && usersFiltered.length > 0 ? (
        <Controller
          control={control}
          name="userIds"
          render={({ field }) => (
            <>
              <UnstyledList>
                {usersFiltered.map(({ item: user, positions }) => (
                  <li key={user.UserId}>
                    <Selectable
                      {...field}
                      label={user.FullName}
                      value={user.UserId}
                      selected={
                        !!field.value.find((userId) => userId === user.UserId)
                      }
                      onChange={(e) => {
                        field.onChange(
                          e.target.checked
                            ? field.value.concat(user.UserId)
                            : field.value.filter(
                                (userId) => userId !== user.UserId,
                              ),
                        );
                      }}
                    >
                      <ListItem
                        title={
                          <HighlightChars
                            str={user.FullName}
                            indices={positions}
                          />
                        }
                        description={user.Roles[0].DisplayName}
                      />
                    </Selectable>
                  </li>
                ))}
              </UnstyledList>
            </>
          )}
        />
      ) : (
        <NoResults>
          <p>
            No people matching <strong>{q}</strong> found. Create a new person
            to continue.
          </p>
        </NoResults>
      )}
    </ControlsContainer>
  );
};

export default SelectUsersTab;
