import { cfa, cfaDark } from "./cfa";
import { cintas } from "./cintas";

export const themes = {
  cfa,
  cfaDark,
  cintas,
};

export type NamedTheme = keyof typeof themes;
