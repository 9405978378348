import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";

const StartStepMfaFooter = ({ onCancel }: { onCancel: () => void }) => {
  const { next } = useWizardContext();

  return (
    <ButtonGroup flow="horizontal">
      <Button onClick={onCancel}>Close</Button>
      <Button buttonType="progressive" onClick={next}>
        Next <Icon name="arrow-alt-right" />
      </Button>
    </ButtonGroup>
  );
};

export default StartStepMfaFooter;
