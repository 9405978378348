import { isWithinInterval } from "date-fns";
import { RenderProps as CalendarProps } from "dayzed";
import { Fragment, useMemo } from "react";
import { useTheme } from "styled-components";
import { v4 as uuidv4 } from "uuid";

import DS from "@design/system";
import { DateRange } from "@util/DateRange";
import { dateRangeCorners, renderBorderRadius } from "@util/dates";

import Button from "./Button";
import Icon from "./Icon";

// FIXME: Anyone know a better way to get internationalised day names?
// March 1st, 2020 is a Sunday. February 2020 was a leap year. Start on 29th Feb
// so that we can +1 in our map to get the first day.
const d = new Date("2020-02-29");
const DAYS = Array.from({ length: 7 }).map(() => {
  d.setDate(d.getDate() + 1);
  return {
    narrow: d.toLocaleString("default", { weekday: "narrow" }),
    long: d.toLocaleString("default", { weekday: "long" }),
  };
});

const m = new Date();
const MONTHS = Array.from({ length: 12 }).map((_, i) => {
  m.setMonth(i);
  return m.toLocaleString("default", { month: "long" });
});

const Calendar = ({
  control: { calendars, getBackProps, getForwardProps, getDateProps },
  selectedDateRange,
  onTodayClick,
}: {
  control: CalendarProps;
  selectedDateRange?: DateRange;
  onTodayClick?: () => void;
}) => {
  const id = useMemo(() => uuidv4(), []);
  const { palettes } = useTheme();

  return (
    <div
      style={{
        display: "grid",
        gridAutoFlow: "column",
        gap: "8px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {calendars.map((calendar) => (
        <div
          key={`${calendar.year}${calendar.month}`}
          style={{
            display: "grid",
            gap: DS.margins.nano,
          }}
        >
          <div style={{ display: "flex" }}>
            <h2
              id={`calendar-label-${id}`}
              style={{ margin: 0, flexGrow: 1, fontSize: 18, fontWeight: 600 }}
              aria-live="polite"
            >
              {MONTHS[calendar.month]} {calendar.year}
            </h2>
            <div
              style={{
                color: palettes.well.small,
                display: "flex",
              }}
            >
              <Button
                fit="compact"
                type="button"
                buttonType="transparent"
                {...getBackProps({ calendars })}
                aria-label="Previous month"
              >
                <Icon name="angle-left" />
              </Button>
              <Button
                fit="compact"
                type="button"
                buttonType="transparent"
                onClick={onTodayClick}
                aria-label="Current month"
              >
                <Icon name="circle-solid" size={8} />
              </Button>
              <Button
                fit="compact"
                buttonType="transparent"
                {...getForwardProps({
                  calendars,
                })}
                aria-label="Next month"
              >
                <Icon name="angle-right" />
              </Button>
            </div>
          </div>

          <div
            style={{
              paddingBottom: 8,
              borderBottom: `solid 1px ${palettes.body.border}`,
              display: "grid",
              gridTemplateColumns: "repeat(7, 30px)",
              justifyItems: "center",
            }}
            role="grid"
            aria-labelledby={`calendar-label-${id}`}
          >
            {DAYS.map((day, i) => (
              <div
                key={`${calendar.year}${calendar.month}${i}`}
                style={{
                  color: palettes.body.small,
                  fontSize: 13,
                  fontWeight: 700,
                }}
                title={day.long}
              >
                {day.narrow}
              </div>
            ))}
          </div>

          <div
            style={{
              color: palettes.well.foreground,
            }}
          >
            {calendar.weeks.map((week, weekIndex, weekArr) => (
              <div
                key={`${calendar.year}${calendar.month}${weekIndex}`}
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(7, 30px)",
                }}
              >
                {week.map((d, dateIndex) => {
                  return (
                    <Fragment
                      key={`${calendar.year}${calendar.month}${weekIndex}${dateIndex}`}
                    >
                      {d ? (
                        <Button
                          fit="compact"
                          buttonType="transparent"
                          tabIndex={d.today ? 0 : -1}
                          {...getDateProps({ dateObj: d })}
                          style={{
                            borderRadius:
                              selectedDateRange &&
                              isWithinInterval(d.date, selectedDateRange)
                                ? renderBorderRadius(
                                    dateRangeCorners(
                                      d.date,
                                      selectedDateRange,
                                      dateIndex,
                                      weekIndex,
                                      weekArr.length,
                                    ),
                                    DS.radii.itemN,
                                  )
                                : DS.radii.itemN,
                            fontStyle: "normal",
                            fontWeight: d.selected
                              ? 600
                              : d.prevMonth || d.nextMonth
                                ? 300
                                : 400,
                            color: d.selected
                              ? palettes.buttons.neutralSelected.foreground
                              : d.today
                                ? palettes.buttons.neutralSelected.background
                                : d.prevMonth || d.nextMonth || !d.selectable
                                  ? palettes.well.small
                                  : selectedDateRange &&
                                      isWithinInterval(
                                        d.date,
                                        selectedDateRange,
                                      )
                                    ? "inherit"
                                    : "inherit",
                            background: d.selected
                              ? palettes.buttons.neutralSelected.background
                              : selectedDateRange &&
                                  isWithinInterval(d.date, selectedDateRange)
                                ? palettes.itemSelected.background
                                : "",
                            border: d.today
                              ? `solid 1px ${palettes.buttons.neutralSelected.border}`
                              : "",
                            outline: 0,
                          }}
                          aria-selected={d.today}
                          aria-pressed={d.today}
                        >
                          {d.date.getDate()}
                        </Button>
                      ) : (
                        <div />
                      )}
                    </Fragment>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Calendar;
