import { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { useWizardContext } from "@components/ModalWizardHelper";
import { numberToWord } from "@lang/lang";

import Button from "../Button";
import Icon from "../Icon";

const getMessage = (count: number) => {
  if (count === 0) return "No people selected.";
  if (count === 1) return "One person selected.";
  if (count > 1) return `${numberToWord(count, true)} people selected.`;
};

const SelectUsersTabFooter = () => {
  const { control } = useFormContext<{ userIds: string[] }>();
  const { goTo } = useWizardContext();

  const userIds = useWatch({ control, name: "userIds" });

  const handleNextClick = useCallback(() => {
    userIds.length ? goTo("addToGroupReview", true) : goTo("newUser", true);
  }, [goTo, userIds.length]);

  return (
    <>
      <p>{getMessage(userIds.length ?? 0)}</p>
      <Button buttonType="progressive" onClick={handleNextClick}>
        {userIds.length ? (
          <>
            Next, review and confirm <Icon name="arrow-alt-right" />
          </>
        ) : (
          <>
            Or, add a new person <Icon name="arrow-alt-right" />
          </>
        )}
      </Button>
    </>
  );
};

export default SelectUsersTabFooter;
