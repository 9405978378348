import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  FormColumn,
  FormRow,
  LabelledDatePicker,
  LabelledInput,
  LabelledSelect,
  LabelledTextArea,
} from "@components/FormControls";
import { useHasPermission, useUsersInStore } from "@state/hooks";

const DetailsTab = ({ loading }: { loading: boolean }) => {
  const { hasPermission } = useHasPermission();
  const { t } = useTranslation();

  const { register, formState, watch } = useFormContext<Api.Unit>();

  const [storeId, type] = watch(["StoreId", "UnitType"]);

  const { data: users, isLoading: isUsersLoading } = useUsersInStore(storeId);

  const editable = useMemo(
    () => hasPermission("units_edit_unit"),
    [hasPermission],
  );

  const availableUsers = useMemo(() => {
    return [
      {
        value: "",
        label: `-- Use ${t("term.store_one").toLowerCase()} contact --`,
      },
      ...(users?.map((user) => ({
        value: user.UserId,
        label: user.FullName,
      })) ?? []),
    ];
  }, [t, users]);

  return (
    <FormColumn>
      <LabelledInput
        label={"Name"}
        disabled={!editable}
        hasError={!!formState.errors.UnitName}
        error={formState.errors?.UnitName?.message}
        loading={loading}
        {...register("UnitName", { required: true })}
      />
      <LabelledTextArea
        label="Description"
        disabled={!editable}
        hasError={!!formState.errors.Description}
        error={formState.errors?.Description?.message}
        loading={loading}
        {...register("Description")}
      />
      <LabelledInput
        label={`Compliance Label`}
        disabled={true}
        hasError={false}
        loading={loading}
        {...register("ComplianceLabel")}
      />
      <LabelledSelect
        label="Contact"
        disabled={!editable}
        defaultValue=""
        options={availableUsers}
        hasError={!!formState.errors.ContactUserId}
        error={formState.errors?.ContactUserId?.message}
        loading={loading || isUsersLoading}
        {...register("ContactUserId")}
      />

      {type === "AutomatedExternalDefibrillator" && (
        <>
          <FormRow>
            <LabelledInput
              label="Battery serial number"
              disabled={!editable}
              hasError={false}
              loading={loading}
              {...register("AedStockDetails.BatterySerialNumber")}
            />
            <LabelledDatePicker
              label="Battery expiry"
              disabled={!editable}
              hasError={false}
              loading={loading}
              {...register("AedStockDetails.BatteryExpiryDate")}
            />
          </FormRow>

          <FormRow>
            <LabelledInput
              label="Pads serial number"
              disabled={!editable}
              hasError={false}
              loading={loading}
              {...register("AedStockDetails.PadsSerialNumber")}
            />
            <LabelledDatePicker
              label="Pads expiry"
              disabled={!editable}
              hasError={false}
              loading={loading}
              {...register("AedStockDetails.PadsExpiryDate")}
            />
          </FormRow>
        </>
      )}
    </FormColumn>
  );
};

export default DetailsTab;
