import { ApiResponseError } from "@api";

import ErrorWell, { AutoErrorWell } from "../ErrorWell";

const EditUserFailedMessage = ({
  response,
}: {
  response?: ApiResponseError<Api.ValidationError> | null;
}) => {
  if (!response || !response.error) return null;

  const { error } = response;

  if (error.hasError("duplicated_email")) {
    return (
      <>
        <ErrorWell>
          <strong>Email address already in use</strong>
          <br />
          An account with this email address already exists. Account emails must
          be unique.
        </ErrorWell>
      </>
    );
  }

  if (error.hasError("not_sufficient_permissions")) {
    return (
      <>
        <ErrorWell>
          <strong>Insufficent permission</strong>
          <br />
          You do not have permission to change these settings. Please contact
          your supervisor or admin or contact{" "}
          <a href="mailto:support@cleverfirstaid.com">support</a> to have these
          setting changed.
        </ErrorWell>
      </>
    );
  }

  if (error.hasError("passwords_not_matching")) {
    return (
      <>
        <ErrorWell>
          <strong>Passwords do not match</strong>
          <br />
          Your new password and confirm passwords do not match. Please ensure
          you enter your password correctly.
        </ErrorWell>
      </>
    );
  }

  return <AutoErrorWell response={response} />;
};

export default EditUserFailedMessage;
