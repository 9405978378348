import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";

import { ApiResponseError, isApiResponseError } from "@api";
import Button from "@components/Button";
import ErrorWell, { AutoErrorWell } from "@components/ErrorWell";
import { LabelledPasswordInput } from "@components/FormControls";
import { LinkButton } from "@components/SecondaryButton";
import { LargeBrandTitle, SmallText, Well } from "@design/helpers";
import DS from "@design/system";
import { usePageTitle } from "@state/hooks";

import PasswordMatchIndicator from "./PasswordMatchIndicator";
import PasswordStrengthIndicator from "./PasswordStrengthIndicator";
import { useAuth } from "./auth";

interface SetPasswordParams {
  email: string;
  password: string;
  confirmPassword: string;
  resetKey: string;
}

const ResetPassword = ({
  onBackToLoginClick,
}: {
  onBackToLoginClick: (e: React.MouseEvent) => void;
}) => {
  const { email: emailEncoded, resetKey } = useParams<{
    email: string;
    resetKey: string;
  }>();

  usePageTitle(`Set Password`);

  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorResponse, setErrorResponse] =
    useState<ApiResponseError<Api.UserValidationError> | null>(null);

  const email = decodeURIComponent(emailEncoded);

  const {
    control,
    register,
    formState: { errors, isSubmitting },
    setValue,
    handleSubmit,
  } = useForm<SetPasswordParams>({
    defaultValues: { email, resetKey },
  });

  register("resetKey");

  const [firstPassword, resetPassword] = useWatch({
    control,
    name: ["password", "confirmPassword"],
  });

  const passwordsDoNotMatch = useMemo(
    () => !firstPassword || firstPassword !== resetPassword,
    [firstPassword, resetPassword],
  );

  const onSubmit: React.FormEventHandler = useCallback(
    (e) =>
      void handleSubmit(
        async ({ email, password, resetKey }: SetPasswordParams) => {
          setErrorMessage(null);
          setErrorResponse(null);

          try {
            await auth.submitNewPassword(email, password, resetKey);
          } catch (e) {
            setErrorMessage("Something went wrong. Please try again.");

            if (!isApiResponseError<Api.UserValidationError>(e)) return;

            const { error } = e;

            if (error.isServerError) {
              setErrorMessage(
                "We encountered an issue on our side. Please try again soon.",
              );
            } else if (
              error.hasError([
                "invalid_reset_password_url",
                "invalid_reset_password_token",
              ])
            ) {
              setErrorMessage(
                "The reset password link used has expired. Please request a new link via Forgot Password.",
              );
            } else if (error.hasError(["password_exposed", "password_weak"])) {
              setErrorMessage(
                "The supplied password is either too weak or has been previously compromised. Please use a more complex password.",
              );
            } else {
              setErrorResponse(e);
            }
          }
        },
      )(e),
    [auth, handleSubmit],
  );

  useEffect(() => setValue("email", email), [email, setValue]);
  useEffect(() => setValue("resetKey", resetKey), [resetKey, setValue]);

  return (
    <form style={{ height: "100%" }} action="#" onSubmit={onSubmit}>
      <div
        style={{
          height: "100%",
          display: "grid",
          gap: DS.margins.regular,
          alignContent: "center",
        }}
      >
        <LargeBrandTitle>Set Password</LargeBrandTitle>

        {errorMessage && <ErrorWell>{errorMessage}</ErrorWell>}
        {errorResponse && <AutoErrorWell response={errorResponse} />}
        {!errorMessage && !errorResponse && (
          <Well>
            <SmallText>
              Please ensure you enter a strong password for your account{" "}
              <strong>{email}</strong>.
            </SmallText>
          </Well>
        )}

        <input type="hidden" autoComplete="username" {...register("email")} />

        <div>
          <LabelledPasswordInput
            label="Password"
            autoComplete="new-password"
            hasError={!!errors.password}
            readOnly={isSubmitting}
            {...register("password")}
          />
          <PasswordStrengthIndicator password={firstPassword} />
        </div>
        <div>
          <LabelledPasswordInput
            label="Confirm Password"
            autoComplete="new-password"
            readOnly={isSubmitting}
            {...register("confirmPassword")}
          />
          <PasswordMatchIndicator
            password={firstPassword}
            confirmPassword={resetPassword}
          />
        </div>
        <div
          style={{
            textAlign: "center",
            display: "grid",
            gap: DS.margins.micro,
          }}
        >
          <Button
            buttonType="action"
            type="submit"
            disabled={passwordsDoNotMatch || isSubmitting}
          >
            {isSubmitting ? "Setting password..." : "Set password"}
          </Button>
          <LinkButton onClick={onBackToLoginClick}>Back to log in</LinkButton>
        </div>
      </div>
    </form>
  );
};

export default ResetPassword;
