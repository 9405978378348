import { useGroup, useStore } from "@state/hooks";

const StoreAddress = ({
  storeId,
  groupId,
}: {
  storeId?: string;
  groupId?: string;
}) => {
  const { data: group } = useGroup(groupId);
  const { data: store } = useStore(
    storeId ??
      (group?.Type === "SingleStore" ? group?.Stores[0]?.StoreId : undefined),
  );

  return <>{store ? store.StreetAddress.AsOneLine : "…"}</>;
};

export default StoreAddress;
