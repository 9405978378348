import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useModal } from "react-modal-hook";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { SessionState, useAuth } from "@auth/auth";
import ConfirmAedInspectionWizardModal from "@modals/ConfirmAedInspectionWizardModal";
import ConfirmAedReplenishedWizardModal from "@modals/ConfirmAedReplenishedWizardModal";
import ConfirmReplacementWizard from "@modals/ConfirmReplacementWizard";
import EditUnitModal from "@modals/EditUnitModal";
import EditUserModal from "@modals/EditUserModal";
import ProposeDeviceLocationModal from "@modals/ProposeDeviceLocationModal";
import ResolveReplacementModal from "@modals/ResolveReplacementsModal";
import ScheduleInspectionModal from "@modals/ScheduleInspectionModal";
import { useCurrentUser } from "@state/hooks";

type ModalKey =
  | "crash-test"
  | "confirm-inspection"
  | "confirm-replenishment"
  | "confirm-replenished"
  | "edit-profile"
  | "edit-device"
  | "propose-device-location"
  | "schedule-inspection"
  | "resolve-item-missing";

type InspectionResultParams = {
  controllerSerialNumber: string;
};

type ReplenishmentResultParams = {
  controllerSerialNumber: string;
  replacementsNeeded?: boolean;
};

type ReplenishedResultParams = {
  controllerSerialNumber: string;
  shipmentId: string;
};

type EditProfileParams = {
  tab: Parameters<typeof EditUserModal>[0]["defaultTab"];
};

type EditUnitParams = {
  controllerSerialNumber: string;
  tab: Parameters<typeof EditUnitModal>[0]["defaultTab"];
};

type ProposeDeviceLocationParams = {
  controllerSerialNumber: string;
  userId: string;
  description: string;
  latitude: number;
  longitude: number;
};

type ScheduleInspectionParams = {
  controllerSerialNumber: string;
};

type ResolveReplacementParams = {
  controllerSerialNumber: string;
  sku: string;
  uid: string;
};

const useIncoming = () => {
  const history = useHistory();
  const { search } = useLocation();
  const processed = useRef(false);
  const route = useRouteMatch<{ modal: ModalKey }>("/incoming/:modal");

  const { session } = useAuth();
  const { data: currentUser } = useCurrentUser();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const [confirmInspectionParams, setConfirmInspectionParams] =
    useState<InspectionResultParams>();

  const [confirmReplenishmentParams, setConfirmReplenishmentParams] =
    useState<ReplenishmentResultParams>();

  const [confirmReplenishedParams, setConfirmReplenishedParams] =
    useState<ReplenishedResultParams>();

  const [editProfileParams, setEditProfileParams] =
    useState<EditProfileParams>();

  const [editUnitParams, setEditUnitParams] = useState<EditUnitParams>();

  const [proposeDeviceLocationParams, setProposeDeviceLocationParams] =
    useState<ProposeDeviceLocationParams>();

  const [scheduleInspectionParams, setScheduleInspectionParams] =
    useState<ScheduleInspectionParams>();

  const [resolveReplacementParams, setResolveReplacementParams] =
    useState<ResolveReplacementParams>();

  const handleClose = useCallback(
    (close: () => void) => () => {
      history.replace("/");
      close();
    },
    [history],
  );

  const [showConfirmInspectionModal, closeConfirmInspectionModal] = useModal(
    () => (
      <ConfirmAedInspectionWizardModal
        controllerSerialNumber={
          confirmInspectionParams?.controllerSerialNumber ?? ""
        }
        onClose={handleClose(closeConfirmInspectionModal)}
      />
    ),
    [confirmInspectionParams?.controllerSerialNumber, handleClose],
  );

  const [showConfirmReplenishmentModal, closeConfirmReplenishmentModal] =
    useModal(
      () => (
        <ConfirmReplacementWizard
          controllerSerialNumber={
            confirmReplenishmentParams?.controllerSerialNumber ?? ""
          }
          replacementNeeded={confirmReplenishmentParams?.replacementsNeeded}
          onClose={handleClose(closeConfirmReplenishmentModal)}
        />
      ),
      [
        confirmReplenishmentParams?.controllerSerialNumber,
        confirmReplenishmentParams?.replacementsNeeded,
        handleClose,
      ],
    );

  const [showConfirmReplenishedModal, closeConfirmReplenishedModal] = useModal(
    () => (
      <ConfirmAedReplenishedWizardModal
        controllerSerialNumber={
          confirmReplenishedParams?.controllerSerialNumber ?? ""
        }
        shipmentId={confirmReplenishedParams?.shipmentId ?? ""}
        onClose={handleClose(closeConfirmReplenishedModal)}
      />
    ),
    [
      confirmReplenishedParams?.controllerSerialNumber,
      confirmReplenishedParams?.shipmentId,
      handleClose,
    ],
  );

  const [showEditProfileModal, closeEditProfileModal] = useModal(
    () => (
      <EditUserModal
        userId={currentUser?.userId ?? ""}
        title="Edit Profile"
        defaultTab={editProfileParams?.tab ?? "general"}
        onClose={handleClose(closeEditProfileModal)}
      />
    ),
    [currentUser?.userId, editProfileParams?.tab, handleClose],
  );

  const [showEditUnitModal, closeEditUnitModal] = useModal(
    () => (
      <EditUnitModal
        unitId={editUnitParams?.controllerSerialNumber ?? ""}
        defaultTab={editUnitParams?.tab ?? "details"}
        onClose={handleClose(closeEditUnitModal)}
      />
    ),
    [editUnitParams?.controllerSerialNumber, editUnitParams?.tab, handleClose],
  );

  const [showProposeDeviceLocationModal, closeProposeDeviceLocationModal] =
    useModal(
      () => (
        <ProposeDeviceLocationModal
          controllerSerialNumber={
            proposeDeviceLocationParams?.controllerSerialNumber ?? ""
          }
          userId={proposeDeviceLocationParams?.userId ?? ""}
          description={proposeDeviceLocationParams?.description ?? ""}
          latitude={proposeDeviceLocationParams?.latitude ?? NaN}
          longitude={proposeDeviceLocationParams?.longitude ?? NaN}
          onClose={handleClose(closeProposeDeviceLocationModal)}
        />
      ),
      [
        handleClose,
        proposeDeviceLocationParams?.controllerSerialNumber,
        proposeDeviceLocationParams?.description,
        proposeDeviceLocationParams?.latitude,
        proposeDeviceLocationParams?.longitude,
        proposeDeviceLocationParams?.userId,
      ],
    );

  const [showScheduleInspectionModal, closeScheduleInspectionModal] = useModal(
    () => (
      <ScheduleInspectionModal
        controllerSerialNumber={
          scheduleInspectionParams?.controllerSerialNumber ?? ""
        }
        onClose={handleClose(closeScheduleInspectionModal)}
      />
    ),
    [handleClose, scheduleInspectionParams?.controllerSerialNumber],
  );

  const [showResolveReplacementModal, closeResolveReplacementModal] = useModal(
    () => (
      <ResolveReplacementModal
        controllerSerialNumber={
          resolveReplacementParams?.controllerSerialNumber ?? ""
        }
        sku={resolveReplacementParams?.sku ?? ""}
        onClose={handleClose(closeResolveReplacementModal)}
        uid={resolveReplacementParams?.uid ?? ""}
      />
    ),
    [
      handleClose,
      resolveReplacementParams?.controllerSerialNumber,
      resolveReplacementParams?.sku,
      resolveReplacementParams?.uid,
    ],
  );

  useEffect(() => {
    if (
      session.state === SessionState.Authenticated &&
      !processed.current &&
      route
    ) {
      const { modal } = route.params;
      processed.current = true;

      switch (modal) {
        case "crash-test":
          throw new Error("Crash test");

        case "confirm-inspection":
          setConfirmInspectionParams({
            controllerSerialNumber: query.get("controllerSerialNumber") ?? "",
          });
          showConfirmInspectionModal();
          break;

        case "confirm-replenishment":
          setConfirmReplenishmentParams({
            controllerSerialNumber: query.get("controllerSerialNumber") ?? "",
            replacementsNeeded:
              query.get("replacementsNeeded") === "1"
                ? true
                : query.get("replacementsNeeded") === "0"
                  ? false
                  : undefined,
          });
          showConfirmReplenishmentModal();
          break;

        case "confirm-replenished":
          setConfirmReplenishedParams({
            controllerSerialNumber: query.get("controllerSerialNumber") ?? "",
            shipmentId: query.get("shipmentId") ?? "",
          });
          showConfirmReplenishedModal();
          break;

        case "edit-profile":
          setEditProfileParams({
            tab: (query.get("tab") ?? "general") as EditProfileParams["tab"],
          });
          showEditProfileModal();
          break;

        case "edit-device":
          setEditUnitParams({
            controllerSerialNumber: query.get("controllerSerialNumber") ?? "",
            tab: (query.get("tab") ?? "general") as EditUnitParams["tab"],
          });
          showEditUnitModal();
          break;

        case "propose-device-location":
          setProposeDeviceLocationParams({
            controllerSerialNumber: query.get("controllerSerialNumber") ?? "",
            userId: query.get("userId") ?? "",
            description: query.get("description") ?? "",
            latitude: Number(query.get("latitude") ?? undefined),
            longitude: Number(query.get("longitude") ?? undefined),
          });
          showProposeDeviceLocationModal();
          break;

        case "schedule-inspection":
          setScheduleInspectionParams({
            controllerSerialNumber: query.get("controllerSerialNumber") ?? "",
          });
          showScheduleInspectionModal();
          break;

        case "resolve-item-missing":
          setResolveReplacementParams({
            controllerSerialNumber: query.get("controllerSerialNumber") ?? "",
            sku: query.get("sku") ?? "",
            uid: query.get("uid") ?? "",
          });
          showResolveReplacementModal();
          break;
      }
    } else if (session.state !== SessionState.Authenticated) {
      closeConfirmInspectionModal();
    }
  }, [
    closeConfirmInspectionModal,
    query,
    route,
    session,
    showConfirmInspectionModal,
    showConfirmReplenishedModal,
    showConfirmReplenishmentModal,
    showEditProfileModal,
    showEditUnitModal,
    showProposeDeviceLocationModal,
    showScheduleInspectionModal,
    showResolveReplacementModal,
  ]);
};

export default useIncoming;
