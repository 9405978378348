import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTheme } from "styled-components";

import Button from "@components/Button";
import DateRangeGraph from "@components/DateRangeGraph";
import ErrorWell from "@components/ErrorWell";
import Icon from "@components/Icon";
import { IconWithToolTip } from "@components/IconWithToolTip";
import {
  IncidentDetails,
  IncidentDetailsHeader,
} from "@components/IncidentDetails";
import ListItem, { ListItemPlaceholder } from "@components/ListItem";
import Message from "@components/Message";
import {
  BarVerticalMetric,
  DonutMetric,
  MetricsRow,
  NumberMetric,
} from "@components/Metric";
import { Page } from "@components/Page";
import Scroller from "@components/Scroller";
import SecondaryButton from "@components/SecondaryButton";
import SidePanelHeader from "@components/SidePanelHeader";
import StoreTime from "@components/StoreTime";
import { Table } from "@components/Table";
import { SmallText, Well } from "@design/helpers";
import DS from "@design/system";
import {
  useCompany,
  useGroup,
  useIncidentStats,
  usePageTitle,
  usePaginatedIncidents,
  useStore,
} from "@state/hooks";
import { DateRange } from "@util/DateRange";
import { DateFormat, dateFormatter } from "@util/dateFormat";
import {
  bucketIncidentStats,
  filter_eventsWithItemsRemoved,
  IncidentRow,
  useIncidentsFilter,
} from "@util/incidents";
import useParamsUpper from "@util/useParamsUpper";
import { useTerminologies } from "@util/useTerminologies";
import { useThemeHelper } from "@util/useThemeHelper";

const columns = createColumnHelper<IncidentRow>();

const Incidents = () => {
  const { t } = useTranslation();
  const { palettes } = useTheme();
  const { eventCategoryIcon, severityPalette } = useThemeHelper();
  const { categoryLabel, getTitle } = useTerminologies();
  const history = useHistory();
  const { storeId, groupId } = useParamsUpper<{
    storeId?: string;
    groupId?: string;
  }>();
  const allStoresMatch = useRouteMatch(["/all-stores"]);

  const { ref, inView } = useInView();
  const { data: store } = useStore(storeId);
  const { data: group } = useGroup(groupId);
  const { data: company } = useCompany();

  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      history.replace({
        pathname: location.pathname,
      });
    }

    initialized.current = true;
  }, [history]);

  usePageTitle(
    `Incidents ${
      store
        ? " - " + store.Name
        : group
          ? " - " + group.Name
          : company
            ? " - " + company.Name
            : ""
    }`,
  );

  const defaultColumns = [
    columns.display({
      id: "incident",

      cell: ({
        row: {
          original: { incident },
        },
      }) => (
        <ListItem
          image={
            <IconWithToolTip
              icon={eventCategoryIcon(incident.category)}
              color={
                severityPalette(
                  incident.incidentOverallSeverity ?? incident.severityLevel,
                ).background
              }
              title={incident.description}
              iconSize={24}
            />
          }
          compact
          title={getTitle(incident)}
          description={
            <StoreTime
              storeName={incident.primaryEvent?.storeName ?? ""}
              dateTime={incident.date}
              format={DateFormat.dateTimeAndTimeZone}
            />
          }
        />
      ),
    }),
    columns.display({
      id: "store-name",
      header: t("term.store_one") ?? "",

      cell: ({
        row: {
          original: { store },
        },
      }) => (
        <div
          style={{
            height: "100%",
            color: palettes.body.small,
            display: "grid",
            alignItems: "center",
          }}
        >
          {store}
        </div>
      ),
    }),
    columns.display({
      id: "device-name",
      header: t("term.unit_one") ?? "",

      cell: ({
        row: {
          original: { incident },
        },
      }) => (
        <div
          style={{
            height: "100%",
            color: palettes.body.small,
            display: "grid",
            alignItems: "center",
          }}
        >
          {incident.primaryEvent?.unitName}
        </div>
      ),
    }),
    columns.display({
      id: "products",
      meta: { width: "1fr" },

      cell: ({
        row: {
          original: { products },
        },
      }) => (
        <div
          style={{
            display: "grid",
            gridAutoFlow: "column",
            gap: DS.margins.micro,
            justifyContent: "end",
          }}
        >
          {products.filter(filter_eventsWithItemsRemoved()).map((event) => (
            <div
              key={event.eventId}
              style={{
                borderRadius: 4,
                overflow: "hidden",
              }}
            >
              <img
                style={{ display: "block" }}
                src={event.photoUrl}
                alt={event.productName}
                title={event.productName}
                width={32}
                height={32}
              />
            </div>
          ))}
        </div>
      ),
    }),
  ];

  const [
    { severity, category, dateRange: filteredDateRange },
    setIncidentsFilter,
  ] = useIncidentsFilter();

  const [dateRange, setDateRange] = useState<DateRange>(filteredDateRange);

  const { data: incidentStats } = useIncidentStats({
    storeId,
    groupId,
    allStores: !!allStoresMatch,
    startDate: dateRange.start.toISOString(),
    endDate: dateRange.end.toISOString(),
  });

  const {
    data: filteredIncidentStats,
    isLoading: isFilteredIncidentStatsLoading,
    isError: isFilteredIncidentStatsError,
  } = useIncidentStats({
    storeId,
    groupId,
    startDate: dateRange.start.toISOString(),
    endDate: dateRange.end.toISOString(),
  });

  const {
    data: incidents,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    isError,
  } = usePaginatedIncidents({
    storeId,
    groupId,
    startDate: dateRange.start.toISOString(),
    endDate: dateRange.end.toISOString(),
  });

  const [selectedIncident, setSelectedIncident] = useState<Api.Incident>();
  const [incidentsFiltered, setIncidentsFiltered] = useState<Api.Incident[]>();

  const hasFilter = useMemo(
    () => severity.length > 0 || category.length > 0,
    [category.length, severity.length],
  );

  const data = useMemo(
    () =>
      incidentsFiltered?.map(
        (incident) =>
          ({
            incident,
            store: incident.primaryEvent?.storeName,
            products: incident.associatedEvents,
          }) as IncidentRow,
      ) ?? [],
    [incidentsFiltered],
  );

  const allIncidents = useMemo(
    () =>
      incidents?.pages.flatMap(({ SuccessPayload }) => SuccessPayload ?? []) ||
      [],
    [incidents?.pages],
  );

  const buckets = useMemo(
    () => bucketIncidentStats(dateRange, incidentStats),
    [dateRange, incidentStats],
  );

  const handleClearFilterClick = useCallback(() => {
    setIncidentsFilter({
      severity: [],
      category: [],
    });

    history.replace("./incidents");
  }, [history, setIncidentsFilter]);

  const handleIncidentClick = useCallback(
    (incident: Api.Incident) => setSelectedIncident(incident),
    [],
  );

  const handleLoadMore = useCallback(() => {
    if (!hasNextPage) return;

    void fetchNextPage();
  }, [fetchNextPage, hasNextPage]);

  const handleDateRangeChange = useCallback(
    (dateRange: DateRange) => setDateRange(dateRange),

    [],
  );

  const handleSelectedDateRangeChange = useCallback(
    (dateRange: DateRange) => {
      setIncidentsFilter({ dateRange });
    },
    [setIncidentsFilter],
  );

  const handleCloseClick = useCallback(
    () => setSelectedIncident(undefined),
    [],
  );

  const handleSeverityClick = useCallback(
    (severity: Api.EventSeverityLevel) =>
      setIncidentsFilter((filter) =>
        filter.severity.includes(severity)
          ? {
              severity: filter.severity.filter((s) => s !== severity),
            }
          : {
              severity: [...filter.severity, severity],
            },
      ),

    [setIncidentsFilter],
  );

  const incidentsByCategory = useMemo(() => {
    return (
      ["Cuts", "Burns", "Eyes", "STF", "Cleaning", "Safety"] as const
    ).map((id) => {
      const count =
        filteredIncidentStats?.incidentsByCategory.find(
          (c) => c.category === id,
        )?.count ?? 0;

      return {
        id,
        title: `${categoryLabel(id)} (${count})`,
        count,
        icon: eventCategoryIcon(id),
      };
    });
  }, [
    eventCategoryIcon,
    categoryLabel,
    filteredIncidentStats?.incidentsByCategory,
  ]);

  useEffect(() => {
    if (inView) void fetchNextPage();
  }, [fetchNextPage, inView]);

  useEffect(() => {
    setIncidentsFiltered(allIncidents);
  }, [allIncidents]);

  useEffect(() => {
    if (!incidents) {
      setIncidentsFiltered([]);
      return;
    }

    setIncidentsFiltered(
      allIncidents
        .filter(
          (incident) =>
            severity.length === 0 ||
            severity.includes(
              incident.incidentOverallSeverity ?? incident.severityLevel,
            ),
        )
        .filter(
          (incident) =>
            category.length === 0 ||
            category.includes(incident.category ?? "Uncategorized"),
        ),
    );
  }, [category, severity, incidents, allIncidents]);

  useEffect(() => {
    setSelectedIncident(undefined);
  }, [groupId, storeId]);

  return (
    <Page showSidebar={!!selectedIncident}>
      <div
        style={{
          display: "grid",
          overflow: "hidden",
          gridTemplateRows: "auto 1fr",
        }}
      >
        <div
          style={{
            padding: DS.margins.regularCss("rl"),
            margin: DS.margins.microCss("tb"),
          }}
        >
          <div>
            <DateRangeGraph
              segments={[
                {
                  label: t("severity.low"),
                  color: severityPalette("Low").background,
                },
                {
                  label: t("severity.medium"),
                  color: severityPalette("Medium").background,
                },
                {
                  label: t("severity.high"),
                  color: severityPalette("High").background,
                },
              ]}
              emptyLabel={`No ${t("term.incident", { count: 0 })}`}
              data={buckets}
              initialDateRange={dateRange}
              initialSelectedDateRange={filteredDateRange}
              onDateRangeChange={handleDateRangeChange}
              onSelectedDateRangeChange={handleSelectedDateRangeChange}
            />
          </div>

          <MetricsRow style={{ marginTop: DS.margins.micro }}>
            <NumberMetric
              label={t("metrics.daysSinceLastHigh.title")}
              value={filteredIncidentStats?.daysSinceLastHighSeverityIncident}
              isLoading={isFilteredIncidentStatsLoading}
              isError={isFilteredIncidentStatsError}
            />
            <DonutMetric
              label={`${t("term.severity_one")}`}
              isLoading={isFilteredIncidentStatsLoading}
              isError={isFilteredIncidentStatsError}
              data={[
                {
                  id: "Low",
                  title: `${t("severity.low")}`,
                  count: filteredIncidentStats?.countLow ?? 0,
                  color: severityPalette("Low").background,
                },
                {
                  id: "Medium",
                  title: `${t("severity.medium")}`,
                  count: filteredIncidentStats?.countMedium ?? 0,
                  color: severityPalette("Medium").background,
                },
                {
                  id: "High",
                  title: `${t("severity.high")}`,
                  count: filteredIncidentStats?.countHigh ?? 0,
                  color: severityPalette("High").background,
                },
              ]}
              showLegend
              selected={severity}
              onChange={handleSeverityClick}
            />
            <BarVerticalMetric
              label="Category"
              value={
                filteredIncidentStats
                  ? filteredIncidentStats.countHigh +
                    filteredIncidentStats.countMedium +
                    filteredIncidentStats.countLow
                  : 0
              }
              size="heavy"
              selected={category}
              data={incidentsByCategory}
              onChange={(category) =>
                setIncidentsFilter((filter) =>
                  filter.category.includes(category)
                    ? {
                        category: filter.category.filter((c) => c !== category),
                      }
                    : {
                        category: [...filter.category, category],
                      },
                )
              }
            />
          </MetricsRow>

          {!allIncidents ||
            !allIncidents.length ||
            !incidentsFiltered ||
            (hasFilter && (
              <Well
                style={{
                  marginTop: DS.margins.micro,
                  textAlign: "center",
                  color: palettes.messages.notice.foreground,
                  background: palettes.messages.notice.background,
                }}
              >
                <SmallText
                  style={{ color: palettes.messages.notice.foreground }}
                >
                  Showing <strong>{incidentsFiltered?.length ?? 0}</strong> of{" "}
                  <strong>
                    {t("incidents.filter", { count: allIncidents.length ?? 0 })}
                  </strong>
                  .{" "}
                  <SecondaryButton inline onClick={handleClearFilterClick}>
                    Clear the filters
                  </SecondaryButton>{" "}
                  to show all {t("term.incident_other").toLowerCase()}.
                </SmallText>
              </Well>
            ))}
        </div>

        {isLoading || (isFetching && !isFetchingNextPage) ? (
          <div style={{ margin: DS.margins.regularCss("rl") }}>
            {Array.from(new Array(6)).map((_v, i) => (
              <div
                key={`placeholder-${i}`}
                style={{
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderBottom: `solid 1px ${palettes.body.border}`,
                }}
              >
                <ListItemPlaceholder />
              </div>
            ))}
          </div>
        ) : isError ||
          (incidents &&
            incidents.pages.some((page) => page.Success !== true)) ? (
          <div style={{ margin: DS.margins.regularCss("rl") }}>
            <ErrorWell style={{ textAlign: "center" }}>
              <Icon
                name="exclamation-alt-circle"
                color={palettes.states.bad.background}
              />{" "}
              There was a problem loading recent{" "}
              {t("term.incident_other").toLowerCase()}.
            </ErrorWell>
          </div>
        ) : !allIncidents || !allIncidents.length || !incidentsFiltered ? (
          <div style={{ margin: DS.margins.regularCss("rl") }}>
            <Message type="success">
              No {t("term.incident_other").toLowerCase()}{" "}
              <strong>
                {dateFormatter(filteredDateRange, DateFormat.fromDateToDate)}
              </strong>{" "}
              for this{" "}
              {storeId ? `${t("term.store_one").toLowerCase()}` : "group"}.
            </Message>
          </div>
        ) : (
          <Scroller>
            <div style={{ padding: DS.margins.regularCss("rbl") }}>
              <Table
                data={data}
                columns={defaultColumns}
                columnVisibility={{ "store-name": !storeId }}
                onRowClick={(row) => handleIncidentClick(row.original.incident)}
                isSelectedRow={(row) =>
                  row.original.incident.incidentId ===
                  selectedIncident?.incidentId
                }
                rowTrimColor={({ original: { incident } }) =>
                  severityPalette(incident.severityLevel).background
                }
                showHeader={true}
              />
              {hasNextPage ? (
                <div
                  style={{
                    margin: DS.margins.regularCss("trl"),
                    textAlign: "center",
                  }}
                >
                  <Button
                    disabled={isFetchingNextPage}
                    onClick={handleLoadMore}
                  >
                    {isFetchingNextPage ? (
                      <>
                        <Icon name="spinner" spin /> Loading more{" "}
                        {t("term.incident_other").toLowerCase()}…
                      </>
                    ) : (
                      <span ref={ref}>
                        Load more {t("term.incident_other").toLowerCase()}
                      </span>
                    )}
                  </Button>
                </div>
              ) : (
                <div
                  style={{ marginTop: DS.margins.micro, textAlign: "center" }}
                >
                  <SmallText>
                    No more {t("term.incident_other").toLowerCase()} within this
                    date range
                  </SmallText>
                </div>
              )}
            </div>
          </Scroller>
        )}
      </div>
      {selectedIncident && (
        <div
          style={{
            overflow: "hidden",
            borderLeft: `solid 1px ${palettes.body.border}`,
            display: "grid",
            alignContent: "start",
          }}
        >
          <SidePanelHeader
            title={getTitle(selectedIncident)}
            subtitle={
              <StoreTime
                storeName={selectedIncident.primaryEvent?.storeName ?? ""}
                dateTime={selectedIncident.date}
                format={DateFormat.dateTimeAndTimeZone}
              />
            }
            closeTitle={`Close ${t("term.incident_one").toLowerCase()} view`}
            onClose={handleCloseClick}
          />
          <IncidentDetailsHeader incident={selectedIncident} />
          <Scroller>
            <IncidentDetails incident={selectedIncident} />
          </Scroller>
        </div>
      )}
    </Page>
  );
};

export default Incidents;
