import { add, differenceInDays, format, sub } from "date-fns";
import { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { Bucket } from "@components/Chart";

import { DateRange, normalizeDateRange, oneMonth } from "./DateRange";

export type IncidentSummary = Omit<
  Api.StoreIncidentSummary,
  "StoreId" | "StoreCountry" | "TotalShipmentCost"
>;

export const INCIDENTS_OVER_DAYS = 14;

export type IncidentsFilter = {
  dateRange: DateRange;
  severityString: Api.EventSeverityLevel[] | string;
  days: number;
  severity: Api.EventSeverityLevel[];
  category: Api.EventCategory[];
};

export type IncidentRow = {
  incident: Api.Incident;
  store: string;
  products: Api.EventMinimal[];
};

export const useIncidentsFilter = (): [
  IncidentsFilter,
  (
    filter:
      | Partial<IncidentsFilter>
      | ((current: IncidentsFilter) => Partial<IncidentsFilter>),
  ) => void,
] => {
  const { search } = useLocation();

  const [filters, setFilters] = useState<IncidentsFilter>(() => {
    const query = new URLSearchParams(search);

    const severityString = query.get("severity") ?? "";
    const severity = severityString
      ? (severityString.split(",") as Api.EventSeverityLevel[])
      : [];

    const category = (query.get("category-type")?.split(",") ??
      []) as Api.EventCategory[];

    const days = Number(query.get("days"));
    const now = new Date();
    const dateRange = days
      ? normalizeDateRange({ start: sub(now, { days: days - 1 }), end: now })
      : oneMonth();

    return {
      dateRange,
      severityString,
      days,
      severity,
      category,
    };
  });

  const setIncidentsFilter = useCallback(
    (
      filter:
        | Partial<IncidentsFilter>
        | ((current: IncidentsFilter) => Partial<IncidentsFilter>),
    ) => {
      const newFilter = typeof filter === "function" ? filter(filters) : filter;

      setFilters((currentFilter) => ({
        ...currentFilter,
        ...newFilter,
      }));
    },
    [filters],
  );

  return [filters, setIncidentsFilter];
};

export const filter_incidentCategory =
  (enabled: boolean, category: Api.EventCategory) => (incident: IncidentRow) =>
    enabled && category ? category.includes(incident.incident.category) : true;

export const filter_eventsWithItemsRemoved = () => (event: Api.EventMinimal) =>
  event.eventType === "StockDecrementEvent" ||
  event.eventType === "ItemTakenEvent" ||
  event.eventType === "ItemUsedEvent" ||
  event.eventType === "StockInspectionEvent";

export const filter_eventsWithItemsPutBack = () => (event: Api.EventMinimal) =>
  event.eventType === "StockIncrementEvent";

export const filter_eventsWithPhotos = () => (event: Api.EventMinimal) =>
  event.eventType === "HardwareDoorCloseEvent" ||
  event.eventType === "HardwareOuterDoorCloseEvent" ||
  event.eventType === "HardwarePhotoEvent" ||
  event.eventType === "HardwareKitPhotoEvent";

export const reduce_aggregateItemsWithDeltas = (
  prev: Api.EventMinimalWithCount[],
  item: Api.EventMinimal,
): Api.EventMinimalWithCount[] => {
  const delta = Math.abs(item.stockDelta);

  const existingItem = prev.find(
    (aggregatedItem) => aggregatedItem.sku === item.sku,
  );

  existingItem
    ? (existingItem.count += delta)
    : prev.push({ ...item, count: delta });

  return prev;
};

export type IncidentBucketSegments = [
  low: number,
  medium: number,
  high: number,
];

export const bucketIncidentStats = (
  dateRange: DateRange,
  incidentStats?: Api.IncidentStats,
): Bucket<IncidentBucketSegments>[] => {
  const stub = new Date(0);

  return Array.from<Bucket<IncidentBucketSegments>>(
    // +1 to make it inclusive of start and end dates
    new Array(Math.abs(differenceInDays(dateRange.start, dateRange.end)) + 1),
  )
    .fill({
      date: { start: stub, end: stub },
      values: [0, 0, 0],
      count: 0,
    })
    .map((_, i) => {
      const start = add(dateRange.start, { days: i });
      start.setHours(0, 0, 0, 0);

      const end = add(dateRange.start, { days: i });
      end.setHours(23, 59, 59, 999);

      const date = { start, end };

      const stats = incidentStats?.incidentsByDate.find((d) =>
        d.date.startsWith(format(date.start, "yyyy-MM-dd")),
      );

      const values: [low: number, mediun: number, high: number] = stats
        ? [stats.low, stats.medium, stats.high]
        : [0, 0, 0];

      return {
        date,
        values,
        count: values.reduce((p, v) => v + p),
      };
    });
};
