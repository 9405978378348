import { t } from "i18next";

import { DateFormat, dateFormatter } from "@util/dateFormat";

interface UnitStatus {
  title: string;
}

/**
 * Filter helper for getting only the units that are considered "active"
 */
export const activeUnitsOnly = (unit: Api.Unit) => {
  switch (unit.Status) {
    case "RequiresMaintenance":
      return true;
    case "Field":
      return true;

    default:
      return false;
  }
};

export const getLastHeartbeat = (unit: Api.Unit) => {
  return dateFormatter(
    { start: new Date(), end: new Date(unit.LastHeartbeat) },
    DateFormat.distanced,
  );
};

export const getStatus = (unit: Api.Unit): UnitStatus => {
  if (unit.Status !== "Field")
    return {
      title: t(`units.status.${unit.Status}.title`),
    };

  if (!unit.IsActive)
    return {
      title: unit.LastHeartbeat
        ? `Offline for ${getLastHeartbeat(unit)}`
        : "Offline",
    };

  return {
    title: t(`units.replenishmentStatus.${unit.ReplenishmentStatus}.title`),
  };
};
