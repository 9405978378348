import { AnimatePresence, motion } from "framer-motion";
import { useId, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLayer } from "react-laag";
import { useModal } from "react-modal-hook";
import styled, { useTheme } from "styled-components";

import DS from "@design/system";

import EditCompanySettingsModal from "../modals/EditCompanySettingsModal";
import EditUserModal from "../modals/EditUserModal";
import { useCurrentUser, useOnLogOutFn } from "../state/hooks";
import Button from "./Button";
import { ContextMenuContainer, MenuButton, Separator } from "./ContextMenu";
import Icon from "./Icon";
import IconButton from "./IconButton";

const Container = styled.header`
  z-index: 1;
  box-sizing: border-box;
  padding: ${DS.margins.micro} ${DS.margins.regular};

  color: ${({ theme }) => theme.palettes.header.foreground};
  background: ${({ theme }) => theme.palettes.header.background};

  box-shadow: ${DS.shadows.panel};

  display: grid;
  grid-template-columns: [lhs] 0.5fr [rhs] 0.5fr;
  align-items: center;
`;

const Header = () => {
  const { t } = useTranslation();
  const controlsId = useId();
  const { links } = useTheme();

  const onLogOut = useOnLogOutFn();
  const { data: currentUser } = useCurrentUser();

  const [isOpen, setIsOpen] = useState(false);

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    onOutsideClick: () => setIsOpen(false),
    overflowContainer: false,
    auto: true,
    placement: "bottom-center",
    triggerOffset: 8,
    containerOffset: 8,
    arrowOffset: 0,
  });

  const [showEditUserModal, closeEditUserModal] = useModal(
    () => (
      <EditUserModal
        userId={currentUser?.userId ?? ""}
        title="Edit Profile"
        onClose={closeEditUserModal}
      />
    ),
    [currentUser],
  );

  const [showCompanySettingsModal, closeCompanySettingsModal] = useModal(
    () => <EditCompanySettingsModal onClose={closeCompanySettingsModal} />,
    [],
  );

  return (
    <Container>
      <div
        style={{
          fontSize: 22,
          fontWeight: 600,
        }}
      >
        {currentUser?.company.companyName}
      </div>
      <div
        style={{
          justifySelf: "flex-end",
          display: "grid",
          gridAutoFlow: "column",
          alignItems: "center",
          gap: DS.margins.micro,
        }}
      >
        <div>
          <Button
            buttonType="transparent"
            {...triggerProps}
            title="User menu"
            aria-label="User menu"
            aria-haspopup
            aria-expanded={isOpen}
            aria-controls={controlsId}
            onClick={() => setIsOpen(true)}
          >
            <div
              style={{
                display: "grid",
                gridAutoFlow: "column",
                gap: DS.margins.micro,
                alignItems: "center",
              }}
            >
              {currentUser?.firstName} {currentUser?.lastName}
            </div>
          </Button>
          {renderLayer(
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  {...layerProps}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1 }}
                >
                  <ContextMenuContainer id={controlsId} role="listbox">
                    <li>
                      <MenuButton
                        onClick={() => {
                          setIsOpen(false);
                          showEditUserModal();
                        }}
                      >
                        <Icon name="user-friends" />
                        {t("common.userMenu.editProfile")}
                      </MenuButton>
                    </li>
                    {currentUser?.roles[0].name == "Admin" && (
                      <>
                        <Separator />
                        <li>
                          <MenuButton
                            onClick={() => {
                              setIsOpen(false);
                              showCompanySettingsModal();
                            }}
                          >
                            <Icon name="cogs" />
                            {t("common.userMenu.companySettings")}
                          </MenuButton>
                        </li>
                      </>
                    )}
                    <Separator />
                    <li>
                      <MenuButton onClick={() => void onLogOut()}>
                        <Icon name="sign-out" /> {t("common.userMenu.logOut")}
                      </MenuButton>
                    </li>
                  </ContextMenuContainer>
                </motion.div>
              )}
            </AnimatePresence>,
          )}
        </div>
        <IconButton
          href={links.support.url}
          buttonType="transparent"
          title={links.support.label}
          target="_blank"
          fit="compact"
          icon="question"
        />
      </div>
    </Container>
  );
};

export default Header;
