import { useFormContext } from "react-hook-form";

import Button from "../Button";

const ReviewAddToGroupTabFooter = () => {
  const {
    formState: { isSubmitting },
  } = useFormContext<Api.User>();

  return (
    <Button type="submit" buttonType="action" disabled={isSubmitting}>
      {isSubmitting ? "Adding to group…" : "Add to group"}
    </Button>
  );
};

export default ReviewAddToGroupTabFooter;
