import { MouseEventHandler, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useIncidents } from "@state/hooks";

import StatCircle from "./StatCircle";

const StatCircleRow = ({
  groupId,
  storeId,
  isLoading = false,
  days = 15,
  onLowClick,
  onMediumClick,
  onHighClick,
}: {
  groupId?: string;
  storeId?: string;
  isLoading?: boolean;
  days: number;
  onLowClick?: MouseEventHandler;
  onMediumClick?: MouseEventHandler;
  onHighClick?: MouseEventHandler;
}) => {
  const { data: incidents, isLoading: isIncidentsLoading } = useIncidents({
    storeId,
    groupId,
    numberDays: days,
  });

  const { t } = useTranslation();

  const lowCount = useMemo(
    () =>
      incidents?.filter(
        (incident) =>
          (incident.incidentOverallSeverity ?? incident.severityLevel) ===
          "Low",
      ).length ?? 0,
    [incidents],
  );
  const mediumCount = useMemo(
    () =>
      incidents?.filter(
        (incident) =>
          (incident.incidentOverallSeverity ?? incident.severityLevel) ===
          "Medium",
      ).length ?? 0,
    [incidents],
  );
  const highCount = useMemo(
    () =>
      incidents?.filter(
        (incident) =>
          (incident.incidentOverallSeverity ?? incident.severityLevel) ===
          "High",
      ).length ?? 0,
    [incidents],
  );

  return (
    <div
      style={{
        display: "grid",
        gridAutoFlow: "column",
        justifyContent: "space-between",
      }}
    >
      {isLoading || isIncidentsLoading ? (
        <>
          <StatCircle number="--" />
          <StatCircle number="--" />
          <StatCircle number="--" />
        </>
      ) : (
        <>
          <StatCircle
            title={`View ${t("severity.low").toLowerCase()} ${t(
              "term.incident_other",
            ).toLowerCase()}`}
            number={lowCount}
            label={t("severity.low")}
            type="Low"
            onClick={onLowClick}
          />
          <StatCircle
            title={`View ${t("severity.medium").toLowerCase()} ${t(
              "term.incident_other",
            ).toLowerCase()}`}
            number={mediumCount}
            label={t("severity.medium")}
            type="Medium"
            onClick={onMediumClick}
          />
          <StatCircle
            title={`View ${t("severity.high").toLowerCase()} ${t(
              "term.incident_other",
            ).toLowerCase()}`}
            number={highCount}
            label={`${t("severity.high").toLowerCase()}`}
            type="High"
            onClick={onHighClick}
          />
        </>
      )}
    </div>
  );
};

export default StatCircleRow;
