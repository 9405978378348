import styled from "styled-components";

import DS from "@design/system";

const ModalFooter = styled.div`
  padding: ${DS.margins.regular};
  display: grid;
  gap: 16px;

  color: ${({ theme }) => theme.palettes.well.foreground};
  background: ${({ theme }) => theme.palettes.well.background};

  > p {
    margin: 0;

    text-align: center;
  }
`;

export default ModalFooter;
