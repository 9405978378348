import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import DS from "@design/system";

import IconButton from "./IconButton";

const Background = styled.div<{ visible: boolean }>`
  position: absolute;
  top: -2px;
  right: -4px;
  bottom: -2px;
  left: -4px;

  opacity: ${({ visible }) => (visible ? "1" : "0")};

  border-radius: ${DS.radii.item};
  background: ${({ theme }) => theme.palettes.form.dim};

  transition: opacity 200ms;
`;

const Outline = styled.div<{ visible: boolean }>`
  position: absolute;
  top: -2px;
  right: -4px;
  bottom: -2px;
  left: -4px;

  opacity: ${({ visible }) => (visible ? "1" : "0")};

  border-radius: ${DS.radii.item};
  border: solid 3px rgba(101, 184, 212, 0.5);

  transition: opacity 200ms;
`;

const Container = styled.div`
  position: relative;

  &:focus {
    outline: 0;
  }
`;

const Content = styled.div`
  position: relative;
`;

const Editable = styled.input`
  width: 100%;
  padding: 0;
  margin: 0;

  font: inherit;
  color: inherit;
  background: transparent;

  border: 0;

  &:focus {
    outline: 0;
  }
`;

// To account for the oversized Outline
const outlineDifference = -4;

const Controls = styled.div<{ visible: boolean }>`
  position: absolute;
  top: calc(100% + ${DS.margins.micro});
  right: ${outlineDifference}px;

  font-size: 12px;

  display: ${({ visible }) => (visible ? "grid" : "none")};
  grid-auto-flow: column;
  gap: ${DS.margins.micro};

  button {
    box-shadow: ${DS.shadows.main};
  }
`;

export interface InlineEditableProps {
  value: string;
  placeholder?: string;
  onChange?: (newValue: string) => void;
}

const InlineEditable = ({
  value,
  placeholder,
  onChange,
}: InlineEditableProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [newValue, setNewValue] = useState(value);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useCallback(
    (node: HTMLInputElement | null) => isEditing && node?.focus(),
    [isEditing],
  );

  const edit = useCallback(() => !isEditing && setIsEditing(true), [isEditing]);
  const pause = useCallback(() => setIsEditing(false), []);

  const commit = useCallback(() => {
    if (!newValue) {
      return;
    }

    setIsEditing(false);
    onChange && onChange(newValue);
  }, [onChange, newValue]);

  const reset = useCallback(() => {
    setIsEditing(false);
    setNewValue(value);
  }, [value]);

  useEffect(() => {
    const handleWindowBlur = () => pause();
    const handleDocumentClick = (e: MouseEvent) => {
      if (!containerRef.current || !e.target) return;

      const inBounds =
        containerRef.current.contains(e.target as Node) ||
        !document.body.contains(e.target as Node);

      if (!inBounds) pause();
    };

    document.addEventListener("click", handleDocumentClick);
    window.addEventListener("blur", handleWindowBlur);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
      window.removeEventListener("blur", handleWindowBlur);
    };
  }, [pause]);

  useEffect(() => setNewValue(value), [value]);

  const handleContainerClick = useCallback(() => {
    edit();
  }, [edit]);

  const handleContentMouseEnter = useCallback(() => setIsOver(true), []);
  const handleContentMouseLeave = useCallback(() => setIsOver(false), []);

  const handleFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      commit();
    },
    [commit],
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setNewValue(e.target.value),
    [],
  );

  const handleSaveClick = useCallback(() => commit(), [commit]);
  const handleCancelClick = useCallback(() => reset(), [reset]);

  return (
    <Container ref={containerRef} tabIndex={0} onClick={handleContainerClick}>
      <Background visible={isOver && !isEditing} />
      <Outline visible={isEditing} />

      <Content
        onMouseEnter={handleContentMouseEnter}
        onMouseLeave={handleContentMouseLeave}
      >
        {isEditing ? (
          <form onSubmit={handleFormSubmit}>
            {/* TODO: 🚨 Handle legit input blur, other than clicking a control button */}
            <Editable
              ref={inputRef}
              placeholder={placeholder}
              defaultValue={newValue}
              onChange={handleInputChange}
            ></Editable>
          </form>
        ) : (
          <span>{value}</span>
        )}
      </Content>

      <Controls visible={isEditing}>
        <IconButton
          title="Save group name change"
          icon="check"
          buttonType="action"
          onClick={handleSaveClick}
        />
        <IconButton
          title="Discard group name change"
          icon="times"
          onClick={handleCancelClick}
        />
      </Controls>

      {/* TODO: 🚨 Accessible button required here */}
    </Container>
  );
};

export default InlineEditable;
