import mapboxGl, { LineLayer, SymbolLayer } from "mapbox-gl";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MapGL, { Layer, MapRef, Marker, Source } from "react-map-gl";
import { useTheme } from "styled-components";

import DS from "@design/system";
import { BoxLoader } from "@util/ContentLoader";
import {
  findCenter,
  findShortest,
  getArrowLocationAndAngle,
  calculateSvgRelativeArrowLocation,
} from "@util/map";
import { useMapLayer } from "@util/useMapLayer";

import MapControls from "./MapControls";

export type LocationMarker = {
  label: string;
  marker: ReactElement;

  longitude: number;
  latitude: number;
};

const lineStore: LineLayer = {
  id: "line-style",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "white",
    "line-width": 2,
  },
};

const symbol: SymbolLayer = {
  id: "symbol",
  type: "symbol",
  layout: {
    "symbol-placement": "line",
    "icon-rotate": -90,
  },
};

const MapLocationChange = ({
  width = 328,
  height = 300,
  from,
  to,
}: {
  width: number;
  height: number;
  from?: LocationMarker;
  to?: LocationMarker;
}) => {
  const theme = useTheme();
  const mapRef = useRef<MapRef | null>(null);

  const [arrow, setArrow] = useState({ x: 0, y: 0, angle: 0 });

  const handleMove = () => {
    const arrowValues = getArrowLocationAndAngle(mapRef.current, from, to);
    if (arrowValues) {
      setArrow(arrowValues);
    }
  };

  const zoomAll = useCallback(() => {
    if (!from || !to) return;
    const solved = findCenter([
      [from?.longitude, from?.latitude],
      [to?.longitude, to?.latitude],
    ]);
    if (solved)
      mapRef.current?.fitBounds(solved, { padding: 40, maxZoom: 16, speed: 4 });
  }, [to, from]);

  const arrowLocation = useMemo(
    () => arrow && calculateSvgRelativeArrowLocation(arrow.angle, 19),
    [arrow],
  );
  const markers = useMemo(
    () => [
      from && (
        <Marker
          onClick={zoomAll}
          key="from"
          longitude={from.longitude}
          latitude={from.latitude}
        >
          {from.marker}
        </Marker>
      ),
      to && (
        <Marker
          onClick={zoomAll}
          key="to"
          longitude={to.longitude}
          latitude={to.latitude}
        >
          {to.marker}
        </Marker>
      ),
    ],
    [from, to, zoomAll],
  );

  const line = useMemo(() => {
    return {
      type: "FeatureCollection" as const,
      features: [
        {
          type: "Feature" as const,
          properties: {
            color: "red",
          },
          geometry: {
            type: "LineString" as const,
            coordinates:
              from && to
                ? findShortest(
                    [from.longitude, from.latitude],
                    [to.longitude, to.latitude],
                  )
                : [],
          },
        },
      ],
    };
  }, [from, to]);

  useEffect(() => {
    if (!mapRef.current || !from || !to) return;

    const solved = findCenter([
      [from.longitude, from.latitude],
      [to.longitude, to.latitude],
    ]);

    if (solved) mapRef.current.fitBounds(solved, { padding: 60 });
  }, [from, to]);

  const coords =
    from && to
      ? ([
          [to.longitude, to.latitude],
          [from.longitude, from.latitude],
        ] as [lng: number, lat: number][])
      : [];

  const [mapStyle] = useMapLayer();

  return !from || !to ? (
    <BoxLoader width={width} height={height} radius={DS.radii.largeItem} />
  ) : (
    <MapGL
      ref={mapRef}
      mapLib={mapboxGl}
      mapboxAccessToken={import.meta.env.VITE_APP_MAPBOX_TOKEN}
      mapStyle={mapStyle}
      onMove={handleMove}
      onZoom={handleMove}
      style={{
        position: "relative",
        width,
        height,
        borderRadius: DS.radii.largeItem,
      }}
    >
      <Source id="line" type="geojson" data={line}>
        <Layer {...lineStore} />
        <Layer {...symbol}></Layer>
      </Source>
      {arrowLocation && (
        <div
          style={{
            pointerEvents: "none",
            transform: `translate3d(${arrow.x + arrowLocation[0] - 20}px,${
              arrow.y + arrowLocation[1] - 20
            }px, 0) rotate3d(0, 0, 1, ${arrow.angle - 90}deg)`,
            position: "absolute",
            width: 40,
            height: 40,
          }}
        >
          <svg height="40" width="40" viewBox="0 0 40 40">
            <path
              d={`M20,20 L30,38 L20,35 L10,38 Z`}
              fill={theme.palettes.states.good.background}
              stroke={theme.palettes.states.good.foreground}
              strokeWidth={2}
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
      {markers}

      <div
        style={{
          position: "absolute",
          top: DS.margins.micro,
          right: DS.margins.micro,
        }}
      >
        <MapControls
          mapRef={mapRef.current}
          showZoomAll={true}
          zoomAllCoords={coords}
          size="small"
        />
      </div>
    </MapGL>
  );
};

export default MapLocationChange;
