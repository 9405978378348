import { forwardRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import DS from "@design/system";

const Container = styled.a<{ active: boolean; border?: "big" | "small" }>`
  position: relative;
  overflow: hidden;
  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  cursor: pointer;
  text-decoration: none;
  text-align: start;

  border: 0;
  border-radius: ${({ border }) =>
    border === "big" ? DS.radii.largeItem : DS.radii.item};

  background: ${({ theme, active }) =>
    active ? theme.palettes.itemSelected.background : "transparent"};

  &:hover {
    background: ${({ theme, active }) =>
      active
        ? theme.palettes.itemSelected.dim
        : theme.palettes.itemSelected.light};
  }
`;

type Props = React.HTMLAttributes<HTMLElement> & {
  to?: string;
  active?: boolean;
  border?: "big" | "small";
  onClick?: () => void;
  children: React.ReactNode;
};

const Linkable = forwardRef<HTMLAnchorElement, Props>(
  (
    { to, active = false, border = "small", onClick, children, ...rest },
    ref,
  ) => {
    const history = useHistory();
    const { palettes } = useTheme();

    const handleClick = useCallback(() => {
      if (!to) {
        return;
      }

      history.push(to);
    }, [history, to]);

    return (
      <Container
        ref={ref}
        as={to ? "a" : "button"}
        type="button"
        active={active}
        border={border}
        onClick={onClick ?? handleClick}
        {...rest}
      >
        {children}

        <div
          style={{
            position: "absolute",
            top: "50%",
            left: 0,
            width: 3,
            height: 32,
            transform: active
              ? "translate3d(0px, -50%, 0) scaleY(1)"
              : "translate3d(-100%, -50%, 0) scaleY(0.8)",
            background: active ? palettes.body.accent : "transparent",
            borderRadius: "0 2px 2px 0",
            transition: "all 150ms ease-out",
          }}
        />
      </Container>
    );
  },
);

Linkable.displayName = "Linkable";

export default Linkable;
