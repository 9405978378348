import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import useAddNewGroup from "@components/AddNewGroup";
import Button from "@components/Button";
import DaysGoneBanner from "@components/DaysGoneBanner";
import GroupReadinessImage from "@components/GroupReadinessImage";
import GroupedList from "@components/GroupedList";
import Icon from "@components/Icon";
import IncidentsChart from "@components/IncidentsChart";
import ReadinessBanner from "@components/ReadinessBanner";
import Scroller from "@components/Scroller";
import { SearchResult, SearchView } from "@components/SearchView";
import SidePanelContainer from "@components/SidePanelContainer";
import { SidePanelContentContainer } from "@components/SidePanelContentContainer";
import SidePanelFooter from "@components/SidePanelFooter";
import StatCircleRow from "@components/StatCircleRow";
import StoreReadinessImage from "@components/StoreReadinessImage";
import DS from "@design/system";

import { useGroup, useGroupsWithStoreCount, useStores } from "../state/hooks";
import { useMapState } from "../util/MapState";
import { useSidePanels } from "../util/SidePanels";
import { INCIDENTS_OVER_DAYS } from "../util/incidents";
import GroupSidePanel from "./GroupSidePanel";
import StoreSidePanel from "./StoreSidePanel";

const getDetailsButtonLabel = (
  group?: Api.TreeGroup,
  terminology?: string | null,
) => {
  if (!group) return "Open group details";

  switch (group.Type) {
    case "Root":
      return "Open company details";
    case "Client":
      return "Open details";
    case "SingleStore":
      return `Open ${terminology} details`;
    case "Franchise":
      return "Open franchise details";
    case "Adhoc":
    default:
      return "Open group details";
  }
};

const OverviewSidePanel = () => {
  const history = useHistory();

  const group = useGroup();
  const stores = useStores();
  const groupsWithCount = useGroupsWithStoreCount();

  const { push } = useSidePanels();
  const { setGroupId, setStoreId } = useMapState();
  const [AddNewGroup, showAddGroup] = useAddNewGroup(group.data);

  const [childGroups, setChildGroups] = useState<Api.GroupWithCount[]>();
  const [searchView, setSearchView] = useState(false);

  const { t } = useTranslation();
  const handleGroupClick = useCallback(
    (group: Api.GroupWithCount) => {
      setGroupId(group.GroupId);
      push({
        id: `group-${group.GroupId}`,
        content: <GroupSidePanel groupId={group.GroupId} />,
      });
    },
    [push, setGroupId],
  );

  const handleStoreClick = useCallback(
    (store: Api.Store) => {
      setStoreId(store.StoreId);
      push({
        id: `store-${store.StoreId}`,
        content: <StoreSidePanel storeId={store.StoreId} />,
      });
    },
    [push, setStoreId],
  );

  const handleOpenDetailClick = useCallback(() => {
    if (!group.data) return;

    if (group.data.Type === "Client") {
      history.push(`/all-stores/overview`);
    } else {
      history.push(`/group/${group.data.GroupId}`);
    }
  }, [group, history]);

  const handleLowClick = useCallback(() => {
    history.push(
      `/group/${
        group.data?.GroupId ?? ""
      }/incidents?severity=Low&days=${INCIDENTS_OVER_DAYS}`,
    );
  }, [group.data, history]);

  const handleMediumClick = useCallback(() => {
    history.push(
      `/group/${
        group.data?.GroupId ?? ""
      }/incidents?severity=Medium&days=${INCIDENTS_OVER_DAYS}`,
    );
  }, [group.data, history]);

  const handleHighClick = useCallback(() => {
    history.push(
      `/group/${
        group.data?.GroupId ?? ""
      }/incidents?severity=High&days=${INCIDENTS_OVER_DAYS}`,
    );
  }, [group.data, history]);

  const navigateToFoundItem = useCallback(
    (result: SearchResult): void => {
      switch (result.type) {
        case "group":
          setGroupId(result.resultId);
          push({
            id: "group",
            content: <GroupSidePanel groupId={result.resultId} />,
          });
          break;
        case "store":
          setStoreId(result.resultId);
          push({
            id: `store-${result.resultId}`,
            content: <StoreSidePanel storeId={result.resultId} />,
          });
          break;
      }
    },
    [push, setGroupId, setStoreId],
  );

  useEffect(() => {
    if (!group.data || !group.data.Groups || !groupsWithCount.data) return;

    setChildGroups(
      group.data.Groups.filter(
        (g) => g.Type === "Franchise" || g.Type === "Adhoc",
      )
        .map(
          (group) =>
            groupsWithCount.data.filter((g) => group.GroupId === g.GroupId)[0],
        )
        .filter((g) => g)
        .sort((a, b) => a.Name.localeCompare(b.Name)),
    );
  }, [group.data, groupsWithCount.data]);

  return (
    <SidePanelContainer aria-label="Overview">
      <SearchView
        style={{ paddingTop: DS.margins.regular }}
        navigateToFoundItem={navigateToFoundItem}
        searchView={searchView}
        setSearchView={setSearchView}
      />

      {!searchView && (
        <>
          <Scroller>
            <SidePanelContentContainer
              contents={[
                <>
                  <div
                    style={{
                      display: "grid",
                      gap: DS.margins.micro,
                      gridTemplateColumns: "1fr auto",
                    }}
                  >
                    <ReadinessBanner groupId={group.data?.GroupId} />
                    <DaysGoneBanner groupId={group.data?.GroupId} />
                  </div>
                </>,
                <>
                  <IncidentsChart days={INCIDENTS_OVER_DAYS} />
                </>,
                <>
                  {group.data && (
                    <StatCircleRow
                      isLoading={group.isLoading}
                      days={INCIDENTS_OVER_DAYS}
                      onLowClick={
                        group.data.Type !== "Client"
                          ? handleLowClick
                          : undefined
                      }
                      onMediumClick={
                        group.data.Type !== "Client"
                          ? handleMediumClick
                          : undefined
                      }
                      onHighClick={
                        group.data.Type !== "Client"
                          ? handleHighClick
                          : undefined
                      }
                    />
                  )}
                </>,
                <>
                  {group.data &&
                    ((group.data.Type === "Client" &&
                      group.data.Stores.length > 0) ||
                    (group.data.Type === "Adhoc" &&
                      group.data.Stores.length > 0) ? (
                      <GroupedList
                        separator
                        tight
                        title={t("term.store_other")}
                        emptyLabel={`No ${t("term.store_other").toLowerCase()}`}
                        data={stores.data}
                        isLoading={group.isLoading}
                        isError={group.isError}
                        listItemKey={(store) => store.StoreId}
                        listItemImage={(store) => (
                          <StoreReadinessImage storeId={store.StoreId} />
                        )}
                        listItemTitle={(store) => store.Name}
                        listItemDescription={(store) =>
                          store.StreetAddress.AsOneLine
                        }
                        onListItemClick={handleStoreClick}
                      />
                    ) : group.data.Type === "Franchise" ||
                      group.data.Type === "Client" ? (
                      <GroupedList
                        separator
                        tight
                        title={
                          group.data?.Groups.filter(
                            (g) => g.Type === "Franchise",
                          ).length
                            ? "Franchises & Groups"
                            : "Groups"
                        }
                        emptyLabel="No groups."
                        data={childGroups}
                        isLoading={group.isLoading}
                        isError={group.isError}
                        controls={
                          (group.data && [
                            {
                              title: "Add a new group",
                              icon: "plus",
                              onClick: () => showAddGroup(),
                            },
                          ]) ||
                          undefined
                        }
                        listItemKey={(group) => group.GroupId}
                        listItemImage={(group) => (
                          <GroupReadinessImage groupId={group.GroupId} />
                        )}
                        listItemTitle={(group) => group.Name}
                        listItemDescription={(group) =>
                          `${t("term.store", { count: group.storeCount })}`
                        }
                        onListItemClick={handleGroupClick}
                      />
                    ) : (
                      <GroupedList
                        separator
                        tight
                        title={t("term.store_other")}
                        emptyLabel={`No ${t("term.store_other").toLowerCase()}`}
                        data={stores.data}
                        isLoading={stores.isLoading}
                        isError={stores.isError}
                        listItemKey={(store) => store.StoreId}
                        listItemImage={(store) => (
                          <StoreReadinessImage storeId={store.StoreId} />
                        )}
                        listItemTitle={(store) => store.Name}
                        listItemDescription={(store) =>
                          store.StreetAddress.AsOneLine
                        }
                        onListItemClick={handleStoreClick}
                      />
                    ))}
                  <AddNewGroup />
                </>,
              ]}
            />
          </Scroller>

          <SidePanelFooter>
            <Button
              buttonType="progressive"
              onClick={handleOpenDetailClick}
              stretch
            >
              {getDetailsButtonLabel(
                group.data,
                t("term.store_one").toLowerCase(),
              )}{" "}
              <Icon name="arrow-up-right-from-square" />
            </Button>
          </SidePanelFooter>
        </>
      )}
    </SidePanelContainer>
  );
};

export default OverviewSidePanel;
