import { Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";

import DS from "@design/system";

import { useTooltip } from "./Tooltip";

const LANGUAGES = ["en", "mi", "pt", "cimode"] as const;
type Language = (typeof LANGUAGES)[number];

const Container = styled.div`
  display: grid;
  gap: 4px;
  grid-auto-flow: column;
`;

function ChangeLanguageButton({
  selected,
  language,
}: {
  selected: boolean;
  language: Language;
}) {
  const { i18n, t } = useTranslation();
  const { palettes } = useTheme();
  const { tooltipProps } = useTooltip<HTMLButtonElement>(
    <div>
      Switch to{" "}
      <strong>
        {t(`lang.${language}`)} ({language})
      </strong>
    </div>,
  );

  const handleClick = useCallback(
    (lng: Language) => {
      void i18n.changeLanguage(lng);
    },
    [i18n],
  );

  return (
    <button
      {...tooltipProps}
      style={{
        padding: selected ? "0 8px" : 0,
        border: 0,
        margin: 0,
        cursor: "pointer",
        borderRadius: DS.radii.pill,
        fontWeight: selected ? 600 : 400,
        color: selected
          ? palettes.buttons.neutralSelected.foreground
          : "inherit",
        background: selected
          ? palettes.buttons.neutralSelected.background
          : "transparent",
      }}
      onClick={() => handleClick(language)}
    >
      {t(`lang.${language}`)}
    </button>
  );
}

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();

  return (
    <Container>
      {LANGUAGES.map((lng, i, arr) => (
        <Fragment key={lng}>
          <ChangeLanguageButton
            selected={i18n.language === lng}
            language={lng}
          />
          {i !== arr.length - 1 ? "|" : ""}
        </Fragment>
      ))}
    </Container>
  );
}
