import { useDayzed } from "dayzed";
import { useCallback, useState } from "react";
import { useLayer } from "react-laag";
import { useTheme } from "styled-components";

import DS from "@design/system";
import { DateRange, normalizeDateRange, oneMonth } from "@util/DateRange";

import Button from "./Button";
import ButtonGroup from "./ButtonGroup";
import Calendar from "./Calendar";
import { MenuButton } from "./ContextMenu";
import DateRangeForDays from "./DateRangeForDays";
import { LabelledDatePicker } from "./FormControls";
import Icon from "./Icon";
import ModalFooter from "./ModalFooter";

const DateRangePicker = ({
  initialDateRange,
  onDateRangeChange,
}: {
  initialDateRange?: DateRange;
  onDateRangeChange?: (dateRange: DateRange) => void;
}) => {
  const { palettes } = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [leftOffset, setLeftOffset] = useState<number>(0);
  const [rightOffset, setRightOffset] = useState<number>(0);

  const [dateRange, setDateRange] = useState<DateRange>(
    initialDateRange ?? oneMonth(),
  );

  const months = (count: number) => {
    const now = new Date();

    const start = new Date(now);
    start.setFullYear(
      start.getFullYear(),
      start.getMonth() - count,
      start.getDate(),
    );

    const end = new Date(now);

    return normalizeDateRange({ start, end });
  };

  const handleDateRangeChange = useCallback(
    (range: DateRange) => {
      setDateRange(range);
      setIsOpen(false);
      onDateRangeChange?.(range);
    },
    [onDateRangeChange],
  );

  const handleDateRangeSelected = useCallback((range: DateRange) => {
    if (isNaN(range.start.getTime()) || isNaN(range.end.getTime())) return;
    setDateRange(range);
  }, []);

  const handleApply = useCallback(() => {
    onDateRangeChange?.(dateRange);
    setIsOpen(false);
  }, [dateRange, onDateRangeChange]);

  const close = useCallback(() => setIsOpen(false), []);

  const { renderLayer, layerProps, triggerProps } = useLayer({
    isOpen,
    onOutsideClick: close,
    triggerOffset: DS.margins.microN,
    placement: "bottom-end",
  });

  const controlLeftCalendar = useDayzed({
    date: dateRange.start,
    selected: [dateRange.start, dateRange.end],
    maxDate: dateRange.end,
    showOutsideDays: true,
    offset: leftOffset,
    onOffsetChanged: (o) => setLeftOffset(o),
    onDateSelected: (selectedDate) => {
      setDateRange((prev) => ({ ...prev, start: selectedDate.date }));
      setLeftOffset(0);
    },
  });

  const controlRightCalendar = useDayzed({
    date: dateRange.end,
    selected: [dateRange.start, dateRange.end],
    maxDate: new Date(),
    minDate: dateRange.start,
    showOutsideDays: true,
    offset: rightOffset,
    onOffsetChanged: (o) => setRightOffset(o),
    onDateSelected: (selectedDate) => {
      setDateRange((prev) => ({ ...prev, end: selectedDate.date }));
      setRightOffset(0);
    },
  });

  return (
    <>
      <Button
        {...triggerProps}
        size="small"
        fit="compact"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon name="calendar-days" />
        <DateRangeForDays startDate={dateRange.start} endDate={dateRange.end} />
        <Icon name="caret-down" />
      </Button>

      {renderLayer(
        isOpen && (
          <div
            {...layerProps}
            style={{
              ...layerProps.style,
              overflow: "hidden",
              display: "grid",
              gridTemplateColumns: "1fr auto",
              border: `solid 1px ${palettes.form.border}`,
              background: palettes.body.background,
              borderRadius: DS.radii.largeItem,
              boxShadow: DS.shadows.dialog,
            }}
          >
            <div style={{ borderRight: `solid 1px ${palettes.body.border}` }}>
              <MenuButton onClick={() => handleDateRangeChange(months(1))}>
                1 Month
              </MenuButton>

              <MenuButton onClick={() => handleDateRangeChange(months(3))}>
                3 Months
              </MenuButton>

              <MenuButton onClick={() => handleDateRangeChange(months(6))}>
                6 Months
              </MenuButton>

              <MenuButton onClick={() => handleDateRangeChange(months(12))}>
                1 Year
              </MenuButton>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateRows: "1fr auto auto",
                gap: DS.margins.micro,
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  padding: DS.margins.regularCss("trl"),
                }}
              >
                <div
                  style={{
                    borderRight: `solid 1px ${palettes.body.border}`,
                    borderBottom: `solid 1px ${palettes.body.border}`,
                    padding: DS.margins.regularCss("rb"),
                  }}
                >
                  <Calendar
                    control={controlLeftCalendar}
                    selectedDateRange={dateRange}
                    onTodayClick={() => setLeftOffset(0)}
                  />
                </div>

                <div
                  style={{
                    borderBottom: `solid 1px ${palettes.body.border}`,
                    paddingLeft: DS.margins.regular,
                  }}
                >
                  <Calendar
                    control={controlRightCalendar}
                    selectedDateRange={dateRange}
                    onTodayClick={() => setRightOffset(0)}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "grid",
                  padding: DS.margins.regularCss("rl"),
                  gridTemplateColumns: `1fr ${DS.margins.regular} 1fr`,
                  placeItems: "center",
                }}
              >
                <LabelledDatePicker
                  value={dateRange.start.toISOString()}
                  onChange={(e) => {
                    handleDateRangeSelected({
                      ...dateRange,
                      start: new Date(e.target.value),
                    });
                    setLeftOffset(0);
                  }}
                  showPicker={false}
                />

                <Icon name="arrow-right" color={palettes.body.small} />

                <LabelledDatePicker
                  value={dateRange.end.toISOString()}
                  onChange={(e) => {
                    handleDateRangeSelected({
                      ...dateRange,
                      end: new Date(e.target.value),
                    });
                    setRightOffset(0);
                  }}
                  showPicker={false}
                />
              </div>

              <ModalFooter>
                <ButtonGroup flow="horizontal">
                  <Button onClick={() => setIsOpen(false)}>Close</Button>
                  <Button buttonType="action" onClick={handleApply}>
                    Apply
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </div>
          </div>
        ),
      )}
    </>
  );
};

export default DateRangePicker;
