import { useTheme } from "styled-components";

import DS from "@design/system";
import { DateFormat } from "@util/dateFormat";
import { useTerminologies } from "@util/useTerminologies";
import { useThemeHelper } from "@util/useThemeHelper";

import ContentLoader from "../util/ContentLoader";
import Icon from "./Icon";
import { Wrapped } from "./Layout";
import StoreTime from "./StoreTime";

export const IncidentCardPlaceholder = () => (
  <ContentLoader width={112} height={112}>
    <rect x={0} y={0} width={112} height={112} rx={DS.radii.largeItem} />
  </ContentLoader>
);

const IncidentCard = ({
  incident,
  showStore = false,
}: {
  incident: Api.Incident;
  showStore?: boolean;
}) => {
  const { palettes } = useTheme();
  const { eventCategoryIcon, severityPalette } = useThemeHelper();

  const { getTitle } = useTerminologies();

  return (
    <div
      style={{
        position: "relative",
        boxSizing: "border-box",
        width: 112,
        height: 112,
        padding: DS.margins.micro,

        border: "solid 1px",
        borderColor: palettes.body.border,
        borderRadius: DS.radii.largeItem,

        display: "grid",
        gap: 2,
        justifyItems: "center",
        alignContent: "center",
      }}
    >
      <Icon
        name={eventCategoryIcon(incident.category)}
        color={
          severityPalette(
            incident.incidentOverallSeverity ?? incident.severityLevel,
          ).background
        }
        size={32}
      />
      <p
        style={{
          overflow: "hidden",
          maxWidth: "100%",
          margin: 0,
          color: palettes.body.foreground,
          lineHeight: "16px",
          fontSize: 14,
          fontWeight: 700,
        }}
      >
        <Wrapped>{getTitle(incident, true)}</Wrapped>
      </p>
      {showStore && (
        <p
          style={{
            overflow: "hidden",
            maxWidth: "100%",
            margin: 0,

            color: palettes.body.small,
            lineHeight: "14px",
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          <Wrapped>{incident.primaryEvent?.storeName}</Wrapped>
        </p>
      )}
      <p
        style={{
          overflow: "hidden",
          maxWidth: "100%",
          margin: 0,

          color: palettes.body.small,
          lineHeight: "14px",
          fontSize: 11,
          fontWeight: 500,
        }}
      >
        <Wrapped>
          <StoreTime
            storeName={incident.primaryEvent?.storeName ?? ""}
            dateTime={incident.date}
            format={DateFormat.distanced}
          />
        </Wrapped>
      </p>
    </div>
  );
};

export default IncidentCard;
