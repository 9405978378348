import { forwardRef } from "react";
import styled, { useTheme } from "styled-components";

import DS from "@design/system";

import ContentLoader from "../util/ContentLoader";
import Icon from "./Icon";
import { MediaCardPlaceholder } from "./MediaCard";

const ContainerBase = styled.div`
  padding-left: ${DS.margins.micro};

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;

  border: solid 1px transparent;
`;

const Container = styled(ContainerBase)<{
  selected: boolean;
  disabled: boolean;
}>`
  user-select: none;
  cursor: default;
  font-style: ${({ disabled }) => (disabled ? "italic" : "normal")};

  border-color: ${({ theme, selected }) =>
    selected
      ? theme.palettes.buttons.neutralSelected.border
      : theme.palettes.buttons.neutral.border};
  border-radius: ${DS.radii.item};

  background: ${({ theme, selected }) =>
    selected ? theme.palettes.itemSelected.background : "transparent"};

  &:hover {
    background: ${({ theme, disabled, selected }) =>
      disabled
        ? "transparent"
        : selected
          ? theme.palettes.itemSelected.dim
          : theme.palettes.itemSelected.light};
  }
`;

export const SelectablePlaceholder = ({
  showImage = true,
  imageSize = 32,
}: {
  showImage?: boolean;
  imageSize?: number;
}) => (
  <ContainerBase>
    <ContentLoader width={24} height={46}>
      <circle cx={8} cy={23} r={8} />
    </ContentLoader>
    <MediaCardPlaceholder
      width={180}
      showImage={showImage}
      imageSize={imageSize}
    />
  </ContainerBase>
);

export const SelectablesPlaceholder = ({
  showImage = true,
  imageSize = 32,
  count = 3,
}: {
  showImage?: boolean;
  imageSize?: number;
  count?: number;
}) => (
  <>
    {Array.from({ length: count }, (_never, i) => (
      <SelectablePlaceholder
        key={`placeholder-${i}`}
        showImage={showImage}
        imageSize={imageSize}
      />
    ))}
  </>
);

interface SelectableProps {
  name?: string;
  label?: string;
  value?: string;
  selected?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  children: React.ReactNode;
}

const Checkbox = styled.input.attrs({
  type: "checkbox",
})`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

const Selectable = forwardRef<HTMLInputElement, SelectableProps>(
  ({ name, label, value, selected, disabled, onChange, children }, ref) => {
    const { palettes } = useTheme();

    return (
      <Container
        as="label"
        aria-label={label}
        selected={selected ?? false}
        disabled={disabled ?? false}
      >
        <Checkbox
          ref={ref}
          type="checkbox"
          name={name}
          value={value}
          onChange={onChange}
        />

        <div>
          {disabled ? (
            <Icon name="circle-outline" color="transparent" />
          ) : selected ? (
            <Icon
              name="circle-outline-with-dot"
              color={palettes.buttons.neutralSelected.background}
            />
          ) : (
            <Icon
              name="circle-outline"
              color={palettes.buttons.neutral.border}
            />
          )}
        </div>

        {children}
      </Container>
    );
  },
);

Selectable.displayName = "Selectable";

export default Selectable;
