import { useCallback, useEffect, useRef } from "react";
import ReactGA4 from "react-ga4";

import { ApiResponseError, isApiResponseError } from "@api";
import { useAuth } from "@auth/auth";

export const trackEvent = <TData, TError extends Api.ValidationError>({
  category,
  action,
  response,
  label,
}: {
  category: string;
  action: string;
  response?: Api.ResponseSuccess<TData> | ApiResponseError<TError>;
  label?: string;
}) => {
  if (import.meta.env.VITE_APP_GA_ENABLED === "false") return;

  const errorMsg =
    response && isApiResponseError(response)
      ? response.error.all.map((e) => e.Description).join(", ")
      : "";

  ReactGA4.event({
    category: category,
    action: action,
    label: `${label ?? "-"} ${errorMsg}`,
  });
};

export const useAnayltics = () => {
  const { session } = useAuth();

  const initialized = useRef(false);

  useEffect(() => {
    if (
      initialized.current ||
      import.meta.env.VITE_APP_GA_ENABLED === "false" ||
      !import.meta.env.VITE_APP_GA_MEASUREMENT_ID
    )
      return;

    ReactGA4.initialize(import.meta.env.VITE_APP_GA_MEASUREMENT_ID, {
      gaOptions: {
        userId: session.user?.userId,
      },
    });

    initialized.current = true;
  }, [session]);

  const track = useCallback((path: string) => {
    if (import.meta.env.VITE_APP_GA_ENABLED === "false") return;

    ReactGA4.send({ hitType: "pageview", page: path });
  }, []);

  return {
    track,
  };
};
