import { useTheme } from "styled-components";

import { SidePanelTitle, SmallText } from "@design/helpers";
import DS from "@design/system";

import CloseButton from "./CloseButton";
import { Wrapped } from "./Layout";

const SidePanelHeader = ({
  title,
  closeTitle,
  subtitle,
  onClose,
}: {
  title: React.ReactNode;
  closeTitle: string;
  onClose?: () => void;
  subtitle?: React.ReactElement | string;
}) => {
  const { palettes } = useTheme();

  return (
    <header
      style={{
        padding: DS.margins.regularCss("tb"),
        paddingBottom: subtitle ? DS.margins.micro : DS.margins.regular,
        margin: DS.margins.regularCss("rl"),

        borderBottom: `solid 1px ${palettes.body.border}`,

        display: "grid",
        gridAutoFlow: "column",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: "100%", overflow: "hidden" }}>
        <SidePanelTitle style={{ lineHeight: "24px" }}>{title}</SidePanelTitle>
        {subtitle && (
          <SmallText>
            <Wrapped>{subtitle}</Wrapped>
          </SmallText>
        )}
      </div>

      {onClose && <CloseButton title={closeTitle} onClick={onClose} />}
    </header>
  );
};

export default SidePanelHeader;
