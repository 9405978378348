import { useMemo } from "react";

import { useStores } from "@state/hooks";
import { dateFormatter, DateFormat } from "@util/dateFormat";

const StoreTime = ({
  storeName,
  dateTime,
  format,
}: {
  storeName: string;
  dateTime: string;
  format: DateFormat;
}) => {
  const { data: allStores } = useStores();

  const store = useMemo(
    () => allStores?.find((store) => store.Name === storeName),
    [allStores, storeName],
  );

  const timeZone = store?.TimeZoneId ?? "UTC";
  const formattedDate = dateFormatter(dateTime, format, timeZone, "short");

  return <span data-utc={dateTime}>{formattedDate}</span>;
};

export default StoreTime;
