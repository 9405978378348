import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapRef, MapboxGeoJSONFeature } from "react-map-gl";
import { useTheme } from "styled-components";

import Donut, { DonutValue } from "@components/Donut";
import { useUnit } from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";

import { Pin, PinContent } from "./Pin";
import { UnitFeatureProperties } from "./types";

export const UnitIncidentMarker = ({
  mapRef,
  feature,
  id,
  selected,
  values,
  cluster,
  onChildChange,
}: {
  mapRef: MapRef | null;
  feature: MapboxGeoJSONFeature;
  id: string;
  selected: boolean;
  values: DonutValue[];
  cluster?: number | null;
  onChildChange?: (child: string) => void;
}) => {
  const { t } = useTranslation();
  const { palettes } = useTheme();
  const { severityPalette } = useThemeHelper();

  const [selectedProperties, setSelectedProperties] =
    useState<UnitFeatureProperties | null>(null);

  const { data: unit } = useUnit(selectedProperties?.id ?? id);

  const vs = useMemo<DonutValue[]>(
    () => [
      {
        id: "low",
        count: selectedProperties?.lowUnitIncidents ?? 0,
        color: severityPalette("Low").background,
      },
      {
        id: "medium",
        count: selectedProperties?.mediumUnitIncidents ?? 0,
        color: severityPalette("Medium").background,
      },
      {
        id: "high",
        count: selectedProperties?.highUnitIncidents ?? 0,
        color: severityPalette("High").background,
      },
    ],
    [
      selectedProperties?.highUnitIncidents,
      selectedProperties?.lowUnitIncidents,
      selectedProperties?.mediumUnitIncidents,
      severityPalette,
    ],
  );

  const incidents = useMemo(
    () => (selectedProperties ? vs : values).reduce((p, v) => p + v.count, 0),
    [selectedProperties, values, vs],
  );

  const onChange = useCallback(
    (f: GeoJSON.Feature<GeoJSON.Geometry>) => {
      setSelectedProperties(f.properties as UnitFeatureProperties);
      onChildChange?.((f.properties as UnitFeatureProperties).id);
    },
    [onChildChange],
  );

  return (
    <Pin
      mapRef={mapRef}
      feature={feature}
      source="units"
      label={unit?.UnitName}
      selected={selected}
      cluster={cluster}
      onChange={onChange}
      content={() => (
        <PinContent
          label={
            <span
              style={{
                color: palettes.body.foreground,
              }}
            >
              {incidents}
            </span>
          }
          description={
            !selected && cluster ? (
              <span
                style={{
                  color: palettes.body.foreground,
                }}
              >
                {t("pages.map.markers.unitCount", { count: cluster })}
              </span>
            ) : null
          }
          selected={selected}
          cluster={cluster}
        />
      )}
      background={(width, height) => (
        <g transform={`translate(0,${selected ? 0 : height - 100})`}>
          <Donut
            radius={cluster ? 25 : selected ? 20 : 0}
            thickness={width / 2 - (cluster ? 25 : selected ? 20 : 0)}
            values={selectedProperties ? vs : values}
            emptyColor={palettes.states.na.background}
            background={palettes.body.background}
          />
        </g>
      )}
    />
  );
};
