import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import DS from "@design/system";
import { useAddGroup } from "@state/hooks";

import { LabelledInput } from "./FormControls";
import IconButton from "./IconButton";

export const AddNewGroup = ({
  parent,
  show,
  setShow,
}: {
  parent?: Api.TreeGroup | null;
  show: boolean;
  setShow: (show: boolean) => void;
}) => {
  const { mutate: addGroup } = useAddGroup();

  const { register, handleSubmit } = useForm<{ groupName: string }>();
  const { ref, ...rest } = register("groupName");

  const handleNewGroupSubmit: React.FormEventHandler = useCallback(
    (e) =>
      void handleSubmit((data: { groupName: string }) => {
        addGroup(
          {
            ParentGroupId: parent?.GroupId,
            Name: data.groupName,
            Type: "Adhoc",
          },
          {
            onError: () => {
              toast.error(
                <span>
                  Unable to add <strong>{data.groupName}</strong>.
                </span>,
              );
            },
            onSuccess: () => {
              toast.success(
                <span>
                  <strong>{data.groupName}</strong> successfully added.
                </span>,
              );
              setShow(false);
            },
          },
        );
      })(e),
    [addGroup, handleSubmit, parent?.GroupId, setShow],
  );

  return (
    <>
      {show && (
        <div style={{ position: "relative" }}>
          <form onSubmit={handleNewGroupSubmit}>
            <LabelledInput
              placeholder="New group name..."
              required
              ref={(el) => {
                ref(el);
                el?.focus();
              }}
              {...rest}
            />
            <div
              style={{
                position: "absolute",
                bottom: DS.margins.nanoN + 1,
                right: DS.margins.nanoN + 1,
              }}
            >
              <IconButton
                type="submit"
                fit="compact"
                buttonType="action"
                icon={"check"}
                title="Save new group"
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
};

const useAddNewGroup = (parent?: Api.TreeGroup | null) => {
  const [show, setShow] = useState(false);
  const showFn = useCallback(() => setShow(true), []);

  const component = useMemo(() => {
    const Component = () => (
      <AddNewGroup parent={parent} show={show} setShow={setShow} />
    );

    return Component;
  }, [parent, show]);

  return [component, showFn] as [() => JSX.Element, () => void];
};

export default useAddNewGroup;
