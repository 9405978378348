import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";

import { NamedTheme, themes } from "@themes";
import { Theme } from "@themes/types";

const INITIAL_THEME =
  themes[
    (new URLSearchParams(location.search).get("theme") as NamedTheme) ??
      import.meta.env.VITE_APP_THEME ??
      "cfa"
  ];

export interface ThemeInterface {
  current: Theme;
  setTheme: (name: NamedTheme) => void;
}

const ThemeContext = createContext<ThemeInterface>({
  current: INITIAL_THEME,
  setTheme: () => null,
});

export const useThemeSwitch = () => useContext(ThemeContext);

export default function ThemeProvider({ children }: { children: ReactNode }) {
  const [current, setCurrent] = useState<Theme>(INITIAL_THEME);

  const setTheme = useCallback(
    (name: NamedTheme) => setCurrent(themes[name]),
    [],
  );

  return (
    <ThemeContext.Provider value={{ current, setTheme }}>
      <StyledComponentsThemeProvider theme={current}>
        {children}
      </StyledComponentsThemeProvider>
    </ThemeContext.Provider>
  );
}
