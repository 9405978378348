import color from "color";
import { forwardRef, useCallback, useMemo } from "react";
import { mergeRefs } from "react-laag";
import styled from "styled-components";

import DS from "@design/system";
import { trackEvent } from "@util/analytics";

import { ButtonProps, ButtonType, ThemeButtonType } from "../button";
import { useTooltip } from "./Tooltip";

export const isThemeButton = (type?: ButtonType): type is ThemeButtonType => {
  return !!type && type !== "transparent";
};

/**
 * Main button component.
 *
 * Button types:
 *  * Default
 *  * Action
 *  * Progressive
 *  * Destructive
 */
const ButtonContainer = styled.div<ButtonProps>`
  box-sizing: border-box;
  width: ${({ stretch }) => (stretch ? "100%" : "auto")};
  margin: 0;
  padding: ${({ fit, size }) =>
    fit === "compact"
      ? size === "small"
        ? `${DS.margins.nano} ${DS.margins.micro}`
        : DS.margins.nano
      : `${DS.margins.micro} ${DS.margins.regular}`};

  cursor: pointer;
  color: ${({ theme, active, buttonType }) =>
    active
      ? theme.palettes.buttons.neutralSelected.foreground
      : isThemeButton(buttonType)
        ? theme.palettes.buttons[buttonType].foreground
        : "currentcolor"};
  line-height: ${({ size }) => (size === "small" ? "12px" : "inherit")};
  font-size: ${({ size }) => (size === "small" ? "12px" : "inherit")};
  font-weight: 600;
  font-style: ${({ disabled }) => (disabled ? "italic" : "normal")};
  text-decoration: none;
  white-space: nowrap;

  border: solid 1px
    ${({ theme, buttonType }) =>
      isThemeButton(buttonType)
        ? theme.palettes.buttons[buttonType].border
        : "transparent"};
  border-radius: ${({ size, shape }) =>
    shape === "square" ? 0 : size === "small" ? DS.radii.pill : DS.radii.item};
  background: ${({ theme, active, buttonType }) =>
    active
      ? theme.palettes.buttons.neutralSelected.background
      : isThemeButton(buttonType)
        ? theme.palettes.buttons[buttonType].background
        : "transparent"};

  box-shadow: ${({ active }) =>
    active ? "inset 0 2px 4px rgba(0, 0, 0, 0.15)" : "none"};

  display: grid;
  grid-auto-flow: column;
  gap: ${DS.margins.nano};
  justify-content: center;
  align-items: center;

  transition: background 200ms;

  &:hover:not(:disabled) {
    background: ${({ theme, active, buttonType }) =>
      active
        ? theme.palettes.buttons.neutralSelected.dim
        : isThemeButton(buttonType)
          ? theme.palettes.buttons[buttonType].dim
          : "rgba(0, 0, 0, 0.1)"};
  }

  &:active:not(:disabled) {
    background: ${({ theme, active, buttonType }) =>
      active
        ? theme.palettes.buttons.neutralSelected.dim
        : isThemeButton(buttonType)
          ? theme.palettes.buttons[buttonType].dim
          : "rgba(0, 0, 0, 0.2)"};
    transform: translate3d(0, 1px, 0);
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme, buttonType }) =>
      isThemeButton(buttonType)
        ? color(theme.palettes.buttons[buttonType].foreground)
            .alpha(0.75)
            .string()
        : "white"};
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(101, 184, 212, 0.5);
  }
`;

const Button = forwardRef<HTMLElement, ButtonProps>(
  (
    { children, title, href, onClick, buttonType = "neutral", ...rest },
    forwardRef,
  ) => {
    const as = useMemo(() => (href ? "a" : "button"), [href]);

    const {
      tooltipProps: { ref, ...tooltipProps },
    } = useTooltip<HTMLButtonElement>(title, { delay: 1000 });

    const onClickTracker = useCallback(
      (e: React.MouseEvent) => {
        trackEvent({
          category: "Button",
          action: title ?? "No Title",
          label: href,
        });

        onClick && onClick(e);
      },
      [href, onClick, title],
    );

    return (
      <ButtonContainer
        as={as}
        ref={mergeRefs(forwardRef, ref)}
        href={href}
        type="button"
        onClick={onClickTracker}
        buttonType={buttonType}
        {...tooltipProps}
        {...rest}
      >
        {children}
      </ButtonContainer>
    );
  },
);

Button.displayName = "Button";

export default Button;
