import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { WizardStepContainer } from "@components/ModalWizardHelper";
import Scroller from "@components/Scroller";
import { AedPads, AedReplaced, Battery } from "@components/WizardIcons";
import WizardOption from "@components/WizardOption";
import { ModalDescription, UnstyledList } from "@design/helpers";
import { ConfirmReplacementForm } from "@util/viewModel";

const SelectReasonStep = () => {
  const { register } = useFormContext<ConfirmReplacementForm>();
  const { t } = useTranslation();

  return (
    <WizardStepContainer>
      <ModalDescription>
        Select the reason(s) replacement is not needed.
      </ModalDescription>
      <Scroller>
        <UnstyledList style={{ padding: "16px 64px" }}>
          <li>
            <WizardOption
              title={`${t("term.aed_one")} Replaced or Updated`}
              description={`This ${t(
                "term.aed_one",
              )} has been replaced with a new ${t(
                "term.aed_one",
              )} and no longer needs replacing.`}
              image={<AedReplaced />}
              value="replaced"
              {...register("selectedReasons")}
            />
          </li>
          <li>
            <WizardOption
              title="Battery Replaced or Updated"
              description={
                "The battery has been replaced or updated and no longer needs replacing."
              }
              image={<Battery />}
              value="battery"
              {...register("selectedReasons")}
            />
          </li>
          <li>
            <WizardOption
              title="Pads Replaced or Updated"
              description={
                "The pads have been replaced or updated and no longer needs replacing."
              }
              image={<AedPads />}
              value="pads"
              {...register("selectedReasons")}
            />
          </li>
          <li>
            <WizardOption
              title="Other"
              description={
                "Other reasons not listed above. You will be asked to specify a reason."
              }
              image={<AedPads />}
              value="other"
              {...register("selectedReasons")}
            />
          </li>
        </UnstyledList>
      </Scroller>
    </WizardStepContainer>
  );
};

export default SelectReasonStep;
