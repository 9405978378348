import { useMemo } from "react";
import ContentLoader from "react-content-loader";

import DS from "@design/system";
import {
  useGroupReadinessReasons,
  useStoreReadinessReasons,
  useUnitReadinessReasons,
} from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";

const getValue = (value: number) => {
  if (value === 0) return "0";
  if (value === 100) return "100";
  return value.toFixed(1);
};

export const ReadinessBannerPlaceholder = () => (
  <ContentLoader width="100%" height={48}>
    <rect x={0} y={0} width="100%" height={48} rx={DS.radii.largeItem} />
  </ContentLoader>
);

const ReadinessBanner = ({
  controllerSerialNumber,
  storeId,
  groupId,
}: {
  storeId?: string;
  groupId?: string;
  controllerSerialNumber?: string;
}) => {
  const { readinessPalette } = useThemeHelper();

  const unitReadiness = useUnitReadinessReasons(controllerSerialNumber);
  const storeReadiness = useStoreReadinessReasons(storeId);
  const groupReadiness = useGroupReadinessReasons(groupId);

  const readiness = useMemo(
    () =>
      controllerSerialNumber
        ? unitReadiness
        : storeId
          ? storeReadiness
          : groupReadiness,

    [
      controllerSerialNumber,
      groupReadiness,
      storeId,
      storeReadiness,
      unitReadiness,
    ],
  );

  if (readiness.isLoading) return <ReadinessBannerPlaceholder />;

  return (
    <div
      title={readiness.reasons?.join(". ") ?? readiness.reason ?? ""}
      style={{
        boxSizing: "border-box",
        height: 48,
        padding: DS.margins.micro,

        cursor: "default",
        textAlign: "center",
        fontSize: 20,

        ...readinessPalette(readiness.percent).toCss(),

        borderRadius: DS.radii.largeItem,

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {readiness.percent > -1 && (
        <span
          style={{
            marginRight: DS.margins.micro,
            fontSize: "1.125em",
            fontWeight: 900,
          }}
        >
          {getValue(readiness.percent)}
          <sup style={{ fontSize: "0.5em" }}>%</sup>
        </span>
      )}
      {readiness.percent === -1 ? "No cabinet installed" : "Ready Rating"}
    </div>
  );
};

export default ReadinessBanner;
