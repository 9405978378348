import {
  ChangeEvent,
  FocusEvent,
  FocusEventHandler,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";

import DS from "@design/system";

import Button from "./Button";
import Icon from "./Icon";

const Container = styled.div`
  position: relative;
`;

const ClearButtonPosition = styled.div<{ variant: "default" | "sidepanel" }>`
  position: absolute;
  top: 50%;
  right: ${({ variant }) =>
    variant === "default" ? DS.margins.nano : DS.margins.micro};
  transform: translateY(-50%);
`;

const SearchIconPosition = styled.div`
  position: absolute;
  top: 50%;
  left: ${DS.margins.micro};
  transform: translateY(-50%);
`;

const Input = styled.input<{ variant: "default" | "sidepanel" }>`
  box-sizing: border-box;
  display: block;
  width: 100%;
  margin: 0;
  padding: ${DS.margins.micro} ${DS.margins.regular};
  padding-left: ${({ variant }) =>
    variant === "default" ? DS.margins.regular : DS.margins.jumbo};

  font-size: 16px;
  color: ${({ theme }) => theme.palettes.form.foreground};

  border: solid 1px;
  border-color: ${({ variant, theme }) =>
    variant === "default"
      ? theme.palettes.form.border
      : theme.palettes.form.dim};
  border-radius: ${({ variant }) =>
    variant === "default" ? DS.radii.pill : DS.radii.item};

  background: ${({ theme, variant }) =>
    variant === "default"
      ? theme.palettes.form.background
      : theme.palettes.form.light};

  &:focus {
    border-color: ${({ variant, theme }) =>
      variant === "default"
        ? theme.palettes.form.foreground
        : theme.palettes.form.accent};
  }

  &::placeholder {
    opacity: 1;
    color: ${({ theme }) => theme.palettes.form.title};
    font-style: italic;
  }
`;

const SearchBar = ({
  variant = "default",
  placeholder,
  onSearch,
  onFocus,
  onBlur,
  value,
}: {
  variant?: "default" | "sidepanel";
  placeholder?: string;
  onSearch: (q: string) => void;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  value: string;
}) => {
  const { t } = useTranslation();

  const searchInputRef = useRef<HTMLInputElement>(null);

  const theme = useTheme();

  useEffect(() => onSearch && onSearch(value), [value, onSearch]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onSearch(e.target.value);
    },
    [onSearch],
  );

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => onFocus && onFocus(e),
    [onFocus],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => onBlur && onBlur(e),
    [onBlur],
  );

  const handleClear = useCallback(() => {
    const el = searchInputRef.current;
    if (!el) return;

    el.focus();

    onSearch("");
  }, [onSearch]);

  return (
    <Container>
      <Input
        ref={searchInputRef}
        variant={variant}
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        type="text"
        name="search"
        placeholder={placeholder ?? t("global.searchPlaceholder")}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      {variant === "default" ? null : (
        <SearchIconPosition>
          <Icon
            name="search"
            color={theme.palettes.form.foreground}
            size={16}
          />
        </SearchIconPosition>
      )}

      {value && (
        <ClearButtonPosition variant={variant}>
          {variant === "default" ? (
            <Button size="small" onClick={handleClear}>
              <Icon name="times" /> {t("common.clearSearch")}
            </Button>
          ) : (
            <Button size="small" fit="compact" onClick={handleClear}>
              <Icon name="times" />
            </Button>
          )}
        </ClearButtonPosition>
      )}
    </Container>
  );
};

export default SearchBar;
