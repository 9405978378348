import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import DS from "@design/system";
import { useGroupReadinessReasons } from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";

import { useTooltip } from "./Tooltip";

const getValue = (value: number) => {
  if (value === 0) return "0";
  if (value === 100) return "100";
  return value.toFixed(1);
};

const GroupReadinessImage = ({
  groupId,
  size = 32,
}: {
  groupId: string;
  size?: number;
}) => {
  const { t } = useTranslation();
  const { readinessPalette } = useThemeHelper();

  const { percent } = useGroupReadinessReasons(groupId);
  const title = useMemo(
    () =>
      percent === -1
        ? `No ${t("term.unit_other").toLowerCase()} installed`
        : `Group readiness at ${getValue(percent)}`,
    [percent, t],
  );

  const { tooltipProps } = useTooltip<HTMLDivElement>(title);

  return (
    <div
      {...tooltipProps}
      aria-label={title}
      style={{
        width: size,
        height: size,
        color: readinessPalette(percent).foreground,
        fontSize: size,
        fontWeight: 700,
        background: readinessPalette(percent).background,
        borderRadius: DS.radii.item,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span style={{ fontSize: "0.4em" }}>
        {percent === -1 ? "--" : getValue(percent)}
      </span>
    </div>
  );
};

export default GroupReadinessImage;
