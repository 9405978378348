import { subDays, formatDistance, formatRelative, isBefore } from "date-fns";

import { DateRange, isDateRange } from "@util/DateRange";

export enum DateFormat {
  /**
   * MM/DD/YYYY or DD/MM/YYYY
   */
  numeric,
  /**
   * MMM/DD/YYYY or DD/MMM/YYYY
   */
  short,
  /**
   * MMMMMM/DD/YYYY or DD/MMMMMM/YYYY
   */
  long,
  /**
   * <number> Days ago
   */
  relative,
  /**
   * <number> Days/Months/Weeks/Years
   */
  distanced,
  /**
   * MMM/DD/YYYY HH:MM
   */
  shortDateTime,
  /**
   * MMM/DD/YYYY HH:MM GMT+HH
   */
  dateTimeAndTimeZone,
  /**
   * MMM/DD/YYYY - MMM/DD/YYYY
   */
  fromDateToDate,
  /**
   * HH:MM
   */
  timeOnly,
}

const getDateFormatOptions = (
  format: DateFormat,
  timeZone?: Intl.DateTimeFormatOptions["timeZone"],
  timeZoneName?: Intl.DateTimeFormatOptions["timeZoneName"],
): Intl.DateTimeFormatOptions => {
  switch (format) {
    case DateFormat.distanced || DateFormat.relative:
      return {};
    case DateFormat.numeric:
      return {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      };
    case DateFormat.short:
      return {
        day: "numeric",
        month: "short",
        year: "numeric",
      };
    case DateFormat.shortDateTime:
      return {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
    case DateFormat.long:
      return {
        day: "numeric",
        month: "long",
        year: "numeric",
      };
    case DateFormat.dateTimeAndTimeZone:
      return {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "2-digit",
        timeZone: timeZone,
        timeZoneName: timeZoneName,
      };
    case DateFormat.timeOnly:
      return {
        hour: "numeric",
        minute: "numeric",
      };

    default:
      return {} as Intl.DateTimeFormatOptions;
  }
};

export function dateFormatter(
  dateTime: Date | string | number | DateRange,
  format: DateFormat,
  timeZone?: Intl.DateTimeFormatOptions["timeZone"],
  timeZoneName?: Intl.DateTimeFormatOptions["timeZoneName"],
) {
  const locale = window.navigator.language;

  if (!dateTime) return "";

  const result = isDateRange(dateTime);
  const start = result ? dateTime.start : new Date(dateTime);
  const end = result ? dateTime.end : new Date();

  const options = getDateFormatOptions(format, timeZone, timeZoneName);

  const relative = format === DateFormat.relative;
  const distanced = format === DateFormat.distanced;

  if (relative) {
    if (isBefore(start, subDays(end, 6)))
      return formatted(end, locale, options);

    return formatRelative(start, end);
  }

  if (distanced) {
    return formatDistance(end, start);
  }

  if (format === DateFormat.fromDateToDate) {
    const options = getDateFormatOptions(DateFormat.short);
    const startDate = formatted(start, locale, options);
    const endDate = formatted(end, locale, options);
    return `${startDate} – ${endDate}`;
  }
  return formatted(start, locale, options);
}

const formatted = (
  date: Date,
  locale: string,
  options: Intl.DateTimeFormatOptions,
) => {
  return new Intl.DateTimeFormat(locale, { ...options }).format(date);
};
