import isPropValid from "@emotion/is-prop-valid";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { ModalProvider } from "react-modal-hook";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { StyleSheetManager } from "styled-components";

import api, { isApiResponseError } from "@api";
import { AuthProvider } from "@auth/auth";
import GlobalStyles from "@design/GlobalStyles";

import App from "./App";
import DevTools from "./DevTools";
import Fallback from "./FallBack";
import Head from "./Head";
import ThemeProvider from "./ThemeProvider";
import { Tooltip } from "./Tooltip";

api.setBaseUrl(import.meta.env.VITE_APP_API_ENDPOINT);

/**
 * Default stale time: 2mins
 */
const STALE_TIME = 1000 * 60 * 2;

const retry = (count: number, response: unknown) => {
  if (
    isApiResponseError(response) &&
    response.error.isXsrfError() &&
    count <= 2
  ) {
    return true;
  }

  return false;
};

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry,
    },
    queries: {
      staleTime: STALE_TIME,
      refetchInterval: STALE_TIME * 2,
      retry,
    },
  },
});

const AppProviders = () => (
  <StyleSheetManager shouldForwardProp={isPropValid}>
    <ThemeProvider>
      <GlobalStyles />

      <ErrorBoundary FallbackComponent={Fallback}>
        <HelmetProvider>
          <Head />

          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <Router basename={import.meta.env.VITE_APP_BASENAME}>
                <ModalProvider>
                  <Switch>
                    <Route>
                      <App />
                    </Route>
                  </Switch>
                </ModalProvider>
              </Router>

              {import.meta.env.VITE_APP_DEBUG_REACT_QUERY === "true" && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
              <DevTools />
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>

        <Tooltip />
      </ErrorBoundary>
    </ThemeProvider>
  </StyleSheetManager>
);

export default AppProviders;
