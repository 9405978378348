import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-hot-toast";

import { UnstyledList } from "@design/helpers";
import DS from "@design/system";

import Button from "../components/Button";
import HighlightChars from "../components/HighlightChars";
import ListItem from "../components/ListItem";
import Modal from "../components/Modal";
import ModalFooter from "../components/ModalFooter";
import ModalHeader from "../components/ModalHeader";
import ModalStandardLayout from "../components/ModalStandardLayout";
import NoResults from "../components/NoResults";
import Scroller from "../components/Scroller";
import SearchBar from "../components/SearchBar";
import Selectable from "../components/Selectable";
import {
  useSearch,
  useStores,
  useStore,
  useGroupsFlat,
  useAddStoresToGroup,
} from "../state/hooks";

const getButtonLabel = (groupIds: string[], loading: boolean) => {
  if (groupIds.length === 1 && loading) {
    return "Adding group to store...";
  } else if (groupIds.length > 1 && loading) {
    return "Adding groups to store...";
  } else if (groupIds.length === 0) {
    return "Select groups to add...";
  } else if (groupIds.length === 1) {
    return "Add group to store";
  } else {
    return "Add groups to store";
  }
};

const getSavedGroupNames = (savedGroup: Api.GroupFlat[]) => {
  const sized = 3;
  const savedGroupNamesList = savedGroup.map((group) => group.Name);
  if (savedGroupNamesList.length === 1) return savedGroupNamesList[0];
  if (savedGroupNamesList.length > sized)
    return savedGroupNamesList.slice(0, sized).join(", ") + "...";

  const firsts = savedGroupNamesList.slice(0, savedGroupNamesList.length - 1);
  const last = savedGroupNamesList[savedGroupNamesList.length - 1];
  return firsts.join(", ") + " and " + last;
};

type AddGroupsToStoreViewModel = { storeIds: string[]; groupsIds: string[] };

const AddGroupToStoreModal = ({
  storeId,
  onClose,
}: {
  storeId?: string;
  onClose: () => void;
}) => {
  const { data: allStores } = useStores();
  const { data: store } = useStore(storeId);
  const { data: groups } = useGroupsFlat();

  const { mutate: addGroupsToStore } = useAddStoresToGroup();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<AddGroupsToStoreViewModel>({
    defaultValues: {
      storeIds: [storeId],
      groupsIds: [],
    },
  });

  const groupIds = useWatch({ control, name: "groupsIds" });

  const {
    results: filteredGroups,
    search,
    q,
    setList: setAddableGroups,
  } = useSearch([] as Api.GroupFlat[], (group) => group.Name);

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const onSubmit: React.FormEventHandler = useCallback(
    (e) =>
      void handleSubmit(async (data: AddGroupsToStoreViewModel) => {
        if (!groups) return;

        const names = getSavedGroupNames(
          groups.filter((group) =>
            data.groupsIds.find((id) => id === group.GroupId),
          ),
        );

        return new Promise<Api.ResponseSuccess<unknown>>((resolve, reject) =>
          addGroupsToStore(data, { onSuccess: resolve, onError: reject }),
        )
          .then(() => {
            onClose();
            toast.success(
              <span>
                <strong>{names}</strong> successfully added.
              </span>,
            );
          })
          .catch(() => {
            onClose();
            toast.error(
              <span>
                Unable to add <strong>{names}</strong>.
              </span>,
            );
          });
      })(e),
    [addGroupsToStore, groups, handleSubmit, onClose],
  );

  useEffect(() => {
    if (!store || !allStores || !groups) {
      setAddableGroups([]);
      return;
    }

    setAddableGroups(
      groups
        .filter((group) => group.Type === "Adhoc")
        .filter((g) => !store.GroupNames.includes(`${g.Name} (${g.Type})`))
        .sort((a, b) => a.Name.localeCompare(b.Name)),
    );
  }, [allStores, groups, setAddableGroups, store]);

  useLayoutEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  return (
    <Modal contentLabel="Add group to store" onClose={onClose}>
      <form onSubmit={onSubmit}>
        <ModalStandardLayout
          header={<ModalHeader title="Add Groups to Store" onClose={onClose} />}
          content={
            <div
              style={{
                display: "grid",
                gridTemplateRows: "auto 1fr",
                gap: DS.margins.regular,
              }}
            >
              <div style={{ marginTop: DS.margins.regular, padding: "0 24px" }}>
                <SearchBar
                  placeholder="Search for groups&hellip;"
                  value={q}
                  onSearch={(q) => search(q)}
                />
              </div>

              <Scroller>
                <div style={{ padding: "0 24px 16px" }}>
                  {filteredGroups && (
                    <Controller
                      control={control}
                      name="groupsIds"
                      render={({ field }) => (
                        <UnstyledList>
                          {filteredGroups.map(({ item: group, positions }) => (
                            <li key={group.GroupId}>
                              <Selectable
                                {...field}
                                label={group.Name}
                                value={group.GroupId}
                                selected={
                                  !!field.value.find(
                                    (groupId) => groupId === group.GroupId,
                                  )
                                }
                                onChange={(e) => {
                                  field.onChange(
                                    e.target.checked
                                      ? field.value.concat(group.GroupId)
                                      : field.value.filter(
                                          (groupId) =>
                                            groupId !== group.GroupId,
                                        ),
                                  );
                                }}
                              >
                                <ListItem
                                  title={
                                    <HighlightChars
                                      str={group.Name}
                                      indices={positions}
                                    />
                                  }
                                  description={group.Name}
                                />
                              </Selectable>
                            </li>
                          ))}
                        </UnstyledList>
                      )}
                    />
                  )}
                  {(!filteredGroups || !filteredGroups.length) && (
                    <NoResults>
                      <p>
                        No groups matching <strong>{q}</strong> found.
                      </p>
                    </NoResults>
                  )}
                </div>
              </Scroller>
            </div>
          }
          footer={
            <ModalFooter>
              <Button
                stretch
                type="submit"
                buttonType="action"
                disabled={groupIds.length === 0 || isSubmitting}
              >
                {getButtonLabel(groupIds, isSubmitting)}
              </Button>
            </ModalFooter>
          }
        />
      </form>
    </Modal>
  );
};

export default AddGroupToStoreModal;
