import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useId, useState } from "react";
import { useLayer } from "react-laag";
import { useTheme } from "styled-components";

import DS from "@design/system";

import { ButtonProps } from "../button";
import Button from "./Button";
import {
  ContextMenuItem,
  isContextMenuButton,
  isContextMenuLink,
  MenuButton,
  Separator,
} from "./ContextMenu";
import Icon from "./Icon";

const MultiButton = ({
  items,
  children,
  ...rest
}: ButtonProps & {
  items: ContextMenuItem[];
}) => {
  const { palettes } = useTheme();

  const id = useId();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => setIsOpen(false), []);

  const handleClick = useCallback(() => {
    setIsOpen((open) => !open);
  }, []);

  const { renderLayer, triggerProps, triggerBounds, layerProps, layerSide } =
    useLayer({
      isOpen,
      onOutsideClick: handleClose,
      overflowContainer: false,
      auto: true,
      placement: "bottom-start",
      possiblePlacements: ["bottom-start", "top-start"],
      triggerOffset: 0,
      containerOffset: 0,
      arrowOffset: 0,
    });

  return (
    <>
      <Button
        {...triggerProps}
        onClick={handleClick}
        style={{
          position: "relative",
          ...(isOpen && {
            background:
              !rest.buttonType || rest.buttonType === "neutral"
                ? palettes.buttons.neutral.background
                : palettes.buttons.action.background,
          }),

          ...(isOpen &&
            layerSide === "bottom" && {
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }),

          ...(isOpen &&
            layerSide === "top" && {
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
            }),
        }}
        aria-haspopup
        aria-expanded={isOpen}
        aria-controls={id}
        {...rest}
      >
        {children}
        <div style={{ position: "absolute", top: 10, right: 16 }}>
          <Icon name="caret-down" />
        </div>
      </Button>

      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <motion.ul
              id={id}
              {...layerProps}
              style={{
                ...layerProps.style,
                boxSizing: "border-box",
                width: triggerBounds?.width,
                margin: 0,
                padding: 0,

                listStyle: "none",

                borderWidth: 1,
                borderStyle: "solid",
                borderColor: palettes.body.border,

                ...(layerSide === "top" && {
                  borderWidth: "1px 1px 0",
                  borderTopRightRadius: DS.radii.item,
                  borderTopLeftRadius: DS.radii.item,

                  transformOrigin: "bottom",
                }),

                ...(layerSide === "bottom" && {
                  borderWidth: "0 1px 1px",
                  borderBottomRightRadius: DS.radii.item,
                  borderBottomLeftRadius: DS.radii.item,

                  transformOrigin: "top",
                }),

                background: palettes.body.background,
                boxShadow: DS.shadows.dialog,
              }}
              initial={{ opacity: 0, scaleY: 0.75 }}
              animate={{ opacity: 1, scaleY: 1 }}
              exit={{ opacity: 0, scaleY: 0.75 }}
              transition={{ duration: 0.1 }}
            >
              {items.map((item, index) =>
                item ? (
                  <li key={item.key}>
                    <MenuButton
                      as={isContextMenuButton(item) ? "button" : "a"}
                      {...(isContextMenuLink(item) && { href: item.href })}
                      {...(isContextMenuButton(item) && {
                        onClick: () => item.onClick(() => setIsOpen(false)),
                      })}
                    >
                      {item.icon && <Icon name={item.icon} />}
                      {item.label}
                    </MenuButton>
                  </li>
                ) : (
                  <Separator key={`separator-${index}`} />
                ),
              )}
            </motion.ul>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};

export default MultiButton;
