import { QRCodeSVG } from "qrcode.react";
import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useTheme } from "styled-components";

import IconButton from "@components/IconButton";
import { WizardStepContainer } from "@components/ModalWizardHelper";
import WizardOptionSeparator from "@components/WizardOptionSeparator";
import { ModalDescription } from "@design/helpers";
import DS from "@design/system";
import { useMfaSecret, useUserFull } from "@state/hooks";

const MfaQRCode = ({ userId }: { userId: string }) => {
  const { palettes } = useTheme();

  const { data: user } = useUserFull(userId);
  const { data: mfaSecret } = useMfaSecret(userId);

  const qrCode = useMemo(() => {
    return `otpauth://totp/${import.meta.env.VITE_APP_MFA_ISSUER}:${
      user?.Email || ""
    }?secret=${mfaSecret ?? ""}&issuer=${import.meta.env.VITE_APP_MFA_ISSUER}`;
  }, [mfaSecret, user?.Email]);

  const handleCopy = useCallback(() => {
    if (!mfaSecret) return;

    navigator.clipboard.writeText(mfaSecret).then(
      () => toast.success("Code copied to clipboard"),
      () => toast.error("Could not copy code to clipboard"),
    );
  }, [mfaSecret]);

  return (
    <>
      <WizardStepContainer>
        <ModalDescription>
          Scan this QR code to verify your device
        </ModalDescription>

        <div
          style={{
            padding: "0 64px",
            display: "grid",
            gap: 8,
            alignContent: "center",
          }}
        >
          <div
            style={{
              padding: DS.margins.regular,

              border: `1px solid ${palettes.body.border}`,
              borderRadius: DS.radii.largeItem,

              // White to match the QR code background
              background: "white",

              display: "grid",
              justifySelf: "center",
            }}
          >
            <QRCodeSVG value={qrCode} size={128} />
          </div>

          <WizardOptionSeparator>Or, use the code below</WizardOptionSeparator>

          <code
            style={{
              justifySelf: "center",
              padding: `${DS.margins.nano} ${DS.margins.jumbo}`,
              color: palettes.well.small,

              borderRadius: DS.radii.largeItem,
              background: palettes.well.background,

              display: "grid",
              gridAutoFlow: "column",
              gap: DS.margins.nano,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {mfaSecret && <span title="MFA secret">{mfaSecret}</span>}

            <IconButton
              title="Copy to clipboard"
              size={"small"}
              buttonType="transparent"
              icon={"copy"}
              onClick={handleCopy}
            />
          </code>
        </div>
      </WizardStepContainer>
    </>
  );
};

export default MfaQRCode;
