import { useCallback, useEffect, useState } from "react";
import { useLayer, Arrow } from "react-laag";
import styled, { useTheme } from "styled-components";
import zxcvbn from "zxcvbn";

import { SmallText, StrongText, UnstyledList } from "@design/helpers";
import DS from "@design/system";
import { Palettes } from "@themes/types";

import { LinkButton } from "../components/SecondaryButton";

const Bar = styled.div<{ barColor: string; lit: boolean }>`
  height: 4px;

  background: ${({ theme, barColor, lit }) =>
    lit ? barColor : theme.palettes.body.dim};
  border-radius: 2px;
`;

const getColor = (palettes: Palettes, score: number) => {
  switch (score) {
    default:
    case -1:
      return palettes.states.na.background;
    case 0:
    case 1:
      return palettes.states.bad.background;
    case 2:
      return palettes.states.warning.background;
    case 3:
    case 4:
      return palettes.states.good.background;
  }
};

const strengthLabel = (strength: number) => {
  switch (strength) {
    case -1:
      return "No password set";
    case 0:
      return "Very weak";
    case 1:
      return "Weak";
    case 2:
      return "Nearly there";
    case 3:
      return "Secure";
    case 4:
      return "Very secure";
  }
};

const strengthLabelLower = (strength: number) => {
  switch (strength) {
    case 0:
      return "Your password is very weak.";
    case 1:
      return "Your password is quite weak.";
    case 2:
      return "Your password is nearly there, however...";
    default:
      return ".";
  }
};

const PasswordStrengthIndicator = ({ password }: { password?: string }) => {
  const { palettes } = useTheme();

  const [score, setScore] = useState<number>(-1);
  const [feedback, setFeedback] = useState<string>();
  const [help, setHelp] = useState<string[]>([]);
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [color, setColor] = useState<string>(getColor(palettes, score));

  const handleWhatsWrongClick = useCallback(
    () => setShowHelp(!showHelp),
    [showHelp],
  );

  const handleOutsideClick = useCallback(() => setShowHelp(false), []);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen: showHelp,
    onOutsideClick: handleOutsideClick,
    triggerOffset: DS.margins.microN,
    placement: "bottom-center",
    possiblePlacements: ["top-center", "bottom-center"],
  });

  useEffect(() => {
    if (!password) {
      setScore(-1);
      setFeedback(undefined);
      setHelp([]);
      setColor(getColor(palettes, -1));
      return;
    }

    const { score, feedback } = zxcvbn(password);

    setScore(score);
    setFeedback(feedback.warning);
    setHelp(feedback.suggestions);
    setColor(getColor(palettes, score));

    if (score > 3) setShowHelp(false);
  }, [password, palettes]);

  return (
    <>
      <div role="presentation" {...triggerProps}>
        <div
          style={{
            marginTop: 4,
            display: "grid",
            gridAutoFlow: "column",
            gap: 2,
          }}
        >
          <Bar barColor={color} lit={score >= 0} />
          <Bar barColor={color} lit={score >= 1} />
          <Bar barColor={color} lit={score >= 2} />
          <Bar barColor={color} lit={score >= 3} />
          <Bar barColor={color} lit={score >= 4} />
        </div>
        <div
          style={{
            display: "grid",
            gridAutoFlow: "column",
            justifyContent: "space-between",
          }}
        >
          <SmallText style={{ padding: DS.margins.nano, color }}>
            {strengthLabel(score)}
          </SmallText>
          {(feedback || help.length > 0) && (
            <SmallText>
              <LinkButton tabIndex={-1} onClick={handleWhatsWrongClick}>
                What&apos;s wrong with my password?
              </LinkButton>
            </SmallText>
          )}
        </div>
      </div>

      {(feedback || help.length > 0) &&
        showHelp &&
        renderLayer(
          <div
            {...layerProps}
            style={{
              ...layerProps.style,
              width: 272 - 32,
              padding: DS.margins.regular,
              background: palettes.body.background,
              border: `solid 1px ${palettes.body.border}`,
              borderRadius: DS.radii.largeItem,
              boxShadow: DS.shadows.dialog,
              zIndex: 2,
            }}
          >
            <div>
              <StrongText>{strengthLabelLower(score)}</StrongText>

              <p>{feedback}</p>

              <UnstyledList>
                {help.map((h, i) => (
                  <li key={`note-${i}`}>{h}</li>
                ))}
              </UnstyledList>
            </div>
            {/* @ts-expect-error Incorrect type definition in react-laag */}
            <Arrow
              {...arrowProps}
              backgroundColor={palettes.body.background}
              borderColor={palettes.body.border}
              borderWidth={1}
              roundness={1}
              size={DS.margins.microN}
            />
          </div>,
        )}
    </>
  );
};

export default PasswordStrengthIndicator;
