import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { useGroup, useStoresInGroup, useUsers } from "../../state/hooks";
import { AddUserForm } from "../../util/viewModel";

const getNames = (users: Api.User[]) => {
  if (!users) return "";

  return users.map((user, i) =>
    i === users.length - 2 ? (
      <>
        <strong>{user.FullName}</strong> and{" "}
      </>
    ) : i === users.length - 1 ? (
      <>
        <strong>{user.FullName}</strong>
      </>
    ) : (
      <>
        <strong>{user.FullName}</strong>,{" "}
      </>
    ),
  );
};

const ReviewAddToGroupTab = () => {
  const { control } = useFormContext<AddUserForm>();

  const [userIds, groupsIds] = useWatch({
    control,
    name: ["userIds", "groupsIds"],
  });

  const { data: users } = useUsers();
  const { data: group } = useGroup(groupsIds[0]);
  const { data: stores } = useStoresInGroup(groupsIds[0], true);

  const [selectedUsers, setSelectedUsers] = useState<Api.User[]>([]);

  useEffect(() => {
    if (!users) return setSelectedUsers([]);
    setSelectedUsers(users.filter((user) => userIds.includes(user.UserId)));
  }, [userIds, users]);

  return (
    <div>
      {group?.Type === "SingleStore" ? (
        <>
          {getNames(selectedUsers)} will be added to{" "}
          <strong>{group?.Name}</strong>.
        </>
      ) : !stores || stores?.length === 0 ? (
        <>
          <p>
            {getNames(selectedUsers)} will be added to{" "}
            <strong>{group?.Name}</strong>.
          </p>
          <p>
            No additional access will be granted as the group currently has no
            stores.
          </p>
        </>
      ) : (
        <>
          {getNames(selectedUsers)} will be added to{" "}
          <strong>{group?.Name}</strong>, giving them additional access to these
          stores:
          <ul>
            {stores?.map((store) => <li key={store.StoreId}>{store.Name}</li>)}
          </ul>
        </>
      )}
    </div>
  );
};

export default ReviewAddToGroupTab;
