import rg4js from "raygun4js";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { SessionState, useAuth } from "@auth/auth";

if (import.meta.env.VITE_APP_RAYGUN_ENABLED === "true") {
  rg4js("setVersion", import.meta.env.VITE_APP_VERSION ?? "none");
  rg4js("apiKey", import.meta.env.VITE_APP_RAYGUN_API_KEY);
  rg4js("withTags", [`env:${import.meta.env.VITE_APP_ENV_NAME}`]);

  rg4js(
    "enableCrashReporting",
    import.meta.env.VITE_APP_RAYGUN_CRASH_ENABLED === "true",
  );
  rg4js(
    "enablePulse",
    import.meta.env.VITE_APP_RAYGUN_PULSE_ENABLED === "true",
  );
}

if (
  import.meta.env.VITE_APP_RAYGUN_ENABLED === "false" ||
  import.meta.env.VITE_APP_RAYGUN_BREADCRUMBS_ENABLED === "false"
) {
  rg4js("disableAutoBreadcrumbsConsole");
}

export const useCrashReporter = () => {
  const location = useLocation();
  const { session } = useAuth();

  useEffect(() => {
    if (import.meta.env.VITE_APP_RAYGUN_ENABLED === "false") return;
    rg4js("trackEvent", { type: "pageView", path: location.pathname });
  }, [location]);

  useEffect(() => {
    if (import.meta.env.VITE_APP_RAYGUN_ENABLED === "false") return;
    if (session.state === SessionState.Authenticated && session.user) {
      const { user } = session;

      rg4js("setUser", {
        identifier: user.userId,
        isAnonymous: false,
        email: user.email,
        firstName: user.firstName,
        fullName: `${user.firstName} ${user.lastName}`,
      });
    } else if (session.state === SessionState.None) {
      rg4js("endSession");
    } else {
      rg4js("setUser", {
        isAnonymous: true,
      });
    }
  }, [session]);

  const visit = useCallback((path: string) => {
    if (import.meta.env.VITE_APP_RAYGUN_ENABLED === "false") return;
    rg4js("trackEvent", { type: "pageView", path });
  }, []);

  const end = useCallback(() => {
    if (import.meta.env.VITE_APP_RAYGUN_ENABLED === "false") return;
    rg4js("endSession");
  }, []);

  return {
    /**
     * All URL changes are already tracked. Use this to add a custom change.
     */
    visit,

    /**
     * Ends the current session. Should be called on log out.
     */
    end,
  };
};

export default useCrashReporter;
