import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";

import { MediumText, SmallText, Well } from "@design/helpers";
import DS from "@design/system";
import {
  useCountryCodes,
  useCurrentUser,
  useGroupsFlat,
  useHasPermission,
  useRoles,
} from "@state/hooks";

import {
  LabelledInput,
  LabelledInputPlaceholder,
  LabelledSelect,
} from "../FormControls";

const ControlsContainer = styled.div`
  display: grid;
  gap: 8px;
  align-content: flex-start;
`;

const GeneralTab = ({
  loading = false,
  isCurrentUser,
}: {
  loading?: boolean;
  isCurrentUser?: boolean;
}) => {
  const { t } = useTranslation();
  const { data: currentUser } = useCurrentUser();
  const { hasPermission } = useHasPermission();

  const { links } = useTheme();

  const { data: roles } = useRoles();

  const { data: countryCodes } = useCountryCodes();

  const [countryCodeOptions, setCountryCodeOptions] = useState<
    {
      value: number;
      label: string;
    }[]
  >([]);

  useEffect(() => {
    if (!countryCodes) {
      setCountryCodeOptions([]);
      return;
    }

    const optionsCountryCode = countryCodes?.CountryCodes.map((country) => ({
      value: country.Key,
      label: country.Name,
    }));

    setCountryCodeOptions(optionsCountryCode);
  }, [countryCodes]);

  const { control, register, formState, watch } = useFormContext<Api.User>();

  const groupsList = useGroupsFlat();

  const franchises = useMemo(
    () =>
      groupsList.data
        ? groupsList.data.filter((group) => group.Type === "Franchise")
        : [],
    [groupsList.data],
  );

  const [roleOptions, setRoleOptions] = useState<
    {
      value: string;
      label: string;
      disabled?: boolean;
    }[]
  >([]);

  useEffect(() => {
    if (!roles || !currentUser) {
      setRoleOptions([]);
      return;
    }

    const options = roles
      .filter((role) => {
        // Only Admin can only create Admin and Consultants
        if (role.Name === "Admin" || role.Name === "Consultant")
          return currentUser.roles[0].name === "Admin";

        // Only if a franchise exists a Admin or Owner can create an Owner
        if (role.Name === "Owner")
          return (
            (currentUser.roles[0].name === "Admin" && franchises.length > 0) ||
            (currentUser.roles[0].name === "Owner" && franchises.length > 0)
          );

        return true;
      })
      .map((role) => ({
        value: role.id,
        label: role.DisplayName,
      }));

    setRoleOptions(options);
  }, [t, roles, currentUser, franchises.length]);

  const selectedUserRoleArray = watch("Roles");
  const currentRole = selectedUserRoleArray?.[0];

  const editable = useMemo(() => {
    return isCurrentUser || hasPermission("users_create_edit_delete_user");
  }, [hasPermission, isCurrentUser]);

  return (
    <ControlsContainer>
      {loading ? (
        <>
          <LabelledInputPlaceholder label="Full name" />
          <LabelledInputPlaceholder label="Email address" />

          <div
            style={{
              display: "grid",

              gridAutoFlow: "column",
              gap: DS.margins.micro,
              gridTemplateColumns: "1fr",
            }}
          >
            <LabelledInputPlaceholder label="Country" />
            <LabelledInputPlaceholder label="Mobile phone number (Optional)" />
          </div>
          {!isCurrentUser && <LabelledInputPlaceholder label="Role" />}
        </>
      ) : (
        <>
          <LabelledInput
            label="Full name"
            disabled={!editable}
            hasError={!!formState.errors.FullName}
            error={formState.errors?.FullName?.message}
            {...register("FullName", { required: true })}
          />
          <LabelledInput
            label="Email address"
            type="email"
            disabled={!editable}
            hasError={!!formState.errors?.Email}
            error={formState.errors?.Email?.message}
            {...register("Email", {
              required: true,
              pattern: /\S+@\S+\.\S+/,
            })}
          />
          <div
            style={{
              display: "grid",

              gridAutoFlow: "column",
              gap: DS.margins.micro,
              gridTemplateColumns: "1fr",
            }}
          >
            <Controller
              control={control}
              name="MobilePhoneCountryCode"
              render={({ field }) => (
                <LabelledSelect
                  {...field}
                  label="Country"
                  disabled={!editable}
                  placeholder="Select your country code"
                  options={countryCodeOptions}
                  value={field.value}
                  {...register("MobilePhoneCountryCode", {
                    required: true,
                  })}
                />
              )}
            />

            <LabelledInput
              label="Mobile phone number (Optional)"
              type="tel"
              disabled={!editable}
              hasError={!!formState.errors?.MobilePhoneNumber}
              error={formState.errors?.MobilePhoneNumber?.message}
              {...register("MobilePhoneNumber", {
                pattern: /[0-9]/,
              })}
            />
          </div>
          {!isCurrentUser && (
            <Controller
              control={control}
              name="Roles"
              render={({ field }) => (
                <LabelledSelect
                  {...field}
                  label="Role"
                  placeholder="Select a role..."
                  disabled={!editable}
                  options={roleOptions}
                  value={
                    field.value && field.value[0]
                      ? field.value[0].id
                      : undefined
                  }
                  onChange={(e) =>
                    field.onChange(
                      roles?.filter((role) => role.id === e.target.value) ?? [],
                    )
                  }
                />
              )}
            />
          )}

          {currentRole && (
            <Well>
              <MediumText style={{ marginBottom: DS.margins.nano }}>
                <strong>Role: {currentRole.DisplayName}</strong>
              </MediumText>
              <SmallText style={{ whiteSpace: "pre-wrap" }}>
                {currentRole.Description}
              </SmallText>
              {isCurrentUser && currentRole.Name !== "Admin" && (
                <SmallText style={{ marginTop: "4px" }}>
                  To request a role change, please contact {links.supportEmail}
                </SmallText>
              )}
            </Well>
          )}
        </>
      )}
    </ControlsContainer>
  );
};

export default GeneralTab;
