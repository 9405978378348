import { AnimatePresence, useReducedMotion } from "framer-motion";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm, UseFormSetError } from "react-hook-form";
import toast from "react-hot-toast";

import {
  AddUsersToGroupsResponse,
  ApiResponseError,
  isApiResponseError,
} from "@api";
import BorderBox from "@components/BorderBox";
import Button from "@components/Button";
import MediaCard from "@components/MediaCard";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import ModalStandardLayout from "@components/ModalStandardLayout";
import {
  Overlay,
  Path,
  Step,
  WizardProvider,
  useWizard,
} from "@components/ModalWizardHelper";
import Scroller from "@components/Scroller";
import WizardSteps from "@components/WizardSteps";
import AccessTab from "@components/people/AccessTab";
import AccessTabFooter from "@components/people/AccessTabFooter";
import AccountCreationFailedTab from "@components/people/AccountCreationFailedTab";
import AccountCreationFailedTabFooter from "@components/people/AccountCreationFailedTabFooter";
import GeneralTab from "@components/people/GeneralTab";
import GeneralTabFooter from "@components/people/GeneralTabFooter";
import NotificationsTab from "@components/people/NotificationsTab";
import NotificationsTabFooter from "@components/people/NotificationsTabFooter";
import ReviewAddToGroupTab from "@components/people/ReviewAddToGroupTab";
import ReviewAddToGroupTabFooter from "@components/people/ReviewAddToGroupTabFooter";
import ReviewTab from "@components/people/ReviewTab";
import ReviewTabFooter from "@components/people/ReviewTabFooter";
import SelectUsersTab from "@components/people/SelectUsersTab";
import SelectUsersTabFooter from "@components/people/SelectUsersTabFooter";
import DS from "@design/system";
import { numberToWord } from "@lang/lang";
import {
  GroupAndOrStoreKey,
  useAddUsersToGroup,
  useCurrentUser,
  useDefaultUserAlerts,
  useGroup,
  useGroupFlat,
  useMutateUser,
  useStores,
} from "@state/hooks";
import { BackForwardCard, Direction } from "@util/animations";
import {
  AddUserForm,
  LastResponseContext,
  SelectedGroupContext,
} from "@util/viewModel";

const isAddUserResponse = (
  result: Api.User | AddUsersToGroupsResponse,
): result is Api.User => (result as Api.User).UserId !== undefined;

const applyErrorsToForm = (
  setError: UseFormSetError<AddUserForm>,
  errors: Api.ErrorDefinition<Api.UserValidationError>[],
) => {
  for (const error of errors) {
    switch (error.ErrorKey) {
      case "duplicated_email":
        setError("Email", {
          type: "manual",
          message: "Email address already in use.",
        });
        break;
      case "duplicated_phone":
        setError("MobilePhoneNumber", {
          type: "manual",
          message: "Mobile phone number already in use.",
        });
    }
  }
};

const getStoreList = ({
  allStores,
  group,
}: {
  allStores?: Api.Store[];
  group?: Api.TreeGroup;
}) => {
  if (!group) return "...";

  if (group.Type === "SingleStore") {
    if (!allStores) return "...";
    const store = allStores.find((s) => s.SingleStoreGroupID === group.GroupId);

    if (!store) return "...";
    return store.StreetAddress.AsOneLine;
  }

  // if (group.Type === "SingleStore") return group.StreetAddress.AsOneLine;
  const stores = group.Stores;

  if (!stores) return "...";
  if (stores.length === 0) return "No stores";
  if (stores.length === 1) return stores[0].Name;
  if (stores.length === 2) return `${stores[0].Name} and ${stores[1].Name}`;

  return `${stores[0].Name} and ${numberToWord(stores.length - 1)} others`;
};

const AddUserWizardModal = ({
  mutationKeys,
  groupId,
  onClose,
}: {
  mutationKeys?: GroupAndOrStoreKey;
  groupId?: string;
  onClose: () => void;
}) => {
  const shouldReduceMotion = useReducedMotion();
  const { data: current } = useCurrentUser();

  useDefaultUserAlerts();
  const { mutate: createUser } = useMutateUser(mutationKeys);
  const { mutate: addUsersToGroup } = useAddUsersToGroup();
  const { data: allStores } = useStores();
  const { data: group } = useGroup(groupId);
  const { data: groupFlat } = useGroupFlat(groupId);

  const [direction, setDirection] = useState<Direction>("forward");
  const [newOrExistingUsers, setNewOrExistingUsers] = useState<
    "new" | "existing"
  >(groupId ? "existing" : "new");
  const [attemptCancel, setAttemptCancel] = useState(false);
  const [lastResponse, setLastResponse] =
    useState<ApiResponseError<Api.UserValidationError> | null>(null);

  const {
    control,
    reset,
    watch,
    formState,
    setError,
    handleSubmit,
    ...methods
  } = useForm<AddUserForm>({
    defaultValues: {
      CompanyId: current?.company.companyId,
      FullName: "",
      Email: "",
      Roles: [],
      Alerts: [],
      Groups: groupFlat ? [groupFlat] : [],
      SendWelcomeEmail: true,
      SendWelcomeEmailLater: true,
      TargetPlatform: 4,
      MobilePhoneNumber: 0,
      PdfReportSkipIfEmpty: true,
      userIds: [],
      groupsIds: [groupId],
    },
  });

  const steps: Step[] = useMemo(
    () => [
      {
        name: "selectUsers",
        title: "Select People",
        Content: <SelectUsersTab />,
        Footer: <SelectUsersTabFooter />,
      },

      // New User
      {
        name: "details",
        title: "Person's Details",
        backLabel: "Back to select people",
        Content: <GeneralTab />,
        Footer: <GeneralTabFooter />,
      },

      {
        name: "notifications",
        title: "Notifications and Alerts",
        backLabel: "Back to general details",
        Content: <NotificationsTab />,
        Footer: <NotificationsTabFooter />,
      },

      {
        name: "access",
        title: "Access",
        backLabel: "Back to notifications and alerts",
        Content: <AccessTab />,
        Footer: <AccessTabFooter />,
      },

      {
        name: "review",
        title: "Review and Confirm",
        backLabel: "Back to access",
        Content: <ReviewTab />,
        Footer: <ReviewTabFooter />,
      },

      {
        name: "error",
        title: "Account Creation Failed",
        backLabel: "Back to access",
        Content: <AccountCreationFailedTab />,
        Footer: <AccountCreationFailedTabFooter />,
      },

      //
      // Existing User
      {
        name: "addToGroupReview",
        title: "Review and Confirm",
        backLabel: "Back to person selection",
        Content: <ReviewAddToGroupTab />,
        Footer: <ReviewAddToGroupTabFooter />,
      },
      {
        name: "addToGroupError",
        title: "Add to Group Failed",
        backLabel: "Back to person selection",
        Content: <AccountCreationFailedTab />,
        Footer: <AccountCreationFailedTabFooter />,
      },
    ],
    [],
  );

  const path: Path = useMemo(
    () =>
      groupId
        ? [
            "selectUsers",
            {
              newUser: [
                "details",
                "notifications",
                "access",
                {
                  review: ["review"],
                  error: ["error"],
                },
              ],
              addToGroupReview: ["addToGroupReview"],
              addToGroupError: ["addToGroupError"],
            },
          ]
        : [
            "details",
            "notifications",
            "access",
            { review: ["review"], error: ["error"] },
          ],
    [groupId],
  );

  const { step, count, previous, goTo, ...rest } = useWizard(path, {
    onStepChange: (step, direction) => {
      if (step === "details") {
        setNewOrExistingUsers("new");
      } else if (step === "selectUsers") {
        setNewOrExistingUsers("existing");
      }

      setDirection(direction);
    },
  });

  const currentStep = useMemo(
    () => steps.find((s) => s.name === step.name) ?? steps[0],
    [step, steps],
  );

  const userFormData = watch();

  const handleBack = useCallback(() => {
    previous();
  }, [previous]);

  const handleCloseAttempt = useCallback(() => {
    formState.isDirty ? setAttemptCancel(true) : onClose();
  }, [formState.isDirty, onClose]);

  const onSubmit: React.FormEventHandler = useCallback(
    (e) =>
      void handleSubmit(async (user: AddUserForm) => {
        if (!user.MobilePhoneNumber) {
          user.MobilePhoneNumber = 0;
        }

        return new Promise<
          Api.ResponseSuccess<Api.User | AddUsersToGroupsResponse>
        >((resolve, reject) =>
          newOrExistingUsers === "new"
            ? createUser(user, { onSuccess: resolve, onError: reject })
            : addUsersToGroup(user, { onSuccess: resolve, onError: reject }),
        )
          .then((response) => response.SuccessPayload)
          .then((result) => {
            onClose();
            if (isAddUserResponse(result)) {
              toast.success(
                <span>
                  Account for <strong>{result.FullName}</strong> successfully
                  created.
                </span>,
              );
            } else {
              toast.success(
                <span>
                  People successfully added to <strong>{group?.Name}</strong>
                </span>,
              );
            }
          })
          .catch((error) => {
            if (isApiResponseError<Api.UserValidationError>(error)) {
              applyErrorsToForm(setError, error.error.all);
              setLastResponse(error);

              if (newOrExistingUsers === "new") {
                goTo("error");
              } else {
                goTo("addToGroupError");
              }
            }
          });
      })(e),
    [
      handleSubmit,
      newOrExistingUsers,
      createUser,
      addUsersToGroup,
      onClose,
      group,
      setError,
      goTo,
    ],
  );

  if (!currentStep || !direction) return null;

  return (
    <Modal contentLabel="Add person dialog" onClose={handleCloseAttempt}>
      <FormProvider
        {...{
          control,
          reset,
          watch,
          formState,
          setError,
          handleSubmit,
          ...methods,
        }}
      >
        <WizardProvider {...{ step, count, goTo, previous, ...rest }}>
          <LastResponseContext.Provider value={lastResponse}>
            <form onSubmit={onSubmit}>
              <ModalStandardLayout
                header={
                  <div style={{ position: "relative" }}>
                    <ModalHeader
                      title={currentStep.title}
                      backLabel={
                        step.number !== 1 ? currentStep.backLabel : null
                      }
                      onBack={handleBack}
                      onClose={handleCloseAttempt}
                    />
                    <Overlay show={attemptCancel} />
                  </div>
                }
                content={
                  <div
                    style={{
                      position: "relative",
                      display: "grid",
                      gridTemplateRows: "auto 1fr",
                      gap: DS.margins.regular,
                    }}
                  >
                    <BorderBox style={{ margin: "0 24px" }}>
                      {newOrExistingUsers === "new" ? (
                        <MediaCard
                          title={userFormData.FullName || <em>Full name</em>}
                          description={userFormData.Email || <em>Email</em>}
                          descriptionExt={
                            userFormData.Roles[0]?.DisplayName || <em>Role</em>
                          }
                        />
                      ) : (
                        <MediaCard
                          title={group?.Name}
                          description={getStoreList({ allStores, group })}
                        />
                      )}
                    </BorderBox>

                    <div style={{ position: "relative" }}>
                      <AnimatePresence
                        initial={false}
                        custom={{
                          direction,
                          reduceMotion: shouldReduceMotion,
                        }}
                      >
                        <BackForwardCard
                          key={currentStep.name}
                          custom={{
                            direction,
                            reduceMotion: shouldReduceMotion,
                          }}
                        >
                          <Scroller>
                            <div style={{ padding: "0 24px 16px" }}>
                              <SelectedGroupContext.Provider
                                value={{ groupId }}
                              >
                                {currentStep.Content}
                              </SelectedGroupContext.Provider>
                            </div>
                          </Scroller>
                        </BackForwardCard>
                      </AnimatePresence>
                    </div>
                    <Overlay show={attemptCancel} />
                  </div>
                }
                footer={
                  <ModalFooter>
                    {attemptCancel ? (
                      <>
                        <p>
                          You have <strong>unsaved changes</strong>. If you
                          leave now, these changes will be lost.
                        </p>
                        <p>Are you sure you want to leave now?</p>
                        <Button onClick={() => setAttemptCancel(false)}>
                          No, I&apos;m not finished.
                        </Button>
                        <Button buttonType="destructive" onClick={onClose}>
                          Yes, leave now.
                        </Button>
                      </>
                    ) : (
                      currentStep.Footer
                    )}
                    <WizardSteps steps={count} current={step.number} />
                  </ModalFooter>
                }
              />
            </form>
          </LastResponseContext.Provider>
        </WizardProvider>
      </FormProvider>
    </Modal>
  );
};

export default AddUserWizardModal;
