import {
  useQuery,
  useQueryClient,
  useMutation,
  QueryFunctionContext,
  useQueries,
  UseQueryResult,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { Fzf, FzfResultItem } from "fzf";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "styled-components";

import api, {
  AddUsersToGroupsResponse,
  RemoveUsersFromGroupsResponse,
  GetByGroupOrStore,
  GetIncidentsOptions,
  GetByPagination,
  GetIncident,
  ApiResponseError,
  GetShipmentsOptions,
  GetByStoresIds,
  GetDashboardOptions,
  GetByDateRange,
} from "@api";
import { useAuth } from "@auth/auth";
import { trackEvent } from "@util/analytics";
import {
  findInTree,
  flattenTree,
  removeDuplicates,
  removeFromTree,
} from "@util/data";
import {
  getStartAndEndDatesFromNumberOfDays,
  trimStartAndEndDates,
} from "@util/dates";

const keys = {
  users: {
    all: [{ scope: "users" }] as const,
    list: (companyId: string) =>
      [{ ...keys.users.all[0], entity: "list", companyId }] as const,
    inStore: (companyId: string, storeId: string) =>
      [
        { ...keys.users.list(companyId)[0], filter: "inStore", storeId },
      ] as const,
    inGroup: (companyId: string, groupId: string) =>
      [
        { ...keys.users.list(companyId)[0], filter: "inGroup", groupId },
      ] as const,
    inAllStores: (companyId: string) =>
      [{ ...keys.users.list(companyId)[0], filter: "inAllStores" }] as const,
    in: (companyId: string, options: GetByGroupOrStore) =>
      [{ ...keys.users.list(companyId)[0], filter: "in", ...options }] as const,
    detail: (userId: string) =>
      [{ ...keys.users.all[0], entity: "detail", userId }] as const,
  },
  stores: {
    all: [{ scope: "stores" }] as const,
    list: (filter?: GetByStoresIds) =>
      [{ ...keys.stores.all[0], filter, entity: "list" }] as const,
    detail: (storeId: string) => [{ ...keys.stores.all[0], storeId }] as const,
  },
  groups: {
    all: [{ scope: "groups" }] as const,
    list: (companyId: string) =>
      [{ ...keys.groups.all[0], entity: "list", companyId }] as const,
    tree: (companyId: string) =>
      [{ ...keys.groups.all[0], entity: "tree", companyId }] as const,
    treeWithStoreCount: (companyId: string) =>
      [
        { ...keys.groups.tree(companyId)[0], special: "treeWithStoreCount" },
      ] as const,
  },
  units: {
    all: ["units"] as const,
    lists: () => [...keys.units.all, "list"] as const,
    details: () => [...keys.units.all, "detail"] as const,
    detail: (controllerSerialNumber: string) =>
      [
        ...keys.units.details(),
        { view: "full", controllerSerialNumber },
      ] as const,
    replenishmentSummary: (controllerSerialNumber: string) =>
      [
        ...keys.units.details(),
        { view: "replenishmenySummary", controllerSerialNumber },
      ] as const,
    stock: (controllerSerialNumber: string) =>
      [
        ...keys.units.details(),
        { view: "stock", controllerSerialNumber },
      ] as const,
  },
  company: {
    get: (companyId: string) => [{ scope: "company", companyId }] as const,
  },
  escalations: {
    all: ["escalations"] as const,
    lists: () => [...keys.escalations.all, "list"] as const,
    list: (options?: GetByGroupOrStore) =>
      [...keys.escalations.lists(), { ...options }] as const,
  },
  products: {
    all: [{ scope: "products" }] as const,
    get: (sku: string) => [{ ...keys.products.all, sku }],
  },
  incidents: {
    all: ["incidents"] as const,
    get: (options: GetIncident) => [...keys.incidents.all, "detail", options],
    latest: () => [...keys.incidents.all, "detail", "latest"],
    list: (options: GetIncidentsOptions) => [
      ...keys.incidents.all,
      "list",
      trimStartAndEndDates(options),
    ],
    paginated: (options: GetIncidentsOptions & GetByPagination) => [
      ...keys.incidents.all,
      "list-paginated",
      trimStartAndEndDates(options),
    ],
    stats: (options: GetByGroupOrStore & GetByDateRange) => [
      ...keys.incidents.all,
      "stats",
      trimStartAndEndDates(options),
    ],
  },
  shipments: {
    all: [{ scope: "shipments" }] as const,
    get: (id: string) => [{ ...keys.shipments.all, id }],
    list: (options: GetShipmentsOptions) => [
      { ...keys.shipments.all, options },
    ],
    listUnit: (
      controllerSerialNumber: string,
      storeId: string,
      maxNumberToReturn?: number,
    ) => [
      {
        ...keys.shipments.all,
        controllerSerialNumber,
        storeId,
        maxNumberToReturn,
      },
    ],
  },
  activities: {
    all: [{ scope: "activities" }] as const,
    list: (options: GetByGroupOrStore & { numberDays: number }) => [
      { ...keys.activities.all, options },
    ],
  },
  inspectionChecks: {
    all: ["inspectionChecks"] as const,
    list: (companyId: string) =>
      [...keys.inspectionChecks.all, "list", companyId] as const,
  },
};

// ===== Current User =====

// ===== Users =====

export type GroupAndOrStoreKey = {
  storeId?: string;
  groupId?: string;
};

const fetchUser = ({
  queryKey: [{ userId }],
}: QueryFunctionContext<ReturnType<(typeof keys.users)["detail"]>>) =>
  api.users.get(userId);

const fetchUsers = ({
  queryKey: [{ companyId }],
}: QueryFunctionContext<ReturnType<(typeof keys.users)["list"]>>) =>
  api.users.listStore(companyId);

const fetchUsersInGroup = ({
  queryKey: [{ companyId, groupId }],
}: QueryFunctionContext<ReturnType<(typeof keys.users)["inGroup"]>>) =>
  api.users.listGroup(companyId, groupId);

const fetchUsersInStore = ({
  queryKey: [{ companyId, storeId }],
}: QueryFunctionContext<ReturnType<(typeof keys.users)["inStore"]>>) =>
  api.users.listStore(companyId, storeId);

const useUsersCacheUpdater = () => {
  const queryClient = useQueryClient();
  const companyId = useCurrentCompanyId();

  return (
    mutatorKeys: GroupAndOrStoreKey,
    updater: (users?: Api.User[]) => Api.User[],
  ) => {
    const { storeId, groupId } = mutatorKeys;

    queryClient.setQueryData<Api.User[]>(keys.users.list(companyId), updater);
    void queryClient.invalidateQueries({
      queryKey: keys.users.list(companyId),
    });

    if (storeId) {
      const key = keys.users.inStore(companyId, storeId);

      queryClient.setQueryData<Api.User[]>(key, updater);
      void queryClient.invalidateQueries({ queryKey: key });
    }

    if (groupId) {
      const key = keys.users.inGroup(companyId, groupId);

      queryClient.setQueryData<{
        InThisGroup: Api.User[];
        InParentGroups: Api.User[];
        InChildGroups: Api.User[];
      }>(key, (group) => ({
        InThisGroup: updater(group?.InThisGroup),
        InParentGroups: updater(group?.InParentGroups),
        InChildGroups: updater(group?.InChildGroups),
      }));
      void queryClient.invalidateQueries({ queryKey: key });
    }
  };
};

export const useCurrentUser = () =>
  useQuery({ queryKey: ["currentUser"], queryFn: () => api.users.current() });

export const useCurrentCompanyId = () => {
  const { data: current } = useCurrentUser();
  const companyId = current?.company.companyId ?? "";

  return companyId;
};

/**
 * Returns the user object for the specified user from the users list. May be missing some info.
 */
export const useUser = (userId?: string) => {
  const companyId = useCurrentCompanyId();

  return useQuery({
    queryKey: keys.users.list(companyId),
    queryFn: fetchUsers,
    enabled: !!userId && !!companyId,
    select: (users) => users.find((user) => user.UserId === userId),
  });
};

/**
 * Returns the ful user object as returned from the GetUserById.
 */
export const useUserFull = (userId?: string) => {
  return useQuery({
    queryKey: keys.users.detail(userId ?? ""),
    queryFn: fetchUser,
    enabled: !!userId,
  });
};

export const useUsers = () => {
  const companyId = useCurrentCompanyId();

  return useQuery({
    queryKey: keys.users.list(companyId),
    queryFn: fetchUsers,
    enabled: !!companyId,
  });
};

export const useUsersInGroup = (groupId?: string) => {
  const companyId = useCurrentCompanyId();

  return useQuery({
    queryKey: keys.users.inGroup(companyId, groupId ?? ""),
    queryFn: fetchUsersInGroup,
    enabled: !!groupId && !!companyId,
  });
};

export const useUsersInGroupFlat = (groupId?: string) => {
  const companyId = useCurrentCompanyId();

  const select = useCallback((usersInGroup: Api.UsersInGroup) => {
    return removeDuplicates(
      [
        ...usersInGroup.InThisGroup,
        ...usersInGroup.InChildGroups,
        ...usersInGroup.InParentGroups,
      ],
      (user) => user.UserId,
    );
  }, []);

  return useQuery({
    queryKey: keys.users.inGroup(companyId, groupId ?? ""),
    queryFn: fetchUsersInGroup,
    enabled: !!groupId && !!companyId,
    select,
  });
};

export const useUsersInStore = (storeId?: string) => {
  const companyId = useCurrentCompanyId();

  return useQuery({
    queryKey: keys.users.inStore(companyId, storeId ?? ""),
    queryFn: fetchUsersInStore,
    enabled: !!storeId && !!companyId,
  });
};

export const useUsersInStores = (storeIds?: string[]) => {
  const queryClient = useQueryClient();
  const companyId = useCurrentCompanyId();

  return useQuery({
    queryKey: ["usersInStores", storeIds],
    queryFn: async () => {
      if (!storeIds) return [];

      const results = await Promise.all(
        storeIds.map(async (storeId) => ({
          storeId,
          users: await queryClient.fetchQuery({
            queryKey: keys.users.inStore(companyId, storeId),
            queryFn: fetchUsersInStore,
          }),
        })),
      );

      return results;
    },
    enabled: !!storeIds && !!companyId,
  });
};

export const useUsersIn = (options: GetByGroupOrStore) => {
  const companyId = useCurrentCompanyId();

  const grabUsers = async (): Promise<
    { inherited: Api.User[]; directOrLower: Api.User[] } | undefined
  > => {
    if (options.storeId) {
      const stores = await api.stores.list();
      const store = stores.find((s) => s.StoreId === options.storeId);

      if (!store) throw new Error("Store not found");

      const usersInStore = await api.users.listStore(
        companyId,
        options.storeId,
      );

      const directOrLower = usersInStore.filter((user) =>
        user.Groups.find((group) => group.GroupId === store.SingleStoreGroupID),
      );

      const inherited = usersInStore.filter(
        (user) => !directOrLower.some((u) => u.UserId === user.UserId),
      );

      return { inherited, directOrLower };
    } else if (options.groupId) {
      const usersInGroup = await api.users.listGroup(
        companyId,
        options.groupId,
      );

      const inherited = [...usersInGroup.InParentGroups];

      const directOrLower = removeDuplicates(
        [...usersInGroup.InThisGroup, ...usersInGroup.InChildGroups],
        (user) => user.UserId,
      ).filter((user) => !inherited.some((u) => u.UserId === user.UserId));

      return { inherited, directOrLower };
    } else if (options.allStores) {
      const storeSingleStoreGroupIds = (await api.groups.tree(companyId))
        // In this instance, we most likely won't have a group, so these
        // _should_ all be SingleStores, but check anyway.
        .filter((s) => s.Type === "SingleStore")
        // Users are assigned to the store's SingleStore group, so it's the
        // GroupId that we want.
        .map((g) => g.GroupId);

      const users = await api.users.listStore(companyId);

      const directOrLower = users.filter((user) =>
        user.Groups.find((group) =>
          storeSingleStoreGroupIds.includes(group.GroupId),
        ),
      );

      const inherited = users.filter(
        (user) => !directOrLower.some((u) => u.UserId === user.UserId),
      );

      return { inherited, directOrLower };
    } else {
      return { inherited: [], directOrLower: [] };
    }
  };

  return useQuery({
    queryKey: keys.users.in(companyId, options),
    queryFn: grabUsers,
  });
};

export const useUserAssociations = (userId: string) => {
  const companyId = useCurrentCompanyId();

  const select = useCallback(
    (company: Api.Company) => {
      if (!company || !userId) return null;

      return flattenTree(
        company.Groups,
        (group) => group.Groups,
        (group) => group.Groups,
        (group) => group.GroupId,
      )
        .filter((group) => group.Type === "SingleStore")
        .filter((group) => !group.Name.startsWith("(Prox)"))
        .filter((group) =>
          group.Stores.find((store) => store.StoreContactId === userId),
        );
    },
    [userId],
  );

  return useQuery({
    queryKey: keys.company.get(companyId),
    queryFn: () => api.company.get(companyId),
    enabled: !!companyId,
    select,
  });
};

export const useMutateUser = (mutationKeys: GroupAndOrStoreKey = {}) => {
  const queryClient = useQueryClient();
  const { data: currentUser } = useCurrentUser();

  const updateUsersCache = useUsersCacheUpdater();

  const editUser = (updatedUser: Api.User) =>
    api.users.update({ ...updatedUser });

  return useMutation<
    Api.ResponseSuccess<Api.User>,
    ApiResponseError<Api.UserValidationError>,
    Api.User
  >({
    mutationFn: editUser,
    onError: (response) => {
      trackEvent({ category: "Edit user", action: "Failed", response });
    },
    onSuccess: async (response) => {
      trackEvent({ category: "Edit user", action: "Success" });

      if (!response || !response.SuccessPayload) return;

      const { SuccessPayload: user } = response;

      const updater = (users?: Api.User[]) =>
        users?.map((u) => (u.UserId === user.UserId ? user : u)) ?? [];

      if (user.UserId === currentUser?.userId) {
        const cu = await api.users.current();

        queryClient.setQueryData(["currentUser"], cu);
      }

      queryClient.setQueryData(keys.users.detail(user.UserId), user);

      updateUsersCache(mutationKeys, updater);
    },
  });
};

export const useDeleteUser = (mutationKeys: GroupAndOrStoreKey = {}) => {
  const queryClient = useQueryClient();

  const updateUsersCache = useUsersCacheUpdater();

  const deleteUser = async (id: string) => await api.users.delete(id);

  return useMutation<
    Api.ResponseSuccess<Api.User>,
    ApiResponseError<Api.UserValidationError>,
    string,
    { user?: Api.User; users?: Api.User[] }
  >({
    mutationFn: deleteUser,
    onError: (response) => {
      trackEvent({ category: "Delete user", action: "Failed", response });
    },
    onSuccess: (_response, userId) => {
      trackEvent({ category: "Delete user", action: "Success" });

      const remover = (users?: Api.User[]) =>
        users?.filter((u) => u.UserId !== userId) ?? [];

      queryClient.removeQueries({
        queryKey: keys.users.detail(userId),
        exact: true,
      });

      updateUsersCache(mutationKeys, remover);
    },
  });
};

export const useHasPermission = () => {
  const { data: user, isLoading: isUserLoading } = useCurrentUser();
  const { data: roles, isLoading: isRolesLoading } = useRoles();

  const permissions = useMemo(() => {
    if (!user || !roles) return [];

    return Array.from(
      new Set(
        user.roles
          .map((role) => roles.find((r) => r.id === role.id))
          .reduce(
            (permissionKeys, role) => [
              ...permissionKeys,
              ...(role?.Permissions.map((p) => p.PermissionKey) ?? []),
            ],
            [] as Api.PermissionKey[],
          ),
      ),
    );
  }, [roles, user]);

  const isLoading = useMemo(
    () => isUserLoading || isRolesLoading,
    [isRolesLoading, isUserLoading],
  );

  const hasPermission = useCallback(
    (permission: Api.PermissionKey) => permissions.includes(permission),
    [permissions],
  );

  return { hasPermission, isLoading };
};

const rolesHierarchy: Array<Api.RoleName> = [
  "Local Safety Officer",
  "Supervisor",
  "Owner",
  "Consultant",
  "Admin",
];

export const useIsHierarchicallySuperiorTo = () => {
  const { data: currentUser } = useCurrentUser();

  const isHierarchicallySuperiorTo = useCallback(
    (user: Api.User) => {
      if (!currentUser) return false;
      return (
        rolesHierarchy.indexOf(currentUser.roles[0].name as Api.RoleName) >=
        rolesHierarchy.indexOf(user.Roles[0].Name)
      );
    },
    [currentUser],
  );

  return { isHierarchicallySuperiorTo };
};

// ===== Stores =====

const fetchStore = ({
  queryKey: [{ storeId }],
}: QueryFunctionContext<ReturnType<(typeof keys.stores)["detail"]>>) =>
  api.stores.get(storeId);

const fetchStores = (
  // Context is not used for this particular method, but we still want to make
  // sure that it is being used in the correct context.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _ctx: QueryFunctionContext<ReturnType<(typeof keys.stores)["list"]>>,
) => api.stores.list();

/**
 * Returns the store object for the specified store from the stores list. Will be missing some information.
 */
export const useStore = (id?: string) => {
  return useQuery({
    queryKey: keys.stores.list(),
    queryFn: fetchStores,
    enabled: !!id,
    select: (stores) => stores.find((store) => store.StoreId === id),
  });
};

/**
 * Returns the full store object as returned from the GetStoreById.
 */
export const useStoreFull = (storeId?: string) => {
  return useQuery({
    queryKey: keys.stores.detail(storeId ?? ""),
    queryFn: fetchStore,
    enabled: !!storeId,
  });
};

export const useStores = (
  options?: GetByStoresIds,
): UseQueryResult<Api.Store[]> => {
  const select = useCallback(
    (stores: Api.Store[]): Api.Store[] =>
      stores
        .filter((store) =>
          options?.storesIds ? options.storesIds.includes(store.StoreId) : true,
        )
        .sort((a, b) => a.Name.localeCompare(b.Name)),
    [options],
  );

  return useQuery({
    queryKey: keys.stores.list(),
    queryFn: fetchStores,
    select,
  });
};

export const useStoresInGroup = (groupId?: string, recursive?: boolean) => {
  const { data: groups } = useGroups();

  const select = useCallback(
    (stores: Api.Store[]): Api.Store[] => {
      if (!groupId || !groups) return [];

      const group = findInTree(
        groups,
        (group) => group.GroupId === groupId,
        (group) => group.Groups,
      );

      if (!recursive) {
        return stores
          .filter((store) =>
            group?.Stores.find((s) => s.StoreId === store.StoreId),
          )
          .sort((a, b) => a.Name.localeCompare(b.Name));
      }

      if (!group) throw new Error("Group not found in allowed groups");

      const storeIdentifiers = flattenTree(
        [group],
        (group) => group.Groups,
        (group) => group.Stores,
        (store) => store.StoreId,
      );

      return stores
        .filter((store) =>
          storeIdentifiers.find((s) => s.StoreId === store.StoreId),
        )
        .sort((a, b) => a.Name.localeCompare(b.Name));
    },
    [groupId, groups, recursive],
  );

  return useQuery({
    queryKey: keys.stores.list(),
    queryFn: fetchStores,
    enabled: !!(groupId && groups),
    select,
  });
};

export const useAddStoresToGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<
    Api.ResponseSuccess<unknown>,
    ApiResponseError<Api.GroupValidationError>,
    { storeIds: string[]; groupsIds: string[] }
  >({
    mutationFn: async ({
      storeIds,
      groupsIds,
    }: {
      storeIds: string[];
      groupsIds: string[];
    }) => api.groups.addStores(groupsIds, storeIds),

    onError: (response) => {
      trackEvent({
        category: "Add stores to group",
        action: "Failed",
        response,
      });
    },
    onSuccess: () => {
      trackEvent({ category: "Add stores to group", action: "Success" });
      void queryClient.invalidateQueries({ queryKey: keys.groups.all });
      void queryClient.invalidateQueries({ queryKey: keys.stores.all });
    },
  });
};

export const useRemoveStoresFromGroups = (
  mutationKeys: GroupAndOrStoreKey = {},
) => {
  const queryClient = useQueryClient();

  return useMutation<
    Api.ResponseSuccess<unknown>,
    ApiResponseError<Api.GroupValidationError>,
    { storeIds: string[]; groupsIds: string[] }
  >({
    mutationFn: ({ storeIds, groupsIds }) =>
      api.groups.removeStores(groupsIds, storeIds),
    onError: (response) => {
      trackEvent({
        category: "Remove stores from groups",
        action: "Failed",
        response,
      });
    },
    onSuccess: () => {
      trackEvent({ category: "Remove stores from groups", action: "Success" });
      const { storeId } = mutationKeys;

      if (storeId)
        void queryClient.invalidateQueries({
          queryKey: keys.stores.detail(storeId),
        });
      void queryClient.invalidateQueries({ queryKey: keys.stores.list() });
      void queryClient.invalidateQueries({ queryKey: keys.groups.all });
    },
  });
};

export const useChangeStoreContact = () => {
  const queryClient = useQueryClient();

  const companyId = useCurrentCompanyId();

  const updateStoreContact = async ({
    storeId,
    contactUser,
  }: {
    storeId: string;
    contactUser: Api.User;
  }) => {
    const store = await api.stores.get(storeId);

    return api.stores.update({
      ...store,
      StoreContact: contactUser as Api.UserStripped,
      StoreContactId: contactUser.UserId,
    });
  };

  return useMutation<
    Api.ResponseSuccess<Api.Store>,
    ApiResponseError<Api.StoreValidationError>,
    { storeId: string; contactUser: Api.User }
  >({
    mutationFn: updateStoreContact,
    onMutate: ({ storeId, contactUser }) => {
      const previousStore = queryClient.getQueryData<Api.Store>(
        keys.stores.detail(storeId),
      );

      queryClient.setQueryData(keys.stores.detail(storeId), {
        ...previousStore,
        StoreContact: contactUser as Api.UserStripped,
        StoreContactId: contactUser?.UserId,
      });
      return previousStore;
    },
    onError: (response, { storeId }, previousStore) => {
      trackEvent({
        category: "Change store contact",
        action: "Failed",
        response,
      });
      queryClient.setQueryData(keys.stores.detail(storeId), previousStore);
    },
    onSettled: (_response, _err, { storeId }) => {
      void queryClient.invalidateQueries({ queryKey: keys.stores.list() });
      void queryClient.invalidateQueries({
        queryKey: keys.stores.detail(storeId),
      });
      void queryClient.invalidateQueries({
        queryKey: keys.company.get(companyId),
      });
    },
  });
};
export const useMutateCompany = () => {
  const queryClient = useQueryClient();

  return useMutation<
    Api.ResponseSuccess<Api.Company>,
    ApiResponseError<Api.CompanyValidationError>,
    Api.Company
  >({
    mutationFn: (updatedCompany) => api.company.update(updatedCompany),
    onSuccess: (_response, company) => {
      trackEvent({
        category: "Change Company settings",
        action: "Success",
      });
      void queryClient.invalidateQueries({
        queryKey: keys.company.get(company.id),
      });
    },
  });
};

export const useMutateCompanyMfa = () => {
  const queryClient = useQueryClient();

  return useMutation<
    Api.ResponseSuccess<Api.CompanyMfa>,
    ApiResponseError<Api.CompanyValidationError>,
    Api.CompanyMfa
  >({
    mutationFn: ({ enable, forceSignOutUsers, companyId }: Api.CompanyMfa) =>
      api.policies.companyMfa(enable, forceSignOutUsers, companyId),

    onSuccess: (_response, context) => {
      void queryClient.invalidateQueries({
        queryKey: keys.company.get(context.companyId),
      });
    },
  });
};

export const useMutateStore = () => {
  const companyId = useCurrentCompanyId();
  const queryClient = useQueryClient();

  return useMutation<
    Api.ResponseSuccess<Api.Store>,
    ApiResponseError<Api.StoreValidationError>,
    Api.Store
  >({
    mutationFn: (updatedStore) => api.stores.update(updatedStore),
    onSuccess: (_response, store) => {
      trackEvent({
        category: "Change store contact",
        action: "Success",
      });
      void queryClient.invalidateQueries({ queryKey: keys.stores.list() });
      void queryClient.invalidateQueries({
        queryKey: keys.stores.detail(store.StoreId),
      });
      void queryClient.invalidateQueries({
        queryKey: keys.company.get(companyId),
      });
    },
  });
};

// ===== Groups =====

const fetchGroupsList = ({
  queryKey: [{ companyId }],
}: QueryFunctionContext<ReturnType<(typeof keys.groups)["list"]>>) =>
  api.groups.list(companyId);

const fetchGroupsTree = ({
  queryKey: [{ companyId }],
}: QueryFunctionContext<
  ReturnType<
    (typeof keys.groups)["tree"] | (typeof keys.groups)["treeWithStoreCount"]
  >
>) => api.groups.tree(companyId);

/**
 * Returns the group tree, starting from the specified `id`.
 *
 * @param id group tree to select
 */
export const useGroup = (id?: string | null) => {
  const companyId = useCurrentCompanyId();

  const select = useCallback(
    (groups: Api.TreeGroup[]): Api.TreeGroup => {
      if (!id) {
        // No id, we want the "root"
        //
        if (groups.length > 1) {
          // There's no definitive root...

          // If all of the groups are stores
          if (groups.every((group) => group.Type === "SingleStore")) {
            return {
              id: "",
              GroupId: "",
              CreatedDate: "",
              ModifiedDate: "",
              Name: "None",
              CompanyId: "",
              Type: "Client",
              ParentGroupId: null,
              IsGeographicallyMatched: false,
              IsProxGroup: false,
              Groups: [],
              Stores: groups.map((group) => group.Stores[0]),
              Users: [],
            };
          }

          if (groups.some((group) => group.Type === "Adhoc")) {
            return {
              id: "",
              GroupId: "",
              CreatedDate: "",
              ModifiedDate: "",
              Name: "None",
              CompanyId: "",
              Type: "Client",
              ParentGroupId: null,
              IsGeographicallyMatched: false,
              IsProxGroup: false,
              Groups: groups.filter((group) => group.Type === "Adhoc"),
              Stores: [],
              Users: [],
            };
          }

          return groups[0];
        } else {
          return groups[0];
        }
      }

      const result = findInTree(
        groups,
        (group) => group.GroupId === id,
        (group) => group.Groups,
      );

      // if (!result) throw new Error("Group not found");
      if (!result)
        return {
          id: "",
          GroupId: "",
          CreatedDate: "",
          ModifiedDate: "",
          Name: "None",
          CompanyId: "",
          Type: "Root",
          ParentGroupId: null,
          IsGeographicallyMatched: false,
          IsProxGroup: false,
          Groups: [],
          Stores: [],
          Users: [],
        };

      return result;
    },
    [id],
  );

  return useQuery({
    queryKey: keys.groups.tree(companyId),
    queryFn: fetchGroupsTree,
    enabled: !!id && !!companyId,
    select,
  });
};

export const useRootGroups = () => {
  const companyId = useCurrentCompanyId();

  const select = useCallback(
    (groups: Api.TreeGroup[]) =>
      groups.filter(
        (group) =>
          group.Type === "Root" ||
          group.Type === "Adhoc" ||
          group.Type === "Franchise",
      ),
    [],
  );

  return useQuery({
    queryKey: keys.groups.tree(companyId),
    queryFn: fetchGroupsTree,
    enabled: !!companyId,
    select,
  });
};

export const useGroups = () => {
  const companyId = useCurrentCompanyId();

  return useQuery({
    queryKey: keys.groups.tree(companyId),
    queryFn: fetchGroupsTree,
    enabled: !!companyId,
  });
};

export const useGroupsFlat = () => {
  const companyId = useCurrentCompanyId();

  const select = useCallback((groups: Api.GroupFlat[]) => {
    return groups
      .filter((group) => group.Type !== "Geographical")
      .filter((group) => !group.Name.startsWith("(Prox)"))
      .sort((a, b) => a.Name.localeCompare(b.Name));
  }, []);

  return useQuery({
    queryKey: keys.groups.list(companyId),
    queryFn: fetchGroupsList,
    enabled: !!companyId,
    select,
  });
};

export const useGroupFlat = (groupId?: string) => {
  const companyId = useCurrentCompanyId();

  const select = useCallback(
    (groups: Api.GroupFlat[]) => {
      return groups.find((group) => group.GroupId === groupId);
    },
    [groupId],
  );

  return useQuery({
    queryKey: keys.groups.list(companyId),
    queryFn: fetchGroupsList,
    enabled: !!companyId && !!groupId,
    select,
  });
};

export type GroupsWithCountAndStores = (Api.TreeGroup & {
  storeCount: number;
} & Api.Store)[];

export const useGroupsWithStoreCount =
  (): UseQueryResult<GroupsWithCountAndStores> => {
    const companyId = useCurrentCompanyId();

    return useQuery({
      queryKey: keys.groups.treeWithStoreCount(companyId),
      queryFn: async (ctx) => {
        const groups = await fetchGroupsTree(ctx);

        return flattenTree(
          [{ Groups: groups }], // Wrapped un a dummy root so we can collect the real roots
          (group) => group.Groups,
          (group) => group.Groups,
          (group) => group.GroupId,
        ).map((group) => ({
          ...group,
          Stores: flattenTree(
            [group],
            (group) => group.Groups,
            (group) => group.Stores,
            (store) => store.StoreId,
          ),
          storeCount:
            flattenTree(
              [group],
              (group) => group.Groups,
              (group) => group.Stores,
              (store) => store.StoreId,
            ).length ?? 0,
        }));
      },
      enabled: !!companyId,
    });
  };

export const useFranchiseForStore = (storeId?: string) => {
  const companyId = useCurrentCompanyId();
  const { data: store } = useStore(storeId);

  const select = useCallback(
    (groups: Api.TreeGroup[]) => {
      if (!store) return null;

      const singleStoreGroup = findInTree(
        groups,
        (group) => group.GroupId === store.SingleStoreGroupID,
        (group) => group.Groups,
      );

      if (!singleStoreGroup) return null;

      const franchise = findInTree(
        groups,
        (group) => group.GroupId === singleStoreGroup.ParentGroupId,
        (group) => group.Groups,
      );

      return franchise;
    },
    [store],
  );

  return useQuery({
    queryKey: keys.groups.tree(companyId),
    queryFn: fetchGroupsTree,
    enabled: (!storeId || !store) && !!companyId,
    select,
  });
};

export const useRemoveUserFromGroup = () => {
  const queryClient = useQueryClient();
  const companyId = useCurrentCompanyId();

  return useMutation<
    Api.ResponseSuccess<RemoveUsersFromGroupsResponse>,
    ApiResponseError<Api.GroupValidationError>,
    { userIds: string[]; groupsIds: string[] }
  >({
    mutationFn: async ({
      userIds,
      groupsIds,
    }: {
      userIds: string[];
      groupsIds: string[];
    }) => api.groups.removeUsers(groupsIds, userIds),

    onError: (response) => {
      trackEvent({
        category: "Remove user from group",
        action: "Failed",
        response,
      });
    },
    onSuccess: (_data, variables) => {
      trackEvent({
        category: "Remove user from group",
        action: "Success",
      });
      variables.userIds.forEach(
        (userId) =>
          void queryClient.invalidateQueries({
            queryKey: keys.users.detail(userId),
          }),
      );
      void queryClient.invalidateQueries({
        queryKey: keys.users.list(companyId),
      });
    },
  });
};

export const useAddUsersToGroup = () => {
  const queryClient = useQueryClient();
  const companyId = useCurrentCompanyId();

  return useMutation<
    Api.ResponseSuccess<AddUsersToGroupsResponse>,
    ApiResponseError<Api.GroupValidationError>,
    { userIds: string[]; groupsIds: string[] }
  >({
    mutationFn: async ({
      userIds,
      groupsIds,
    }: {
      userIds: string[];
      groupsIds: string[];
    }) => api.groups.addUsers(groupsIds, userIds),

    onError: (response) => {
      trackEvent({
        category: "Add users to group",
        action: "Failed",
        response,
      });
    },
    onSuccess: () => {
      trackEvent({
        category: "Add users to group",
        action: "Success",
      });
      void queryClient.invalidateQueries({
        queryKey: keys.users.list(companyId),
      });
    },
  });
};

export const useAddGroup = () => {
  const queryClient = useQueryClient();
  const companyId = useCurrentCompanyId();

  const addGroup = (newGroup: Partial<Api.TreeGroup>) =>
    api.groups.update({ ...newGroup, CompanyId: companyId });

  const key = keys.groups.list(companyId);

  return useMutation<
    Api.ResponseSuccess<Api.TreeGroup>,
    ApiResponseError<Api.GroupValidationError>,
    Partial<Api.TreeGroup>,
    { previousGroups: Api.TreeGroup[] }
  >({
    mutationFn: addGroup,
    onMutate: (newGroup) => {
      void queryClient.cancelQueries({ queryKey: key });

      const previousGroups =
        queryClient.getQueryData<Api.TreeGroup[]>(key) ?? [];

      queryClient.setQueryData<Partial<Api.TreeGroup>[]>(key, (old) => [
        ...(old ?? []),
        newGroup,
      ]);

      return { previousGroups };
    },
    onError: (response, _newGroup, context) => {
      trackEvent({
        category: "Add group",
        action: "Failed",
        response,
      });
      queryClient.setQueryData(key, context?.previousGroups ?? []);
    },
    onSuccess: () => {
      trackEvent({
        category: "Add group",
        action: "Success",
      });
    },
    onSettled: (response) => {
      if (!response) return;

      // TODO: Inject the new group into the correct place in the tree

      void queryClient.invalidateQueries({ queryKey: keys.groups.all });
      void queryClient.invalidateQueries({ queryKey: key });
    },
  });
};

export const useUpdateGroup = () => {
  const queryClient = useQueryClient();
  const companyId = useCurrentCompanyId();

  const updateGroup = async (updatedGroup: Partial<Api.TreeGroup>) => {
    const company = await api.company.get(companyId);

    const group = findInTree(
      company.Groups,
      (group) => group.GroupId === updatedGroup.GroupId,
      (group) => group.Groups,
    );

    return api.groups.update({
      ...group,
      ...updatedGroup,

      // CompanyId is not attached to all groups in the GetCompany call.
      CompanyId: companyId,

      // Don't need to pass in the groups below.
      Groups: undefined,
    });
  };

  return useMutation<
    Api.ResponseSuccess<Api.TreeGroup>,
    ApiResponseError<Api.GroupValidationError>,
    Partial<Api.TreeGroup>,
    unknown
  >({
    mutationFn: updateGroup,
    onError: (response) => {
      trackEvent({
        category: "Update group",
        action: "Failed",
        response,
      });
    },
    onSuccess: () => {
      trackEvent({
        category: "Update group",
        action: "Success",
      });
    },
    onSettled: (response) => {
      if (!response) return;

      void queryClient.invalidateQueries({ queryKey: ["company", companyId] });
      void queryClient.invalidateQueries({
        queryKey: keys.groups.list(companyId),
      });
      void queryClient.invalidateQueries({
        queryKey: keys.groups.tree(companyId),
      });
    },
  });
};

export const useDeleteGroup = () => {
  const queryClient = useQueryClient();
  const companyId = useCurrentCompanyId();

  const deleteGroup = (id: string) => api.groups.delete(companyId, id);

  return useMutation<
    Api.ResponseSuccess<Api.TreeGroup>,
    ApiResponseError<Api.GroupValidationError>,
    string
  >({
    mutationFn: deleteGroup,
    onError: (response) => {
      trackEvent({
        category: "Delete group",
        action: "Failed",
        response,
      });
    },
    onSuccess: (_response, id) => {
      trackEvent({
        category: "Delete group",
        action: "Success",
      });
      queryClient.setQueryData<Api.TreeGroup[]>(
        keys.groups.tree(companyId),
        (groups) => {
          if (!groups) return [];

          return removeFromTree(
            groups,
            (group) => group.GroupId === id,
            (group) => group.Groups,
            "Groups",
          );
        },
      );

      queryClient.setQueryData<Api.GroupFlat[]>(
        keys.groups.list(companyId),
        (groups) => groups?.filter((group) => group.GroupId !== id) ?? [],
      );

      void queryClient.invalidateQueries({ queryKey: keys.groups.all });
    },
  });
};

// ===== Others =====

const fetchUnits = ({
  queryKey: [, ,],
}: QueryFunctionContext<ReturnType<typeof keys.units.lists>>) =>
  api.units.list();

const fetchUnit = ({
  queryKey: [, , { controllerSerialNumber }],
}: QueryFunctionContext<ReturnType<typeof keys.units.detail>>) =>
  api.units.get(controllerSerialNumber);

const fetchReplenishmentSummary = ({
  queryKey: [, , { controllerSerialNumber }],
}: QueryFunctionContext<ReturnType<typeof keys.units.replenishmentSummary>>) =>
  api.units.getReplenishmentSummary(controllerSerialNumber);

const fetchUnitStock = ({
  queryKey: [, , { controllerSerialNumber }],
}: QueryFunctionContext<ReturnType<(typeof keys.units)["stock"]>>) =>
  api.units.stock(controllerSerialNumber);

export const useUnits = (options?: GetByGroupOrStore & GetByStoresIds) => {
  const { data: storesInGroup } = useStoresInGroup(options?.groupId, true);

  const select = useCallback(
    (units: Api.Unit[]) => {
      if (!options) return units;

      if (options.storeId) {
        return units.filter((unit) => unit.StoreId === options.storeId);
      }

      if (options.groupId && storesInGroup) {
        const storesIds = storesInGroup.map((store) => store.StoreId);
        return units.filter((unit) => storesIds.includes(unit.StoreId));
      }

      if (options.storesIds) {
        const storesIds = options.storesIds;
        return units.filter((unit) => storesIds.includes(unit.StoreId));
      }

      return units;
    },
    [options, storesInGroup],
  );

  return useQuery({
    queryKey: keys.units.lists(),
    queryFn: fetchUnits,
    enabled:
      !options ||
      (!!options &&
        !!(
          (options.groupId && storesInGroup) ||
          options.storeId ||
          options.storesIds
        )),
    select,
  });
};

export const useUnit = (controllerSerialNumber?: string) => {
  return useQuery({
    queryKey: keys.units.lists(),
    queryFn: fetchUnits,
    enabled: !!controllerSerialNumber,
    select: (units) =>
      units.find(
        (unit) => unit.ControllerSerialNumber === controllerSerialNumber,
      ),
  });
};

export const useUnitFull = (controllerSerialNumber?: string) => {
  return useQuery({
    queryKey: keys.units.detail(controllerSerialNumber ?? ""),
    queryFn: fetchUnit,
    enabled: !!controllerSerialNumber,
  });
};

export const useUnitsByIds = (ids: string[]) => {
  const select = useCallback(
    (units: Api.Unit[]) =>
      units.filter((unit) => ids.includes(unit.ControllerSerialNumber)),
    [ids],
  );

  return useQuery({
    queryKey: keys.units.lists(),
    queryFn: () => api.units.list(),
    enabled: !!ids,
    select,
  });
};

type Readiness =
  | {
      isLoading: boolean;
      reason: string;
      percent: number;
      reasons?: undefined;
    }
  | {
      isLoading: boolean;
      reason: undefined;
      percent: number;
      reasons: string[];
    };

export const useUnitReadiness = (controllerSerialNumber?: string) => {
  const { data: unit, isLoading } = useUnit(controllerSerialNumber);

  const readiness = useMemo(() => {
    if (!unit) return { reason: "", percent: -1 };

    const reasons = unit.ReadinessReason?.split(". ") ?? [];

    if (reasons[reasons.length - 1] === "") reasons.splice(-1, 1);

    const percent = unit.ReadinessPercentage;

    return {
      reasons,
      percent,
    };
  }, [unit]);

  return { readiness, isLoading };
};

export const useUnitReadinessReasons = (
  controllerSerialNumber?: string,
): Readiness => {
  const { data: unit, isLoading } = useUnit(controllerSerialNumber);

  const readiness = useMemo(() => {
    return {
      reason: unit?.ReadinessReason ?? "",
      percent: unit?.ReadinessPercentage ?? 0,
      reasons: undefined,
    };
  }, [unit?.ReadinessPercentage, unit?.ReadinessReason]);

  return { ...readiness, isLoading };
};

export const useUnitStock = (controllerSerialNumber?: string) =>
  useQuery({
    queryKey: keys.units.stock(controllerSerialNumber ?? ""),
    queryFn: fetchUnitStock,
    enabled: !!controllerSerialNumber,
  });

export const useUnitReplenishmentSummary = (controllerSerialNumber?: string) =>
  useQuery({
    queryKey: keys.units.replenishmentSummary(controllerSerialNumber ?? ""),
    queryFn: fetchReplenishmentSummary,
    enabled: !!controllerSerialNumber,
  });

export const useUnitShipmentSummary = (
  controllerSerialNumber?: string,
  shipmentId?: string,
) => {
  const {
    data: shipment,
    isLoading: isLoadingShipment,
    isError: isErrorShipment,
  } = useShipment(shipmentId);
  const {
    data: replenishments,
    isLoading: isLoadingReplenishments,
    isError: isErrorReplenishments,
  } = useUnitReplenishmentSummary(controllerSerialNumber);

  const data = useMemo(() => {
    if (!replenishments || !shipment) return [];

    const unitStockStatus = removeDuplicates(
      [
        ...replenishments.NormalInKitStock,
        ...replenishments.InTransitStock,
        ...replenishments.AnomalousConsumedStock,
        ...replenishments.NonAnomalousRecentConsumedStock,
        ...replenishments.RecentLostOrDamagedStock,
        ...replenishments.StockOnSiteNotInKit,
        ...replenishments.ExpiredStockStillInKit,
        ...replenishments.ExpiredStockNeedingReplacement,
        ...replenishments.ExpiredStockCardProducts,
        ...replenishments.CloseToExpiryProductsInKit,
      ],
      (stock) => stock.UID,
    );

    return shipment.ShipmentLines.map((line) => ({
      line,
      old: unitStockStatus.find((s) => s.SKU === line.SKU),
    }));
  }, [replenishments, shipment]);

  const isLoading = useMemo(
    () => isLoadingShipment || isLoadingReplenishments,
    [isLoadingReplenishments, isLoadingShipment],
  );
  const isError = useMemo(
    () => isErrorShipment || isErrorReplenishments,
    [isErrorReplenishments, isErrorShipment],
  );

  return {
    data,
    isLoading,
    isError,
  };
};

const useUnitsCacheUpdater = () => {
  const queryClient = useQueryClient();

  return (unit: Api.Unit) => {
    queryClient.setQueryData(
      keys.units.detail(unit.ControllerSerialNumber),
      unit,
    );

    const updater = (units?: Api.Unit[]) =>
      units?.map((u) =>
        u.ControllerSerialNumber === unit.ControllerSerialNumber ? unit : u,
      ) ?? [];

    queryClient.setQueryData<Api.Unit[]>(keys.units.lists(), updater);
    void queryClient.invalidateQueries({ queryKey: keys.units.lists() });
  };
};

export const useMutateUnit = () => {
  const updateUnitsCache = useUnitsCacheUpdater();

  const editUnit = (updatedUnit: Api.Unit) =>
    api.units.update({ ...updatedUnit });

  return useMutation<
    Api.ResponseSuccess<Api.Unit>,
    ApiResponseError<Api.UnitValidationError>,
    Api.Unit
  >({
    mutationFn: editUnit,
    onError: (response) => {
      trackEvent({ category: "Edit unit", action: "Failed", response });
    },
    onSuccess: (response) => {
      trackEvent({ category: "Edit unit", action: "Success" });

      if (!response || !response.SuccessPayload) return;

      const { SuccessPayload: unit } = response;

      updateUnitsCache(unit);
    },
  });
};

/**
 * useUpdateUnit
 *
 * Apply partial updates to a unit.
 */
export const useUpdateUnit = () => {
  const updateUnitsCache = useUnitsCacheUpdater();

  const updateUnit = (partialUnit: Api.AedInspectionComplete) =>
    api.units.partialUpdate(partialUnit);

  return useMutation<
    Api.ResponseSuccess<Api.Unit>,
    ApiResponseError<Api.UnitValidationError>,
    Api.AedInspectionComplete
  >({
    mutationFn: updateUnit,
    onError: (response) => {
      trackEvent({ category: "Update unit", action: "Failed", response });
    },
    onSuccess: (response) => {
      trackEvent({ category: "Update unit", action: "Success" });

      if (!response || !response.SuccessPayload) return;
      const { SuccessPayload: unit } = response;

      updateUnitsCache(unit);
    },
  });
};

export const useScheduleInspection = () => {
  return useMutation<
    Api.ResponseSuccess<Api.Unit>,
    ApiResponseError<Api.UnitValidationError>,
    { controllerSerialNumber: string; dueDate: string }
  >({
    mutationFn: ({
      controllerSerialNumber,
      dueDate,
    }: {
      controllerSerialNumber: string;
      dueDate: string;
    }) =>
      api.units.scheduleAedInspection(
        controllerSerialNumber,
        new Date(dueDate),
      ),
  });
};

export const useRequestUnitAddressChange = () => {
  return useMutation<
    Api.ResponseSuccess<unknown>,
    ApiResponseError<Api.GenericError>,
    { controllerSerialNumber: string; address: Api.AddressChange }
  >({
    mutationFn: ({
      controllerSerialNumber,
      address,
    }: {
      controllerSerialNumber: string;
      address: Api.AddressChange;
    }) => api.units.requestAddressChange(controllerSerialNumber, address),
  });
};

export const useInspectionChecks = () => {
  const companyId = useCurrentCompanyId();

  return useQuery({
    queryKey: keys.inspectionChecks.list(companyId),
    queryFn: () => api.inspectionsChecks.list(companyId),
    enabled: !!companyId,
  });
};

export const useUnitInspectionCompleted = (unitReminderId?: string) => {
  const updateUnitsCache = useUnitsCacheUpdater();
  const updateEscalationCache = useEscalationCacheUpdater();

  const submitInspectionResult = (result: Api.AedInspectionComplete) =>
    api.units.confirmAedInspection({ ...result });

  return useMutation<
    Api.ResponseSuccess<Api.Unit>,
    ApiResponseError<Api.UnitValidationError>,
    Api.AedInspectionComplete
  >({
    mutationFn: submitInspectionResult,
    onError: (response) => {
      trackEvent({
        category: "AED inspection completed",
        action: "Failed",
        response,
      });
    },
    onSuccess: (response) => {
      trackEvent({ category: "AED inspection completed", action: "Success" });

      if (!response || !response.SuccessPayload) return;

      const { SuccessPayload: unit } = response;

      updateUnitsCache(unit);
      updateEscalationCache(unitReminderId);
    },
  });
};

export const useUnitReplenishmentRequested = (unitReminderId?: string) => {
  const updateUnitsCache = useUnitsCacheUpdater();
  const updateEscalationCache = useEscalationCacheUpdater();

  const submitReplenishmentResult = (result: Api.AedReplenishment) =>
    api.units.requestAedReplenishment(result);

  return useMutation<
    Api.ResponseSuccess<Api.Unit>,
    ApiResponseError<Api.UnitValidationError>,
    Api.AedReplenishment
  >({
    mutationFn: submitReplenishmentResult,
    onError: (response) => {
      trackEvent({
        category: "AED replenishment request",
        action: "Failed",
        response,
      });
    },
    onSuccess: (response) => {
      trackEvent({
        category: "AED replenishment request",
        action: "Success",
      });

      if (!response || !response.SuccessPayload) return;

      const { SuccessPayload: unit } = response;

      updateUnitsCache(unit);
      updateEscalationCache(unitReminderId);
    },
  });
};

export const useUnitReplenishmentDeclined = (unitReminderId?: string) => {
  const updateUnitsCache = useUnitsCacheUpdater();
  const updateEscalationCache = useEscalationCacheUpdater();

  const submitReplenishmentDeclined = (result: Api.AedReplenishment) =>
    api.units.declineAedReplenishment(result);

  return useMutation<
    Api.ResponseSuccess<Api.Unit>,
    ApiResponseError<Api.UnitValidationError>,
    Api.AedReplenishment
  >({
    mutationFn: submitReplenishmentDeclined,
    onError: (response) => {
      trackEvent({
        category: "AED replenishment declined",
        action: "Failed",
        response,
      });
    },
    onSuccess: (response) => {
      trackEvent({
        category: "AED replenishment declined",
        action: "Success",
      });

      if (!response || !response.SuccessPayload) return;

      const { SuccessPayload: unit } = response;

      updateUnitsCache(unit);
      updateEscalationCache(unitReminderId);
    },
  });
};

export const useUnitToolReplacementRequested = (unitReminderId?: string) => {
  const updateUnitsCache = useUnitsCacheUpdater();
  const updateEscalationCache = useEscalationCacheUpdater();

  const submitReplenishmentResult = (result: Api.UnitToolReplacement) =>
    api.units.requestUnitToolReplacement(result);

  return useMutation<
    Api.ResponseSuccess<Api.Unit>,
    ApiResponseError<Api.UnitValidationError>,
    Api.UnitToolReplacement
  >({
    mutationFn: submitReplenishmentResult,
    onError: (response) => {
      trackEvent({
        category: "Unit Tool Replacement Request",
        action: "Failed",
        response,
      });
    },
    onSuccess: (response) => {
      trackEvent({
        category: "Unit Tool Replacement Request",
        action: "Success",
      });

      if (!response || !response.SuccessPayload) return;

      const { SuccessPayload: unit } = response;

      updateUnitsCache(unit);
      updateEscalationCache(unitReminderId);
    },
  });
};

export const useUnitToolReplacementDeclined = (unitReminderId?: string) => {
  const updateUnitsCache = useUnitsCacheUpdater();
  const updateEscalationCache = useEscalationCacheUpdater();

  const submitReplenishmentDeclined = (result: Api.UnitToolReplacement) =>
    api.units.declineUnitToolReplacement(result);

  return useMutation<
    Api.ResponseSuccess<Api.Unit>,
    ApiResponseError<Api.UnitValidationError>,
    Api.UnitToolReplacement
  >({
    mutationFn: submitReplenishmentDeclined,
    onError: (response) => {
      trackEvent({
        category: "Unit tool replacement declined",
        action: "Failed",
        response,
      });
    },
    onSuccess: (response) => {
      trackEvent({
        category: "Unit tool replacement declined",
        action: "Success",
      });

      if (!response || !response.SuccessPayload) return;

      const { SuccessPayload: unit } = response;

      updateUnitsCache(unit);
      updateEscalationCache(unitReminderId);
    },
  });
};

export const useUnitReplenished = (unitReminderId?: string) => {
  const updateUnitsCache = useUnitsCacheUpdater();
  const updateEscalationCache = useEscalationCacheUpdater();

  const submitReplenished = (result: Api.AedReplenished) =>
    api.units.confirmAedReplenished(result);

  return useMutation<
    Api.ResponseSuccess<Api.Unit>,
    ApiResponseError<Api.UnitValidationError>,
    Api.AedReplenished
  >({
    mutationFn: submitReplenished,
    onError: (response) => {
      trackEvent({
        category: "AED replenished",
        action: "Failed",
        response,
      });
    },
    onSuccess: (response) => {
      trackEvent({ category: "AED replenished", action: "Success" });

      if (!response || !response.SuccessPayload) return;

      const { SuccessPayload: unit } = response;

      updateUnitsCache(unit);
      updateEscalationCache(unitReminderId);
    },
  });
};

export const useRoles = () =>
  useQuery({ queryKey: ["roles"], queryFn: () => api.roles.list() });

export const useDefaultUserAlerts = () => {
  const { data: roles } = useRoles();

  const queries = (roles ?? []).map((role) => ({
    queryKey: ["defaultUserAlerts", role.Name],
    queryFn: () => api.users.defaultUserAlerts(role.Name),
  }));

  return useQueries({ queries });
};

export const useCompany = () => {
  const companyId = useCurrentCompanyId();

  return useQuery({
    queryKey: keys.company.get(companyId),
    queryFn: () => api.company.get(companyId),
    enabled: !!companyId,
  });
};

export const useIncident = (options: GetIncident) =>
  useQuery({
    queryKey: keys.incidents.get(options),
    queryFn: () => api.incidents.get(options),
    enabled: !!(options && (options.id || options.eventId)),
  });

export const useIncidents = (options: GetIncidentsOptions) =>
  useQuery({
    queryKey: keys.incidents.list(options),
    queryFn: () => api.events.incidents(options),
  });

export const useIncidentStats = (options: GetByGroupOrStore & GetByDateRange) =>
  useQuery({
    queryKey: keys.incidents.stats(options),
    queryFn: () =>
      api.incidents.stats({ ...options, excludeIncidentData: true }),
  });

export const usePaginatedIncidents = (
  options: GetIncidentsOptions & GetByPagination,
) =>
  useInfiniteQuery({
    queryKey: keys.incidents.paginated(options),
    queryFn: ({ pageParam }) =>
      api.events.paginatedIncidents({ ...options, page: pageParam }),

    initialPageParam: 1,
    getNextPageParam: ({
      Pagination: { currentPage, nextPage, totalPages },
    }) => (currentPage === totalPages ? undefined : nextPage),
  });

export const useDashboard = (options: GetDashboardOptions) => {
  let newOptions = options;

  if (options.numberDays) {
    const { numberDays, ...rest } = options;
    const { start, end } = getStartAndEndDatesFromNumberOfDays(numberDays);

    newOptions = {
      ...rest,
      numberDays: -1,
      startDate: start.toISOString(),
      endDate: end.toISOString(),
    };
  } else {
    newOptions = options;
  }

  return useQuery({
    queryKey: ["dashboard", newOptions],
    queryFn: () => api.dashboard(newOptions),
    enabled: !!(
      newOptions.groupId ||
      newOptions.storeId ||
      newOptions.allStores
    ),
    refetchInterval: 1000 * 60 * 1, // 1min
  });
};

export const useTimeZones = () =>
  useQuery({
    queryKey: ["timeZones"],
    queryFn: () => api.location.timeZones(),
  });

export const useCountries = () =>
  useQuery({
    queryKey: ["countries"],
    queryFn: () => api.location.countries(),
  });

export const useCountryCodes = () =>
  useQuery({
    queryKey: ["countryCodes"],
    queryFn: () => api.countryCodes.list(),
    staleTime: Infinity,
  });

export const useCompanyTerminology = () =>
  useQuery({
    queryKey: ["terminology"],
    queryFn: () => api.terminology.get(),
    staleTime: Infinity,
    gcTime: Infinity,
  });

export const useInvoices = (storeId?: string, since?: Date) =>
  useQuery({
    queryKey: ["invoices", storeId, since],
    queryFn: () => api.billing.invoices(storeId ?? "", since ?? new Date()),
    enabled: !!storeId || !!since,
  });

const fetchEscalations = ({
  queryKey: [, , options],
}: QueryFunctionContext<ReturnType<(typeof keys.escalations)["list"]>>) =>
  api.escalations.list(options);

export const useEscalations = (
  { groupId, storeId, storesIds, allStores } = {} as GetByGroupOrStore &
    GetByStoresIds,
) => {
  const select = useCallback(
    (escalations: Api.Escalation[]) =>
      escalations.filter((escalation) =>
        storesIds ? storesIds.includes(escalation.StoreId) : true,
      ),
    [storesIds],
  );

  return useQuery({
    queryKey: keys.escalations.list({ groupId, storeId, allStores }),
    queryFn: fetchEscalations,
    select,
  });
};

const useEscalationCacheUpdater = () => {
  const queryClient = useQueryClient();

  return (unitReminderId?: string) => {
    unitReminderId &&
      queryClient.setQueriesData<Api.Escalation[]>(
        { queryKey: keys.escalations.lists() },
        (escalations) =>
          escalations?.filter(
            (escalation) => escalation.UnitReminderId !== unitReminderId,
          ),
      );

    void queryClient.invalidateQueries({ queryKey: keys.escalations.lists() });
  };
};

const fetchProduct = ({
  queryKey: [{ sku }],
}: QueryFunctionContext<ReturnType<(typeof keys.products)["get"]>>) =>
  api.products.get(sku);

export const useProduct = (sku?: string) =>
  useQuery({
    queryKey: keys.products.get(sku ?? ""),
    queryFn: fetchProduct,
    enabled: !!sku,
  });

export const useOnLogOutFn = () => {
  const { logOut } = useAuth();

  return useCallback(async () => {
    await logOut();
  }, [logOut]);
};

export const useMfaSecret = (UserId?: string) =>
  useQuery({
    queryKey: ["mfaSecret", UserId],
    queryFn: () => api.auth.mfaSecret(UserId ?? ""),
    enabled: !!UserId,
    staleTime: Infinity,
  });

export const useSearch = <T>(initialList?: T[], sel?: (item: T) => string) => {
  const fzf = useRef<Fzf<T[]>>();

  const [q, setQ] = useState<string>("");
  const [results, setResults] = useState<FzfResultItem<T>[]>();
  const [list, setList] = useState<T[] | undefined>(
    initialList && [...initialList],
  );

  useEffect(() => {
    fzf.current = new Fzf<unknown[]>(list ?? [], {
      casing: "case-insensitive",
      selector: (v) => {
        return sel ? sel(v as T) : "";
      },
    });
  }, [list, sel]);

  useEffect(() => {
    if (!list || !fzf.current) return;

    if (!q) {
      setResults(list.map((item) => ({ item }) as FzfResultItem<T>));
      return;
    }

    const results = fzf.current.find(q);

    setResults(results);
  }, [q, list]);

  useEffect(() => {
    fzf.current = new Fzf<unknown[]>([], { selector: () => "" });
  }, []);

  return { results, search: setQ, q, list, setList };
};

export const usePageTitle = (title: string) => {
  const { meta } = useTheme();

  useEffect(() => {
    document.title = title + ` | ${meta.appName}`;
  });
};

export const useReadiness = () =>
  useQuery({ queryKey: ["readiness"], queryFn: () => api.stores.readiness() });

export const useStoreReadinessReasons = (storeId?: string): Readiness => {
  const { data: units, isLoading } = useUnits({ storeId });
  const { data: overallReadiness } = useReadiness();

  const readiness = useMemo(() => {
    if (!units || units.length === 0) return { reason: "", percent: -1 };

    const fieldUnits = units.filter(
      (unit) =>
        unit.Status === "Field" || unit.Status === "RequiresMaintenance",
    );

    if (fieldUnits.length === 0) return { reason: "", percent: -1 };

    const reasons = fieldUnits.reduce(
      (p, unit) =>
        unit.ReadinessReason
          ? p.concat(unit.ReadinessReason.split(". ") as [])
          : p,
      [] as string[],
    );

    if (reasons[reasons.length - 1] === "") reasons.splice(-1, 1);

    const percent =
      overallReadiness?.ByStore.find((store) => store.StoreId === storeId)
        ?.ReadinessPercent ?? -1;

    return {
      reasons,
      percent,
    };
  }, [overallReadiness, storeId, units]);

  return { ...readiness, isLoading };
};

export const useStoresReadiness = (storeIds?: string[]) => {
  const {
    data: units,
    isLoading: isUnitsLoading,
    isError: isUnitsError,
  } = useUnits();
  const {
    data: allReadinesses,
    isLoading: isReadinessLoading,
    isError: isReadinessError,
  } = useReadiness();

  const readinesses = useMemo(() => {
    if (!storeIds || !units) return undefined;

    return storeIds.map((storeId) => {
      const readiness = allReadinesses?.ByStore.find(
        (result) => result.StoreId === storeId,
      );

      return {
        storeId,
        readiness: readiness?.ReadinessPercent,
      };
    });
  }, [allReadinesses, storeIds, units]);

  return {
    readinesses,
    isLoading: isUnitsLoading || isReadinessLoading,
    isError: isUnitsError || isReadinessError,
  };
};

export const useUnitsReadiness = (unitsIds?: string[]) => {
  const {
    data: units,
    isLoading: isUnitsLoading,
    isError: isUnitsError,
  } = useUnits();

  const readinesses = useMemo(() => {
    if (!unitsIds || !units) return undefined;

    return unitsIds.map((unitId) => {
      const readiness = units.find(
        (result) => result.ControllerSerialNumber === unitId,
      )?.ReadinessPercentage;

      return {
        unitId,
        readiness,
      };
    });
  }, [unitsIds, units]);

  return {
    readinesses,
    isLoading: isUnitsLoading,
    isError: isUnitsError,
  };
};

export const useGroupReadinessReasons = (groupId?: string): Readiness => {
  const { data: units, isLoading } = useUnits({ groupId });
  const { data: overallReadiness } = useReadiness();

  const readiness = useMemo(() => {
    if (!units || units.length === 0 || !overallReadiness)
      return { reason: "", percent: -1 };

    const fieldUnits = units.filter((unit) => unit.Status === "Field");

    const reasons = fieldUnits.reduce(
      (p, unit) =>
        unit.ReadinessReason
          ? p.concat(unit.ReadinessReason.split(". ") as [])
          : p,
      [] as string[],
    );

    if (reasons[reasons.length - 1] === "") reasons.splice(-1, 1);

    const eligibleCount =
      overallReadiness?.ByGroup.reduce(
        (count, g) => count + g.EligibleKits,
        0,
      ) ?? 0;

    const percent =
      groupId === "" && eligibleCount > 0
        ? overallReadiness.OverallGroupsReadinessPercent
        : overallReadiness?.ByGroup.find((group) => group.GroupId === groupId)
            ?.ReadinessPercent ?? -1;

    return {
      reasons,
      percent,
    };
  }, [groupId, overallReadiness, units]);

  return { ...readiness, isLoading };
};

export const useDaysGone = ({
  storeId,
  groupId,
  storesIds,
}: GetByGroupOrStore & GetByStoresIds) => {
  const {
    data: store,
    isLoading: isStoreLoading,
    isError: isStoreError,
  } = useStore(storeId);
  const { data: storesInList } = useStores({ storesIds });
  const {
    data: storesInGroup,
    isLoading: isStoresLoading,
    isError: isStoresError,
  } = useStoresInGroup(groupId, true);

  const daysGone = useMemo(() => {
    if (!store && !storesInGroup && !storesInList) return null;
    if (store && !store.LastHighSeverityEventDate) return null;
    if (!store && groupId && storesInGroup && !storesInGroup.length)
      return null;
    if (!store && storesIds && storesInList && !storesInList.length)
      return null;

    // FIXME: This is operating under the wrong assumptions
    const now = new Date();
    const then = store
      ? new Date(store.LastHighSeverityEventDate ?? 0).getTime()
      : (storesInList ?? storesInGroup ?? [])
          .filter((s) => s.LastHighSeverityEventDate)
          .reduce(
            (p, s) =>
              Math.max(p, new Date(s.LastHighSeverityEventDate ?? 0).getTime()),
            -1,
          );

    if (then === -1) return null;

    const v = Math.floor(
      (now.getTime() - new Date(then).getTime()) / (1000 * 60 * 60 * 24),
    );

    return v;
  }, [groupId, store, storesIds, storesInGroup, storesInList]);

  return {
    daysGone,
    isLoading: isStoreLoading || isStoresLoading,
    isError: isStoreError || isStoresError,
  };
};

export const useShipment = (id?: string) =>
  useQuery({
    queryKey: keys.shipments.all,
    queryFn: () => api.shipments.get(id),
    enabled: !!id,
  });

export const useShipments = (options: GetShipmentsOptions) =>
  useQuery({
    queryKey: keys.shipments.list(options),
    queryFn: () => api.shipments.list(options),
  });

export const useShipmentsByIds = (
  ids: string[],
  options: GetShipmentsOptions,
) => {
  const select = useCallback(
    (shipments: Api.Shipment[]) =>
      shipments.filter((shipment) => ids.includes(shipment.ShipmentID)),
    [ids],
  );

  return useQuery({
    queryKey: keys.shipments.list(options),
    queryFn: () => api.shipments.list(options),
    enabled: !!ids,
    select,
  });
};

export const useAcceptTos = () => {
  return useCallback(async () => {
    await api.users.acceptToS();
  }, []);
};

export const useShipmentsForUnit = (
  controllerSerialNumber?: string,
  storeId?: string,
  maxNumberToReturn?: number,
) =>
  useQuery({
    queryKey: keys.shipments.listUnit(
      controllerSerialNumber ?? "",
      storeId ?? "",
      maxNumberToReturn,
    ),
    queryFn: () =>
      api.shipments.listUnit(
        controllerSerialNumber ?? "",
        storeId ?? "",
        maxNumberToReturn,
      ),
    enabled: !!controllerSerialNumber && !!storeId,
  });

export const useActivities = (
  options: GetByGroupOrStore & { numberDays: number },
) =>
  useQuery({
    queryKey: keys.activities.list(options),
    queryFn: async () => api.activity.list(options),
  });

export const useActivitiesForUnit = (
  options: GetByGroupOrStore & { numberDays: number },
  controllerSerialNumber?: string,
) => {
  const select = useCallback(
    (activities: Api.Activity[]) =>
      activities.filter(
        (activity) =>
          activity.ControllerSerialNumber === controllerSerialNumber,
      ),
    [controllerSerialNumber],
  );

  return useQuery({
    queryKey: keys.activities.list(options),
    queryFn: async () => api.activity.list(options),

    enabled: !!controllerSerialNumber,
    select,
  });
};
