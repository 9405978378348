import { LngLat, LngLatBoundsLike, LngLatLike } from "mapbox-gl";
import { MapRef } from "react-map-gl";

function validCoords(coords: LngLatLike) {
  if (coords instanceof LngLat) return true;

  if (
    coords instanceof Array &&
    coords.length === 2 &&
    coords[0] > Number.MIN_SAFE_INTEGER &&
    coords[0] < Number.MAX_SAFE_INTEGER &&
    coords[1] > Number.MIN_SAFE_INTEGER &&
    coords[1] < Number.MAX_SAFE_INTEGER
  ) {
    return true;
  }

  return false;
}

export function findCenter(
  coords: [lng: number, lat: number][],
): LngLatBoundsLike | undefined {
  coords.sort((a, b) => a[1] - b[1]);
  const northMostLat = coords[0];
  const southMostLat = coords[coords.length - 1];
  const c = coords
    .sort((a, b) => a[0] - b[0])
    .reduce<{
      distance: number;
      sw: LngLatLike;
      ne: LngLatLike;
    }>(
      (p, coord, i, arr) => {
        const marker = i === 0 ? arr[arr.length - 1] : arr[i - 1];
        const compareTo = i === 0 ? marker[0] : marker[0] + 360;

        const distance = compareTo - coord[0];

        return distance < p.distance
          ? {
              distance,
              sw: [coord[0], southMostLat[1]],
              ne: [compareTo, northMostLat[1]],
            }
          : p;
      },
      { distance: Infinity, sw: [0, 0], ne: [0, 0] },
    );

  return validCoords(c.sw) && validCoords(c.ne) ? [c.sw, c.ne] : undefined;
}

export function findShortest(
  from: [lng: number, lat: number],
  to: [lng: number, lat: number],
) {
  const oneWay = Math.max(from[0] - to[0], to[0] - from[0]);
  const otherWay = Math.min(to[0] + 360 - from[0], from[0] + 360 - to[0]);

  // Trust me, I know what I'm doing...
  return oneWay < otherWay
    ? [from, to]
    : from[0] - to[0] >= 180
      ? [[to[0] + 360, to[1]], from]
      : [[from[0] + 360, from[1]], to];
}

function projectToXY(mapRef: MapRef, lat: number, long: number) {
  return mapRef.project({
    lat: lat,
    lng: long,
  });
}
function getAngle(
  startx: number,
  starty: number,
  destx: number,
  desty: number,
) {
  const deltay = starty - desty;
  const deltax = startx - destx;
  const angle = Math.atan2(deltay, deltax);
  return toDegrees(angle);
}

function toRadians(degrees: number) {
  return (degrees * Math.PI) / 180;
}

function toDegrees(radians: number) {
  return (radians * 180) / Math.PI;
}

export function calculateSvgRelativeArrowLocation(
  angle: number,
  radius: number,
): number[] {
  angle = 90 - angle;

  const radians = toRadians(angle);
  const y = Math.cos(radians) * radius;
  const x = Math.sin(radians) * radius;
  return [x, y];
}

export function getArrowLocationAndAngle(
  mapRef: MapRef | null,
  from?: { longitude: number; latitude: number },
  to?: { longitude: number; latitude: number },
) {
  if (!to || !from || !mapRef) return null;
  const shortSet = findShortest(
    [from.longitude, from.latitude],
    [to.longitude, to.latitude],
  );
  const isToThenFrom =
    to.latitude == shortSet[0][1] && from.latitude == shortSet[1][1];

  const fromCoords = isToThenFrom ? { ...shortSet[1] } : { ...shortSet[0] };
  const toCoords = isToThenFrom ? { ...shortSet[0] } : { ...shortSet[1] };
  const fromXY = projectToXY(mapRef, fromCoords[1], fromCoords[0]);
  const toXY = projectToXY(mapRef, toCoords[1], toCoords[0]);
  const angle = getAngle(fromXY.x, fromXY.y, toXY.x, toXY.y);
  return { x: toXY.x, y: toXY.y, angle: angle };
}
