import { formatDistanceStrict } from "date-fns";
import { ReactNode, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import useResizeObserver from "use-resize-observer";

import DateRangeForDays from "@components/DateRangeForDays";
import LilSquareThing from "@components/LilSquareThing";
import LocalisedDate from "@components/LocalisedDate";
import { useTooltip } from "@components/Tooltip";
import DS from "@design/system";
import { DateRange } from "@util/DateRange";
import { DateFormat } from "@util/dateFormat";

const MIN_BAR_WIDTH = 8;
const BAR_GAP = 0;

const Container = styled.div.attrs({
  role: "presentation",
})`
  box-sizing: border-box;
  height: 100%;
  padding: ${DS.margins.nanoCss("rl")};

  display: grid;
  grid-auto-flow: column;
  gap: ${BAR_GAP}px;
  justify-content: end;
`;

const BarFill = styled.div`
  overflow: hidden;
  border-radius: 4px 4px 0 0;
`;

const BarContainer = styled.div`
  box-sizing: border-box;
  padding: 2px 2px 0;

  display: grid;
  align-items: end;

  border-radius: 6px 6px 0 0;

  &:hover {
    background: ${({ theme }) => theme.palettes.form.light};
  }
`;

const Bar = <
  TDef extends SegmentDefBase,
  TSegments extends ChartSegments<TDef>,
  TValues extends ChartValues<TSegments>,
>({
  segments,
  bucket,
  width,
  scale,
  emptyLabel,
}: {
  segments: TSegments;
  bucket: Bucket<TValues>;
  width: number;
  scale: number;
  emptyLabel?: string;
}) => {
  const { palettes } = useTheme();

  const tip = useTooltipBuilder(segments, bucket, emptyLabel);
  const { tooltipProps } = useTooltip<HTMLDivElement>(tip);

  return (
    <BarContainer {...tooltipProps} style={{ width }}>
      <BarFill>
        {bucket.count === 0 ? (
          <div style={{ height: 4, background: palettes.body.border }} />
        ) : bucket.values && bucket.values.length ? (
          <>
            {bucket.values.map((v, i) => {
              return (
                <BarFillSegment
                  key={`bar-${bucket.date.start.toISOString()}-${i}`}
                  height={v * scale}
                  color={segments[i].color}
                />
              );
            })}
          </>
        ) : null}
      </BarFill>
    </BarContainer>
  );
};

const BarFillSegment = styled.div<{
  height: number;
  color: string;
}>`
  height: ${({ height }) => height}px;
  background: ${({ color }) => color};
`;

const useTooltipBuilder = <
  TDef extends SegmentDefBase,
  TSegments extends ChartSegments<TDef>,
  TValues extends ChartValues<TSegments>,
>(
  segments: TSegments,
  { date, values }: Bucket<TValues>,
  emptyLabel?: string,
) => {
  const { palettes } = useTheme();

  const parts: ReactNode[] = values
    .map(
      (v, i) =>
        v > 0 && (
          <span
            key={`part-${i}-${v}`}
            style={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <LilSquareThing color={segments[i].color} />
            {v} {segments[i].label}
          </span>
        ),
    )
    // Strip the empties out _after_, so that the previous step can maintain
    // the correct index.
    .filter((s) => s);

  if (parts.length === 0) parts.push(emptyLabel ?? "No values");

  const span =
    date.start.getDate() !== date.end.getDate() ? (
      <>
        {" "}
        —{" "}
        <span>
          {formatDistanceStrict(date.start, date.end, { unit: "day" })}
        </span>
      </>
    ) : null;

  const dateStr =
    date.start.getDate() === date.end.getDate() ? (
      <span style={{ color: palettes.tooltip.small }}>
        (<LocalisedDate dateTime={date.start} format={DateFormat.short} />)
      </span>
    ) : (
      <span style={{ color: palettes.tooltip.small }}>
        (<DateRangeForDays startDate={date.start} endDate={date.end} />)
      </span>
    );

  return (
    <span style={{ display: "flex", gap: 4 }}>
      {parts}
      {span}
      {dateStr}
    </span>
  );
};

export type SegmentDefBase = { label: string; color: string };
export type ChartSegments<TDef extends SegmentDefBase> = [] | TDef[];

export type ChartValues<T extends ChartSegments<SegmentDefBase>> = {
  [K in keyof T]: number;
};

export type Bucket<T> = {
  date: DateRange;
  values: T;
  count: number;
};

const Chart = <
  TDef extends SegmentDefBase,
  TSegments extends ChartSegments<TDef>,
  TValues extends ChartValues<TSegments>,
>({
  segments,
  data,
  emptyLabel,
  height = 144,
  barWidth,
  maxValue,
}: {
  segments: TSegments;
  data: Bucket<TValues>[];
  emptyLabel?: string;
  height?: number;
  barWidth?: number;
  maxValue?: number;
}) => {
  const { ref, width } = useResizeObserver();

  const { dynamicData, dynamicBarWidth } = useMemo(() => {
    if (!width)
      return { dynamicData: data, dynamicBarWidth: barWidth ?? MIN_BAR_WIDTH };

    let dynamicData = [...data];
    let attempt = 1;

    while (
      attempt < 10 && // Cap it so we don't loop forever 👀
      ((width ?? 1) + BAR_GAP) / dynamicData.length - BAR_GAP <= MIN_BAR_WIDTH
    ) {
      dynamicData = dynamicData.reduce((p, b, i, arr) => {
        const nextBucket = arr[i + 1];

        if (i % 2 && nextBucket) {
          const values: TValues = [...b.values];

          for (let i = 0; i < b.values.length; i++) {
            values[i] = values[i] + nextBucket.values[i];
          }

          const newBucket: Bucket<TValues> = {
            date: { start: b.date.start, end: nextBucket.date.end },
            count: b.count + nextBucket.count,
            values,
          };

          return [...p, newBucket];
        } else {
          return p;
        }
      }, [] as Bucket<TValues>[]);

      attempt++;
    }

    const w = barWidth
      ? barWidth
      : ((width ?? 1) + BAR_GAP) / dynamicData.length - BAR_GAP;

    return { dynamicData, dynamicBarWidth: w };
  }, [barWidth, data, width]);

  const meta = useMemo(() => {
    const max =
      maxValue ?? dynamicData.reduce((p, b) => Math.max(p, b.count), 0);

    const scale = max ? height / max : 1;

    return { max, scale };
  }, [dynamicData, height, maxValue]);

  return (
    <Container ref={ref}>
      {dynamicData.map((b, i) => (
        <Bar
          key={`bar-${i}`}
          bucket={b}
          segments={segments}
          emptyLabel={emptyLabel}
          width={dynamicBarWidth}
          scale={meta.scale}
        />
      ))}
    </Container>
  );
};

export default Chart;
