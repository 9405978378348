import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import api, { isApiResponseError } from "@api";
import ErrorWell from "@components/ErrorWell";
import { BodyText, LargeBrandTitle, Well } from "@design/helpers";
import DS from "@design/system";

import { LinkButton } from "../components/SecondaryButton";

const EmailSent = ({
  email: email,

  onResendRecoveryClick,
  onBackToLoginClick,
}: {
  email?: string;

  onResendRecoveryClick: (e: React.MouseEvent) => void;
  onBackToLoginClick: (e: React.MouseEvent) => void;
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = useForm<{ email: string }>({ defaultValues: { email: email } });

  const onSubmit: React.FormEventHandler = useCallback(
    (e) =>
      void handleSubmit(async ({ email }: { email: string }) => {
        setErrorMessage(null);

        try {
          await api.requestPasswordReset(email);
        } catch (e) {
          setError("root", {
            message: "Something west wrong. Please try again",
          });

          if (!isApiResponseError<Api.UserValidationError>(e)) return;

          const { error } = e;

          if (error.isServerError) {
            setErrorMessage(
              "We encountered an issue on our side. Please try again soon.",
            );
          } else {
            setError("root", {
              message:
                e.error.all[0].Description ||
                "There was an issue sending your recovery link. Please try again.",
            });
          }
        }
      })(e),
    [handleSubmit, setError],
  );

  return (
    <form style={{ height: "100%" }} action="#" onSubmit={onSubmit}>
      <div
        style={{
          height: "100%",
          display: "grid",
          gap: DS.margins.regular,
          alignContent: "center",
        }}
      >
        <LargeBrandTitle>Email Sent</LargeBrandTitle>

        {errorMessage && <ErrorWell>{errorMessage}</ErrorWell>}
        {errors.email && <ErrorWell>{errors.email.message}</ErrorWell>}

        <BodyText>
          We have sent a recovery email to the supplied address. Please follow
          the instructions attached.
        </BodyText>

        <Well
          style={{
            overflow: "hidden",
            textAlign: "center",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <strong title={email}>{email}</strong>
        </Well>

        <div>
          <input type="hidden" {...register("email")} />

          <LinkButton
            type="submit"
            disabled={isSubmitting}
            onClick={onResendRecoveryClick}
          >
            {isSubmitting ? "Resending…" : "Resend recovery link"}
          </LinkButton>

          <span>&bull;</span>
          <LinkButton onClick={onBackToLoginClick}>Return to log in</LinkButton>
        </div>
      </div>
    </form>
  );
};

export default EmailSent;
