interface IconProps {
  size?: number;
  main?: string;
  shade?: string;
}

export const Offline100 = ({ size = 60 }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 11.3761C10 5.09328 14.8985 0 20.9412 0H59.0588C65.1015 0 70 5.09328 70 11.3761V68.6239C70 74.9067 65.1015 80 59.0588 80H20.9412C14.8985 80 10 74.9067 10 68.6239V11.3761Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M17.4116 22.7948C17.4116 15.9521 23.1796 10.6578 29.7099 11.5065L53.004 14.534C58.4793 15.2457 62.5881 20.0851 62.5881 25.8223V55.0417C62.5881 60.8795 58.3383 65.7697 52.7524 66.3596L29.4583 68.8195C23.0117 69.5002 17.4116 64.2389 17.4116 57.5015V22.7948Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M10 11C10 4.92487 14.9249 0 21 0H59C65.0751 0 70 4.92487 70 11V69C70 75.0751 65.0751 80 59 80H21C14.9249 80 10 75.0751 10 69V11Z"
      fill="#DF5959"
    />
    <path
      d="M55.8428 50.5393C57.1742 49.2328 58 47.4129 58 45.3999C58 41.918 55.525 39.0099 52.24 38.3462C52.4706 37.7443 52.6 37.0862 52.6 36.3999C52.6 33.4187 50.1813 30.9999 47.2 30.9999C46.0919 30.9999 45.0569 31.3374 44.2019 31.9112C42.6438 29.2112 39.7356 27.3999 36.4 27.3999C35.297 27.3999 34.2405 27.5981 33.2642 27.9608L55.8428 50.5393Z"
      fill="#812424"
    />
    <path
      d="M27.9609 33.264C27.5982 34.2404 27.4 35.2969 27.4 36.3999C27.4 36.5518 27.4056 36.7037 27.4113 36.8555C24.2613 37.9637 22 40.9674 22 44.4999C22 48.9718 25.6281 52.5999 30.1 52.5999H47.2967L27.9609 33.264Z"
      fill="#812424"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55 55C54.4138 55.5861 53.4635 55.5861 52.8774 55L26.7594 28.882C26.1733 28.2959 26.1733 27.3456 26.7594 26.7594C27.3456 26.1733 28.2959 26.1733 28.882 26.7594L55 52.8774C55.5861 53.4635 55.5861 54.4138 55 55Z"
      fill="#812424"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="40"
        y1="0"
        x2="40"
        y2="80"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4F4F4" />
        <stop offset="0.473958" stopColor="#F5F5F5" />
        <stop offset="1" stopColor="#EAEAEA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="39.9999"
        y1="9.90812"
        x2="39.9999"
        y2="70.0916"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EEEEEE" />
        <stop offset="0.473958" stopColor="#E8E8E8" />
        <stop offset="1" stopColor="#E3E3E3" />
      </linearGradient>
    </defs>
  </svg>
);
