import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import { useMemo } from "react";
import styled from "styled-components";

import DS from "@design/system";

const Tr = styled.tr<{
  selected?: boolean;
  trimColor?: string;
  footerVisible?: boolean;
  showPointer?: boolean;
}>`
  display: contents;

  cursor: ${({ showPointer }) => (showPointer ? "pointer" : "default")};

  td:first-child {
    position: relative;
    overflow: hidden;
  }

  &:last-child td {
    border-bottom: ${({ theme, footerVisible }) =>
      footerVisible ? "none" : `solid 1px ${theme.palettes.body.border}`};
  }

  td:first-child:before {
    content: "";

    position: absolute;
    top: 50%;
    left: 0;
    width: 3px;
    height: 32px;

    background: ${({ theme, selected, trimColor }) =>
      trimColor
        ? trimColor
        : selected
          ? theme.palettes.body.accent
          : theme.palettes.body.background};

    border-radius: 2px;

    transform: ${({ selected, trimColor }) =>
      trimColor || selected
        ? "translate3d(0px, -50%, 0) scaleY(1)"
        : "translate3d(-100%, -50%, 0) scaleY(0.8)"};

    transition: all 150ms ease-out;
  }

  td:first-child > * {
    border-radius: 4px 0 0 4px;
  }

  td:last-child > * {
    border-radius: 0 4px 4px 0;
    border-bottom: none;
  }

  td > * {
    background: ${({ theme, selected }) =>
      selected ? theme.palettes.itemSelected.background : "transparent"};
  }

  &:hover td > * {
    background: ${({ selected, theme }) =>
      selected
        ? theme.palettes.itemSelected.dim
        : theme.palettes.itemSelected.light};

    ${({ theme }) => theme.palettes.body.border};
  }
`;

const Td = styled.td`
  padding-top: ${DS.margins.nano};
  padding-bottom: ${DS.margins.nano};

  border-bottom: solid 1px ${({ theme }) => theme.palettes.body.border};
`;

const Th = styled.th`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: ${DS.margins.nano};
  margin-bottom: ${DS.margins.nano};
  z-index: 1;
  padding: ${DS.margins.micro};
  background: ${({ theme }) => theme.palettes.well.background};
  color: ${({ theme }) => theme.palettes.well.foreground};

  font-weight: 700;

  &:first-child {
    border-top-left-radius: ${DS.radii.largeItem};
    border-bottom-left-radius: ${DS.radii.largeItem};
  }

  &:last-child {
    border-top-right-radius: ${DS.radii.largeItem};
    border-bottom-right-radius: ${DS.radii.largeItem};
  }
`;

export type Meta = {
  width: string;
};

export const Table = <TData extends unknown>({
  data,
  columns,
  columnVisibility,
  isSelectedRow,
  rowTrimColor,
  onRowClick,
  showHeader = true,
  showFooter = false,
  ...rest
}: {
  data: TData[];
  columns: ColumnDef<TData>[];
  columnVisibility?: VisibilityState;
  isSelectedRow?: (row: Row<TData>) => boolean;
  rowTrimColor?: (row: Row<TData>) => string;
  onRowClick?: (row: Row<TData>) => void;
  showHeader?: boolean;
  showFooter?: boolean;
} & React.TableHTMLAttributes<HTMLTableElement>) => {
  const table = useReactTable({
    data,
    columns,
    state: { columnVisibility },
    getCoreRowModel: getCoreRowModel(),
  });

  const width = useMemo(
    () =>
      table
        .getVisibleFlatColumns()
        .map((col) => (col.columnDef?.meta as Meta)?.width ?? "auto")
        .join(" "),

    // After trying a few different options, this was the easiest and most
    // reliable way of updating the width when the column visiblity changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, columnVisibility],
  );

  return (
    <table
      cellSpacing={0}
      cellPadding={0}
      style={{
        boxSizing: "border-box",
        width: "100%",
        display: "grid",

        gridTemplateColumns: width,
      }}
      {...rest}
    >
      {showHeader && (
        <thead
          style={{
            display: "contents",
            padding: "16px",
            width: "100%",
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id} selected={false}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </thead>
      )}
      <tbody style={{ display: "contents" }}>
        {table.getRowModel().rows.map((row) => (
          <Tr
            key={row.id}
            selected={!!isSelectedRow && isSelectedRow(row)}
            trimColor={rowTrimColor?.(row)}
            showPointer={!!onRowClick}
            onClick={() => onRowClick && onRowClick(row)}
            footerVisible={showFooter}
          >
            {row.getVisibleCells().map((cell) => (
              <Td key={cell.id}>
                <div
                  style={{
                    boxSizing: "border-box",
                    height: "100%",
                    padding: DS.margins.micro,
                    display: "grid",
                    alignItems: "center",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              </Td>
            ))}
          </Tr>
        ))}
      </tbody>

      {showFooter && (
        <tfoot
          style={{
            display: "contents",
            padding: "16px",
            width: "100%",
          }}
        >
          {table.getFooterGroups().map((footerGroup) => (
            <Tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {flexRender(
                    header.column.columnDef.footer,
                    header.getContext(),
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </tfoot>
      )}
    </table>
  );
};
