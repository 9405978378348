import styled, { useTheme } from "styled-components";

import DS from "@design/system";
import { useUser } from "@state/hooks";
import ContentLoader from "@util/ContentLoader";
import { useThemeHelper } from "@util/useThemeHelper";

import { useTooltip } from "./Tooltip";

export interface AvatarProps {
  userId: string;
  imageUrl: string;
  size?: number;
  shape?: "round" | "square";
}

const ImageContainer = styled.div<
  Pick<AvatarProps, "shape" | "size" | "imageUrl">
>`
  width: 1em;
  height: 1em;

  font-size: ${({ size }) => size}px;

  background: ${({ imageUrl }) =>
    imageUrl ? "transparent" : DS.palette.silver};
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;

  border-radius: ${({ shape }) =>
    shape === "round" ? "0.5em" : DS.radii.item};
`;

const GeneratedContainer = styled.div<Pick<AvatarProps, "shape" | "size">>`
  width: 1em;
  height: 1em;

  font-size: ${({ size }) => size}px;
  font-weight: 700;

  border-radius: ${({ shape }) =>
    shape === "round" ? "0.5em" : DS.radii.item};

  display: grid;
  align-content: center;
  justify-content: center;
`;

export const AvatarPlaceholder = ({
  size = 32,
  shape = "square",
}: {
  size?: number;
  shape?: "square" | "round";
}) => (
  <ContentLoader width={size} height={size}>
    <rect
      width={size}
      height={size}
      rx={shape === "square" ? DS.radii.item : size}
    />
  </ContentLoader>
);

const Avatar = ({
  userId,
  imageUrl,
  shape = "square",
  size = 32,
}: AvatarProps) => {
  const { palettes } = useTheme();
  const { rolePalette } = useThemeHelper();

  const { data: user } = useUser(userId);

  const userName = user?.FullName ?? "Aa";
  const role = user?.Roles[0];
  const initials = `${userName[0].toUpperCase()}${userName[1]}`;

  const { tooltipProps } = useTooltip<HTMLDivElement>(
    <>
      <strong>{userName}</strong>{" "}
      <span style={{ color: palettes.tooltip.small }}>{role?.DisplayName}</span>
    </>,
  );

  // FIXME: Logged in user currently send "defaultavatar.png" as the PhotoUrl
  // to address an issue in Prox. Once the fix for that has been published, the
  // backend will be updated to no longer return this.
  return imageUrl && !imageUrl.endsWith("defaultavatar.png") ? (
    <ImageContainer shape={shape} size={size} imageUrl={imageUrl} />
  ) : (
    <GeneratedContainer
      {...tooltipProps}
      shape={shape}
      size={size}
      title={userName}
      style={rolePalette(role?.Name).toCss()}
    >
      <span style={{ fontSize: "0.5em" }}>{initials}</span>
    </GeneratedContainer>
  );
};

export default Avatar;
