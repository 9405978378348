import { useCallback } from "react";
import { useTheme } from "styled-components";

import GroupHeader from "@components/GroupHeader";
import Icon from "@components/Icon";
import Linkable from "@components/Linkable";
import ListItem, { ListItemPlaceholder } from "@components/ListItem";
import Message from "@components/Message";
import StoreTime from "@components/StoreTime";
import {
  IconWell,
  MediumText,
  SmallText,
  StrongText,
  Well,
} from "@design/helpers";
import DS from "@design/system";
import IncidentSidePanel from "@sidepanels/IncidentSidePanel";
import { useIncident, useProduct, useUnit } from "@state/hooks";
import { BoxLoader } from "@util/ContentLoader";
import { useSidePanels } from "@util/SidePanels";
import { DateFormat } from "@util/dateFormat";
import { useTerminologies } from "@util/useTerminologies";
import { useThemeHelper } from "@util/useThemeHelper";
import { EscalationProps } from "@util/viewModel";

const MissingTool = ({
  escalation,
}: EscalationProps<
  Api.MissingToolEscalation | Api.PutMultiUseItemBackIntoKitEscalation
>) => {
  const { palettes } = useTheme();
  const { eventCategoryIcon, severityPalette } = useThemeHelper();
  const { getTitle } = useTerminologies();

  const { push } = useSidePanels();
  const { data: product } = useProduct(escalation.SKU);
  const { data: unit } = useUnit(escalation.ControllerSerialNumber);
  const { data: incident, fetchStatus: incidentStatus } = useIncident({
    eventId: escalation.RelatedEventHistoryId,
  });

  const handleIncidentClick = useCallback(() => {
    if (!incident) return;

    push({
      id: `incident-${incident.incidentId}`,
      content: <IncidentSidePanel incident={incident} />,
    });
  }, [incident, push]);

  return (
    <div
      style={{
        overflow: "hidden",
        padding: `${DS.margins.micro} ${DS.margins.regular}`,
        display: "grid",
        gap: DS.margins.regular,
      }}
    >
      <Message type="notice">
        If this item is not returned to the First Aid Cabinet within{" "}
        <strong>3 days</strong>, then a new item will be dispatched to keep the
        cabinet compliant.
      </Message>

      {product && unit ? (
        <div style={{ position: "relative" }}>
          <img
            style={{
              display: "block",
              background: palettes.well.background,
              borderRadius: DS.radii.item,
            }}
            width={288}
            height={288}
            src={product.ImageUrl}
            alt={product.Name}
          />
        </div>
      ) : (
        <BoxLoader title="Loading product image…" width={288} height={288} />
      )}

      <Well>
        <StrongText>{product?.Name}</StrongText>
        <MediumText style={{ color: palettes.well.small }}>
          {escalation.StoreName} ({unit?.UnitName})
        </MediumText>
      </Well>

      <div>
        <GroupHeader>Related Incident</GroupHeader>
        {incident ? (
          <Linkable onClick={handleIncidentClick}>
            <ListItem
              image={
                <Icon
                  name={eventCategoryIcon(incident.category)}
                  color={
                    severityPalette(
                      incident.incidentOverallSeverity ??
                        incident.severityLevel,
                    ).foreground
                  }
                />
              }
              title={getTitle(incident)}
              description={
                <StoreTime
                  storeName={incident.primaryEvent?.storeName ?? ""}
                  dateTime={incident.date}
                  format={DateFormat.dateTimeAndTimeZone}
                />
              }
              linked
            />
          </Linkable>
        ) : !incident && incidentStatus === "idle" ? (
          <IconWell
            style={{
              color: palettes.messages.notice.foreground,
              background: palettes.messages.notice.background,
            }}
          >
            <Icon name="question-circle" />
            <SmallText style={{ color: palettes.messages.notice.foreground }}>
              Related incident not found
            </SmallText>
          </IconWell>
        ) : (
          <ListItemPlaceholder />
        )}
      </div>
    </div>
  );
};

export default MissingTool;
