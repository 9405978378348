import { motion } from "framer-motion";
import { Fragment, useMemo } from "react";
import styled, { useTheme } from "styled-components";

import LocalisedDate from "@components/LocalisedDate";
import { SmallText } from "@design/helpers";
import DS from "@design/system";
import { StatePalette } from "@themes/types";
import { DateFormat } from "@util/dateFormat";
import { getShipmentStatusIcon } from "@util/shipments";
import { useThemeHelper } from "@util/useThemeHelper";

import { IIcon } from "../icons";
import Icon from "./Icon";
import { Link } from "./SecondaryButton";
import { useTooltip } from "./Tooltip";

const MAX_DOTS = 3;

const getStatusStep = (status: Api.ShipmentStatus) => {
  switch (status) {
    // Stage 1: Shipment is with us
    case "Created":
    case "LabelPending":
    case "LabelCreated":
    case "Printed": // StarShipIt
    case "AwaitingCollection": // StarShipIt
    case "AwaitingDispatch": // Toll
      return 1;

    // Stage 2: Shipment is on its way
    case "Sent":
    case "Dispatched": // StarShipIt
    case "InTransit": // StarShipIt
    case "OutForDelivery": // StarShipIt
    case "PickupInStore": // StarShipIt
    case "AttemptedDelivery": // StarShipIt
      return 2;

    // Stage 3: Shipment is with the customer
    case "Unpacking":
    case "OverdueUnpacking":
    case "Delivered": // StarShipIt
      return 3;

    // Stage 4: Shipment has been completed or cancelled.
    case "Complete":
    case "NeverCompleted":
    case "Cancelled":
    case "LabelCreationError":
    case "Exception": // StarShipIt
      return 4;

    default:
      return 1;
  }
};

export const getStatusLabel = (status: Api.ShipmentStatus) => {
  switch (status) {
    case "Created":
    case "LabelPending":
    case "LabelCreated":
    case "Printed": // StarShipIt
    case "AwaitingCollection": // StarShipIt
    case "AwaitingDispatch": // Toll
      return "Preparing Shipment";

    case "Sent":
    case "Dispatched": // StarShipIt
    case "InTransit": // StarShipIt
    case "OutForDelivery": // StarShipIt
    case "PickupInStore": // StarShipIt
    case "AttemptedDelivery": // StarShipIt
      return "Sent";

    case "Unpacking":
    case "Delivered":
      return "Unpacking";

    case "OverdueUnpacking":
      return "Unpacking Overdue";

    case "Complete":
      return "Completed";

    case "NeverCompleted":
    case "Cancelled":
    case "LabelCreationError":
    case "Exception": // StarShipIt
      return "Cancelled";

    default:
      return status;
  }
};

const getStatusValue = (status: Api.ShipmentStatus) => {
  switch (status) {
    // Notice - All states before shipment has been sent, or where there is
    // nothing the customer can do.
    case "Created":
    case "LabelPending":
    case "LabelCreated":
    case "Printed": // StarShipIt
    case "AwaitingCollection": // StarShipIt
    case "AwaitingDispatch": // Toll
    case "Sent":
    case "Dispatched": // StarShipIt
    case "InTransit": // StarShipIt
    case "OutForDelivery": // StarShipIt
      return 2;

    // Warning - All states where the customer needs to do something
    case "PickupInStore": // StarShipIt
    case "AttemptedDelivery": // StarShipIt
    case "Unpacking":
    case "Delivered": // StarShipIt
      return 3;

    // Positive - All sucessfully completed shipments
    case "Complete":
      return 1;

    // Danger - All states where action is urgently needed or shipment cancelled
    case "OverdueUnpacking":
    case "NeverCompleted":
    case "Cancelled":
    case "LabelCreationError":
    case "Exception": // StarShipIt
      return 4;

    // Neutral - Unknown states
    default:
      return 0;
  }
};

const Dot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: ${DS.radii.pill};
`;

const Summary = ({ shipment }: { shipment: Api.Shipment }) => {
  const { shipmentStatusPalette } = useThemeHelper();

  const { tooltipProps } = useTooltip<HTMLAnchorElement>(
    "Track status on courier's site (opens in new tab)",
  );

  const { background } = useMemo(
    () => shipmentStatusPalette(shipment.Status),
    [shipment.Status, shipmentStatusPalette],
  );

  return (
    <div
      style={{
        display: "grid",
        gridTemplateAreas: "'tracking status' 'courier courier'",
        columnGap: DS.margins.regular,
      }}
    >
      <strong
        style={{
          gridArea: "tracking",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {shipment.TrackingNumber}
      </strong>

      <SmallText style={{ gridArea: "courier" }}>
        {shipment.TrackingUrl ? (
          <Link {...tooltipProps} href={shipment.TrackingUrl} target="_blank">
            {shipment.Courier}
          </Link>
        ) : (
          shipment.Courier
        )}
      </SmallText>
      <div
        style={{
          gridArea: "status",

          fontWeight: 700,
          color: background,

          display: "flex",
          gap: DS.margins.nano,
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <div
          style={{
            width: 8,
            height: 8,
            background,
            borderRadius: DS.radii.pill,
          }}
        />
        {getStatusLabel(shipment.Status)}
      </div>
    </div>
  );
};

const progressVariants = {
  hidden: {},
  show: {
    transition: {
      duration: 0.1,
      staggerChildren: 0.1,
    },
  },
};

const dotVariants = {
  hidden: {
    transform: "scale(0)",
  },
  show: {
    transform: "scale(1)",
  },
};

const lineVariants = {
  hidden: {
    transform: "scaleX(0)",
  },
  show: {
    transform: "scaleX(1)",
    transition: {
      duration: 0.1,
    },
  },
};

const ProgressDot = styled(motion.div)`
  width: 8px;
  height: 8px;
  border-radius: 4px;
`;

const ProgressLine = styled(motion.div)`
  height: 4px;
  border-radius: 2px;

  transform-origin: center left;
`;

const CurrentStepDot = styled(motion.div)`
  width: 28px;
  height: 28px;
  border-radius: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Progress = ({
  steps,
  current,
  icon,
  palette,
}: {
  steps: number;
  current: number;
  icon: IIcon;
  palette: StatePalette;
}) => {
  const { palettes } = useTheme();

  return (
    <motion.div
      variants={progressVariants}
      initial="hidden"
      animate="show"
      style={{
        gridArea: "progress",
        display: "grid",
        gridTemplateColumns: Array.from(new Array(steps))
          .map((_, i, array) => "auto" + (i === array.length - 1 ? "" : " 1fr"))
          .join(" "),
        gap: DS.margins.nano,
        alignItems: "center",
        justifyContent: "stretch",
      }}
    >
      {Array.from(new Array(steps).keys()).map((v, i, array) => (
        <Fragment key={`progress-${v}`}>
          {i === current - 1 ? (
            <CurrentStepDot
              key={`dot-large-${v}`}
              variants={dotVariants}
              style={{
                background: palette.background,
              }}
            >
              <Icon name={icon} size={14} color={palette.foreground} />
            </CurrentStepDot>
          ) : (
            <ProgressDot
              key={`dot-small-${v}`}
              variants={dotVariants}
              style={{
                background:
                  current <= i ? palettes.body.border : palette.background,
              }}
            />
          )}
          {i !== array.length - 1 && (
            <ProgressLine
              variants={lineVariants}
              key={`line-${v}`}
              style={{
                background:
                  current <= i + 1 ? palettes.body.border : palette.background,
              }}
            />
          )}
        </Fragment>
      ))}
    </motion.div>
  );
};

const Dates = ({ shipment }: { shipment: Api.Shipment }) => (
  <div
    style={{
      gridArea: "dates",

      marginTop: DS.margins.micro,

      display: "grid",
      gridAutoFlow: "column",
      justifyContent: "space-between",
    }}
  >
    <SmallText>
      {shipment.ShippedDate ? (
        <>
          <strong>Shipped</strong>{" "}
          <LocalisedDate
            dateTime={shipment.ShippedDate}
            format={DateFormat.short}
          />
        </>
      ) : (
        <>
          <strong>Created</strong>{" "}
          <LocalisedDate
            dateTime={shipment.CreatedDate}
            format={DateFormat.short}
          />
        </>
      )}
    </SmallText>
    <SmallText>
      {shipment.DeliveryDate && (
        <>
          <strong>Arrived</strong>{" "}
          <LocalisedDate
            dateTime={shipment.DeliveryDate}
            format={DateFormat.short}
          />
        </>
      )}
    </SmallText>
  </div>
);

const ShipmentCard = ({
  shipments = [],
  size = "dot",
}: {
  shipments?: Api.Shipment[];
  size?: "dot" | "minimal" | "full" | "progress";
}) => {
  const { palettes } = useTheme();
  const { shipmentStatusPalette } = useThemeHelper();

  return (
    <div
      style={{
        display: "grid",
        gap: 8,
      }}
    >
      {size === "dot" ? (
        <div
          style={{
            padding: DS.margins.micro,
            display: "grid",
            gridAutoFlow: "column",
            gap: DS.margins.micro,
          }}
        >
          {shipments
            .sort((a, b) => getStatusValue(b.Status) - getStatusValue(a.Status))
            .slice(0, shipments.length > MAX_DOTS ? MAX_DOTS - 1 : MAX_DOTS)
            .map((shipment) => (
              <Dot
                key={shipment.ShipmentID}
                style={{
                  background: shipmentStatusPalette(shipment.Status).background,
                }}
              />
            ))}
          {shipments.length > MAX_DOTS && (
            <div style={{ position: "relative", paddingRight: 8 }}>
              <Dot
                style={{
                  fontSize: 11,
                  fontWeight: 600,
                  textAlign: "center",
                  lineHeight: "16px",
                }}
              >
                + {shipments.length - MAX_DOTS + 1}
              </Dot>
              <Dot
                style={{
                  position: "absolute",
                  zIndex: -1,
                  top: 0,
                  left: 4,
                }}
              />
              <Dot
                style={{
                  position: "absolute",
                  zIndex: -2,
                  top: 0,
                  left: 8,
                }}
              />
            </div>
          )}
        </div>
      ) : (
        shipments.map((shipment, i, array) => (
          <Fragment key={shipment.ShipmentID}>
            <div
              key={shipment.ShipmentID}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: DS.margins.micro,
              }}
            >
              {size === "full" ? (
                <>
                  <Summary shipment={shipment} />
                  <div>
                    <Progress
                      steps={4}
                      current={getStatusStep(shipment.Status)}
                      icon={getShipmentStatusIcon(shipment.Status)}
                      palette={shipmentStatusPalette(shipment.Status)}
                    />
                    <Dates shipment={shipment} />
                  </div>
                </>
              ) : size === "progress" ? (
                <div>
                  <Progress
                    steps={4}
                    current={getStatusStep(shipment.Status)}
                    icon={getShipmentStatusIcon(shipment.Status)}
                    palette={shipmentStatusPalette(shipment.Status)}
                  />
                  <Dates shipment={shipment} />
                </div>
              ) : (
                <Summary shipment={shipment} />
              )}
            </div>
            {i !== array.length - 1 && (
              <div
                style={{
                  borderBottom: "solid 1px",
                  borderBottomColor: palettes.body.border,
                }}
              />
            )}
          </Fragment>
        ))
      )}
    </div>
  );
};

export default ShipmentCard;
