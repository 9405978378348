const exists = <T>(target: T, collection: T[], getKey: (item: T) => string) =>
  !!collection.find((item) => getKey(item) === getKey(target));

/**
 * Takes a recursive tree and extracts and flattens a specific object from that tree. Is able to remove duplicates via the given `searchPredicate`.
 *
 * @param nodes The initial array of nodes to collect.
 * @param getNodeCollection Function to get the next node collection from the given node.
 * @param getNestedCollection Function to get the _other_ collection, the one you really want.
 * @param searchPredicate Function used to compare nodes for equality, and avoid adding duplicates
 * @param collection Starting collection. Defaults to an empty array.
 */
export const flattenTree = <Node, Out>(
  nodes: Node[],
  getNodeCollection: (node: Node) => Node[],
  getNestedCollection: (node: Node) => Out[],
  searchPredicate: (out: Out) => string,
  collection: Out[] = [],
): Out[] => {
  for (const node of nodes) {
    flattenTree(
      getNodeCollection(node),
      getNodeCollection,
      getNestedCollection,
      searchPredicate,
      collection,
    );

    const toAdd = getNestedCollection(node).filter(
      (outItem) => !exists(outItem, collection, searchPredicate),
    );

    collection.push(...toAdd);
  }

  return removeDuplicates(collection, searchPredicate);
};

export function findInTree<Node>(
  nodes: Node[],
  predicate: (node: Node) => boolean,
  getNestedNodes: (node: Node) => Node[],
): Node | null;

export function findInTree<Node>(
  nodes: Node[],
  predicate: (node: Node) => boolean,
  getNestedNodes: (node: Node) => Node[],
  findAll: boolean,
): Node[] | null;

export function findInTree<Node>(
  nodes: Node[],
  predicate: (node: Node) => boolean,
  getNestedNodes: (node: Node) => Node[],
  findAll?: boolean,
): Node[] | Node | null {
  const collection = [];

  // Try the top nodes first
  for (const node of nodes) {
    if (predicate(node)) {
      if (findAll) {
        collection.push(node);
      } else {
        return node;
      }
    }
  }

  // No try digging deeper
  for (const node of nodes) {
    if (findAll) {
      const result = findInTree(
        getNestedNodes(node),
        predicate,
        getNestedNodes,
        findAll,
      );

      if (result) return [...collection, ...result];
    } else {
      const found = findInTree(getNestedNodes(node), predicate, getNestedNodes);

      if (found) return found;
    }
  }

  if (findAll) {
    return collection.length > 0 ? collection : null;
  } else {
    return collection.length > 0 ? collection[0] : null;
  }
}

/**
 * Takes a recursive tree and searches down for the child node, then back up for the ancestor node.
 *
 * @param nodes The initial array of nodes to search.
 * @param childPredicate Function to identify the child node.
 * @param ancestorPredicate Function to identify the ancestor node.
 * @param getNestedNodes Function to get the next collection to recurse the tree.
 */
export function findAncestor<Node>(
  nodes: Node[],
  childPredicate: (node: Node) => boolean,
  ancestorPredicate: (node: Node) => boolean,
  getNestedNodes: (node: Node) => Node[],
): Node | null {
  let result: Node | null = null;

  for (const node of nodes) {
    if (ancestorPredicate(node)) {
      result = node;
    }

    if (childPredicate(node)) {
      return result;
    }

    const nestedResult = findAncestor(
      getNestedNodes(node),
      childPredicate,
      ancestorPredicate,
      getNestedNodes,
    );

    if (nestedResult) {
      return nestedResult;
    }
  }

  return result;
}

/**
 *
 */
export function findAncestors<Node>(
  nodes: Node[],
  childPredicate: (node: Node) => boolean,
  getNestedNodes: (node: Node) => Node[],
): Node[] {
  const results = [] as Node[];

  for (const node of nodes) {
    results.push(node);

    if (childPredicate(node)) {
      return results;
    }

    const nestedResults = findAncestors(
      getNestedNodes(node),
      childPredicate,
      getNestedNodes,
    );

    if (nestedResults.length > 0) {
      results.push(...nestedResults);
      return results;
    }

    results.pop();
  }

  return results;
}

/**
 * Iterates through the collection and returns a new collection with duplicates removed.
 *
 * @param nodes The collection to iterate through.
 * @param searchPredicate Function used to compare nodes for equality.
 */
export function removeDuplicates<Node>(
  nodes: Node[],
  searchPredicate: (node: Node) => string,
) {
  const unique = [] as Node[];

  for (let i = 0; i < nodes.length; i++) {
    if (!exists(nodes[i], unique, searchPredicate)) {
      unique.push(nodes[i]);
    }
  }

  return unique;
}

export function removeFromTree<Node>(
  nodes: Node[],
  predicate: (node: Node) => boolean,
  getNestedCollection: (node: Node) => Node[],
  prop: keyof Node,
): Node[] {
  const cloneArray = [...nodes];

  return cloneArray
    .filter((node) => !predicate(node))
    .map((node) => ({
      ...node,
      [prop]: removeFromTree(
        getNestedCollection(node),
        predicate,
        getNestedCollection,
        prop,
      ),
    }));
}
