import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";

const UploadPhotoStepFooter = () => {
  const { next, previous } = useWizardContext();

  return (
    <ButtonGroup flow="horizontal">
      <Button onClick={previous}>
        <Icon name="arrow-alt-left" /> Back to delivered items
      </Button>
      <Button buttonType="progressive" onClick={next}>
        Or, skip photo upload <Icon name="arrow-alt-right" />
      </Button>
    </ButtonGroup>
  );
};

export default UploadPhotoStepFooter;
