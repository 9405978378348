import {
  animate,
  motion,
  MotionValue,
  PanInfo,
  useMotionValue,
} from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import DS from "@design/system";

import IconButton from "./IconButton";

interface CarouselProps<T> {
  items: T[];

  autoRotate?: boolean;
  compact?: boolean;
  dots?: "default" | "image";
  infinite?: boolean;

  children: (item: T) => JSX.Element;
  onClick?: (item: T) => void;
}

const FRAMES = [-1, 0, 1];

const transition = {
  type: "spring" as const,
  bounce: 0,
};

const Dot = styled.button`
  width: ${DS.margins.micro};
  height: ${DS.margins.micro};
  margin: 0;
  padding: 0;

  cursor: pointer;

  border: 0;
  border-radius: ${DS.margins.micro};
`;

const Page = ({
  x,
  frameIndex,
  onDragStart,
  onDragEnd,
  children,
}: {
  x: MotionValue;
  frameIndex: number;
  onDragStart?(
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
  ): void;
  onDragEnd?(
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
  ): void;
  children: JSX.Element;
}) => {
  return (
    <motion.div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: `${frameIndex * 100}%`,
        x,
      }}
      draggable
      drag="x"
      dragElastic={1}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      {children}
    </motion.div>
  );
};

const Carousel = <T extends unknown>({
  items,
  autoRotate = false,
  compact = false,
  dots = "default",
  infinite = false,
  children,
  onClick,
}: CarouselProps<T>) => {
  const timer = useRef<NodeJS.Timeout>();
  const { palettes } = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);
  const dragging = useRef<boolean>(false);

  const [index, setIndex] = useState(0);

  const x = useMotionValue(0);

  const calculateNewX = useMemo(
    () => () => -index * (containerRef.current?.clientWidth || 0),
    [index],
  );

  const next = useCallback(() => setIndex((previous) => previous + 1), []);
  const previous = useCallback(() => setIndex((previous) => previous - 1), []);

  const handleClick = useCallback(() => {
    if (!dragging.current) onClick?.(items[index]);
  }, [dragging, index, items, onClick]);

  const handleDragStart = useCallback(() => (dragging.current = true), []);

  const handleDragEnd = useCallback(
    (_event: Event, { offset, velocity }: PanInfo) => {
      // FIXME: There must be a better way?
      setTimeout(() => (dragging.current = false), 150);

      const clientWidth = containerRef.current?.clientWidth || 0;

      if (Math.abs(velocity.y) > Math.abs(velocity.x)) {
        void animate(x, calculateNewX(), transition);
        return;
      }

      if (offset.x > clientWidth / 4 && (infinite || index > 0)) {
        setIndex((previous) => previous - 1);
      } else if (
        offset.x < -clientWidth / 4 &&
        (infinite || index < items.length - 1)
      ) {
        setIndex((previous) => previous + 1);
      } else {
        void animate(x, calculateNewX(), transition);
      }
    },
    [calculateNewX, index, infinite, items, x],
  );

  const stopTimer = useCallback(() => clearTimeout(timer.current), []);

  const startTimer = useCallback(() => {
    stopTimer();

    timer.current = setTimeout(() => {
      next();
      startTimer();
    }, 5000);
  }, [next, stopTimer]);

  const restartTimer = useCallback(() => {
    stopTimer();
    if (autoRotate) startTimer();
  }, [autoRotate, startTimer, stopTimer]);

  useEffect(() => setIndex(0), [items]);

  useEffect(
    () => animate(x, calculateNewX(), transition).stop,
    [calculateNewX, index, x],
  );

  useEffect(() => {
    if (autoRotate) startTimer();
    return () => stopTimer();
  }, [stopTimer, startTimer, autoRotate]);

  if (!items || items.length === 0) return null;

  return (
    <motion.div
      ref={containerRef}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <button
        style={{
          position: "absolute",
          overflow: "hidden",
          display: "block",
          width: "100%",
          height: "100%",
          margin: 0,
          padding: 0,
          textAlign: "start",
          border: 0,
          background: "none",
        }}
        title="Zoom"
        aria-label="Zoom"
        onClick={handleClick}
      >
        {items.length === 1
          ? children(items[0])
          : FRAMES.map((frame) => {
              const modulo = (frame + index) % items.length;
              const itemIndex = modulo < 0 ? items.length + modulo : modulo;

              if (!infinite) {
                if (index === 0 && frame === -1) return null;
                if (index === items.length - 1 && frame === 1) return null;
              }

              return (
                <Page
                  key={`some-key-${frame}-${index}`}
                  x={x}
                  frameIndex={frame + index}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                >
                  {children(items[itemIndex])}
                </Page>
              );
            })}
      </button>
      {items.length > 1 && (
        <>
          <div
            style={{
              position: "absolute",
              bottom: DS.margins.micro,
              left: "50%",
              transform: "translateX(-50%)",
              display: "grid",
              gridAutoFlow: "column",
              gap: DS.margins.nano,
            }}
          >
            {items.map((_item, i) => (
              <Dot
                key={i}
                onClick={() => {
                  restartTimer();
                  setIndex(i);
                }}
                style={{
                  background:
                    i === index % items.length
                      ? dots === "default"
                        ? palettes.body.accent
                        : palettes.body.background
                      : dots === "default"
                        ? palettes.body.border
                        : "rgba(0, 0, 0, 0.5)",
                }}
              />
            ))}
          </div>
          {(infinite || index > 0) && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: compact ? -16 : DS.margins.regular,
                color: compact
                  ? palettes.body.border
                  : palettes.body.background,
                transform: "translateY(-50%)",
              }}
            >
              <IconButton
                icon="angle-left"
                iconSize={compact ? 16 : 32}
                buttonType="transparent"
                onClick={() => {
                  restartTimer();
                  previous();
                }}
              />
            </div>
          )}
          {(infinite || index < items.length - 1) && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: compact ? -16 : DS.margins.regular,
                color: compact
                  ? palettes.body.border
                  : palettes.body.background,
                transform: "translateY(-50%)",
              }}
            >
              <IconButton
                icon="angle-right"
                iconSize={compact ? 16 : 32}
                buttonType="transparent"
                onClick={() => {
                  restartTimer();
                  next();
                }}
              />
            </div>
          )}
        </>
      )}
    </motion.div>
  );
};

export default Carousel;
