import { formatDistanceToNow } from "date-fns";
import { Fragment, useMemo } from "react";

import { useProduct, useStore } from "@state/hooks";

import { IIcon } from "../icons";

/**
 * Filter helper for getting only the escalations that are considered "open"
 */
export const openAndNotPausedEscalationsOnly = (escalation: Api.Escalation) =>
  !escalation.IsClosed && !escalation.IsPaused;

export const getEscalationIcon = (reminder: Api.UnitReminderType): IIcon => {
  switch (reminder) {
    case "Replenishment":
      return "truck";
    case "PowerOff":
      return "plug";
    case "MissingTool":
      return "cut";
    case "ExpiredStock":
      return "hourglass-end";
    case "PutMultiUseItemBackIntoKit":
      return "box-open";
    case "AedPartsReplacementConfirmation":
      return "clipboard-list";
    case "AedPartsReplacement":
      return "aed-inverted";
    case "DeviceInspection":
      return "aed-inverted";
    default:
      return "times";
  }
};

export const EscalationTitle = ({
  escalation,
}: {
  escalation: Api.Escalation;
}) => {
  const { data: product } = useProduct(
    "SKU" in escalation ? escalation.SKU : undefined,
  );

  const title = useMemo(() => {
    switch (escalation.ReminderType) {
      case "Replenishment":
        return "Replacement Pending";
      case "PowerOff":
        return "Power Loss";
      case "MissingTool":
        return product ? `${product.Name} Missing` : "Tool Missing";
      case "ExpiredStock":
        return "Stock Expired";
      case "PutMultiUseItemBackIntoKit":
        return product ? `${product.Name} Missing` : "Multiuse Item Missing";
      case "AedPartsReplacementConfirmation":
        return "Replacement Needed";
      case "AedPartsReplacement":
        return "Replacement Overdue";
      case "DeviceInspection":
        return "AED Inspection Due";
      default:
        return "Unknown";
    }
  }, [escalation.ReminderType, product]);

  return <Fragment>{title}</Fragment>;
};

export const EscalationDescription = ({
  escalation,
}: {
  escalation: Api.Escalation;
}) => {
  const { data: store } = useStore(escalation.StoreId);

  return (
    <Fragment>
      {store?.StoreContact.FullName} contacted{" "}
      {formatDistanceToNow(new Date(escalation.CreatedDate), {
        addSuffix: true,
      })}
    </Fragment>
  );
};

export const getEscalationTitle = (reminder: Api.UnitReminderType) => {
  switch (reminder) {
    case "Replenishment":
      return "Replenishment Pending";
    case "PowerOff":
      return "Power Loss";
    case "MissingTool":
      return "Tool Missing";
    case "ExpiredStock":
      return "Stock Expired";
    case "PutMultiUseItemBackIntoKit":
      return "Multiuse Item Missing";
    case "AedPartsReplacementConfirmation":
    case "AedPartsReplacement":
    case "DeviceInspection":
      return "AED";
    default:
      return "Unknown";
  }
};

export const getEscalationDescription = (reminder: Api.UnitReminderType) => {
  switch (reminder) {
    case "Replenishment":
      return "New items arrived and should be stocked";
    case "PowerOff":
      return "First aid cabinet has lost power.";
    case "MissingTool":
      return "Missing items needs to be returned.";
    case "ExpiredStock":
      return "One or more items have expired.";
    case "PutMultiUseItemBackIntoKit":
      return "Multiuse item needs to be returned.";
    case "AedPartsReplacementConfirmation":
      return "AED parts need to be ordered.";
    case "AedPartsReplacement":
      return "AED parts need to be replaced.";
    case "DeviceInspection":
      return "AED inspection is overdue.";
    default:
      return "Unknown";
  }
};

export const getActivityIcon = (activity: Api.Activity): IIcon => {
  switch (activity.Type) {
    case "AedDetailsUpdated":
      return "pencil-alt";

    case "AedInspectionComplete":
      return "check-alt-circle";

    case "AedReplenishmentDeclined":
    case "AedReplenishmentRequested":
    case "AedReplenishmentComplete":
      return "box-open";

    default:
      return "minus";
  }
};

export const getActivityTitle = (activity: Api.Activity) => {
  switch (activity.Type) {
    case "AedInspectionComplete":
      return "Inspection completed";
    case "AedReplenishmentRequested":
      return "Replenishment requested";
    case "AedReplenishmentDeclined":
      return "Replenishment declined";
    case "AedReplenishmentComplete":
      return "Replenishment completed";

    default:
      return "Unknown";
  }
};

export const aedActivityFilter = (activity: Api.Activity) => {
  switch (activity.Type) {
    case "AedInspectionComplete":
    case "AedReplenishmentRequested":
    case "AedReplenishmentDeclined":
    case "AedReplenishmentComplete":
      return true;

    default:
      return false;
  }
};
