import styled from "styled-components";

import DS from "@design/system";

const getFlow = ({
  flow = "vertical",
}: {
  flow?: "horizontal" | "vertical";
}) =>
  flow === "vertical"
    ? "grid-auto-flow: row; grid-auto-rows: minmax(0, 1fr)"
    : "grid-auto-flow: column; grid-auto-columns: minmax(0, 1fr)";

const ButtonGroup = styled.div<{ flow?: "horizontal" | "vertical" }>`
  display: grid;
  gap: ${DS.margins.regular};
  ${getFlow};
`;

export default ButtonGroup;
