import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTheme } from "styled-components";

import Icon from "@components/Icon";
import ListItem, { ListItemsPlaceholder } from "@components/ListItem";
import Message from "@components/Message";
import { Link } from "@components/SecondaryButton";
import StoreTime from "@components/StoreTime";
import { LargeDataNotice, UnstyledList } from "@design/helpers";
import {
  useActivitiesForUnit,
  useCurrentCompanyId,
  useCurrentUser,
} from "@state/hooks";
import { DateFormat } from "@util/dateFormat";
import {
  aedActivityFilter,
  getActivityIcon,
  getActivityTitle,
} from "@util/escalations";

const HistoryTab = () => {
  const { palettes } = useTheme();

  const { data: currentUser } = useCurrentUser();
  const companyId = useCurrentCompanyId();

  const [controllerSerialNumber, storeId] = useWatch<Api.Unit>({
    name: ["ControllerSerialNumber", "StoreId"],
  });

  const { data: activities, isLoading } = useActivitiesForUnit(
    {
      storeId: storeId as string,
      numberDays: 90,
    },
    controllerSerialNumber as string,
  );

  const aedActivities = useMemo(
    () => activities?.filter(aedActivityFilter) ?? [],
    [activities],
  );

  return (
    <>
      <Message type="info">
        <Link
          href={`${
            import.meta.env.VITE_APP_API_ENDPOINT
          }/PdfPrint/AedReport?companyId=${companyId}&storeId=${
            storeId as string
          }&requestedByUserId=${currentUser?.userId ?? ""}`}
        >
          Generate an AED report
        </Link>{" "}
        for additional details.
      </Message>
      <UnstyledList>
        {isLoading ? (
          <ListItemsPlaceholder />
        ) : aedActivities.length === 0 ? (
          <LargeDataNotice>No recent activity</LargeDataNotice>
        ) : (
          aedActivities.map((a) => (
            <li key={a.ActivityID}>
              <ListItem
                image={
                  <Icon name={getActivityIcon(a)} color={palettes.body.small} />
                }
                title={getActivityTitle(a)}
                description={
                  <span>
                    By {a.LastModifiedByUserName} on{" "}
                    <StoreTime
                      storeName={a.StoreName}
                      dateTime={a.ModifiedDate}
                      format={DateFormat.dateTimeAndTimeZone}
                    />
                  </span>
                }
              />
              {a.AdditionalData && (
                <p
                  style={{ paddingLeft: "32px", marginTop: 0, marginBottom: 0 }}
                >
                  <strong> Reason: </strong>
                  {a.AdditionalData}
                </p>
              )}
            </li>
          ))
        )}
      </UnstyledList>
    </>
  );
};

export default HistoryTab;
