import { useMemo } from "react";
import { useParams } from "react-router-dom";

const useParamsUpper = <
  Params extends Record<string, string | undefined>,
>() => {
  const params = useParams<Params>();

  return useMemo(() => {
    const upper: Record<string, string | undefined> = {};

    for (const key in params) {
      upper[key] = params[key]?.toUpperCase();
    }

    return upper;
  }, [params]);
};

export default useParamsUpper;
