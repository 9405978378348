import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";

import DS from "@design/system";
import { useDaysGone } from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";

import { useTooltip } from "./Tooltip";

const DaysGoneBannerPlaceholder = () => (
  <ContentLoader width={48} height={48}>
    <rect x={0} y={0} width={48} height={48} rx={DS.radii.largeItem} />
  </ContentLoader>
);

const DaysGoneBanner = ({
  storeId,
  groupId,
}: {
  storeId?: string;
  groupId?: string;
}) => {
  const { t } = useTranslation();
  const { daysGonePalette } = useThemeHelper();

  const { daysGone, isLoading } = useDaysGone({ groupId, storeId });

  const { tooltipProps } = useTooltip<HTMLDivElement>(
    daysGone
      ? `${daysGone} days since last ${t("severity.high").toLowerCase()} ${t(
          "term.incident_one",
        ).toLowerCase()}`
      : `No ${t("severity.high").toLowerCase()} ${t(
          "term.incident_other",
        ).toLowerCase()} have occurred`,
  );

  if (isLoading) return <DaysGoneBannerPlaceholder />;

  return (
    <div
      {...tooltipProps}
      style={{
        boxSizing: "border-box",
        width: 48,
        height: 48,
        padding: DS.margins.micro,

        cursor: "default",
        textAlign: "center",
        lineHeight: 1,
        fontSize: 22,
        fontWeight: 700,
        color: daysGonePalette(daysGone).foreground,
        background: daysGonePalette(daysGone).background,
        borderRadius: DS.radii.largeItem,

        display: "grid",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span>{daysGone ?? "--"}</span>
      <span style={{ fontSize: "0.5em", fontWeight: 400 }}>days</span>
    </div>
  );
};

export default DaysGoneBanner;
