import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";
import { useShipment } from "@state/hooks";
import { AedReplenished } from "@util/viewModel";

const StartStepFooter = ({ onCancel }: { onCancel: () => void }) => {
  const { watch } = useFormContext<AedReplenished>();
  const { next } = useWizardContext();

  const [confirmed, shipmentId] = watch(["confirmed", "ShipmentId"]);

  const { data: shipment } = useShipment(shipmentId);

  const ready = useMemo(() => {
    if (!shipment || !confirmed) return false;

    return shipment.ShipmentLines.length === confirmed.length;
  }, [confirmed, shipment]);

  return (
    <ButtonGroup flow="horizontal">
      <Button onClick={onCancel}>Close</Button>
      <Button buttonType="progressive" disabled={!ready} onClick={next}>
        Next, upload photo <Icon name="arrow-alt-right" />
      </Button>
    </ButtonGroup>
  );
};

export default StartStepFooter;
