import { ThemeIcons } from "@themes/types";

export const defaultIcons: ThemeIcons = {
  unitType: {
    Unknown: "question-circle",
    FirstAidStation: "device-first-aid-cabinet",
    AutomatedExternalDefibrillator: "device-aed",
    Cube: "question-circle",
    EyewashStation: "device-eye-wash",
  },

  eventCategory: {
    Cuts: "plaster",
    Burns: "temperature",
    Eyes: "eye",
    STF: "clipboard-check",
    Cleaning: "tint",
    Safety: "shield-alt",

    // Unused
    Uncategorized: "question-circle",
    "Box of Items": "question-circle",
    Hardware: "question-circle",
    Tools: "question-circle",
  },

  other: {
    overview: "home",
    device: "mobile-alt",
    incident: "briefcase-medical",
    shipment: "truck",
    person: "user-friends",
    store: "home",
    group: "grid",
    company: "building",
    billing: "money",
    setting: "cogs",
  },
};
