import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { AuthState, isApiResponseError } from "@api";
import Button from "@components/Button";
import ErrorWell from "@components/ErrorWell";
import {
  LabelledCheckbox,
  LabelledInput,
  LabelledPasswordInput,
} from "@components/FormControls";
import { Link, LinkButton } from "@components/SecondaryButton";
import { LargeBrandTitle } from "@design/helpers";
import DS from "@design/system";

import { useAuth } from "./auth";

interface LoginParams {
  email: string;
  password: string;
  sharedDevice: boolean;
}

const LogIn = ({
  defaultEmail,
  onEmailChange,
  onForgotPasswordClick,
  onMfaRequired,
}: {
  defaultEmail?: string;
  onEmailChange?: (email: string) => void;
  onForgotPasswordClick: (e: React.MouseEvent) => void;
  onMfaRequired: (mfaType: AuthState) => void;
}) => {
  const { t } = useTranslation();
  const { meta, links } = useTheme();
  const auth = useAuth();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    register,
    watch,
    setError,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<LoginParams>({ mode: "onChange" });

  const email = watch("email", defaultEmail);
  const password = watch("password");

  const onSubmit: React.FormEventHandler = useCallback(
    (formEvent) =>
      void handleSubmit(async (data: LoginParams) => {
        setErrorMessage(null);

        const { email, password, sharedDevice } = data;

        try {
          const state = await auth.logIn(email, password, sharedDevice);
          (state === "mfa-totp" || state === "mfa-email") &&
            onMfaRequired(state);
        } catch (e) {
          setErrorMessage("Something went wrong. Please try again.");

          if (!isApiResponseError<Api.UserValidationError>(e)) return;

          const { error } = e;

          if (error.isServerError) {
            setErrorMessage(
              "We encountered an issue on our side. Please try again soon.",
            );
          } else {
            setErrorMessage(null);
            setError("email", {
              type: "manual",
              message:
                "You have entered an incorrect email address or password.",
            });
            setError("password", {
              type: "manual",
              message:
                "You have entered an incorrect email address or password.",
            });
          }
        }
      })(formEvent),
    [auth, handleSubmit, onMfaRequired, setError],
  );

  useEffect(
    () => onEmailChange && onEmailChange(email),
    [email, onEmailChange],
  );

  return (
    <form style={{ height: "100%" }} action="#" onSubmit={onSubmit}>
      <div
        style={{
          height: "100%",
          display: "grid",
          gap: DS.margins.large,
          alignContent: "center",
        }}
      >
        <LargeBrandTitle>{meta.appName}</LargeBrandTitle>
        {errorMessage && <ErrorWell>{errorMessage}</ErrorWell>}
        {errors.email && <ErrorWell>{errors.email.message}</ErrorWell>}
        <LabelledInput
          type="email"
          label={t("pages.logIn.labels.email")}
          autoComplete="email"
          hasError={!!errors.email}
          readOnly={isSubmitting}
          {...register("email")}
        />
        <div style={{ display: "grid", gap: DS.margins.micro }}>
          <LabelledPasswordInput
            label={t("pages.logIn.labels.password")}
            autoComplete="current-password"
            hasError={!!errors.password}
            readOnly={isSubmitting}
            {...register("password")}
          />
          <LabelledCheckbox
            label={t("pages.logIn.labels.sharedDevice")}
            {...register("sharedDevice")}
          />
        </div>
        <div
          style={{
            textAlign: "center",
            display: "grid",
            gap: DS.margins.micro,
          }}
        >
          <Button
            buttonType="action"
            type="submit"
            disabled={!email || !password || isSubmitting}
          >
            {isSubmitting
              ? t("pages.logIn.buttons.loggingIn")
              : t("pages.logIn.buttons.logIn")}
          </Button>
          <LinkButton onClick={onForgotPasswordClick}>
            {t("pages.logIn.buttons.forgotPassword")}
          </LinkButton>
          <Link target="_blank" href={links.support.url}>
            {links.support.label}
          </Link>
        </div>
      </div>
    </form>
  );
};

export default LogIn;
