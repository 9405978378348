import { Helmet } from "react-helmet-async";
import { useTheme } from "styled-components";

const Head = () => {
  const { meta } = useTheme();

  return (
    <Helmet>
      <title>{meta.appName}</title>
      <meta name="description" content={meta.description} />

      {meta.links?.map((link, i) => <link key={`link-${i}`} {...link} />)}
    </Helmet>
  );
};

export default Head;
