import { useModal } from "react-modal-hook";

import Button from "@components/Button";
import Message from "@components/Message";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import ModalStandardLayout from "@components/ModalStandardLayout";
import Scroller from "@components/Scroller";
import DS from "@design/system";
import { useCompany } from "@state/hooks";

import EnableCompanyMfaModal from "./EnableCompanyMfaModal";

const EditCompanySettingsModal = ({ onClose }: { onClose: () => void }) => {
  const { data: company, isLoading } = useCompany();
  const mfaEnabled = company?.IsMfaPolicyEnabled;

  const [showCompanyMfaModal, closeCompanyMfaModal] = useModal(
    () => <EnableCompanyMfaModal onClose={closeCompanyMfaModal} />,
    [],
  );
  return (
    <Modal contentLabel="Company Settings" onClose={onClose}>
      <ModalStandardLayout
        header={<ModalHeader title="Company Settings" onClose={onClose} />}
        content={
          <Scroller>
            <div
              style={{
                padding: `0 ${DS.margins.regular} ${DS.margins.regular}`,
                display: "grid",
                gap: DS.margins.micro,
              }}
            >
              <Message type="info">
                <strong>Enforce Two-factor Authentication</strong>
                <br />
                Enforces two-factor authentication for <em>all</em> people in
                the company. These changes can take several minutes to complete.
              </Message>
              {isLoading ? (
                <Button stretch disabled>
                  Loading…
                </Button>
              ) : mfaEnabled ? (
                <Button
                  buttonType="destructive"
                  onClick={showCompanyMfaModal}
                  stretch
                >
                  Disable two-factor authentication
                </Button>
              ) : (
                <Button onClick={showCompanyMfaModal} stretch>
                  Enforce two-factor authentication
                </Button>
              )}
            </div>
          </Scroller>
        }
        footer={
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        }
      />
    </Modal>
  );
};

export default EditCompanySettingsModal;
