import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { isApiResponseError } from "@api";
import ErrorWell from "@components/ErrorWell";
import { SmallText, Well } from "@design/helpers";
import DS from "@design/system";

import Button from "../components/Button";
import {
  LabelledInput,
  LabelledInputPlaceholder,
  LabelledSwitch,
} from "../components/FormControls";
import Modal from "../components/Modal";
import ModalFooter from "../components/ModalFooter";
import ModalHeader from "../components/ModalHeader";
import ModalStandardLayout from "../components/ModalStandardLayout";
import Scroller from "../components/Scroller";
import { useCompany, useMutateStore, useStoreFull } from "../state/hooks";

const BillingContactModal = ({
  storeId,
  onSave,
  onClose,
}: {
  storeId?: string;
  onSave: (updatedStore: Api.Store) => void;
  onClose: () => void;
}) => {
  const { data: company } = useCompany();
  const { data: store } = useStoreFull(storeId);
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    formState: { errors },
    register,
    watch,
    reset,
    handleSubmit,
    setError,
  } = useForm<Api.Store>(
    store ? { defaultValues: store, mode: "onChange" } : {},
  );

  const watchBillingCompanyName = watch("BillingCompanyName");
  const watchUseStoreContact = watch(
    "IsBillingContactStoreContact",
    store?.IsBillingContactStoreContact,
  );

  const { mutate: updateStore, isPending } = useMutateStore();

  useEffect(() => reset(store), [reset, store]);

  const onSubmit: React.FormEventHandler = useCallback(
    (e) =>
      void handleSubmit((data: Api.Store) => {
        setErrorMessage(null);

        if (!store) return;

        updateStore(
          {
            ...store,
            ...data,
          },
          {
            onError: (e) => {
              setErrorMessage("Something went wrong. Please try again.");

              if (!isApiResponseError<Api.StoreValidationError>(e)) return;
              const { error } = e;

              if (error.isServerError) {
                setErrorMessage(
                  "We encountered an issue on our side. Please try again soon.",
                );
              } else if (
                error.hasError([
                  "billing_name_required",
                  "invalid_billing_email",
                ])
              ) {
                setErrorMessage(null);
                setError("BillingCompanyName", {
                  type: "manual",
                  message: "Billing name required",
                });
              }
            },
            onSuccess: (response) => {
              toast.success(
                <span>
                  <strong>Billing Contact</strong> successfully updated.
                </span>,
              );
              onSave && onSave(response.SuccessPayload);
            },
          },
        );
      })(e),
    [handleSubmit, onSave, setError, store, updateStore],
  );

  return (
    <Modal contentLabel="Edit billing contact information" onClose={onClose}>
      <form onSubmit={onSubmit}>
        <ModalStandardLayout
          header={<ModalHeader title="Billing Contact" onClose={onClose} />}
          content={
            <Scroller>
              <div
                style={{
                  display: "grid",

                  padding: `0 ${DS.margins.large} ${DS.margins.regular}`,
                  gridAutoFlow: "row",
                  alignContent: "flex-start",
                  gap: DS.margins.regular,
                }}
              >
                {errorMessage && <ErrorWell>{errorMessage}</ErrorWell>}
                <Well>
                  <SmallText>
                    Customise the contact information displayed on your
                    invoices.
                  </SmallText>
                </Well>

                <div>
                  {store ? (
                    <LabelledInput
                      label="Billing Name"
                      hasError={!!errors.BillingCompanyName}
                      {...register("BillingCompanyName")}
                    />
                  ) : (
                    <LabelledInputPlaceholder label="Company Name Override" />
                  )}

                  <div style={{ marginTop: DS.margins.nano }}>
                    <SmallText>
                      Will display as{" "}
                      <strong>
                        {company && store
                          ? `${company.Name} - ${
                              watchBillingCompanyName || store.Name
                            }`
                          : "…"}
                      </strong>
                    </SmallText>
                  </div>
                </div>

                <LabelledSwitch
                  label={`Use ${t("term.store_one").toLowerCase()} contact`}
                  {...register("IsBillingContactStoreContact")}
                />

                {store ? (
                  <>
                    <LabelledInput
                      label="Contact Name"
                      readOnly={watchUseStoreContact}
                      {...register("BillingContactName")}
                    />
                    <LabelledInput
                      label="Contact Email"
                      readOnly={watchUseStoreContact}
                      {...register("BillingContactEmail")}
                    />
                  </>
                ) : (
                  <>
                    <LabelledInputPlaceholder label="Contact Name" />
                    <LabelledInputPlaceholder label="Contact Email" />
                  </>
                )}
              </div>
            </Scroller>
          }
          footer={
            <ModalFooter>
              <Button
                stretch
                buttonType="action"
                type="submit"
                disabled={isPending}
              >
                {isPending ? "Updating…" : "Update billing contact"}
              </Button>
            </ModalFooter>
          }
        />
      </form>
    </Modal>
  );
};

export default BillingContactModal;
