import { DateRange } from "@util/DateRange";
import { DateFormat, dateFormatter } from "@util/dateFormat";
import { getStartAndEndDatesFromNumberOfDays } from "@util/dates";

type ByNumberDays = { numberDays: number; startDate?: never; endDate?: never };
type ByDateRange = { numberDays?: never; startDate: Date; endDate: Date };

const isNumberDays = (
  params: ByNumberDays | ByDateRange,
): params is ByNumberDays =>
  !!params.numberDays && !params.startDate && !params.endDate;

const isDateRange = (
  params: ByNumberDays | ByDateRange,
): params is ByDateRange =>
  !params.numberDays && !!params.startDate && !!params.endDate;

const DateRangeForDays = (params: ByNumberDays | ByDateRange) => {
  let dateRange: DateRange;

  if (isNumberDays(params)) {
    dateRange = getStartAndEndDatesFromNumberOfDays(params.numberDays);
  } else if (isDateRange(params)) {
    dateRange = { start: params.startDate, end: params.endDate };
  } else {
    throw new Error("invalid parameters for date range");
  }

  return <span>{dateFormatter(dateRange, DateFormat.fromDateToDate)}</span>;
};

export default DateRangeForDays;
