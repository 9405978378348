import {
  Fragment,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { AuthState } from "@api";
import Icon from "@components/Icon";
import { SmallText } from "@design/helpers";
import DS from "@design/system";

import { Link } from "../components/SecondaryButton";
import useCrashReporter from "../util/useCrashReporter";
import EmailSent from "./EmailSent";
import ForgotPassword from "./ForgotPassword";
import LogIn from "./LogIn";
import Mfa from "./Mfa";
import SetPassword from "./SetPassword";

type Step =
  | "login"
  | "mfa-required"
  | "submit-email"
  | "recovery-link-sent"
  | "set-password";

const StageContainer = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const SlideContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 64px;
`;

const StageContext = createContext<Step>("login");

const Stage = ({
  currentStep,
  children,
}: {
  currentStep: Step;
  children: React.ReactNode;
}) => {
  return (
    <StageContainer>
      <StageContext.Provider value={currentStep}>
        {children}
      </StageContext.Provider>
    </StageContainer>
  );
};

const Slide = ({
  step,
  children,
}: {
  step: Step;
  children: React.ReactNode;
}) => {
  const currentStep = useContext(StageContext);

  return currentStep === step ? (
    <SlideContainer>{children}</SlideContainer>
  ) : null;
};

const getStepFromPath = (pathname: string, step?: Step): Step => {
  if (pathname === "/forgot-password") {
    return step === "recovery-link-sent"
      ? "recovery-link-sent"
      : "submit-email";
  } else if (pathname.startsWith("/set-password")) {
    return "set-password";
  } else if (step === "mfa-required") {
    return "mfa-required";
  } else {
    return "login";
  }
};

const LogInPage = ({ loading }: { loading: boolean }) => {
  const location = useLocation();
  const history = useHistory();
  const { meta, links, palettes } = useTheme();
  const { visit } = useCrashReporter();

  const [step, setStep] = useState<Step>(getStepFromPath(location.pathname));

  const [email, setEmail] = useState<string>("");

  const [mfaType, setMfaType] = useState<AuthState>("unauthenticated");

  const handleEmailChange = useCallback((email: string) => setEmail(email), []);

  const handleForgotPasswordClick = useCallback(() => {
    setStep("submit-email");
    history.push("/forgot-password");
  }, [history]);

  const handleMfaRequired = useCallback((mfaType: AuthState) => {
    setStep("mfa-required");
    setMfaType(mfaType);
  }, []);

  const handleBackToLoginClick = useCallback(() => {
    setStep("login");
    history.push("/login");
  }, [history]);

  const handleResendRecoveryClick = useCallback(
    () => setStep("recovery-link-sent"),
    [],
  );

  useEffect(() => {
    setStep(getStepFromPath(location.pathname, step));
    visit(`${location.pathname}-${step}`);
  }, [location.pathname, step, visit]);

  return (
    <div
      style={{
        height: "calc(var(--vh, 1vh) * 100)",
        background: palettes.body.dim,
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
      }}
    >
      {loading && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,

            color: palettes.body.small,
            fontWeight: 700,
            fontSize: 28,

            background: "rgba(255, 255, 255, 0.8)",

            display: "grid",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Icon name="spinner" spin />
          </div>
        </div>
      )}
      <h1
        style={{
          height: 0,
          margin: 0,
          padding: DS.margins.regular,
          justifySelf: "flex-end",
        }}
        aria-label={`${meta.appName}`}
      >
        <meta.Logo />
      </h1>

      <div
        style={{
          justifySelf: "center",
          position: "relative",
          width: 400,
          display: "grid",
          alignContent: "center",
        }}
      >
        <div
          style={{
            height: 480,
            background: palettes.body.background,
            borderRadius: 80,
            boxShadow: DS.shadows.dialog,
          }}
        />

        <Stage currentStep={step}>
          <Switch>
            <Route path="/login">
              <Slide step="login">
                <LogIn
                  defaultEmail={email}
                  onEmailChange={handleEmailChange}
                  onForgotPasswordClick={handleForgotPasswordClick}
                  onMfaRequired={handleMfaRequired}
                />
              </Slide>

              <Slide step="mfa-required">
                <Mfa
                  email={email}
                  onBackToLoginClick={handleBackToLoginClick}
                  mfaType={mfaType}
                />
              </Slide>

              <Slide step="submit-email">
                <ForgotPassword
                  defaultEmail={email}
                  onBackToLoginClick={handleBackToLoginClick}
                  onEmailSubmit={(email) => {
                    setEmail(email);
                    setStep("recovery-link-sent");
                  }}
                />
              </Slide>
            </Route>

            <Route
              path={[
                "/set-password/:email/:resetKey",
                "/set-password/:email/",
                "/set-password",
              ]}
            >
              <Slide step="set-password">
                <SetPassword onBackToLoginClick={handleBackToLoginClick} />
              </Slide>
            </Route>

            <Route path="/forgot-password">
              <Slide step="submit-email">
                <ForgotPassword
                  defaultEmail={email}
                  onBackToLoginClick={handleBackToLoginClick}
                  onEmailSubmit={(email) => {
                    setEmail(email);
                    setStep("recovery-link-sent");
                  }}
                />
              </Slide>

              <Slide step="recovery-link-sent">
                <EmailSent
                  email={email}
                  onResendRecoveryClick={handleResendRecoveryClick}
                  onBackToLoginClick={handleBackToLoginClick}
                />
              </Slide>
            </Route>
          </Switch>
        </Stage>
      </div>

      <footer style={{ padding: DS.margins.regular, textAlign: "center" }}>
        <SmallText>
          &copy; {new Date().getFullYear()} {meta.copyright}
          {links.footer.map((link) => (
            <Fragment key={link.url}>
              <span> &bull; </span>
              <Link href={link.url} target="_blank" title={link.label}>
                {link.label}
              </Link>
            </Fragment>
          ))}
        </SmallText>
      </footer>
    </div>
  );
};
export default LogInPage;
