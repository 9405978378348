const pt: Partial<I18n.TranslationBase> = {
  lang: {
    cimode: "Debug",
    en: "Inglês",
    mi: "Te Reo Māori",
    pt: "Português",
  },
  common: {
    actionsToDo: "",
    clearSearch: "",

    userMenu: {
      editProfile: "Editar Perfil",
      companySettings: "Configurações da empresa",
      logOut: "Sair",
    },
  },

  pages: {
    logIn: {
      labels: {
        email: "E-mail",
        password: "Senha",
        sharedDevice: "Computador compartilhado?",
      },
      buttons: {
        logIn: "Faz login",
        loggingIn: "Fazendo login",
        forgotPassword: "Esqueceu sua senha?",
      },
    },
    map: {
      ribbon: {
        filter: {
          unit: "Mostrar apenas $t(term.unit_other), lowercase)",
          store: "Mostrar apenas $t(term.store_other, lowercase)",
        },
      },
      markers: {
        storeCount: "",
        unitCount: "",
      },
    },
  },

  menu: {
    overview: "Casa",
    units: "$t(term.unit_other)",
    incidents: "$t(term.incident_other)",
    shipments: "Remessos",
    people: "Pessoas",
    stores: "$t(term.store_other)",
    billing: "Cobrança",
    settings: "Configurações",
  },
};

export default pt;
