import { useCallback, useEffect, useState } from "react";
import { useFormContext, useFormState, useWatch } from "react-hook-form";

import { useWizardContext } from "@components/ModalWizardHelper";

import Button from "../Button";
import Icon from "../Icon";

const GeneralTabFooter = () => {
  // TODO: Use `trigger` to force a validation check.
  const { control, setValue } = useFormContext<Api.User>();
  const { next } = useWizardContext();

  const { errors } = useFormState({ control });
  const [fullName, email, roles, mobile] = useWatch({
    control,
    name: ["FullName", "Email", "Roles", "MobilePhoneNumber"],
  });

  const [ready, setReady] = useState(false);

  const handleNextClick = useCallback(() => {
    if (mobile) {
      setValue(
        "MobilePhoneNumber",
        Number(mobile.toString().replaceAll(/[^0-9]/g, "")),
      );
    }
    next();
  }, [mobile, next, setValue]);

  useEffect(() => {
    setReady(!!fullName && !!email && !errors.Email && !!roles);
  }, [fullName, email, roles, errors]);

  return (
    <Button
      buttonType="progressive"
      onClick={handleNextClick}
      title={
        !ready ? "Please enter a name, email and role before continuing." : ""
      }
      disabled={!ready}
    >
      Next, notifications and alerts <Icon name="arrow-alt-right" />
    </Button>
  );
};

export default GeneralTabFooter;
