import { ReactElement } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 480px;
  height: 480px;

  display: grid;
  grid-template-rows: auto 1fr auto;

  > * {
    overflow: hidden;
  }
`;

const ModalWizardLayout = ({
  header,
  content,
  footer,
}: {
  header: ReactElement;
  content: ReactElement;
  footer: ReactElement;
}) => {
  return (
    <Container>
      {header}
      {content}
      {footer}
    </Container>
  );
};

export default ModalWizardLayout;
