import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";

import DS from "@design/system";
import { Direction } from "@util/animations";

export type WizardContextProps = {
  step: { name: string; number: number };
  count: number;

  goTo: (step: string, updateStack?: boolean) => void;
  goToStart: () => void;
  next: () => void;
  previous: () => void;
};

export type Step = {
  /**
   * Unique identifying name for the step
   */
  name: string;

  backLabel?: string | null;
  backTo?: string | null;

  /**
   * Use Title Case
   */
  title: string;

  Content: ReactNode;
  Footer: ReactNode;
};

export type Key = string;
export type Branch = { [index in string]: Path };
export type Node = Key | Branch;
export type Path = [string, ...Node[]];

export type NodeResult = { path: Path; step: string; index: number };

export const isKey = (k: Node): k is Key => typeof k === "string";
export const isBranch = (b: Node): b is Branch => typeof b === "object";

export const Overlay = styled.div<{ show: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  pointer-events: ${({ show }) => (show ? "all" : "none")};

  background: ${({ show }) =>
    show ? "rgba(0, 0, 0, 0.25)" : "rgba(0, 0, 0, 0)"};

  transition: background 200ms ease-in-out;
`;

export const WizardStepContainer = styled.div`
  overflow: hidden;

  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: ${DS.margins.micro};
`;

export const WizardControlsContainer = styled.div`
  padding: 16px 96px;

  display: grid;
  gap: 8px;
  align-content: center;
`;

export const WizardIconContainer = styled.div`
  margin-bottom: ${DS.margins.regular};

  display: grid;
  grid-auto-flow: column;
  gap: ${DS.margins.regular};
  justify-content: center;
`;

export const WizardFooter = styled.div`
  padding: ${DS.margins.micro};

  display: grid;
`;

export function flattenPath(path: Path, target?: string): (string | null)[] {
  return path.reduce(
    (p, node) => {
      if (isKey(node)) {
        return [...p, node];
      } else {
        const keys = Object.keys(node);

        for (const key of keys) {
          const branchedPath = node[key];

          if (target === key) {
            // If the target is this branch, then flatten it.
            return [...p, ...flattenPath(branchedPath)];
          } else {
            // Otherwise, we need to check if the target is in this branch
            const flat = flattenPath(branchedPath, target);

            // And only return it if the target is in the flattened branch
            if (target && flat.indexOf(target) > -1) {
              return [...p, ...flat];
            }
          }
        }

        // Key not found, just null it
        return [...p, null];
      }
    },
    [] as (string | null)[],
  );
}

export function findNode(path: Path, target: string): NodeResult {
  const index = path.findIndex((k) => k === target);

  if (index > -1) return { path, step: target, index };

  // Not on this path, time to recurse
  for (const node of path) {
    if (!isBranch(node)) continue;

    const keys = Object.keys(node);

    for (const key of keys) {
      const p = node[key];

      if (key === target)
        return {
          path: p,
          step: p[0],
          index: 0,
        };

      try {
        return findNode(p, target);
      } catch (e) {
        // In here, we don't care if we don't find the node. Just move on.
        continue;
      }
    }
  }

  throw new Error(`Could not find step '${target}'`);
}

export function nextNode(path: Path, current: string): NodeResult {
  const node = findNode(path, current);

  if (node.index === node.path.length - 1)
    throw new Error(`No next node available after '${current}'`);

  const next = node.path[node.index + 1];

  if (!isKey(next))
    throw new Error(`Next resulted in a branch after '${current}'`);

  return {
    path: node.path,
    step: next,
    index: node.index + 1,
  };
}

export function countNode(path: Path, target: string): number {
  const flat = flattenPath(path, target);
  const index = flat.indexOf(target);

  return index > -1 ? index + 1 : index;
}

export function countPath(path: Path, current: string): number {
  const flat = flattenPath(path, current);
  const index = flat.indexOf(current);

  return index > -1 ? flat.length : -1;
}

export const useWizard = (
  path: Path,
  {
    onStepChange,
  }: { onStepChange?: (step: string, direction: Direction) => void } = {},
): WizardContextProps => {
  const [stack, setStack] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState<{
    name: string;
    number: number;
  }>({ name: path[0], number: 1 });

  const count = useMemo(
    () => countPath(path, currentStep.name),
    [currentStep.name, path],
  );

  const goTo = useCallback(
    (step: string, updateStack = false) => {
      const node = findNode(path, step);

      const flat = flattenPath(path, node.step);

      // const direction: Direction = currentIndex < newIndex ? "forward" : "back";
      const direction = "forward";

      if (updateStack) {
        setStack((current) => [...current, currentStep.name]);
      }

      setCurrentStep({ name: node.step, number: flat.indexOf(node.step) + 1 });

      onStepChange?.(node.step, direction);
    },
    [currentStep.name, onStepChange, path],
  );

  const goToStart = useCallback(() => {
    const flat = flattenPath(path);
    const firstStep = flat[0];

    if (!firstStep) throw new Error("No start step provided");

    setStack([]);
    setCurrentStep({ name: firstStep, number: 1 });

    onStepChange?.(firstStep, "back");
  }, [onStepChange, path]);

  const next = useCallback(() => {
    const node = nextNode(path, currentStep.name);

    setStack((current) => [...current, currentStep.name]);

    setCurrentStep({ name: node.step, number: countNode(path, node.step) });

    onStepChange?.(node.step, "forward");
  }, [currentStep, onStepChange, path]);

  const previous = useCallback(() => {
    const prev = stack.slice(-1)[0];

    const node = findNode(path, prev);

    setStack((current) => current.filter((s) => s !== prev));

    setCurrentStep({ name: node.step, number: countNode(path, prev) });

    onStepChange?.(node.step, "back");
  }, [onStepChange, path, stack]);

  return { step: currentStep, count, goTo, goToStart, next, previous };
};

const err = "Wizard context not available without a WizardProvider.";

const WizardContext = createContext<WizardContextProps>({
  step: { name: "", number: -1 },
  count: 0,
  goTo: () => {
    throw new Error(err);
  },
  goToStart: () => {
    throw new Error(err);
  },
  next: () => {
    throw new Error(err);
  },
  previous: () => {
    throw new Error(err);
  },
});

export const useWizardContext = () => useContext(WizardContext);

export const WizardProvider = ({
  children,
  ...wizard
}: {
  children?: ReactNode | ReactNode[];
} & WizardContextProps) => (
  <WizardContext.Provider value={wizard}>{children}</WizardContext.Provider>
);
