import styled from "styled-components";

import DS from "./system";

export const TinyText = styled.p`
  margin: 0;

  color: ${DS.palette.laidBackSilver};
  font-size: 10px;
`;

export const MediumText = styled.p`
  margin: 0;

  font-size: 14px;
  font-weight: 600;
`;

export const BodyText = styled.p`
  margin: 0;

  font-size: 16px;
`;

export const SemiStrongText = styled.p`
  margin: 0;

  color: ${({ theme }) => theme.palettes.body.foreground};
  font-size: 16px;
  font-weight: 600;
`;

export const StrongText = styled.p`
  margin: 0;

  color: ${({ theme }) => theme.palettes.body.foreground};
  font-size: 16px;
  font-weight: 700;
`;

export const SmallGroupHeader = styled.h4`
  padding: ${DS.margins.micro} 0 0;
  margin: 0 0 ${DS.margins.micro};

  color: ${({ theme }) => theme.palettes.body.small};
  font-size: 14px;
  font-weight: 600;

  border-top: solid 1px ${({ theme }) => theme.palettes.body.border};
`;

export const SidePanelTitle = styled.h3`
  margin: 0;

  color: ${({ theme }) => theme.palettes.body.foreground};
  font-size: 22px;
  font-weight: 700;
`;

export const ModalTitle = styled.h2`
  margin: 0;

  color: ${({ theme }) => theme.palettes.body.foreground};
  font-size: 22px;
  font-weight: 700;
`;

export const ModalDescription = styled.p`
  margin: 0;

  text-align: center;
  color: ${({ theme }) => theme.palettes.body.small};
  font-size: 16px;
`;

export const PanelTitle = styled.h3`
  margin: 0;

  color: ${({ theme }) => theme.palettes.body.foreground};
  font-size: 18px;
  font-weight: 600;
`;

export const LargeBrandTitle = styled.h2`
  margin: 0;

  color: ${({ theme }) => theme.palettes.body.title};
  font-size: 36px;
  font-weight: 800;
`;

export const TitleSmall = styled.h2`
  margin: 0;

  color: ${({ theme }) => theme.palettes.body.foreground};
  font-size: 22px;
  font-weight: 600;
`;

export const Well = styled.div`
  padding: ${DS.margins.micro};

  background: ${({ theme }) => theme.palettes.well.background};
  border-radius: ${DS.radii.item};
`;

export const SmallText = styled.p`
  margin: 0;

  font-size: 12px;
  color: ${({ theme }) => theme.palettes.body.small};

  ${Well} & {
    color: ${({ theme }) => theme.palettes.well.small};
  }
`;

export const MessageText = styled.p`
  margin: 0;

  font-size: 12px;
`;

/**
 * Works like a Well, but is designed to have an icon.
 *
 * Example:
 *
 * <IconWell>
 *   <Icon name="check-alt-circle" />
 *   <SmallText>Things went well</SmallTest>
 * </IconWell>
 */
export const IconWell = styled(Well)`
  display: grid;
  grid-auto-flow: column;
  gap: ${DS.margins.micro};
  align-items: center;
  justify-content: start;
`;

export const UnstyledList = styled.ul<{
  horizontal?: boolean;
  gutter?: number;
  gap?: number;
}>`
  padding: ${({ gutter }) => (gutter ? DS.margins.regularCss("rl") : 0)};
  margin: 0;

  list-style: none;

  display: grid;
  grid-auto-flow: ${({ horizontal }) => (horizontal ? "column" : "row")};
  width: ${({ horizontal }) => (horizontal ? "fit-content" : "auto")};
  gap: ${({ gap }) => (gap !== undefined ? `${gap}px` : DS.margins.micro)};
`;

export const LargeDataNotice = styled.p`
  margin: 0;
  padding: ${DS.margins.regular};

  color: ${({ theme }) => theme.palettes.body.small};
  text-align: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 660px;
  margin: 0 auto ${DS.margins.jumbo};
  padding: ${DS.margins.large} ${DS.margins.large} 2px;
`;
