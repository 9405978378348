import { useFormContext } from "react-hook-form";

import Button from "@components/Button";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";
import DS from "@design/system";
import { AedInspection } from "@util/viewModel";

const ChecksStepFooter = ({ index }: { index: number }) => {
  const { next, previous } = useWizardContext();
  const { watch } = useFormContext<AedInspection>();
  const run = watch(`ChecksCompleted.${index}`);

  const isDateCheckCompleted =
    run.DeviceInspectionCheck.Type === "Date" ? !!run.InspectionResult : true;

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: DS.margins.regular,
        }}
      >
        <Button onClick={previous}>
          <Icon name="arrow-alt-left" />
          Back
        </Button>
        <Button
          buttonType="progressive"
          onClick={next}
          disabled={!isDateCheckCompleted}
        >
          Next
          <Icon name="arrow-alt-right" />
        </Button>
      </div>
    </div>
  );
};

export default ChecksStepFooter;
