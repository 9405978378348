import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";

const SubmitMfaFooter = () => {
  const { previous, next } = useWizardContext();

  return (
    <ButtonGroup flow="horizontal">
      <Button onClick={previous}>
        <Icon name="arrow-alt-left" /> Back to setup
      </Button>
      <Button onClick={next} buttonType="progressive">
        Next, enter code <Icon name="arrow-alt-right" />
      </Button>
    </ButtonGroup>
  );
};

export default SubmitMfaFooter;
