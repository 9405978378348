import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import BorderBox from "@components/BorderBox";
import Button from "@components/Button";
import ChangeStoreContact from "@components/ChangeStoreContact";
import GroupHeader from "@components/GroupHeader";
import MediaCard from "@components/MediaCard";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import ModalStandardLayout from "@components/ModalStandardLayout";
import Scroller from "@components/Scroller";
import DS from "@design/system";
import { useRemoveUserFromGroup, useStore } from "@state/hooks";

const RemoveUserFromGroupModal = ({
  user,
  group,
  store,
  onConfirm,
  onClose,
}: {
  user: Api.User;
  group: Api.TreeGroup;
  store?: Api.Store;
  onConfirm: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { palettes } = useTheme();

  const { mutate: removeUserFromGroup, isPending } = useRemoveUserFromGroup();
  const { isLoading: isLoadingCompany, isFetching: isFetchingCompany } =
    useStore(store?.StoreId);

  const isStoreContact = useMemo(
    () => store?.StoreContactId === user.UserId,
    [store, user],
  );

  const handleConfirm = useCallback(() => {
    removeUserFromGroup(
      { userIds: [user.UserId], groupsIds: [group.GroupId] },
      {
        onError: () => {
          toast.error(
            <span>
              Unable to remove <strong>{user.FullName}</strong> from{" "}
              <strong>{group.Name}</strong>.
            </span>,
          );
        },
        onSuccess: () => {
          toast.success(
            <span>
              <strong>{user.FullName}</strong> was successfully removed from{" "}
              <strong>{group.Name}</strong>.
            </span>,
          );
          onConfirm();
        },
      },
    );
  }, [user, group, removeUserFromGroup, onConfirm]);

  return (
    <Modal contentLabel="Remove user from group" onClose={onClose}>
      <ModalStandardLayout
        header={
          <ModalHeader
            title={`Remove From ${
              group.Type === "SingleStore" ? "Store" : "Group"
            }`}
            onClose={onClose}
          />
        }
        content={
          <div
            style={{
              display: "grid",
              gap: DS.margins.regular,
              alignContent: "flex-start",
            }}
          >
            <BorderBox style={{ margin: DS.margins.largeCss("rl") }}>
              <MediaCard
                title={user.FullName}
                description={user.Email}
                descriptionExt={user.Roles[0].DisplayName}
              />
            </BorderBox>
            {isStoreContact ? (
              <Scroller>
                <div style={{ margin: DS.margins.largeCss("rl") }}>
                  <p
                    style={{
                      margin: DS.margins.microCss("b"),
                      color: palettes.well.foreground,
                    }}
                  >
                    <strong>{user.FullName}</strong> can not be removed from{" "}
                    <strong>{group.Name}</strong> as they are the{" "}
                    {t("term.store_one").toLowerCase()} contact.
                    <br />
                    <br />
                    You will need to change the{" "}
                    {t("term.store_one").toLowerCase()} contact for this{" "}
                    {t("term.store_one").toLowerCase()} to someone else before
                    you can continue:
                  </p>
                  <div>
                    <GroupHeader separator={false}>
                      {t("term.store_one")} Contact
                    </GroupHeader>
                    <BorderBox style={{ padding: DS.margins.microCss("rl") }}>
                      <ChangeStoreContact
                        storeId={store?.StoreId}
                        loading={isLoadingCompany || isFetchingCompany}
                      />
                    </BorderBox>
                  </div>
                </div>
              </Scroller>
            ) : (
              <p
                style={{
                  margin: DS.margins.largeCss("rl"),
                  color: palettes.well.foreground,
                }}
              >
                This action will remove <strong>{user.FullName}</strong> from{" "}
                <strong>{group.Name}</strong>.
                <br />
                <br />
                All data is retained within the person, so no data will be lost.
                Only the association is removed.
              </p>
            )}
          </div>
        }
        footer={
          <ModalFooter>
            {isStoreContact ? (
              <Button onClick={onClose}>Close</Button>
            ) : (
              <>
                <p>Are you sure you want to continue?</p>
                <Button disabled={isPending} onClick={onClose}>
                  No, take me back!
                </Button>
                <Button
                  buttonType="destructive"
                  disabled={isPending}
                  onClick={handleConfirm}
                >
                  {isPending
                    ? "Removing…"
                    : `Yes, remove from ${
                        group.Type === "SingleStore" ? "store" : "group"
                      }`}
                </Button>
              </>
            )}
          </ModalFooter>
        }
      />
    </Modal>
  );
};

export default RemoveUserFromGroupModal;
