import { useCallback } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import DS from "@design/system";

import BorderBox from "../components/BorderBox";
import Button from "../components/Button";
import MediaCard from "../components/MediaCard";
import Modal from "../components/Modal";
import ModalFooter from "../components/ModalFooter";
import ModalHeader from "../components/ModalHeader";
import ModalStandardLayout from "../components/ModalStandardLayout";
import { useDeleteGroup } from "../state/hooks";

const DeleteGroupModal = ({
  group,
  onConfirm,
  onClose,
}: {
  group: Api.GroupWithCount;
  onConfirm: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { palettes } = useTheme();

  const { mutate, isPending } = useDeleteGroup();

  const handleConfirm = useCallback(() => {
    mutate(group.GroupId, {
      onError: () => {
        toast.error(
          <span>
            Unable to delete <strong>{group.Name}</strong>.
          </span>,
        );
      },
      onSuccess: () => {
        toast.success(
          <span>
            <strong>{group.Name}</strong> successfully deleted.
          </span>,
        );

        onConfirm && onConfirm();
      },
    });
  }, [mutate, group, onConfirm]);

  return (
    <Modal contentLabel="Delete person" onClose={onClose}>
      <ModalStandardLayout
        header={<ModalHeader title="Delete Group" onClose={onClose} />}
        content={
          <div
            style={{
              margin: DS.margins.largeCss("rl"),
              display: "grid",
              gap: DS.margins.regular,
              alignContent: "flex-start",
            }}
          >
            <BorderBox>
              <MediaCard
                imageSize={64}
                title={group.Name}
                description={`${group.storeCount} ${t("term.store", {
                  count: group.storeCount,
                })}`}
              />
            </BorderBox>

            <p style={{ margin: 0, color: palettes.well.foreground }}>
              This action will permanently delete <strong>{group.Name}</strong>
              .<br />
              <br />
              It will <em>not</em> delete the stores, users or accessories
              linked to it.
            </p>
          </div>
        }
        footer={
          <ModalFooter>
            <p
              style={{
                margin: 0,
                textAlign: "center",
                color: palettes.well.foreground,
              }}
            >
              Are you sure you want to continue?
            </p>
            <Button disabled={isPending} onClick={onClose}>
              No, take me back!
            </Button>
            <Button
              buttonType="destructive"
              disabled={isPending}
              onClick={handleConfirm}
            >
              {isPending ? "Deleting group…" : "Yes, permanently delete."}
            </Button>
          </ModalFooter>
        }
      />
    </Modal>
  );
};

export default DeleteGroupModal;
