import { useCallback, useMemo } from "react";

import LocalisedDate from "@components/LocalisedDate";
import SidePanelContainer from "@components/SidePanelContainer";
import { DateFormat } from "@util/dateFormat";
import { EscalationTitle } from "@util/escalations";
import { EscalationProps } from "@util/viewModel";

import Scroller from "../components/Scroller";
import SidePanelFooter from "../components/SidePanelFooter";
import SidePanelHeader from "../components/SidePanelHeader";
import { useSidePanels } from "../util/SidePanels";
import AedInspection from "./escalations/AedInspection";
import AedInspectionFooter from "./escalations/AedInspectionFooter";
import AedPartsReplacement from "./escalations/AedPartsReplacement";
import AedPartsReplacementConfirmation from "./escalations/AedPartsReplacementConfirmation";
import AedPartsReplacementConfirmationFooter from "./escalations/AedPartsReplacementConfirmationFooter";
import AedPartsReplacementFooter from "./escalations/AedPartsReplacementFooter";
import ExpiredStock from "./escalations/ExpiredStock";
import MissingTool from "./escalations/MissingTool";
import PowerOff from "./escalations/PowerOff";
import Replenishment from "./escalations/Replenishment";

const EscalationSidePanel = ({
  escalation,
}: EscalationProps<Api.Escalation>) => {
  const { pop } = useSidePanels();
  const handleCloseClick = useCallback(() => pop(), [pop]);

  const detail = useMemo(() => {
    switch (escalation.ReminderType) {
      case "PowerOff":
        return <PowerOff escalation={escalation} />;
      case "Replenishment":
        return <Replenishment escalation={escalation} />;
      case "MissingTool":
      case "PutMultiUseItemBackIntoKit":
        return <MissingTool escalation={escalation} />;
      case "ExpiredStock":
        return <ExpiredStock escalation={escalation} />;
      case "DeviceInspection":
        return <AedInspection escalation={escalation} />;
      case "AedPartsReplacement":
        return <AedPartsReplacement escalation={escalation} />;
      case "AedPartsReplacementConfirmation":
        return <AedPartsReplacementConfirmation escalation={escalation} />;
      default:
        return null;
    }
  }, [escalation]);

  const footer = useMemo(() => {
    switch (escalation.ReminderType) {
      case "DeviceInspection":
        return <AedInspectionFooter escalation={escalation} />;
      case "AedPartsReplacement":
        return <AedPartsReplacementFooter escalation={escalation} />;
      case "AedPartsReplacementConfirmation":
        return (
          <AedPartsReplacementConfirmationFooter escalation={escalation} />
        );
      default:
        return null;
    }
  }, [escalation]);

  return (
    <SidePanelContainer>
      <div style={{ display: "grid" }}>
        <SidePanelHeader
          title={<EscalationTitle escalation={escalation} />}
          subtitle={
            <>
              <LocalisedDate
                dateTime={escalation.CreatedDate}
                format={DateFormat.shortDateTime}
              />
            </>
          }
          closeTitle="Close action view"
          onClose={handleCloseClick}
        />
      </div>

      <Scroller>
        <div>{detail}</div>
      </Scroller>

      <SidePanelFooter>{footer}</SidePanelFooter>
    </SidePanelContainer>
  );
};
export default EscalationSidePanel;
