import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapRef, MapboxGeoJSONFeature } from "react-map-gl";

import { useUnit } from "@state/hooks";
import { useThemeHelper } from "@util/useThemeHelper";

import { Pin, PinContent } from "./Pin";
import { UnitFeatureProperties } from "./types";

export const UnitEscalationMarker = ({
  mapRef,
  feature,
  id,
  selected,
  cluster,
  onChildChange,
}: {
  mapRef: MapRef | null;
  feature: MapboxGeoJSONFeature;
  id: string;
  selected: boolean;
  cluster?: number | null;
  onChildChange?: (child: string) => void;
}) => {
  const { t } = useTranslation();
  const { escalationsCountPalette } = useThemeHelper();

  const [selectedProperties, setSelectedProperties] =
    useState<UnitFeatureProperties | null>(null);

  const { actions } =
    selectedProperties ?? (feature.properties as UnitFeatureProperties);

  const { data: unit } = useUnit(selectedProperties?.id ?? id);

  const { foreground, background } = useMemo(
    () => escalationsCountPalette(actions),
    [actions, escalationsCountPalette],
  );

  const onChange = useCallback(
    (f: GeoJSON.Feature<GeoJSON.Geometry>) => {
      setSelectedProperties(f.properties as UnitFeatureProperties);
      onChildChange?.((f.properties as UnitFeatureProperties).id);
    },
    [onChildChange],
  );

  return (
    <Pin
      mapRef={mapRef}
      feature={feature}
      source={"units"}
      label={unit?.UnitName}
      selected={selected}
      cluster={cluster}
      onChange={onChange}
      content={() => (
        <PinContent
          label={<span style={{ color: foreground }}>{actions}</span>}
          description={
            !selected && cluster ? (
              <span style={{ color: foreground }}>
                {t("pages.map.markers.unitCount", { count: cluster })}
              </span>
            ) : (
              <span style={{ color: foreground }}>Actions</span>
            )
          }
          selected={selected}
          cluster={cluster}
        />
      )}
      background={(width, height) => (
        <rect width={width} height={height} fill={background} />
      )}
    />
  );
};
