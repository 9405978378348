export const downloadSummaryReport = ({
  companyId,
  userId,
  groupId,
  includeLowIncidents,
}: {
  companyId: string;
  userId?: string;
  groupId?: string;
  includeLowIncidents?: boolean;
}) =>
  `${
    import.meta.env.VITE_APP_API_ENDPOINT
  }/PdfPrint/SummaryReport?companyId=${companyId}&user=${
    userId ?? ""
  }&groupId=${groupId ?? ""}&numberDays=7&includeLowIncidents=${
    includeLowIncidents ?? ""
  }`;

export const downloadAedReport = ({
  companyId,
  userId,
  groupId,
}: {
  companyId: string;
  userId?: string;
  groupId?: string;
}) =>
  `${
    import.meta.env.VITE_APP_API_ENDPOINT
  }/PdfPrint/AedReport?companyId=${companyId}&groupId=${
    groupId ?? ""
  }&requestedByUserId=${userId ?? ""}`;

export const downloadAedReportCsv = ({
  companyId,
  storeId,
  groupId,
}: {
  companyId: string;
  storeId?: string;
  groupId?: string;
}) =>
  `${
    import.meta.env.VITE_APP_API_ENDPOINT
  }/xoria/v1/csv/aed-report?companyId=${companyId}${
    storeId ? `&storeId=${storeId}` : ""
  }${groupId ? `&groupId=${groupId}` : ""}`;

export const downloadIncidentStats = (groupId?: string) =>
  `${
    import.meta.env.VITE_APP_API_ENDPOINT
  }/EventHistory/GetIncidentStatSummaryByMonth?groupId=${groupId ?? ""}`;
