import styled, { useTheme } from "styled-components";

import DS from "@design/system";

const Line = styled.div`
  height: 0;
  border-top: solid 1px ${({ theme }) => theme.palettes.body.border};
`;

const WizardOptionSeparator = ({
  children,
}: {
  children: React.ReactElement | string;
}) => {
  const { palettes } = useTheme();

  return (
    <div
      style={{
        margin: DS.margins.microCss("tb"),
        display: "grid",
        gridTemplateColumns: "1fr auto 1fr",
        alignItems: "center",
      }}
    >
      <Line />
      <div
        style={{
          padding: "0 10px",
          fontStyle: "italic",
          fontWeight: 400,
          color: palettes.body.small,
        }}
      >
        {children}
      </div>
      <Line />
    </div>
  );
};

export default WizardOptionSeparator;
