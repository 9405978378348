import { Dispatch, SetStateAction, useMemo } from "react";

import { useUserPreference } from "./useUserPreference";

export function useMapLayer(): [
  string,
  Dispatch<SetStateAction<"default" | "satellite">>,
] {
  const [mapView, setMapview] = useUserPreference({
    key: "mapView",
    defaultValue: "default",
  });

  const mapStyle = useMemo(
    () =>
      mapView === "default"
        ? import.meta.env.VITE_APP_MAPBOX_DEFAULT_STYLE
        : import.meta.env.VITE_APP_MAPBOX_SATELLITE_STYLE,
    [mapView],
  );

  return [mapStyle, setMapview];
}
