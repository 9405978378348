import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Session, SessionState, useAuth } from "./auth";

const publicPaths = ["/login", "/set-password", "/forgot-password"];

const INITIAL_PATH = publicPaths.find((path) =>
  location.pathname.startsWith(path),
)
  ? ""
  : location.pathname;

const useRedirectUrl = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();

  const { session } = useAuth();

  const redirectUrl = useRef<string>(INITIAL_PATH);
  const previousSession = useRef<Session | null>();

  useEffect(() => {
    // Tried to go somewhere, but we aren't logged in. Take the user to the log
    // in page, and remember where they came from.
    if (
      !publicPaths.find((path) => pathname.startsWith(path)) &&
      session.state === SessionState.None
    ) {
      redirectUrl.current =
        previousSession.current?.state === SessionState.Authenticated
          ? ""
          : // FIXME: This is a bit loose. Just use full URL?
            `${pathname}${search}`;

      history.replace("/login");
    }

    // User landed on the log in page but they are already logged in, take them
    // to where they came from, or the home page.
    if (pathname === "/login" && session.state === SessionState.Authenticated) {
      history.push(redirectUrl.current ?? "/");
      redirectUrl.current = "";
    }

    previousSession.current = session;
  }, [history, pathname, search, session]);
};

export default useRedirectUrl;
