import { ReactElement } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 360px;
  height: 560px;

  display: grid;
  grid-template-rows: auto 1fr auto;

  > * {
    overflow: hidden;
  }
`;

const ModalContainer = ({
  header,
  content,
  footer,
}: {
  header: ReactElement;
  content: ReactElement;
  footer: ReactElement;
}) => {
  return (
    <Container>
      {header}
      {content}
      {footer}
    </Container>
  );
};

export default ModalContainer;
