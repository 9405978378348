import styled, { useTheme } from "styled-components";

import DS from "@design/system";

import { IIcon } from "../icons";
import Icon from "./Icon";
import { useTooltip } from "./Tooltip";

export type BarSize = "light" | "medium" | "heavy";

const BarContainer = styled.label<{ size: BarSize; clickable: boolean }>`
  box-sizing: border-box;
  display: block;
  width: ${({ size }) =>
    size === "light" ? 12 : size === "medium" ? 18 : 38}px;
  height: 100%;
  padding: ${({ size }) =>
    size === "light" ? 2 : size === "medium" ? 2 : 4}px;
  padding-top: ${DS.margins.regularN + DS.margins.microN}px;
  padding-bottom: 0;

  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  border: 0;
  border-radius: ${DS.radii.item} ${DS.radii.item} 0 0;
  background: transparent;

  display: grid;
  grid-template-rows: 1fr auto;

  &:hover {
    background: ${({ theme }) => theme.palettes.form.light};
  }
`;

const BarFillContainer = styled.div<{ size: BarSize }>`
  overflow: hidden;

  border-radius: ${({ size }) =>
    size === "light"
      ? "2px 2px 0 0"
      : size === "medium"
        ? "3px 3px 0 0"
        : "4px 4px 0 0"};

  transition: all 350ms;
`;

const BarFill = styled.div<{
  size: BarSize;
  color?: string;
  hasSelection?: boolean;
  selected?: boolean;
}>`
  border: 0;
  background: ${({ theme, hasSelection, selected, color }) =>
    hasSelection
      ? selected
        ? theme.palettes.body.accent
        : theme.palettes.itemSelected.background
      : color ?? theme.palettes.body.accent};

  transition: all 350ms;
`;

export const Bar = ({
  title,
  icon,
  color,
  value,
  max,
  hasSelection,
  selected,
  showLabel = true,
  size = "medium",
  onChange,
}: {
  title?: string;
  icon?: IIcon;
  color?: string[] | string;
  value: number[] | number;
  max: number;
  hasSelection?: boolean;
  selected?: boolean;
  showLabel?: boolean;
  size?: BarSize;
  onChange?: React.ChangeEventHandler;
}) => {
  const { palettes } = useTheme();

  const { tooltipProps } = useTooltip<HTMLLabelElement>(title ?? "empty");

  return (
    <BarContainer size={size} clickable={!!onChange} {...tooltipProps}>
      {onChange && (
        <input
          style={{
            position: "absolute",
            opacity: 0,
            pointerEvents: "none",
          }}
          type="checkbox"
          name={title}
          onChange={onChange}
          checked={selected}
        />
      )}
      <div
        style={{
          position: "relative",
          height: "100%",
          display: "grid",
          alignItems: "end",
        }}
      >
        {icon && (
          <div
            style={{
              position: "absolute",
              bottom: `${
                ((value instanceof Array
                  ? value.reduce((p, v) => p + v)
                  : value) /
                  (max === 0 ? 1 : max)) *
                100
              }%`,
              left: "50%",
              transform: "translateX(-50%)",
              transition: "all 350ms",
            }}
          >
            <Icon
              name={icon}
              size={size === "light" ? 10 : "inherit"}
              color={
                hasSelection
                  ? selected
                    ? palettes.body.accent
                    : palettes.body.small
                  : palettes.body.small
              }
            />
          </div>
        )}
        {value instanceof Array ? (
          <BarFillContainer
            size={size}
            style={{
              height:
                value.reduce((p, v) => p + v, 0) > 0
                  ? `${(value.reduce((p, v) => p + v, 0) / max) * 100}%`
                  : "3px",
            }}
          >
            {value.reduce((p, v) => p + v, 0) > 0 ? (
              value.map((v, i) => (
                <BarFill
                  key={`fill-${i}`}
                  size={size}
                  hasSelection={hasSelection}
                  selected={selected}
                  color={color && (color instanceof Array ? color[i] : color)}
                  style={{
                    height: `${
                      (v /
                        (value.reduce((p, v) => p + v, 0) === 0
                          ? 1
                          : value.reduce((p, v) => p + v, 0))) *
                      100
                    }%`,
                  }}
                />
              ))
            ) : (
              <BarFill
                key={`fill-${title ?? ""}`}
                size={size}
                hasSelection={hasSelection}
                selected={selected}
                color={palettes.body.dim}
                style={{ height: 3 }}
              />
            )}
          </BarFillContainer>
        ) : (
          <BarFillContainer
            size={size}
            style={{
              height: `${
                value ? `${(value / (max === 0 ? 1 : max)) * 100}%` : "3px"
              }`,
            }}
          >
            <BarFill
              size={size}
              hasSelection={hasSelection}
              selected={selected}
              style={{ height: "100%" }}
              color={
                value
                  ? color && !(color instanceof Array)
                    ? color
                    : palettes.body.accent
                  : palettes.body.dim
              }
            />
          </BarFillContainer>
        )}
      </div>
      {showLabel && (
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: hasSelection
              ? selected
                ? palettes.body.foreground
                : palettes.body.small
              : palettes.body.small,
            textAlign: "center",
          }}
        >
          {value}
        </div>
      )}
    </BarContainer>
  );
};
