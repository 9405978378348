import color from "color";
import { Link as Ln } from "react-router-dom";
import styled from "styled-components";

import DS from "@design/system";

const SecondaryButton = styled.button.attrs({
  type: "button",
})<{ inline?: boolean }>`
  margin: 0;
  padding: ${({ inline }) => (inline ? 0 : DS.margins.nano)};

  cursor: pointer;
  color: ${({ theme }) => theme.palettes.body.accent};

  border: 0;
  border-radius: ${DS.radii.item};
  background: none;

  outline-offset: -3px;

  &:active {
    transform: translate3d(0, 1px, 0);
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(101, 184, 212, 0.5);
  }
`;

export const RouteLink = styled(Ln)`
  margin: 0;

  text-decoration: none;
  color: ${({ theme }) => theme.palettes.body.accent};

  border: 0;
  background: transparent;

  &:active {
    transform: translate3d(0, 1px, 0);
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(101, 184, 212, 0.5);
  }
`;

export const Link = styled.a`
  margin: 0;

  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.palettes.body.accent};

  border: 0;
  background: transparent;

  transition: 200ms;

  &:active {
    transform: translate3d(0, 1px, 0);
  }

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) =>
      color(theme.palettes.body.accent).darken(0.25).string()};
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(101, 184, 212, 0.5);
  }
`;

export const LinkButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
) => <Link as="button" type="button" {...props} />;

export default SecondaryButton;
