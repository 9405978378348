import { useMemo } from "react";

import { DateRange } from "@util/DateRange";
import { DateFormat, dateFormatter } from "@util/dateFormat";

const LocalisedDate = ({
  dateTime,
  format,
}: {
  dateTime: string | number | Date | DateRange;
  format: DateFormat;
}) => {
  const formatted = useMemo(() => {
    return dateFormatter(dateTime, format);
  }, [dateTime, format]);

  return <span data-utc={dateTime}>{formatted}</span>;
};

export default LocalisedDate;
