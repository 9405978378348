import { startOfToday } from "date-fns";
import { useCallback, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import { LabelledDatePicker } from "@components/FormControls";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import {
  WizardControlsContainer,
  WizardIconContainer,
  WizardStepContainer,
} from "@components/ModalWizardHelper";
import ModalWizardLayout from "@components/ModalWizardLayout";
import { AedCalendar } from "@components/WizardIcons";
import { ModalDescription } from "@design/helpers";
import { useScheduleInspection } from "@state/hooks";

const ScheduleInspectionModal = ({
  controllerSerialNumber,
  onClose,
}: {
  controllerSerialNumber: string;
  onClose: () => void;
}) => {
  const { mutate: scheduleInspection } = useScheduleInspection();
  const { t } = useTranslation();

  const {
    control,
    formState: { isSubmitting },
    register,
    handleSubmit,
  } = useForm<{ controllerSerialNumber: string; dueDate: string }>({
    defaultValues: {
      controllerSerialNumber: controllerSerialNumber,
      dueDate: new Date().toString(),
    },
  });

  const dateValue = useWatch({ control, name: "dueDate", defaultValue: "" });
  const isValidDate = new Date(startOfToday()) <= new Date(dateValue);
  const yesterday = useMemo(() => {
    const day = new Date();
    day.setDate(day.getDate() - 1);
    return day;
  }, []);

  const onSubmit = useCallback(
    (formEvent?: React.FormEvent) =>
      void handleSubmit(async (data) =>
        new Promise((resolve, reject) =>
          scheduleInspection(data, { onSuccess: resolve, onError: reject }),
        ).then(() => {
          onClose();
          toast.success(<span>{t("term.aed_one")} inspection scheduled</span>);
        }),
      )(formEvent),
    [handleSubmit, onClose, scheduleInspection, t],
  );

  return (
    <Modal
      contentLabel={`Schedule ${t("term.aed_one")} inspection`}
      onClose={onClose}
    >
      <form onSubmit={onSubmit}>
        <ModalWizardLayout
          header={
            <ModalHeader
              title={`Schedule ${t("term.aed_one")} inspection`}
              onClose={onClose}
            />
          }
          content={
            <WizardStepContainer style={{ gridTemplateRows: "1fr" }}>
              <WizardControlsContainer>
                <WizardIconContainer>
                  <AedCalendar size={96} />
                </WizardIconContainer>

                <LabelledDatePicker
                  label="Date for inspection"
                  minDate={yesterday}
                  {...register("dueDate")}
                />

                <ModalDescription>
                  Enter a date for the inspection.
                </ModalDescription>
              </WizardControlsContainer>
            </WizardStepContainer>
          }
          footer={
            <ModalFooter>
              <ButtonGroup flow="horizontal">
                <Button onClick={onClose} disabled={isSubmitting}>
                  Close
                </Button>
                <Button
                  type="submit"
                  buttonType="action"
                  disabled={!isValidDate || isSubmitting}
                >
                  {isSubmitting
                    ? "Scheduling inspection"
                    : `Schedule ${t("term.aed_one")} inspection`}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          }
        />
      </form>
    </Modal>
  );
};

export default ScheduleInspectionModal;
