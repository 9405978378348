import { t } from "i18next";
import { useTheme } from "styled-components";

import { Offline100 } from "@components/FASIcon";
import Icon from "@components/Icon";
import MediaCard from "@components/MediaCard";
import StoreTime from "@components/StoreTime";
import { IconWell, SmallText } from "@design/helpers";
import DS from "@design/system";
import { useUnit } from "@state/hooks";
import { DateFormat } from "@util/dateFormat";
import { EscalationProps } from "@util/viewModel";

const PowerOff = ({ escalation }: EscalationProps<Api.PowerOffEscalation>) => {
  const { palettes } = useTheme();

  const { data: unit } = useUnit(escalation.ControllerSerialNumber);

  return (
    <div
      style={{
        overflow: "hidden",
        padding: `${DS.margins.micro} ${DS.margins.regular}`,
        display: "grid",
        gap: DS.margins.regular,
      }}
    >
      <IconWell>
        <Icon
          name="exclamation-alt-circle"
          color={palettes.messages.info.background}
        />
        <SmallText>
          This first aid cabinet lost power on{" "}
          <strong>
            <StoreTime
              storeName={escalation.StoreName}
              dateTime={escalation.CreatedDate}
              format={DateFormat.dateTimeAndTimeZone}
            />{" "}
            ({t("term.store_one")} time)
          </strong>
          . Please ensure that it is plugged in correctly at the wall.
        </SmallText>
      </IconWell>

      <div
        style={{
          display: "grid",
          gap: DS.margins.micro,
          gridTemplateColumns: "auto 1fr",
        }}
      >
        <Offline100 />
        <MediaCard
          title="First Aid Cabinet Offline"
          description={unit?.UnitName}
        />
      </div>
    </div>
  );
};

export default PowerOff;
