import { useTranslation } from "react-i18next";

import Button from "@components/Button";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import ModalMessageLayout from "@components/ModalMessageLayout";
import DS from "@design/system";

const NoPermissionsModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  return (
    <Modal>
      <ModalMessageLayout
        header={<ModalHeader title={t("modals.noPermission.title")} />}
        content={
          <div
            style={{
              textAlign: "center",
              position: "relative",
              padding: `${DS.margins.regular} ${DS.margins.regular}`,
            }}
          >
            {t("modals.noPermission.body")}
          </div>
        }
        footer={
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        }
      />
    </Modal>
  );
};
export default NoPermissionsModal;
