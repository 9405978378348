import { MouseEventHandler, useMemo } from "react";
import { useTheme } from "styled-components";

import ContentLoader from "@util/ContentLoader";

import { useTooltip } from "./Tooltip";

const donutSegment = (
  start: number,
  end: number,
  r: number,
  r0: number,
  offset = 0,
  rotation = -0.25,
) => {
  if (end - start === 1) end -= 0.00001;
  const a0 = 2 * Math.PI * (start + rotation);
  const a1 = 2 * Math.PI * (end + rotation);
  const x0 = Math.cos(a0),
    y0 = Math.sin(a0);
  const x1 = Math.cos(a1),
    y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  return `M ${offset + r + r0 * x0} ${offset + r + r0 * y0} L ${
    offset + r + r * x0
  } ${offset + r + r * y0} A ${r} ${r} 0 ${largeArc} 1 ${offset + r + r * x1} ${
    offset + r + r * y1
  } L ${offset + r + r0 * x1} ${
    offset + r + r0 * y1
  } A ${r0} ${r0} 0 ${largeArc} 0 ${offset + r + r0 * x0} ${
    offset + r + r0 * y0
  }`;
};

export type DonutValue<T extends string = string> = {
  id: T;
  count: number;
  color: string;
  title?: string;
};

const Segment = <T extends string>({
  offset = 0,
  value,
  index,
  half,
  offsets,
  radius,
  total,
  thickness,
  selected,
  hasSelected,
  onClick,
}: {
  offset: number;
  value: DonutValue<T>;
  index: number;
  half?: boolean;
  offsets: number[];
  radius: number;
  total: number;
  thickness: number;
  selected?: boolean;
  hasSelected?: boolean;
  onClick?: MouseEventHandler;
}) => {
  const { tooltipProps } = useTooltip<SVGPathElement>(
    `${value.title ?? ""} (${value.count})`,
  );

  return (
    <path
      {...tooltipProps}
      style={{
        transition: "all 350ms",
        cursor: onClick ? "pointer" : "default",
      }}
      id={value.id}
      key={value.id}
      d={
        half
          ? donutSegment(
              offsets[index] / total / (360 / 210),
              (offsets[index] + value.count) / total / (360 / 210),
              radius,
              radius + thickness,
              offset,
              0.5 - 15 / 360,
            )
          : donutSegment(
              offsets[index] / total,
              (offsets[index] + value.count) / total,
              radius,
              radius + thickness,
              offset,
            )
      }
      fill={value.color}
      stroke={value.color}
      strokeWidth={selected ? 4 : 0}
      strokeLinejoin="round"
      strokeLinecap="round"
      opacity={!hasSelected ? 1 : selected ? 1 : 0.25}
      onClick={onClick}
    />
  );
};

export const DonutPlaceholder = ({
  radius = 50,
  thickness = 20,
  half,
}: {
  radius?: number;
  thickness?: number;
  half?: boolean;
}) => {
  const size = useMemo(() => radius * 2 + thickness * 2, [radius, thickness]);

  return (
    <ContentLoader width={size} height={half ? size / 1.6 : size}>
      <Segment
        offset={thickness}
        half={half}
        value={{
          id: "loading",
          count: 1,
          color: "black",
        }}
        index={0}
        offsets={[0]}
        radius={radius}
        total={1}
        thickness={thickness}
      />
    </ContentLoader>
  );
};

const Donut = <T extends string>({
  radius = 50,
  thickness = 20,
  half,
  values,
  emptyColor,
  background,
  selected,
  onChange,
}: {
  radius?: number;
  thickness?: number;
  half?: boolean;
  values: DonutValue<T>[];
  emptyColor?: string;
  background?: string;
  selected?: T[] | null | string;
  onChange?: (id: T) => void;
}) => {
  const { palettes } = useTheme();

  const size = useMemo(() => radius * 2 + thickness * 2, [radius, thickness]);

  const { offsets, total } = useMemo(() => {
    const offsets = [];
    let total = 0;

    for (const value of values) {
      offsets.push(total);
      total += value.count;
    }

    return {
      offsets,
      total,
    };
  }, [values]);

  const hasSelected = useMemo(
    () => !!(selected && selected.length),
    [selected],
  );

  return (
    <svg
      width={size}
      height={half ? size / 1.6 : size}
      viewBox={`0 0 ${size} ${half ? size / 1.6 : size}`}
      style={{ display: "block", overflow: "visible" }}
    >
      <circle
        cx={size / 2}
        cy={size / 2}
        r={size / 2}
        fill={background ?? "none"}
      />
      {total > 0 ? (
        <g>
          {values.map((value, i) => (
            <Segment
              offset={thickness}
              key={value.id}
              value={value}
              index={i}
              half={half}
              offsets={offsets}
              total={total}
              thickness={thickness}
              radius={radius}
              selected={
                selected instanceof Array
                  ? selected.includes(value.id)
                  : selected === value.id
              }
              hasSelected={hasSelected}
              onClick={() => onChange?.(value.id)}
            />
          ))}
          {values
            .filter((value) => selected?.includes(value.id))
            .map((value) => (
              <use
                key={value.id}
                xlinkHref={`#${value.id}`}
                style={{ pointerEvents: "none" }}
              />
            ))}
        </g>
      ) : (
        <g>
          {half ? (
            <path
              d={donutSegment(
                0,
                1 / (360 / 210),
                radius,
                radius + thickness,
                thickness,
                0.5 - 15 / 360,
              )}
              fill={emptyColor ?? palettes.form.dim}
            />
          ) : (
            <circle
              cx={radius + thickness}
              cy={radius + thickness}
              r={radius + thickness / 2}
              strokeWidth={thickness}
              stroke={emptyColor ?? palettes.form.dim}
              fill={background ?? "none"}
            />
          )}
        </g>
      )}
    </svg>
  );
};

export default Donut;
