import color from "color";
import { motion } from "framer-motion";
import { useMemo } from "react";
import styled from "styled-components";

import DS from "@design/system";

const DOT_SIZE = DS.margins.microN;
const DOT_GAP = DS.margins.microN;

const Container = styled.div`
  position: relative;

  display: grid;
  gap: ${DOT_GAP}px;
  grid-auto-flow: column;
  justify-self: center;
`;

const Dot = styled.div<{ current: boolean }>`
  width: ${({ current }) => (current ? 16 : DOT_SIZE)}px;
  height: ${DOT_SIZE}px;

  background: ${({ theme }) =>
    color(theme.palettes.body.foreground).alpha(0.25).toString()};
  border-radius: ${DOT_SIZE / 2}px;

  transition: width 200ms ease-in-out;
`;

const CurrentDot = styled(Dot)`
  background: ${({ theme }) => theme.palettes.body.accent};
`;

const WizardSteps = ({
  steps,
  current,
}: {
  steps: number;
  current: number;
}) => {
  const position = useMemo(
    () => Math.max(0, Math.min(current, steps) - 1) * (DOT_SIZE + DOT_GAP),
    [steps, current],
  );

  const dots = useMemo(
    () =>
      Array.from(Array(steps)).map((_d, i) => (
        <Dot key={`dot-${i}`} current={i === current - 1} />
      )),
    [current, steps],
  );

  return (
    <Container>
      {dots}

      <motion.div style={{ position: "absolute" }} animate={{ x: position }}>
        <CurrentDot current={true} />
      </motion.div>
    </Container>
  );
};

export default WizardSteps;
