import { useFormContext } from "react-hook-form";

import { WizardStepContainer } from "@components/ModalWizardHelper";
import Scroller from "@components/Scroller";
import { Battery } from "@components/WizardIcons";
import WizardOption from "@components/WizardOption";
import { ModalDescription, UnstyledList } from "@design/helpers";
import { ToolReplacementForm } from "@util/viewModel";

const SelectReasonStep = () => {
  const { register } = useFormContext<ToolReplacementForm>();

  return (
    <WizardStepContainer>
      <ModalDescription>
        Select the reason(s) replacement is not needed.
      </ModalDescription>
      <Scroller>
        <UnstyledList style={{ padding: "15px 64px" }}>
          <li>
            <WizardOption
              title="Item is accounted for."
              description={
                "This item is in or around the cabinet, but not being detected."
              }
              image={<Battery />} // todo: replace with relevant image
              value="accounted for"
              {...register("selectedReasons")}
            />
          </li>
          <li>
            <WizardOption
              title="Already been requested."
              description={
                "You've previously  requested a replacement for this item."
              }
              image={<Battery />}
              value="already requested"
              {...register("selectedReasons")}
            />
          </li>
          <li>
            <WizardOption
              title="Other"
              description={
                "Other reasons not listed above. You will be asked to specify a reason."
              }
              image={<Battery />}
              value="other"
              {...register("selectedReasons")}
            />
          </li>
        </UnstyledList>
      </Scroller>
    </WizardStepContainer>
  );
};

export default SelectReasonStep;
