import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTheme } from "styled-components";

import { isApiResponseError } from "@api";
import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import ErrorWell from "@components/ErrorWell";
import { LabelledInput } from "@components/FormControls";
import Icon from "@components/Icon";
import MapLocationChange from "@components/MapLocationChange";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import {
  Overlay,
  WizardControlsContainer,
  WizardStepContainer,
} from "@components/ModalWizardHelper";
import ModalWizardLayout from "@components/ModalWizardLayout";
import { ModalDescription } from "@design/helpers";
import DS from "@design/system";
import {
  useHasPermission,
  useMutateUnit,
  useUnitFull,
  useUser,
} from "@state/hooks";
import { TextLoader } from "@util/ContentLoader";

import NoPermissionsModal from "./NoPermissionModal";

const ProposeDeviceLocationModal = ({
  controllerSerialNumber,
  userId,
  description,
  latitude,
  longitude,
  onClose,
}: {
  controllerSerialNumber: string;
  userId: string;
  description: string;
  latitude: number;
  longitude: number;
  onClose: () => void;
}) => {
  const { palettes } = useTheme();

  const {
    data: unit,
    isLoading,
    isError,
  } = useUnitFull(controllerSerialNumber);
  const { mutate: updateUnit } = useMutateUnit();
  const { data: user } = useUser(userId);

  const { hasPermission } = useHasPermission();

  const [attemptCancel, setAttemptCancel] = useState(false);

  const { formState, handleSubmit, register, reset, setError } =
    useForm<Api.Unit>();

  const handleCloseAttempt = useCallback(() => setAttemptCancel(true), []);

  const onSubmit = useCallback(
    (formEvent?: React.FormEvent) =>
      void handleSubmit(async (data) => {
        return new Promise<Api.ResponseSuccess<Api.Unit>>((resolve, reject) =>
          updateUnit(data, { onSuccess: resolve, onError: reject }),
        )
          .then((response) => response.SuccessPayload)
          .then((updatedUnit) => {
            onClose();
            toast.success(
              <span>
                <strong>{updatedUnit.UnitName}</strong> successfully updated.
              </span>,
            );
          })
          .catch((error) => {
            if (isApiResponseError<Api.UserValidationError>(error)) {
              setError("root", {
                message:
                  "We encountered an issue on our side. Please try again soon.",
              });
            }
          });
      })(formEvent),
    [handleSubmit, onClose, setError, updateUnit],
  );

  useEffect(() => {
    unit &&
      reset({
        ...unit,
        Location: description,
        Latitude: latitude,
        Longitude: longitude,
      });
  }, [description, latitude, longitude, reset, unit]);

  return !hasPermission("units_edit_unit") ? (
    <NoPermissionsModal onClose={onClose} />
  ) : (
    <Modal
      contentLabel="Confirm Device Location Change"
      onClose={handleCloseAttempt}
    >
      <form onSubmit={onSubmit}>
        <ModalWizardLayout
          header={
            <div style={{ position: "relative" }}>
              <ModalHeader
                title={"Proposed Location Change"}
                onClose={handleCloseAttempt}
              />

              <Overlay show={attemptCancel} />
            </div>
          }
          content={
            <div style={{ position: "relative" }}>
              {isError ? (
                <div style={{ padding: DS.margins.regular }}>
                  <ErrorWell style={{ textAlign: "center" }}>
                    <Icon
                      name="exclamation-alt-circle"
                      color={palettes.states.bad.background}
                    />{" "}
                    There was a problem loading device data.
                  </ErrorWell>
                </div>
              ) : (
                <WizardStepContainer>
                  <ModalDescription style={{ padding: "0 64px" }}>
                    {!user || !unit ? (
                      <span style={{ display: "grid", justifyItems: "center" }}>
                        <TextLoader width={300} />
                      </span>
                    ) : (
                      <>
                        Location change for <strong>{unit.UnitName}</strong>{" "}
                        suggested by <strong>{user.FullName}</strong>.
                      </>
                    )}
                  </ModalDescription>

                  <WizardControlsContainer>
                    <LabelledInput
                      label="Location"
                      loading={isLoading}
                      {...register("Location")}
                    />
                    <MapLocationChange
                      width={288}
                      height={200}
                      from={
                        unit && {
                          label: "From",
                          marker: (
                            <div
                              style={{
                                padding: DS.margins.micro,
                                background: palettes.states.na.background,
                                borderRadius: DS.radii.pill,
                                border: "solid 2px",
                                borderColor: palettes.states.na.foreground,
                                display: "grid",
                              }}
                            >
                              <Icon
                                name="aed"
                                color={palettes.states.na.foreground}
                                size={14}
                              />
                            </div>
                          ),
                          longitude: unit.Longitude,
                          latitude: unit.Latitude,
                        }
                      }
                      to={{
                        label: "To",
                        marker: (
                          <div
                            style={{
                              padding: 12, // DS.margins.micro,
                              background: palettes.states.good.background,
                              borderRadius: DS.radii.pill,
                              border: "solid 2px",
                              borderColor: palettes.states.good.foreground,
                              display: "grid",
                            }}
                          >
                            <Icon
                              name="aed"
                              color={palettes.states.good.foreground}
                            />
                          </div>
                        ),
                        longitude,
                        latitude,
                      }}
                    />
                  </WizardControlsContainer>
                </WizardStepContainer>
              )}

              <Overlay show={attemptCancel} />
            </div>
          }
          footer={
            <ModalFooter>
              {attemptCancel ? (
                <>
                  <p>
                    You have not confirmed the location change request. If you
                    leave now{" "}
                    <strong>
                      the new location will <em>not</em> be saved
                    </strong>
                    .
                  </p>
                  <p>Are you sure you want to leave now?</p>
                  <ButtonGroup flow="horizontal">
                    <Button onClick={() => setAttemptCancel(false)}>
                      No, I&apos;m not finished.
                    </Button>
                    <Button buttonType="destructive" onClick={onClose}>
                      Yes, leave now.
                    </Button>
                  </ButtonGroup>
                </>
              ) : (
                <div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: DS.margins.regular,
                    }}
                  >
                    <Button onClick={() => setAttemptCancel(true)}>
                      Cancel location change
                    </Button>
                    <Button
                      type="submit"
                      buttonType="action"
                      disabled={formState.isSubmitting}
                    >
                      {formState.isSubmitting
                        ? "Saving location…"
                        : "Confirm new location"}
                    </Button>
                  </div>
                </div>
              )}
            </ModalFooter>
          }
        />
      </form>
    </Modal>
  );
};

export default ProposeDeviceLocationModal;
