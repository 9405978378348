import { ReactNode } from "react";
import { styled } from "styled-components";

import Icon from "@components/Icon";
import { Link } from "@components/SecondaryButton";
import DS from "@design/system";
import { IIcon } from "@icons";
import { MessagePalette, PaletteHelperFns, Palettes } from "@themes/types";
import { useThemeHelper } from "@util/useThemeHelper";

const Container = styled.div<{
  palette: MessagePalette & PaletteHelperFns;
  withIcon: boolean;
}>`
  padding: ${DS.margins.micro};

  ${({ palette }) => palette.toCss()};

  font-size: 12px;
  border-radius: ${DS.radii.item};

  display: grid;
  gap: ${DS.margins.micro};
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;

  ${Link} {
    color: ${({ palette }) => palette.foreground};
    font-weight: 700;
    text-decoration: underline;
  }
`;

export default function Message({
  children,
  type,
  icon,
}: {
  children: ReactNode;
  type: keyof Palettes["messages"];
  icon?: IIcon | null;
}) {
  const { messagesPalette, messageTypeIcon } = useThemeHelper();

  if (icon === undefined) icon = messageTypeIcon(type);

  return (
    <Container palette={messagesPalette(type)} withIcon={!!icon}>
      {icon !== null && <Icon name={icon} size={16} />}
      <div>{children}</div>
    </Container>
  );
}
