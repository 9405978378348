import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTheme } from "styled-components";

import ErrorWell from "@components/ErrorWell";
import MediaCard from "@components/MediaCard";
import { WizardStepContainer } from "@components/ModalWizardHelper";
import Scroller from "@components/Scroller";
import { Location } from "@components/WizardIcons";
import { ModalDescription, UnstyledList } from "@design/helpers";
import { useUnitFull } from "@state/hooks";
import {
  getInspectionRunResult,
  getInspectionTypeIcon,
} from "@util/inspections";
import { AedInspection } from "@util/viewModel";

const hasChanges = (
  newValues: (string | null | undefined)[],
  originalValues: (string | null | undefined)[],
) =>
  newValues.length === originalValues.length &&
  newValues.filter(
    (v) =>
      !originalValues
        .map((o) => (o === undefined || o === null ? "" : o))
        .includes(v ?? ""),
  ).length === 0;

const notSpecifiedOrRender = (
  value: string | null | undefined,
  render: (v: string) => React.ReactNode,
) => (value ? render(value) : <em>Not specified</em>);

const useRenderDiff = () => {
  const { palettes } = useTheme();

  const renderer = useCallback(
    (
      newValue: string | null | undefined,
      showDiff: boolean,
      originalValue: string | null | undefined,
      render: (value: string) => React.ReactNode = (value) => value,
    ) => {
      const original = originalValue
        ? originalValue.length > 20 && isNaN(new Date(originalValue).getTime())
          ? `${originalValue.substring(0, 20)}…`
          : originalValue
        : "";

      if (!showDiff || hasChanges([newValue], [originalValue]))
        return notSpecifiedOrRender(newValue, render);

      return (
        <span>
          <span
            style={{
              color: palettes.body.small,
              textDecoration: "line-through",
            }}
          >
            {notSpecifiedOrRender(original, render)}
          </span>
          <span style={{ color: palettes.body.small }}> → </span>
          {notSpecifiedOrRender(newValue, render)}
        </span>
      );
    },
    [palettes.body.small],
  );

  return renderer;
};

const ReviewStep = () => {
  const renderDiff = useRenderDiff();

  const {
    watch,
    formState: { errors },
  } = useFormContext<AedInspection>();

  const [
    controllerSerialNumber,
    locationDescription,
    checksCompleted,
    longitude,
    latitude,
  ] = watch([
    "ControllerSerialNumber",
    "NewLocation",
    "ChecksCompleted",
    "NewLongitude",
    "NewLatitude",
  ]);

  const { data: unit } = useUnitFull(controllerSerialNumber);

  const locationChanged = useMemo(() => {
    const unitLatitude = unit?.Latitude.toFixed(5).toString();
    const unitLongitude = unit?.Longitude.toFixed(5).toString();
    const newLongitude = longitude?.toFixed(5).toString();
    const newLatitude = latitude?.toFixed(5).toString();

    if (!(newLongitude || newLatitude)) return "no physical change";

    return newLatitude !== unitLatitude && newLongitude !== unitLongitude
      ? "location updated"
      : "no physical change";
  }, [unit?.Latitude, unit?.Longitude, longitude, latitude]);

  return (
    <WizardStepContainer>
      <ModalDescription style={{ padding: "0 64px" }}>
        {errors.root && <ErrorWell>{errors.root.message}</ErrorWell>}
        Confirm that the inspection details are correct.
      </ModalDescription>

      <Scroller>
        <UnstyledList style={{ padding: "16px 72px" }}>
          <li>
            <MediaCard
              image={
                <Location
                  grayscale={hasChanges(
                    [locationDescription],
                    [unit?.Location],
                  )}
                />
              }
              imageSize={48}
              title="Location Description"
              description={renderDiff(
                locationDescription,
                true,
                unit?.Location,
              )}
              descriptionExt={locationChanged}
            />
          </li>
          {checksCompleted.map((run) => (
            <li key={run.DeviceInspectionCheckId}>
              <MediaCard
                image={getInspectionTypeIcon(run.DeviceInspectionCheck.Type)}
                imageSize={48}
                title={run.DeviceInspectionCheck.Label}
                description={
                  run.DeviceInspectionCheck.Type === "Text"
                    ? renderDiff(run.InspectionResult, true, "")
                    : run.DeviceInspectionCheck.Description
                }
                descriptionExt={getInspectionRunResult(run)}
              />
            </li>
          ))}
        </UnstyledList>
      </Scroller>
    </WizardStepContainer>
  );
};

export default ReviewStep;
