import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";

import DS from "@design/system";

const styled_TabList = styled(TabList)`
  margin: 0;
  padding: 0;

  list-style: none;

  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
`;

const styled_Tab = styled(Tab)`
  padding: ${DS.margins.micro};
  padding-top: 0;

  cursor: pointer;
  color: ${({ theme }) => theme.palettes.body.small};
  font-size: 16px;

  background: none;
  border-bottom: solid 2px transparent;

  :hover {
    color: ${"red"};
  }

  &[aria-selected="true"] {
    color: ${({ theme }) => theme.palettes.well.title};
    font-weight: 600;

    border-bottom-color: ${({ theme }) => theme.palettes.body.accent};
  }
`;

export { styled_Tab as Tab, Tabs, styled_TabList as TabList, TabPanel };
