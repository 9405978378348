import color from "color";
import styled from "styled-components";

import DS from "@design/system";

const ContextButton = styled.button<{ shape?: "circle" | "square" }>`
  margin: 0;
  padding: ${DS.margins.nano};

  cursor: pointer;
  color: ${({ theme }) =>
    color(theme.palettes.buttons.neutral.foreground)
      .mix(color(theme.palettes.buttons.neutral.background), 0.5)
      .toString()};
  line-height: 16px;

  border: 0;
  border-radius: ${({ shape }) =>
    shape === "circle" ? "16px" : `${DS.radii.item}`};

  background: transparent;

  &:hover {
    color: ${({ theme }) =>
      color(theme.palettes.buttons.neutral.foreground)
        .mix(color(theme.palettes.buttons.neutral.background), 0.25)
        .toString()};
    background: ${({ theme }) =>
      color(theme.palettes.buttons.neutral.background)
        .mix(color(theme.palettes.buttons.neutral.foreground), 0.25)
        .toString()};
  }
`;

export default ContextButton;
