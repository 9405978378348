import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import Message from "@components/Message";
import { WizardStepContainer } from "@components/ModalWizardHelper";
import Scroller from "@components/Scroller";
import StoreTime from "@components/StoreTime";
import WizardOption, {
  WizardOptionPlaceholder,
} from "@components/WizardOption";
import { ModalDescription, UnstyledList } from "@design/helpers";
import DS from "@design/system";
import { useShipment, useUnitShipmentSummary } from "@state/hooks";
import { DateFormat } from "@util/dateFormat";
import { AedReplenished } from "@util/viewModel";

const StartStep = () => {
  const { t } = useTranslation();
  const { palettes } = useTheme();

  const { register, watch } = useFormContext<AedReplenished>();

  const [controllerSerialNumber, shipmentId] = watch([
    "ControllerSerialNumber",
    "ShipmentId",
  ]);

  const { data: shipment } = useShipment(shipmentId);
  const {
    data: lines,
    isLoading,
    isError,
  } = useUnitShipmentSummary(controllerSerialNumber, shipmentId);

  return (
    <WizardStepContainer>
      <ModalDescription style={{ padding: "0 64px" }}>
        Select the items to confirm that you have received them and replaced
        them in the {t("term.aed_one")}.
      </ModalDescription>
      <Scroller>
        <UnstyledList style={{ padding: "16px 64px" }}>
          {isError ? (
            <Message type="error">
              There was an error loading the shipment
            </Message>
          ) : isLoading ? (
            <>
              <WizardOptionPlaceholder />
              <WizardOptionPlaceholder />
            </>
          ) : (
            lines.map(({ line, old }) => (
              <li key={line.ShipmentLineID}>
                <WizardOption
                  title={line.Name}
                  description={
                    old ? (
                      <span>
                        To replace <strong>{old.ProductName}</strong> ({old.SKU}
                        ) that was due to expire{" "}
                        <StoreTime
                          storeName={shipment?.Store?.Name ?? ""}
                          dateTime={old.ExpiryDate}
                          format={DateFormat.short}
                        />
                      </span>
                    ) : (
                      <span>
                        New <strong>{line.Name}.</strong>
                      </span>
                    )
                  }
                  image={
                    <div
                      style={{
                        width: 48,
                        height: 48,

                        backgroundColor: "white",
                        backgroundImage: `url(${line.ImageUrl})`,
                        backgroundSize: 40,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",

                        border: `solid 1px ${palettes.body.border}`,
                        borderRadius: DS.radii.item,
                      }}
                    />
                  }
                  value={line.SKU}
                  {...register("confirmed")}
                />
              </li>
            ))
          )}
        </UnstyledList>
      </Scroller>
    </WizardStepContainer>
  );
};

export default StartStep;
