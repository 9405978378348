import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import AppProviders from "@components/AppProviders";

const container = document.getElementById("root");

if (!container) throw new Error("Fatal error, no root container");

const Root = () => (
  <StrictMode>
    <AppProviders />
  </StrictMode>
);

createRoot(container).render(<Root />);
