import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";

import ChangeStoreContact from "@components/ChangeStoreContact";
import DateRangeForDays from "@components/DateRangeForDays";
import { DonutValue } from "@components/Donut";
import { LabelledSelect, LabelledSwitch } from "@components/FormControls";
import GroupHeader from "@components/GroupHeader";
import GroupedList from "@components/GroupedList";
import {
  BarVerticalColumn,
  BarVerticalMetric,
  DonutMetric,
  MetricsRow,
  NumberMetric,
  ShipmentsMetric,
  EscalationsVerticalBarMetric,
} from "@components/Metric";
import MultiButton from "@components/MultiButton";
import { Page } from "@components/Page";
import Scroller from "@components/Scroller";
import DS from "@design/system";
import {
  useCurrentCompanyId,
  useCurrentUser,
  useDashboard,
  useFranchiseForStore,
  useGroupsFlat,
  useGroupsWithStoreCount,
  useHasPermission,
  useIncidentStats,
  usePageTitle,
  useStoreFull,
  useStoresReadiness,
  useUnits,
} from "@state/hooks";
import { DateFormat, dateFormatter } from "@util/dateFormat";
import {
  downloadAedReport,
  downloadAedReportCsv,
  downloadIncidentStats,
  downloadSummaryReport,
} from "@util/downloads";
import { getUnitTypes } from "@util/unit";
import useParamsUpper from "@util/useParamsUpper";
import { useTerminologies } from "@util/useTerminologies";
import { useThemeHelper } from "@util/useThemeHelper";

import AddGroupToStoreModal from "../modals/AddGroupToStoreModal";
import RemoveGroupFromStoreModal from "../modals/RemoveGroupFromStoreModal";

const displayTime = (hour: number) => {
  const from = new Date(0);
  from.setHours(hour);

  const to = new Date(0);
  to.setHours(hour + 1);

  const start = dateFormatter(from, DateFormat.timeOnly);
  const end = dateFormatter(to, DateFormat.timeOnly);

  return `${start}–${end}`;
};

const StoreOverview = () => {
  const { palettes } = useTheme();
  const { eventCategoryIcon, severityPalette } = useThemeHelper();
  const { storeId } = useParamsUpper<{ storeId: string }>();

  const [showMinorIncidents, setShowMinorIncidents] = useState(false);

  const { hasPermission } = useHasPermission();
  const { categoryLabel } = useTerminologies();
  const {
    data: store,
    isLoading: isLoadingStore,
    isError: isErrorStore,
  } = useStoreFull(storeId);
  const {
    data: groups,
    isLoading: isLoadingGroups,
    isError: isErrorGroups,
  } = useGroupsFlat();
  const { data: groupsWithStoreCount } = useGroupsWithStoreCount();
  const { data: franchise } = useFranchiseForStore(storeId);
  const { data: currentUser } = useCurrentUser();
  const { t } = useTranslation();

  const { data: units } = useUnits({ storeId });

  const companyId = useCurrentCompanyId();
  const {
    readinesses,
    isLoading: isReadinessLoading,
    isError: isReadinessError,
  } = useStoresReadiness(storeId ? [storeId] : undefined);
  const {
    data: incidentStats,
    isLoading: isIncidentStatsLoading,
    isError: isIncidentStatsError,
  } = useIncidentStats({ storeId });
  const [days, setDays] = useState<number>(7);
  usePageTitle(`Overview ${store ? " - " + store.Name : ""}`);

  const dashboard = useDashboard({
    storeId,
    numberDays: days,
    includeLowIncidents: showMinorIncidents,
  });

  const [parentGroups, setParentGroups] = useState<Api.GroupWithCount[]>([]);
  const [groupToRemove, setGroupToRemove] =
    useState<Api.GroupWithCount | null>();

  const history = useHistory();

  const handleDaysGoneChange = useCallback(() => {
    history.push(`/store/${storeId}/devices?days-since=1`);
  }, [history, storeId]);

  const handleIncidentCategoryChange = useCallback(
    (category: Api.EventCategory) => {
      history.push(
        `/store/${storeId}/incidents?category-type=${category}&days=${days}`,
      );
    },
    [days, history, storeId],
  );

  const handleIncidentSeverityChange = useCallback(
    (severity: Api.EventSeverityLevel) => {
      history.push(
        `/store/${storeId}/incidents?severity=${severity}&days=${days}`,
      );
    },
    [days, history, storeId],
  );

  const handleLowReadinessChange = useCallback(() => {
    history.push(`/store/${storeId}/devices?readiness=not-ready`);
  }, [history, storeId]);

  const handleActiveShipments = useCallback(() => {
    history.push(`/store/${storeId}/shipments?showShipments=hide`);
  }, [history, storeId]);

  const incidentsByCategory = useMemo(() => {
    return (
      [
        "Cuts",
        "Burns",
        "Eyes",
        "STF",
        "Cleaning",
        "Safety",
      ] as Api.EventCategory[]
    ).map((id) => {
      const count =
        dashboard.data?.Dashboard.IncidentsByProductCategory.find(
          (incident) => incident.key === id,
        )?.incidentCount ?? 0;

      return {
        id,
        title: `${categoryLabel(id)} (${count})`,
        count,
        icon: eventCategoryIcon(id),
      };
    });
  }, [
    eventCategoryIcon,
    categoryLabel,
    dashboard.data?.Dashboard.IncidentsByProductCategory,
  ]);

  const incidentsByTime = useMemo(() => {
    const data: BarVerticalColumn<string>[] = Array.from(new Array(24)).map(
      (_, hour) => ({
        id: `time-${hour}`,
        title: `${displayTime(hour)}`,
        count: [],
      }),
    );

    dashboard.data?.Dashboard.IncidentsByTimeOfDayLow?.forEach(
      ({ Item1: hour, Item2: count }) =>
        (data[hour as number].count as number[]).push(count),
    );
    dashboard.data?.Dashboard.IncidentsByTimeOfDayMedium?.forEach(
      ({ Item1: hour, Item2: count }) =>
        (data[hour as number].count as number[]).push(count),
    );
    dashboard.data?.Dashboard.IncidentsByTimeOfDayHigh?.forEach(
      ({ Item1: hour, Item2: count }) =>
        (data[hour as number].count as number[]).push(count),
    );

    return data;
  }, [dashboard.data]);

  const readyRating = useMemo(() => {
    const readyRating = readinesses
      ? Number(
          readinesses
            .filter(({ readiness }) => (readiness ?? -1) > -1)
            .reduce(
              (p, { readiness }, _i, { length }) =>
                p + (readiness ?? 0) / length,
              0,
            )
            .toFixed(1),
        )
      : 0;

    return readyRating === Infinity ||
      dashboard.data?.Dashboard.UnitsTotal === 0
      ? undefined
      : readyRating;
  }, [dashboard.data, readinesses]);

  const [showAddGroupToStoreModal, closeAddStoreModal] = useModal(
    () => (
      <AddGroupToStoreModal storeId={storeId} onClose={closeAddStoreModal} />
    ),
    [storeId],
  );

  const [showRemoveGroupFromStoreModal, closeRemoveGroupFromStoreModal] =
    useModal(() => {
      if (!store || !groupToRemove) return null;

      return (
        <RemoveGroupFromStoreModal
          store={store}
          group={groupToRemove}
          onConfirm={closeRemoveGroupFromStoreModal}
          onClose={closeRemoveGroupFromStoreModal}
        />
      );
    }, [store, groupToRemove]);

  const handleAddGroupToStoreClick = useCallback(() => {
    showAddGroupToStoreModal();
  }, [showAddGroupToStoreModal]);

  const handleRemoveGroupFromStore = useCallback(
    (group: Api.GroupWithCount) => {
      setGroupToRemove(group);
      showRemoveGroupFromStoreModal();
    },
    [showRemoveGroupFromStoreModal],
  );

  useEffect(() => {
    if (!groups || !store || !groupsWithStoreCount || !franchise) {
      setParentGroups([]);
      return;
    }

    setParentGroups(
      groups
        .filter((g) => g.Type === "Franchise" || g.Type === "Adhoc")
        .filter((g) => store.GroupNames.includes(`${g.Name} (${g.Type})`))
        .concat(
          [franchise].map<Api.GroupFlat>((g) => ({
            id: g.GroupId,
            GroupId: g.GroupId,
            ParentGroupId: g.ParentGroupId ?? "",
            CompanyId: g.CompanyId,
            CreatedDate: g.CreatedDate,
            ModifiedDate: g.ModifiedDate,
            Name: g.Name,
            Type: g.Type,
            IsGeographyMatched: !!g.IsGeographicallyMatched,
          })),
        )
        .map(
          (g) =>
            groupsWithStoreCount.filter(
              (gwsc) => gwsc.GroupId === g.GroupId,
            )[0],
        )
        .filter((g) => g)
        .sort((a, b) => a.Name.localeCompare(b.Name)),
    );
  }, [store, groups, groupsWithStoreCount, franchise]);

  const handleShowMinorIncidentsChanged: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((e) => {
      setShowMinorIncidents(e.target.checked);
    }, []);

  return (
    <Page showSidebar>
      <Scroller>
        <div
          style={{
            padding: DS.margins.regular,
            display: "grid",
            gap: DS.margins.regular,
          }}
        >
          <div>
            <MetricsRow>
              <EscalationsVerticalBarMetric storeId={storeId} />
              <ShipmentsMetric
                label="Active shipments"
                storeId={storeId}
                title="Show active shipments"
                onChange={handleActiveShipments}
              />
              <NumberMetric
                label={`Days since last ${t("severity.high").toLowerCase()} ${t(
                  "term.incident_one",
                ).toLowerCase()}`}
                title={`Show ${t(
                  "term.unit_other",
                ).toLowerCase()} with recent ${t(
                  "severity.high",
                ).toLowerCase()} ${t("term.incident_other").toLowerCase()}`}
                onChange={handleDaysGoneChange}
                value={incidentStats?.daysSinceLastHighSeverityIncident}
                isLoading={isIncidentStatsLoading}
                isError={isIncidentStatsError}
              />
              <NumberMetric
                label="Ready rating score"
                value={readyRating}
                title={`Show ${t(
                  "term.unit_other",
                ).toLowerCase()} with low ready rating`}
                onChange={handleLowReadinessChange}
                isLoading={isReadinessLoading}
                isError={isReadinessError}
              />
            </MetricsRow>
          </div>
          <div>
            <GroupHeader
              controls={[
                {
                  element: (
                    <LabelledSwitch
                      tKey="groupOverview.showLowIncidents"
                      onChange={handleShowMinorIncidentsChanged}
                    />
                  ),
                },
                {
                  element: (
                    <LabelledSelect
                      compact
                      label={t("groupOverview.timePeriod.label")}
                      options={[
                        {
                          label: t("groupOverview.timePeriod.values.24hours"),
                          value: "1",
                        },
                        {
                          label: t("groupOverview.timePeriod.values.7days"),
                          value: "7",
                        },
                        {
                          label: t("groupOverview.timePeriod.values.14days"),
                          value: "14",
                        },
                        {
                          label: t("groupOverview.timePeriod.values.30days"),
                          value: "30",
                        },
                        {
                          label: t("groupOverview.timePeriod.values.3months"),
                          value: "91",
                        },
                        {
                          label: t("groupOverview.timePeriod.values.6months"),
                          value: "182",
                        },
                        {
                          label: t("groupOverview.timePeriod.values.12months"),
                          value: "365",
                        },
                      ]}
                      value={days}
                      onChange={(e) => setDays(Number(e.target.value))}
                    />
                  ),
                },
              ]}
            >
              <span>
                {t("term.incident_other")} over time period{" "}
                <span style={{ fontSize: 12, fontWeight: 400 }}>
                  <DateRangeForDays numberDays={days} />
                </span>
              </span>
            </GroupHeader>
            <MetricsRow>
              <BarVerticalMetric
                label={`${t("term.incident_other")} by category`}
                value={dashboard.data?.Dashboard.TotalIncidents ?? 0}
                size="heavy"
                data={incidentsByCategory}
                onChange={(category) => handleIncidentCategoryChange(category)}
                isLoading={dashboard.isLoading}
                isError={dashboard.isError}
              />

              <DonutMetric
                label={`${t("term.incident_other")} by ${t(
                  "term.severity_one",
                ).toLowerCase()}`}
                onChange={(severity) => handleIncidentSeverityChange(severity)}
                data={
                  [
                    ...(showMinorIncidents
                      ? [
                          {
                            id: "Low",
                            title: `${t("severity.low")}`,
                            count:
                              dashboard.data?.Dashboard.IncidentsByIncidentType.find(
                                (d) => d.key === "Low",
                              )?.incidentCount ?? 0,
                            color: severityPalette("Low").background,
                          },
                        ]
                      : []),

                    {
                      id: "Medium",
                      title: `${t("severity.medium")}`,
                      count:
                        dashboard.data?.Dashboard.IncidentsByIncidentType.find(
                          (d) => d.key === "Medium",
                        )?.incidentCount ?? 0,
                      color: severityPalette("Medium").background,
                    },
                    {
                      id: "High",
                      title: `${t("severity.high")}`,
                      count:
                        dashboard.data?.Dashboard.IncidentsByIncidentType.find(
                          (d) => d.key === "High",
                        )?.incidentCount ?? 0,
                      color: severityPalette("High").background,
                    },
                  ] as DonutValue<Api.EventSeverityLevel>[]
                }
                isLoading={dashboard.isLoading}
                isError={dashboard.isError}
                showLegend
              />

              <NumberMetric
                label="Average ready rating over period"
                value={
                  dashboard.data?.Dashboard.UnitsTotal === 0
                    ? undefined
                    : dashboard.data?.Dashboard.UnitReadinessPercent
                }
                isLoading={dashboard.isLoading}
                isError={dashboard.isError}
              />

              <BarVerticalMetric
                label={`${t(
                  "term.incident_other",
                )} by time of day (12am–11:59pm)`}
                value={""}
                size="light"
                color={[
                  severityPalette("Low").background,
                  severityPalette("Medium").background,
                  severityPalette("High").background,
                ]}
                data={incidentsByTime}
                isLoading={dashboard.isLoading}
                isError={dashboard.isError}
                selected={null}
              />
            </MetricsRow>
          </div>
        </div>
      </Scroller>
      <div
        style={{
          borderLeft: `solid 1px ${palettes.body.border}`,
          display: "grid",
          overflow: "hidden",
        }}
      >
        <Scroller>
          <div
            style={{
              padding: DS.margins.regular,
              display: "grid",
              gap: DS.margins.regular,
            }}
          >
            <MultiButton
              items={[
                {
                  key: "summary-report",
                  label: "Summary report",
                  href: downloadSummaryReport({
                    companyId,
                    userId: currentUser?.id,
                    groupId: store?.SingleStoreGroupID,
                    includeLowIncidents: showMinorIncidents,
                  }),
                },
                ...(getUnitTypes(units ?? []).some(
                  (type) => type === "AutomatedExternalDefibrillator",
                )
                  ? [
                      {
                        key: "aed-report",
                        label: `${t("term.aed_one")} report`,
                        href: downloadAedReport({
                          companyId,
                          userId: currentUser?.id,
                          groupId: store?.SingleStoreGroupID,
                        }),
                      },
                      {
                        key: "aed-report-csv",
                        label: `${t("term.aed_one")} report (csv)`,
                        href: downloadAedReportCsv({ companyId, storeId }),
                      },
                    ]
                  : []),
                ...(hasPermission("events_view_customer_events")
                  ? [
                      {
                        key: "incident-stats",
                        label: `${t("term.incident_one")} statistics`,
                        href: downloadIncidentStats(store?.SingleStoreGroupID),
                      },
                    ]
                  : []),
              ]}
            >
              Report downloads
            </MultiButton>
            <div>
              <GroupHeader separator={true}>
                {t("term.store_one")} Contact
              </GroupHeader>

              <ChangeStoreContact storeId={store?.StoreId} />
            </div>

            <GroupedList
              separator
              title={`${t("term.store_one")} belongs to`}
              emptyLabel={`${t(
                "term.store_one",
              )} does not belong to any groups.`}
              controls={[
                {
                  icon: "plus",
                  title: "Add Group to Store",
                  onClick: handleAddGroupToStoreClick,
                },
              ]}
              data={parentGroups}
              isLoading={isLoadingStore || isLoadingGroups}
              isError={isErrorStore || isErrorGroups}
              listItemKey={(group) => group.GroupId}
              listItemTo={(group) => `/group/${group.GroupId}/overview`}
              listItemTitle={(group) => group.Name}
              listItemDescription={(group) =>
                t("term.store", { count: group.storeCount })
              }
              listItemContextMenu={(group) =>
                group.Type === "Adhoc"
                  ? [
                      {
                        key: "remove-group",
                        icon: "minus",
                        label: "Remove from store",
                        onClick: (close) => {
                          handleRemoveGroupFromStore(group);
                          close();
                        },
                      },
                    ]
                  : undefined
              }
            />
          </div>
        </Scroller>
      </div>
    </Page>
  );
};

export default StoreOverview;
