import { useFormContext } from "react-hook-form";

import { LabelledInput } from "@components/FormControls";
import {
  WizardControlsContainer,
  WizardStepContainer,
} from "@components/ModalWizardHelper";
import { ModalDescription } from "@design/helpers";
import { ToolReplacementForm } from "@util/viewModel";

const SpecifyReasonStep = () => {
  const { register } = useFormContext<ToolReplacementForm>();

  return (
    <WizardStepContainer>
      <ModalDescription>
        Specify the reason that a replacement is not needed.
      </ModalDescription>

      <WizardControlsContainer>
        <LabelledInput label="Reason" {...register("reason")} />
      </WizardControlsContainer>
    </WizardStepContainer>
  );
};

export default SpecifyReasonStep;
