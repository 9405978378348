import { useCombobox } from "downshift";
import { FzfResultItem } from "fzf";
import { useEffect, useRef } from "react";
import { useLayer } from "react-laag";
import { useTheme } from "styled-components";
import useResizeObserver from "use-resize-observer";

import { UnstyledList } from "@design/helpers";
import DS from "@design/system";
import { useSearch } from "@state/hooks";

import {
  Container as InputContainer,
  Control as InputControl,
  LabelledInput,
} from "./FormControls";
import Linkable from "./Linkable";
import NoResults from "./NoResults";
import { useTooltip } from "./Tooltip";

export interface ItemSelectorProps<T> {
  label?: string;
  value?: T;
  items?: T[];
  placeholder?: string;
  loading?: boolean;
  getKey: (item: T) => string;
  searchSelector: (item: T) => string;
  filterItems?: (inputValue?: string) => T[];
  itemRenderer: (
    item: T,
    matchIndices?: Set<number> | null,
    isActive?: boolean | null,
  ) => React.ReactNode;
  valueRenderer?: (item: T) => React.ReactNode;
  itemDisabled?: (item: T) => boolean;
  loadingRenderer?: () => React.ReactNode;
  onItemSelect?: (item: T) => void;
}

const ItemSelector = <T extends unknown>({
  label,
  value,
  valueRenderer,
  items,
  placeholder = "Search…",
  loading = false,
  getKey,
  searchSelector,
  itemRenderer,
  itemDisabled,
  loadingRenderer,
  onItemSelect,
}: ItemSelectorProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { ref, height } = useResizeObserver();
  const { results, search, setList, q } = useSearch(items, searchSelector);

  const { palettes } = useTheme();

  const { tooltipProps } = useTooltip<HTMLDivElement>(label);

  useEffect(() => {
    setList(items);
  }, [items, setList]);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox<FzfResultItem<T>>({
    items: results ?? [],
    itemToString: () => "",
    onInputValueChange: ({ inputValue }) => search(inputValue ?? ""),
    onSelectedItemChange: ({ selectedItem }) =>
      onItemSelect &&
      selectedItem &&
      (!itemDisabled || !itemDisabled(selectedItem.item)) &&
      onItemSelect(selectedItem.item),
  });

  const { layerProps, triggerProps, triggerBounds, renderLayer } = useLayer({
    isOpen,
    placement: "bottom-start",
    triggerOffset: (height ?? 0) * -1 - 8,
  });

  useEffect(() => {
    if (isOpen) inputRef.current?.focus();
  }, [isOpen]);

  return (
    <div>
      <Linkable
        ref={ref}
        aria-label={label}
        {...getToggleButtonProps(triggerProps)}
      >
        <div {...tooltipProps}>
          {value ? (
            valueRenderer ? (
              valueRenderer(value)
            ) : (
              itemRenderer(value)
            )
          ) : (
            <InputContainer withLabel={false} hasError={false}>
              <InputControl
                style={{
                  fontStyle: "italic",
                  color: palettes.body.small,
                }}
                withLabel={false}
                hasError={false}
              >
                <div style={{ marginTop: -1 }}>{placeholder}</div>
              </InputControl>
            </InputContainer>
          )}
        </div>
        {loading && loadingRenderer && (
          <div
            style={{
              position: "absolute",
              top: 1,
              left: -8,
              width: "calc(100% + 16px)",
              borderRadius: DS.radii.item,
              background: `${palettes.well.dim}EE`,
            }}
          >
            {loadingRenderer()}
          </div>
        )}
      </Linkable>

      {renderLayer(
        <div
          {...layerProps}
          style={{
            ...layerProps.style,
            left: Number(layerProps.style.left) - 8,
            display: isOpen ? "block" : "none",
          }}
        >
          <div
            id="itemselector-items"
            style={{
              overflow: "hidden",

              width: (triggerBounds?.width ?? 0) + 16,

              borderRadius: DS.radii.largeItem,
              background: palettes.body.background,
              boxShadow: DS.shadows.panel,
            }}
          >
            <div
              style={{
                padding: DS.margins.micro,
                paddingBottom: DS.margins.micro,
              }}
            >
              <LabelledInput
                placeholder={placeholder}
                autoComplete="off"
                spellCheck="false"
                autoCorrect="off"
                {...getInputProps({ ref: inputRef })}
              />
            </div>

            <div
              style={{
                boxSizing: "border-box",
                overflow: "hidden auto",
                background: palettes.body.background,
              }}
            >
              {value && (
                <div
                  style={{
                    padding: DS.margins.microCss("rl"),
                    borderBottom: "solid 1px",
                    borderColor: palettes.body.border,
                  }}
                >
                  {itemRenderer(value)}
                </div>
              )}

              <div
                {...getMenuProps()}
                style={{
                  boxSizing: "border-box",
                  overflow: "hidden auto",
                  width: "100%",
                  maxHeight: 200,
                  padding: DS.margins.microCss("rbl"),
                }}
              >
                <UnstyledList gutter={0}>
                  {results && results.length > 0 ? (
                    results.map((item, index) => (
                      <li
                        key={getKey(item.item)}
                        id={getKey(item.item)}
                        {...getItemProps({ item, index })}
                      >
                        {itemRenderer(
                          item.item,
                          item.positions,
                          highlightedIndex === index,
                        )}
                      </li>
                    ))
                  ) : (
                    <NoResults>
                      No results found for <strong>{q}</strong>.
                    </NoResults>
                  )}
                </UnstyledList>
              </div>
            </div>
          </div>
        </div>,
      )}
    </div>
  );
};

export default ItemSelector;
