import { useFormContext } from "react-hook-form";

const ReviewTab = () => {
  const { watch } = useFormContext<Api.User>();

  const watchFullName = watch("FullName");
  const watchGroups = watch("Groups");

  return (
    <div style={{ wordBreak: "break-word" }}>
      <strong>{watchFullName}</strong> will have access to the following groups
      and stores:
      <br />
      <ul>
        {watchGroups?.map((group) => <li key={group.GroupId}>{group.Name}</li>)}
      </ul>
    </div>
  );
};

export default ReviewTab;
