import { AnimatePresence, useReducedMotion } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import { isApiResponseError } from "@api";
import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import {
  Overlay,
  Path,
  Step,
  WizardProvider,
  useWizard,
} from "@components/ModalWizardHelper";
import ModalWizardLayout from "@components/ModalWizardLayout";
import WizardSteps from "@components/WizardSteps";
import MfaQRCode from "@components/people/MfaQRCode";
import StartStepMfa from "@components/people/StartStepMfa";
import StartStepMfaFooter from "@components/people/StartStepMfaFooter";
import SubmitMfaFooter from "@components/people/SubmitMfaFooter";
import SuccessMfaFooter from "@components/people/SuccessMfaFooter";
import SuccessMfaModal from "@components/people/SuccessMfaModal";
import {
  GroupAndOrStoreKey,
  useMfaSecret,
  useMutateUser,
  useUserFull,
} from "@state/hooks";
import { BackForwardCard, Direction } from "@util/animations";

const ConfirmMfaModal = ({
  userId,
  mutationKeys,
  onClose,
}: {
  userId: string;
  mutationKeys?: GroupAndOrStoreKey;
  onClose: () => void;
}) => {
  const shouldReduceMotion = useReducedMotion();

  const { data: user } = useUserFull(userId);
  const { data: mfaSecret } = useMfaSecret(userId);
  const { mutate: updateUser } = useMutateUser(mutationKeys);

  const [attemptCancel, setAttemptCancel] = useState(false);

  const [direction, setDirection] = useState<Direction>("forward");

  const {
    formState,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
    ...methods
  } = useForm<Api.User>({
    defaultValues: {
      AuthenticateChangeRequest: {
        HasMfaEnabled: true,
        MfaValidationMethod: "authenticator",
      },
    },
  });

  const path: Path = useMemo(() => ["setup", "enable", "enter"], []);

  const handleCloseAttempt = useCallback(() => setAttemptCancel(true), []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSubmit: React.FormEventHandler = useCallback(
    (e) =>
      void handleSubmit(async (data) => {
        const updatedUser: Api.User = {
          ...data,
          AuthenticateChangeRequest: {
            ...data.AuthenticateChangeRequest,
            SuppliedTOTPSecret: mfaSecret!,
            MfaValidationMethod: "authenticator",
            HasMfaEnabled: true,
          },
        };

        return new Promise<Api.ResponseSuccess<Api.User>>((resolve, reject) =>
          updateUser(updatedUser, { onSuccess: resolve, onError: reject }),
        )
          .then((response) => response.SuccessPayload)
          .then((updatedUser) => {
            if (!updatedUser) return;
            onClose();
            toast.success(
              <span>Two-Factor Authentication successfully enabled</span>,
            );
          })

          .catch((error) => {
            if (isApiResponseError<Api.UserValidationError>(error)) {
              setError("root", {
                message:
                  "You have entered an incorrect code. Please check the code matches the one you received.",
              });
            }
          });
      })(e),

    [handleSubmit, mfaSecret, onClose, setError, updateUser],
  );

  useEffect(() => reset(user), [user, reset]);

  const code = watch("AuthenticateChangeRequest.SuppliedTOTPCode");

  const steps: Step[] = useMemo(
    () => [
      {
        name: "setup",
        title: "Enable Two-Factor Authentication",
        Content: <StartStepMfa />,
        Footer: <StartStepMfaFooter onCancel={() => setAttemptCancel(true)} />,
      },

      {
        name: "enable",
        title: "Verify Device",
        Content: <MfaQRCode userId={userId} />,
        Footer: <SubmitMfaFooter />,
      },

      {
        name: "enter",
        title: "Enter Verification Code",
        Content: <SuccessMfaModal />,
        Footer: <SuccessMfaFooter code={code} />,
      },
    ],
    [code, userId],
  );

  const { step, count, previous, ...rest } = useWizard(path, {
    onStepChange: (_stepName, direction) => setDirection(direction),
  });

  const currentStep = useMemo(
    () => steps.find((s) => s.name === step.name) ?? steps[0],
    [step, steps],
  );

  if (!step || !direction) return null;

  return (
    <Modal contentLabel="Setup Authenticator App" onClose={handleCloseAttempt}>
      <FormProvider
        {...{
          formState,
          handleSubmit,
          register,
          reset,
          setError,
          setValue,
          watch,

          ...methods,
        }}
      >
        <WizardProvider {...{ step, count, direction, previous, ...rest }}>
          <form onSubmit={onSubmit}>
            <ModalWizardLayout
              header={
                <div style={{ position: "relative" }}>
                  <ModalHeader
                    title={currentStep.title}
                    backLabel={currentStep.backLabel}
                    onBack={previous}
                    onClose={handleCloseAttempt}
                  />

                  <Overlay show={attemptCancel} />
                </div>
              }
              content={
                <div style={{ position: "relative" }}>
                  <AnimatePresence
                    initial={false}
                    custom={{ direction, reduceMotion: shouldReduceMotion }}
                  >
                    {user && (
                      <BackForwardCard
                        key={currentStep.name}
                        custom={{ direction, reduceMotion: shouldReduceMotion }}
                      >
                        {currentStep.Content}
                      </BackForwardCard>
                    )}
                  </AnimatePresence>
                  <Overlay show={attemptCancel} />
                </div>
              }
              footer={
                <ModalFooter>
                  {attemptCancel ? (
                    <>
                      <p>
                        You have not completed the two-factor authentication set
                        up. If you leave now,{" "}
                        <strong>2FA will remain off</strong>.
                      </p>

                      <p>Are you sure you want to leave now?</p>

                      <ButtonGroup flow="horizontal">
                        <Button onClick={() => setAttemptCancel(false)}>
                          No, I&apos;m not finished.
                        </Button>
                        <Button buttonType="destructive" onClick={handleClose}>
                          Yes, leave now.
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      {currentStep.Footer}
                      <WizardSteps steps={count} current={step.number} />
                    </>
                  )}
                </ModalFooter>
              }
            />
          </form>
        </WizardProvider>
      </FormProvider>
    </Modal>
  );
};

export default ConfirmMfaModal;
