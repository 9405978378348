import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import Icon from "@components/Icon";
import { useWizardContext } from "@components/ModalWizardHelper";
import { ToolReplacementForm } from "@util/viewModel";

const SelectReasonStepFooter = () => {
  const { goTo, previous } = useWizardContext();
  const { formState, watch } = useFormContext<ToolReplacementForm>();

  const reasons = watch("selectedReasons");

  const buttonLabel = useMemo(
    () =>
      !reasons || reasons.length === 0
        ? "Next"
        : reasons.includes("other")
          ? "Next, specify reason"
          : "Next, review",
    [reasons],
  );

  const handleNextClick = useCallback(
    () =>
      reasons.includes("other")
        ? goTo("specifyReason", true)
        : goTo("declineWithoutReason", true),
    [goTo, reasons],
  );

  return (
    <ButtonGroup flow="horizontal">
      <Button disabled={formState.isSubmitting} onClick={previous}>
        <Icon name="arrow-alt-left" /> Back, to selection
      </Button>
      <Button
        buttonType="progressive"
        disabled={!reasons || reasons.length === 0 || formState.isSubmitting}
        onClick={handleNextClick}
      >
        {buttonLabel} <Icon name="arrow-alt-right" />
      </Button>
    </ButtonGroup>
  );
};

export default SelectReasonStepFooter;
