import { useErrorBoundary } from "react-error-boundary";
import { useTheme } from "styled-components";

import ErrorPageImage from "@components/ErrorPage";
import { LargeBrandTitle, StrongText } from "@design/helpers";
import DS from "@design/system";

import Button from "./Button";
import ErrorWell from "./ErrorWell";

type ErrorDisplayProps = {
  error: Error;
};

const Fallback = ({ error }: ErrorDisplayProps) => {
  const { resetBoundary } = useErrorBoundary();
  const { palettes } = useTheme();

  const isLocalDev = import.meta.env.VITE_APP_ENV_NAME === "localdev";

  return (
    <div
      style={{
        height: "100vh",

        display: "grid",
        placeContent: "center",
      }}
    >
      <div
        style={{
          maxWidth: 1024,
          padding: 60,

          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
          gap: 60,

          border: `solid 1px ${palettes.body.border}`,
          borderRadius: DS.radii.floating,
        }}
      >
        <div
          style={{
            display: "grid",
            textAlign: "center",
            gap: DS.margins.regular,
          }}
        >
          <LargeBrandTitle>Uh Oh! An error has occurred!</LargeBrandTitle>

          {isLocalDev ? (
            <>
              <ErrorWell
                style={{ maxHeight: 250, textAlign: "start", overflow: "auto" }}
              >
                <code>
                  <pre style={{ margin: 0, lineHeight: 1.4 }}>
                    {error.name}: {error.message}
                    <br />
                    <br />
                    {error.stack}
                  </pre>
                </code>
              </ErrorWell>
            </>
          ) : (
            <StrongText>Something went wrong, please try again</StrongText>
          )}

          <Button
            buttonType="action"
            onClick={resetBoundary}
            style={{ justifySelf: "center" }}
          >
            Go back
          </Button>
        </div>

        <ErrorPageImage width={300} />
      </div>
    </div>
  );
};

export default Fallback;
