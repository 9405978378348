import { useFormContext } from "react-hook-form";

import { LabelledPasswordInput } from "@components/FormControls";
import Message from "@components/Message";
import {
  WizardControlsContainer,
  WizardStepContainer,
} from "@components/ModalWizardHelper";
import { ModalDescription } from "@design/helpers";

const DisableMfa = () => {
  const { register, formState } = useFormContext<Api.User>();

  return (
    <WizardStepContainer>
      <div style={{ padding: "0 64px" }}>
        <Message type="notice">
          <strong>Two-factor authentication</strong> adds an additional layer of
          security by requiring more than just a password to sign in. It is
          recommend that you keep 2FA enabled.
        </Message>
      </div>

      <WizardControlsContainer>
        <ModalDescription>Enter your password to disable 2FA.</ModalDescription>

        <LabelledPasswordInput
          label="Current password"
          hasError={
            !!formState.errors.AuthenticateChangeRequest?.CurrentPassword
          }
          error={
            formState.errors.AuthenticateChangeRequest?.CurrentPassword?.message
          }
          {...register("AuthenticateChangeRequest.CurrentPassword")}
        />
      </WizardControlsContainer>
    </WizardStepContainer>
  );
};

export default DisableMfa;
