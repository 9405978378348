import GraphemeSplitter from "grapheme-splitter";

const splitter = new GraphemeSplitter();

export function lowercase(value: string) {
  const chars = splitter.splitGraphemes(value);

  return chars.reduce((p, v, i, arr) => {
    const next = arr[i + 1];
    const previous = arr[i - 1];

    return v === v.toUpperCase() &&
      ((next && next !== " " && next === next.toUpperCase()) ||
        (previous && previous !== " " && previous === previous.toUpperCase()))
      ? `${p}${v}`
      : `${p}${v.toLowerCase()}`;
  }, "");
}
