import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import styled, { useTheme } from "styled-components";

import Icon from "@components/Icon";
import {
  WizardStepContainer,
  useWizardContext,
} from "@components/ModalWizardHelper";
import { ModalDescription } from "@design/helpers";
import DS from "@design/system";
import { AedReplenished } from "@util/viewModel";

const DropBox = styled.div<{ active: boolean }>`
  cursor: pointer;
  color: ${({ theme, active }) =>
    active
      ? theme.palettes.buttons.neutralSelected.foreground
      : theme.palettes.well.foreground};

  border: dashed 2px;
  border-color: ${({ theme, active }) =>
    active
      ? theme.palettes.buttons.neutralSelected.foreground
      : theme.palettes.well.dim};
  border-radius: ${DS.radii.largeItem};

  background: ${({ theme, active }) =>
    active
      ? theme.palettes.buttons.neutralSelected.background
      : theme.palettes.well.background};

  transition: all ease-in-out 200ms;
`;

const UploadPhotoStep = () => {
  const { palettes } = useTheme();

  const { setValue } = useFormContext<AedReplenished>();
  const { next } = useWizardContext();

  const onDrop = useCallback(
    ([file]: File[]) => {
      if (!file) return;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const raw = reader.result as string;

        const base64Header = new RegExp(/(.*),(.*)/);
        const [, , data] = base64Header.exec(raw) as string[];

        setValue("PhotoBase64", data);
        setValue("photoPreview", raw);

        next();
      };
    },
    [next, setValue],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    maxFiles: 1,
    multiple: false,
    onDrop,
  });

  return (
    <WizardStepContainer>
      <ModalDescription style={{ padding: "0 64px" }}>
        Optionally, take a photo of the AED to show items has been replaced
        correctly.
      </ModalDescription>

      <DropBox
        {...getRootProps()}
        active={isDragActive}
        style={{
          margin: "8px 64px",

          display: "grid",
          gap: DS.margins.micro,
          alignContent: "center",
          justifyItems: "center",
        }}
      >
        <input {...getInputProps()} />
        <Icon
          name="cloud-upload"
          color={
            isDragActive
              ? palettes.buttons.neutralSelected.foreground
              : palettes.well.small
          }
          size={48}
        />
        {isDragActive ? (
          <span>Drop image to upload</span>
        ) : (
          <span>
            Drag and drop, or <strong>choose a file</strong> to upload
          </span>
        )}
      </DropBox>
    </WizardStepContainer>
  );
};

export default UploadPhotoStep;
