import styled, { keyframes } from "styled-components";

import icons, { IIcon } from "../icons";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.span<{
  $color?: string;
  $spin: boolean;
}>`
  display: inline-block;
  position: relative;

  width: 1em;
  height: 1em;

  & > svg {
    display: block;

    ${({ $color: color }) => (color ? `color: ${color};` : "")}
  }

  animation: ${({ $spin: spin }) => (spin ? rotate : "")} 2s linear infinite;
`;

const Icon = ({
  name,
  color,
  size = "inherit",
  spin = false,
}: {
  name: IIcon;
  color?: string;
  size?: string | number;
  spin?: boolean;
}) => {
  const icon = icons[name];

  if (!icon) throw new Error(`Icon ${name} does not exist`);

  const Component = icon.Icon;

  return (
    <Container $color={color} $spin={spin} style={{ fontSize: size }}>
      <Component />
    </Container>
  );
};

export type IconName = IIcon;
export default Icon;
