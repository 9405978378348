import styled from "styled-components";

import DS from "@design/system";

const ButtonBar = styled.div<{ vertical?: boolean }>`
  border-radius: ${DS.radii.item};
  background: ${({ theme }) => theme.palettes.buttons.neutral.background};
  box-shadow: ${DS.shadows.panel};

  display: grid;
  grid-auto-flow: ${({ vertical }) => (vertical ? "row" : "column")};

  button {
    border: 0;
    border-radius: 0;
  }

  button:not(:last-child) {
    ${({ theme, vertical }) =>
      vertical
        ? `border-bottom: solid 1px ${theme.palettes.buttons.neutral.border};`
        : `border-right: solid 1px ${theme.palettes.buttons.neutral.border};`}
  }

  button:first-child {
    ${({ vertical }) =>
      vertical
        ? `
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    `
        : `
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    `}
  }

  button:last-child {
    ${({ vertical }) =>
      vertical
        ? `
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    `
        : `
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    `}
  }
`;

export default ButtonBar;
