import { AnimatePresence, useReducedMotion } from "framer-motion";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { isApiResponseError } from "@api";
import Button from "@components/Button";
import ButtonGroup from "@components/ButtonGroup";
import ErrorWell from "@components/ErrorWell";
import Icon from "@components/Icon";
import Modal from "@components/Modal";
import ModalFooter from "@components/ModalFooter";
import ModalHeader from "@components/ModalHeader";
import {
  Overlay,
  Path,
  Step,
  WizardProvider,
  useWizard,
} from "@components/ModalWizardHelper";
import ModalWizardLayout from "@components/ModalWizardLayout";
import WizardSteps from "@components/WizardSteps";
import CheckStep from "@components/units/inspection/CheckStep";
import CheckStepFooter from "@components/units/inspection/CheckStepFooter";
import LocationStep from "@components/units/inspection/LocationStep";
import LocationStepFooter from "@components/units/inspection/LocationStepFooter";
import ReviewStep from "@components/units/inspection/ReviewStep";
import ReviewStepFooter from "@components/units/inspection/ReviewStepFooter";
import DS from "@design/system";
import { useUnitFull, useUnitInspectionCompleted } from "@state/hooks";
import { BackForwardCard, Direction } from "@util/animations";
import { AedInspection } from "@util/viewModel";

const ConfirmAedInspectionWizardModal = ({
  unitReminderId,
  controllerSerialNumber,
  onClose,
}: {
  unitReminderId?: string;
  controllerSerialNumber: string;
  onClose: () => void;
}) => {
  const shouldReduceMotion = useReducedMotion();
  const { t } = useTranslation();
  const { palettes } = useTheme();

  const { data: unit, isError } = useUnitFull(controllerSerialNumber);
  const [attemptCancel, setAttemptCancel] = useState(false);

  const values = useMemo<AedInspection>(
    () => ({
      ControllerSerialNumber: controllerSerialNumber,
      NewLatitude: unit?.Latitude,
      NewLongitude: unit?.Longitude,

      NewLocation: unit?.Location,
      ChecksCompleted: unit?.DeviceInspectionRuns.filter((r) => r.IsDue) ?? [],
    }),
    [
      controllerSerialNumber,
      unit?.DeviceInspectionRuns,
      unit?.Latitude,
      unit?.Location,
      unit?.Longitude,
    ],
  );

  const { handleSubmit, reset, setError, watch, ...methods } =
    useForm<AedInspection>({
      mode: "onChange",
      defaultValues: {
        NewAedPutIn: false,
        NewLatitude: unit?.Latitude,
        NewLongitude: unit?.Longitude,
        ControllerSerialNumber: controllerSerialNumber,
        InspectionGood: false,

        ChecksCompleted: [],
      },
      values,
    });

  const path: Path = useMemo(
    () => [
      "location",
      ...((unit &&
        unit.DeviceInspectionRuns.filter((r) => r.IsDue).map(
          (_c, i) => `check-${i}`,
        )) ??
        []),
      "review",
    ],
    [unit],
  );

  const steps: Step[] = useMemo(
    () => [
      {
        name: "location",
        title: "Confirm Location",
        Content: <LocationStep />,
        Footer: <LocationStepFooter onCancel={() => setAttemptCancel(true)} />,
      },
      ...((unit &&
        unit.DeviceInspectionRuns.filter((r) => r.IsDue).map((r, i) => ({
          name: `check-${i}`,
          title: r.DeviceInspectionCheck.Label,
          Content: <CheckStep index={i} />,
          Footer: <CheckStepFooter index={i} />,
        }))) ??
        []),
      {
        name: "review",
        title: "Confirm Inspection",
        Content: <ReviewStep />,
        Footer: <ReviewStepFooter />,
      },
    ],
    [unit],
  );

  const [direction, setDirection] = useState<Direction>("forward");

  const { step, count, previous, ...rest } = useWizard(path, {
    onStepChange: (_stepName, direction) => setDirection(direction),
  });

  const currentStep = useMemo(
    () => steps.find((s) => s.name === step.name) ?? steps[0],
    [step, steps],
  );

  const { mutate: submitInspectionResult } =
    useUnitInspectionCompleted(unitReminderId);

  const handleCloseAttempt = useCallback(() => setAttemptCancel(true), []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleBack = useCallback(() => previous(), [previous]);

  const onSubmit = useCallback(
    (formEvent?: React.FormEvent) =>
      void handleSubmit(async (data) => {
        return new Promise((resolve, reject) =>
          submitInspectionResult(
            {
              ...data,

              ControllerSerialNumber: controllerSerialNumber,
              InspectionGood: false,
            },
            { onSuccess: resolve, onError: reject },
          ),
        )
          .then(() => {
            onClose();
            toast.success(
              <span>{t("term.aed_one")} inspection submitted</span>,
            );
          })
          .catch((error) => {
            if (isApiResponseError<Api.UnitValidationError>(error)) {
              if (error.error.hasError("generic_error")) {
                setError("root", {
                  message: `You do not have permission to update this ${t(
                    "term.aed_one",
                  )}.`,
                });
              } else {
                setError("root", {
                  message:
                    "We encountered an issue on our side. Please try again soon.",
                });
              }
            } else {
              setError("root", {
                message:
                  "We encountered an issue on our side. Please try again soon.",
              });
            }
          });
      })(formEvent),
    [
      controllerSerialNumber,
      handleSubmit,
      onClose,
      setError,
      submitInspectionResult,
      t,
    ],
  );

  if (!step || !direction) return null;

  return (
    <Modal contentLabel="Confirm Inspection" onClose={handleCloseAttempt}>
      <FormProvider
        {...{
          handleSubmit,
          reset,
          setError,
          watch,
          ...methods,
        }}
      >
        <WizardProvider {...{ step, count, direction, previous, ...rest }}>
          <form onSubmit={onSubmit}>
            <ModalWizardLayout
              header={
                <div style={{ position: "relative" }}>
                  <ModalHeader
                    title={currentStep.title}
                    backLabel={currentStep.backLabel}
                    onBack={handleBack}
                    onClose={handleCloseAttempt}
                  />

                  <Overlay show={attemptCancel} />
                </div>
              }
              content={
                <div style={{ position: "relative" }}>
                  {isError ? (
                    <div style={{ padding: DS.margins.regular }}>
                      <ErrorWell style={{ textAlign: "center" }}>
                        <Icon
                          name="exclamation-alt-circle"
                          color={palettes.states.bad.background}
                        />{" "}
                        There was a problem loading inspection data.
                      </ErrorWell>
                    </div>
                  ) : (
                    <AnimatePresence
                      initial={false}
                      custom={{ direction, reduceMotion: shouldReduceMotion }}
                    >
                      <BackForwardCard
                        key={currentStep.name}
                        custom={{
                          direction,
                          reduceMotion: shouldReduceMotion,
                        }}
                      >
                        {currentStep.Content}
                      </BackForwardCard>
                    </AnimatePresence>
                  )}

                  <Overlay show={attemptCancel} />
                </div>
              }
              footer={
                <ModalFooter>
                  {attemptCancel ? (
                    <>
                      <p>
                        You have not completed updating your {t("term.aed_one")}{" "}
                        details. If you leave now{" "}
                        <strong>
                          the inspection will <em>not</em> be marked as
                          completed
                        </strong>
                        .
                      </p>
                      <p>Are you sure you want to leave now?</p>
                      <ButtonGroup flow="horizontal">
                        <Button onClick={() => setAttemptCancel(false)}>
                          No, I&apos;m not finished.
                        </Button>
                        <Button buttonType="destructive" onClick={handleClose}>
                          Yes, leave now.
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      {currentStep.Footer}
                      <WizardSteps steps={count} current={step.number} />
                    </>
                  )}
                </ModalFooter>
              }
            />
          </form>
        </WizardProvider>
      </FormProvider>
    </Modal>
  );
};

export default ConfirmAedInspectionWizardModal;
