import { useModal } from "react-modal-hook";

import Button from "@components/Button";
import ConfirmAedInspectionWizardModal from "@modals/ConfirmAedInspectionWizardModal";
import { useSidePanels } from "@util/SidePanels";
import { EscalationProps } from "@util/viewModel";

const AedInspectionFooter = ({
  escalation,
}: EscalationProps<Api.AedInspectionEscalation>) => {
  const { pop } = useSidePanels();

  const [showConfirmInspectionModal, closeConfirmInspectionModal] = useModal(
    () => (
      <ConfirmAedInspectionWizardModal
        unitReminderId={escalation.UnitReminderId}
        controllerSerialNumber={escalation.ControllerSerialNumber}
        onClose={() => {
          pop();
          closeConfirmInspectionModal();
        }}
      />
    ),
    [escalation, pop],
  );

  return (
    <Button
      buttonType="action"
      onClick={() => {
        showConfirmInspectionModal();
      }}
    >
      Begin inspection…
    </Button>
  );
};

export default AedInspectionFooter;
