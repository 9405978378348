import { useMemo } from "react";
import { useTheme } from "styled-components";

import GroupHeader from "@components/GroupHeader";
import Icon from "@components/Icon";
import MediaCard, { MediaCardPlaceholder } from "@components/MediaCard";
import StoreTime from "@components/StoreTime";
import { Aed } from "@components/WizardIcons";
import { IconWell, MessageText, UnstyledList } from "@design/helpers";
import DS from "@design/system";
import { useUnitFull, useUnitReplenishmentSummary } from "@state/hooks";
import { removeDuplicates } from "@util/data";
import { DateFormat } from "@util/dateFormat";
import { useThemeHelper } from "@util/useThemeHelper";
import { EscalationProps } from "@util/viewModel";

const AedPartsReplacement = ({
  escalation,
}: EscalationProps<Api.AedPartsReplacementEscalationConfirmation>) => {
  const { palettes } = useTheme();
  const { messagesPalette } = useThemeHelper();

  const { data: unit } = useUnitFull(escalation.ControllerSerialNumber);

  const { data: replenishment, isLoading } = useUnitReplenishmentSummary(
    escalation.ControllerSerialNumber,
  );

  const itemsToReplace = useMemo(
    () =>
      replenishment
        ? removeDuplicates(
            [
              ...replenishment.CloseToExpiryProductsInKit,
              ...replenishment.ExpiredStockStillInKit,
              ...replenishment.ExpiredStockNeedingReplacement,
            ],
            (stock) => stock.UID,
          )
        : [],
    [replenishment],
  );

  return (
    <div
      style={{
        overflow: "hidden",
        padding: `${DS.margins.micro} ${DS.margins.regular}`,
        display: "grid",
        gap: DS.margins.regular,
      }}
    >
      <IconWell style={messagesPalette("info").toCss()}>
        <Icon name="exclamation-alt-circle" />
        <MessageText>
          One or more items in this AED are due to expire soon. Please inspect
          the AED and confirm that replacement parts are needed.
        </MessageText>
      </IconWell>

      {!unit ? (
        <MediaCardPlaceholder showDescriptionExt />
      ) : (
        <MediaCard
          image={<Aed />}
          title={unit.UnitName}
          description={unit.Description}
          descriptionExt={unit.Model}
        />
      )}

      <div>
        <GroupHeader>Items to Replace</GroupHeader>

        <UnstyledList>
          {isLoading ? (
            <>
              <MediaCardPlaceholder />
              <MediaCardPlaceholder />
            </>
          ) : (
            itemsToReplace.map((stock) => (
              <li key={stock.UID}>
                <MediaCard
                  title={stock.ProductName}
                  description={
                    <span>
                      Expires{" "}
                      <StoreTime
                        storeName={unit?.StoreName ?? ""}
                        dateTime={stock.ExpiryDate}
                        format={DateFormat.short}
                      />
                    </span>
                  }
                  image={
                    <div
                      style={{
                        width: 48,
                        height: 48,

                        backgroundColor: "white",
                        backgroundImage: `url(${stock.ProductImageUrl})`,
                        backgroundSize: 40,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",

                        border: `solid 1px ${palettes.body.border}`,
                        borderRadius: DS.radii.item,
                      }}
                    />
                  }
                />
              </li>
            ))
          )}
        </UnstyledList>
      </div>
    </div>
  );
};

export default AedPartsReplacement;
