import { ColumnDef } from "@tanstack/react-table";
import { FzfResultItem } from "fzf";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { LabelledSelect } from "@components/FormControls";
import GroupHeader, { GroupHeaderPlaceholder } from "@components/GroupHeader";
import HighlightChars from "@components/HighlightChars";
import IconButton from "@components/IconButton";
import { IconWithToolTip } from "@components/IconWithToolTip";
import ListItem, {
  ListItemPlaceholder,
  ListItemsPlaceholder,
} from "@components/ListItem";
import LocalisedDate from "@components/LocalisedDate";
import { Page } from "@components/Page";
import QuantityContainer from "@components/QuantityContainer";
import Scroller from "@components/Scroller";
import SearchBar from "@components/SearchBar";
import SecondaryButton from "@components/SecondaryButton";
import { getStatusLabel } from "@components/ShipmentCard";
import SidePanelHeader from "@components/SidePanelHeader";
import { Table } from "@components/Table";
import { TextWithToolTip } from "@components/TextWithToolTip";
import {
  LargeDataNotice,
  SmallText,
  UnstyledList,
  Well,
} from "@design/helpers";
import DS from "@design/system";
import {
  useShipments,
  useStore,
  useGroup,
  usePageTitle,
  useCompany,
  useSearch,
  useUnitReplenishmentSummary,
  useUnitFull,
} from "@state/hooks";
import { DateFormat } from "@util/dateFormat";
import {
  getShipmentStatusIcon,
  useShipmentsFilter,
  sort_shipments,
  Sorting,
  Filtering,
  filter_shipments,
} from "@util/shipments";
import useParamsUpper from "@util/useParamsUpper";
import { useThemeHelper } from "@util/useThemeHelper";

const ShipmentItemsList = styled.li`
  display: grid;
  padding: ${DS.margins.microCss("rl")};
  grid-template-columns: 1fr auto auto;
  gap: ${DS.margins.regular};
  align-items: center;
  justify-content: space-between;
`;

const getTitle = (shipment: Api.Shipment) => `${shipment.TrackingNumber}`;

const Shipments = () => {
  const { t } = useTranslation();
  const { features, palettes } = useTheme();
  const { shipmentStatusPalette } = useThemeHelper();
  const allStoresMatch = useRouteMatch(["/all-stores"]);
  const history = useHistory();
  const { storeId, groupId } = useParamsUpper<{
    storeId?: string;
    groupId?: string;
  }>();

  const [selectedShipment, setSelectedShipment] = useState<Api.Shipment | null>(
    null,
  );

  const { data: store } = useStore(storeId);
  const { data: group } = useGroup(groupId);
  const { data: company } = useCompany();
  const { data: unit } = useUnitFull(selectedShipment?.ControllerSerialNumber);
  const { data: replenishment, isLoading: isReplenishmentsSummaryLoading } =
    useUnitReplenishmentSummary(selectedShipment?.ControllerSerialNumber);

  const { data: shipments, isLoading: isShipmentsLoading } = useShipments({
    storeId,
    groupId,
    allStores: !!allStoresMatch,
    numberDays: 90,
  });

  const [sorting, setSorting] = useState<Sorting>("all");

  const [{ showShipments }, setShipmentFilter] = useShipmentsFilter();

  const hasFilter = useMemo<boolean>(
    () => showShipments !== "all",
    [showShipments],
  );

  usePageTitle(
    `Shipments ${
      store
        ? " - " + store.Name
        : group
          ? " - " + group.Name
          : company
            ? " - " + company.Name
            : ""
    }`,
  );

  const {
    search,
    q,
    setList: setSearchableShipments,
    results: trackingNumberSearched,
  } = useSearch<Api.Shipment>([], (shipments) => shipments.TrackingNumber);

  const shipmentsList = useMemo(
    () =>
      (trackingNumberSearched ?? [])
        .filter(filter_shipments(showShipments))
        .sort(sort_shipments(sorting)),
    [showShipments, sorting, trackingNumberSearched],
  );

  const data = useMemo(() => shipmentsList ?? [], [shipmentsList]);

  const bulildBatchNumber = useCallback((shipmentLine: Api.ShipmentLine) => {
    const batchNumbers = shipmentLine.ShipmentStock.map(
      (stockItem) => stockItem.Stock.BatchNumber,
    ).join(", ");

    return {
      ...shipmentLine,
      BatchNumbers: batchNumbers,
    };
  }, []);

  const expiredReplacements = useMemo(() => {
    if (replenishment && selectedShipment) {
      return selectedShipment.ShipmentLines.filter((shipmentLine) =>
        [
          ...replenishment.ExpiredStockStillInKit,
          ...replenishment.ExpiredStockNeedingReplacement,
          ...replenishment.ExpiredStockCardProducts,
        ].find((stock) => stock.SKU === shipmentLine.Product.SKU),
      ).map((shipmentLine) => {
        return bulildBatchNumber(shipmentLine);
      });
    }
  }, [bulildBatchNumber, replenishment, selectedShipment]);

  const lostOrDamaged = useMemo(() => {
    if (replenishment && selectedShipment) {
      return selectedShipment.ShipmentLines.filter((shipmentLine) =>
        [...replenishment.RecentLostOrDamagedStock].find(
          (stock) => stock.SKU === shipmentLine.Product.SKU,
        ),
      ).map((shipmentLine) => {
        return bulildBatchNumber(shipmentLine);
      });
    } else {
      return [];
    }
  }, [bulildBatchNumber, replenishment, selectedShipment]);

  const everythingElse = useMemo(() => {
    if (replenishment && selectedShipment) {
      return selectedShipment.ShipmentLines.filter(
        (shipmentLine) =>
          !lostOrDamaged.find(
            (stock) => stock.SKU === shipmentLine.Product.SKU,
          ) &&
          !expiredReplacements?.find(
            (stock) => stock.SKU === shipmentLine.Product.SKU,
          ),
      ).map((shipmentLine) => {
        return bulildBatchNumber(shipmentLine);
      });
    } else {
      return [];
    }
  }, [
    bulildBatchNumber,
    expiredReplacements,
    lostOrDamaged,
    replenishment,
    selectedShipment,
  ]);

  const productsInUnit = useMemo(() => {
    if (!unit || !selectedShipment) return;

    const allFound: Api.Stock[] = [];

    selectedShipment.ShipmentLines.forEach((shipmentLine) => {
      shipmentLine.ShipmentStock?.forEach((stock) => {
        const found = unit.Stock.find((s) => s.UID === stock.UID);

        if (found) allFound.push(found);
      });
    });
    return allFound;
  }, [unit, selectedShipment]);

  const firstGroup = useMemo(() => {
    if (!!everythingElse.length && selectedShipment) {
      return "consumed-stock";
    } else if (!!expiredReplacements?.length && selectedShipment) {
      return "replacing-expired-stock";
    } else if (!!lostOrDamaged.length && selectedShipment) {
      return "lost-or-damaged-stock";
    }
  }, [
    everythingElse.length,
    expiredReplacements?.length,
    lostOrDamaged.length,
    selectedShipment,
  ]);

  const getProductIcon = useCallback(
    (product: Api.ShipmentLine) => {
      const total = product.Quantity;
      const productCount = productsInUnit?.filter(
        (p) => p.SKU === product.SKU,
      ).length;

      if (productCount === 0) {
        return "times";
      } else if (productCount === total) {
        return "check";
      } else return "exclamation";
    },
    [productsInUnit],
  );

  const getProductIconColor = useCallback(
    (product: Api.ShipmentLine) => {
      const total = product.Quantity;
      const productCount = productsInUnit?.filter(
        (p) => p.SKU === product.SKU,
      ).length;

      if (productCount === 0) {
        return palettes.states.bad.background;
      } else if (productCount === total) {
        return palettes.states.good.background;
      } else return palettes.states.warning.background;
    },

    [productsInUnit, palettes],
  );

  const getTooltipMessage = useCallback(
    (product: Api.ShipmentLine) => {
      const total = product.Quantity;

      const productCount = productsInUnit?.filter(
        (p) => p.SKU === product.SKU,
      ).length;

      if (!productCount) {
        return "Item not added to the cabinet";
      } else if (productCount === total) {
        return "Item has been added to the cabinet";
      } else return `Only ${productCount} have been added to the cabinet`;
    },
    [productsInUnit],
  );

  const getBatchNumberToolTip = useCallback(
    (batchNumbers: string) => {
      return (
        <>
          <div>
            {t("batch.filter", { count: batchNumbers.split(",").length })}
          </div>
          <ol
            style={{
              marginTop: 8,
              marginBottom: 0,
              paddingLeft: 0,
              listStylePosition: "inside",
              color: palettes.well.foreground,
            }}
          >
            {batchNumbers.split(",")?.map((el) => (
              <li key={el}>
                <code>{el}</code>
              </li>
            ))}
          </ol>
        </>
      );
    },
    [t, palettes],
  );

  const columns = useMemo<ColumnDef<FzfResultItem<Api.Shipment>>[]>(
    () => [
      {
        id: "list-item",
        header: "Tracking Number",
        meta: { width: "1fr" },

        cell: ({ row: { original: shipments } }) =>
          shipments && (
            <ListItem
              compact
              title={
                <HighlightChars
                  str={shipments.item.TrackingNumber}
                  indices={shipments.positions}
                />
              }
              description={
                <>
                  Last updated{" "}
                  <LocalisedDate
                    dateTime={shipments.item.ModifiedDate}
                    format={DateFormat.short}
                  />
                </>
              }
              image={
                <IconWithToolTip
                  icon={getShipmentStatusIcon(shipments.item.Status)}
                  color={
                    shipmentStatusPalette(shipments.item.Status).background
                  }
                  title={getStatusLabel(shipments.item.Status)}
                  iconSize={24}
                />
              }
            />
          ),
      },

      {
        id: "store-name",
        header: t("term.store_one") ?? "",
        enableHiding: true,
        meta: { width: "auto" },

        cell: ({ row: { original: shipments } }) =>
          shipments && (
            <div
              style={{
                color: palettes.well.foreground,
              }}
            >
              {shipments.item.Store?.Name}
            </div>
          ),
      },
      {
        id: "device-name",
        header: t("term.unit_one") ?? "",
        meta: { width: "auto" },

        cell: ({ row: { original: shipments } }) =>
          shipments && (
            <div
              style={{
                color: palettes.well.foreground,
              }}
            >
              {shipments.item.UnitName}
            </div>
          ),
      },
      ...(features.showBilling
        ? [
            {
              id: "shipment-cost",
              header: () => (
                <TextWithToolTip
                  title={"Includes shipping, excludes taxes"}
                  toolTipPlacement={"top-center"}
                >
                  Cost
                </TextWithToolTip>
              ),

              cell: ({ row: { original: shipments } }) =>
                shipments && (
                  <div style={{ textAlign: "right" }}>
                    {new Intl.NumberFormat("en-NZ", {
                      style: "currency",
                      currency: "NZD",
                    }).format(
                      shipments.item.ShipmentCost + shipments.item.CourierCost,
                    )}
                  </div>
                ),
            } as ColumnDef<FzfResultItem<Api.Shipment>>,
          ]
        : []),

      {
        id: "date",
        header: "Shipped Date",
        meta: { width: "auto" },

        cell: ({ row: { original: shipments } }) => {
          if (!shipments.item.CreatedDate) {
            return null;
          }

          return (
            shipments && (
              <>
                <LocalisedDate
                  dateTime={shipments.item.CreatedDate}
                  format={DateFormat.numeric}
                />
              </>
            )
          );
        },
      },
      {
        id: "delivery-date",
        header: "Delivery Date",
        meta: { width: "auto" },
        cell: ({ row: { original: shipments } }) => {
          if (!shipments.item.DeliveryDate) {
            return null;
          }

          return (
            shipments && (
              <LocalisedDate
                dateTime={shipments.item.DeliveryDate}
                format={DateFormat.numeric}
              />
            )
          );
        },
      },
      {
        id: "invoice",
        meta: { width: "auto" },
        cell: ({ row: { original: shipments } }) => {
          if (!shipments.item.InvoiceUrl) {
            return null;
          }
          return (
            <div
              style={{
                color: palettes.well.foreground,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <IconButton
                size="normal"
                icon={"clipboard-list"}
                buttonType="transparent"
                href={shipments.item.InvoiceUrl}
                target="_blank"
                title="View invoice details (opens in new tab)"
              />
            </div>
          );
        },
      },
      {
        id: "url",
        meta: { width: "auto" },
        cell: ({ row: { original: shipments } }) => {
          if (!shipments.item.TrackingNumber) {
            return null;
          }
          return (
            <div
              style={{
                color: palettes.well.foreground,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <IconButton
                size="normal"
                icon={"link"}
                buttonType="transparent"
                href={shipments.item.TrackingUrl}
                target="_blank"
                title="View tracking details (opens in new tab)"
              />
            </div>
          );
        },
      },
    ],
    [t, features.showBilling, shipmentStatusPalette, palettes.well.foreground],
  );

  const handleSearchChange = useCallback((q: string) => search(q), [search]);

  const handleCloseClick = useCallback(() => setSelectedShipment(null), []);

  const handleShipmentClick = useCallback(
    (shipment: Api.Shipment) => setSelectedShipment(shipment),
    [],
  );

  const handleClearFilterClick = useCallback(() => {
    setShipmentFilter({
      showShipments: "all",
    });
    search("");
    history.replace("./shipments");
  }, [history, search, setShipmentFilter]);

  const initialized = useRef(false);

  const location = useLocation();

  useEffect(() => {
    if (!initialized.current) {
      history.replace({
        pathname: location.pathname,
      });
    }

    initialized.current = true;
  }, [history, location]);

  useEffect(
    () => setSearchableShipments(shipments),
    [setSearchableShipments, shipments],
  );

  useEffect(() => {
    setSelectedShipment(null);
  }, [groupId, storeId]);

  return (
    <Page showSidebar={!!selectedShipment}>
      <div
        style={{
          display: "grid",
          overflow: "hidden",
          gridTemplateRows: "auto 1fr",
        }}
      >
        <div
          style={{
            padding: DS.margins.regularCss("rl"),
            margin: DS.margins.microCss("tb"),
          }}
        >
          <div
            style={{
              padding: DS.margins.micro,
              background: palettes.well.background,
              borderRadius: DS.radii.largeItem,

              display: "grid",
              gridAutoFlow: "column",
              gap: DS.margins.regular,
              justifyContent: "flex-start",
              gridTemplateColumns: "1fr 210px 200px",
            }}
          >
            <SearchBar
              onSearch={handleSearchChange}
              value={q}
              placeholder="Search for shipments…"
            />
            <LabelledSelect
              compact
              label="Show shipments"
              options={
                [
                  {
                    value: "all",
                    label: "All",
                  },
                  { value: "completed", label: "Completed" },
                  { value: "on-way", label: "Sent" },
                  { value: "overdue", label: "Overdue" },
                  { value: "hide", label: "Active" },
                ] as { value: Filtering; label: string }[]
              }
              value={showShipments}
              onChange={(e) =>
                setShipmentFilter({
                  showShipments: e.target.value as Filtering,
                })
              }
            />

            <LabelledSelect
              compact
              label="Sort by"
              options={
                [
                  { value: "creation-date", label: "Creation date" },
                  { value: "unfinished-first", label: "Unfinished first" },
                ] as { value: Sorting; label: string }[]
              }
              value={sorting}
              onChange={(e) => setSorting(e.target.value as Sorting)}
            />
          </div>
          {hasFilter && (
            <Well
              style={{
                marginTop: DS.margins.micro,
                textAlign: "center",
                background: palettes.messages.notice.background,
              }}
            >
              <SmallText style={{ color: palettes.messages.notice.foreground }}>
                Showing{" "}
                <strong>
                  {shipmentsList.length ?? 0} of {shipments?.length}
                </strong>{" "}
                shipments.{" "}
                <SecondaryButton inline onClick={handleClearFilterClick}>
                  Clear the filters
                </SecondaryButton>{" "}
                to show all shipments
              </SmallText>
            </Well>
          )}

          {q && (!shipmentsList || !shipmentsList.length) && (
            <LargeDataNotice>
              No shipments matching <strong>{q}</strong>
            </LargeDataNotice>
          )}
        </div>

        <Scroller>
          <div style={{ padding: DS.margins.regularCss("rbl") }}>
            {isShipmentsLoading ? (
              <div style={{ margin: DS.margins.regularCss("rl") }}>
                {Array.from(new Array(6)).map((_v, i) => (
                  <div
                    key={`placeholder-${i}`}
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      borderBottom: `solid 1px ${palettes.body.border}`,
                    }}
                  >
                    <ListItemPlaceholder showImage={false} />
                  </div>
                ))}
              </div>
            ) : (
              <Table
                data={data}
                columns={columns}
                columnVisibility={{ "store-name": !storeId }}
                onRowClick={(row) => handleShipmentClick(row.original.item)}
                isSelectedRow={(row) => row.original.item === selectedShipment}
              />
            )}
          </div>
        </Scroller>
      </div>

      {selectedShipment && (
        <div
          style={{
            overflow: "hidden",
            borderLeft: `solid 1px ${palettes.body.border}`,
            display: "grid",
            alignContent: "start",
          }}
        >
          <SidePanelHeader
            title={getTitle(selectedShipment)}
            subtitle={selectedShipment.Store.Name}
            closeTitle="Close shipment view"
            onClose={handleCloseClick}
          />

          <Scroller>
            <div
              style={{
                padding: DS.margins.regularCss("rbl"),
                display: "grid",
                gap: DS.margins.micro,
              }}
            >
              {isReplenishmentsSummaryLoading && selectedShipment ? (
                <div>
                  <GroupHeaderPlaceholder separator={false} />
                  <ListItemsPlaceholder />
                </div>
              ) : (
                <>
                  {!!everythingElse.length && selectedShipment && (
                    <div>
                      <GroupHeader separator={false}>
                        Consumed stock
                      </GroupHeader>
                      <UnstyledList>
                        {everythingElse.map((p) => (
                          <ShipmentItemsList key={p.ShipmentLineID}>
                            <ListItem
                              compact
                              image={p.ImageUrl}
                              title={p.Name}
                              descriptionTip={getBatchNumberToolTip(
                                p.BatchNumbers,
                              )}
                              description={
                                p.BatchNumbers
                                  ? `Batch: ${p.BatchNumbers}`
                                  : null
                              }
                            />
                            <QuantityContainer>{`x${p.Quantity}`}</QuantityContainer>
                            <IconWithToolTip
                              title={getTooltipMessage(p)}
                              icon={getProductIcon(p)}
                              color={getProductIconColor(p)}
                            />
                          </ShipmentItemsList>
                        ))}
                      </UnstyledList>
                    </div>
                  )}

                  {!!expiredReplacements?.length && selectedShipment && (
                    <div>
                      <GroupHeader
                        separator={firstGroup !== "replacing-expired-stock"}
                      >
                        Replacing Expired items
                      </GroupHeader>
                      <UnstyledList>
                        {expiredReplacements.map((p) => (
                          <ShipmentItemsList key={p.ShipmentLineID}>
                            <ListItem
                              compact
                              image={p.ImageUrl}
                              title={p.Name}
                              descriptionTip={getBatchNumberToolTip(
                                p.BatchNumbers,
                              )}
                              description={
                                p.BatchNumbers
                                  ? `Batch: ${p.BatchNumbers}`
                                  : null
                              }
                            />
                            <QuantityContainer>{`x${p.Quantity}`}</QuantityContainer>
                            <IconWithToolTip
                              title={getTooltipMessage(p)}
                              icon={getProductIcon(p)}
                              color={getProductIconColor(p)}
                            />
                          </ShipmentItemsList>
                        ))}
                      </UnstyledList>
                    </div>
                  )}
                  {!!lostOrDamaged.length && selectedShipment && (
                    <div>
                      <GroupHeader
                        separator={firstGroup !== "lost-or-damaged-stock"}
                      >
                        Lost or Damaged
                      </GroupHeader>
                      <UnstyledList>
                        {lostOrDamaged.map((p) => (
                          <ShipmentItemsList key={p.ShipmentLineID}>
                            <ListItem
                              compact
                              image={p.ImageUrl}
                              title={p.Name}
                              descriptionTip={getBatchNumberToolTip(
                                p.BatchNumbers,
                              )}
                              description={
                                p.BatchNumbers
                                  ? `Batch: ${p.BatchNumbers}`
                                  : null
                              }
                            />
                            <QuantityContainer>{`x${p.Quantity}`}</QuantityContainer>
                            <IconWithToolTip
                              title={getTooltipMessage(p)}
                              icon={getProductIcon(p)}
                              color={getProductIconColor(p)}
                            ></IconWithToolTip>
                          </ShipmentItemsList>
                        ))}
                      </UnstyledList>
                    </div>
                  )}
                </>
              )}
            </div>
          </Scroller>
        </div>
      )}
    </Page>
  );
};

export default Shipments;
