import { format, isSameDay, isWithinInterval } from "date-fns";

import { DateRange, normalizeDateRange } from "@util/DateRange";

const hasStartDate = (params: unknown): params is { startDate: string } =>
  !!params &&
  typeof params === "object" &&
  "startDate" in params &&
  !!params.startDate;

const hasEndDate = (params: unknown): params is { endDate: string } =>
  !!params &&
  typeof params === "object" &&
  "endDate" in params &&
  !!params.endDate;

export const trimStartAndEndDates = <T extends unknown>(obj: T): T => {
  if (!obj) return obj;

  if (hasStartDate(obj))
    obj.startDate = format(new Date(obj.startDate), "yyyy-MM-dd");

  if (hasEndDate(obj))
    obj.endDate = format(new Date(obj.endDate), "yyyy-MM-dd");

  return obj;
};

export const getStartAndEndDatesFromNumberOfDays = (
  numberOfDays: number,
): DateRange => {
  const endDate = new Date();
  const startDate = new Date(
    new Date().setDate(endDate.getDate() - numberOfDays + 1),
  );
  return normalizeDateRange({ start: startDate, end: endDate });
};

export interface CornerDef {
  topLeft: boolean;
  topRight: boolean;
  bottomRight: boolean;
  bottomLeft: boolean;
}

/**
 * Calculates the visual corners of a date range. Used for special styling when
 * displaying a date range in a calendar widget.
 *
 * @param date The date being rendered.
 * @param dateRange The selected date range that will be displayed.
 * @param dateIndex Day of week index of the date being rendered.
 * @param weekIndex Week of the month index of the date being rendered.
 * @param visibleWeeks Number of weeks visible on the current month.
 */
export function dateRangeCorners(
  date: Date,
  dateRange: DateRange,
  dateIndex: number,
  weekIndex: number,
  visibleWeeks: number,
): CornerDef {
  const corners: CornerDef = {
    topLeft: false,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
  };

  // Not within range, keep it square.
  if (!isWithinInterval(date, dateRange)) return corners;

  const isStartDate = isSameDay(date, dateRange.start);
  const isEndDate = isSameDay(date, dateRange.end);

  const dayAbove = new Date(date);
  dayAbove.setDate(dayAbove.getDate() - 7);
  const dayAboveIsInRange = isWithinInterval(dayAbove, dateRange);

  const dayBelow = new Date(date);
  dayBelow.setDate(dayBelow.getDate() + 7);
  const dayBelowIsInRange = isWithinInterval(dayBelow, dateRange);

  // Start and end dates always have top left and bottom right, respectively.
  if (isStartDate) {
    corners.topLeft = true;
    corners.bottomLeft = !dayBelowIsInRange || weekIndex === visibleWeeks - 1;
  }

  if (isEndDate) {
    corners.bottomRight = true;
    corners.topRight = !dayAboveIsInRange || weekIndex === 0;
  }

  // Start of week, day above is not in range
  if (!dayAboveIsInRange && dateIndex === 0) {
    corners.topLeft = true;
  }

  // End of week, if day above is not in range
  if (!dayAboveIsInRange && dateIndex === 6) {
    corners.topRight = true;
  }

  // Start of week, if day below is not in range
  if (!dayBelowIsInRange && dateIndex === 0) {
    corners.bottomLeft = true;
  }

  // End of week, if day below is not in range
  if (!dayBelowIsInRange && dateIndex === 6) {
    corners.bottomRight = true;
  }

  // Corners of month, if in range
  if (dateIndex === 0 && weekIndex === 0) corners.topLeft = true;
  if (dateIndex === 6 && weekIndex === 0) corners.topRight = true;
  if (dateIndex === 6 && weekIndex === visibleWeeks - 1)
    corners.bottomRight = true;
  if (dateIndex === 0 && weekIndex === visibleWeeks - 1)
    corners.bottomLeft = true;

  return corners;
}

/**
 * Given a CornerDef, render a border radius.
 */
export function renderBorderRadius(corners: CornerDef, radius: number) {
  return `${corners.topLeft ? radius + "px" : 0} ${
    corners.topRight ? radius + "px" : 0
  } ${corners.bottomRight ? radius + "px" : 0} ${
    corners.bottomLeft ? radius + "px" : 0
  }`;
}
