import axios, { AxiosInstance } from "axios";
import { format } from "date-fns";
import download from "downloadjs";

import {
  attachApplicationInfo,
  attachCorrelationId,
  attachFingerprint,
  convertStartAndEndDates,
} from "./middleware";

export * from "./error";

export interface GetByGroupOrStore {
  storeId?: string;
  groupId?: string;
  allStores?: boolean;
}

export interface GetByGroupIrStoreOrUnit extends GetByGroupOrStore {
  controllerSerialNumber?: string;
}

export interface GetByStoresIds {
  storesIds?: string[];
}

export interface GetByPagination {
  page?: number;
  pageSize?: number;
}

// FIXME: This should be numberDays OR start/end dates
export interface GetByDateRange {
  numberDays?: number;
  startDate?: string;
  endDate?: string;
}

export interface GetIncident {
  id?: string;
  eventId?: string;
}

export type GetDashboardOptions = GetByGroupOrStore &
  GetByDateRange & { includeLowIncidents?: boolean };
export type GetIncidentsOptions = GetByGroupOrStore & GetByDateRange;
export type GetShipmentsOptions = GetByGroupOrStore & GetByDateRange;

export interface AddUsersToGroupsResponse {
  id: string;
  CreatedDate: string;
  ModifiedDate: string;
  OriginalId: string;
}

export interface LogInParams {
  Email: string;
  SuppliedPassword: string;
  IsSharedComputer?: boolean;
}

export type AuthState =
  | "unauthenticated"
  | "mfa-email"
  | "mfa-totp"
  | "authenticated";

export type RemoveUsersFromGroupsResponse = AddUsersToGroupsResponse;

export const client = axios.create({
  headers: {},
  withCredentials: true,

  // Pass all errors, 3xx, 4xx and 5xx alike, through so that they can be
  // handled later by hooks.
  validateStatus: () => true,
});

client.interceptors.request.use(attachCorrelationId);
client.interceptors.request.use(attachApplicationInfo);
client.interceptors.request.use(convertStartAndEndDates);
client.interceptors.request.use(attachFingerprint);

const xsrfCache = {
  name: "",
};

const countryCodesUrl = "/Store/GetAllowedCountryCodes";

const api = {
  setBaseUrl: (url: string) => {
    client.defaults.baseURL = url;
  },

  setXsrf: (xsrf: Api.XsrfToken) => {
    xsrfCache.name = xsrf.tokenName;
    client.defaults.headers.common[xsrfCache.name] = xsrf.token;
  },

  clearXsrf: () => {
    delete client.defaults.headers.common[xsrfCache.name];
  },

  _setCookie: (cookies: string) => {
    client.defaults.headers.common["Cookie"] = cookies;
  },

  auth: {
    xsrfToken: async () =>
      (await client.get<Api.XsrfToken>("/Home/GetXsrfToken")).data,

    mfaSecret: async (userId: string) =>
      (
        await client.get<Api.ResponseSuccess<string>>(
          `/xoria/v1/auth/mfa/secret?userId=${userId}`,
        )
      ).data.SuccessPayload,
  },

  logIn: async (email: string, password: string, sharedDevice = false) => {
    const response = await client.post<Api.User | Api.MfaRequired>(
      "/Authentication/Login",
      {
        Email: email,
        SuppliedPassword: password,
        IsSharedComputer: sharedDevice,
      },
    );

    const isMfaRequiredResponse = (
      data: Api.User | Api.MfaRequired,
    ): data is Api.MfaRequired => "MfaValidationMethod" in data;

    const status: AuthState =
      response.status === 200
        ? "authenticated"
        : response.status === 202
          ? isMfaRequiredResponse(response.data)
            ? response.data.MfaValidationMethod === "authenticator"
              ? "mfa-totp"
              : "mfa-email"
            : "unauthenticated"
          : "unauthenticated";

    return { user: response.data, status };
  },

  submitMfa: async (email: string, code: string) =>
    (
      await client.post<Api.User>("/Authentication/ValidateMFA", {
        Email: email,
        SuppliedVerificationCode: Number(code),
      })
    ).data,

  logOut: async () => {
    await client.get("/Authentication/Logout");
  },

  /**
   * @throws ApiResponseError<Api.UserValidationError>
   */
  requestPasswordReset: async (email: string) => {
    const response = await client.post<Api.ResponseSuccess<Api.User>>(
      "/ResetPassword/SendResetPasswordEmail",
      {
        Email: email,
      },
    );

    return response.data;
  },

  /**
   * @throws ApiResponseError<Api.UserValidationError>
   */
  submitNewPassword: async (
    email: string,
    newPassword: string,
    key: string,
  ) => {
    const response = await client.post<
      Api.ResponseSuccess<{ ContextUser: Api.User }>
    >("/ResetPassword/ResetPassword", {
      Email: email,
      SuppliedPassword: newPassword,
      ConfirmPassword: newPassword,
      ResetKey: key,
    });

    return response.data.SuccessPayload.ContextUser;
  },

  dashboard: async (options: GetDashboardOptions) => {
    const response = await client.get<Api.DashboardUpdate>(
      "/Home/DashboardUpdate",
      {
        params: options,
      },
    );

    return response.data;
  },

  policies: {
    companyMfa: async (
      enable: boolean,
      forceSignOutUsers: boolean,
      companyId: string,
    ) =>
      (
        await client.put<Api.ResponseSuccess<Api.CompanyMfa>>(
          "/xoria/v1/policies/company/mfa",
          {
            companyId,
            enable,
            forceSignOutUsers,
          },
        )
      ).data,
  },

  company: {
    get: async (id: string) => {
      const response = await client.get<Api.Company>("/Company/GetCompany", {
        params: { id },
      });

      return response.data;
    },

    /**
     * @throws ApiResponseError<Api.CompanyValidationError>
     */
    update: async (company: Api.Company) => {
      const response = await client.post<Api.ResponseSuccess<Api.Company>>(
        "/Company/SaveCompany",
        company,
      );

      return response.data;
    },
  },

  stores: {
    get: async (id: string) =>
      (
        await client.get<Api.StoreFull>("/Store/GetStoreById", {
          params: { storeId: id },
        })
      ).data,

    list: async (groupId?: string) =>
      (
        await client.get<Api.Store[]>("/Store/GetStores", {
          params: {
            groupId,
            storeStatuses: "READY,BILLABLE",
            status: "READY,BILLABLE",
          },
        })
      ).data,

    /**
     * @throws ApiResponseError<Api.StoreValidationError>
     */
    update: async (store: Api.Store) => {
      const response = await client.post<Api.ResponseSuccess<Api.Store>>(
        "/Store/SaveStore",
        store,
      );

      return response.data;
    },

    readiness: async () => {
      const response = await client.get<Api.OverallReadiness>(
        "/Store/GetStoresReadiness",
      );

      return response.data;
    },
  },

  groups: {
    /**
     * Returns a tree of only the groups a user has access to.
     */
    tree: async (companyId: string) => {
      const response = await client.get<Api.TreeGroup[]>(
        "/Groups/GetUserCompaniesGroupsAndStores",
        {
          params: {
            companyId,
            storeStatuses: "READY,BILLABLE",
            status: "READY,BILLABLE",
          },
        },
      );

      return response.data;
    },

    /**
     * Returns a completely flat list of all groups in the company.
     */
    list: async (companyId: string) => {
      const response = await client.get<Api.GroupFlat[]>(
        "/Groups/GetGroupsList",
        {
          params: { id: companyId },
        },
      );

      return response.data;
    },

    addUsers: async (groupsIds: string[], userIds: string[]) => {
      const response = await client.post<
        Api.ResponseSuccess<AddUsersToGroupsResponse>
      >("/Groups/AddUsersToGroups", { userIds, groupsIds });

      return response.data;
    },

    removeUsers: async (groupsIds: string[], userIds: string[]) => {
      const response = await client.post<
        Api.ResponseSuccess<RemoveUsersFromGroupsResponse>
      >("/Groups/RemoveUsersFromGroups", { userIds, groupsIds });

      return response.data;
    },

    /**
     * @throws ApiResponseError<Api.GroupValidationError>
     */
    addStores: async (groupsIds: string[], storeIds: string[]) => {
      const response = await client.post<Api.ResponseSuccess<unknown>>(
        "/Groups/AddStoresToGroups",
        { storeIds, groupsIds },
      );

      return response.data;
    },

    /**
     * @throws ApiResponseError<Api.GroupValidationError>
     */
    removeStores: async (groupsIds: string[], storeIds: string[]) => {
      const response = await client.post<Api.ResponseSuccess<unknown>>(
        "/Groups/RemoveStoresFromGroups",
        { storeIds, groupsIds },
      );

      return response.data;
    },

    /**
     * @throws ApiResponseError<Api.GroupValidationError>
     */
    update: async (group: Partial<Api.TreeGroup>) => {
      const response = await client.post<Api.ResponseSuccess<Api.TreeGroup>>(
        "/Groups/SaveGroup",
        group,
      );

      return response.data;
    },

    /**
     * @throws ApiResponseError<Api.GroupValidationError>
     */
    delete: async (companyId: string, id: string) => {
      const response = await client.post<Api.ResponseSuccess<Api.TreeGroup>>(
        "/Groups/DeleteGroup",
        { id, companyId, keepChildren: true },
      );

      return response.data;
    },
  },

  units: {
    get: async (controllerSerialNumber: string) => {
      const response = await client.get<Api.Unit>("/Unit/GetUnit", {
        params: { controllerSerialNumber },
      });

      return response.data;
    },

    list: async (options?: GetByGroupOrStore): Promise<Api.Unit[]> => {
      const response = await client.get<Api.Unit[]>("/Unit/GetUnits", {
        params: { unitsOnly: true, ...options },
      });

      return response.data.filter((unit) => !unit.IsBeacon);
    },

    update: async (unit: Partial<Api.Unit>) => {
      const response = await client.post<Api.ResponseSuccess<Api.Unit>>(
        "/Unit/SaveUnit",
        unit,
      );

      return response.data;
    },

    partialUpdate: async (unit: Api.AedInspectionComplete) =>
      (
        await client.post<Api.ResponseSuccess<Api.Unit>>(
          "/AedCustomerWorkflow/UpdateAedDetails",
          unit,
        )
      ).data,

    stock: async (controllerSerialNumber: string) =>
      (
        await client.get<Api.ResponseSuccess<Api.UnitStockSummary>>(
          "/Unit/GetUnit",
          {
            params: { controllerSerialNumber, stockOnly: true },
          },
        )
      ).data,

    getReplenishmentSummary: async (controllerSerialNumber: string) =>
      (
        await client.get<Api.ReplenishmentSummary>(
          "/Unit/GetReplenishmentSummary",
          { params: { controllerSerialNumber } },
        )
      ).data,

    scheduleAedInspection: async (
      controllerSerialNumber: string,
      dueDate: Date,
    ) =>
      (
        await client.post<Api.ResponseSuccess<Api.Unit>>(
          `/AedCustomerWorkflow/ScheduleAdHocAedInspection?controllerSerialNumber=${controllerSerialNumber}&dueDate=${format(
            dueDate,
            "yyyy-MM-dd",
          )}`,
        )
      ).data,

    confirmAedInspection: async (result: Api.AedInspectionComplete) =>
      (
        await client.post<Api.ResponseSuccess<Api.Unit>>(
          "/AedCustomerWorkflow/ConfirmAedInspection",
          result,
        )
      ).data,

    requestAedReplenishment: async (result: Api.AedReplenishment) =>
      (
        await client.post<Api.ResponseSuccess<Api.Unit>>(
          "/AedCustomerWorkflow/SignOffAedReplenishment",
          result,
        )
      ).data,

    declineAedReplenishment: async (result: Api.AedReplenishment) =>
      (
        await client.post<Api.ResponseSuccess<Api.Unit>>(
          "/AedCustomerWorkflow/DeclineAedReplenishment",
          result,
        )
      ).data,

    requestUnitToolReplacement: async (result: Api.UnitToolReplacement) =>
      (
        await client.post<Api.ResponseSuccess<Api.Unit>>(
          "/xoria/v1/station/request-product-replacement",
          result,
        )
      ).data,

    declineUnitToolReplacement: async (result: Api.UnitToolReplacement) =>
      (
        await client.post<Api.ResponseSuccess<Api.Unit>>(
          "/xoria/v1/station/decline-product-replacement",
          result,
        )
      ).data,

    confirmAedReplenished: async (result: Api.AedReplenished) =>
      (
        await client.post<Api.ResponseSuccess<Api.Unit>>(
          "/AedCustomerWorkflow/ConfirmAedReplenishment",
          result,
        )
      ).data,

    requestAddressChange: async (
      controllerSerialNumber: string,
      address: Api.AddressChange,
    ) =>
      (
        await client.post<Api.ResponseSuccess<unknown>>(
          "/AedCustomerWorkflow/SuggestAddressChange",
          { controllerSerialNumber, address },
        )
      ).data,
  },

  users: {
    /**
     * Get the currently logged in user.
     */
    current: async (signal?: AbortSignal) => {
      const response = await client.get<Api.ResponseSuccess<Api.CurrentUser>>(
        "/User/GetUser",
        { signal },
      );

      return response.data.SuccessPayload;
    },

    get: async (id: string) => {
      if (!id) {
        throw new Error("User id required");
      }

      const response = await client.get<Api.User>("/User/GetUserById", {
        params: { id, source: "portal" },
      });

      return response.data;
    },

    listStore: async (companyId: string, storeId?: string) => {
      const response = await client.get<Api.User[]>("/User/GetUsers", {
        params: { storeId, companyId },
      });

      return response.data;
    },

    listGroup: async (companyId: string, groupId: string) => {
      const response = await client.get<Api.UsersInGroup>(
        "/Groups/GetAllUsersForGroup",
        { params: { companyId, groupId } },
      );

      return response.data;
    },

    /**
     * @throws ApiResponseError<Api.UserValidationError>
     */
    update: async (user: Partial<Api.User>) => {
      const response = await client.post<Api.ResponseSuccess<Api.User>>(
        "/User/SaveUser?source=portal",
        user,
      );

      return response.data;
    },

    /**
     * @throws ApiResponseError<Api.UserValidationError>
     */
    delete: async (id: string) => {
      const response = await client.post<Api.ResponseSuccess<Api.User>>(
        `/User/DeleteUser/${id}`,
      );

      return response.data;
    },

    defaultUserAlerts: async (roleName: string): Promise<Api.UserAlert[]> => {
      const response = await client.get<Api.UserAlert[]>(
        "/User/GetDefaultUserAlerts",
        { params: { roleName, isStoreContact: false } },
      );

      return response.data;
    },

    acceptToS: async () => {
      return await client.post<Api.ResponseSuccess<unknown>>(
        "/xoria/v1/users/accept-tos",
        {},
      );
    },
  },

  roles: {
    list: async (): Promise<Api.Role[]> =>
      (await client.get<Api.Role[]>("/Role/GetRoles")).data,
  },

  incidents: {
    get: async ({ id, eventId }: GetIncident) => {
      const response = await (eventId
        ? client.get<Api.Incident>("/EventHistory/GetIncidentByEventId", {
            params: { id: eventId },
          })
        : client.get<Api.Incident>("/EventHistory/GetIncidentById", {
            params: { id },
          }));

      return response.data;
    },
    stats: async (
      options: GetByGroupOrStore &
        GetByDateRange & { excludeIncidentData?: boolean },
    ) =>
      (
        await client.get<Api.IncidentStats>("/EventHistory/GetIncidentStats", {
          params: options,
        })
      ).data,
  },

  events: {
    incidents: async ({
      storeId,
      groupId,
      numberDays = 9,
      startDate,
      endDate,
    }: GetIncidentsOptions) => {
      const response = await client.get<Api.Incident[]>(
        "/EventHistory/GetIncidents",
        {
          params: {
            storeId,
            groupId,
            numberDays,
            startDate,
            endDate,
          },
        },
      );

      return response.data;
    },

    paginatedIncidents: async ({
      storeId,
      groupId,
      startDate,
      endDate,
      page,
      pageSize,
    }: GetIncidentsOptions & GetByPagination) => {
      const response = await client.get<Api.PaginatedResponse<Api.Incident[]>>(
        "/EventHistory/GetPaginatedIncidents",
        {
          params: {
            storeId,
            groupId,
            startDate,
            endDate,
            numberDays: -1,
            page: page ?? 1,
            pageSize: pageSize ?? 10,
          },
        },
      );

      return response.data;
    },
  },

  location: {
    timeZones: async () => {
      const response = await client.get<{ TimeZones: Api.Timezone[] }>(
        "/Store/GetTimeZones",
      );

      return response.data.TimeZones;
    },

    countries: async () => {
      const response = await client.get<{ TimeZones: Api.Timezone[] }>(
        "/Store/GetAllowedCountries",
      );

      return response.data.TimeZones;
    },
  },

  countryCodes: {
    list: async () => {
      const response = await client.get<{ CountryCodes: Api.CountryCodes[] }>(
        countryCodesUrl,
      );

      return response.data;
    },
  },

  terminology: {
    get: async () =>
      (
        await client.get<Api.ResponseSuccess<Api.Terminology>>(
          `/xoria/v1/company/terminology/replacements`,
        )
      ).data.SuccessPayload,
  },

  billing: {
    invoices: async (storeId: string, since: Date) => {
      const response = await client.get<Api.BillingInformation>(
        "/Billing/GetStoreInvoices",
        {
          params: { storeId, since: since.toISOString() },
        },
      );

      return response.data;
    },

    downloadInvoicePdf: async (storeId: string, invoice: Api.Invoice) => {
      const response = await client.get(
        `${
          client.defaults.baseURL ?? ""
        }/Billing/GetStoreInvoicePdf?storeId=${storeId}&invoiceId=${
          invoice.InvoiceID
        }`,
        { responseType: "blob" },
      );

      download(
        new Blob([response.data]),
        `${invoice.InvoiceNumber}.pdf`,
        "application/pdf",
      );
    },
  },

  escalations: {
    list: async (options?: GetByGroupOrStore) => {
      const response = await client.get<Api.Escalation[]>(
        "/Admin/GetEscalations",
        { params: options },
      );

      return response.data;
    },
  },

  products: {
    get: async (sku: string) => {
      if (!sku) throw new Error("Sku is required");

      const response = await client.get<Api.Product>("/Product/GetProduct", {
        params: { sku },
      });

      return response.data;
    },
  },

  shipments: {
    get: async (id?: string) => {
      if (!id) throw new Error("Id is required");

      const response = await client.get<Api.Shipment>("/Shipment/GetShipment", {
        params: { shipmentId: id },
      });

      return response.data;
    },

    listUnit: async (
      controllerSerialNumber: string,
      storeId: string,
      maxNumberToReturn?: number,
    ) => {
      const response = await client.get<Api.Shipment[]>(
        "/Shipment/GetMostRecentShipmentsForUnit",
        { params: { controllerSerialNumber, storeId, maxNumberToReturn } },
      );

      return response.data;
    },

    list: async (options: GetShipmentsOptions) => {
      const response = await client.get<Api.Shipment[]>(
        "/Shipment/GetShipments",
        { params: options },
      );

      return response.data;
    },
  },

  activity: {
    list: async (options: GetByGroupOrStore & { numberDays: number }) =>
      (
        await client.get<Api.Activity[]>("/Home/RecentActivity", {
          params: options,
        })
      ).data,
  },

  inspectionsChecks: {
    list: async (companyId: string) =>
      (
        await client.get<Api.DeviceInspectionCheck[]>(
          "/AedCustomerWorkflow/GetInspectionChecks",
          { params: { companyId } },
        )
      ).data,
  },
};

// Used for end-to-end tests only
declare global {
  interface Window {
    _api: typeof api;
    _client: AxiosInstance;
  }
}

const win = globalThis.window || {};
if (win) {
  win._api = api;
  win._client = client;
}

export default api;
