import { useCallback, useEffect, useMemo } from "react";
import { MapRef } from "react-map-gl";

import DS from "@design/system";
import { IIcon } from "@icons";
import { findCenter } from "@util/map";
import { useMapLayer } from "@util/useMapLayer";
import { ButtonProps } from "src/button";

import ButtonBar from "./ButtonBar";
import IconButton from "./IconButton";
import { LongitudeLatitude } from "./MapLocationEditor";

export type Preset = {
  title: string;
  icon: IIcon;
  location: LongitudeLatitude;
};

const ZOOMED_IN = 18;
const MAP_ANIMATION_SPEED = 4;

const MapControls = ({
  mapRef,
  showPoi = false,
  showZoom = true,
  showZoomAll = false,
  showSatelite = true,
  showMyLocation = false,
  pointsOfInterest,
  resetLocation,
  zoomAllCoords,
  onLocationChange,
  size = "default",
}: {
  mapRef: MapRef | null;
  showPoi?: boolean;
  showZoom?: boolean;
  showZoomAll?: boolean;
  showSatelite?: boolean;
  showMyLocation?: boolean;
  pointsOfInterest?: Preset[];
  resetLocation?: LongitudeLatitude;
  zoomAllCoords?: [lng: number, lat: number][];
  onLocationChange?: (location: LongitudeLatitude) => void;
  size?: "default" | "small";
}) => {
  const [mapView, setMapView] = useMapLayer();

  const buttonSizeProps: Partial<ButtonProps> = useMemo(
    () => (size === "default" ? { fit: "relaxed" } : { iconSize: 12 }),
    [size],
  );

  const zoomToAllBounds = useMemo(() => {
    if (!zoomAllCoords || !showZoomAll) return;
    return findCenter(zoomAllCoords);
  }, [zoomAllCoords, showZoomAll]);

  const zoomAll = useCallback(() => {
    if (mapRef && zoomToAllBounds) {
      mapRef.fitBounds(zoomToAllBounds, {
        maxZoom: ZOOMED_IN,
        speed: MAP_ANIMATION_SPEED,
        padding: 40,
      });
    }
  }, [mapRef, zoomToAllBounds]);

  const handlePresetClick = useCallback(
    (preset: Preset) => {
      const { longitude, latitude } = preset.location;

      onLocationChange?.(preset.location);

      mapRef?.flyTo({
        speed: MAP_ANIMATION_SPEED,
        center: [longitude, latitude],
        zoom: ZOOMED_IN,
      });
    },
    [onLocationChange, mapRef],
  );

  const handleResetChangesClick = useCallback(() => {
    if (!resetLocation) return;

    onLocationChange?.({ ...resetLocation });

    mapRef?.flyTo({
      speed: MAP_ANIMATION_SPEED,
      center: [resetLocation.longitude, resetLocation.latitude],
      zoom: ZOOMED_IN,
    });
  }, [resetLocation, onLocationChange, mapRef]);

  const handleSetToCurrentLocationClick = useCallback(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const { longitude, latitude } = coords;
      onLocationChange?.({ longitude, latitude });

      mapRef?.flyTo({
        center: [coords.longitude, coords.latitude],
        zoom: ZOOMED_IN,
      });
    });
  }, [onLocationChange, mapRef]);

  const handleLayersClick = useCallback(() => {
    setMapView((current) => (current == "satellite" ? "default" : "satellite"));
  }, [setMapView]);

  useEffect(() => {
    mapRef?.getMap().setStyle(mapView);
  }, [mapRef, mapView]);

  return (
    <div
      style={{
        display: "grid",
        gap: DS.margins.micro,
      }}
      key="zoom-controls"
    >
      {mapRef && (
        <>
          {showZoom && (
            <ButtonBar vertical>
              <IconButton
                title="Zoom in"
                tooltipPlacement="left-center"
                icon="plus"
                onClick={mapRef.zoomIn}
                {...buttonSizeProps}
              />
              <IconButton
                title="Zoom out"
                tooltipPlacement="left-center"
                icon="minus"
                onClick={mapRef.zoomOut}
                {...buttonSizeProps}
              />
            </ButtonBar>
          )}

          {zoomToAllBounds && (
            <ButtonBar vertical>
              <IconButton
                title={`Zoom to all`}
                tooltipPlacement="left-center"
                icon="expand-alt"
                onClick={zoomAll}
                {...buttonSizeProps}
              />
            </ButtonBar>
          )}
          {showPoi && (
            <ButtonBar vertical>
              {pointsOfInterest?.map((preset, i) => (
                <IconButton
                  key={`location-preset-${i}`}
                  title={preset.title}
                  tooltipPlacement="left-center"
                  icon={preset.icon}
                  onClick={() => handlePresetClick(preset)}
                  {...buttonSizeProps}
                />
              ))}
              {showMyLocation && (
                <IconButton
                  title="Set to current location"
                  tooltipPlacement="left-center"
                  icon="location-arrow"
                  onClick={handleSetToCurrentLocationClick}
                  {...buttonSizeProps}
                />
              )}
              {resetLocation && (
                <IconButton
                  title="Reset changes"
                  tooltipPlacement="left-center"
                  icon="rotate-left"
                  onClick={handleResetChangesClick}
                  {...buttonSizeProps}
                />
              )}
            </ButtonBar>
          )}
          {showSatelite && (
            <ButtonBar vertical>
              <IconButton
                title="Toggle map view"
                tooltipPlacement="left-center"
                icon={"layer-group"}
                onClick={handleLayersClick}
                {...buttonSizeProps}
              />
            </ButtonBar>
          )}
        </>
      )}
    </div>
  );
};
export default MapControls;
