import { motion, AnimatePresence } from "framer-motion";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLayer, useHover, Arrow, IBounds, Placement } from "react-laag";
import { useTheme } from "styled-components";

import DS from "@design/system";

interface TipOptions {
  /**
   * Delay before the tooltip is shown.
   * @default 100
   */
  delay?: number;

  /**
   * Preferred placement for the tooltip. Tooltip will still auto-adjust depending on space available.
   * @default top-center
   */
  placement?: Placement;
}

interface CurrentTooltip {
  tip: ReactNode;
  show: boolean;
  bounds?: IBounds;

  options: TipOptions;
}

const listeners: Dispatch<SetStateAction<CurrentTooltip | undefined>>[] = [];

const dispatchTipUpdate = (currentTooltip: CurrentTooltip) => {
  listeners.forEach((listener) => listener(currentTooltip));
};

const useTipStore = () => {
  const [currentTip, setCurrentTip] = useState<CurrentTooltip>();

  useEffect(() => {
    listeners.push(setCurrentTip);

    return () => {
      const index = listeners.indexOf(setCurrentTip);
      if (index < -1) listeners.splice(index, 1);
    };
  }, []);

  return { currentTip };
};

export const useTooltip = <T extends HTMLElement | SVGElement>(
  tip: ReactNode,
  options?: TipOptions,
) => {
  const ref = useRef<T | null>(null);
  const [isOver, hoverProps] = useHover({
    delayEnter: options?.delay ?? 100,
    delayLeave: 0,
  });

  const tooltipProps = useMemo(
    () => ({
      ...hoverProps,
      ref,
    }),
    [hoverProps],
  );

  useEffect(() => {
    dispatchTipUpdate({
      tip,
      show: isOver,
      bounds: ref.current?.getBoundingClientRect(),
      options: options ?? {},
    });
  }, [isOver, options, tip]);

  return { tooltipProps };
};

export const Tooltip = () => {
  const { palettes } = useTheme();

  const { currentTip } = useTipStore();

  const { layerProps, arrowProps, renderLayer } = useLayer({
    trigger: {
      getBounds: () =>
        currentTip?.bounds ?? {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          width: 0,
          height: 0,
        },
    },
    isOpen: (currentTip && currentTip.tip && currentTip.show) || false,
    placement: currentTip?.options.placement,
    auto: true,
    triggerOffset: DS.margins.microN,
  });

  return renderLayer(
    <AnimatePresence>
      {currentTip && currentTip.tip && currentTip.show && (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.1 }}
          {...layerProps}
        >
          <div
            style={{
              padding: `${DS.margins.nano} ${DS.margins.micro}`,
              fontSize: 14,
              color: palettes.tooltip.foreground,
              background: palettes.tooltip.background,
              borderRadius: DS.radii.item,
              maxWidth: 480,
            }}
          >
            {currentTip.tip}
          </div>
          {/* @ts-expect-error Incorrect type definition in react-laag */}
          <Arrow
            {...arrowProps}
            backgroundColor={palettes.tooltip.background}
            borderWidth={0}
            size={6}
          />
        </motion.div>
      )}
    </AnimatePresence>,
  );
};
