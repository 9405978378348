import { ReactNode } from "react";
import styled from "styled-components";

import DS from "@design/system";

const SidePanelFooter = styled.footer<{ children?: ReactNode }>`
  padding: ${({ children }) =>
    children ? DS.margins.regular : DS.margins.micro};
  background: ${({ theme }) => theme.palettes.body.dim};
  display: grid;
  gap: ${DS.margins.micro};
`;

export default SidePanelFooter;
