const mi: Partial<I18n.TranslationBase> = {
  menu: {
    overview: "Tirohanga whānui",
    units: "$t(term.unit_other)",
    incidents: "$t(term.incident_other)",
    shipments: "",
    people: "Ranga",
    stores: "$t(term.store_other)",
    billing: "Pire",
    settings: "Tautuhinga",
  },
  filterPanel: {
    title: "Whakarōpū",
    manageGroups: "Whakahaere whakarōpū",
    storeCount_zero: "Kaore he toa",
    storeCount_one: "{{count}} toa",
    storeCount_other: "{{count}} toa",
  },
  // people: {
  //   search: "Rapu…",
  // },
  // global: {
  //   buttonLabels: {
  //     save: "Pupuri",
  //     saveAndClose: "Pupuri and close",
  //     saving: "Pupuri…",
  //     close: "Whakakore",
  //   },
  // },
};

export default mi;
