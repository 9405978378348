import { useMemo } from "react";
import styled from "styled-components";

import DS from "@design/system";

import { ButtonProps } from "../button";
import { IIcon } from "../icons";
import { isThemeButton } from "./Button";
import Icon from "./Icon";
import { useTooltip } from "./Tooltip";

const Container = styled.button<ButtonProps>`
  box-sizing: border-box;
  display: block;
  padding: ${({ fit }) =>
    fit === "tight"
      ? 2
      : fit === "compact"
        ? DS.margins.nanoN
        : fit === "relaxed"
          ? 10
          : DS.margins.microN}px;
  margin: 0;

  cursor: pointer;
  color: ${({ theme, active, buttonType }) =>
    active
      ? theme.palettes.buttons.neutralSelected.foreground
      : isThemeButton(buttonType)
        ? theme.palettes.buttons[buttonType].foreground
        : "currentcolor"};
  line-height: ${({ size }) => (size === "small" ? "12px" : "inherit")};
  font-size: ${({ size }) => (size === "small" ? "12px" : "inherit")};

  border: solid 1px
    ${({ theme, buttonType }) =>
      isThemeButton(buttonType)
        ? theme.palettes.buttons[buttonType].border
        : "transparent"};
  border-radius: ${DS.radii.item};
  background: ${({ theme, active, buttonType }) =>
    active
      ? theme.palettes.buttons.neutralSelected.background
      : isThemeButton(buttonType)
        ? theme.palettes.buttons[buttonType].background
        : "transparent"};

  transition: background 200ms;

  box-shadow: ${({ active }) =>
    active ? "inset 0 2px 4px rgba(0, 0, 0, 0.15)" : "none"};

  span:first-child {
    display: block;
  }

  &:hover:not(:disabled) {
    background: ${({ theme, active, buttonType }) =>
      active
        ? theme.palettes.buttons.neutralSelected.dim
        : isThemeButton(buttonType)
          ? theme.palettes.buttons[buttonType].dim
          : "rgba(0, 0, 0, 0.1)"};
  }

  &:active:not(:disabled) {
    transform: translate3d(0, 1px, 0);
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(101, 184, 212, 0.5);
  }
`;

const IconButton = ({
  icon,
  iconSize,
  title,
  tooltipPlacement,
  spin = false,
  shape = "square",
  href,
  ...rest
}: {
  icon: IIcon;
  iconSize?: number;
  spin?: boolean;
  shape?: "square" | "round";
} & ButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const as = useMemo(() => (href ? "a" : "button"), [href]);

  const { tooltipProps } = useTooltip<HTMLButtonElement>(title, {
    placement: tooltipPlacement ?? "top-center",
  });

  return (
    <Container
      type="button"
      {...rest}
      {...tooltipProps}
      as={as}
      href={href}
      aria-label={title}
      style={{
        fontSize: iconSize ?? "inherit",
        borderRadius: shape === "square" ? DS.radii.item : DS.radii.pill,
      }}
    >
      <Icon name={icon} spin={spin} />
    </Container>
  );
};

export default IconButton;
